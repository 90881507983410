import type {QrReaderADto} from '@cohort/admin-schemas/qrcode';
import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import ExportCSV from '@cohort/merchants/components/buttons/ExportCSV';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {qrReadersKeys, useQrReadersWithPagination} from '@cohort/merchants/hooks/api/QrReaders';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import usePageSearchParam from '@cohort/merchants/hooks/pageSearchParam';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {deleteQrReader, exportQrReaders} from '@cohort/merchants/lib/api/QrReaders';
import {
  trackQrReaderDeleted,
  trackQrReadersSettingsPageViewed,
} from '@cohort/merchants/lib/Tracking';
import CreateQrReadersModal from '@cohort/merchants/pages/settings/qr-readers/CreateQrReadersModal';
import {CheckCircle, Plus, Trash} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {createColumnHelper} from '@tanstack/react-table';
import dayjs from 'dayjs';
import type {TFunction} from 'i18next';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

interface QrReadersTableProps {
  readers: QrReaderADto[];
  t: TFunction<'pages', 'settings.qrReaders.page'>;
  onDeleteReader: (id: string) => void;
}

const QrReadersTable: React.FC<QrReadersTableProps> = ({readers, t, onDeleteReader}) => {
  const [copiedId, setCopiedId] = useState<string | undefined>();
  const columnHelper = createColumnHelper<QrReaderADto>();
  const columns = [
    columnHelper.accessor('name', {
      header: t('tableHeaderName'),
      cell: data => (
        <div>
          <p className="font-medium text-foreground">{data.getValue()}</p>
          <p className="text-slate-500">{lastScan(data.row.original.lastScanDate)}</p>
        </div>
      ),
      enableSorting: false,
    }),
    columnHelper.accessor(row => `${row.url.slice(0, 20)}...${row.url.slice(-10)}`, {
      id: 'url',
      header: t('tableHeaderUrl'),
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'actions',
      cell: data => (
        <div className="flex justify-end gap-4">
          {copiedId === data.row.original.id ? (
            <div className="my-auto flex w-32 justify-center text-primary">
              <CheckCircle className="mr-1 h-5 w-5 cursor-pointer" />
              <span className="text-sm font-medium">{t('labelCopied')}</span>
            </div>
          ) : (
            <Button
              className="w-32"
              variant="secondary"
              onClick={async () => handleCopyUrl(data.row.original)}
            >
              {t('buttonCopyUrl')}
            </Button>
          )}
          <Button
            variant="secondary"
            size="icon"
            onClick={() => onDeleteReader(data.row.original.id)}
          >
            <Trash size={20} className="text-red-400" />
          </Button>
        </div>
      ),
    }),
  ];

  const handleCopyUrl = useCallback(
    async (reader: QrReaderADto): Promise<void> => {
      await navigator.clipboard.writeText(reader.url);
      setCopiedId(reader.id);
      setTimeout((id: string) => {
        if (copiedId === id) {
          setCopiedId(undefined);
        }
      }, 3000);
    },
    [copiedId]
  );

  const lastScan = useCallback(
    (date: Date | null) => {
      return date ? t('subtitleLastScan', {time: dayjs(date).fromNow()}) : t('subtitleNotUsed');
    },
    [t]
  );
  const table = useCohortTable({
    columns,
    data: readers,
  });

  return (
    <DataTable
      table={table}
      columnsLength={columns.length}
      emptyStatePlaceholder={t('emptyPlaceholder')}
    />
  );
};

const QrReadersSettingsPage = (): JSX.Element => {
  const queryClient = useQueryClient();

  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.qrReaders.page',
  });

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [readerToDelete, setReaderToDelete] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const page = usePageSearchParam();

  useEffect(() => {
    trackQrReadersSettingsPageViewed();
  }, []);

  const {data, isLoading} = useQrReadersWithPagination(merchantId, {page, pageSize: 10});

  const {mutate: deleteReaderMutation} = useCohortMutation({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    mutationFn: async () => deleteQrReader(merchantId, readerToDelete!),
    notifySuccessMessage: t('deleteModal.notificationSuccess'),
    onSuccess: async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      trackQrReaderDeleted(readerToDelete!);
      setReaderToDelete(undefined);
      await queryClient.invalidateQueries(qrReadersKeys.list(merchantId));
    },
  });

  const [pagination, readers] = data ?? [];

  const headerConfig = {
    title: t('header'),
    subtitle: t('subheader'),
    topRightElements: (
      <Button onClick={() => setCreateModalOpen(true)}>
        <Plus className="-ml-1 mr-2 h-5 w-5" />
        {t('buttonAdd')}
      </Button>
    ),
  };

  const paginationConfig = {
    pagination,
  };

  return (
    <AdminPage header={headerConfig} pagination={paginationConfig}>
      <div className="mb-2 flex items-center justify-end">
        <ExportCSV
          disabled={isLoading || (readers?.length ?? 0) === 0}
          downloadFunction={async () => exportQrReaders(merchantId)}
          withCache={false}
        />
      </div>
      <QrReadersTable readers={readers ?? []} t={t} onDeleteReader={id => setReaderToDelete(id)} />
      <CreateQrReadersModal
        show={createModalOpen}
        merchantId={merchantId}
        onClose={() => setCreateModalOpen(false)}
      />
      <DeletionModal
        title={t('deleteModal.title')}
        subtitle={t('deleteModal.subtitle')}
        onClose={() => setReaderToDelete(undefined)}
        onDelete={() => deleteReaderMutation()}
        show={readerToDelete !== undefined}
      />
    </AdminPage>
  );
};

export default QrReadersSettingsPage;
