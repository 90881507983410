// i18nOwl-ignore [eventDate, campaignId, collectionId, perkId, badgeId, appId, cohortId]
import type {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';

export function useUserEventPropertiesTranslations(): TFunction {
  const {t} = useTranslation('hooks', {
    keyPrefix: 'rules.userEventProperties',
  });

  return t;
}
