import {Card, CardTitleSecondary} from '@cohort/components-xps/components/cards/Card';
import type {FaqItem} from '@cohort/shared/schema/common/campaign';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Disclosure} from '@headlessui/react';
import {CaretDown} from '@phosphor-icons/react';
import React from 'react';

type FaqQuestionCardProps = {
  faqItem: FaqItem;
};

const FaqQuestionCard: React.FC<FaqQuestionCardProps> = ({faqItem}) => (
  <Disclosure as="div" key={faqItem.question} className="mb-2 w-full cursor-pointer rounded-xl">
    {({open}) => (
      <Card>
        <Disclosure.Button as="div" className="flex w-full items-center justify-between">
          <CardTitleSecondary>{faqItem.question}</CardTitleSecondary>
          <CaretDown
            size={24}
            className={cn('transform duration-200', open ? '-rotate-0' : '-rotate-90')}
            style={{
              color: 'var(--xps-accent-color)',
            }}
          />
        </Disclosure.Button>
        <Disclosure.Panel as="dd" className="mt-4">
          <div
            dangerouslySetInnerHTML={{__html: faqItem.answer}}
            className="prose prose-sm m-0 cursor-text text-justify text-sm font-medium focus:outline-none"
          />
        </Disclosure.Panel>
      </Card>
    )}
  </Disclosure>
);

export default FaqQuestionCard;
