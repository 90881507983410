import type {DigitalAssetCollectionAnalyticsADto} from '@cohort/admin-schemas/digitalAssetCollection';
import Button from '@cohort/merchants/components/buttons/Button';
import PolygonButton from '@cohort/merchants/components/buttons/PolygonButton';
import {DigitalAssetCollectionStatusBadge} from '@cohort/merchants/components/digital-assets/DigitalAssetCollectionStatusBadge';
import {DigitalAssetVisual} from '@cohort/merchants/components/DigitalAssetVisual';
import LastEditLabel from '@cohort/merchants/components/LastEditLabel';
import Loader from '@cohort/merchants/components/Loader';
import {getDigitalAssetRoute} from '@cohort/merchants/lib/Pages';
import DigitalAssetCollectionDropdown from '@cohort/merchants/pages/digital-asset-collections/overview/DigitalAssetCollectionDropdown';
import {ArrowCircleRight} from '@phosphor-icons/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';

interface DigitalAssetCollectionListItemProps {
  digitalAssetCollection: DigitalAssetCollectionAnalyticsADto;
  action?: 'dropdown' | 'link';
}

const DigitalAssetCollectionListLeftColumn: React.FC<DigitalAssetCollectionListItemProps> = ({
  digitalAssetCollection,
}) => {
  return (
    <div className="flex w-full items-start justify-between gap-2 truncate md:items-center">
      <div className="flex items-center gap-4 truncate">
        <DigitalAssetVisual
          digitalAssetImageFileKey={digitalAssetCollection.imageFileKey}
          animationFileKey={digitalAssetCollection.animationFileKey}
          className="rounded-md"
          size={48}
        />
        <div className="truncate">
          <h3 className="flex items-center gap-x-1 truncate text-sm font-medium">
            {digitalAssetCollection.internalName}
            {digitalAssetCollection.nftEnabled && digitalAssetCollection.smartContract === null && (
              <Loader size={14} color="gray" />
            )}
            {digitalAssetCollection.nftEnabled &&
              digitalAssetCollection.smartContract !== null &&
              digitalAssetCollection.smartContract.blockExplorerUrl !== null && (
                <PolygonButton
                  size={14}
                  href={digitalAssetCollection.smartContract.blockExplorerUrl}
                />
              )}
          </h3>
        </div>
      </div>
      <div className="block md:hidden">
        <DigitalAssetCollectionDropdown digitalAssetCollection={digitalAssetCollection} />
      </div>
    </div>
  );
};

const DigitalAssetCollectionListMiddleColumn: React.FC<DigitalAssetCollectionListItemProps> = ({
  digitalAssetCollection,
}) => (
  <div>
    <DigitalAssetCollectionStatusBadge
      size="small"
      campaignsCount={digitalAssetCollection.campaignsCount}
    />
  </div>
);

const DigitalAssetCollectionListRightColumn: React.FC<DigitalAssetCollectionListItemProps> = ({
  digitalAssetCollection,
  action,
}) => {
  return (
    <div className="flex w-full items-center justify-end gap-6">
      <div className="flex w-full flex-col items-end gap-2">
        <LastEditLabel date={digitalAssetCollection.updatedAt} />
      </div>
      <div className="hidden md:block">
        {action === 'dropdown' ? (
          <DigitalAssetCollectionDropdown digitalAssetCollection={digitalAssetCollection} />
        ) : (
          <Button variant="secondary" className="!px-2">
            <ArrowCircleRight className="h-6 w-6 text-slate-500" />
          </Button>
        )}
      </div>
    </div>
  );
};

const DigitalAssetCollectionListItem: React.FC<DigitalAssetCollectionListItemProps> = ({
  digitalAssetCollection,
  action = 'dropdown',
}) => {
  const navigate = useNavigate();
  const handleClick = (e: React.MouseEvent<HTMLLIElement>): void => {
    e.stopPropagation();
    navigate(getDigitalAssetRoute(digitalAssetCollection.id).path);
  };
  return (
    <li
      key={digitalAssetCollection.id}
      data-testid="digitalAssetCollection-list-item"
      className="border-t-2 border-slate-100 hover:cursor-pointer"
      onClick={handleClick}
    >
      <div className="grid items-center gap-6 py-4 md:[grid-template-columns:1fr_min-content_280px]">
        <DigitalAssetCollectionListLeftColumn digitalAssetCollection={digitalAssetCollection} />
        <DigitalAssetCollectionListMiddleColumn digitalAssetCollection={digitalAssetCollection} />
        <DigitalAssetCollectionListRightColumn
          digitalAssetCollection={digitalAssetCollection}
          action={action}
        />
      </div>
    </li>
  );
};

export default DigitalAssetCollectionListItem;
