import type {PatchMerchantADto} from '@cohort/admin-schemas/merchant';
import UrlInput from '@cohort/merchants/components/form/input/UrlInput';
import {merchantsKeys} from '@cohort/merchants/hooks/api/Merchants';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {notify} from '@cohort/merchants/hooks/toast';
import {patchMerchant} from '@cohort/merchants/lib/api/Merchants';
import {trackBrandSettingsSaveButtonClicked} from '@cohort/merchants/lib/Tracking';
import LinkSection from '@cohort/merchants/pages/settings/integrations/LinkSection';
import {urlTransformer, urlValidator} from '@cohort/shared/utils/validators';
import {zodResolver} from '@hookform/resolvers/zod';
import {ArrowSquareOut} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import z from 'zod';

const EmbedSettingsFormSchema = z.object({
  embedUrl: z
    .string()
    .refine(url => url === '' || urlValidator(url), 'errorUrl')
    .transform(url => (url !== '' ? urlTransformer(url) : null)),
});
type EmbedSettingsFormType = z.infer<typeof EmbedSettingsFormSchema>;

type EmbedSettingsFormProps = {
  merchantId: string;
  embedUrl: string | null;
};

const EmbedSettingsForm: React.FC<EmbedSettingsFormProps> = ({merchantId, embedUrl}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.integrations.embedForm',
  });
  const queryClient = useQueryClient();
  const {
    register,
    control,
    handleSubmit,
    formState: {isDirty},
  } = useForm<EmbedSettingsFormType>({
    defaultValues: {
      embedUrl: embedUrl !== null ? embedUrl.replace('https://', '') : null,
    },
    resolver: zodResolver(EmbedSettingsFormSchema),
  });

  const {isLoading, mutate: updateMerchant} = useCohortMutation({
    mutationFn: async (data: PatchMerchantADto) => patchMerchant(merchantId, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries(merchantsKeys.getById(merchantId));
      notify('success', t('notificationUpdateSuccess'));
      trackBrandSettingsSaveButtonClicked();
    },
  });

  return (
    <form
      onSubmit={handleSubmit(data => updateMerchant(data))}
      className="space-y-6 rounded-3xl bg-white"
    >
      <LinkSection
        title={t('title')}
        description={t('description')}
        buttons={[
          {
            label: t('documentationLink'),
            url: `https://docs.getcohort.com/developers/embed`,
            icon: <ArrowSquareOut className="-ml-1 mr-2 h-5 w-5" />,
          },
          {
            label: t('buttonSave'),
            color: 'primary',
            isSubmit: true,
            isLoading: isLoading === true,
            isDisabled: isDirty === false,
            testId: 'save',
          },
        ]}
      />
      <UrlInput
        className="w-1/2"
        name="embedUrl"
        type={'text'}
        label={t('labelEmbedUrl')}
        placeholder="example.com"
        register={register}
        control={control}
      />
    </form>
  );
};

export default EmbedSettingsForm;
