/* eslint-disable import/no-unassigned-import */
import '@cohort/components-xps/xps-theme.css';
import '@cohort/merchants/index.css';
import '@cohort/merchants/lib/i18n';

import {notify} from '@cohort/merchants/hooks/toast';
import {initFirebase} from '@cohort/merchants/lib/Firebase';
import {initTracking} from '@cohort/merchants/lib/Tracking';
import RouterWithFlags from '@cohort/merchants/router/RouterWithFlags';
import {defaultNetworkErrorMessage} from '@cohort/shared/models';
import {TurnstileProvider} from '@cohort/shared-frontend/providers/TurnstileProvider';
import {QueryCache, QueryClient, QueryClientProvider} from '@tanstack/react-query';
import React from 'react';
import {createRoot} from 'react-dom/client';

initTracking();
initFirebase();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 30, // 30 seconds
    },
  },
  queryCache: new QueryCache({
    onError: (err: unknown) => {
      let errorMessage;

      if (err instanceof Error) {
        errorMessage = err.message;
      }
      notify('error', errorMessage ?? defaultNetworkErrorMessage);
    },
  }),
});

// Configure error overlay
if (import.meta.env.DEV) {
  let overlayVisible = false;

  window.onerror = (event, source, lineno, colno, err) => {
    if (!overlayVisible && err && err.message) {
      const ErrorOverlay = customElements.get('vite-error-overlay');

      if (!ErrorOverlay) {
        return;
      }
      const overlay = new ErrorOverlay(err);

      document.body.appendChild(overlay);
      overlayVisible = true;
    }
  };
}

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <TurnstileProvider action="admin" siteKey={import.meta.env.COHORT_TURNSTILE_SITE_KEY}>
      <QueryClientProvider client={queryClient}>
        <RouterWithFlags />
      </QueryClientProvider>
    </TurnstileProvider>
  </React.StrictMode>
);
