import type {
  JourneyParticipationADto,
  JourneyParticipationsParamsADto,
  MarkStepCompletedADto,
  ParticipationAnalyticsADto,
} from '@cohort/admin-schemas/journeyParticipation';
import {
  JourneyParticipationASchema,
  ParticipationAnalyticsASchema,
} from '@cohort/admin-schemas/journeyParticipation';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import type {ExportDto, ExportFormatDto} from '@cohort/shared/schema/common/export';
import {ExportSchema} from '@cohort/shared/schema/common/export';
import type {PaginationDto} from '@cohort/shared/schema/common/pagination';
import {paginatedParser} from '@cohort/shared/utils/parser';

export async function getJourneyParticipation(
  merchantId: string,
  journeyParticipationsId: string
): Promise<JourneyParticipationADto> {
  return apiCall(
    'GET',
    `/v1/merchants/${merchantId}/journey-participations/${journeyParticipationsId}`,
    {
      expect: HttpCodes.SUCCESS,
      parser: JourneyParticipationASchema.parse,
    }
  );
}

export async function getJourneyParticipations(
  merchantId: string,
  params: JourneyParticipationsParamsADto
): Promise<[PaginationDto, JourneyParticipationADto[]]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/journey-participations`, {
    expect: HttpCodes.SUCCESS,
    params,
    parser: paginatedParser(JourneyParticipationASchema),
  });
}

export async function getParticipationAnalytics(
  merchantId: string,
  campaignId: string
): Promise<ParticipationAnalyticsADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/journey-participations/analytics`, {
    expect: HttpCodes.SUCCESS,
    parser: ParticipationAnalyticsASchema.parse,
    params: {campaignId},
  });
}

export async function markStepCompleted(
  merchantId: string,
  journeyParticipationId: string,
  body: MarkStepCompletedADto
): Promise<JourneyParticipationADto> {
  return apiCall(
    'POST',
    `/v1/merchants/${merchantId}/journey-participations/${journeyParticipationId}/mark-step-completed`,
    {
      expect: HttpCodes.CREATED,
      parser: JourneyParticipationASchema.parse,
      body,
    }
  );
}

export async function exportParticipations(
  merchantId: string,
  campaignId: string,
  format: ExportFormatDto
): Promise<ExportDto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/journey-participations/export`, {
    expect: HttpCodes.CREATED,
    parser: ExportSchema.parse,
    params: {
      campaignId,
      format,
    },
  });
}
