import {usePerkIntegrations} from '@cohort/merchants/apps/usePerkIntegrations';
import type {PerkTypeData} from '@cohort/merchants/hooks/usePerkUtils';
import type {PerkType} from '@cohort/shared/schema/common/perks';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface PerkTypeCardProps {
  perkTypeData: PerkTypeData;
  onClick: (type: PerkType) => void;
}

const PerkTypeCard: React.FC<PerkTypeCardProps> = ({perkTypeData, onClick}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'perks.new.perkTypeCard'});
  const {getPerkIntegration, getPerkIntegrationTitle} = usePerkIntegrations();

  return (
    <li
      key={perkTypeData.title}
      onClick={() => onClick(perkTypeData.type)}
      data-testid={`perk-type-card-${perkTypeData.type}`}
      className="col-span-1 flex cursor-pointer flex-col divide-y divide-slate-200 overflow-hidden rounded-lg border bg-white transition duration-300 ease-in-out hover:shadow-md"
    >
      <div className="flex flex-col items-start p-6">
        <div
          className={cn(
            'flex h-14 w-14 items-center justify-center rounded',
            perkTypeData.backgroundColor,
            perkTypeData.iconColor
          )}
        >
          {perkTypeData.icon}
        </div>
        <div className="pt-8">{perkTypeData.title}</div>
        <div className="pt-2 text-slate-500">
          {perkTypeData.type !== 'custom'
            ? perkTypeData.perkIntegrations
                .map(integrationId => getPerkIntegrationTitle(getPerkIntegration(integrationId)))
                .join(', ')
            : t('labelBlankTemplate')}
        </div>
      </div>
    </li>
  );
};

export default PerkTypeCard;
