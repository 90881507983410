import {DateSchema} from '@cohort/shared/schema/common';
import {DefaultContextSchema} from '@cohort/shared/schema/templating/common';
import {UserTSchema} from '@cohort/shared/schema/templating/common';
import {z} from 'zod';

const JourneyRewardTSchema = z.object({
  id: z.string().uuid(),
  videoUrl: z.string().url().nullable(),
  description: z.string().nullable(),
  imageUrl: z.string().url().nullable(),
  title: z.string(),
  type: z.enum(['digital-asset', 'perk']),
});

const JourneyStepTSchema = z.object({
  id: z.string().uuid(),
  description: z.string(),
  icon: z.string().emoji(),
  isCompleted: z.boolean(),
  title: z.string(),
});

export const JourneyTSchema = z.object({
  id: z.string().uuid(),
  videoUrl: z.string().url().nullable(),
  availableSupply: z.number().int().min(0).nullable(),
  description: z.string(),
  imageUrl: z.string().url().nullable(),
  participation: z
    .object({
      completedAt: DateSchema.nullable(),
    })
    .nullable(),
  rewards: z.array(JourneyRewardTSchema),
  status: z.enum(['published', 'ended']),
  steps: z.array(JourneyStepTSchema),
  title: z.string(),
  totalSupply: z.number().int().min(1).nullable(),
});
export type JourneyTDto = z.infer<typeof JourneyTSchema>;

export const JourneyContextTSchema = DefaultContextSchema.extend({
  user: UserTSchema,
  journey: JourneyTSchema,
});
export type JourneyContextTDto = z.infer<typeof JourneyContextTSchema>;
