// i18nOwl-ignore [app-postgresql.description]
import type {App} from '@cohort/merchants/apps';
import translationEn from '@cohort/merchants/apps/postgresql/locales/en.json';
import translationFr from '@cohort/merchants/apps/postgresql/locales/fr.json';
import PostgresqlEditConnectionComponent from '@cohort/merchants/apps/postgresql/PostgresqlEditConnectionComponent';
import {PostgresqlSyncIntegration} from '@cohort/merchants/apps/postgresql/sync/SyncIntegration';
import type {PostgresqlAppStruct} from '@cohort/shared/apps/postgresql';
import {PostgresqlAppSpec} from '@cohort/shared/apps/postgresql';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

const PostgresqlApp: App<PostgresqlAppStruct> = {
  spec: PostgresqlAppSpec,
  ConnectionEditComponent: PostgresqlEditConnectionComponent,
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="postgresql" height={40} width={40} />,
  notificationIntegrations: [],
  perkIntegrations: [],
  triggerIntegrations: [],
  userEvents: [],
  sync: PostgresqlSyncIntegration,
};

export default PostgresqlApp;
