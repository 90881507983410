import type {StepCompletionAttemptADto} from '@cohort/admin-schemas/journeyParticipation';
import Button from '@cohort/merchants/components/buttons/Button';
import {StepCompletion} from '@cohort/merchants/components/campaigns/journeys/StepCompletion';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@cohort/merchants/components/modals/Dialog';
import Timeline from '@cohort/merchants/components/Timeline';
import {useTranslation} from 'react-i18next';

type StepCompletionStepperProps = {
  stepCompletionAttempts: Array<StepCompletionAttemptADto>;
};

const StepCompletionStepper: React.FC<StepCompletionStepperProps> = ({stepCompletionAttempts}) => {
  const sortedStepCompletionAttempts = stepCompletionAttempts.sort(
    (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
  );

  return (
    <Timeline
      timelineItems={sortedStepCompletionAttempts.map(attempt => ({
        children: <StepCompletion attempt={attempt} displayDate={true} />,
        statusIcon: attempt.success ? 'success' : 'error',
      }))}
    />
  );
};

type StepCompletionModalProps = {
  onClose: () => void;
  stepCompletionAttempts: Array<StepCompletionAttemptADto>;
};

const StepCompletionModal: React.FC<StepCompletionModalProps> = ({
  onClose,
  stepCompletionAttempts,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.activity.journey.stepCompletionModal',
  });

  return (
    <Dialog onOpenChange={onClose} open>
      <DialogContent className="w-[600px]">
        <DialogHeader className="space-y-6">
          <DialogTitle className="text-lg font-semibold tracking-normal">{t('title')}</DialogTitle>
        </DialogHeader>

        <DialogBody>
          <StepCompletionStepper stepCompletionAttempts={stepCompletionAttempts} />
        </DialogBody>

        <DialogFooter className="flex justify-end bg-transparent">
          <Button onClick={onClose} variant="primary">
            {t('buttonClose')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default StepCompletionModal;
