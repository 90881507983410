/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {GetAirdropActivitiesParamsADto} from '@cohort/admin-schemas/airdropActivities';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getAirdropActivities} from '@cohort/merchants/lib/api/Campaigns';

export const airdropActivitiesKeys = {
  airdropActivities: ['airdrop-activities'] as const,
  list: (merchantId: string, campaignId: string, params: GetAirdropActivitiesParamsADto) =>
    [...airdropActivitiesKeys.airdropActivities, merchantId, campaignId, params, 'list'] as const,
};

export const useAirdropActivities = (
  merchantId: string,
  campaignId: string,
  params: GetAirdropActivitiesParamsADto,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: airdropActivitiesKeys.list(merchantId, campaignId, params),
    queryFn: async () => getAirdropActivities(merchantId, campaignId, params),
    ...options,
  });
