import type {App} from '@cohort/merchants/apps';
import Tooltip from '@cohort/shared-frontend/components/Tooltip';
import React, {Fragment} from 'react';

type AppLogoProps = {
  app: App | null;
  size?: number;
  fallbackLogo?: React.ReactElement;
  tooltipContent?: string;
};

const AppLogo: React.FC<AppLogoProps> = ({app, size = 20, fallbackLogo, tooltipContent}) => {
  const logo = app
    ? React.cloneElement(app.logo as React.ReactElement, {height: size, width: size})
    : fallbackLogo ?? <Fragment />;

  return (
    <Tooltip contentAsChild content={tooltipContent} disabled={tooltipContent === undefined}>
      {logo}
    </Tooltip>
  );
};

export default AppLogo;
