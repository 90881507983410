import type {BadgeADto} from '@cohort/admin-schemas/badge';
import Button from '@cohort/merchants/components/buttons/Button';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import {badgesKeys} from '@cohort/merchants/hooks/api/Badges';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {userEventsKeys} from '@cohort/merchants/hooks/api/UserEvents';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {deleteBadge} from '@cohort/merchants/lib/api/Badges';
import {getBadgesRoute} from '@cohort/merchants/lib/Pages';
import {Trash} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

type DeleteBadgeProps = {
  badge: BadgeADto;
};

const DeleteBadge: React.FC<DeleteBadgeProps> = ({badge}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {t} = useTranslation('pages', {
    keyPrefix: 'users.badges.badge.deleteBadge',
  });

  const {mutate: deleteBadgeMutation} = useCohortMutation({
    mutationFn: async () => deleteBadge(merchantId, badge.id),
    notifySuccessMessage: t('notificationDeleteSuccess'),
    onSuccess: async () => {
      navigate(getBadgesRoute().path);
      await queryClient.invalidateQueries(badgesKeys.list(merchantId));
      queryClient.invalidateQueries(userEventsKeys.userEvents);
    },
  });

  return (
    <div>
      <Button variant="secondary" size="icon" onClick={() => setShowDeleteModal(true)}>
        <Trash size={20} className="text-red-400" />
      </Button>
      {showDeleteModal && (
        <DeletionModal
          title={t('deleteModalTitle')}
          subtitle={t('deleteModalSubtitle')}
          onClose={async () => setShowDeleteModal(false)}
          show={showDeleteModal}
          onDelete={deleteBadgeMutation}
        />
      )}
    </div>
  );
};

export default DeleteBadge;
