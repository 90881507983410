import BaseReferenceComponent from '@cohort/merchants/components/references/BaseReferenceComponent';
import {getDigitalAssetRoute} from '@cohort/merchants/lib/Pages';

type DigitalAssetReferenceComponentProps = {
  digitalAssetCollectionId: string;
  imageFileKey: string;
  internalName: string;
};

const DigitalAssetCollectionReferenceComponent: React.FC<DigitalAssetReferenceComponentProps> = ({
  digitalAssetCollectionId,
  imageFileKey,
  internalName,
}) => (
  <BaseReferenceComponent
    title={internalName}
    redirectPath={getDigitalAssetRoute(digitalAssetCollectionId).path}
    imageFileKey={imageFileKey}
  />
);
export default DigitalAssetCollectionReferenceComponent;
