import type {JourneyParticipationADto} from '@cohort/admin-schemas/journeyParticipation';
import type {JourneyStepADto} from '@cohort/admin-schemas/journeySteps';
import Button from '@cohort/merchants/components/buttons/Button';
import StepCompletionModal from '@cohort/merchants/components/campaigns/journeys/StepCompletionModal';
import {ConfirmationModal} from '@cohort/merchants/components/modals/ConfirmationModal';
import {TimelineItem} from '@cohort/merchants/components/Timeline';
import {JourneyParticipationKeys} from '@cohort/merchants/hooks/api/JourneyParticipations';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {markStepCompleted} from '@cohort/merchants/lib/api/JourneyParticipations';
import {notifyError} from '@cohort/merchants/stores/ErrorModalStore';
import {formatDate} from '@cohort/shared/utils/format';
import {CheckCircle, ListMagnifyingGlass, XCircle} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import React, {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {sortBy} from 'remeda';
import {match} from 'ts-pattern';

type JourneyParticipationStepProps = {
  journeyParticipation: JourneyParticipationADto;
  journeyStep: JourneyStepADto;
  isLastStep: boolean;
};

const JourneyParticipationStep: React.FC<JourneyParticipationStepProps> = ({
  journeyParticipation,
  journeyStep,
  isLastStep,
}) => {
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.activity.journey.journeyParticipationsStep',
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showStepCompletionModal, setShowStepCompletionModal] = useState(false);
  const {mutate: markStepCompletedMutation, isLoading: stepCompleteLoading} = useCohortMutation({
    mutationFn: async () =>
      markStepCompleted(merchant.id, journeyParticipation.id, {journeyStepId: journeyStep.id}),
    notifySuccessMessage: t('notificationCompleteSuccess'),
    onSuccess: async () => {
      await queryClient.invalidateQueries(JourneyParticipationKeys.participations);
    },
    onError: err => {
      notifyError(err, t('notificationCompleteFailed'));
    },
    onSettled: () => {
      setShowConfirmationModal(false);
    },
  });

  const stepCompletionAttempts = journeyParticipation.stepCompletionAttempts.filter(
    attempt => attempt.stepId === journeyStep.id
  );
  const sortedStepCompletionAttempts = sortBy(stepCompletionAttempts, attempt => attempt.createdAt);
  const successfulStepCompletionAttempt = stepCompletionAttempts.find(attempt => attempt.success);

  const isStepFailed =
    sortedStepCompletionAttempts.length > 0 &&
    successfulStepCompletionAttempt === undefined &&
    journeyStep.triggers.length > 0 &&
    journeyStep.triggers.every(trigger => trigger.verificationAttemptsLeft === 0);

  const stepStatus = match({
    isStepFailed,
    successfulStepCompletionAttempt,
  })
    .with({isStepFailed: true}, () => {
      const lastAttempt = sortedStepCompletionAttempts[sortedStepCompletionAttempts.length - 1];
      if (sortedStepCompletionAttempts.length === 0) {
        throw new Error('sortedStepCompletionAttemptsLength can not be 0 for failed step');
      }

      return (
        <Fragment>
          <XCircle className="mr-2 h-5 w-5 text-red-500" />
          <p className="mr-1 text-muted-foreground">{t('labelStepFailed')}</p>
          <p className="font-medium">
            {formatDate(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              lastAttempt!.createdAt
            )}
          </p>
          <Button
            onClick={() => setShowConfirmationModal(true)}
            variant="secondary"
            className="ml-4 space-x-1"
            size="xs"
            loading={stepCompleteLoading}
          >
            <CheckCircle size={18} />
            <div>{t('buttonComplete')}</div>
          </Button>
        </Fragment>
      );
    })
    .with({successfulStepCompletionAttempt: undefined}, () => (
      <Fragment>
        <XCircle className="mr-2 h-5 w-5 text-slate-400" />
        <p className="text-muted-foreground">{t('labelStepNotCompleted')}</p>
        <Button
          onClick={() => setShowConfirmationModal(true)}
          variant="secondary"
          className="ml-4 space-x-1"
          size="xs"
          loading={stepCompleteLoading}
        >
          <CheckCircle size={18} />
          <div>{t('buttonComplete')}</div>
        </Button>
      </Fragment>
    ))
    .otherwise(() => (
      <Fragment>
        <CheckCircle className="mr-2 h-5 w-5 text-green-500" />
        <p className="mr-1 text-muted-foreground">{t('labelStepCompleted')}</p>

        <p className="font-medium">
          {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            formatDate(successfulStepCompletionAttempt!.createdAt)
          }
        </p>
      </Fragment>
    ));

  return (
    <TimelineItem showVerticalLine={!isLastStep}>
      <p className="line-clamp-2 w-[40%] px-4 font-medium">
        {journeyStep.title[merchant.defaultLanguage]}
      </p>
      <div className="flex items-center">{stepStatus}</div>
      {stepCompletionAttempts.length > 0 && (
        <Button
          onClick={() => setShowStepCompletionModal(true)}
          variant="secondary"
          className="ml-2 space-x-1"
          size="xs"
          loading={stepCompleteLoading}
        >
          <ListMagnifyingGlass size={18} /> <div>{t('buttonViewDetails')}</div>
        </Button>
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          content={t('confirmationModal.subtitle')}
          submitButtonText={t('confirmationModal.button')}
          show={true}
          onClose={() => setShowConfirmationModal(false)}
          onSubmit={() => markStepCompletedMutation()}
          loading={stepCompleteLoading}
        />
      )}
      {showStepCompletionModal && (
        <StepCompletionModal
          onClose={() => setShowStepCompletionModal(false)}
          stepCompletionAttempts={stepCompletionAttempts}
        />
      )}
    </TimelineItem>
  );
};

export default JourneyParticipationStep;
