import type {UpdateUserDataADto, UserAnalyticsADto} from '@cohort/admin-schemas/userAnalytics';
import type {MembershipPassCardProps} from '@cohort/components-xps/components/cards/MembershipPassCard';
import MembershipPassCard from '@cohort/components-xps/components/cards/MembershipPassCard';
import EngagementScoreGauge from '@cohort/merchants/components/EngagementScoreGauge';
import {InformationTooltip} from '@cohort/merchants/components/InformationTooltip';
import Loader from '@cohort/merchants/components/Loader';
import {useCustomComponents} from '@cohort/merchants/hooks/api/CustomComponents';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserAttributes} from '@cohort/merchants/hooks/api/UserAttributes';
import {usersKeys} from '@cohort/merchants/hooks/api/Users';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCurrentUser} from '@cohort/merchants/hooks/contexts/currentUser';
import {updateUser} from '@cohort/merchants/lib/api/Users';
import {getCustomComponent} from '@cohort/merchants/lib/CustomComponents';
import EditableField from '@cohort/merchants/pages/users/user/overview/EditableField';
import {notifyError} from '@cohort/merchants/stores/ErrorModalStore';
import {CohortEngagementScoreUserPropertySpec} from '@cohort/shared/apps/cohort/userProperties/engagementScore';
import type {WebappLanguage} from '@cohort/shared/schema/common';
import {DateSchema} from '@cohort/shared/schema/common';
import type {UserTDto} from '@cohort/shared/schema/templating/common';
import {formatDate} from '@cohort/shared/utils/format';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import {makeUserContext} from '@cohort/shared/utils/templating';
import {zodResolver} from '@hookform/resolvers/zod';
import {ArrowsClockwise, At, Cube, Gauge, Gift, TrendUp, UserPlus} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import type {TFunction} from 'i18next';
import i18n from 'i18next';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

export const UpdateUserFormSchema = z.object({
  subscriptionDate: DateSchema,
});
export type UpdateUserForm = z.infer<typeof UpdateUserFormSchema>;

type DetailRowProps = {
  icon: React.ReactNode;
  label: string;
  children: React.ReactNode;
  action?: React.ReactNode;
};

const DetailRow: React.FC<DetailRowProps> = ({icon, label, children, action}) => (
  <div className="flex h-20 items-center space-x-4 rounded-lg border border-slate-200 p-4">
    {icon}
    <div className="flex flex-col justify-center space-y-2">
      <h3 className="text-sm text-slate-500">{label}</h3>
      <div className="flex w-full items-center justify-between">
        <div className="text-sm font-medium">{children}</div>
        {action}
      </div>
    </div>
  </div>
);

type EmailDetailRowProps = {
  email: string;
  t: TFunction;
};

const EmailDetailRow: React.FC<EmailDetailRowProps> = ({email, t}) => (
  <DetailRow
    icon={<At className="h-10 w-10 rounded-md bg-slate-100 p-2 text-slate-500" />}
    label={t('labelEmail')}
  >
    {email}
  </DetailRow>
);

type LastActiveAtDetailRowProps = {
  lastActiveAt: Date;
  t: TFunction;
};

const LastActiveAtDetailRow: React.FC<LastActiveAtDetailRowProps> = ({lastActiveAt, t}) => (
  <DetailRow
    icon={<ArrowsClockwise className="h-10 w-10 rounded-md bg-slate-100 p-2 text-slate-500" />}
    label={t('labelLastActiveAt')}
  >
    {formatDate(lastActiveAt)}
  </DetailRow>
);

type CompletedJourneysDetailRowProps = {
  countCompletedJourneys: number;
  countJourneys: number;
  t: TFunction;
};

const CompletedJourneysDetailRow: React.FC<CompletedJourneysDetailRowProps> = ({
  countCompletedJourneys,
  countJourneys,
  t,
}) => (
  <DetailRow
    icon={<TrendUp className="h-10 w-10 rounded-md bg-slate-100 p-2 text-slate-500" />}
    label={t('labelCompletedJouneys')}
  >
    {`${countCompletedJourneys}/${countJourneys}`}
  </DetailRow>
);

type UsedPerksDetailRowProps = {
  countUsedPerks: number;
  countPerks: number;
  t: TFunction;
};

const UsedPerksDetailRow: React.FC<UsedPerksDetailRowProps> = ({countUsedPerks, countPerks, t}) => (
  <DetailRow
    icon={<Gift className="h-10 w-10 rounded-md bg-slate-100 p-2 text-slate-500" />}
    label={t('labelUsedPerks')}
  >
    {`${countUsedPerks}/${countPerks}`}
  </DetailRow>
);

type EngagementScoreRowProps = {
  user: UserAnalyticsADto;
  t: TFunction;
};

const EngagementScoreRow: React.FC<EngagementScoreRowProps> = ({user, t}) => {
  const merchant = useCurrentMerchant();

  const {data: userAttributes, isLoading: isAttributesLoading} = useUserAttributes(merchant.id, {
    userId: user.id,
    userPropertyReferenceIds: [CohortEngagementScoreUserPropertySpec.id],
  });
  const engagementScore = (userAttributes?.[0]?.value as number | null) ?? null;

  return (
    <div className="flex h-20 items-center rounded-lg border border-slate-200 p-4">
      <Gauge className="mr-4 h-10 w-10 rounded-md bg-slate-100 p-2 text-slate-500" />
      <div className="flex flex-col justify-center space-y-2">
        <div className="flex flex-row space-x-1">
          <h3 className="text-sm text-slate-500">{t('labelEngagementScore')}</h3>
          <InformationTooltip
            content={<div className="w-[304px]">{t('tooltipEngagementScore')}</div>}
          />
        </div>
        <div className="flex w-full items-center justify-between">
          <div className="text-sm font-medium">{t('descriptionEngagementScore')}</div>
        </div>
      </div>
      {!isAttributesLoading ? (
        <div className="ml-auto">
          <EngagementScoreGauge width={120} value={engagementScore} />
        </div>
      ) : (
        <div className="ml-auto pr-10">
          <Loader size={30} color="gray" />
        </div>
      )}
    </div>
  );
};

type SubscriptionDetailRowProps = {
  user: UserAnalyticsADto;
  t: TFunction;
};

const SubscriptionDetailRow: React.FC<SubscriptionDetailRowProps> = ({user, t}) => {
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();

  const {register, handleSubmit, control, reset} = useForm<UpdateUserForm>({
    resolver: zodResolver(UpdateUserFormSchema),
    defaultValues: {
      subscriptionDate: user.joinedAt,
    },
  });

  const {mutate: updateUserMutation} = useCohortMutation({
    mutationFn: async (data: UpdateUserDataADto) => updateUser(merchant.id, user.id, data),
    notifySuccessMessage: t('notificationSuccess'),
    onSuccess: async () => {
      reset({}, {keepValues: true});
      await queryClient.invalidateQueries(usersKeys.users);
    },
    onError: err => notifyError(err, t('notificationError')),
  });

  return (
    <DetailRow
      icon={<UserPlus className="h-10 w-10 rounded-md bg-slate-100 p-2 text-slate-500" />}
      label={t('labelJoinedAt')}
    >
      <EditableField
        dataType="date"
        control={control}
        register={register}
        name="subscriptionDate"
        defaultValue={user.joinedAt}
        key={user.joinedAt.toString()}
        onSubmit={() => handleSubmit(data => updateUserMutation({membershipPass: data}))()}
        userAttributeClassName="font-medium"
      />
    </DetailRow>
  );
};

type DigitalAssetsDetailRowProps = {
  countDigitalAssets: number;
  t: TFunction;
};

const DigitalAssetsDetailRow: React.FC<DigitalAssetsDetailRowProps> = ({countDigitalAssets, t}) => (
  <DetailRow
    icon={<Cube className="h-10 w-10 rounded-md bg-slate-100 p-2 text-slate-500" />}
    label={t('labelDigitalAssets')}
  >
    {`${countDigitalAssets}`}
  </DetailRow>
);

const UserInformationSection = (): JSX.Element => {
  const user = useCurrentUser();
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {keyPrefix: 'users.user.overview.userInformationSection'});
  const selectedLanguage = i18n.language as WebappLanguage;
  const {data: components} = useCustomComponents(merchant.id);
  const membershipPassCustomComponent = getCustomComponent(components, 'membership-pass', true);

  const getMembershipPassBannerUrl = (): string | null => {
    if (merchant.membershipPassConfiguration.bannerFileKey) {
      return getImageUrl(
        import.meta.env.COHORT_ENV,
        merchant.membershipPassConfiguration.bannerFileKey,
        {
          h: Sizes.S,
          w: Sizes.S,
        }
      );
    } else if (merchant.logoFileKey) {
      return getImageUrl(import.meta.env.COHORT_ENV, merchant.logoFileKey, {
        h: Sizes.S,
        w: Sizes.S,
      });
    }
    return null;
  };

  const membershipPass: MembershipPassCardProps['membershipPass'] = {
    title:
      merchant.membershipPassConfiguration.title[selectedLanguage] ??
      t('defaultTitle', {merchantName: merchant.name}),
    imageUrl: getMembershipPassBannerUrl(),
    memberHandle: user.membershipPassHandle,
    subscriptionDate: user.joinedAt,
    memberId: user.membershipPassMemberId,
    blockExplorerUrl: user.membershipPassBlockExplorerUrl,
  };
  const customComponentContext = {
    user: makeUserContext(merchant, {
      id: user.id,
      email: user.email,
      membershipPass: {
        memberHandle: user.membershipPassHandle,
        memberId: user.membershipPassMemberId,
        subscriptionDate: user.joinedAt,
      },
      wallet: {
        address: '0x34e9A5DBc7B231bE41CC711499A68ef85a115933',
      },
    }) as UserTDto,
    lang: selectedLanguage,
    isMobile: false,
  };

  return (
    <div className="flex w-full space-x-6">
      <div className="flex flex-grow flex-col space-y-6">
        <div className="grid flex-grow grid-cols-2 gap-6">
          <EmailDetailRow email={user.email} t={t} />
          <EngagementScoreRow user={user} t={t} />
          <LastActiveAtDetailRow lastActiveAt={user.lastActiveAt} t={t} />
          <SubscriptionDetailRow user={user} t={t} />
        </div>
        <div className="grid flex-grow grid-cols-3 gap-6">
          <CompletedJourneysDetailRow
            countCompletedJourneys={user.journeysCompletedCount}
            countJourneys={user.journeysCount}
            t={t}
          />
          <UsedPerksDetailRow
            countUsedPerks={user.perkUsagesCount}
            countPerks={user.perksCount}
            t={t}
          />
          <DigitalAssetsDetailRow countDigitalAssets={user.ownershipsCount} t={t} />
        </div>
      </div>
      <div id="preview" className="w-[300px] rounded-xl p-4">
        <MembershipPassCard
          membershipPass={membershipPass}
          selectedLanguage={selectedLanguage}
          customComponent={
            membershipPassCustomComponent && membershipPassCustomComponent.template
              ? {
                  context: customComponentContext,
                  template: membershipPassCustomComponent.template,
                }
              : undefined
          }
        />
      </div>
    </div>
  );
};

export default UserInformationSection;
