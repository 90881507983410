import type {CustomTermsADto} from '@cohort/admin-schemas/customTerms';
import Button from '@cohort/merchants/components/buttons/Button';
import Input from '@cohort/merchants/components/form/input/Input';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {customTermsKeys} from '@cohort/merchants/hooks/api/CustomTerms';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {notify} from '@cohort/merchants/hooks/toast';
import useTranslateLanguage from '@cohort/merchants/hooks/useTranslateLanguage';
import {postCustomTerms} from '@cohort/merchants/lib/api/CutomTerms';
import {
  changeSelectedLanguageIfNeeded,
  sortLanguages,
} from '@cohort/merchants/lib/form/localization';
import {trackBrandSettingsSaveButtonClicked} from '@cohort/merchants/lib/Tracking';
import LinkSection from '@cohort/merchants/pages/settings/integrations/LinkSection';
import type {WebappLanguage} from '@cohort/shared/schema/common';
import {WebappLanguageSchema} from '@cohort/shared/schema/common';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import type {Control, UseFormRegister} from 'react-hook-form';
import {useForm, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

const removeUndefinedOrEmptyKeys = (
  customTerms: Partial<Record<WebappLanguage, Record<string, string>>>,
  definedLanguages: Array<WebappLanguage>
): Partial<Record<WebappLanguage, Record<string, string>>> => {
  for (const language of Object.keys(WebappLanguageSchema.Enum) as Array<WebappLanguage>) {
    if (customTerms[language] === undefined) {
      continue;
    }
    for (const [key, value] of Object.entries(customTerms[language] ?? {})) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (value === undefined || value === '') {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-dynamic-delete
        delete customTerms[language]![key];
      }
    }
    if (!definedLanguages.includes(language)) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete customTerms[language];
    }
  }
  return customTerms;
};

type TermInputsProps = {
  selectedLanguage: WebappLanguage;
  control: Control<CustomTermsFormValues>;
  register: UseFormRegister<CustomTermsFormValues>;
};

const TermInputs: React.FC<TermInputsProps> = ({selectedLanguage, register, control}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.brand.customTermsForm',
  });
  const {t: selectedLanguageTranslation} = useTranslation('pages', {
    keyPrefix: 'settings.brand.customTermsForm',
    lng: selectedLanguage,
  });
  const merchant = useCurrentMerchant();
  // i18nOwl-ignore [labelBadges, labelExperienceSpace, labelHome, labelDigitalAssets, labelPerks, labelJourneys, labelRewards]
  return (
    <div className="grid grid-cols-2 gap-6">
      <Input
        label={selectedLanguageTranslation('labelExperienceSpace', {brand: merchant.name})}
        description={t('descriptionExperienceSpace')}
        type="text"
        name={`customTerms.${selectedLanguage}.experienceSpace`}
        register={register}
        control={control}
        placeholder={selectedLanguageTranslation('labelExperienceSpace', {brand: merchant.name})}
      />
      <Input
        label={selectedLanguageTranslation('labelHome')}
        description={t('descriptionHome')}
        type="text"
        name={`customTerms.${selectedLanguage}.home`}
        register={register}
        control={control}
        placeholder={selectedLanguageTranslation('labelHome')}
      />
      <Input
        label={selectedLanguageTranslation('labelRewards')}
        description={t('descriptionRewards')}
        type="text"
        name={`customTerms.${selectedLanguage}.rewards`}
        register={register}
        control={control}
        placeholder={selectedLanguageTranslation('labelRewards')}
      />
      <Input
        label={selectedLanguageTranslation('labelDigitalAssets')}
        description={t('descriptionDigitalAssets')}
        type="text"
        name={`customTerms.${selectedLanguage}.digitalAssets`}
        register={register}
        control={control}
        placeholder={selectedLanguageTranslation('labelDigitalAssets')}
      />
      <Input
        label={selectedLanguageTranslation('labelPerks')}
        description={t('descriptionPerks')}
        type="text"
        name={`customTerms.${selectedLanguage}.perks`}
        register={register}
        control={control}
        placeholder={selectedLanguageTranslation('labelPerks')}
      />
      <Input
        label={selectedLanguageTranslation('labelJourneys')}
        description={t('descriptionJourneys')}
        type="text"
        name={`customTerms.${selectedLanguage}.journeys`}
        register={register}
        control={control}
        placeholder={selectedLanguageTranslation('labelJourneys')}
      />
      <Input
        label={selectedLanguageTranslation('labelBadges')}
        description={t('descriptionBadges')}
        type="text"
        name={`customTerms.${selectedLanguage}.badges`}
        register={register}
        control={control}
        placeholder={selectedLanguageTranslation('labelBadges')}
      />
    </div>
  );
};

const CustomTermsFormSchema = z
  .object({
    selectedLanguage: WebappLanguageSchema,
    definedLanguages: z.array(WebappLanguageSchema),
    customTerms: z.record(WebappLanguageSchema, z.record(z.string(), z.string())),
  })
  .transform(data => {
    data.customTerms = removeUndefinedOrEmptyKeys(data.customTerms, data.definedLanguages);
    return data;
  });

export type CustomTermsFormValues = z.infer<typeof CustomTermsFormSchema>;

type CustomTermsFormProps = {
  customTerms: CustomTermsADto;
};

const CustomTermsForm: React.FC<CustomTermsFormProps> = ({customTerms}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.brand.customTermsForm',
  });
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();
  const getLanguageTranslation = useTranslateLanguage();

  const webappLanguages = sortLanguages(
    Object.keys(WebappLanguageSchema.Values) as WebappLanguage[],
    getLanguageTranslation,
    merchant.defaultLanguage
  );

  const {register, control, handleSubmit, formState, reset, setValue} =
    useForm<CustomTermsFormValues>({
      defaultValues: {
        selectedLanguage: merchant.defaultLanguage,
        definedLanguages: webappLanguages as WebappLanguage[],
        customTerms: customTerms.data,
      },
      resolver: zodResolver(CustomTermsFormSchema),
    });

  const selectedLanguage = useWatch({
    control,
    name: `selectedLanguage`,
  });

  const {isLoading, mutate: updateMerchant} = useCohortMutation({
    mutationFn: async (data: CustomTermsFormValues) => {
      await postCustomTerms(merchant.id, {data: data.customTerms});
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: async customTerms => {
      await queryClient.invalidateQueries(customTermsKeys.getByMerchantId(merchant.id));
      notify('success', t('notificationUpdateSuccess'));
      trackBrandSettingsSaveButtonClicked();
      reset({}, {keepValues: true});
    },
  });

  return (
    <form
      className="flex flex-col space-y-6"
      onSubmit={handleSubmit(
        async data => updateMerchant(data),
        errors =>
          changeSelectedLanguageIfNeeded(errors, merchant.defaultLanguage, language =>
            setValue('selectedLanguage', language)
          )
      )}
    >
      <LinkSection title={t('title')} description={t('subtitle')} />
      <HighlightText text={t('helperText')} type="info" />
      <LanguageSelectorInput
        control={control}
        definedLanguagesPath="definedLanguages"
        selectedLanguagePath="selectedLanguage"
        allowLanguagesManagement={false}
      />
      <TermInputs
        selectedLanguage={selectedLanguage}
        register={register}
        control={control}
        key={selectedLanguage}
      />
      <div className="flex justify-end space-x-2">
        <Button type="submit" loading={isLoading} disabled={!formState.isDirty} data-testid="save">
          {t('buttonSave')}
        </Button>
      </div>
    </form>
  );
};

export default CustomTermsForm;
