import type {CampaignADto} from '@cohort/admin-schemas/campaign';
import {DigitalAssetVisual} from '@cohort/merchants/components/DigitalAssetVisual';
import {PerkAppBadge} from '@cohort/merchants/components/perks/PerkAppBadge';
import {PerkTypeBadge} from '@cohort/merchants/components/perks/PerkTypeBadge';
import {PerkVisual} from '@cohort/merchants/components/PerkVisual';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

const RewardSectionContent: React.FC<{campaign: CampaignADto}> = ({campaign}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.review.rewardSectionContent',
  });
  const collections = campaign.rewardBundle.collections.map(
    collectionItem => collectionItem.collection
  );
  const perks = campaign.rewardBundle.perks.map(perkItem => perkItem.perk);

  return (
    <div className="flex flex-col gap-8">
      {collections.length > 0 && (
        <div className="flex flex-col gap-4">
          <div className="text-base font-semibold">{t('digitalAssets')}</div>
          <div className="grid auto-rows-fr items-center gap-6 [grid-template-columns:max-content_1fr]">
            {collections.map(collection => (
              <Fragment key={collection.id}>
                <DigitalAssetVisual
                  digitalAssetImageFileKey={collection.imageFileKey}
                  animationFileKey={collection.animationFileKey}
                  className="rounded-md"
                  size={48}
                />
                <div className="font-medium">{collection.internalName}</div>
              </Fragment>
            ))}
          </div>
        </div>
      )}
      {perks.length > 0 && (
        <div className="flex flex-col gap-4">
          <div className="text-base font-semibold">{t('perks')}</div>
          <div className="grid auto-rows-fr items-center gap-6 [grid-template-columns:max-content_max-content_1fr]">
            {perks.map(perk => (
              <Fragment key={perk.id}>
                <PerkVisual perk={perk} className="!h-12" />
                <div className="font-medium">{perk.internalName}</div>
                <div>
                  <PerkTypeBadge perkType={perk.type} />
                  <PerkAppBadge perkIntegrationId={perk.integration?.perkIntegrationId ?? null} />
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RewardSectionContent;
