import Spinner from '@cohort/shared-frontend/components/Spinner';

type CardOverlayProps = {
  children: React.ReactNode;
};

const CardOverlay: React.FC<CardOverlayProps> = ({children}) => (
  <div
    className="pointer-events-none absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center text-lg font-bold text-white"
    style={{
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }}
  >
    {children}
  </div>
);

const OwnedCardOverlay: React.FC<{text: string}> = ({text}) => (
  <CardOverlay>
    <p className="p-2 text-center text-white">{text}</p>
  </CardOverlay>
);

const LoadingCardOverlay: React.FC = () => (
  <CardOverlay>
    <Spinner color="#FFFFFF" />
  </CardOverlay>
);

export {OwnedCardOverlay, LoadingCardOverlay};
