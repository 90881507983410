import type {
  DigitalAssetAnalyticsADto,
  DigitalAssetOrderByADto,
} from '@cohort/admin-schemas/digitalAsset';
import DigitalAssetTokenIdBadge from '@cohort/merchants/components/digital-assets/DigitalAssetTokenIdBadge';
import ObtentionTypeBadge from '@cohort/merchants/components/ObtentionTypeBadge';
import UserReferenceComponent from '@cohort/merchants/components/references/UserReferenceComponent';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {formatDate} from '@cohort/shared/utils/format';
import {createColumnHelper} from '@tanstack/react-table';
import React, {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

type DigitalAssetCollectionOwnersTableProps = {
  sortBy: DigitalAssetOrderByADto;
  onSort: (key: string) => void;
  digitalAssets: Array<DigitalAssetAnalyticsADto>;
  isLoading: boolean;
};

const DigitalAssetCollectionOwnersTable: React.FC<DigitalAssetCollectionOwnersTableProps> = ({
  sortBy,
  onSort,
  digitalAssets,
  isLoading,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix:
      'digitalAssetCollections.digitalAssetCollection.owners.digitalAssetCollectionOwnersTable',
  });
  const columnHelper = createColumnHelper<DigitalAssetAnalyticsADto>();
  const columns = [
    columnHelper.display({
      header: t('tableHeaderOwner'),
      cell: data => (
        <Fragment>
          {data.row.original.user ? (
            <UserReferenceComponent user={data.row.original.user} />
          ) : (
            <span className="ml-4 font-semibold italic">{t('externalWallet')}</span>
          )}
        </Fragment>
      ),
      enableSorting: false,
    }),
    columnHelper.accessor(row => `#${row.tokenId}`, {
      header: t('tableHeaderTokenId'),
      cell: data => (
        <DigitalAssetTokenIdBadge tokenId={data.row.original.tokenId} nft={data.row.original.nft} />
      ),
      minSize: 200,
      enableSorting: false,
    }),
    columnHelper.accessor('ownership.obtentionType', {
      header: t('tableHeaderOrigin'),
      cell: data => (
        <ObtentionTypeBadge type={data.getValue()} campaign={data.row.original.campaign} />
      ),
      minSize: 250,
      enableSorting: false,
    }),
    columnHelper.accessor(
      row => (row.ownership.obtentionDate ? formatDate(row.ownership.obtentionDate) : '-'),
      {
        id: 'obtentionDate',
        header: t('tableHeaderObtentionDate'),
        minSize: 200,
      }
    ),
  ];

  const table = useCohortTable(
    {
      data: digitalAssets,
      columns,
    },
    {
      sortBy,
      onSortUpdate: onSort,
    }
  );

  return (
    <DataTable
      table={table}
      columnsLength={columns.length}
      isLoading={isLoading}
      emptyStatePlaceholder={t('emptyPlaceholder')}
    />
  );
};

export default DigitalAssetCollectionOwnersTable;
