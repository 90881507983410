import type {GetPerksParamsADto} from '@cohort/admin-schemas/perk';
import {usePerkIntegrations} from '@cohort/merchants/apps/usePerkIntegrations';
import type {FilterOption} from '@cohort/merchants/components/filters/Filters';
import Filters from '@cohort/merchants/components/filters/Filters';
import type {PerksListFilters} from '@cohort/merchants/hooks/filters/perksListFilters';
import {
  integrationFilter,
  statusFilter,
  typeFilter,
} from '@cohort/merchants/hooks/filters/perksListFilters';
import {usePerkUtils} from '@cohort/merchants/hooks/usePerkUtils';
import {
  trackPerksListTableAppsFilterChanged,
  trackPerksListTableStatusFilterChanged,
  trackPerksListTableTypesFilterChanged,
} from '@cohort/merchants/lib/Tracking';
import {Desktop, Tag} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

type PerksFiltersProps = {
  filters: PerksListFilters;
  hideStatusFilter?: boolean;
  short?: boolean;
};

const PerksFilters: React.FC<PerksFiltersProps> = ({filters, hideStatusFilter, short}) => {
  const {t} = useTranslation('components', {keyPrefix: 'perks.perksFilters'});
  const {getPerkTypeData} = usePerkUtils();
  const {getPerkIntegration, getPerkIntegrationTitle} = usePerkIntegrations();

  // i18nOwl-ignore [status.archived, status.draft, status.live]
  const statusFilterOptions = statusFilter.map(status => ({
    value: status,
    label: t(`status.${status}`),
    active: filters.status ? filters.status.includes(status) : false,
  }));

  const integrationFilterOptions = integrationFilter.map(integrationId => {
    return {
      value: integrationId,
      label: getPerkIntegrationTitle(getPerkIntegration(integrationId)),
      active: filters.integrations ? filters.integrations.includes(integrationId) : false,
    };
  });

  const typeFilterOptions = typeFilter.map(type => {
    return {
      value: type,
      label: getPerkTypeData(type).title,
      active: filters.types ? filters.types.includes(type) : false,
    };
  });

  return (
    <Filters
      filters={[
        ...(hideStatusFilter
          ? []
          : [
              {
                label: t('filterByStatus'),
                options: statusFilterOptions,
                type: 'multiple',
                short,
                onChange: (statusOptions: FilterOption[]) => {
                  const activeFilterOptions = statusOptions.filter(o => o.active);
                  trackPerksListTableStatusFilterChanged(activeFilterOptions.map(o => o.value));
                  filters.setStatus(
                    activeFilterOptions.map(o => o.value) as GetPerksParamsADto['status']
                  );
                },
              } as const,
            ]),
        {
          label: t('filterByApp'),
          options: integrationFilterOptions,
          type: 'multiple',
          short,
          icon: <Desktop className="mr-1.5 inline-block h-4 w-4" />,
          onChange: typeOptions => {
            const activeFilterOptions = typeOptions.filter(o => o.active);

            trackPerksListTableAppsFilterChanged(activeFilterOptions.map(o => o.value));
            filters.setIntegrations(
              activeFilterOptions.map(o => o.value) as GetPerksParamsADto['integration']
            );
          },
        },
        {
          label: t('filterByType'),
          options: typeFilterOptions,
          type: 'multiple',
          short,
          icon: <Tag className="mr-1.5 inline-block h-4 w-4" />,
          onChange: typeOptions => {
            const activeFilterOptions = typeOptions.filter(o => o.active);
            trackPerksListTableTypesFilterChanged(activeFilterOptions.map(o => o.value));
            filters.setTypes(activeFilterOptions.map(o => o.value) as GetPerksParamsADto['type']);
          },
        },
      ]}
    />
  );
};
export default PerksFilters;
