/* eslint-disable @typescript-eslint/naming-convention */
import type {WrappedField} from '@cohort/merchants/components/form/FieldWrapper';
import FieldWrapper from '@cohort/merchants/components/form/FieldWrapper';
import type {SelectOption} from '@cohort/merchants/components/form/select/SelectPicker';
import {defaultStyle, getTheme} from '@cohort/merchants/lib/form/select';
import type {FormField} from '@cohort/merchants/lib/form/utils';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Info} from '@phosphor-icons/react';
import {X} from '@phosphor-icons/react/dist/ssr';
import type {DetailedHTMLProps, HTMLAttributes, KeyboardEventHandler} from 'react';
import {useState} from 'react';
import type {FieldValues} from 'react-hook-form';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import CreatableSelect from 'react-select/creatable';

type ListInputProps<T extends FieldValues> = WrappedField &
  FormField<T> & {
    lowerCaseItems?: boolean;
  };

type ClearComponentProps = {
  innerProps: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  className?: string;
};

const ClearComponent: React.FC<ClearComponentProps> = ({innerProps, className}) => (
  <span
    {...innerProps}
    className={cn(
      'flex cursor-pointer items-center text-slate-400 hover:text-slate-500',
      className
    )}
  >
    <X size={14} weight="bold" />
  </span>
);

export default function ListInput<T extends FieldValues>({
  control,
  name,
  label,
  labelPosition,
  description,
  lowerCaseItems = false,
}: ListInputProps<T>): JSX.Element {
  const {t} = useTranslation('components', {keyPrefix: 'form.v2.listInput'});
  const [inputValue, setInputValue] = useState('');
  const {field, fieldState} = useController({
    control,
    name,
  });

  const handleKeyDown: KeyboardEventHandler = event => {
    if (!inputValue) {
      return;
    }
    switch (event.key) {
      case 'Enter':
      case 'Tab': {
        const currentValue: Array<string> = Array.isArray(field.value) ? field.value : [];
        const newValue = lowerCaseItems ? inputValue.trim().toLowerCase() : inputValue.trim();
        const existingOption = currentValue.find(option => option === newValue);

        if (!existingOption) {
          field.onChange([...currentValue, newValue]);
        }
        setInputValue('');
        event.preventDefault();
      }
    }
  };

  return (
    <FieldWrapper
      label={label}
      labelPosition={labelPosition}
      name={name}
      description={description}
      error={fieldState.error?.message}
    >
      <div>
        <CreatableSelect
          id="creatable-select"
          isMulti
          theme={getTheme}
          styles={defaultStyle}
          components={{
            DropdownIndicator: null,
            MultiValueRemove: ({innerProps}) => (
              <ClearComponent innerProps={innerProps} className="p-1 pl-0" />
            ),
            ClearIndicator: ({innerProps}) => (
              <ClearComponent innerProps={innerProps} className="mr-2" />
            ),
          }}
          menuIsOpen={false}
          onInputChange={newValue => setInputValue(newValue)}
          onChange={newValue =>
            field.onChange(newValue.map((option: SelectOption) => option.value))
          }
          onKeyDown={handleKeyDown}
          placeholder={t('placeholder')}
          inputValue={inputValue}
          value={field.value?.map((option: string) => ({label: option, value: option})) ?? []}
        />
        <p className="mt-2 flex items-center text-xs text-slate-500">
          <Info size={16} className="mr-1" />
          {t('hint')}
        </p>
      </div>
    </FieldWrapper>
  );
}
