import Button from '@cohort/merchants/components/buttons/Button';
import Form from '@cohort/merchants/components/form/Form';
import Input from '@cohort/merchants/components/form/input/Input';
import {Dialog, DialogContent, DialogTitle} from '@cohort/merchants/components/modals/Dialog';
import {campaignsKeys} from '@cohort/merchants/hooks/api/Campaigns';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {createCampaign} from '@cohort/merchants/lib/api/Campaigns';
import {getCampaignEditRoute} from '@cohort/merchants/lib/Pages';
import {trackCampaignCreateSuccess} from '@cohort/merchants/lib/Tracking';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {z} from 'zod';

interface CreateCampaignModalProps {
  onClose: () => void;
}

export const CampaignInternalNameFormSchema = z.object({
  internalName: z.string().min(3, {message: 'errorTooShort3'}),
});

type CampaignInternalNameFormValues = z.infer<typeof CampaignInternalNameFormSchema>;

const CreateCampaignModal: React.FC<CreateCampaignModalProps> = ({onClose}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {t} = useTranslation('pages', {keyPrefix: 'campaigns.overview.createCampaignModal'});

  const {isLoading, mutate: createCampaignMutation} = useCohortMutation({
    mutationFn: async (data: CampaignInternalNameFormValues) => createCampaign(merchantId, data),
    onSuccess: async newCampaign => {
      await queryClient.invalidateQueries(campaignsKeys.list(merchantId));
      trackCampaignCreateSuccess(newCampaign);
      navigate(getCampaignEditRoute(newCampaign.id).path);
    },
  });

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent displayCloseButton={false}>
        <Form
          className="p-6"
          testId="create-campaign-modal"
          formSchema={CampaignInternalNameFormSchema}
          defaultValues={{
            internalName: t('placeholderInternalName'),
          }}
          isSubmitting={isLoading}
          onSubmit={data => createCampaignMutation(data)}
          formActions={[
            <Button className="h-10 w-full" type="submit" disabled={false}>
              {t('buttonCreate')}
            </Button>,
          ]}
        >
          {({register, control}) => (
            <Fragment>
              <div className="flex flex-col justify-center pb-4 text-center">
                <DialogTitle className="text-lg">{t('create')}</DialogTitle>
              </div>
              <div className="mb-6 mt-4">
                <Input
                  type="text"
                  name="internalName"
                  register={register}
                  control={control}
                  description={t('contextInternalName')}
                />
              </div>
            </Fragment>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateCampaignModal;
