import type {ConnectionADto} from '@cohort/admin-schemas/connection';
import {ConnectionNameSchema} from '@cohort/admin-schemas/connection';
import {Badge} from '@cohort/merchants/components/Badge';
import Button from '@cohort/merchants/components/buttons/Button';
import Editabletext from '@cohort/merchants/components/form/Editabletext';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {connectionsKeys} from '@cohort/merchants/hooks/api/Connections';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {deleteConnection, patchConnection} from '@cohort/merchants/lib/api/Connections';
import EditConnectionModal from '@cohort/merchants/pages/connections/new/EditConnectionModal';
import {notifyError} from '@cohort/merchants/stores/ErrorModalStore';
import type {AppSpec} from '@cohort/shared/apps/app';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import {formatDate} from '@cohort/shared/utils/format';
import ConnectionErrorBadge from '@cohort/shared-frontend/components/ConnectionErrorBadge';
import {Trash} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {createColumnHelper} from '@tanstack/react-table';
import {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

type ConnectionsTableProps = {
  appSpec: AppSpec;
  merchantConnections: ConnectionADto[];
  isConnectionsLoading: boolean;
};

const ConnectionsTable: React.FC<ConnectionsTableProps> = ({
  appSpec,
  merchantConnections,
  isConnectionsLoading,
}) => {
  const [brokenConnection, setBrokenConnection] = useState<ConnectionADto | null>(null);
  const [connectionToConfigure, setConnectionToConfigure] = useState<ConnectionADto | null>(null);
  const [connectionToDelete, setConnectionToDelete] = useState<ConnectionADto | null>(null);
  const {t} = useTranslation('pages', {keyPrefix: 'apps.app.connections.connectionsTable'});
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();
  const columnHelper = createColumnHelper<ConnectionADto>();

  const [queryParameters] = useSearchParams();
  const connectionIdToConfigure = queryParameters.get('connectionIdToConfigure');

  useEffect(() => {
    if (connectionIdToConfigure) {
      const connection = merchantConnections.find(
        connection => connection.id === connectionIdToConfigure
      );
      if (connection && connection.status === 'config_needed') {
        setConnectionToConfigure(connection);
      }
    }
  }, [connectionIdToConfigure, merchantConnections]);

  const columns = [
    columnHelper.accessor('name', {
      header: t('tableHeaderName'),
      cell: data => {
        const connection = data.row.original;
        return (
          <div className="flex w-fit space-x-3 font-medium text-foreground">
            <Editabletext
              value={data.getValue()}
              textSize="small"
              key={connection.status} // @Devs, Auto update the component when the connection status changes.
              onChange={(name: string) =>
                updateConnectionName({
                  connectionId: connection.id,
                  name,
                })
              }
              validator={(connectionName: string) =>
                ConnectionNameSchema.safeParse(connectionName).success
              }
            />
            {connection.status === 'broken' && (
              <div className="flex items-center">
                <Badge
                  size="small"
                  text={
                    <ConnectionErrorBadge
                      label={t('connectionErrorLabel')}
                      iconSize="sm"
                      textColor="text-red-800"
                    />
                  }
                  textColor="text-red-800"
                  backgroundColor="bg-red-100"
                />
              </div>
            )}
          </div>
        );
      },
      enableSorting: false,
    }),
    columnHelper.accessor(row => (row.status === 'ready' ? formatDate(row.createdAt) : '-'), {
      header: t('tableHeaderCreatedAt'),
      minSize: 200,
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => null,
      cell: data => {
        const connection = data.row.original;
        return (
          <div className="flex items-center justify-end gap-4">
            {connection.status === 'broken' && (
              <Button
                onClick={() => {
                  setBrokenConnection(connection);
                }}
              >
                {t('buttonReconnect')}
              </Button>
            )}
            {connection.status === 'config_needed' && (
              <Button onClick={() => setConnectionToConfigure(connection)}>
                {t('buttonConfigure')}
              </Button>
            )}
            <Button
              variant="secondary"
              size="icon"
              onClick={() => setConnectionToDelete(connection)}
            >
              <Trash size={20} className="text-red-400" />
            </Button>
          </div>
        );
      },
    }),
  ];

  const {mutate: updateConnectionName} = useCohortMutation({
    mutationFn: async (args: {connectionId: string; name: string}) =>
      patchConnection(merchant.id, args.connectionId, {name: args.name}),
    notifySuccessMessage: t('patchNotificationSuccess'),
    onSuccess: async () => {
      await queryClient.invalidateQueries(connectionsKeys.list(merchant.id));
    },
  });

  const {mutate: deleteMerchantConnection} = useCohortMutation({
    mutationFn: async (connectionId: string) => deleteConnection(merchant.id, connectionId),
    notifySuccessMessage: t('deleteNotificationSuccess'),
    onSuccess: async () => {
      await queryClient.invalidateQueries(connectionsKeys.list(merchant.id));
    },
    onError: error => {
      if (isCohortError(error, 'connection.in-use')) {
        notifyError(error, t('deleteInUseErrorNotification'));
      } else {
        notifyError(error, t('deleteErrorNotification'));
      }
    },
  });
  const table = useCohortTable({
    // Show broken connections first.
    data: merchantConnections.sort(a => (a.status === 'broken' ? -1 : 1)),
    columns,
  });

  return (
    <Fragment>
      <DataTable
        table={table}
        columnsLength={columns.length}
        emptyStatePlaceholder={t('emptyPlaceholder')}
        isLoading={isConnectionsLoading}
      />
      {connectionToDelete !== null && (
        <DeletionModal
          show
          title={t('modalTitle')}
          subtitle={t('modalText', {connectionName: connectionToDelete.name})}
          onClose={() => setConnectionToDelete(null)}
          onDelete={() => {
            deleteMerchantConnection(connectionToDelete.id);
            setConnectionToDelete(null);
          }}
        />
      )}
      {connectionToConfigure && (
        <EditConnectionModal
          onClose={() => setConnectionToConfigure(null)}
          appId={appSpec.id}
          connectionToEdit={connectionToConfigure}
        />
      )}
      {brokenConnection !== null && (
        <EditConnectionModal
          onClose={() => setBrokenConnection(null)}
          appId={appSpec.id}
          connectionToEdit={brokenConnection}
        />
      )}
    </Fragment>
  );
};
export default ConnectionsTable;
