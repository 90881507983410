import type {GetUserAnalyticsOrderByParamADto} from '@cohort/admin-schemas/userAnalytics';
import type {PaginationFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import {useBasePageFilters} from '@cohort/merchants/hooks/filters/basePageFilters';

type UsersListFilters = {
  orderBy: GetUserAnalyticsOrderByParamADto;
  setOrderBy: (orderBy: GetUserAnalyticsOrderByParamADto) => void;
  search: string;
  setSearch: (search: string) => void;
  reset: () => void;
};

export const useUsersListFilters = (): PaginationFilters & UsersListFilters =>
  useBasePageFilters<UsersListFilters>(/^(\/test)?\/users$/u, set => ({
    orderBy: '-lastActiveAt',
    setOrderBy: orderBy => set({orderBy}),
    search: '',
    setSearch: search => set({search, page: 1}),
    reset: () =>
      set({
        page: 1,
        orderBy: '-lastActiveAt',
        search: '',
      }),
  }));
