import Button from '@cohort/merchants/components/buttons/Button';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {getCampaignAudienceEditRoute} from '@cohort/merchants/lib/Pages';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

const CampaignAudience = (): JSX.Element => {
  const campaign = useCurrentCampaign();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.audience.campaignAudience',
  });

  // i18nOwl-ignore [audienceDescription.cohort, audienceDescription.everyone, audienceDescription.invitees, audienceDescription.airdrop.everyone, audienceDescription.airdrop.cohort]
  // i18nOwl-ignore [audienceLabel.cohort, audienceLabel.everyone, audienceLabel.invitees]
  return (
    <div className="flex items-center justify-between rounded-xl bg-gray-50 p-4">
      <div className="flex items-center gap-4">
        <span className="inline-block font-semibold text-gray-900">
          {t(`audienceLabel.${campaign.audience}`)}
        </span>
        <span className="inline-block text-sm text-gray-500">
          {campaign.type === 'airdrop'
            ? t(`audienceDescription.airdrop.${campaign.audience}`)
            : t(`audienceDescription.${campaign.audience}`)}
        </span>
      </div>
      <Link to={getCampaignAudienceEditRoute(campaign.id).path}>
        <Button>{t('changeAudienceBtn')}</Button>
      </Link>
    </div>
  );
};

export default CampaignAudience;
