import type {PerkAccessADto, PerkUsageADto} from '@cohort/admin-schemas/perkAccesses';
import Button from '@cohort/merchants/components/buttons/Button';
import ObtentionTypeBadge from '@cohort/merchants/components/ObtentionTypeBadge';
import PerkAccessStatusBadge from '@cohort/merchants/components/perks/PerkAccessStatusBadge';
import PerkUsageDetailsModal from '@cohort/merchants/components/perks/PerkUsageDetailsModal';
import PerkReferenceComponent from '@cohort/merchants/components/references/PerkReferenceComponent';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import type {PerkAccessesFilters} from '@cohort/merchants/hooks/filters/perkAccessesFilters';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {formatDate} from '@cohort/shared/utils/format';
import {ListMagnifyingGlass} from '@phosphor-icons/react';
import {createColumnHelper} from '@tanstack/react-table';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

const UserPerksTable: React.FC<{
  filters: PerkAccessesFilters;
  isLoading: boolean;
  perkAccesses: PerkAccessADto[];
}> = ({filters, isLoading, perkAccesses}) => {
  const [perkAnalyticsUsagesToShow, setPerkAnalyticsUsagesToShow] =
    useState<Array<PerkUsageADto> | null>(null);
  const {t} = useTranslation('pages', {keyPrefix: 'users.user.perks.userPerksTable'});
  const columnHelper = createColumnHelper<PerkAccessADto>();
  const columns = [
    columnHelper.display({
      header: t('tableHeaderPerk'),
      cell: data => (
        <PerkReferenceComponent
          internalName={data.row.original.perk.internalName}
          perkId={data.row.original.perk.id}
          perkType={data.row.original.perk.type}
          perkIntegrationId={data.row.original.perk.integration?.perkIntegrationId ?? null}
        />
      ),
      minSize: 300,
      enableSorting: false,
    }),
    columnHelper.display({
      header: t('tableHeaderObtentionType'),
      cell: data => (
        <ObtentionTypeBadge
          type={data.row.original.obtentionType}
          campaign={data.row.original.campaign}
        />
      ),
      minSize: 200,
    }),
    columnHelper.accessor(row => formatDate(row.createdAt), {
      id: 'createdAt',
      header: t('tableHeaderAvailableSince'),
      minSize: 200,
    }),
    columnHelper.accessor('status', {
      header: t('tableHeaderStatus'),
      cell: data => <PerkAccessStatusBadge perkAccess={data.row.original} />,
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'usages',
      header: () => null,
      cell: data => {
        if (data.row.original.usages.length > 0) {
          return (
            <div className="text-right">
              <Button
                onClick={() => setPerkAnalyticsUsagesToShow(data.row.original.usages)}
                variant="secondary"
                size="sm"
              >
                <ListMagnifyingGlass size={20} className="mr-1" />
                {t('buttonShowDetails')}
              </Button>
            </div>
          );
        }
        return null;
      },
    }),
  ];
  const table = useCohortTable(
    {
      columns,
      data: perkAccesses,
    },
    {
      sortBy: filters.sort,
      onSortUpdate: filters.setSort,
    }
  );

  return (
    <div>
      <DataTable
        table={table}
        columnsLength={columns.length}
        isLoading={isLoading}
        emptyStatePlaceholder={t('emptyPlaceholder')}
      />
      {perkAnalyticsUsagesToShow !== null && (
        <PerkUsageDetailsModal
          onClose={() => setPerkAnalyticsUsagesToShow(null)}
          title={t('perkUsageDetailsModalTitle')}
          perkAnalyticsUsages={perkAnalyticsUsagesToShow}
        />
      )}
    </div>
  );
};
export default UserPerksTable;
