import type {WrappedField} from '@cohort/merchants/components/form/FieldWrapper';
import BaseTextarea from '@cohort/merchants/components/form/textarea/BaseTextarea';
import type {FormField} from '@cohort/merchants/lib/form/utils';
import type {FieldValues} from 'react-hook-form';
import {useController, useFormState} from 'react-hook-form';

type TextareaProps<T extends FieldValues> = JSX.IntrinsicElements['textarea'] &
  WrappedField &
  FormField<T>;

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function Textarea<T extends FieldValues>({
  register,
  control,
  label,
  description,
  rules,
  ...props
}: TextareaProps<T>): JSX.Element {
  const {errors} = useFormState({
    control,
    name: props.name,
  });

  const {field} = useController({
    control,
    name: props.name,
  });

  return (
    <BaseTextarea
      label={label}
      description={description}
      error={errors[props.name]?.message as string}
      {...props}
      {...register(props.name, rules)}
      value={field.value}
    />
  );
}
