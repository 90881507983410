import type {PerkIntegrationId} from '@cohort/shared/apps';
import {PerkIntegrationIdSchema} from '@cohort/shared/apps';
import type {PerkType} from '@cohort/shared/schema/common/perks';
import {
  Calendar,
  CallBell,
  ChartBar,
  ChatsCircle,
  CurrencyCircleDollar,
  Desktop,
  Gift,
  LockSimple,
  PencilSimple,
  ShieldCheck,
  ShoppingCart,
  Star,
  Ticket,
  Users,
  UsersThree,
  Waveform,
  Wrench,
} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

export interface PerkTypeData {
  type: PerkType;
  perkIntegrations: PerkIntegrationId[];
  title: string;
  icon: JSX.Element;
  backgroundColor: string;
  perkTitlePlaceholder: string;
  perkDescriptionPlaceholder: string;
  iconColor: string;
}

interface Output {
  getPerkTypeData: (perkType: PerkType) => PerkTypeData;
  perkTypesData: PerkTypeData[];
}

export const usePerkUtils = (): Output => {
  const {t} = useTranslation('hooks', {keyPrefix: 'usePerkUtils'});

  const perkTypesData: PerkTypeData[] = [
    {
      type: 'custom',
      perkIntegrations: PerkIntegrationIdSchema.options,
      title: t('typeCustomTitle'),
      icon: <PencilSimple className="h-6 w-6" />,
      backgroundColor: 'bg-slate-100',
      perkTitlePlaceholder: t('typeCustomTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeCustomDescriptionPlaceholder'),
      iconColor: 'text-slate-500',
    },
    {
      type: 'limited-edition',
      perkIntegrations: ['shopify.discount', 'cohort.form', 'typeform.form'],
      title: t('typeLimitedEditionTitle'),
      icon: <ShoppingCart className="h-6 w-6" />,
      backgroundColor: 'bg-teal-100',
      perkTitlePlaceholder: t('typeLimitedEditionTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeLimitedEditionDescriptionPlaceholder'),
      iconColor: 'text-teal-500',
    },
    {
      type: 'custom-product',
      perkIntegrations: ['shopify.discount', 'cohort.form', 'typeform.form'],
      title: t('typeCustomProductTitle'),
      icon: <Wrench className="h-6 w-6" />,
      backgroundColor: 'bg-indigo-100',
      perkTitlePlaceholder: t('typeCustomProductTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeCustomProductDescriptionPlaceholder'),
      iconColor: 'text-indigo-500',
    },
    {
      type: 'exclusive-content',
      perkIntegrations: [],
      title: t('typeExclusiveContentTitle'),
      icon: <Wrench className="h-6 w-6" />,
      backgroundColor: 'bg-indigo-100',
      perkTitlePlaceholder: t('typeExclusiveContentTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeExclusiveContentDescriptionPlaceholder'),
      iconColor: 'text-indigo-500',
    },
    {
      type: 'discount',
      perkIntegrations: ['cohort.form', 'typeform.form', 'shopify.discount', 'cohort.qr-code'],
      title: t('typeDiscountTitle'),
      icon: <CurrencyCircleDollar className="h-6 w-6" />,
      backgroundColor: 'bg-pink-100',
      perkTitlePlaceholder: t('typeDiscountTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeDiscountDescriptionPlaceholder'),
      iconColor: 'text-pink-500',
    },
    {
      type: 'pre-sale',
      perkIntegrations: ['shopify.discount', 'cohort.form', 'typeform.form'],
      title: t('typePreSaleTitle'),
      icon: <Calendar className="h-6 w-6" />,
      backgroundColor: 'bg-primary/10',
      perkTitlePlaceholder: t('typePreSaleTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typePreSaleDescriptionPlaceholder'),
      iconColor: 'text-primary',
    },
    {
      type: 'private-sale',
      perkIntegrations: ['shopify.discount', 'cohort.form', 'typeform.form'],
      title: t('typePrivateSaleTitle'),
      icon: <ShieldCheck className="h-6 w-6" />,
      backgroundColor: 'bg-orange-100',
      perkTitlePlaceholder: t('typePrivateSaleTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typePrivateSaleDescriptionPlaceholder'),
      iconColor: 'text-orange-500',
    },
    {
      type: 'giveaway',
      perkIntegrations: PerkIntegrationIdSchema.options,
      title: t('typeGiveawayTitle'),
      icon: <Gift className="h-6 w-6" />,
      backgroundColor: 'bg-lime-100',
      perkTitlePlaceholder: t('typeGiveawayTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeGiveawayDescriptionPlaceholder'),
      iconColor: 'text-lime-500',
    },
    {
      type: 'private-community',
      perkIntegrations: ['discord.private-access', 'cohort.qr-code'],
      title: t('typePrivateCommunityTitle'),
      icon: <LockSimple className="h-6 w-6" />,
      backgroundColor: 'bg-yellow-100',
      perkTitlePlaceholder: t('typePrivateCommunityTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typePrivateCommunityDescriptionPlaceholder'),
      iconColor: 'text-yellow-500',
    },
    {
      type: 'voting',
      perkIntegrations: ['cohort.form', 'typeform.form', 'discord.private-access'],
      title: t('typeVotingTitle'),
      icon: <ChartBar className="h-6 w-6" />,
      backgroundColor: 'bg-sky-100',
      perkTitlePlaceholder: t('typeVotingTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeVotingDescriptionPlaceholder'),
      iconColor: 'text-sky-500',
    },
    {
      type: 'survey',
      perkIntegrations: ['cohort.form', 'typeform.form', 'discord.private-access'],
      title: t('typeSurveyTitle'),
      icon: <ChatsCircle className="h-6 w-6" />,
      backgroundColor: 'bg-purple-100',
      perkTitlePlaceholder: t('typeSurveyTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeSurveyDescriptionPlaceholder'),
      iconColor: 'text-purple-500',
    },
    {
      type: 'event-ticket',
      perkIntegrations: ['cohort.form', 'typeform.form', 'cohort.qr-code'],
      title: t('typeEventTicketTitle'),
      icon: <Ticket className="h-6 w-6" />,
      backgroundColor: 'bg-amber-100',
      perkTitlePlaceholder: t('typeEventTicketTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeEventTicketDescriptionPlaceholder'),
      iconColor: 'text-amber-500',
    },
    {
      type: '1-1-meeting',
      perkIntegrations: ['cohort.form', 'typeform.form', 'discord.private-access'],
      title: t('type11MeetingTitle'),
      icon: <Users className="h-6 w-6" />,
      backgroundColor: 'bg-green-100',
      perkTitlePlaceholder: t('type11MeetingTitlePlaceholder'),
      perkDescriptionPlaceholder: t('type11MeetingDescriptionPlaceholder'),
      iconColor: 'text-green-500',
    },
    {
      type: 'webinar',
      perkIntegrations: ['discord.private-access'],
      title: t('typeWebinarTitle'),
      icon: <Waveform className="h-6 w-6" />,
      backgroundColor: 'bg-red-100',
      perkTitlePlaceholder: t('typeWebinarTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeWebinarDescriptionPlaceholder'),
      iconColor: 'text-red-500',
    },
    {
      type: 'livestream',
      perkIntegrations: ['discord.private-access'],
      title: t('typeLivestreamTitle'),
      icon: <Desktop className="h-6 w-6" />,
      backgroundColor: 'bg-emerald-100',
      perkTitlePlaceholder: t('typeLivestreamTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeLivestreamDescriptionPlaceholder'),
      iconColor: 'text-emerald-500',
    },
    {
      type: 'conciergerie',
      perkIntegrations: [],
      title: t('typeConciergerieTitle'),
      icon: <CallBell className="h-6 w-6" />,
      backgroundColor: 'bg-cyan-100',
      perkTitlePlaceholder: t('typeConciergerieTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeConciergerieDescriptionPlaceholder'),
      iconColor: 'text-cyan-500',
    },
    {
      type: 'VIP-support',
      perkIntegrations: [],
      title: t('typeVIPSupportTitle'),
      icon: <Star className="h-6 w-6" />,
      backgroundColor: 'bg-violet-100',
      perkTitlePlaceholder: t('typeVIPSupportTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeVIPSupportDescriptionPlaceholder'),
      iconColor: 'text-violet-500',
    },
    {
      type: 'community',
      perkIntegrations: ['discord.private-access', 'cohort.form', 'typeform.form'],
      title: t('typeCommunityTitle'),
      icon: <UsersThree className="h-6 w-6" />,
      backgroundColor: 'bg-rose-100',
      perkTitlePlaceholder: t('typeCommunityTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeCommunityDescriptionPlaceholder'),
      iconColor: 'text-rose-500',
    },
  ];

  const getPerkTypeData = (perkType: PerkType): PerkTypeData =>
    perkTypesData.find(p => p.type === perkType) as PerkTypeData;

  return {
    perkTypesData,
    getPerkTypeData,
  };
};
