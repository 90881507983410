import BreadCrumb from '@cohort/merchants/components/BreadCrumb';
import {CampaignStatusBadge} from '@cohort/merchants/components/campaigns/CampaignStatusBadge';
import CampaignTypeBadge from '@cohort/merchants/components/campaigns/CampaignTypeBadge';
import CampaignVisual from '@cohort/merchants/components/CampaignVisual';
import {campaignsKeys} from '@cohort/merchants/hooks/api/Campaigns';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {patchCampaign} from '@cohort/merchants/lib/api/Campaigns';
import {trackBreadcrumbBackButtonClicked} from '@cohort/merchants/lib/Tracking';
import CampaignDropdown from '@cohort/merchants/pages/campaigns/overview/CampaignDropdown';
import {CampaignInternalNameFormSchema} from '@cohort/merchants/pages/campaigns/overview/CreateCampaignModal';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

const CampaignPageBreadcrumb = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {t} = useTranslation('pages', {keyPrefix: 'campaigns.campaign.campaignPageBreadcrumb'});
  const campaign = useCurrentCampaign();
  const queryClient = useQueryClient();

  const {mutate: updateCampaignInternalName} = useCohortMutation({
    mutationFn: async (name: string) =>
      patchCampaign(merchantId, campaign.id, {internalName: name}),
    notifySuccessMessage: t('notificationSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(campaignsKeys.list(merchantId));
      queryClient.invalidateQueries(campaignsKeys.getById(merchantId, campaign.id));
    },
  });

  const visual = <CampaignVisual campaign={campaign} className="rounded-md border" size={48} />;

  return (
    <Fragment>
      <BreadCrumb
        title={campaign.internalName}
        handleTitleChange={(name: string) => updateCampaignInternalName(name)}
        validator={(internalName: string) =>
          CampaignInternalNameFormSchema.safeParse({internalName}).success
        }
        onBackClick={() => {
          trackBreadcrumbBackButtonClicked('campaign', campaign);
        }}
        badges={[
          campaign.type ? (
            <CampaignTypeBadge key="type" type={campaign.type} />
          ) : (
            <Fragment key="type" />
          ),
          <CampaignStatusBadge key="status" size="small" status={campaign.status} />,
        ]}
        image={campaign.status !== 'draft' ? visual : undefined}
      >
        <div className="ml-4 flex items-center gap-4">
          <CampaignDropdown campaign={campaign} />
        </div>
      </BreadCrumb>
    </Fragment>
  );
};

export default CampaignPageBreadcrumb;
