import {useCurrentPerk} from '@cohort/merchants/hooks/contexts/currentPerk';
import {usePerkPageStore} from '@cohort/merchants/hooks/stores/perkPage';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import {getPerksRoute} from '@cohort/merchants/lib/Pages';
import {CurrentPerkProvider} from '@cohort/merchants/pages/perks/perk/CurrentPerkContext';
import PerkPageBreadcrumb from '@cohort/merchants/pages/perks/perk/PerkPageBreadcrumb';
import PerkPageMenu from '@cohort/merchants/pages/perks/perk/PerkPageMenu';
import {useEffect} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {shallow} from 'zustand/shallow';

const PerkPageSkeleton: React.FC = () => {
  const perk = useCurrentPerk();
  const {footer, perkPreview} = usePerkPageStore(
    store => ({
      footer: store.footer,
      perkPreview: store.perkPreview,
    }),
    shallow
  );
  const location = useLocation();
  const navigate = useNavigate();

  // Ensure people won't access some locked pages if the perk is in draft
  useEffect(() => {
    if (
      perk.status === 'draft' &&
      (location.pathname.includes('usages') || location.pathname.includes('digital-assets'))
    ) {
      navigate(getPerksRoute().path, {replace: true});
    }
  }, [perk.status, location.pathname, navigate]);

  return (
    <PageLayout
      title={perk.internalName}
      breadcrumb={<PerkPageBreadcrumb />}
      menu={<PerkPageMenu />}
      rightAside={perkPreview}
      footer={footer}
    >
      <Outlet />
    </PageLayout>
  );
};

const PerkPageLayout = (): JSX.Element => (
  <CurrentPerkProvider>
    <PerkPageSkeleton />
  </CurrentPerkProvider>
);

export default PerkPageLayout;
