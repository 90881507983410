import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Link} from '@phosphor-icons/react';
import {Image} from '@phosphor-icons/react';
import {Fragment} from 'react';
import {useNavigate} from 'react-router-dom';
import {isDefined} from 'remeda';

type BaseReferenceComponentProps = {
  title: string;
  titleAddon?: JSX.Element;
  redirectPath: string;
  imageFileKey?: string | null;
};

const BaseReferenceComponent: React.FC<BaseReferenceComponentProps> = ({
  title,
  titleAddon,
  redirectPath,
  imageFileKey,
}) => {
  const navigate = useNavigate();
  return (
    <div className="px-3">
      <a
        className="my-auto"
        href={redirectPath}
        onClick={e => {
          e.preventDefault();
          navigate(redirectPath);
        }}
      >
        <div className="group rounded-lg p-2 hover:cursor-pointer hover:bg-slate-50">
          <div className={cn(imageFileKey !== undefined && 'flex')}>
            {isDefined(imageFileKey) ? (
              <img
                className="mr-4 h-10 w-10 rounded-md object-cover"
                src={getImageUrl(import.meta.env.COHORT_ENV, imageFileKey, {
                  h: Sizes.S,
                  w: Sizes.S,
                })}
              />
            ) : imageFileKey === null ? (
              <div className="mr-4 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-slate-100 text-slate-400">
                <Image width={24} height={24} />
              </div>
            ) : (
              <Fragment />
            )}
            <div className="flex w-full flex-row items-center justify-between space-x-2">
              <div>
                <p className={cn('line-clamp-1 font-semibold', titleAddon && 'mb-1.5')}>{title}</p>
                {titleAddon}
              </div>
              <div className="opacity-0 transition-opacity group-hover:cursor-pointer group-hover:opacity-100">
                <Link
                  size={20}
                  className="text-slate-400 opacity-0 transition-opacity group-hover:opacity-100"
                />
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default BaseReferenceComponent;
