import type {CreateUserPropertyDataADto} from '@cohort/admin-schemas/userProperty';
import Button from '@cohort/merchants/components/buttons/Button';
import Input from '@cohort/merchants/components/form/input/Input';
import RadioCards from '@cohort/merchants/components/form/RadioCards';
import {Dialog, DialogContent, DialogTitle} from '@cohort/merchants/components/modals/Dialog';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {userPropertiesKeys} from '@cohort/merchants/hooks/api/UserProperties';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {
  createUserProperty,
  getUserPropertyReferenceIdAvailability,
} from '@cohort/merchants/lib/api/UserProperties';
import {notifyError} from '@cohort/merchants/stores/ErrorModalStore';
import {UserPropertyDataTypeSchema} from '@cohort/shared/schema/common/userProperty';
import {toSlug} from '@cohort/shared/utils/format';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import React from 'react';
import type {FieldErrors} from 'react-hook-form';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import z from 'zod';

type CreateUserPropertyModalProps = {
  onClose: () => void;
};

const CreateUserPropertyModal: React.FC<CreateUserPropertyModalProps> = ({onClose}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.userProperties.createUserPropertyModal',
  });
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();

  const {isLoading, mutate: createUserPropertyMutation} = useCohortMutation({
    mutationFn: async (data: CreateUserPropertyDataADto) => createUserProperty(merchant.id, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries(userPropertiesKeys.userProperties);
      onClose();
    },
    notifySuccessMessage: t('notificationSuccess'),
    onError: err => {
      notifyError(err, t('notificationError'));
    },
  });

  const CreateUserPropertyFormSchema = z
    .object({
      name: z.string().min(1, {message: 'errorRequired'}),
      referenceId: z.string(),
      dataType: UserPropertyDataTypeSchema.refine(
        value => value !== 'resource_list',
        'resource_list is not allowed'
      ),
    })
    .superRefine(async ({referenceId, name}, ctx) => {
      if (!name) return;
      const finalReferenceId = referenceId === '' ? toSlug(name) : referenceId;
      const availability = await getUserPropertyReferenceIdAvailability(merchant.id, {
        appId: null,
        referenceId: finalReferenceId,
      });

      if (availability.available === false) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'errorNotAvailable',
          path: ['referenceId'],
        });
      }
    });
  type CreateUserPropertyForm = z.infer<typeof CreateUserPropertyFormSchema>;

  const {register, control, handleSubmit, setValue, getValues} = useForm<CreateUserPropertyForm>({
    defaultValues: {
      name: '',
      referenceId: '',
      dataType: 'string',
    },
    resolver: zodResolver(CreateUserPropertyFormSchema),
  });

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <form
          className="w-[800px] p-6"
          onSubmit={handleSubmit(
            data => createUserPropertyMutation(data),
            (e: FieldErrors) => {
              if (e.referenceId?.message === 'errorNotAvailable') {
                // prefill referenceId with the wrong value so the user can change it
                setValue('referenceId', toSlug(getValues('name')));
              }
            }
          )}
        >
          <div className="flex flex-col pb-4">
            <DialogTitle className="text-lg font-bold leading-6">{t('title')}</DialogTitle>
          </div>
          <div className="mb-8 mt-6 flex flex-col space-y-6">
            <Input
              type="text"
              name="name"
              placeholder={t('placeholderName')}
              register={register}
              control={control}
              label={t('labelName')}
            />
            <Input
              type="text"
              name="referenceId"
              register={register}
              control={control}
              label={t('labelReferenceId')}
            />
            <RadioCards
              name="dataType"
              direction="row"
              label={t('labelType')}
              className="grid grid-cols-2"
              register={register}
              control={control}
              options={[
                {
                  label: t('string'),
                  description: t('stringDescription'),
                  value: 'string',
                },
                {
                  label: t('number'),
                  description: t('numberDescription'),
                  value: 'number',
                },
                {
                  label: t('boolean'),
                  description: t('booleanDescription'),
                  value: 'boolean',
                },
                {
                  label: t('date'),
                  description: t('dateDescription'),
                  value: 'date',
                },
                {
                  label: t('stringList'),
                  description: t('stringListDescription'),
                  value: 'string_list',
                },
              ]}
              withCheckIcon={true}
            />
          </div>
          <Button className="h-10 w-full" type="submit" loading={isLoading}>
            {t('buttonCreate')}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateUserPropertyModal;
