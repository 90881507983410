import {Badge} from '@cohort/merchants/components/Badge';
import type {SyncStatus} from '@cohort/shared/schema/common/sync';
import {useTranslation} from 'react-i18next';

interface Props {
  status: SyncStatus;
  size?: 'large' | 'small';
}

export const SyncStatusBadge = ({status, size}: Props): JSX.Element => {
  const {t} = useTranslation('components', {keyPrefix: 'syncs.syncStatusBadge'});

  let badgeData;

  switch (status) {
    case 'draft':
      badgeData = {
        text: t('statusDraft'),
        backgroundColor: 'bg-yellow-100',
        textColor: 'text-yellow-800',
        dotColor: 'text-yellow-400',
      };
      break;
    case 'paused':
      badgeData = {
        text: t('statusPaused'),
        backgroundColor: 'bg-slate-100',
        textColor: 'text-slate-800',
        dotColor: 'text-slate-400',
      };
      break;
    case 'started':
      badgeData = {
        text: t('statusStarted'),
        backgroundColor: 'bg-green-100',
        textColor: 'text-green-800',
        dotColor: 'text-green-400',
      };
      break;
  }

  return (
    <Badge
      text={badgeData.text}
      backgroundColor={badgeData.backgroundColor}
      textColor={badgeData.textColor}
      dotColor={badgeData.dotColor}
      size={size ?? 'large'}
    />
  );
};
