import type {GetDigitalAssetCollectionsParamsADto} from '@cohort/admin-schemas/digitalAssetCollection';
import type {PaginationFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import {useBasePageFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import {useState} from 'react';

export const statusFilter = ['ready', 'live'] as const;
export const sortFilter = ['-updatedAt', 'internalName'] as const;

export type DigitalAssetCollectionsListFilters = {
  status: GetDigitalAssetCollectionsParamsADto['status'];
  setStatus: (status: GetDigitalAssetCollectionsParamsADto['status']) => void;
  sort: GetDigitalAssetCollectionsParamsADto['orderBy'];
  setSort: (sort: GetDigitalAssetCollectionsParamsADto['orderBy']) => void;
  reset: () => void;
};

const useStateFilters = (): PaginationFilters & DigitalAssetCollectionsListFilters => {
  const [page, setPage] = useState<number>(1);
  const [status, setStatus] = useState<GetDigitalAssetCollectionsParamsADto['status']>();
  const [sort, setSort] = useState<GetDigitalAssetCollectionsParamsADto['orderBy']>('-updatedAt');
  const reset = (): void => {
    setPage(1);
    setStatus(undefined);
    setSort('-updatedAt');
  };
  return {
    page,
    setPage,
    status,
    setStatus: status => {
      setStatus(status);
      setPage(1);
    },
    sort,
    setSort,
    reset,
  };
};

export const useDigitalAssetCollectionsListFilters = (
  pagePath?: RegExp
): PaginationFilters & DigitalAssetCollectionsListFilters =>
  pagePath === undefined
    ? useStateFilters()
    : useBasePageFilters<DigitalAssetCollectionsListFilters>(pagePath, set => ({
        status: undefined,
        setStatus: status => {
          set({status, page: 1});
        },
        sort: '-updatedAt',
        setSort: sort => set({sort}),
        reset: () =>
          set({
            status: undefined,
            sort: '-updatedAt',
          }),
      }));
