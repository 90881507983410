import type {AssetADto} from '@cohort/admin-schemas/asset';
import Button from '@cohort/merchants/components/buttons/Button';
import FileInput from '@cohort/merchants/components/form/FileInput';
import LocalizedInput from '@cohort/merchants/components/form/input/LocalizedInput';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import LocalizedTextEditorInput from '@cohort/merchants/components/form/textEditor/LocalizedTextEditorInput';
import {useCurrentBadge} from '@cohort/merchants/hooks/contexts/currentBadge';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {notify} from '@cohort/merchants/hooks/toast';
import {
  changeSelectedLanguageIfNeeded,
  getDefinedLanguages,
} from '@cohort/merchants/lib/form/localization';
import {handleFormErrors} from '@cohort/merchants/lib/form/utils';
import {getBadgesRoute} from '@cohort/merchants/lib/Pages';
import type {BadgeFormStepProps} from '@cohort/merchants/pages/users/badges/badge/edit/utils';
import {
  BadgeAppearanceStepSchema,
  SaveBadgeAppearanceForLaterRefinement,
  TransformedBadgeAppearanceStepSchema,
} from '@cohort/merchants/pages/users/badges/badge/edit/utils';
import {notifyError} from '@cohort/merchants/stores/ErrorModalStore';
import type {AssetKind} from '@cohort/shared/schema/common/assets';
import {AllowedAssetMimeTypes} from '@cohort/shared/schema/common/assets';
import {isFile} from '@cohort/shared-frontend/utils/isFile';
import {zodResolver} from '@hookform/resolvers/zod';
import type {UseMutateAsyncFunction} from '@tanstack/react-query';
import {Fragment, useEffect} from 'react';
import {useController, useForm, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {z} from 'zod';

type BadgeFormStepPropsWithUpload = {
  uploadMutation: UseMutateAsyncFunction<
    AssetADto | null,
    unknown,
    {file: File; assetKind: AssetKind},
    unknown
  >;
};

type BadgeAppearanceStepValues = z.infer<typeof BadgeAppearanceStepSchema>;

const BadgeAppearanceStep: React.FC<BadgeFormStepProps & BadgeFormStepPropsWithUpload> = ({
  uploadMutation,
  isProcessing,
  setStepSaveBtn,
  onStepValidated,
  updateBadgeMutation,
}) => {
  const merchant = useCurrentMerchant();
  const badge = useCurrentBadge();
  const {t} = useTranslation('pages', {keyPrefix: 'users.badges.badge.edit.badgeAppearanceStep'});
  const navigate = useNavigate();

  const {register, control, handleSubmit, setValue, getValues, setError, clearErrors} =
    useForm<BadgeAppearanceStepValues>({
      defaultValues: {
        name: badge.name,
        description: badge.description,
        imageFileKey: badge.imageFileKey,
        defaultLanguage: merchant.defaultLanguage,
        selectedLanguage: merchant.defaultLanguage,
        definedLanguages: getDefinedLanguages(merchant.defaultLanguage, [
          badge.description,
          badge.name,
        ]),
      },
      resolver: zodResolver(BadgeAppearanceStepSchema),
    });

  const {field: selectedLanguageField} = useController({
    control,
    name: 'selectedLanguage',
  });

  useEffect(() => {
    setStepSaveBtn?.(
      <Button
        variant="secondary"
        loading={isProcessing}
        onClick={async () => {
          try {
            const badgeData = BadgeAppearanceStepSchema.partial()
              .superRefine(SaveBadgeAppearanceForLaterRefinement)
              .parse(getValues());
            if (isFile(badgeData.imageFileKey)) {
              const asset = await uploadMutation({
                file: badgeData.imageFileKey as unknown as File,
                assetKind: 'badgeVisual',
              });

              if (asset === null) {
                return notifyError(t('errorFileUploadFailed'));
              }
              badgeData.imageFileKey = asset.fileKey;
            }
            clearErrors();
            await updateBadgeMutation(badgeData);
            notify('success', t('notificationSuccess'));
            navigate(getBadgesRoute().path);
          } catch (error) {
            if (error instanceof z.ZodError) {
              handleFormErrors(error, clearErrors, setError);
              changeSelectedLanguageIfNeeded(
                error,
                merchant.defaultLanguage,
                selectedLanguageField.onChange
              );
            } else {
              throw error;
            }
          }
        }}
      >
        {t('saveBtn')}
      </Button>
    );
  }, [
    setStepSaveBtn,
    t,
    isProcessing,
    updateBadgeMutation,
    getValues,
    clearErrors,
    setError,
    navigate,
    merchant.defaultLanguage,
    selectedLanguageField.onChange,
    uploadMutation,
  ]);

  const selectedLanguage = useWatch({
    control,
    name: 'selectedLanguage',
  });

  if (selectedLanguage === undefined) {
    return <Fragment />;
  }

  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  return (
    <Fragment>
      <LanguageSelectorInput
        control={control}
        definedLanguagesPath="definedLanguages"
        selectedLanguagePath="selectedLanguage"
      />
      <form
        className="space-y-8"
        id="badge-appearance-step"
        onSubmit={handleSubmit(
          async data => {
            try {
              const transformedData = TransformedBadgeAppearanceStepSchema.parse(data);
              if (isFile(transformedData.imageFileKey)) {
                const asset = await uploadMutation({
                  file: transformedData.imageFileKey as unknown as File,
                  assetKind: 'badgeVisual',
                });

                if (asset === null) {
                  return notifyError(t('errorFileUploadFailed'));
                }
                transformedData.imageFileKey = asset.fileKey;
              }
              await updateBadgeMutation(transformedData);
              onStepValidated();
            } catch (error) {
              if (error instanceof z.ZodError) {
                handleFormErrors(error, clearErrors, setError);
                changeSelectedLanguageIfNeeded(error, merchant.defaultLanguage, lang =>
                  setValue('selectedLanguage', lang)
                );
              } else {
                throw error;
              }
            }
          },
          errors => {
            changeSelectedLanguageIfNeeded(errors, merchant.defaultLanguage, lang =>
              setValue('selectedLanguage', lang)
            );
          }
        )}
      >
        <LocalizedInput
          type="text"
          label={t('labelNameInput')}
          name="name"
          placeholder={t('placeholderNameInput')}
          register={register}
          control={control}
          selectedLanguage={selectedLanguage}
        />
        <LocalizedTextEditorInput
          label={t('labelDescriptionInput')}
          name="description"
          placeholder={t('placeholderDescriptionInput')}
          register={register}
          control={control}
          selectedLanguage={selectedLanguage}
        />
        {isDefaultLanguageSelected && (
          <FileInput
            name="imageFileKey"
            label={t('labelVisualInput')}
            register={register}
            control={control}
            acceptHint={t('helperBadgeVisualInput')}
            accept={AllowedAssetMimeTypes.badgeVisual.options.join(',')}
            assetKind="badgeVisual"
            disabled={!isDefaultLanguageSelected}
            withResize
          />
        )}
      </form>
    </Fragment>
  );
};

export default BadgeAppearanceStep;
