import type {PatchMerchantADto} from '@cohort/admin-schemas/merchant';
import AdminPage from '@cohort/merchants/components/AdminPage';
import {Badge} from '@cohort/merchants/components/Badge';
import Button from '@cohort/merchants/components/buttons/Button';
import {InformationTooltip} from '@cohort/merchants/components/InformationTooltip';
import {AddLanguageModal} from '@cohort/merchants/components/modals/AddLanguageModal';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {merchantsKeys} from '@cohort/merchants/hooks/api/Merchants';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {notify} from '@cohort/merchants/hooks/toast';
import useTranslateLanguage from '@cohort/merchants/hooks/useTranslateLanguage';
import {patchMerchant} from '@cohort/merchants/lib/api/Merchants';
import {sortLanguages} from '@cohort/merchants/lib/form/localization';
import type {Language} from '@cohort/shared/schema/common';
import {LANGUAGE_FLAGS, SUPPORTED_LANGUAGES} from '@cohort/shared/schema/common';
import {Trash} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {createColumnHelper} from '@tanstack/react-table';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

const LanguagesSettingsPage = (): JSX.Element => {
  const queryClient = useQueryClient();
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {keyPrefix: 'settings.languages.page'});
  const getLanguageTranslation = useTranslateLanguage();
  const columnHelper = createColumnHelper<Language>();
  const columns = [
    columnHelper.display({
      id: 'language',
      header: t('tableHeaderLanguage'),
      cell: data => (
        <div className="flex h-10 items-center space-x-2 text-sm font-medium">
          <span>{LANGUAGE_FLAGS[data.row.original]}</span>
          <span>{getLanguageTranslation(data.row.original)}</span>
          {data.row.original === merchant.defaultLanguage && (
            <div className="flex items-center space-x-2 pl-4">
              <Badge
                text={t('labelDefaultLanguage')}
                textColor="text-primary"
                backgroundColor="bg-primary/10"
                size="small"
              />
              <InformationTooltip content={t('tooltipChangeLanguage')} />
            </div>
          )}
        </div>
      ),
    }),
    columnHelper.display({
      id: 'actions',
      cell: data =>
        data.row.original !== merchant.defaultLanguage ? (
          <div className="text-right">
            <Button
              variant="secondary"
              size="icon"
              onClick={() => setLanguageToRemove(data.row.original)}
            >
              <Trash size={20} className="text-red-400" />
            </Button>
          </div>
        ) : null,
    }),
  ];
  const languages = useMemo(
    () =>
      sortLanguages(merchant.supportedLanguages, getLanguageTranslation, merchant.defaultLanguage),
    [merchant.supportedLanguages, getLanguageTranslation, merchant.defaultLanguage]
  );
  const table = useCohortTable({
    columns,
    data: languages,
  });

  const [addLanguageModelOpened, setAddLanguageModalOpened] = useState(false);
  const [languageToRemove, setLanguageToRemove] = useState<Language | undefined>(undefined);

  const languageOptions = sortLanguages(
    SUPPORTED_LANGUAGES.filter(language => !merchant.supportedLanguages.includes(language)),
    getLanguageTranslation
  );

  const {isLoading, mutate: updateMerchant} = useCohortMutation({
    mutationFn: async (data: PatchMerchantADto) => patchMerchant(merchant.id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(merchantsKeys.getById(merchant.id));
      setAddLanguageModalOpened(false);
      setLanguageToRemove(undefined);
      notify('success', t('notificationUpdateSuccess'));
    },
  });

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
    topRightElements: (
      <Button onClick={() => setAddLanguageModalOpened(true)} key="add-language">
        {t('buttonAddLanguage')}
      </Button>
    ),
  };

  return (
    <AdminPage header={headerConfig}>
      <DataTable
        table={table}
        columnsLength={columns.length}
        emptyStatePlaceholder={''}
        isLoading={isLoading}
      />
      {languageToRemove && (
        <DeletionModal
          title={t('removeLanguageTitle', {language: getLanguageTranslation(languageToRemove)})}
          subtitle={t('removeLanguageSubtitle')}
          show
          onClose={() => setLanguageToRemove(undefined)}
          onDelete={() =>
            updateMerchant({
              supportedLanguages: merchant.supportedLanguages.filter(l => l !== languageToRemove),
            })
          }
        />
      )}
      {addLanguageModelOpened && (
        <AddLanguageModal
          onClose={() => setAddLanguageModalOpened(false)}
          onLanguagesAdded={(languages: Array<Language>) =>
            updateMerchant({supportedLanguages: [...merchant.supportedLanguages, ...languages]})
          }
          languageOptions={languageOptions}
          isLoading={isLoading}
          title={t('addLanguageModalTitle')}
          subtitle={t('addLanguageModalSubitle')}
        />
      )}
    </AdminPage>
  );
};

export default LanguagesSettingsPage;
