import type {JourneyParticipationsOrderBy} from '@cohort/admin-schemas/journeyParticipation';
import type {PaginationFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import {useBasePageFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import type {JourneyParticipationStatus} from '@cohort/shared/schema/common/journeyParticipations';

export const statusFilter = ['in-progress', 'completed'] as const;

type JourneyParticipationsListFilters = {
  status: Array<JourneyParticipationStatus>;
  setStatus: (status: Array<JourneyParticipationStatus>) => void;
  orderBy: JourneyParticipationsOrderBy;
  setOrderBy: (orderBy: JourneyParticipationsOrderBy) => void;
  searchEmail: string;
  setSearchEmail: (searchEmail: string) => void;
  reset: () => void;
};

export const useJourneyParticipationsListFilters = (): PaginationFilters &
  JourneyParticipationsListFilters =>
  useBasePageFilters<JourneyParticipationsListFilters>(
    /^(\/test)?\/campaigns\/.*\/activity$/u,
    set => ({
      status: [],
      setStatus: status => {
        set({status, page: 1});
      },
      orderBy: '-createdAt',
      setOrderBy: orderBy => set({orderBy}),
      reset: () => set({status: [], orderBy: '-createdAt'}),
      searchEmail: '',
      setSearchEmail: searchEmail => set({searchEmail}),
    })
  );
