import {DateSchema, EmailSchema} from '@cohort/shared/schema/common';
import {PaginationParamsSchema} from '@cohort/shared/schema/common/pagination';
import {z} from 'zod';

export const AirdropActivityASchema = z.object({
  createdAt: DateSchema,
  user: z.object({
    id: z.string(),
    email: EmailSchema,
  }),
});
export type AirdropActivityADto = z.infer<typeof AirdropActivityASchema>;

export const GetAirdropActivitiesParamsASchema = PaginationParamsSchema.merge(
  z.object({
    searchEmail: z.string().optional(),
  })
);
export type GetAirdropActivitiesParamsADto = z.infer<typeof GetAirdropActivitiesParamsASchema>;
