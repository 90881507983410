import type {UserPropertyADto} from '@cohort/admin-schemas/userProperty';
import {Badge} from '@cohort/merchants/components/Badge';
import DataTypeIcon from '@cohort/merchants/components/DataTypeIcon';
import SelectUserProperty from '@cohort/merchants/components/modals/ImportUsersModal/SelectUserProperty';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {findCsvEmailHeader} from '@cohort/merchants/lib/form/utils';
import {
  getValidUserPropertyDataTypes,
  tryParseFromCsv,
} from '@cohort/shared/schema/common/userAttribute';
import {ArrowRight, XCircle} from '@phosphor-icons/react';
import {createColumnHelper} from '@tanstack/react-table';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Select from 'react-select';

type FieldMappingStepProps = {
  setMappedProperties: (mappedProperties: Array<UserPropertyADto | undefined>) => void;
  mappedProperties: Array<UserPropertyADto | undefined>;
  csvHeaders: string[];
  csvUsers: Record<string, string | undefined>[];
  isLoading: boolean;
};
const FieldMappingStep: React.FC<FieldMappingStepProps> = ({
  setMappedProperties,
  mappedProperties,
  csvHeaders,
  csvUsers,
  isLoading,
}) => {
  const {t} = useTranslation('components', {keyPrefix: 'modals.importUsersModal.fieldMappingStep'});
  const [emailHeader] = useState<string | null>(findCsvEmailHeader(csvHeaders, csvUsers));

  const columnHelper = createColumnHelper<string>();
  const columns = [
    columnHelper.display({
      id: 'column',
      header: t('fieldMappingTable.headerCsvColumn'),
      cell: data => (
        <div className="mt-4 max-w-40 overflow-hidden text-ellipsis whitespace-nowrap">
          <Badge
            textColor="text-muted-foreground"
            backgroundColor="bg-gray-100"
            text={data.row.original}
            className="inline"
          />
        </div>
      ),
      size: 150,
    }),
    columnHelper.display({
      id: 'mapped',
      header: t('fieldMappingTable.headerMapped'),
      cell: data =>
        mappedProperties[data.row.index] !== undefined || data.row.original === emailHeader ? (
          <ArrowRight className="mt-3.5 text-green-500" size={24} />
        ) : (
          <XCircle className="mt-3.5 text-muted-foreground" size={24} />
        ),
    }),
    columnHelper.display({
      id: 'userProperty',
      header: t('fieldMappingTable.headerUserProperty'),
      cell: data => {
        const csvValues = csvUsers.map(csvUser => tryParseFromCsv(csvUser[data.row.original]));
        const validDataTypes = getValidUserPropertyDataTypes(csvValues);

        return data.row.original === emailHeader ? (
          <Select
            placeholder={
              <div className="flex flex-row items-center gap-2">
                <DataTypeIcon dataType="string" size={16} />
                {t('email')}
              </div>
            }
            isDisabled
            className="mt-1.5"
          />
        ) : (
          <SelectUserProperty
            index={data.row.index}
            value={mappedProperties[data.row.index]}
            mappedProperties={mappedProperties}
            onChange={value => {
              const properties = [...mappedProperties];
              properties[data.row.index] = value ?? undefined;
              setMappedProperties(properties);
            }}
            isDisabled={isLoading}
            dataTypesFilter={validDataTypes}
            className="mt-1.5"
          />
        );
      },
      size: 250,
    }),
    columnHelper.display({
      id: 'sampleData',
      header: t('fieldMappingTable.headerSampleData'),
      cell: data => (
        <ul className="mt-0.5 max-w-48 text-xs text-muted-foreground">
          {csvUsers.slice(0, 3).map((user, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={`sample-${index}`} className="overflow-hidden text-ellipsis whitespace-nowrap">
              {user[data.row.original]}
            </p>
          ))}
        </ul>
      ),
      size: 200,
    }),
  ];
  const table = useCohortTable({
    columns,
    data: csvHeaders,
  });

  return (
    <Fragment>
      <p className="text-base font-medium text-gray-900">{t('title')}</p>
      <p className="pb-6 text-sm text-slate-700">{t('subtitle')}</p>
      <div className="flex-1 overflow-y-auto">
        <DataTable
          table={table}
          columnsLength={columns.length}
          isLoading={isLoading}
          emptyStatePlaceholder={''}
          componentsClassName={{tableCell: 'align-top'}}
        />
      </div>
    </Fragment>
  );
};

export default FieldMappingStep;
