import {IconBadge} from '@cohort/merchants/components/IconBadge';
import {Tag} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

type DigitalAssetCollectionBadge = {
  size?: 'large' | 'small';
};

const DigitalAssetCollectionBadge: React.FC<DigitalAssetCollectionBadge> = ({size = 'small'}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'digitalAssets.digitalAssetCollectionBadge',
  });

  return (
    <IconBadge
      size={size}
      text={t('labelDigitalAsset')}
      icon={<Tag height={14} width={14} className="mr-1 inline-block" />}
    />
  );
};

export default DigitalAssetCollectionBadge;
