import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import ExportCSV from '@cohort/merchants/components/buttons/ExportCSV';
import {useRedeemCodes} from '@cohort/merchants/hooks/api/Campaigns';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import usePageSearchParam from '@cohort/merchants/hooks/pageSearchParam';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {exportRedeemCodes} from '@cohort/merchants/lib/api/Campaigns';
import {trackCampaignRedeemLinksPageViewed} from '@cohort/merchants/lib/Tracking';
import CampaignAudience from '@cohort/merchants/pages/campaigns/campaign/audience/CampaignAudience';
import CampaignInvitesTable from '@cohort/merchants/pages/campaigns/campaign/audience/CampaignInvitesTable';
import CampaignRedeemLinksModal from '@cohort/merchants/pages/campaigns/campaign/audience/CampaignRedeemLinksModal';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

const PAGE_SIZE = 20;

const CampaignInvitees = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const campaign = useCurrentCampaign();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.audience.campaignInvitees',
  });
  const page = usePageSearchParam();
  const [searchParams] = useSearchParams();

  const [showRedeemLinksModal, setShowRedeemLinksModal] = useState(() =>
    searchParams.has('generate')
  );

  const {data, isLoading} = useRedeemCodes(merchantId, campaign.id, {
    page,
    pageSize: PAGE_SIZE,
  });
  const [pagination, redeemCodes] = data ?? [];

  useEffect(() => trackCampaignRedeemLinksPageViewed(campaign), [campaign]);

  return (
    <AdminPage header={{title: t('title')}} pagination={{pagination}}>
      <CampaignAudience />
      <div className="flex items-center justify-end pb-4">
        <div className="flex items-center gap-4">
          <ExportCSV
            disabled={isLoading || (redeemCodes?.length ?? 0) === 0}
            downloadFunction={async () => exportRedeemCodes(merchantId, campaign.id, 'csv')}
          />
          <Button
            key="generate-btn"
            className={cn(
              campaign.totalSupply &&
                (!pagination || pagination.total + campaign.numOrdered >= campaign.totalSupply) &&
                'hidden'
            )}
            onClick={() => setShowRedeemLinksModal(true)}
          >
            {t('generateRedeemLinksBtn')}
          </Button>
        </div>
      </div>
      <section className="overflow-y-auto">
        <CampaignInvitesTable
          redeemCodes={redeemCodes ?? []}
          campaign={campaign}
          isLoading={isLoading}
        />
      </section>
      {showRedeemLinksModal && (
        <CampaignRedeemLinksModal
          show={true}
          merchantId={merchantId}
          campaign={campaign}
          handleOnClose={() => setShowRedeemLinksModal(false)}
          redeemCodesLength={pagination?.total ?? 0}
        />
      )}
    </AdminPage>
  );
};

export default CampaignInvitees;
