import useBackNavigationStore from '@cohort/merchants/hooks/stores/backNavigation';
import type {StateStorage, StorageValue} from 'zustand/middleware';

function isRegExp(value: unknown): value is RegExp {
  return value instanceof RegExp;
}

function isMatchingRoute(route: string | RegExp, pathname: string): boolean {
  if (isRegExp(route)) {
    return route.test(pathname);
  }
  return route === pathname;
}

export function queryStorage(applyForRoute: string | RegExp): StateStorage {
  return {
    getItem: () => {
      if (!isMatchingRoute(applyForRoute, window.location.pathname)) {
        return null;
      }
      const searchParams = new URLSearchParams(window.location.search.slice(1));
      const state = Object.fromEntries(searchParams);
      const stateToLoad: Record<string, unknown> = {};

      for (const [key, value] of Object.entries(state)) {
        if (value.includes(',')) {
          stateToLoad[key] = value.split(',');
        } else {
          stateToLoad[key] = value;
        }
      }
      return JSON.stringify({state: stateToLoad, version: 0});
    },
    setItem: (key, newValue): void => {
      if (!isMatchingRoute(applyForRoute, window.location.pathname)) {
        return;
      }
      const rawState = JSON.parse(newValue) as StorageValue<Record<string, unknown>>;
      const stateToSave: Record<string, string> = {};

      for (const [key, value] of Object.entries(rawState.state)) {
        if (Array.isArray(value) && value.length > 0) {
          stateToSave[key] = value.join(',');
          continue;
        }

        if (value !== undefined && value !== null && value !== '' && !Array.isArray(value)) {
          stateToSave[key] = value.toString();
        }
      }
      const searchParams = new URLSearchParams(stateToSave);

      window.history.replaceState(null, '', `?${searchParams.toString()}`);
      useBackNavigationStore
        .getState()
        .setCurrentPath(window.location.pathname, `?${searchParams.toString()}`);
    },
    removeItem: (): void => {
      window.history.replaceState(null, '', '');
      useBackNavigationStore.getState().setCurrentPath(window.location.pathname, '');
    },
  };
}
