import CustomComponentCard from '@cohort/components-xps/components/cards/CustomComponentCard';
import i18nComponentsXpsInstance from '@cohort/components-xps/lib/i18n';
import type {Language} from '@cohort/shared/schema/common';
import {HexColorSchema} from '@cohort/shared/schema/common';
import type {MembershipPassContextTDto} from '@cohort/shared/schema/templating/common';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {CalendarBlank, Image, User} from '@phosphor-icons/react';
import Color from 'color';
import dayjs from 'dayjs';
import {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

export type MembershipPassCardProps = {
  customComponent?: {
    context: MembershipPassContextTDto;
    template: string;
  };
  membershipPass: {
    title: string | null;
    imageUrl: string | null;
    memberHandle: string;
    subscriptionDate: Date;
    memberId: number;
    blockExplorerUrl: string | null;
  };
  selectedLanguage?: Language;
};

const MembershipPassCard: React.FC<MembershipPassCardProps> = ({
  membershipPass,
  customComponent,
  selectedLanguage,
}) => {
  const [hasDarkBg, setHasDarkBg] = useState(false);
  const [gradientColors, setGradientColors] = useState<{
    from: string | Color<string>;
    to: string | Color<string>;
  }>({
    from: '',
    to: '',
  });

  const {t} = useTranslation('components', {
    keyPrefix: 'cards.membershipPassCard',
    lng: selectedLanguage ?? i18nComponentsXpsInstance.language,
    // Enforcing the i18n instance to avoid being overriden when imported.
    i18n: i18nComponentsXpsInstance,
  });
  const {title, imageUrl, memberHandle, subscriptionDate, memberId, blockExplorerUrl} =
    membershipPass;

  useEffect(() => {
    const backgroundColor = getComputedStyle(document.documentElement).getPropertyValue(
      '--xps-membership-pass-background-color'
    );

    if (HexColorSchema.safeParse(backgroundColor).success) {
      const bgColor = Color(backgroundColor);
      const isDarkBg = bgColor.isDark();
      const computedColor = isDarkBg ? bgColor.lighten(0.3).hex() : bgColor.darken(0.4).hex();

      setGradientColors({
        from: isDarkBg ? computedColor : bgColor,
        to: isDarkBg ? bgColor : computedColor,
      });
      setHasDarkBg(isDarkBg);
    }
  }, []);

  const textColor = hasDarkBg ? 'text-white' : 'text-black/90';
  const joinedDate = dayjs(subscriptionDate).format('ll');

  return (
    <Fragment>
      {customComponent ? (
        <CustomComponentCard
          context={customComponent.context}
          template={customComponent.template}
        />
      ) : (
        <div
          className="w-full"
          style={{
            borderRadius: 'var(--xps-membership-pass-border-radius)',
          }}
        >
          <div className="relative">
            {blockExplorerUrl !== null && (
              <a
                href={blockExplorerUrl !== '' ? blockExplorerUrl : undefined}
                target="_blank"
                className="absolute right-4 top-4 flex flex-row items-center gap-1 rounded-md border border-[#8247E5] bg-white/60 p-[6px] text-xs font-semibold text-black no-underline backdrop-blur-sm"
              >
                <SvgAppIcon name="polygon" height={16} width={16} />
                {t('viewPolygon')}
              </a>
            )}
            {imageUrl === null ? (
              <div className="flex min-h-[179px] w-full rounded-t-[--xps-membership-pass-border-radius] transition-all duration-500 ease-in-out group-hover:opacity-90">
                <Image
                  className={cn('m-auto h-12 w-12', hasDarkBg ? 'text-white/60' : 'text-black/60')}
                />
              </div>
            ) : (
              <img
                data-testid="membership-pass-image-preview"
                title="Membership pass banner"
                src={imageUrl}
                className="aspect-video w-full overflow-clip rounded-b-none rounded-t-[--xps-membership-pass-border-radius] bg-[--xps-membership-pass-background-color] object-cover transition-all duration-500 ease-in-out group-hover:opacity-90"
                crossOrigin="anonymous"
                alt="Membership pass"
              />
            )}
          </div>
          <div className="h-2 bg-black"></div>
          <div
            className="p-4"
            style={{
              background: `linear-gradient(107.27deg, ${gradientColors.from} 18.88%, ${gradientColors.to} 94.25%`,
              borderBottomRightRadius: 'var(--xps-membership-pass-border-radius)',
              borderBottomLeftRadius: 'var(--xps-membership-pass-border-radius)',
            }}
          >
            <div className={cn('mb-1 text-sm', textColor)}>@{memberHandle}</div>
            <div className={cn('text-xl font-bold', textColor)}>{title}</div>
            <div className="mt-[10px] flex justify-between">
              <div
                className="flex rounded bg-white/60 px-2.5 py-1 text-xs text-black"
                data-testid="subscription-date"
              >
                <CalendarBlank size={12} className="my-auto mr-1" />
                {t('titleSubscriptionDate', {subscriptionDate: joinedDate})}
              </div>
              <div className="flex rounded bg-white/60 px-2.5 py-1 text-xs text-black">
                <User size={12} className="my-auto mr-1" /> ID #{memberId}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default MembershipPassCard;
