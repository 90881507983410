import type {
  PerkAccessADto,
  PerkAccessOrderByADto,
  PerkUsageADto,
} from '@cohort/admin-schemas/perkAccesses';
import Button from '@cohort/merchants/components/buttons/Button';
import ObtentionTypeBadge from '@cohort/merchants/components/ObtentionTypeBadge';
import PerkAccessStatusBadge from '@cohort/merchants/components/perks/PerkAccessStatusBadge';
import PerkUsageDetailsModal from '@cohort/merchants/components/perks/PerkUsageDetailsModal';
import UserReferenceComponent from '@cohort/merchants/components/references/UserReferenceComponent';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {formatDate} from '@cohort/shared/utils/format';
import {ListMagnifyingGlass} from '@phosphor-icons/react';
import {createColumnHelper, getExpandedRowModel} from '@tanstack/react-table';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';

type PerkOwnersTableProps = {
  perkAccesses: PerkAccessADto[];
  sortBy: PerkAccessOrderByADto;
  onSort: (key: string) => void;
  isLoading: boolean;
};

const PerkOwnersTable: React.FC<PerkOwnersTableProps> = ({
  perkAccesses,
  sortBy,
  onSort,
  isLoading,
}): JSX.Element => {
  const [perkAnalyticsUsagesToShow, setPerkAnalyticsUsagesToShow] =
    useState<Array<PerkUsageADto> | null>(null);
  const {t} = useTranslation('pages', {keyPrefix: 'perks.perk.owners.perkOwnersTable'});
  const columnHelper = createColumnHelper<PerkAccessADto>();
  const columns = [
    columnHelper.accessor('user', {
      header: t('tableHeaderOwner'),
      cell: data => <UserReferenceComponent user={data.getValue()} />,
      enableSorting: false,
    }),
    columnHelper.accessor('obtentionType', {
      header: t('tableHeaderOrigin'),
      cell: data => (
        <ObtentionTypeBadge type={data.getValue()} campaign={data.row.original.campaign} />
      ),
      minSize: 250,
      enableSorting: false,
    }),
    columnHelper.accessor(row => formatDate(row.createdAt), {
      id: 'createdAt',
      header: t('tableHeaderAcquisitionDate'),
      minSize: 200,
      enableSorting: false,
    }),
    columnHelper.accessor(row => (row.lastUsageDate ? formatDate(row.lastUsageDate) : '-'), {
      id: 'lastUsageDate',
      header: t('tableHeaderLastUsageDate'),
      minSize: 200,
    }),
    columnHelper.display({
      header: t('tableHeaderStatus'),
      minSize: 200,
      enableSorting: false,
      cell: data => <PerkAccessStatusBadge perkAccess={data.row.original} />,
    }),
    columnHelper.display({
      id: 'usages',
      header: () => null,
      cell: data => {
        if (data.row.original.usages.length > 0) {
          return (
            <div className="text-right">
              <Button
                onClick={() => setPerkAnalyticsUsagesToShow(data.row.original.usages)}
                variant="secondary"
                size="sm"
              >
                <ListMagnifyingGlass size={20} className="mr-1" />
                {t('buttonShowDetails')}
              </Button>
            </div>
          );
        }
        return null;
      },
      minSize: 100,
    }),
  ];
  const table = useCohortTable(
    {
      data: perkAccesses,
      columns,
      getRowCanExpand: row => row.original.usages.length > 0,
      getExpandedRowModel: getExpandedRowModel(),
    },
    {
      sortBy,
      onSortUpdate: onSort,
    }
  );

  return (
    <Fragment>
      <DataTable
        table={table}
        columnsLength={columns.length}
        isLoading={isLoading}
        emptyStatePlaceholder={t('emptyPlaceholder')}
      />
      {perkAnalyticsUsagesToShow !== null && (
        <PerkUsageDetailsModal
          onClose={() => setPerkAnalyticsUsagesToShow(null)}
          title={t('perkUsageDetailsModalTitle')}
          perkAnalyticsUsages={perkAnalyticsUsagesToShow}
        />
      )}
    </Fragment>
  );
};

export default PerkOwnersTable;
