import {useUrlParams} from '@cohort/merchants/hooks/useUrlParams';
import type {PaginationDto} from '@cohort/shared/schema/common/pagination';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {CaretLeft, CaretRight} from '@phosphor-icons/react';
import React, {useCallback} from 'react';
import {Trans} from 'react-i18next';

const fillPageNumbers = (start: number, end: number): number[] =>
  [...Array(end - start + 1).keys()].map(x => start + x);

const computePagination = (page: number, pages: number): (number | string)[] => {
  if (pages <= 7) {
    return fillPageNumbers(1, pages);
  }
  if (page <= 2 || page >= pages - 1) {
    return [...fillPageNumbers(1, 3), 'center', ...fillPageNumbers(pages - 2, pages)];
  }
  if (page === 3) {
    return [...fillPageNumbers(1, 4), 'center', ...fillPageNumbers(pages - 1, pages)];
  }
  if (page === pages - 2) {
    return [...fillPageNumbers(1, 2), 'center', ...fillPageNumbers(pages - 3, pages)];
  }
  return [1, 'left', ...fillPageNumbers(page - 1, page + 1), 'right', pages];
};

type PaginationButtonProps = {
  page: number | string;
  children: string | JSX.Element;
  handleClick: (page: number) => void;
  currentPage?: number;
  isFirst?: boolean;
  isLast?: boolean;
};

const PaginationButton: React.FC<PaginationButtonProps> = ({
  page,
  handleClick,
  currentPage,
  isFirst,
  isLast,
  children,
}) => (
  <button
    type="button"
    key={page}
    onClick={() => typeof page === 'number' && handleClick(page)}
    className={cn(
      '-ml-px flex w-10 justify-center border py-2 text-sm font-medium hover:z-10',
      isFirst && 'rounded-bl-md rounded-tl-md',
      isLast && 'rounded-br-md rounded-tr-md',
      currentPage === page
        ? 'z-20 border-primary bg-primary/10 text-primary-darker'
        : 'bg-white hover:text-slate-700',
      typeof page === 'string' && 'cursor-not-allowed hover:text-slate-400'
    )}
  >
    {children}
  </button>
);

type PaginationFooterProps = {
  pagination: PaginationDto;
  className?: string;
  onPageChange?: (page: number) => void;
};

const PaginationFooter: React.FC<PaginationFooterProps> = ({
  pagination,
  className,
  onPageChange,
}) => {
  const {patchSearchParams} = useUrlParams();

  const handleClick = useCallback(
    (page: number | null) => {
      if (page !== null) {
        onPageChange ? onPageChange(page) : patchSearchParams({page: page.toString()});
      }
    },
    [onPageChange, patchSearchParams]
  );

  const paginationButtons = computePagination(pagination.page, pagination.pages);
  const prev = pagination.page > 1 ? pagination.page - 1 : null;
  const next = pagination.page < pagination.pages ? pagination.page + 1 : null;
  const showingFrom = (pagination.page - 1) * pagination.pageSize + 1;
  const showingTo = Math.min(showingFrom + pagination.pageSize - 1, pagination.total);

  return (
    <nav className={cn('flex w-full items-center justify-between', className)}>
      <p className="text-sm text-slate-500">
        <Trans
          i18nKey="paginationFooter.pagesHelper"
          ns="components"
          values={{from: showingFrom, to: showingTo, total: pagination.total}}
          components={{
            bold: <span className="font-medium text-gray-700" />,
          }}
        />
      </p>
      <div className="flex">
        {prev !== null && (
          <PaginationButton page={prev} handleClick={handleClick} isFirst={true}>
            <CaretLeft className="h-5 w-4 text-slate-400" aria-hidden="true" />
          </PaginationButton>
        )}
        {paginationButtons.map((page, index) => (
          <PaginationButton
            page={page}
            handleClick={handleClick}
            currentPage={pagination.page}
            isFirst={prev === null && index === 0}
            isLast={next === null && index === paginationButtons.length - 1}
            key={page.toString()}
          >
            {typeof page === 'number' ? page.toString() : '...'}
          </PaginationButton>
        ))}
        {next !== null && (
          <PaginationButton page={next} handleClick={handleClick} isLast={true}>
            <CaretRight className="h-5 w-4 text-slate-400" aria-hidden="true" />
          </PaginationButton>
        )}
      </div>
    </nav>
  );
};

export default PaginationFooter;
