import React from 'react';

type EngagementScoreGaugeProps = {
  width: number;
  value: number | null;
};

const EngagementScoreGauge: React.FC<EngagementScoreGaugeProps> = ({width, value}) => {
  if (value !== null) {
    value = Math.round(Math.max(0, Math.min(value, 100)));
  }

  const radius = 40;
  const angle = value !== null ? (value / 100) * 180 : 0;
  const markerX = 60 + radius * Math.cos((angle - 180) * (Math.PI / 180));
  const markerY = 60 + radius * Math.sin((angle - 180) * (Math.PI / 180));

  const colors = [
    '#EF4444',
    '#F97316',
    '#F9891B',
    '#FBBF24',
    '#FACD1E',
    '#FACC15',
    '#C2E11C',
    '#A3E635',
    '#38D76B',
    '#22C55E',
  ];
  const colorIndex = value !== null ? Math.min(Math.floor(value / 10), colors.length - 1) : 0;
  const markerColor = colors[colorIndex];

  return (
    <div className="relative" style={{width, height: width / 2}}>
      <svg width={width} viewBox="0 10 120 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20 60 A40 40 0 0 1 100 60"
          stroke="url(#gradient)"
          strokeWidth="7"
          strokeLinecap="round"
        />
        <defs>
          <linearGradient
            id="gradient"
            x1="20"
            y1="60"
            x2="100"
            y2="60"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EF4444" />
            <stop offset="0.33" stopColor="#F97316" />
            <stop offset="0.66" stopColor="#FBBF24" />
            <stop offset="1" stopColor="#22C55E" />
          </linearGradient>
        </defs>
        {value !== null && (
          <>
            <circle cx={markerX} cy={markerY} r="12" fill="white" className="drop-shadow-md" />
            <circle cx={markerX} cy={markerY} r="8" fill={markerColor} />
          </>
        )}
      </svg>
      <div
        className="absolute left-1/2 -translate-x-1/2 transform font-bold text-slate-900"
        style={{fontSize: `${width * 0.15}px`, bottom: `${width * 0.05}px`}}
      >
        {value !== null ? value : 'N/A'}
      </div>
    </div>
  );
};

export default EngagementScoreGauge;
