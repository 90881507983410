import {Badge} from '@cohort/merchants/components/Badge';
import type {CohortType} from '@cohort/shared/schema/common/cohort';
import {Lightning, ListBullets} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

type CohortTypeBadgeProps = {
  type: CohortType;
  size?: 'large' | 'small';
};

export const CohortTypeBadge: React.FC<CohortTypeBadgeProps> = ({type, size}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'cohorts.cohortTypeBadge',
  });
  const badgeData = match(type)
    .with('static', () => ({
      text: (
        <div className="flex items-center gap-2">
          <ListBullets size={16} className="text-slate-500" />
          <span>{t('typeStatic')}</span>
        </div>
      ),
      backgroundColor: 'bg-slate-50',
      textColor: 'text-slate-800',
    }))
    .with('dynamic', () => ({
      text: (
        <div className="flex items-center gap-2">
          <Lightning size={16} className="text-slate-500" />
          <span>{t('typeDynamic')}</span>
        </div>
      ),
      backgroundColor: 'bg-slate-50',
      textColor: 'text-slate-800',
    }))
    .exhaustive();

  return (
    <Badge
      text={badgeData.text}
      backgroundColor={badgeData.backgroundColor}
      textColor={badgeData.textColor}
      size={size ?? 'large'}
    />
  );
};
