import type {OrganizationMemberADto} from '@cohort/admin-schemas/organizationMember';
import {getCurrentMember} from '@cohort/merchants/lib/api/Auth';
import type {SsoProviderType} from '@cohort/shared/schema/common/ssoProvider';
import {initializeApp} from 'firebase/app';
import type {Auth, User, UserCredential} from 'firebase/auth';
import {
  getAuth,
  OAuthProvider,
  SAMLAuthProvider,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut,
} from 'firebase/auth';
import jwt_decode from 'jwt-decode';
import {match} from 'ts-pattern';

export const ORG_PICKER_TENANT_ID = import.meta.env.COHORT_ORG_PICKER_TENANT_ID;

export function initFirebase(): void {
  initializeApp({
    apiKey: import.meta.env.COHORT_FIREBASE_API_KEY,
    authDomain: import.meta.env.COHORT_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.COHORT_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.COHORT_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.COHORT_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.COHORT_FIREBASE_APP_ID,
    measurementId: import.meta.env.COHORT_FIREBASE_MEASUREMENT_ID,
  });
}

export async function getIdToken(): Promise<string | undefined> {
  const auth = getAuth();
  const user = auth.currentUser;
  const token = await user?.getIdToken();

  return token;
}

export async function loginWithCustomToken(token: string): Promise<User> {
  const {tenant_id: tenantId} = jwt_decode<{tenant_id: string}>(token);
  const auth = getAuthForTenant(tenantId);
  auth.tenantId = tenantId;

  const credential = await signInWithCustomToken(auth, token);
  return credential.user;
}

export async function loginWithEmailAndPassword(
  email: string,
  password: string,
  tenantId: string
): Promise<User | void> {
  const auth = getAuthForTenant(tenantId);
  const credential = await signInWithEmailAndPassword(auth, email, password);
  return credential.user;
}

export async function loginWithSsoProvider(
  tenantId: string,
  providerType: SsoProviderType,
  providerId: string
): Promise<User> {
  const auth = getAuthForTenant(tenantId);
  const provider = match(providerType)
    .with('saml', () => new SAMLAuthProvider(providerId))
    .with('oidc', () => new OAuthProvider(providerId))
    .exhaustive();

  let credential: UserCredential;
  try {
    credential = await signInWithPopup(auth, provider);
  } catch (err: unknown) {
    // If the browser is blocking the popup, we fallback to redirect
    // Error are not of type FirebaseError but FirebaseAuthError not exposed by the SDK
    if (
      typeof err === 'object' &&
      err !== null &&
      'code' in err &&
      err.code === 'auth/popup-blocked'
    ) {
      credential = await signInWithRedirect(auth, provider);
    } else {
      throw err;
    }
  }

  return credential.user;
}

export async function logout(): Promise<void> {
  await signOut(getAuth());
}

export async function signinOrganizationMember(
  firebaseUser: User
): Promise<OrganizationMemberADto> {
  const idToken = await firebaseUser.getIdToken();
  const member = await getCurrentMember(idToken);

  return member;
}

export function getAuthForTenant(tenantId: string): Auth {
  const auth = getAuth();

  auth.tenantId = tenantId;
  return auth;
}
