/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {JourneyParticipationsParamsADto} from '@cohort/admin-schemas/journeyParticipation';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {
  getJourneyParticipation,
  getJourneyParticipations,
  getParticipationAnalytics,
} from '@cohort/merchants/lib/api/JourneyParticipations';

export const JourneyParticipationKeys = {
  participations: ['journey-participations'] as const,
  getById: (merchantId: string, journeyParticipationId: string) =>
    [...JourneyParticipationKeys.participations, merchantId, journeyParticipationId] as const,
  listWithParams: (merchantId: string, params: JourneyParticipationsParamsADto) =>
    [...JourneyParticipationKeys.participations, merchantId, params] as const,
  getStatistics: (merchantId: string, campaignId: string) =>
    [...JourneyParticipationKeys.participations, merchantId, campaignId, 'statistics'] as const,
};

export const useJourneyParticipations = (
  merchantId: string,
  params: JourneyParticipationsParamsADto,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: JourneyParticipationKeys.listWithParams(merchantId, params),
    queryFn: async () => getJourneyParticipations(merchantId, params),
    ...options,
  });

export const useJourneyParticipation = (
  merchantId: string,
  journeyParticipationId: string,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: JourneyParticipationKeys.getById(merchantId, journeyParticipationId),
    queryFn: async () => getJourneyParticipation(merchantId, journeyParticipationId),
    ...options,
  });

export const useParticipationAnalytics = (
  merchantId: string,
  campaignId: string,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: JourneyParticipationKeys.getStatistics(merchantId, campaignId),
    queryFn: async () => getParticipationAnalytics(merchantId, campaignId),
    ...options,
  });
