/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {
  getStripeConnectAccount,
  getStripeConnectAccountBalance,
  getStripeConnectAccountPayoutSchedule,
} from '@cohort/merchants/lib/api/Stripe';

export const stripeKeys = {
  stripe: ['stripe'] as const,
  connectAccount: () => [...stripeKeys.stripe, 'connect-account'] as const,
  payoutSchedule: () => [...stripeKeys.connectAccount(), 'payout-schedule'] as const,
  accountBalance: () => [...stripeKeys.connectAccount(), 'account-balance'] as const,
};

export const useStripeConnectAccount = (enabled?: boolean) =>
  useCohortQuery({
    queryKey: stripeKeys.connectAccount(),
    queryFn: async () => getStripeConnectAccount(),
    enabled,
  });

export const useStripeConnectAccountPayoutSchedule = (enabled?: boolean) =>
  useCohortQuery({
    queryKey: stripeKeys.payoutSchedule(),
    queryFn: async () => getStripeConnectAccountPayoutSchedule(),
    enabled,
  });

export const useStripeConnectAccountBalance = (enabled?: boolean) =>
  useCohortQuery({
    queryKey: stripeKeys.accountBalance(),
    queryFn: async () => getStripeConnectAccountBalance(),
    enabled,
  });
