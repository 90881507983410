import type {DigitalAssetReward as DigitalAssetRewardType} from '@cohort/components-xps/components/rewards/DigitalAssetReward';
import DigitalAssetReward from '@cohort/components-xps/components/rewards/DigitalAssetReward';
import type {PerkReward as PerkRewardType} from '@cohort/components-xps/components/rewards/PerkReward';
import PerkReward from '@cohort/components-xps/components/rewards/PerkReward';
import i18nComponentsXpsInstance from '@cohort/components-xps/lib/i18n';
import type {DefaultContextDto} from '@cohort/shared/schema/templating/common';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import type {FC} from 'react';
import {getI18n, useTranslation} from 'react-i18next';
import {groupBy, mapValues, pipe} from 'remeda';

type UserOwnership = {
  digitalAsset: {
    collection: {
      id: string;
    };
  };
};

type UserPerk = {
  perk: {
    id: string;
  };
  accesses: Array<unknown>;
};

type RewardListProps = {
  userOwnerships?: Array<UserOwnership>;
  userPerks?: Array<UserPerk>;
  perks: Array<PerkRewardType>;
  collections: Array<DigitalAssetRewardType>;
  isMobile?: boolean;
  customComponent?: {
    perk?: string;
    digitalAsset?: string;
    context: DefaultContextDto;
  };
  onPerkClick?: (perkId: string) => void;
  onCollectionClick?: (ownershipId: string) => void;
  isJourneyCompleted?: boolean;
};

export const RewardList: FC<RewardListProps> = ({
  userOwnerships,
  userPerks,
  perks,
  collections,
  isMobile,
  customComponent,
  onPerkClick,
  onCollectionClick,
  isJourneyCompleted,
}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'rewardList',
    lng: getI18n().language,
    i18n: i18nComponentsXpsInstance,
  });
  const countByCollectionId = userOwnerships
    ? pipe(
        userOwnerships,
        groupBy(ownership => ownership.digitalAsset.collection.id),
        mapValues(ownerships => ownerships.length)
      )
    : null;
  const countByPerkId = userPerks
    ? pipe(
        userPerks,
        groupBy(perk => perk.perk.id),
        mapValues(perks => perks.reduce((acc, perk) => acc + perk.accesses.length, 0))
      )
    : null;

  return (
    <div className="flex flex-col gap-8">
      {collections.length > 0 && (
        <div className="flex flex-col gap-3">
          <h2>{t('digitalAssetsTitle')}</h2>
          <div className={cn('no-scrollbar overflow-x-auto', isMobile && 'flex')}>
            <div
              className={cn(
                'grid gap-4 [grid-template-columns:repeat(auto-fill,var(--xps-digital-asset-card-width))]',
                isMobile && 'grid-flow-col'
              )}
            >
              {collections.map(collection => {
                const ownershipsCount = countByCollectionId?.[collection.id] ?? 0;
                const missingFromJourney = isJourneyCompleted === true && ownershipsCount === 0;

                return (
                  <div key={collection.id} className="w-[--xps-digital-asset-card-width]">
                    <DigitalAssetReward
                      collection={collection}
                      canBeObtained={!missingFromJourney}
                      customComponent={
                        customComponent && customComponent.digitalAsset
                          ? {
                              template: customComponent.digitalAsset,
                              context: customComponent.context,
                            }
                          : undefined
                      }
                      onClick={
                        !missingFromJourney && onCollectionClick
                          ? () => onCollectionClick(collection.id)
                          : undefined
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {perks.length > 0 && (
        <div className="flex flex-col gap-3">
          <h2>{t('perksTitle')}</h2>
          <div className={cn('no-scrollbar overflow-x-auto', isMobile && 'flex')}>
            <div
              className={cn(
                'grid gap-4 [grid-template-columns:repeat(auto-fill,var(--xps-perk-card-width))]',
                isMobile && 'grid-flow-col'
              )}
            >
              {perks.map(perk => {
                const perkAccessesCount = countByPerkId?.[perk.id] ?? 0;
                const missingFromJourney = isJourneyCompleted === true && perkAccessesCount === 0;

                return (
                  <div key={perk.id} className="w-[--xps-perk-card-width]">
                    <PerkReward
                      perk={perk}
                      availableInstances={1}
                      canBeObtained={!missingFromJourney}
                      customComponent={
                        customComponent && customComponent.perk
                          ? {
                              template: customComponent.perk,
                              context: customComponent.context,
                            }
                          : undefined
                      }
                      onClick={
                        !missingFromJourney && onPerkClick ? () => onPerkClick(perk.id) : undefined
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default RewardList;
