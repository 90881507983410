import {create} from 'zustand';

export interface DigitalAssetCollectionPageStore {
  footer?: React.ReactNode;
  setFooter: (footer: React.ReactNode) => void;
}

export const useDigitalAssetCollectionPageStore = create<DigitalAssetCollectionPageStore>(set => ({
  footer: undefined,
  setFooter: footer => set({footer}),
}));
