// eslint-disable-next-line import/no-unassigned-import
import 'dayjs/locale/fr';

import ErrorBoundaryComponent from '@cohort/merchants/components/ErrorBoundary';
import {ErrorModal} from '@cohort/merchants/components/ErrorModal';
import Loader from '@cohort/merchants/components/Loader';
import Toaster from '@cohort/merchants/components/toasts/Toaster';
import {useAuthentication} from '@cohort/merchants/hooks/authentication';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import useVersionChecker from '@cohort/merchants/hooks/versionChecker';
import {languageToLocale, timeJsLocale} from '@cohort/merchants/lib/Utils';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useSearchParams} from 'react-router-dom';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const App: React.FC = () => {
  useAuthentication();
  useVersionChecker();

  const [searchParams, setSearchParams] = useSearchParams();
  const {i18n} = useTranslation();
  const isAuthenticating = useUserSessionStore(store => store.isAuthenticating);

  useEffect(() => {
    const language = searchParams.get('lng');

    if (language !== null) {
      const locale = languageToLocale(language);
      dayjs.locale(timeJsLocale(locale));
      i18n.changeLanguage(language);
      searchParams.delete('lng');
      setSearchParams(searchParams, {replace: true});
    }
  }, [i18n, searchParams, setSearchParams]);

  useEffect(() => {
    const lng = i18n.language;

    dayjs.locale(timeJsLocale(languageToLocale(lng)));
  }, [i18n.language]);

  if (isAuthenticating) {
    return (
      <div className="my-auto flex h-screen w-full items-center justify-center">
        <Loader size={50} color="gray" />
      </div>
    );
  }

  return (
    <ErrorBoundaryComponent>
      <Outlet />
      <ErrorModal />
      <Toaster />
    </ErrorBoundaryComponent>
  );
};

export default App;
