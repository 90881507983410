import type {BadgeADto} from '@cohort/admin-schemas/badge';
import Button from '@cohort/merchants/components/buttons/Button';
import RankBadge from '@cohort/merchants/components/RankBadge';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {getBadgeEditRoute, getBadgeRoute} from '@cohort/merchants/lib/Pages';
import DeleteBadge from '@cohort/merchants/pages/users/badges/badge/DeleteBadge';
import BadgeStatusOrCohortLabel from '@cohort/merchants/pages/users/badges/BadgeStatusOrCohortLabel';
import BadgeVisual from '@cohort/merchants/pages/users/badges/BadgeVisual';
import {formatDate} from '@cohort/shared/utils/format';
import Tooltip from '@cohort/shared-frontend/components/Tooltip';
import {ArrowCircleRight, EyeSlash} from '@phosphor-icons/react';
import {createColumnHelper} from '@tanstack/react-table';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

type BadgesTableProps = {
  badges: BadgeADto[];
  isLoading: boolean;
  withBadgeDeletion?: boolean;
};

const BadgesTable: React.FC<BadgesTableProps> = ({
  badges,
  isLoading,
  withBadgeDeletion = false,
}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'users.badges.badgesTable'});
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<BadgeADto>();

  const navigateBasedOnBadgeStatus = (badge: BadgeADto): void => {
    badge.status === 'draft'
      ? navigate(getBadgeEditRoute(badge.id).path)
      : navigate(getBadgeRoute(badge.id).path);
  };

  const columns = [
    columnHelper.display({
      header: undefined,
      id: 'first-col',
      cell: data => {
        const badge = data.row.original;
        return (
          <div
            className="flex items-center space-x-4 hover:cursor-pointer"
            onClick={() => navigateBasedOnBadgeStatus(badge)}
          >
            <RankBadge rank={badge.rank} />
            <BadgeVisual imageFileKey={badge.imageFileKey} />
            <div className="flex gap-2 text-sm font-medium text-slate-700">
              {badge.internalName}
              {badge.visibility === 'cohort' && (
                <Tooltip content={t('hiddenBadge')}>
                  <EyeSlash size={20} className="text-slate-500" />
                </Tooltip>
              )}
            </div>
          </div>
        );
      },
      enableSorting: false,
    }),
    columnHelper.accessor('status', {
      header: undefined,
      cell: data => (
        <div className="flex justify-end">
          <BadgeStatusOrCohortLabel badge={data.row.original} />
        </div>
      ),
      enableSorting: false,
    }),
    columnHelper.accessor('updatedAt', {
      header: undefined,
      cell: data => {
        const badge = data.row.original;
        return (
          <div className="flex justify-end">
            {badge.status === 'draft' ? (
              <Button
                variant="secondary"
                size="sm"
                onClick={() => navigate(getBadgeEditRoute(badge.id).path)}
              >
                {t('finishDraft')}
              </Button>
            ) : (
              <div className="text-xs text-slate-500">
                {t('lastEdit', {date: formatDate(badge.updatedAt)})}
              </div>
            )}
          </div>
        );
      },
      enableSorting: false,
    }),
    columnHelper.display({
      header: undefined,
      id: 'actions',
      maxSize: 32,
      cell: data => {
        const badge = data.row.original;
        return (
          <div className="flex justify-end">
            {withBadgeDeletion ? (
              <DeleteBadge badge={badge} />
            ) : (
              <Button
                variant="secondary"
                size="icon"
                onClick={() => navigate(getBadgeRoute(badge.id).path)}
              >
                <ArrowCircleRight size={20} className="text-slate-400" />
              </Button>
            )}
          </div>
        );
      },
      enableSorting: false,
    }),
  ];
  const table = useCohortTable({
    columns,
    data: badges,
  });

  return (
    <DataTable
      table={table}
      columnsLength={columns.length}
      showHeader={false}
      isLoading={isLoading}
      emptyStatePlaceholder={t('emptyPlaceholder')}
    />
  );
};

export default BadgesTable;
