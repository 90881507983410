import Loader from '@cohort/merchants/components/Loader';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@cohort/merchants/components/tables/Table';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {useAutoAnimate} from '@formkit/auto-animate/react';
import {CaretDown, CaretUp} from '@phosphor-icons/react';
import type {Column, Row, Table as TableType} from '@tanstack/react-table';
import {flexRender} from '@tanstack/react-table';
import type {CSSProperties} from 'react';
import {Fragment, useCallback, useState} from 'react';

type ComponentsClassNamesProps = {
  table?: string;
  tableCell?: string;
};

type DataTableProps<TData> = {
  table: TableType<TData>;
  emptyStatePlaceholder: string;
  columnsLength: number;
  isLoading?: boolean;
  showHeader?: boolean;
  componentsClassName?: ComponentsClassNamesProps;
  onRowClick?: (row: TData) => void;
  renderExpandedRow?: (props: {row: Row<TData>}) => React.ReactNode;
};

function computePinnedStyle<TData>(column: Column<TData>): CSSProperties {
  const isPinned = column.getIsPinned();

  return {
    boxShadow: isPinned ? '-4px 0 4px -4px hsl(var(--border)) inset' : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    position: isPinned ? 'sticky' : 'relative',
    zIndex: isPinned ? 1 : 0,
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function SortToggle<TData>({column}: {column: Column<TData>}): JSX.Element | null {
  if (!column.getCanSort()) {
    return null;
  }
  return (
    <div className="inline-flex flex-col">
      <CaretUp
        size={12}
        weight="fill"
        className={cn(column.getIsSorted() === 'asc' ? 'text-primary' : 'text-slate-400')}
      />
      <CaretDown
        size={12}
        weight="fill"
        className={cn('-mt-1', column.getIsSorted() === 'desc' ? 'text-primary' : 'text-slate-400')}
      />
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function DataTable<TData>({
  table,
  emptyStatePlaceholder,
  columnsLength,
  isLoading,
  onRowClick,
  renderExpandedRow,
  componentsClassName,
  showHeader = true,
}: DataTableProps<TData>): JSX.Element {
  const [hasScrolled, setHasScrolled] = useState(false);
  const tableWrapperHandler = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      const handleScroll = (): void => {
        const hasScrolled = node.scrollLeft !== 0;

        setHasScrolled(hasScrolled);
      };

      node.addEventListener('scroll', handleScroll);
    }
  }, []);
  const [parent] = useAutoAnimate();

  return (
    <Table
      tableWrapperRef={tableWrapperHandler}
      style={{
        minWidth: table.getTotalSize(),
      }}
      className={componentsClassName?.table}
    >
      {showHeader && (
        <TableHeader>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <TableHead
                    key={header.id}
                    className={cn(header.column.getCanSort() && 'cursor-pointer select-none')}
                    style={{
                      ...(hasScrolled && computePinnedStyle(header.column)),
                      width: header.column.getSize(),
                    }}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className="flex items-center gap-2">
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}

                      <SortToggle column={header.column} />
                    </div>
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
      )}
      <TableBody className={cn(!showHeader && 'border-t')}>
        {table.getRowModel().rows.length ? (
          table.getRowModel().rows.map(row => (
            <Fragment key={row.id}>
              <TableRow
                data-state={row.getIsSelected() && 'selected'}
                onClick={() => onRowClick?.(row.original)}
                className={cn(onRowClick && 'cursor-pointer')}
              >
                {row.getVisibleCells().map(cell => (
                  <TableCell
                    key={cell.id}
                    style={{
                      ...(hasScrolled && computePinnedStyle(cell.column)),
                      width: cell.column.getSize(),
                    }}
                    className={componentsClassName?.tableCell}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
              {renderExpandedRow && (
                <TableRow ref={parent} className="hover:bg-background">
                  {row.getIsExpanded() && (
                    <TableCell
                      colSpan={row.getVisibleCells().length}
                      className={componentsClassName?.tableCell}
                    >
                      {renderExpandedRow({row})}
                    </TableCell>
                  )}
                </TableRow>
              )}
            </Fragment>
          ))
        ) : (
          <TableRow>
            <TableCell
              colSpan={columnsLength}
              className={cn('h-24 text-center', componentsClassName?.tableCell)}
            >
              {isLoading ? (
                <div className="inline-block">
                  <Loader color="gray" />
                </div>
              ) : (
                emptyStatePlaceholder
              )}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
