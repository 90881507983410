import {DateSchema, LocalizedStringSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const CreateContentCategoryASchema = z.object({
  internalName: z.string(),
  title: LocalizedStringSchema,
});
export type CreateContentCategoryADto = z.infer<typeof CreateContentCategoryASchema>;

export const PatchContentCategoryASchema = z.object({
  internalName: z.string().optional(),
  title: LocalizedStringSchema.optional(),
});
export type PatchContentCategoryADto = z.infer<typeof PatchContentCategoryASchema>;

export const ReorderContentCategoriesASchema = z.array(z.string().uuid());
export type ReorderContentCategoriesADto = z.infer<typeof ReorderContentCategoriesASchema>;

export const ContentCategoryASchema = z.object({
  id: z.string().uuid(),
  merchantId: z.string().uuid(),
  mode: z.string(),
  internalName: z.string(),
  title: LocalizedStringSchema,
  rank: z.number(),
  createdAt: DateSchema,
  updatedAt: DateSchema,
});
export type ContentCategoryADto = z.infer<typeof ContentCategoryASchema>;

export const ContentCategoriesResponseASchema = z.array(ContentCategoryASchema);
export type ContentCategoriesResponseADto = z.infer<typeof ContentCategoriesResponseASchema>;
