import type {CampaignADto} from '@cohort/admin-schemas/campaign';
import type {Price} from '@cohort/shared/schema/common/currency';
import {CurrencySpecs} from '@cohort/shared/schema/common/currency';
import Big from 'big.js';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

const PriceDisplay: React.FC<{price: Price}> = ({price}) => (
  <p className="font-medium">
    {CurrencySpecs.toDecimal(price.currency, price.amount)}
    &nbsp;{price.currency.toUpperCase()}
  </p>
);

const PriceSectionContent: React.FC<{campaign: CampaignADto}> = ({campaign}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.review.priceSectionContent',
  });

  if (!campaign.store || campaign.store.pricing === 'free') {
    return <p>{t('free')}</p>;
  }

  const defaultPrice = campaign.store.prices.find(
    price => price.currency === campaign.store?.defaultCurrency
  );

  return (
    <Fragment>
      {campaign.store.prices.map(price => (
        <PriceDisplay key={price.currency} price={price} />
      ))}
      {campaign.totalSupply && defaultPrice && (
        <p className="text-gray-500">
          {t('revenueExpected', {
            revenue: CurrencySpecs.toDecimal(
              defaultPrice.currency,
              Big(defaultPrice.amount).mul(campaign.totalSupply)
            ),
            currency: defaultPrice.currency.toUpperCase(),
          })}
        </p>
      )}
    </Fragment>
  );
};

export default PriceSectionContent;
