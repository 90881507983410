import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import AirdropActivityPage from '@cohort/merchants/pages/campaigns/campaign/activity/airdrop/AirdropActivityPage';
import JourneyActivityPage from '@cohort/merchants/pages/campaigns/campaign/activity/journey/JourneyActivityPage';
import StoreActivityPage from '@cohort/merchants/pages/campaigns/campaign/activity/store/StoreActivityPage';
import {Fragment} from 'react';
import {match} from 'ts-pattern';

const CampaignActivityPage = (): JSX.Element => {
  const campaign = useCurrentCampaign();

  return match(campaign.type)
    .with('store', () => <StoreActivityPage />)
    .with('journey', () => <JourneyActivityPage />)
    .with('airdrop', () => <AirdropActivityPage />)
    .with(null, () => <Fragment />)
    .exhaustive();
};

export default CampaignActivityPage;
