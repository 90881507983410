import {getYoutubeCommentUrl, getYoutubeProfileUrl} from '@cohort/shared/apps/youtube/common';
import type {YoutubeCommentVerificationAttemptData} from '@cohort/shared/apps/youtube/triggers/comment';
import {Trans} from 'react-i18next';

const YoutubeCommentTriggerStepCompletionTitleComponent: React.FC<{
  data: YoutubeCommentVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  const {channelId, channelTitle, comment} = data;
  const linkClassName = 'font-semibold text-gray-900';
  const linkRel = 'noopener noreferrer nofollow';

  if (!completionSuccess || comment === null) {
    return (
      <Trans
        i18nKey="triggerIntegrations.comment.stepCompletionTitleComponent.error"
        ns="app-youtube"
        values={{username: channelTitle}}
        components={{
          userLink: (
            <a
              className={linkClassName}
              target="_blank"
              rel={linkRel}
              href={getYoutubeProfileUrl(channelId)}
            />
          ),
        }}
      />
    );
  }

  return (
    <Trans
      i18nKey="triggerIntegrations.comment.stepCompletionTitleComponent.success"
      ns="app-youtube"
      values={{username: channelTitle}}
      components={{
        userLink: (
          <a
            className={linkClassName}
            target="_blank"
            rel={linkRel}
            href={getYoutubeProfileUrl(channelId)}
          />
        ),
        commentLink: (
          <a
            className={linkClassName}
            target="_blank"
            rel={linkRel}
            href={getYoutubeCommentUrl(comment.videoId, comment.id)}
          />
        ),
      }}
    />
  );
};

export default YoutubeCommentTriggerStepCompletionTitleComponent;
