import {cn} from '@cohort/shared-frontend/utils/classNames';
import React from 'react';

interface VideoPlayerProps {
  imgUrl: string | null;
  videoFileKey: string;
  displayName: string;
  enforce16x9AspectRatio?: boolean;
  layout?: 'row' | 'column';
  controls?: boolean;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  displayName,
  imgUrl,
  videoFileKey,
  enforce16x9AspectRatio,
  layout = 'row',
  controls = true,
}: VideoPlayerProps): React.ReactElement => (
  <div className="h-full">
    <video
      controls={controls}
      className={cn(
        'w-full overflow-clip object-cover',
        enforce16x9AspectRatio && 'aspect-video',
        layout === 'row' ? 'h-full' : 'h-auto'
      )}
      autoPlay
      playsInline
      loop
      muted
      controlsList="nodownload"
      disablePictureInPicture
    >
      <source src={videoFileKey} />
      {imgUrl !== null && (
        <img
          title={displayName}
          src={imgUrl}
          className="w-full"
          crossOrigin="anonymous"
          alt={displayName}
        />
      )}
    </video>
  </div>
);
