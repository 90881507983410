import type {CampaignADto} from '@cohort/admin-schemas/campaign';
import {useCampaign} from '@cohort/merchants/hooks/api/Campaigns';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {createContext, Fragment} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

type CurrentCampaignProviderProps = {
  children: React.ReactNode;
};

export const CurrentCampaignContext = createContext<CampaignADto | null>(null);

export const CurrentCampaignProvider: React.FC<CurrentCampaignProviderProps> = ({children}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {campaignId} = useParams();
  const navigate = useNavigate();

  const {data: campaign, isFetched} = useCampaign(
    merchantId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    campaignId!,
    {enabled: Boolean(campaignId), refetchOnWindowFocus: false}
  );

  if (campaignId === undefined) {
    navigate('/not-found', {replace: true});
  }

  if (isFetched && !campaign) {
    navigate('/not-found', {replace: true});
  }

  // Maybe one day we could create Skeletons for page loading
  if (campaign === undefined) {
    return <Fragment />;
  }

  return (
    <CurrentCampaignContext.Provider value={campaign}>{children}</CurrentCampaignContext.Provider>
  );
};
