import type {CreateQrReadersADto} from '@cohort/admin-schemas/qrcode';
import Button from '@cohort/merchants/components/buttons/Button';
import CsvOrTextInput from '@cohort/merchants/components/form/CsvOrTextInput';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@cohort/merchants/components/modals/Dialog';
import {qrReadersKeys} from '@cohort/merchants/hooks/api/QrReaders';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {createQrReaders} from '@cohort/merchants/lib/api/QrReaders';
import {splitByCommaOrNewLine} from '@cohort/merchants/lib/form/utils';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

export const CreateQrReadersFormSchema = z.object({
  names: z.string().refine(value => {
    const names = splitByCommaOrNewLine(value);
    for (const name of names) {
      if (name.length < 3) {
        return false;
      }
    }
    return true;
  }, 'errorNamesList'),
});

type CreateQrReadersFormValues = z.infer<typeof CreateQrReadersFormSchema>;

interface CreateQrReadersModalProps {
  merchantId: string;
  show: boolean;
  onClose: () => void;
}

const CreateQrReadersModal: React.FC<CreateQrReadersModalProps> = ({merchantId, show, onClose}) => {
  const queryClient = useQueryClient();
  const {t} = useTranslation('pages', {keyPrefix: 'settings.qrReaders.createQrReadersModal'});

  const {register, control, watch, handleSubmit, reset} = useForm<CreateQrReadersFormValues>({
    resolver: zodResolver(CreateQrReadersFormSchema),
    defaultValues: {
      names: '',
    },
  });

  const names = watch('names');

  const {isLoading, mutate: submitQrReadersCreation} = useCohortMutation({
    mutationFn: async (data: CreateQrReadersADto) => createQrReaders(merchantId, data),
    notifyErrorMessage: t('notificationError'),
    notifySuccessMessage: t('notificationSuccess'),
    onSuccess: async () => {
      await queryClient.invalidateQueries(qrReadersKeys.list(merchantId));
      onClose();
      reset({}, {keepValues: false});
    },
  });

  return (
    <Dialog open={show}>
      <DialogContent displayCloseButton={false}>
        <form
          className="w-[600px]"
          onSubmit={handleSubmit(data => {
            const names = splitByCommaOrNewLine(data.names);
            submitQrReadersCreation(names.map(name => ({name: name})));
          })}
        >
          <DialogHeader>
            <DialogTitle>{t('title')}</DialogTitle>
            <DialogDescription>{t('description')}</DialogDescription>
          </DialogHeader>
          <DialogBody>
            <CsvOrTextInput
              name="names"
              register={register}
              control={control}
              validator={(name: string) => name.length >= 3}
              label={t('label')}
              placeholder={t('placeholder')}
            />
          </DialogBody>
          <DialogFooter className="flex items-center justify-end gap-4">
            <Button variant="secondary" onClick={onClose} disabled={isLoading}>
              {t('buttonCancel')}
            </Button>
            <Button type="submit" loading={isLoading} disabled={names.length === 0}>
              {t('buttonCreate')}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateQrReadersModal;
