import type {ConnectionADto} from '@cohort/admin-schemas/connection';
import type {SyncADto} from '@cohort/admin-schemas/sync';
import {SyncStatusBadge} from '@cohort/merchants/components/syncs/SyncStatusBadge';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {getAppSyncRoute} from '@cohort/merchants/lib/Pages';
import SyncDropdown from '@cohort/merchants/pages/apps/app/syncs/edit/SyncDropdown';
import {formatDate} from '@cohort/shared/utils/format';
import {createColumnHelper} from '@tanstack/react-table';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

type SyncsTableProps = {
  syncs: SyncADto[];
  connections: ConnectionADto[];
  isLoading: boolean;
};

const SyncsTable: React.FC<SyncsTableProps> = ({syncs, connections, isLoading}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'apps.app.syncs.syncsTable'});
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<SyncADto>();

  const columns = [
    columnHelper.accessor('status', {
      header: t('tableHeaderStatus'),
      minSize: 200,
      enableSorting: false,
      cell: data => <SyncStatusBadge status={data.getValue()} />,
    }),
    columnHelper.accessor(row => connections.find(c => c.id === row.connectionId)?.name || '', {
      header: t('tableHeaderConnection'),
      minSize: 200,
      enableSorting: false,
    }),
    columnHelper.accessor(row => formatDate(row.createdAt), {
      header: t('tableHeaderCreatedAt'),
      minSize: 200,
      enableSorting: false,
    }),
    columnHelper.accessor(row => (row.lastRunAt !== null ? formatDate(row.lastRunAt) : '-'), {
      header: t('tableHeaderLastRunAt'),
      minSize: 200,
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => null,
      cell: data => (
        <div className="flex items-center justify-end gap-4">
          <SyncDropdown sync={data.row.original} />
        </div>
      ),
    }),
  ];

  const table = useCohortTable({
    data: syncs,
    columns,
  });

  return (
    <Fragment>
      <DataTable
        table={table}
        columnsLength={columns.length}
        emptyStatePlaceholder={t('emptyPlaceholder')}
        isLoading={isLoading}
        onRowClick={row => navigate(getAppSyncRoute(row.appId, row.id).path)}
        componentsClassName={{table: 'mb-[70px]'}}
      />
    </Fragment>
  );
};

export default SyncsTable;
