import {MediaCover} from '@cohort/components-xps/components/cards/MediaCover';
import {VideoPlayer} from '@cohort/components-xps/components/cards/VideoPlayer';
import {isUrl} from '@cohort/shared/typeUtils';
import {getImageUrl, getVideoUrl, Sizes} from '@cohort/shared/utils/media';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {isFile} from '@cohort/shared-frontend/utils/isFile';
import {useMemo, useState} from 'react';

type CardVisualProps = {
  bannerFileKey: string | null | File;
  thumbnailFileKey: string | null | File;
  videoFileKey: string | null | File;
  visualName: string;
  enforce16x9AspectRatio?: boolean;
  autoPlayVideo?: boolean;
  layout?: 'row' | 'column';
  className?: string;
};

const CardVisual: React.FC<CardVisualProps> = ({
  bannerFileKey,
  thumbnailFileKey,
  videoFileKey,
  visualName,
  enforce16x9AspectRatio = false,
  autoPlayVideo = false,
  layout = 'row',
  className,
}) => {
  const [showVideoPlayer, setShowVideoPlayer] = useState(autoPlayVideo);

  const imgUrl = useMemo((): string | null => {
    if (bannerFileKey !== null) {
      if (isFile(bannerFileKey)) {
        return URL.createObjectURL(bannerFileKey);
      }
      return getImageUrl(import.meta.env.COHORT_ENV, bannerFileKey, {
        h: Sizes.M,
        w: Sizes.M,
      });
    } else if (thumbnailFileKey !== null) {
      if (isFile(thumbnailFileKey)) {
        return URL.createObjectURL(thumbnailFileKey);
      }

      return getImageUrl(import.meta.env.COHORT_ENV, thumbnailFileKey, {
        h: Sizes.M,
        w: Sizes.M,
      });
    }
    return null;
  }, [bannerFileKey, thumbnailFileKey]);

  const videoUrl = useMemo((): string | null => {
    if (videoFileKey !== null) {
      if (isFile(videoFileKey)) {
        return URL.createObjectURL(videoFileKey);
      }
      if (isUrl(videoFileKey)) {
        return videoFileKey;
      }
      return getVideoUrl(import.meta.env.COHORT_ENV, videoFileKey, {
        h: Sizes.M,
        w: Sizes.M,
      });
    }
    return null;
  }, [videoFileKey]);

  const handlePlayVideoClick = (): void => {
    setShowVideoPlayer(true);
  };

  return (
    <div
      className={cn(
        'h-full w-full object-cover',
        videoFileKey !== null && !autoPlayVideo && 'cursor-pointer',
        className
      )}
    >
      {videoUrl !== null && showVideoPlayer ? (
        <VideoPlayer
          imgUrl={imgUrl}
          displayName={visualName}
          videoFileKey={videoUrl}
          enforce16x9AspectRatio={enforce16x9AspectRatio}
          layout={layout}
          controls={!autoPlayVideo}
        />
      ) : (
        <MediaCover
          imgUrl={imgUrl}
          bannerFileKey={bannerFileKey}
          displayName={visualName}
          videoFileKey={videoFileKey}
          handlePlayVideoClick={() => handlePlayVideoClick()}
          enforce16x9AspectRatio={enforce16x9AspectRatio}
          layout={layout}
        />
      )}
    </div>
  );
};

export default CardVisual;
