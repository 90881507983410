import type {PerkIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {assertConnectionId} from '@cohort/merchants/apps';
import {useShopifyShop} from '@cohort/merchants/apps/shopify/api';
import ErrorState from '@cohort/merchants/components/ErrorState';
import Input from '@cohort/merchants/components/form/input/Input';
import RadioCards from '@cohort/merchants/components/form/RadioCards';
import Loader from '@cohort/merchants/components/Loader';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {getCurrencySymbol} from '@cohort/merchants/lib/Utils';
import type {PerkFormValues} from '@cohort/merchants/pages/perks/perk/edit/utils';
import type {ShopifyShop} from '@cohort/shared/apps/shopify/actions/getShop';
import {Fragment} from 'react';
import type {Control, UseFormRegister} from 'react-hook-form';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {isNil} from 'remeda';
import {match} from 'ts-pattern';

type ConfigFormProps = {
  register: UseFormRegister<PerkFormValues>;
  control: Control<PerkFormValues>;
  shop: ShopifyShop;
};

const ConfigForm: React.FC<ConfigFormProps> = ({register, control, shop}) => {
  const {t} = useTranslation('app-shopify', {
    keyPrefix: 'perkIntegrations.discount.configComponent',
  });

  const {field: discountType} = useController({control, name: 'integration.config.type'});
  const {field: discountValue} = useController({control, name: 'integration.config.value'});
  const {field: singleUse} = useController({control, name: 'integration.config.singleUse'});

  if (isNil(discountType.value)) {
    discountType.onChange('percentage');
  }

  if (isNil(discountValue.value)) {
    discountValue.onChange(0);
  }

  if (isNil(singleUse.value)) {
    singleUse.onChange(false);
  }

  return (
    <Fragment>
      <Input
        label={t('labelDiscount')}
        name="integration.config.name"
        register={register}
        control={control}
      />
      <div className="w-[500px]">
        <RadioCards
          name="integration.config.type"
          direction="row"
          label={t('labelType')}
          register={register}
          control={control}
          options={[
            {
              label: t('labelPercentage'),
              value: 'percentage',
            },
            {
              label: t('labelAmount'),
              value: 'fixed-amount',
            },
          ]}
        />
      </div>
      <div className="w-[200px]">
        <Input
          insetPrefix={{
            component: (
              <div className="pointer-events-none absolute inset-y-0 left-0 mb-px ml-px mt-px flex items-center rounded-bl-md rounded-tl-md border-r bg-slate-50 px-3 text-sm text-slate-500">
                {discountType.value === 'percentage' ? '%' : getCurrencySymbol(shop.currency)}
              </div>
            ),
            size: 40,
          }}
          type="number"
          label={t('labelValue')}
          name="integration.config.value"
          register={register}
          control={control}
          min={0}
          max={discountType.value === 'percentage' ? 100 : undefined}
        />
      </div>
      <Input
        type="checkbox"
        name="integration.config.singleUse"
        label={t('labelLimit')}
        labelPosition="right"
        register={register}
        control={control}
      />
    </Fragment>
  );
};

const ShopifyDiscountPerkIntegrationConfigComponent: React.FC<
  PerkIntegrationConfigComponentProps
> = ({formReturn: {register, control}, connectionId}) => {
  assertConnectionId(connectionId);

  const merchant = useCurrentMerchant();

  const {
    data: shopData,
    isLoading: isShopLoading,
    isError: isShopError,
  } = useShopifyShop(merchant.id, connectionId);

  const formContent = match({
    isShopError,
    isShopLoading: isShopLoading || shopData === undefined,
  })
    .with({isShopLoading: true}, () => <Loader size={30} color="gray" />)
    .with({isShopError: true}, () => <ErrorState />)
    .otherwise(() => (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      <ConfigForm register={register} control={control} shop={shopData!.output} />
    ));

  return <div className="space-y-6">{formContent}</div>;
};

export default ShopifyDiscountPerkIntegrationConfigComponent;
