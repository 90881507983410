import {IconBadge} from '@cohort/merchants/components/IconBadge';
import {Users} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

type DigitalAssetCollectionOwnersBadgeProps = {
  ownersCount: number;
  size?: 'large' | 'small';
};

const DigitalAssetCollectionOwnersBadge: React.FC<DigitalAssetCollectionOwnersBadgeProps> = ({
  ownersCount,
  size = 'small',
}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'digitalAssets.digitalAssetCollectionOwnersBadge',
  });

  return (
    <IconBadge
      size={size}
      icon={<Users className="mr-1 inline-block h-4 w-4 text-slate-500" />}
      text={ownersCount === 0 ? t('labelNoOwners') : t('labelOwners', {count: ownersCount})}
    />
  );
};

export default DigitalAssetCollectionOwnersBadge;
