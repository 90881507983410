import {useStripeConnectAccount} from '@cohort/merchants/hooks/api/Stripe';
import {useTestModeStore} from '@cohort/merchants/hooks/stores/testMode';
import {getSettingsRoute} from '@cohort/merchants/lib/Pages';
import {trackPaymentSettingsPageViewed} from '@cohort/merchants/lib/Tracking';
import PaymentSettings from '@cohort/merchants/pages/settings/payments/PaymentSettings';
import PaymentSettingsNewAccount from '@cohort/merchants/pages/settings/payments/PaymentSettingsNewAccount';
import PaymentSettingsPendingAccount from '@cohort/merchants/pages/settings/payments/PaymentSettingsPendingAccount';
import {Fragment, useEffect} from 'react';
import {Navigate} from 'react-router-dom';

const PaymentSettingsPage = (): JSX.Element => {
  const testModeEnabled = useTestModeStore(state => state.testModeEnabled);
  const {data, isFetched} = useStripeConnectAccount(!testModeEnabled);

  useEffect(() => {
    if (!testModeEnabled) {
      trackPaymentSettingsPageViewed();
    }
  }, [testModeEnabled]);

  if (testModeEnabled) {
    return <Navigate to={getSettingsRoute().path} />;
  }

  if (!isFetched) {
    return <Fragment />;
  }

  if (data?.account === null) {
    return <PaymentSettingsNewAccount />;
  }

  if (data?.account && !data.account.ready) {
    return <PaymentSettingsPendingAccount account={data.account} />;
  }

  return <PaymentSettings />;
};

export default PaymentSettingsPage;
