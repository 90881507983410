import {notify} from '@cohort/merchants/hooks/toast';
import {CheckCircle, Clipboard} from '@phosphor-icons/react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

const CopyToClipboard: React.FC<{target: string}> = ({target}) => {
  const {t} = useTranslation('components', {keyPrefix: 'buttons.copyToClipboard'});
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  return copied ? (
    <CheckCircle className="inline-block h-5 w-5 align-sub text-green-600" />
  ) : (
    <button
      className="align-sub"
      onClick={async () => {
        await navigator.clipboard.writeText(target);
        notify('success', t('copied'));
        setCopied(true);
      }}
    >
      <Clipboard className="h-5 w-5 text-slate-500" />
    </button>
  );
};

export default CopyToClipboard;
