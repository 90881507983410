import Button from '@cohort/merchants/components/buttons/Button';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import RadioCards from '@cohort/merchants/components/form/RadioCards';
import LocalizedTextEditorInput from '@cohort/merchants/components/form/textEditor/LocalizedTextEditorInput';
import {useCurrentBadge} from '@cohort/merchants/hooks/contexts/currentBadge';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {notify} from '@cohort/merchants/hooks/toast';
import {
  changeSelectedLanguageIfNeeded,
  getDefinedLanguages,
} from '@cohort/merchants/lib/form/localization';
import {handleFormErrors} from '@cohort/merchants/lib/form/utils';
import {getBadgesRoute} from '@cohort/merchants/lib/Pages';
import type {BadgeFormStepProps} from '@cohort/merchants/pages/users/badges/badge/edit/utils';
import {
  BadgeVisibilityStepSchema,
  SaveBadgeVisibilityForLaterRefinement,
  TransformedBadgeVisibilityStepSchema,
} from '@cohort/merchants/pages/users/badges/badge/edit/utils';
import type {BadgeVisibility} from '@cohort/shared/schema/common/badges';
import {zodResolver} from '@hookform/resolvers/zod';
import {EyeSlash} from '@phosphor-icons/react';
import {Eye} from '@phosphor-icons/react/dist/ssr';
import {Fragment, useEffect, useState} from 'react';
import {useController, useForm, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {z} from 'zod';

type BadgeVisibilityStepValues = z.infer<typeof BadgeVisibilityStepSchema>;

const BadgeVisibilityStep: React.FC<BadgeFormStepProps> = ({
  isProcessing,
  setStepSaveBtn,
  onStepValidated,
  updateBadgeMutation,
}) => {
  const [lockedDescriptionKey, setLockedDescriptionKey] = useState<string>(Date.now().toString());
  const merchant = useCurrentMerchant();
  const badge = useCurrentBadge();
  const {t} = useTranslation('pages', {keyPrefix: 'users.badges.badge.edit.badgeVisibilityStep'});
  const navigate = useNavigate();

  const {register, control, handleSubmit, setValue, getValues, setError, clearErrors} =
    useForm<BadgeVisibilityStepValues>({
      defaultValues: {
        descriptionType:
          badge.lockedDescription[merchant.defaultLanguage] !== undefined ? 'custom' : 'badge',
        visibility: badge.visibility,
        lockedDescription:
          badge.visibility === 'everyone' &&
          badge.lockedDescription[merchant.defaultLanguage] !== undefined
            ? badge.lockedDescription
            : badge.description,
        description: badge.description,
        defaultLanguage: merchant.defaultLanguage,
        selectedLanguage: merchant.defaultLanguage,
        definedLanguages: getDefinedLanguages(merchant.defaultLanguage, [
          badge.description,
          badge.name,
        ]),
      },
      resolver: zodResolver(BadgeVisibilityStepSchema),
    });

  const {field: selectedLanguageField} = useController({
    control,
    name: 'selectedLanguage',
  });

  const {field: visibilityField} = useController({
    control,
    name: 'visibility',
  });

  const {field: descriptionTypeField} = useController({
    control: control,
    name: 'descriptionType',
  });

  useEffect(() => {
    setStepSaveBtn?.(
      <Button
        variant="secondary"
        loading={isProcessing}
        onClick={async () => {
          try {
            const badgeData = BadgeVisibilityStepSchema.partial()
              .superRefine(SaveBadgeVisibilityForLaterRefinement)
              .parse(getValues());
            clearErrors();
            await updateBadgeMutation(badgeData);
            notify('success', t('notificationSuccess'));
            navigate(getBadgesRoute().path);
          } catch (error) {
            if (error instanceof z.ZodError) {
              handleFormErrors(error, clearErrors, setError);
              changeSelectedLanguageIfNeeded(
                error,
                merchant.defaultLanguage,
                selectedLanguageField.onChange
              );
            } else {
              throw error;
            }
          }
        }}
      >
        {t('saveBtn')}
      </Button>
    );
  }, [
    setStepSaveBtn,
    t,
    isProcessing,
    updateBadgeMutation,
    getValues,
    clearErrors,
    setError,
    navigate,
    merchant.defaultLanguage,
    selectedLanguageField.onChange,
  ]);

  const selectedLanguage = useWatch({
    control,
    name: 'selectedLanguage',
  });

  if (selectedLanguage === undefined) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <form
        className="space-y-8"
        id="badge-visibility-step"
        onSubmit={handleSubmit(
          async data => {
            try {
              if (
                visibilityField.value === 'everyone' &&
                descriptionTypeField.value === 'custom' &&
                data.lockedDescription[selectedLanguage] === undefined
              ) {
                setError('lockedDescription', {
                  type: 'required',
                  message: t('lockedDescriptionRequired'),
                });
                return;
              }
              const updatedLockedDescription = {
                lockedDescription:
                  descriptionTypeField.value === 'custom' ? data.lockedDescription : {},
              };
              const transformedData = TransformedBadgeVisibilityStepSchema.parse({
                ...badge,
                ...data,
                ...updatedLockedDescription,
              });
              await updateBadgeMutation({
                ...transformedData,
                ...(badge.status === 'draft' ? {status: 'published'} : {}),
              });
              onStepValidated();
            } catch (error) {
              if (error instanceof z.ZodError) {
                handleFormErrors(error, clearErrors, setError);
                changeSelectedLanguageIfNeeded(error, merchant.defaultLanguage, lang =>
                  setValue('selectedLanguage', lang)
                );
              } else {
                throw error;
              }
            }
          },
          errors => {
            changeSelectedLanguageIfNeeded(errors, merchant.defaultLanguage, lang =>
              setValue('selectedLanguage', lang)
            );
          }
        )}
      >
        <RadioCards
          name="visibility"
          label={t('labelVisibility')}
          direction="row"
          register={register}
          control={control}
          onChange={e => {
            const visibility = e.target.value as BadgeVisibility;
            if (visibility === 'cohort') {
              setValue('lockedDescription', {});
            }
          }}
          options={[
            {
              label: t('labelEveryone'),
              // 460px to avoid a width change when selecting a different radio card (icon appears/disappears)
              description: <div className="w-[460px]">{t('descriptionEveryone')}</div>,
              value: 'everyone',
              prefix: (
                <Eye
                  width={40}
                  height={40}
                  className="mb-auto rounded-md bg-slate-50 p-2 text-muted-foreground"
                />
              ),
            },
            {
              label: t('labelCohort'),
              description: <div className="w-[460px]">{t('descriptionCohort')}</div>,
              value: 'cohort',
              prefix: (
                <EyeSlash
                  width={40}
                  height={40}
                  className="mb-auto rounded-md bg-slate-50 p-2 text-muted-foreground"
                />
              ),
            },
          ]}
        />

        {visibilityField.value === 'everyone' && (
          <Fragment>
            <RadioCards
              name="descriptionType"
              label={t('labelLockedDescription')}
              direction="row"
              register={register}
              control={control}
              onChange={e => {
                const descriptionType = e.target.value;
                if (descriptionType === 'badge') {
                  setValue('lockedDescription', badge.description);
                } else {
                  setValue('lockedDescription', badge.lockedDescription);
                }
                setLockedDescriptionKey(Date.now().toString());
              }}
              options={[
                {
                  label: t('labelSameDescription'),
                  value: 'badge',
                },
                {
                  label: t('labelCustomDescription'),
                  value: 'custom',
                },
              ]}
            />
            <LanguageSelectorInput
              control={control}
              definedLanguagesPath="definedLanguages"
              selectedLanguagePath="selectedLanguage"
              disabled={descriptionTypeField.value !== 'custom'}
            />
            {descriptionTypeField.value === 'custom' ? (
              <LocalizedTextEditorInput
                // Used to update the values of the editor when the description type changes.
                key={lockedDescriptionKey}
                name="lockedDescription"
                register={register}
                control={control}
                selectedLanguage={selectedLanguage}
              />
            ) : (
              <LocalizedTextEditorInput
                name="description"
                register={register}
                control={control}
                selectedLanguage={selectedLanguage}
                disabled
              />
            )}
          </Fragment>
        )}
      </form>
    </Fragment>
  );
};

export default BadgeVisibilityStep;
