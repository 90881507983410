import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {Fragment} from 'react';
import type {Control, Path, UseFormRegister} from 'react-hook-form';
import {useController, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type OwnershipsSettingsInputForm = {
  nftEnabled: boolean;
  withMaxOwnershipsPerUser: boolean;
  maxOwnershipsPerUser: number | null;
};

type DigitalAssetCollectionOwnershipsInputProps<T extends OwnershipsSettingsInputForm> = {
  control: Control<T>;
  register: UseFormRegister<T>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function DigitalAssetCollectionOwnershipsInput<
  T extends OwnershipsSettingsInputForm,
>({control, register}: DigitalAssetCollectionOwnershipsInputProps<T>): JSX.Element {
  const {t} = useTranslation('pages', {
    keyPrefix:
      'digitalAssetCollections.digitalAssetCollection.digitalAssetCollectionOwnershipsInput',
  });
  const {field: maxOwnershipsPerUser} = useController({
    control,
    name: 'maxOwnershipsPerUser' as Path<T>,
  });
  const nftEnabled = useWatch({control, name: 'nftEnabled' as Path<T>});

  return (
    <Fragment>
      <div className="grid items-center gap-4 [grid-template-columns:max-content_1fr]">
        <SwitchInput
          id="withMaxOwnershipsPerUser"
          name={'withMaxOwnershipsPerUser' as Path<T>}
          register={register}
          control={control}
          onCheckedChange={checked => maxOwnershipsPerUser.onChange(checked ? 1 : null)}
        />
        <div>
          <p className="text-sm font-medium text-slate-700">{t('labelMaxOwnershipsPerUser')}</p>
          <p className="text-sm text-slate-500">{t('descriptionMaxOwnershipsPerUser')}</p>
        </div>
      </div>
      {nftEnabled && <HighlightText text={t('hintMaxOwnershipsPerUser')} type="info" />}
    </Fragment>
  );
}
