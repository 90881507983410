import type {CreatePerkRequestADto} from '@cohort/admin-schemas/perk';
import Button from '@cohort/merchants/components/buttons/Button';
import Form from '@cohort/merchants/components/form/Form';
import Input from '@cohort/merchants/components/form/input/Input';
import {Dialog, DialogContent, DialogTitle} from '@cohort/merchants/components/modals/Dialog';
import {perksKeys} from '@cohort/merchants/hooks/api/Perks';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {createPerk} from '@cohort/merchants/lib/api/Perks';
import {getPerkEditRoute} from '@cohort/merchants/lib/Pages';
import {trackPerkCreateSuccess} from '@cohort/merchants/lib/Tracking';
import type {PerkType} from '@cohort/shared/schema/common/perks';
import {useQueryClient} from '@tanstack/react-query';
import React, {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import z from 'zod';

interface CreatePerkModalProps {
  type: PerkType;
  onClose: () => void;
}

export const PerkInternalNameFormSchema = z.object({
  internalName: z.string().min(3, {message: 'errorTooShort3'}),
});

const CreatePerkModal: React.FC<CreatePerkModalProps> = ({onClose, type}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {t} = useTranslation('pages', {keyPrefix: 'perks.new.createPerkModal'});
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {isLoading, mutate: createPerkMutation} = useCohortMutation({
    mutationFn: async (data: CreatePerkRequestADto) => createPerk(merchantId, data),
    onSuccess: async newPerk => {
      await queryClient.invalidateQueries(perksKeys.list(merchantId));
      trackPerkCreateSuccess(newPerk);
      const url = getPerkEditRoute(newPerk.id).path;
      navigate(url);
    },
  });

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <Form
          className="p-6"
          formSchema={PerkInternalNameFormSchema}
          defaultValues={{
            internalName: t('placeholderInternalName'),
          }}
          isSubmitting={isLoading}
          onSubmit={data => createPerkMutation({...data, type})}
          formActions={[
            <Button
              className="h-10 w-full"
              data-testid="create-perk-button"
              type="submit"
              disabled={false}
            >
              {t('buttonCreate')}
            </Button>,
          ]}
        >
          {({register, control}) => (
            <Fragment>
              <div className="flex flex-col justify-center pb-4 text-center">
                <DialogTitle className="text-lg font-bold leading-6">{t('title')}</DialogTitle>
              </div>
              <div className="mb-6 mt-4">
                <Input
                  type="text"
                  name="internalName"
                  register={register}
                  control={control}
                  description={t('contextInternalName')}
                />
              </div>
            </Fragment>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreatePerkModal;
