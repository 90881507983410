import Button from '@cohort/merchants/components/buttons/Button';
import {CohortTypeBadge} from '@cohort/merchants/components/cohorts/CohortTypeBadge';
import SelectCohort from '@cohort/merchants/components/cohorts/SelectCohort';
import {IconBadge} from '@cohort/merchants/components/IconBadge';
import {useCohort} from '@cohort/merchants/hooks/api/Cohorts';
import {useCurrentBadge} from '@cohort/merchants/hooks/contexts/currentBadge';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {notify} from '@cohort/merchants/hooks/toast';
import {handleFormErrors} from '@cohort/merchants/lib/form/utils';
import {getBadgesRoute, getCohortMembersRoute} from '@cohort/merchants/lib/Pages';
import type {
  BadgeCohortStepValues,
  BadgeFormStepProps,
} from '@cohort/merchants/pages/users/badges/badge/edit/utils';
import {
  BadgeCohortStepSchema,
  SaveBadgeCohortForLaterRefinement,
} from '@cohort/merchants/pages/users/badges/badge/edit/utils';
import {zodResolver} from '@hookform/resolvers/zod';
import {ArrowCircleRight, Users} from '@phosphor-icons/react';
import {Fragment, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {match} from 'ts-pattern';
import {z} from 'zod';

const BadgeCohortStep: React.FC<BadgeFormStepProps> = ({
  isProcessing,
  setStepSaveBtn,
  onStepValidated,
  updateBadgeMutation,
}) => {
  const badge = useCurrentBadge();
  const [showSelectCohort, setShowSelectCohort] = useState(badge.status !== 'published');
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {keyPrefix: 'users.badges.badge.edit.badgeCohortStep'});
  const navigate = useNavigate();

  const {register, handleSubmit, setValue, getValues, setError, clearErrors} =
    useForm<BadgeCohortStepValues>({
      defaultValues: {
        cohortId: badge.cohortId,
      },
      resolver: zodResolver(BadgeCohortStepSchema),
    });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const {data: currentCohort} = useCohort(merchant.id, badge.cohortId!, {
    enabled: badge.cohortId !== null,
  });

  useEffect(() => {
    setStepSaveBtn?.(
      <Button
        variant="secondary"
        loading={isProcessing}
        onClick={async () => {
          try {
            const badgeData = BadgeCohortStepSchema.partial()
              .superRefine(SaveBadgeCohortForLaterRefinement)
              .parse(getValues());
            clearErrors();
            await updateBadgeMutation(badgeData);
            notify('success', t('notificationSuccess'));
            navigate(getBadgesRoute().path);
          } catch (error) {
            if (error instanceof z.ZodError) {
              handleFormErrors(error, clearErrors, setError);
            } else {
              throw error;
            }
          }
        }}
      >
        {t('saveBtn')}
      </Button>
    );
  }, [
    setStepSaveBtn,
    t,
    isProcessing,
    updateBadgeMutation,
    getValues,
    clearErrors,
    setError,
    navigate,
    merchant.defaultLanguage,
  ]);

  const content = match({
    showSelectCohort,
    isCurrentCohortDefined: currentCohort !== undefined,
  })
    .with({showSelectCohort: true}, () => (
      <SelectCohort
        register={register}
        onChange={cohort => setValue('cohortId', cohort.id)}
        height={520}
      />
    ))
    .with({isCurrentCohortDefined: true}, () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const cohort = currentCohort!;
      return (
        <div className="flex justify-between rounded-lg border border-slate-200 p-4 shadow-sm">
          <div className="flex items-center space-x-4">
            <div className="text-sm font-semibold text-slate-900">{cohort.name}</div>
            <div>
              <IconBadge
                key="users-badge"
                icon={<Users className="mr-1.5 h-4 w-4 text-slate-500" />}
                backgroundColor="bg-gray-100"
                text={`${cohort.usersCount ?? 0}`}
                size="small"
              />
            </div>
            <div>
              <CohortTypeBadge key="type-badge" type={cohort.type} size="small" />
            </div>
          </div>
          <div className="flex space-x-4">
            <Button
              variant="secondary"
              size="icon"
              onClick={() => navigate(getCohortMembersRoute(cohort.id).path)}
            >
              <ArrowCircleRight size={20} className="text-slate-400" />
            </Button>
            <Button onClick={() => setShowSelectCohort(true)}>{t('buttonEdit')}</Button>
          </div>
        </div>
      );
    })
    .otherwise(() => <Fragment />);

  return (
    <form
      className="space-y-8"
      id="badge-cohort-step"
      onSubmit={handleSubmit(async data => {
        try {
          const parsedData = BadgeCohortStepSchema.parse(data);
          await updateBadgeMutation(parsedData);
          onStepValidated();
        } catch (error) {
          if (error instanceof z.ZodError) {
            handleFormErrors(error, clearErrors, setError);
          } else {
            throw error;
          }
        }
      })}
    >
      {content}
    </form>
  );
};

export default BadgeCohortStep;
