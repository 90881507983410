import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import ExportCSV from '@cohort/merchants/components/buttons/ExportCSV';
import {IconBadge} from '@cohort/merchants/components/IconBadge';
import ImportUsersModal from '@cohort/merchants/components/modals/ImportUsersModal/ImportUsersModal';
import SearchInput from '@cohort/merchants/components/SearchInput';
import {useUsers} from '@cohort/merchants/hooks/api/Users';
import {useUsersListFilters} from '@cohort/merchants/hooks/filters/usersListFilters';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import {exportUsers} from '@cohort/merchants/lib/api/Users';
import UserPropertiesVisibilityDrawer from '@cohort/merchants/pages/users/overview/user-properties-visibility-drawer/UserPropertiesVisibilityDrawer';
import UsersOverviewMenu from '@cohort/merchants/pages/users/overview/UsersOverviewMenu';
import UsersTable from '@cohort/merchants/pages/users/overview/UsersTable';
import {UserList, UserPlus, Users} from '@phosphor-icons/react';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

const PAGE_SIZE = 20;

const UsersOverviewPage: React.FC = () => {
  const [showUserPropertiesVisibilityDrawer, setShowUserPropertiesVisibilityDrawer] =
    useState(false);
  const [importUsersModalOpen, setImportUsersModalOpen] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {t} = useTranslation('pages', {keyPrefix: 'users.overview.page'});
  const [usersCount, setUsersCount] = useState<number | null>(null);
  const filters = useUsersListFilters();

  const {data, isLoading} = useUsers(merchantId, {
    page: filters.page,
    pageSize: PAGE_SIZE,
    orderBy: filters.orderBy,
    ...(filters.search !== '' ? {search: filters.search} : {}),
  });
  const [pagination, users] = data ?? [];

  useEffect(() => {
    // update users count badge only when search is empty
    if (filters.search === '' && pagination !== undefined) {
      setUsersCount(pagination.total);
    }
  }, [filters.search, pagination]);

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
    titleBadge: usersCount !== null && (
      <IconBadge
        key="perks"
        icon={<Users className="mr-1.5 h-4 w-4 text-slate-500" />}
        text={t('usersBadge', {count: usersCount})}
      />
    ),
    topRightElements: [
      <Button
        key="manageUserProperties"
        variant="secondary"
        onClick={() => setShowUserPropertiesVisibilityDrawer(true)}
      >
        <UserList size={20} className="mr-2" /> {t('manageUserProperties')}
      </Button>,
      <Button key="importUsers" onClick={() => setImportUsersModalOpen(true)}>
        <UserPlus size={20} className="mr-2" /> {t('importUsers')}
      </Button>,
    ],
  };

  const paginationConfig = {
    pagination,
    onPageChange: filters.setPage,
  };

  return (
    <PageLayout title={t('title')} menu={<UsersOverviewMenu />}>
      <AdminPage header={headerConfig} pagination={paginationConfig}>
        <div className="mb-2 flex items-center justify-between">
          <SearchInput
            value={filters.search}
            onChange={filters.setSearch}
            placeholder={t('placeholderSearch')}
            delay={300}
            className="w-60"
          />
          <ExportCSV downloadFunction={async () => exportUsers(merchantId)} withCache={false} />
        </div>
        <UsersTable
          users={users ?? []}
          isLoading={isLoading}
          orderBy={filters.orderBy}
          setOrderBy={filters.setOrderBy}
        />
        {showUserPropertiesVisibilityDrawer && (
          <UserPropertiesVisibilityDrawer
            onClose={() => setShowUserPropertiesVisibilityDrawer(false)}
          />
        )}
        {importUsersModalOpen && (
          <ImportUsersModal onClose={() => setImportUsersModalOpen(false)} />
        )}
      </AdminPage>
    </PageLayout>
  );
};

export default UsersOverviewPage;
