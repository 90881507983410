import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import useDefaultRoute from '@cohort/merchants/hooks/useDefaultRoute';
import {Navigate, Outlet, useSearchParams} from 'react-router-dom';

const NoAuthLayout: React.FC = () => {
  const [searchParams] = useSearchParams();
  const isLoggedIn = useUserSessionStore(store => store.isLoggedIn);
  const defaultRoute = useDefaultRoute();

  if (isLoggedIn) {
    const destination = searchParams.get('destination');

    if (destination !== null) {
      return <Navigate to={destination} />;
    }
    return <Navigate to={defaultRoute} replace />;
  }
  return <Outlet />;
};

export default NoAuthLayout;
