import {getYoutubeProfileUrl, getYoutubeVideoUrl} from '@cohort/shared/apps/youtube/common';
import type {YoutubeLikeVerificationAttemptData} from '@cohort/shared/apps/youtube/triggers/like';
import {Trans} from 'react-i18next';

const YoutubeLikeTriggerStepCompletionTitleComponent: React.FC<{
  data: YoutubeLikeVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  const {channelId, channelTitle, videoId} = data;
  const linkClassName = 'font-semibold text-gray-900';
  const linkRel = 'noopener noreferrer nofollow';

  if (!completionSuccess) {
    return (
      <Trans
        i18nKey="triggerIntegrations.like.stepCompletionTitleComponent.error"
        ns="app-youtube"
        values={{username: channelTitle}}
        components={{
          userLink: (
            <a
              className={linkClassName}
              target="_blank"
              rel={linkRel}
              href={getYoutubeProfileUrl(channelId)}
            />
          ),
        }}
      />
    );
  }

  return (
    <Trans
      i18nKey="triggerIntegrations.like.stepCompletionTitleComponent.success"
      ns="app-youtube"
      values={{username: channelTitle}}
      components={{
        userLink: (
          <a
            className={linkClassName}
            target="_blank"
            rel={linkRel}
            href={getYoutubeProfileUrl(channelId)}
          />
        ),
        videoLink: (
          <a
            className={linkClassName}
            target="_blank"
            rel={linkRel}
            href={getYoutubeVideoUrl(videoId)}
          />
        ),
      }}
    />
  );
};

export default YoutubeLikeTriggerStepCompletionTitleComponent;
