import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import {getCampaignRoute} from '@cohort/merchants/lib/Pages';
import type {CohortNewJourneyAvailableEventStruct} from '@cohort/shared/apps/cohort/userEvents/journey/newJourneyAvailable';
import {CohortNewJourneyAvailableEventSpec} from '@cohort/shared/apps/cohort/userEvents/journey/newJourneyAvailable';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const campaignId = (userEvent.properties as CohortNewJourneyAvailableEventStruct['Properties'])
    .campaignId;
  const campaignInternalName = userEvent.campaignInternalName;
  const navigate = useNavigate();

  return (
    <Trans
      i18nKey="userEvents.journey.new-journey-available.detail"
      ns="app-cohort"
      values={{
        campaignInternalName,
      }}
      components={{
        bold: formatDetailComponentBoldText(userEvent, () =>
          navigate(getCampaignRoute(campaignId).path)
        ),
      }}
    />
  );
};

export const CohortNewJourneyAvailableUserEvent: UserEvent<CohortNewJourneyAvailableEventStruct> = {
  spec: CohortNewJourneyAvailableEventSpec,
  detailComponent: DetailComponent,
};
