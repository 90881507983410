import StepCompletionCard from '@cohort/merchants/apps/StepCompletionCard';
import type {DiscordHasRoleVerificationAttemptData} from '@cohort/shared/apps/discord/triggers/hasRole';

const DiscordHasRoleTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: DiscordHasRoleVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => (
  <StepCompletionCard
    translationConfig={{
      // i18nOwl-ignore [app-discord.triggerIntegrations.has-role.stepCompletionTitleComponent.success]
      // i18nOwl-ignore [app-discord.triggerIntegrations.has-role.stepCompletionTitleComponent.error]
      key: `triggerIntegrations.has-role.stepCompletionTitleComponent.${
        completionSuccess ? 'success' : 'error'
      }`,
      ns: 'app-discord',
      value: data?.expectedRoleName ?? null,
    }}
  />
);

export default DiscordHasRoleTriggerIntegrationStepCompletionTitleComponent;
