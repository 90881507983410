import {INSTAGRAM_URL} from '@cohort/shared/apps/instagram/common';
import type {InstagramCommentMediaVerificationAttemptData} from '@cohort/shared/apps/instagram/triggers/commentMedia';
import {Trans} from 'react-i18next';

const InstagramCommentMediaTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: InstagramCommentMediaVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  const {username, comment} = data;
  const linkClassName = 'font-semibold text-gray-900';
  const linkRel = 'noopener noreferrer nofollow';
  const userLink = `${INSTAGRAM_URL}/${username}`;

  if (!completionSuccess || comment === null) {
    return (
      <Trans
        i18nKey="triggerIntegrations.comment-media.stepCompletionTitleComponent.error"
        ns="app-instagram"
        values={{username}}
        components={{
          userLink: <a className={linkClassName} target="_blank" rel={linkRel} href={userLink} />,
        }}
      />
    );
  }

  return (
    <Trans
      i18nKey="triggerIntegrations.comment-media.stepCompletionTitleComponent.success"
      ns="app-instagram"
      values={{username}}
      components={{
        userLink: <a className={linkClassName} target="_blank" rel={linkRel} href={userLink} />,
        commentLink: (
          <a className={linkClassName} target="_blank" rel={linkRel} href={comment.permalink} />
        ),
      }}
    />
  );
};

export default InstagramCommentMediaTriggerIntegrationStepCompletionTitleComponent;
