import type {OrganizationADto} from '@cohort/admin-schemas/organization';
import MerchantLogoPlaceholder from '@cohort/components-xps/components/MerchantLogoPlaceholder';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';

type OrganizationLogoProps = {
  organization: OrganizationADto;
};
const OrganizationLogo: React.FC<OrganizationLogoProps> = ({organization}) => {
  if (organization.merchantLogoFileKey === null) {
    return <MerchantLogoPlaceholder size={64} merchantName={organization.name} />;
  }

  return (
    <img
      src={getImageUrl(import.meta.env.COHORT_ENV, organization.merchantLogoFileKey, {
        w: Sizes.S,
        h: Sizes.S,
      })}
      className="w-16 rounded-xl border"
    />
  );
};
export default OrganizationLogo;
