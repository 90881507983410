import {useCurrentUser} from '@cohort/merchants/hooks/contexts/currentUser';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import {CurrentUserProvider} from '@cohort/merchants/pages/users/user/CurrentUserContext';
import UserPageBreadcrumb from '@cohort/merchants/pages/users/user/UserPageBreadcrumb';
import UserPageMenu from '@cohort/merchants/pages/users/user/UserPageMenu';
import {Outlet} from 'react-router-dom';

const UserPageSkeleton: React.FC = () => {
  const user = useCurrentUser();

  return (
    <PageLayout title={user.email} breadcrumb={<UserPageBreadcrumb />} menu={<UserPageMenu />}>
      <Outlet />
    </PageLayout>
  );
};

const UserPageLayout = (): JSX.Element => (
  <CurrentUserProvider>
    <UserPageSkeleton />
  </CurrentUserProvider>
);

export default UserPageLayout;
