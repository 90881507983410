import EmptyState from '@cohort/merchants/components/EmptyState';
import LoadingState from '@cohort/merchants/components/LoadingState';
import NotificationGlobalSettingsListRowBody from '@cohort/merchants/components/notifications/NotificationGlobalSettingsListRowBody';
import NotificationResourceSettingsListRowBody from '@cohort/merchants/components/notifications/NotificationResourceSettingsListRowBody';
import NotificationsSettingsListRowHeader from '@cohort/merchants/components/notifications/NotificationsSettingsListRowHeader';
import {useNotificationConfigurations} from '@cohort/merchants/hooks/api/Notifications';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {EventId} from '@cohort/shared/apps';
import type {NotificationConfigurationResourceType} from '@cohort/shared/schema/common/notificationConfiguration';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
} from '@cohort/shared-frontend/components/Accordion';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Fragment, useState} from 'react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {groupBy} from 'remeda';

type NotificationsSettingsListProps = {
  userEventIds: Array<EventId>;
  resource?: {
    type: NotificationConfigurationResourceType;
    id: string;
  };
};

const NotificationsSettingsList: React.FC<NotificationsSettingsListProps> = ({
  userEventIds,
  resource,
}) => {
  const [activeRow, setActiveRow] = useState<EventId | null>(null);
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.notifications.notificationsSettingsList',
  });
  const {data} = useNotificationConfigurations(merchant.id);

  if (userEventIds.length === 0) {
    return (
      <EmptyState title={t('emptyUserEventsTitle')} description={t('emptyUserEventsDescription')} />
    );
  }

  const notificationConfigurations = resource
    ? data?.filter(
        nc =>
          nc.resourceType === null ||
          (nc.resourceType === resource.type && nc.resourceId === resource.id)
      )
    : data;

  if (notificationConfigurations === undefined) {
    return <LoadingState />;
  }

  const notificationConfigurationsByUserEventId = groupBy(
    notificationConfigurations,
    notificationConfiguration => notificationConfiguration.userEventId
  );

  return (
    <ul>
      {userEventIds.map(userEventId => {
        const notificationConfigurations =
          notificationConfigurationsByUserEventId[userEventId] ?? [];
        const globalNotificationConfigurations = notificationConfigurations.filter(
          configuration => configuration.resourceType === null
        );
        const overrideNotificationConfigurations = resource
          ? notificationConfigurations.filter(
              configuration =>
                configuration.resourceType === resource.type &&
                configuration.resourceId === resource.id
            )
          : [];
        const notificationConfigurationsToDisplay = !resource
          ? globalNotificationConfigurations
          : overrideNotificationConfigurations.length > 0
            ? overrideNotificationConfigurations
            : globalNotificationConfigurations;

        return (
          <li key={userEventId} className="border-t py-4 first:border-t-0 first:pt-0">
            <Fragment>
              <Accordion
                type="single"
                collapsible
                className="w-full cursor-pointer"
                value={activeRow !== null && activeRow === userEventId ? userEventId : undefined}
              >
                <div>
                  <div
                    className={cn(
                      'items-center',
                      activeRow !== null && activeRow !== userEventId && 'opacity-30'
                    )}
                  >
                    <AccordionItem value={userEventId} className="border-0 ">
                      <div onClick={() => setActiveRow(activeRow === null ? userEventId : null)}>
                        <NotificationsSettingsListRowHeader
                          open={activeRow === userEventId}
                          userEventId={userEventId}
                          notificationConfigurations={notificationConfigurationsToDisplay}
                        />
                      </div>

                      <AccordionContent className="mt-4 pb-0">
                        {!resource ? (
                          // Body for the global settings page (/settings/notifications)
                          <NotificationGlobalSettingsListRowBody
                            notificationConfigurations={notificationConfigurations}
                            userEventId={userEventId}
                          />
                        ) : (
                          // Body for the resource settings page (perks, journeys etc. /:resourceId/notifications)
                          <NotificationResourceSettingsListRowBody
                            notificationConfigurations={notificationConfigurations}
                            userEventId={userEventId}
                            resource={resource}
                          />
                        )}
                      </AccordionContent>
                    </AccordionItem>
                  </div>
                </div>
              </Accordion>
            </Fragment>
          </li>
        );
      })}
    </ul>
  );
};

export default NotificationsSettingsList;
