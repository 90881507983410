import type {AssetADto} from '@cohort/admin-schemas/asset';
import type {PatchCampaignADto} from '@cohort/admin-schemas/campaign';
import {
  isDefaultLanguageTranslationConsistent,
  isDefaultTranslationDefined,
  removeUndefinedLanguages,
} from '@cohort/merchants/lib/form/localization';
import {fileValidatorOptional} from '@cohort/merchants/lib/form/validators';
import {BaseCampaignSettingsSchema} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/campaign';
import type {CampaignSettingsStepValues} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import {uploadCampaignAssets} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import {LocalizedRichTextSchema, LocalizedStringSchema} from '@cohort/shared/schema/common';
import type {AssetKind} from '@cohort/shared/schema/common/assets';
import {isVideoFile} from '@cohort/shared/utils/mimeTypes';
import {isFile} from '@cohort/shared-frontend/utils/isFile';
import type {UseMutateAsyncFunction} from '@tanstack/react-query';
import {z} from 'zod';

// Base journeys campaigns data schema.
export const BaseCampaignJourneySettingsStepSchema = BaseCampaignSettingsSchema.merge(
  z.object({
    journey: z.object({
      description: LocalizedRichTextSchema,
      title: LocalizedStringSchema,
      optInCta: LocalizedStringSchema,
      imageFileKey: z.any().refine(fileValidatorOptional),
      animationFileKey: z.any().refine(fileValidatorOptional),
    }),
  })
);

export const CampaignJourneySettingsStepSchema = BaseCampaignJourneySettingsStepSchema.superRefine(
  ({defaultLanguage, journey}, ctx) => {
    if (defaultLanguage === undefined) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorRequired',
        path: ['defaultLanguage'],
      });
    }

    if (!isDefaultTranslationDefined(journey.description, defaultLanguage)) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorTooShort3',
        path: ['journey.description'],
      });
    }

    if (!isDefaultTranslationDefined(journey.title, defaultLanguage)) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorTooShort3',
        path: ['journey.title'],
      });
    }

    if (!isDefaultTranslationDefined(journey.optInCta, defaultLanguage)) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorTooShort3',
        path: ['journey.optInCta'],
      });
    }
  }
).transform(data => {
  data.journey.description = removeUndefinedLanguages(
    data.journey.description,
    data.definedLanguages
  );
  data.journey.title = removeUndefinedLanguages(data.journey.title, data.definedLanguages);
  data.journey.optInCta = removeUndefinedLanguages(data.journey.optInCta, data.definedLanguages);
  return data;
});

export const DraftCampaignJourneySettingsStepSchema =
  BaseCampaignJourneySettingsStepSchema.superRefine(
    ({defaultLanguage, definedLanguages, journey}, ctx) => {
      if (!definedLanguages) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'errorRequired',
          path: ['definedLanguages'],
        });
        return false;
      }

      if (!defaultLanguage) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'errorRequired',
          path: ['defaultLanguage'],
        });
        return false;
      }

      // If the data in default language is not defined but it is defined in another language, we need to throw an error.
      if (definedLanguages.length < 2) {
        return true;
      } else {
        if (!isDefaultLanguageTranslationConsistent(journey.title, defaultLanguage)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'errorTooShort3',
            path: ['journey.title'],
          });
          return false;
        }

        if (!isDefaultLanguageTranslationConsistent(journey.description, defaultLanguage)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'errorTooShort3',
            path: ['journey.description'],
          });
          return false;
        }

        if (!isDefaultLanguageTranslationConsistent(journey.optInCta, defaultLanguage)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'errorTooShort3',
            path: ['journey.optInCta'],
          });
          return false;
        }
      }

      return true;
    }
  );

export const uploadCampaignJourneyAssets = async (
  formData: Partial<CampaignSettingsStepValues>,
  uploadMutation: UseMutateAsyncFunction<
    AssetADto | null,
    unknown,
    {file: File; assetKind: AssetKind},
    unknown
  >
): Promise<PatchCampaignADto> => {
  // upload base campaign assets
  const updatedData = await uploadCampaignAssets(formData, uploadMutation);

  if (!updatedData.journey || !formData.journey) {
    throw new Error('Journey data is missing');
  }

  if (formData.journey.imageFileKey && isFile(formData.journey.imageFileKey)) {
    const asset = await uploadMutation({
      file: formData.journey.imageFileKey,
      assetKind: 'journeyVisual',
    });

    if (asset) {
      updatedData.journey.animationFileKey = isVideoFile(asset.mimeType) ? asset.fileKey : null;
      updatedData.journey.imageFileKey = isVideoFile(asset.mimeType) ? null : asset.fileKey;
    }
  } else if (!updatedData.journey.imageFileKey || updatedData.journey.imageFileKey.length === 0) {
    updatedData.journey.imageFileKey = null;
    updatedData.journey.animationFileKey = null;
  }

  return updatedData;
};
