import {isDark} from '@cohort/shared-frontend/utils/isDark';
import {useEffect} from 'react';

export default function usePreviewStyle(backgroundColor: string, accentColor: string): void {
  useEffect(() => {
    function loadMerchantTheme(): void {
      const root = document.documentElement;
      const hasDarkBg = isDark(backgroundColor);
      const hasDarkAccent = isDark(accentColor);

      root.style.setProperty('--xps-background-color', backgroundColor);
      root.style.setProperty('--xps-accent-color', accentColor);
      root.style.setProperty('--xps-primary-btn-color', hasDarkAccent ? '#ffffff' : '#000000');
      root.style.setProperty(
        '--xps-primary-btn-hover-color',
        hasDarkAccent ? '#ffffff' : '#000000'
      );

      root.classList.remove('light', 'dark');
      root.classList.add(hasDarkBg ? 'dark' : 'light');
    }

    loadMerchantTheme();
  }, [accentColor, backgroundColor]);
}
