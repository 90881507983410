import type {ApiKeyADto} from '@cohort/admin-schemas/apiKeys';
import DropdownButton from '@cohort/merchants/components/buttons/DropdownButton';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {formatDate} from '@cohort/shared/utils/format';
import {PencilSimple, Trash} from '@phosphor-icons/react';
import {createColumnHelper} from '@tanstack/react-table';
import React from 'react';
import {useTranslation} from 'react-i18next';

type ApiKeysTableProps = {
  apiKeys: ApiKeyADto[];
  onDeleteApiKey: (id: string) => void;
  onEditApiKey: (apiKey: ApiKeyADto) => void;
};

const ApiKeysTable: React.FC<ApiKeysTableProps> = ({apiKeys, onDeleteApiKey, onEditApiKey}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.integrations.apiKeysTable',
  });
  const columnHelper = createColumnHelper<ApiKeyADto>();
  const columns = [
    columnHelper.accessor('name', {
      header: t('columnName'),
      cell: data => <span className="font-medium text-foreground">{data.getValue()}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor('redacted', {
      header: t('columnKey'),
      enableSorting: false,
    }),
    columnHelper.accessor(row => formatDate(row.createdAt), {
      id: 'createdAt',
      header: t('columnDate'),
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'actions',
      cell: data => (
        <div className="flex justify-end">
          <DropdownButton
            entries={[
              {
                id: 'edit',
                label: <span className="text-slate-700">{t('labelEdit')}</span>,
                icon: <PencilSimple className="h-4 w-4 text-slate-400" />,
                handleOnClick: () => onEditApiKey(data.row.original),
              },
              {
                id: 'delete',
                label: <span className="text-red-500">{t('labelDelete')}</span>,
                icon: <Trash className="h-4 w-4 text-red-500" />,
                handleOnClick: () => onDeleteApiKey(data.row.original.id),
              },
            ]}
          />
        </div>
      ),
    }),
  ];
  const table = useCohortTable({
    columns,
    data: apiKeys,
  });

  return (
    <DataTable
      table={table}
      columnsLength={columns.length}
      emptyStatePlaceholder={t('emptyPlaceholder')}
      componentsClassName={{table: 'mb-[70px]'}}
    />
  );
};

export default ApiKeysTable;
