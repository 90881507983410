import {DigitalAssetCollectionASchema} from '@cohort/admin-schemas/digitalAssetCollection';
import {PerkASchema} from '@cohort/admin-schemas/perk';
import {DateSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const RewardBundleASchema = z.object({
  id: z.string().uuid(),
  collections: z.array(
    z.object({
      collectionId: z.string().uuid(),
      collection: DigitalAssetCollectionASchema,
      updatedAt: DateSchema,
    })
  ),
  perks: z.array(
    z.object({
      perkId: z.string().uuid(),
      revocable: z.boolean(),
      perk: PerkASchema,
      updatedAt: DateSchema,
    })
  ),
});
export type RewardBundleADto = z.infer<typeof RewardBundleASchema>;

export const UpdateRewardBundleASchema = z.object({
  collections: z.array(z.object({collectionId: z.string().uuid()})),
  perks: z.array(z.object({perkId: z.string().uuid(), revocable: z.boolean()})),
});
