import HighlightText from '@cohort/merchants/components/HighlightText';
import {AddLanguageModal} from '@cohort/merchants/components/modals/AddLanguageModal';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import useTranslateLanguage from '@cohort/merchants/hooks/useTranslateLanguage';
import {sortLanguages} from '@cohort/merchants/lib/form/localization';
import {getLanguagesRoute} from '@cohort/merchants/lib/Pages';
import type {Language} from '@cohort/shared/schema/common';
import {LANGUAGE_FLAGS} from '@cohort/shared/schema/common';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Menu, Transition} from '@headlessui/react';
import {CaretDown, XCircle} from '@phosphor-icons/react';
import {Plus} from '@phosphor-icons/react';
import {Fragment, useCallback, useRef, useState} from 'react';
import type {Control, FieldValues, Path} from 'react-hook-form';
import {useController} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';

const DEFAULT_MAX_DISPLAYED_LANGUAGES = 3;

const getShownAndHiddenLanguages = (
  initialLanguages: Array<Language>,
  selectedLanguage: Language,
  maxShown: number,
  getLanguageTranslation: (language: Language) => string
): [Array<Language>, Array<Language>] => {
  const shownLanguages = initialLanguages.slice(0, maxShown);
  let hiddenLanguages = initialLanguages.slice(maxShown);

  if (hiddenLanguages.includes(selectedLanguage)) {
    // last shown languages goes to hidden languages
    const lastShown = shownLanguages.pop();
    hiddenLanguages.push(lastShown as Language);
    // selected language goes to shown languages
    hiddenLanguages = hiddenLanguages.filter(language => language !== selectedLanguage);
    shownLanguages.push(selectedLanguage);
  }
  return [
    shownLanguages,
    hiddenLanguages.sort((a, b) => {
      return getLanguageTranslation(a).localeCompare(getLanguageTranslation(b));
    }),
  ];
};

type SelectLanguagesPopUpProps = {
  languages: Array<Language>;
  onChange: (language: Language) => void;
};

const SelectLanguagesPopUp = ({languages, onChange}: SelectLanguagesPopUpProps): JSX.Element => {
  const getLanguageTranslation = useTranslateLanguage();
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={cn(
            'group mx-4 inline-flex  cursor-pointer justify-center rounded-md bg-white px-2 py-1 text-sm font-medium text-slate-500  hover:text-slate-600'
          )}
        >
          +{languages.length}
          <CaretDown
            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-slate-400 group-hover:text-slate-500"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-0"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {languages.map(language => (
              <Menu.Item
                as="div"
                key={language}
                className="flex cursor-pointer items-center px-4 py-2 hover:bg-slate-50"
                onClick={() => onChange(language)}
              >
                {LANGUAGE_FLAGS[language]}
                <span className="pl-2">{getLanguageTranslation(language)}</span>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

type LanguageSelectorInputProps<T extends FieldValues> = {
  selectedLanguagePath: Path<T>;
  definedLanguagesPath: Path<T>;
  control: Control<T>;
  allowLanguagesManagement?: boolean;
  disabled?: boolean;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function LanguageSelectorInput<T extends FieldValues>({
  control,
  selectedLanguagePath,
  definedLanguagesPath,
  allowLanguagesManagement = true,
  disabled = false,
}: LanguageSelectorInputProps<T>): JSX.Element {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('components', {keyPrefix: 'form.v2.languageSelectorInput'});
  const getLanguageTranslation = useTranslateLanguage();
  const [displayedLanguagesNumber, setDisplayedLanguagesNumber] = useState(
    DEFAULT_MAX_DISPLAYED_LANGUAGES
  );
  const [deletionModalLanguage, setDeletionModalLanguage] = useState<Language | null>(null);
  const [addLanguageModelOpened, setAddLanguageModalOpened] = useState(false);
  const resizeObserver = useRef<ResizeObserver | null>(null);

  const {field: definedLanguagesField} = useController({
    control,
    name: definedLanguagesPath,
  });

  const {field: selectedLanguageField} = useController({
    control,
    name: selectedLanguagePath,
  });

  const everyLanguagesAreDefined = merchant.supportedLanguages.every(lang =>
    definedLanguagesField.value.includes(lang)
  );

  const languagesNotYetAdded = sortLanguages(
    merchant.supportedLanguages.filter(language => !definedLanguagesField.value.includes(language)),
    getLanguageTranslation
  );

  const currentLanguages = sortLanguages(
    definedLanguagesField.value,
    getLanguageTranslation,
    merchant.defaultLanguage
  );

  const [shownLanguages, hiddenLanguages] = getShownAndHiddenLanguages(
    currentLanguages,
    selectedLanguageField.value,
    displayedLanguagesNumber,
    getLanguageTranslation
  );

  const ref = useCallback(
    (node: HTMLDivElement | null) => {
      if (node === null) {
        return resizeObserver.current?.disconnect();
      }
      resizeObserver.current = new ResizeObserver(() => {
        setDisplayedLanguagesNumber(Math.max(1, (node.offsetWidth - 80) / 140));
      });
      resizeObserver.current.observe(node);
    },
    [resizeObserver]
  );

  return (
    <Fragment>
      <div
        className={cn(
          'flex w-full items-center justify-between rounded-lg bg-slate-100 text-sm',
          disabled && 'opacity-50'
        )}
      >
        <div className="flex flex-grow flex-wrap items-center" ref={ref}>
          {shownLanguages.map((language: Language) => (
            <div key={language} data-testid={language} className="flex items-center">
              <div
                onClick={() => selectedLanguageField.onChange(language)}
                className={cn(
                  'ml-6 flex cursor-pointer border-b-2 pb-2 pt-3',
                  language === selectedLanguageField.value
                    ? 'text-black-900 border-primary font-medium'
                    : 'border-transparent font-normal text-gray-500'
                )}
              >
                {LANGUAGE_FLAGS[language]}
                <span className="pl-2">{getLanguageTranslation(language)}</span>
              </div>
              {language !== merchant.defaultLanguage && allowLanguagesManagement && !disabled && (
                <div
                  className="ml-2"
                  onClick={() => {
                    setDeletionModalLanguage(language);
                  }}
                >
                  <XCircle className="h-5 w-5 cursor-pointer text-slate-400" />
                </div>
              )}
            </div>
          ))}
          {hiddenLanguages.length > 0 && (
            <SelectLanguagesPopUp
              languages={hiddenLanguages}
              onChange={(language: Language) => selectedLanguageField.onChange(language)}
            />
          )}
        </div>
        {!everyLanguagesAreDefined && allowLanguagesManagement && !disabled && (
          <div
            className="mr-6 flex cursor-pointer items-center space-x-2 font-medium text-primary"
            onClick={() => {
              setAddLanguageModalOpened(true);
            }}
          >
            <Plus className="h-5 w-5" />
            <span>{t('add')}</span>
          </div>
        )}
      </div>
      {deletionModalLanguage !== null && (
        <DeletionModal
          show
          onClose={() => {
            setDeletionModalLanguage(null);
          }}
          onDelete={() => {
            setDeletionModalLanguage(null);
            const newDefinedLanguages = definedLanguagesField.value.filter(
              (definedLanguage: Language) => definedLanguage !== deletionModalLanguage
            );
            definedLanguagesField.onChange(newDefinedLanguages);
            selectedLanguageField.onChange(merchant.defaultLanguage);
          }}
          title={t('deletionModal.title')}
          subtitle={t('deletionModal.subtitle')}
        />
      )}
      {addLanguageModelOpened && (
        <AddLanguageModal
          onClose={() => setAddLanguageModalOpened(false)}
          onLanguagesAdded={(languages: Array<Language>) => {
            definedLanguagesField.onChange([...definedLanguagesField.value, ...languages]);
            if (languages.length > 0) {
              selectedLanguageField.onChange(languages[0] as Language);
            }
            setAddLanguageModalOpened(false);
          }}
          languageOptions={languagesNotYetAdded}
          title={t('additionModal.title')}
          helper={
            <HighlightText
              type="info"
              text={
                <Trans
                  i18nKey="form.v2.languageSelectorInput.additionModal.subtitle"
                  ns="components"
                  components={{
                    pageLink: (
                      <a
                        className="font-medium text-blue-600 underline"
                        href={getLanguagesRoute().path}
                        target="_blank"
                        rel="noreferrer"
                      />
                    ),
                  }}
                />
              }
            />
          }
        />
      )}
    </Fragment>
  );
}
