import type {TriggerIntegration} from '@cohort/merchants/apps';
import YoutubeCommentTriggerIntegrationConfigComponent from '@cohort/merchants/apps/youtube/triggers/comment/ConfigComponent';
import YoutubeCommentTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/youtube/triggers/comment/StepCompletionTitleComponent';
import type {YoutubeCommentTriggerStruct} from '@cohort/shared/apps/youtube/triggers/comment';
import {YoutubeCommentTriggerSpec} from '@cohort/shared/apps/youtube/triggers/comment';

export const YoutubeCommentTriggerIntegration: TriggerIntegration<YoutubeCommentTriggerStruct> = {
  spec: YoutubeCommentTriggerSpec,
  configComponent: YoutubeCommentTriggerIntegrationConfigComponent,
  stepCompletionTitleComponent: YoutubeCommentTriggerIntegrationStepCompletionTitleComponent,
  getTitle: t => t('triggerIntegrations.comment.title', {ns: 'app-youtube'}),
};
