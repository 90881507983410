import type {
  CreateStripeConnectAccountDataADto,
  StripeConnectAccountBalanceADto,
  StripeConnectAccountPayoutScheduleADto,
  StripeConnectAccountResponseADto,
  StripeConnectLoginLinkADto,
  StripeConnectOnboardingLinkADto,
  UpdateStripeConnectAccountPayoutScheduleDataADto,
} from '@cohort/admin-schemas/stripe';
import {
  StripeConnectAccountBalanceASchema,
  StripeConnectAccountPayoutScheduleASchema,
  StripeConnectAccountResponseASchema,
  StripeConnectLoginLinkASchema,
} from '@cohort/admin-schemas/stripe';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';

export async function createStripeConnectAccount(
  data: CreateStripeConnectAccountDataADto
): Promise<StripeConnectAccountResponseADto> {
  return apiCall('POST', `/v1/stripe-account`, {
    expect: HttpCodes.CREATED,
    parser: StripeConnectAccountResponseASchema.parse,
    body: data,
  });
}

export async function getStripeConnectAccount(): Promise<StripeConnectAccountResponseADto> {
  return apiCall('GET', `/v1/stripe-account`, {
    expect: HttpCodes.SUCCESS,
    parser: StripeConnectAccountResponseASchema.parse,
  });
}

export async function getStripeConnectAccountBalance(): Promise<StripeConnectAccountBalanceADto> {
  return apiCall('GET', `/v1/stripe-account/balance`, {
    expect: HttpCodes.SUCCESS,
    parser: StripeConnectAccountBalanceASchema.parse,
  });
}

export async function getStripeConnectAccountPayoutSchedule(): Promise<StripeConnectAccountPayoutScheduleADto> {
  return apiCall('GET', `/v1/stripe-account/payout/schedule`, {
    expect: HttpCodes.SUCCESS,
    parser: StripeConnectAccountPayoutScheduleASchema.parse,
  });
}

export async function updateStripeConnectAccountPayoutSchedule(
  data: UpdateStripeConnectAccountPayoutScheduleDataADto
): Promise<StripeConnectAccountPayoutScheduleADto> {
  return apiCall('PATCH', `/v1/stripe-account/payout/schedule`, {
    expect: HttpCodes.SUCCESS,
    parser: StripeConnectAccountPayoutScheduleASchema.parse,
    body: data,
  });
}

export async function getStripeConnectOnboardingLink(): Promise<StripeConnectOnboardingLinkADto> {
  return apiCall('GET', `/v1/stripe-account/onboarding-link`, {
    expect: HttpCodes.SUCCESS,
    parser: StripeConnectLoginLinkASchema.parse,
  });
}

export async function getStripeConnectLoginLink(): Promise<StripeConnectLoginLinkADto> {
  return apiCall('GET', `/v1/stripe-account/login-link`, {
    expect: HttpCodes.SUCCESS,
    parser: StripeConnectLoginLinkASchema.parse,
  });
}
