import {cn} from '@cohort/shared-frontend/utils/classNames';

interface Props {
  icon: JSX.Element;
  text?: JSX.Element | string;
  description?: JSX.Element | string;
  button?: JSX.Element;
  className?: string;
}

export const EmptyContentContainer = ({
  icon,
  text,
  description,
  button,
  className,
}: Props): JSX.Element => (
  <div
    className={cn(
      'flex h-80 flex-col items-center justify-center rounded-md bg-slate-50',
      className
    )}
  >
    <div className="flex h-10 w-10 items-center justify-center">{icon}</div>
    <span className="mt-2 font-medium text-gray-900">{text}</span>
    <span className="mt-2 text-center text-sm font-normal text-gray-500">{description}</span>
    <div className="mt-4">{button}</div>
  </div>
);
