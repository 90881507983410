import AsidePageMenu from '@cohort/merchants/components/AsidePageMenu';
import type {PageLink} from '@cohort/merchants/lib/Pages';
import {getContentCategoriesRoute, getContentsRoute} from '@cohort/merchants/lib/Pages';
import {NewspaperClipping} from '@phosphor-icons/react';
import {CirclesFour} from '@phosphor-icons/react/dist/ssr';
import {useTranslation} from 'react-i18next';

const ContentsOverviewMenu = (): JSX.Element => {
  const {t} = useTranslation('pages', {keyPrefix: 'contents.overview.contentsOverviewMenu'});

  type NavItem = {
    name: string;
    path: string;
    page: PageLink;
    icon: JSX.Element;
  };

  const nav: Array<NavItem> = [
    {
      name: t('labelContents'),
      path: getContentsRoute().path,
      page: getContentsRoute(),
      icon: <NewspaperClipping size={20} />,
    },
    {
      name: t('labelCategories'),
      path: getContentCategoriesRoute().path,
      page: getContentCategoriesRoute(),
      icon: <CirclesFour size={20} />,
    },
  ];

  return <AsidePageMenu entries={nav} />;
};

export default ContentsOverviewMenu;
