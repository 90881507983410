import type {
  JourneyParticipationADto,
  JourneyParticipationsOrderBy,
} from '@cohort/admin-schemas/journeyParticipation';
import JourneyParticipationStatusBadge from '@cohort/merchants/components/campaigns/journeys/JourneyParticipationStatusBadge';
import JourneyParticipationStepper from '@cohort/merchants/components/campaigns/journeys/JourneyParticipationStepper';
import {Progress} from '@cohort/merchants/components/Progress';
import CampaignReferenceComponent from '@cohort/merchants/components/references/CampaignReferenceComponent';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {formatDate} from '@cohort/shared/utils/format';
import {CaretDown, CaretUp} from '@phosphor-icons/react';
import {createColumnHelper, getExpandedRowModel} from '@tanstack/react-table';
import {useTranslation} from 'react-i18next';

type UserJourneysTableProps = {
  isLoading: boolean;
  journeyParticipations: JourneyParticipationADto[];
  orderBy: JourneyParticipationsOrderBy;
  setOrderBy: (orderBy: JourneyParticipationsOrderBy) => void;
};

const UserJourneysTable: React.FC<UserJourneysTableProps> = ({
  isLoading,
  journeyParticipations,
  orderBy,
  setOrderBy,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'users.user.journeys.userJourneysTable',
  });

  const columnHelper = createColumnHelper<JourneyParticipationADto>();
  const columns = [
    columnHelper.display({
      header: t('tableHeaderJourney'),
      cell: data => (
        <CampaignReferenceComponent
          internalName={data.row.original.campaign.internalName}
          campaignId={data.row.original.campaign.id}
          imageFileKey={data.row.original.campaign.imageFileKey}
        />
      ),
      minSize: 300,
      enableSorting: false,
    }),
    columnHelper.accessor(row => formatDate(row.createdAt), {
      id: 'createdAt',
      header: t('tableHeaderJoinedAt'),
      minSize: 200,
    }),
    columnHelper.accessor('status', {
      header: t('tableHeaderStatus'),
      cell: data => <JourneyParticipationStatusBadge status={data.getValue()} />,
      enableSorting: false,
    }),
    columnHelper.accessor(
      row => row.stepCompletionAttempts.filter(attempt => attempt.success).length,
      {
        id: 'completedSteps',
        header: t('tableHeaderCompletedSteps'),
        cell: data => (
          <div className="flex items-center gap-2">
            <Progress
              value={
                data.row.original.journeySteps.length === 0
                  ? 0
                  : (data.getValue() / data.row.original.journeySteps.length) * 100
              }
            />
            {data.getValue()}/{data.row.original.journeySteps.length}
          </div>
        ),
        enableSorting: false,
      }
    ),
    columnHelper.display({
      id: 'details',
      header: () => null,
      cell: ({row}) => (
        <button onClick={row.getToggleExpandedHandler()}>
          {row.getIsExpanded() ? (
            <span className="font-medium text-primary">
              {t('buttonHideDetails')}
              <CaretUp size={16} className="ml-1 inline" />
            </span>
          ) : (
            <span className="font-medium text-primary">
              {t('buttonShowDetails')}
              <CaretDown size={16} className="ml-1 inline" />
            </span>
          )}
        </button>
      ),
    }),
  ];
  const table = useCohortTable(
    {
      data: journeyParticipations,
      columns,
      getRowCanExpand: () => true,
      getExpandedRowModel: getExpandedRowModel(),
    },
    {
      sortBy: orderBy,
      onSortUpdate: setOrderBy,
    }
  );

  return (
    <DataTable
      table={table}
      columnsLength={columns.length}
      isLoading={isLoading}
      emptyStatePlaceholder={t('emptyPlaceholder')}
      renderExpandedRow={({row}) => (
        <JourneyParticipationStepper journeyParticipation={row.original} />
      )}
    />
  );
};
export default UserJourneysTable;
