import MerchantsRoutes from '@cohort/merchants/router/Routes';
import React from 'react';
import {BrowserRouter} from 'react-router-dom';

const Router: React.FC = () => (
  <BrowserRouter>
    <MerchantsRoutes />
  </BrowserRouter>
);

export default Router;
