import Tooltip from '@cohort/shared-frontend/components/Tooltip';

type StackedImagesProps = {
  imageData: Array<{url: string; tooltipContent?: string}>;
  maxImages?: number;
  imgSize?: number;
};

const StackedImages: React.FC<StackedImagesProps> = ({imageData, maxImages = 3, imgSize = 24}) => {
  return (
    <div className="flex -space-x-3">
      {imageData.slice(0, maxImages).map((data, index) => {
        const {url, tooltipContent} = data;

        return (
          <div
            key={url}
            style={{zIndex: imageData.length - index}} // Stacking images in reverse order.
          >
            <Tooltip
              contentAsChild
              content={tooltipContent}
              disabled={tooltipContent === undefined}
            >
              <img
                className="rounded-full border-2 border-white object-cover"
                src={url}
                width={imgSize}
                height={imgSize}
              />
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

export default StackedImages;
