import {Apps} from '@cohort/merchants/apps';
import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {syncsKeys} from '@cohort/merchants/hooks/api/Syncs';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCurrentSync} from '@cohort/merchants/hooks/contexts/currentSync';
import {useSyncPageStore} from '@cohort/merchants/hooks/stores/syncPage';
import {notify} from '@cohort/merchants/hooks/toast';
import {patchSync} from '@cohort/merchants/lib/api/Sync';
import type {UpdateUserSyncConfigFormType} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/sync';
import {UpdateUserSyncConfigFormSchema} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/sync';
import {CohortError} from '@cohort/shared/schema/common/errors';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import {isEqual} from 'lodash';
import {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {shallow} from 'zustand/shallow';

const SyncImportUsersConfigPage: React.FC = () => {
  const merchant = useCurrentMerchant();
  const {sync} = useCurrentSync();
  const queryClient = useQueryClient();
  const [isSavingDisabled, setIsSavingDisabled] = useState(true);
  const {t} = useTranslation('pages', {keyPrefix: 'apps.app.syncs.edit.syncImportConfigPage'});

  const app = Apps.find(app => app.spec.id === sync.appId);
  const integration = app?.sync;

  const {isLoading: isUpdating, mutate: updateSyncMutation} = useCohortMutation({
    mutationFn: async (data: UpdateUserSyncConfigFormType) => patchSync(merchant.id, sync.id, data),
    notifySuccessMessage: t('notificationSuccess'),
    onSuccess: async () => {
      await queryClient.invalidateQueries(syncsKeys.getById(merchant.id, sync.id));
    },
    onError: err => {
      if (err instanceof CohortError && err.message === 'user-property.used-in-rule') {
        const cohortList = (err.context.cohorts as Array<{id: string; name: string}>)
          .map(cohort => cohort.name)
          .join(', ');

        return notify(
          'error',
          t('notificationUserPropertyInUseErrorTitle', {
            userPropertyName: err.context.userPropertyName,
          }),
          {
            description: (
              <p className="text-sm">
                <Trans
                  i18nKey="apps.app.syncs.edit.syncImportConfigPage.notificationUserPropertyInUseErrorDescription"
                  ns="pages"
                  components={{
                    list: <span className="font-medium"></span>,
                  }}
                  values={{
                    cohorts: cohortList,
                  }}
                />
              </p>
            ),
            duration: 7000,
          }
        );
      }

      if (
        err instanceof CohortError &&
        err.message === 'user-property.reference-id-already-exists'
      ) {
        return notify('error', t('notificationUserPropertyAlreadyExistsError'));
      }

      return notify('error', t('notificationError'));
    },
  });

  const useFormProps = useForm<UpdateUserSyncConfigFormType>({
    resolver: zodResolver(UpdateUserSyncConfigFormSchema),
    defaultValues: {
      userSyncConfig: sync.userSyncConfig ?? undefined,
    },
  });

  const {setFooter} = useSyncPageStore(
    state => ({
      setFooter: state.setFooter,
    }),
    shallow
  );

  const config = useFormProps.watch('userSyncConfig');

  useEffect(() => {
    setFooter(
      <div className="-m-px flex h-[var(--submit-footer-height)] justify-end border-l border-t px-8 py-4">
        <Button
          disabled={isEqual(sync.userSyncConfig, config) || isSavingDisabled}
          onClick={() => updateSyncMutation({userSyncConfig: config})}
          loading={isUpdating}
        >
          {t('saveBtn')}
        </Button>
      </div>
    );
    return () => setFooter(null);
  }, [config, isSavingDisabled, isUpdating, setFooter, t, updateSyncMutation, sync.userSyncConfig]);

  if (integration === undefined) {
    return null;
  }

  return (
    <AdminPage header={{title: t('title'), subtitle: t('subtitle')}}>
      <FormProvider {...useFormProps}>
        <integration.importUsersConfigComponent
          sync={sync}
          setIsSavingDisabled={setIsSavingDisabled}
        />
      </FormProvider>
    </AdminPage>
  );
};

export default SyncImportUsersConfigPage;
