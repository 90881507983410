import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import {cn} from '@cohort/shared-frontend/utils/classNames';

interface PolygonButtonProps {
  href?: string;
  size?: number;
}

const PolygonButton: React.FC<PolygonButtonProps> = ({href, size = 10}) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    if (href !== undefined) {
      window.open(href, '_blank');
    }
  };
  return (
    <div onClick={handleClick} className={cn(href !== undefined && 'hover:cursor-pointer')}>
      <SvgAppIcon name="polygon" height={size} width={size} />
    </div>
  );
};

export default PolygonButton;
