import FaqQuestionCard from '@cohort/components-xps/components/cards/FaqQuestionCard';
import i18nComponentsXpsInstance from '@cohort/components-xps/lib/i18n';
import type {FaqItem} from '@cohort/shared/schema/common/campaign';
import React from 'react';
import {getI18n, useTranslation} from 'react-i18next';

type CampaignFaqsProps = {
  faqs: FaqItem[];
  supportEmail: string | null;
};

const CampaignFaqs: React.FC<CampaignFaqsProps> = ({faqs, supportEmail}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'campaignFaqs',
    lng: getI18n().language,
    i18n: i18nComponentsXpsInstance,
  });

  return (
    <dl>
      {faqs.map(question => (
        <FaqQuestionCard key={question.question} faqItem={question} />
      ))}
      {supportEmail !== null && (
        <FaqQuestionCard
          key="supportEmail"
          faqItem={{
            question: t('needHelp'),
            answer: `<p>${t('contactUs')} <a href=mailto:${supportEmail}>${supportEmail}</a></p>`,
          }}
        />
      )}
    </dl>
  );
};

export default CampaignFaqs;
