import {useUserEventApps} from '@cohort/merchants/apps/useUserEventApps';
import AdminPage from '@cohort/merchants/components/AdminPage';
import Filters from '@cohort/merchants/components/filters/Filters';
import SearchInput from '@cohort/merchants/components/SearchInput';
import Timeline from '@cohort/merchants/components/Timeline';
import {useUserEvents} from '@cohort/merchants/hooks/api/UserEvents';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCurrentUser} from '@cohort/merchants/hooks/contexts/currentUser';
import {
  eventIdFilter,
  useUserActivityFilters,
} from '@cohort/merchants/hooks/filters/userEventsFilter';
import UserEventItem from '@cohort/merchants/pages/users/user/activity/UserEventItem';
import type {EventId} from '@cohort/shared/apps';
import {useTranslation} from 'react-i18next';

const PAGE_SIZE = 20;

const UserActivityPage = (): JSX.Element => {
  const user = useCurrentUser();
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {keyPrefix: 'users.user.activity.page'});
  const filters = useUserActivityFilters();
  const userEventApps = useUserEventApps();

  const statusFilterOptions = Object.keys(eventIdFilter).map(eventId => ({
    value: eventId,
    label: userEventApps.getUserEventTitle(eventId),
    active: filters.eventIds.includes(eventId as EventId),
  }));

  const {data} = useUserEvents(merchant.id, {
    userId: user.id,
    page: filters.page,
    pageSize: PAGE_SIZE,
    search: filters.search !== '' ? filters.search : undefined,
    eventIds: filters.eventIds,
    orderBy: '-eventDate',
  });

  const [pagination, userEvents] = data ?? [];

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
  };

  const paginationConfig = {
    pagination,
    onPageChange: filters.setPage,
  };

  return (
    <AdminPage header={headerConfig} pagination={paginationConfig}>
      <div className="flex flex-row space-x-2">
        <SearchInput
          value={filters.search}
          onChange={filters.setSearch}
          placeholder={t('placeholderSearch')}
          delay={300}
          className="!h-9 w-40"
        />
        <Filters
          filters={[
            {
              label: t('filterByEvent'),
              options: statusFilterOptions,
              type: 'multiple',
              onChange: statusOptions => {
                const activeFilterOptions = statusOptions.filter(o => o.active);
                filters.setUserEventIds(activeFilterOptions.map(o => o.value) as EventId[]);
              },
              className: '!h-9 !focus:ring-0',
            },
          ]}
        />
      </div>
      {userEvents !== undefined && (
        <Timeline
          timelineItems={userEvents.map(userEvent => ({
            children: <UserEventItem userEvent={userEvent} key={userEvent.id} />,
          }))}
        />
      )}
    </AdminPage>
  );
};

export default UserActivityPage;
