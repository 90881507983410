import {cn} from '@cohort/shared-frontend/utils/classNames';
import {DotsSixVertical, PencilSimple, Trash} from '@phosphor-icons/react';

type DraggableListItemProps = {
  children: React.ReactNode;
  className?: string;
  onEdit?: () => void;
  onRemove?: () => void;
  grow?: boolean;
};

const DraggableListItem: React.FC<DraggableListItemProps> = ({
  children,
  className,
  onEdit,
  onRemove,
  grow,
}) => (
  <div className={cn('py-1', grow && 'grow')}>
    <div
      className={cn(
        'flex h-12 items-center justify-between gap-4 rounded-lg border bg-white shadow-sm',
        className
      )}
    >
      <div className="flex h-full items-center border-r px-3">
        <DotsSixVertical className="h-5 w-5 text-slate-400" />
      </div>
      <div className="flex-1">{children}</div>
      <div className="flex h-full items-center">
        {onEdit && (
          <button className="h-full border-l px-3" type="button" onClick={onEdit}>
            <PencilSimple className="h-5 w-5 text-gray-500" />
          </button>
        )}
        {onRemove && (
          <button className="h-full border-l px-3" type="button" onClick={onRemove}>
            <Trash className="h-5 w-5 text-red-400" />
          </button>
        )}
      </div>
    </div>
  </div>
);

export default DraggableListItem;
