import AdminPage from '@cohort/merchants/components/AdminPage';
import KPICard from '@cohort/merchants/components/KPICard';
import {useUserBadges} from '@cohort/merchants/hooks/api/Users';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCurrentUser} from '@cohort/merchants/hooks/contexts/currentUser';
import {useBasePageFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import BadgesTable from '@cohort/merchants/pages/users/badges/overview/BadgesTable';
import {Medal} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

const PAGE_SIZE = 20;

const UserBadgesPage: React.FC = () => {
  const user = useCurrentUser();
  const merchant = useCurrentMerchant();

  const {t} = useTranslation('pages', {keyPrefix: 'users.user.badges.page'});

  const filters = useBasePageFilters(/^(\/test)?\/users\/.*\/badges$/u, null);

  const {data, isLoading} = useUserBadges(merchant.id, user.id, {
    page: filters.page,
    pageSize: PAGE_SIZE,
  });
  const [pagination, userBadges] = data ?? [];

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
    topRightElements: [
      <KPICard
        key="badges-counter"
        icon={<Medal className="h-4 w-4 text-slate-500" />}
        text={t('labelBadges', {count: userBadges?.length ?? 0})}
        value={userBadges?.length ?? 0}
      />,
    ],
  };

  const paginationConfig = {
    pagination,
    onPageChange: filters.setPage,
  };

  return (
    <AdminPage header={headerConfig} pagination={paginationConfig}>
      <BadgesTable isLoading={isLoading} badges={userBadges ?? []} />
    </AdminPage>
  );
};
export default UserBadgesPage;
