import type {GetPerksParamsADto} from '@cohort/admin-schemas/perk';
import SingleChoiceFilter from '@cohort/merchants/components/filters/SingleChoiceFilter';
import type {PerksListFilters} from '@cohort/merchants/hooks/filters/perksListFilters';
import {sortFilter} from '@cohort/merchants/hooks/filters/perksListFilters';
import {useTranslation} from 'react-i18next';

type PerksSortByProps = {
  filters: PerksListFilters;
};

const PerksSortBy: React.FC<PerksSortByProps> = ({filters}) => {
  const {t} = useTranslation('components', {keyPrefix: 'perks.perksSortBy'});

  // i18nOwl-ignore [sortBy.-createdAt, sortBy.-updatedAt, sortBy.type, sortBy.internalName]
  const sortingOptions = sortFilter.map(sort => ({
    value: sort,
    label: t(`sortBy.${sort}`),
    active: filters.sort === sort,
  }));

  return (
    <SingleChoiceFilter
      options={sortingOptions}
      onChange={option => filters.setSort(option.value as GetPerksParamsADto['orderBy'])}
      prefix={t('title')}
    />
  );
};
export default PerksSortBy;
