import type {MerchantFontADto, PatchMerchantADto} from '@cohort/admin-schemas/merchant';
import Button from '@cohort/merchants/components/buttons/Button';
import {EmptyContentContainer} from '@cohort/merchants/components/EmptyContentContainer';
import HighlightText from '@cohort/merchants/components/HighlightText';
import Loader from '@cohort/merchants/components/Loader';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {merchantsKeys, useMerchantCustomStyle} from '@cohort/merchants/hooks/api/Merchants';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {notify} from '@cohort/merchants/hooks/toast';
import {patchMerchant} from '@cohort/merchants/lib/api/Merchants';
import AddFontModal from '@cohort/merchants/pages/settings/customization/AddFontModal';
import LinkSection from '@cohort/merchants/pages/settings/integrations/LinkSection';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import Editor from '@monaco-editor/react';
import {Trash} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {createColumnHelper} from '@tanstack/react-table';
import type {editor} from 'monaco-editor';
import {useRef, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

const styleCustomisationErrors = [
  'merchant.missing-style-configuration',
  'merchant.invalid-style-value',
  'merchant.invalid-custom-stylesheet',
] as const;
type StyleCustomisationError = (typeof styleCustomisationErrors)[number];

type StyleCustomisationFormProps = {
  onEditorLoaded: () => void;
};

const StyleCustomisationForm: React.FC<StyleCustomisationFormProps> = ({onEditorLoaded}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.customization.styleCustomisationForm',
  });
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [canSave, setCanSave] = useState(true);
  const [createModal, setCreateModal] = useState(false);
  const editorRef = useRef<editor.IStandaloneCodeEditor>();
  const queryClient = useQueryClient();
  const [deleteFont, setDeleteFont] = useState<MerchantFontADto | null>(null);

  const {isLoading, mutate: updateMerchant} = useCohortMutation({
    mutationFn: async (data: PatchMerchantADto) => patchMerchant(merchant.id, data),
    notifySuccessMessage: t('updateSuccess'),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(merchantsKeys.getById(merchant.id));
      if (variables.customStyleSheetContent) {
        // Reload the page to apply the new custom styles in previews
        window.location.reload();
      }
    },
    onError: err => {
      if (isCohortError(err)) {
        if (styleCustomisationErrors.includes(err.code as StyleCustomisationError)) {
          const {variableName, value} = err.context;
          const errorMessages: Record<StyleCustomisationError, string> = {
            'merchant.missing-style-configuration': t('updateErrorMissingStyle', {variableName}),
            'merchant.invalid-style-value': t('updateErrorInvalidValue', {variableName, value}),
            'merchant.invalid-custom-stylesheet': t('updateErrorInvalidCustomStylesheet'),
          };

          return notify('error', t('updateErrorTitle'), {
            description: errorMessages[err.code as StyleCustomisationError],
          });
        }
        notify('error', err.code);
      }
    },
  });
  const {data: customStyle, isFetched} = useMerchantCustomStyle(
    merchant.id,
    merchant.customStyleSheetUrl
  );

  const columnHelper = createColumnHelper<MerchantFontADto>();
  const columns = [
    columnHelper.accessor('family', {
      header: t('columnName'),
      cell: data => <span className="font-medium text-foreground">{data.getValue()}</span>,
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'actions',
      cell: data => (
        <div className="flex items-center justify-end gap-8">
          <Button
            variant="link"
            size="icon"
            onClick={() => {
              const link = document.createElement('a');

              link.href = data.row.original.src;
              link.target = '_blank';
              link.rel = 'noreferrer noopener';
              link.click();
            }}
          >
            {t('viewFontBtn')}
          </Button>
          <Button variant="secondary" size="icon" onClick={() => setDeleteFont(data.row.original)}>
            <Trash size={20} className="text-red-400" />
          </Button>
        </div>
      ),
    }),
  ];
  const table = useCohortTable({
    columns,
    data: merchant.fonts,
  });

  function getEditorValue(): string | undefined {
    return editorRef.current?.getValue();
  }

  function handleEditorDidMount(editor: editor.IStandaloneCodeEditor): void {
    setIsEditorReady(true);
    editorRef.current = editor;
    onEditorLoaded();
  }

  return (
    <div className="space-y-6">
      <LinkSection title={t('title')} description={t('subtitle')} />
      <section className="space-y-4">
        <div className="text-right">
          <Button variant="primary" onClick={() => setCreateModal(true)}>
            {t('addCustomFontBtn')}
          </Button>
        </div>
        <DataTable
          table={table}
          columnsLength={columns.length}
          emptyStatePlaceholder={t('emptyPlaceholder')}
        />
        <HighlightText type="info" text={t('customFontDescription')}></HighlightText>
      </section>
      <section className="space-y-4">
        {!isFetched ? (
          <EmptyContentContainer icon={<Loader size={50} color="gray" />} />
        ) : (
          <Editor
            theme="vs-dark"
            defaultLanguage="css"
            defaultValue={customStyle}
            height={600}
            onValidate={markers => setCanSave(markers.length === 0)}
            onMount={handleEditorDidMount}
            options={{
              minimap: {
                enabled: false,
              },
              wordWrap: 'on',
              wordWrapColumn: 120,
            }}
          />
        )}
        <HighlightText
          type="info"
          text={
            <Trans
              i18nKey="settings.customization.styleCustomisationForm.cssVariablesHint"
              ns="pages"
              components={{
                pageLink: (
                  <a
                    className="font-medium text-blue-600 underline"
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://docs.getcohort.com/concepts/experience-space/customizing"
                  />
                ),
              }}
            />
          }
        />
        <div className="flex justify-end space-x-2">
          <Button
            type="submit"
            disabled={!isEditorReady || !canSave}
            loading={isLoading}
            onClick={() => {
              const editorValue = getEditorValue();

              return updateMerchant({
                customStyleSheetContent: editorValue,
              });
            }}
          >
            Save
          </Button>
        </div>
      </section>
      {createModal && <AddFontModal onClose={() => setCreateModal(false)} />}
      {deleteFont && (
        <DeletionModal
          title={t('deleteModalTitle')}
          subtitle={t('deleteModalSubtitle')}
          onClose={() => setDeleteFont(null)}
          show={true}
          onDelete={() => {
            const newFonts = merchant.fonts.filter(font => font.id !== deleteFont.id);

            updateMerchant({
              fonts: newFonts,
            });
            setDeleteFont(null);
          }}
        />
      )}
    </div>
  );
};
export default StyleCustomisationForm;
