import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import {IconBadge} from '@cohort/merchants/components/IconBadge';
import CreateCohortModal from '@cohort/merchants/components/modals/CreateCohortModal';
import {useCohorts} from '@cohort/merchants/hooks/api/Cohorts';
import {useBasePageFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import CohortsTable from '@cohort/merchants/pages/users/cohorts/CohortsTable';
import UsersOverviewMenu from '@cohort/merchants/pages/users/overview/UsersOverviewMenu';
import {Plus, Users} from '@phosphor-icons/react';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

const PAGE_SIZE = 20;

const CohortsOverviewPage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {t} = useTranslation('pages', {keyPrefix: 'users.cohorts.page'});
  const [cohortsCount, setCohortsCount] = useState<number | null>(null);
  const [createCohortModalOpen, setCreateCohortModalOpen] = useState(false);

  const filters = useBasePageFilters(/^(\/test)?\/cohorts$/u, null);

  function handleCreateCohortClick(): void {
    setCreateCohortModalOpen(true);
  }

  const {data, isLoading} = useCohorts(merchantId, {
    page: filters.page,
    pageSize: PAGE_SIZE,
  });

  const [pagination, cohorts] = data ?? [];

  useEffect(() => {
    if (pagination !== undefined) {
      setCohortsCount(pagination.total);
    }
  }, [pagination]);

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
    titleBadge: (
      <IconBadge
        key="cohorts"
        icon={<Users className="mr-1.5 h-4 w-4 text-slate-500" />}
        text={t('cohortsBadge', {count: cohortsCount ?? 0})}
      />
    ),
    topRightElements: (
      <Button key="createCohort" onClick={handleCreateCohortClick}>
        <Plus className="-ml-1 mr-2 h-5 w-5" />
        {t('buttonCreateCohort')}
      </Button>
    ),
  };

  const paginationConfig = {
    pagination,
    onPageChange: filters.setPage,
  };

  return (
    <PageLayout title={t('title')} menu={<UsersOverviewMenu />}>
      <AdminPage header={headerConfig} pagination={paginationConfig}>
        <CohortsTable cohorts={cohorts ?? []} isLoading={isLoading} />
        {createCohortModalOpen && (
          <CreateCohortModal onClose={() => setCreateCohortModalOpen(false)} redirectAfterCreate />
        )}
      </AdminPage>
    </PageLayout>
  );
};

export default CohortsOverviewPage;
