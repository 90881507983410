import type {PatchBadgeADto} from '@cohort/admin-schemas/badge';
import AdminPage from '@cohort/merchants/components/AdminPage';
import SubmitFooter from '@cohort/merchants/components/form/SubmitFooter';
import {badgesKeys} from '@cohort/merchants/hooks/api/Badges';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentBadge} from '@cohort/merchants/hooks/contexts/currentBadge';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useBadgePageStore} from '@cohort/merchants/hooks/stores/badgePage';
import {updateBadge} from '@cohort/merchants/lib/api/Badges';
import {getBadgeVisibilityRoute} from '@cohort/merchants/lib/Pages';
import BadgeCohortStep from '@cohort/merchants/pages/users/badges/badge/edit/BadgeCohortStep';
import {useQueryClient} from '@tanstack/react-query';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const BadgeCohortPage: React.FC = () => {
  const merchant = useCurrentMerchant();
  const badge = useCurrentBadge();
  const queryClient = useQueryClient();
  const {t} = useTranslation('pages', {keyPrefix: 'users.badges.badge.cohort.page'});
  const setFooter = useBadgePageStore(state => state.setFooter);
  const navigate = useNavigate();

  const {isLoading: isUpdatingBadge, mutateAsync: updateBadgeMutation} = useCohortMutation({
    mutationFn: async (data: PatchBadgeADto) => updateBadge(merchant.id, badge.id, data),
    notifyErrorMessage: t('notificationErrorUpdate'),
    notifySuccessMessage: t('notificationUpdateSuccess'),
  });

  useEffect(() => {
    setFooter(
      <SubmitFooter
        formName="badge-cohort-step"
        loading={isUpdatingBadge}
        submitLabel={t('saveBtn')}
      />
    );
    return () => setFooter(null);
  }, [setFooter, t, isUpdatingBadge]);

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
  };

  return (
    <AdminPage header={headerConfig}>
      <BadgeCohortStep
        isProcessing={isUpdatingBadge}
        onStepValidated={() => {
          queryClient.invalidateQueries(badgesKeys.getById(merchant.id, badge.id));
          navigate(getBadgeVisibilityRoute(badge.id).path);
        }}
        updateBadgeMutation={updateBadgeMutation}
      />
    </AdminPage>
  );
};

export default BadgeCohortPage;
