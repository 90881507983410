import type {AssetADto} from '@cohort/admin-schemas/asset';
import type {PatchPerkRequestADto, PerkADto} from '@cohort/admin-schemas/perk';
import Button from '@cohort/merchants/components/buttons/Button';
import SubmitFooter from '@cohort/merchants/components/form/SubmitFooter';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCurrentPerk} from '@cohort/merchants/hooks/contexts/currentPerk';
import {changeSelectedLanguageIfNeeded} from '@cohort/merchants/lib/form/localization';
import {handleFormErrors} from '@cohort/merchants/lib/form/utils';
import type {PerkFormValues} from '@cohort/merchants/pages/perks/perk/edit/utils';
import {
  formatAndUpload,
  PerkFormSchema,
  validateIntegrationForm,
} from '@cohort/merchants/pages/perks/perk/edit/utils';
import type {AssetKind} from '@cohort/shared/schema/common/assets';
import type {UseMutateAsyncFunction, UseMutateFunction} from '@tanstack/react-query';
import type {
  Control,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
  UseFormStateReturn,
} from 'react-hook-form';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {ZodError} from 'zod';

type PerkFormFooterProps = {
  getValues: UseFormGetValues<PerkFormValues>;
  setError: UseFormSetError<PerkFormValues>;
  clearErrors: UseFormClearErrors<PerkFormValues>;
  formState: UseFormStateReturn<PerkFormValues>;
  control: Control<PerkFormValues>;
  uploadConfig: {
    mutation: UseMutateAsyncFunction<
      AssetADto,
      unknown,
      {file: File; assetKind: AssetKind},
      unknown
    >;
    isUploading: boolean;
  };
  updateConfig: {
    mutation: UseMutateFunction<PerkADto, unknown, PatchPerkRequestADto, unknown>;
    isUpdating: boolean;
  };
  publishConfig: {
    isPublishing: boolean;
  };
};

const PerkFormFooter: React.FC<PerkFormFooterProps> = ({
  getValues,
  setError,
  clearErrors,
  formState,
  control,
  uploadConfig,
  updateConfig,
  publishConfig,
}) => {
  const merchant = useCurrentMerchant();
  const perk = useCurrentPerk();
  const {t} = useTranslation('pages', {keyPrefix: 'perks.perk.edit.perkFormFooter'});
  const loading = uploadConfig.isUploading || updateConfig.isUpdating || publishConfig.isPublishing;

  const {field: selectedLanguageField} = useController({
    control,
    name: 'selectedLanguage',
  });

  return (
    <SubmitFooter
      formName="perk-form"
      loading={loading}
      submitLabel={perk.status === 'draft' ? t('buttonPublish') : t('buttonUpdate')}
      additionalAction={
        perk.status === 'draft' ? (
          <Button
            data-testid="save-perk-btn"
            loading={loading}
            variant="secondary"
            onClick={async () => {
              if (!validateIntegrationForm(getValues(), clearErrors, setError)) {
                return;
              }
              try {
                const perkData = PerkFormSchema.parse(getValues());
                const finalPerkData = await formatAndUpload(perkData, perk, uploadConfig.mutation);
                // @ts-ignore - we have an issues with z.any() being possible undefined...
                updateConfig.mutation(finalPerkData);
              } catch (err) {
                if (err instanceof ZodError) {
                  handleFormErrors(err, clearErrors, setError);
                  changeSelectedLanguageIfNeeded(
                    formState.errors,
                    merchant.defaultLanguage,
                    selectedLanguageField.onChange
                  );
                  return;
                }
                throw err;
              }
            }}
          >
            {t('buttonSave')}
          </Button>
        ) : undefined
      }
    />
  );
};

export default PerkFormFooter;
