import Button from '@cohort/merchants/components/buttons/Button';
import ErrorPageTemplate from '@cohort/merchants/components/ErrorPageTemplate';
import {getHomeRoute} from '@cohort/merchants/lib/Pages';
import {ErrorBoundary} from '@sentry/react';
import {QueryErrorResetBoundary} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

interface ErrorComponentProps {
  resetError: () => void;
  resetQuery: () => void;
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({resetError, resetQuery}) => {
  const navigate = useNavigate();
  const {t} = useTranslation('components', {keyPrefix: 'errorBoundary'});

  return (
    <ErrorPageTemplate
      title={t('title')}
      description={t('description')}
      cta={
        <div className="flex items-center gap-2">
          <Button
            onClick={() => {
              resetQuery();
              resetError();
            }}
          >
            {t('btnBack')}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              resetError();
              navigate(getHomeRoute().path);
            }}
          >
            {t('btnHome')}
          </Button>
        </div>
      }
    />
  );
};

const ErrorBoundaryComponent = ({children}: {children: React.ReactNode}): JSX.Element => (
  <QueryErrorResetBoundary>
    {({reset}) => (
      <ErrorBoundary fallback={props => <ErrorComponent {...props} resetQuery={reset} />}>
        {children}
      </ErrorBoundary>
    )}
  </QueryErrorResetBoundary>
);

export default ErrorBoundaryComponent;
