import type {ApiKeyADto, CreateApiKeyResponseADto} from '@cohort/admin-schemas/apiKeys';
import {CreateApiKeyDataASchema, UpdateApiKeyDataASchema} from '@cohort/admin-schemas/apiKeys';
import Button from '@cohort/merchants/components/buttons/Button';
import Input from '@cohort/merchants/components/form/input/Input';
import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import {
  DialogBody,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@cohort/merchants/components/modals/Dialog';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@cohort/merchants/components/tables/Table';
import {merchantsKeys} from '@cohort/merchants/hooks/api/Merchants';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {createApiKey, patchApiKey} from '@cohort/merchants/lib/api/ApiKeys';
import type {Permission} from '@cohort/shared/schema/common/apiKeyPermissions';
import {allPermissions, RESOURCES} from '@cohort/shared/schema/common/apiKeyPermissions';
import Switch from '@cohort/shared-frontend/components/Switch';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

type ManageApiKeyFormProps = {
  apiKey?: ApiKeyADto;
  onCreate: (key: CreateApiKeyResponseADto) => void;
  onCancel: () => void;
  handleOnClose: () => void;
};

const ManageApiKeyForm: React.FC<ManageApiKeyFormProps> = ({
  apiKey,
  onCreate,
  onCancel,
  handleOnClose,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const queryClient = useQueryClient();
  const {t} = useTranslation('pages', {keyPrefix: 'settings.integrations.createApiKeyModal'});

  const isCreation = apiKey === undefined;

  const baseSchema = isCreation ? CreateApiKeyDataASchema : UpdateApiKeyDataASchema;
  const ApiKeyFormSchema = baseSchema.extend({
    selectedPermissions: z.object(
      allPermissions.reduce(
        (acc, permission) => {
          acc[permission] = z.boolean();
          return acc;
        },
        {} as Record<Permission, z.ZodBoolean>
      )
    ),
  });
  type ApiKeyFormData = z.infer<typeof ApiKeyFormSchema>;

  const {register, control, handleSubmit, setValue, watch} = useForm<ApiKeyFormData>({
    resolver: zodResolver(ApiKeyFormSchema),
    defaultValues: {
      name: apiKey?.name ?? t('formInputNamePlaceholder'),
      permissions: apiKey?.permissions ?? ['basic'],
      selectedPermissions: allPermissions.reduce(
        (acc, permission) => {
          acc[permission] =
            permission === 'basic' || (apiKey?.permissions.includes(permission) ?? false);
          return acc;
        },
        {} as Record<Permission, boolean>
      ),
    },
  });

  const selectedPermissions = watch('selectedPermissions');
  const allReadPermissionsSelected = allPermissions
    .filter(permission => permission.endsWith(':read'))
    .every(permission => selectedPermissions[permission] === true);
  const allWritePermissionsSelected = allPermissions
    .filter(permission => permission.endsWith(':write'))
    .every(permission => selectedPermissions[permission] === true);

  const {isLoading: isCreating, mutate: createApiKeyMutation} = useCohortMutation({
    mutationFn: async ({data}: {data: ApiKeyFormData}) => {
      const permissions = allPermissions.filter(
        permission => data.selectedPermissions[permission] === true
      );

      return createApiKey(merchantId, {name: data.name, permissions});
    },
    onSuccess: key => {
      queryClient.invalidateQueries(merchantsKeys.settings(merchantId));

      if (isCreation) {
        onCreate(key);
        return;
      }
    },
  });

  const {isLoading: isUpdating, mutate: patchApiKeyMutation} = useCohortMutation({
    mutationFn: async ({data}: {data: ApiKeyFormData}) => {
      if (isCreation) {
        return;
      }

      const permissions = allPermissions.filter(
        permission => data.selectedPermissions[permission] === true
      );

      return patchApiKey(merchantId, apiKey.id, {name: data.name, permissions});
    },
    onSuccess: () => {
      queryClient.invalidateQueries(merchantsKeys.settings(merchantId));
      handleOnClose();
    },
    notifySuccessMessage: t('successfullUpdateMessage'),
  });

  const toggleAllPermissions = (readOrWrite: 'read' | 'write', value: boolean): void => {
    const permissions = allPermissions.filter(permission => permission.endsWith(`:${readOrWrite}`));
    for (const permission of permissions) {
      setValue(`selectedPermissions.${permission}`, value);
    }
  };

  // i18nOwl-ignore [formPermissionsTable.resource.campaigns]
  // i18nOwl-ignore [formPermissionsTable.resource.cohorts]
  // i18nOwl-ignore [formPermissionsTable.resource.digital-assets]
  // i18nOwl-ignore [formPermissionsTable.resource.orders]
  // i18nOwl-ignore [formPermissionsTable.resource.perks]
  // i18nOwl-ignore [formPermissionsTable.resource.user-properties]
  // i18nOwl-ignore [formPermissionsTable.resource.users]
  return (
    <Fragment>
      <DialogHeader className="flex flex-col justify-center text-center">
        <DialogTitle className="text-lg font-bold leading-6">
          {isCreation ? t('createFormTitle') : t('updateFormTitle')}
        </DialogTitle>
      </DialogHeader>
      <DialogBody>
        <form>
          <div className="mb-6">
            <Input
              label={t('formInputNameLabel')}
              type="text"
              autoFocus
              onFocus={e => e.target.select()}
              name="name"
              register={register}
              control={control}
            />
          </div>
          <div className="mt-4 font-medium">{t('formPermissionsLabel')}</div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t('formPermissionsTable.tableHeaderResource')}</TableHead>
                <TableHead className="w-[200px]">
                  {t('formPermissionsTable.tableHeaderRead')}
                  <Button
                    variant="link"
                    className="text-xs"
                    onClick={() => toggleAllPermissions('read', !allReadPermissionsSelected)}
                  >
                    {allReadPermissionsSelected
                      ? t('formPermissionsTable.unselectAll')
                      : t('formPermissionsTable.selectAll')}
                  </Button>
                </TableHead>
                <TableHead className="w-[200px]">
                  {t('formPermissionsTable.tableHeaderWrite')}
                  <Button
                    variant="link"
                    className="text-xs"
                    onClick={() => toggleAllPermissions('write', !allWritePermissionsSelected)}
                  >
                    {allWritePermissionsSelected
                      ? t('formPermissionsTable.unselectAll')
                      : t('formPermissionsTable.selectAll')}
                  </Button>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {RESOURCES.map(resource => (
                <TableRow key={resource}>
                  <TableCell className="text-sm font-medium text-slate-700">
                    {t(`formPermissionsTable.resource.${resource}`)}
                  </TableCell>
                  <TableCell>
                    <SwitchInput
                      name={`selectedPermissions.${resource}:read`}
                      control={control}
                      register={register}
                      onCheckedChange={value => {
                        setValue(`selectedPermissions.${resource}:read`, value);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <SwitchInput
                      name={`selectedPermissions.${resource}:write`}
                      control={control}
                      register={register}
                      onCheckedChange={value => {
                        setValue(`selectedPermissions.${resource}:write`, value);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
              <TableRow key="basic">
                <TableCell className="text-sm font-medium text-slate-700">
                  {t(`formPermissionsTable.basic`)}
                </TableCell>
                <TableCell>
                  <Switch checked={true} disabled={true} />
                </TableCell>
                <TableCell>
                  <Switch checked={true} disabled={true} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </form>
      </DialogBody>
      <DialogFooter>
        <Button variant="secondary" disabled={isCreating || isUpdating} onClick={() => onCancel()}>
          {t('cancelBtn')}
        </Button>
        <Button
          onClick={handleSubmit(data => {
            isCreation ? createApiKeyMutation({data}) : patchApiKeyMutation({data});
          })}
          disabled={isCreating || isUpdating}
          loading={isCreating || isUpdating}
        >
          {isCreation ? t('createBtn') : t('updateBtn')}
        </Button>
      </DialogFooter>
    </Fragment>
  );
};

export default ManageApiKeyForm;
