import type {PerkIntegrationId} from '@cohort/shared/apps';
import type {PublishedCampaignStatus} from '@cohort/shared/schema/common/campaign';
import type {PerkType, RaffleInstanceStatus} from '@cohort/shared/schema/common/perks';
import type {UserTDto} from '@cohort/shared/schema/templating/common';
import type {DigitalAssetTDto} from '@cohort/shared/schema/templating/digitalAsset';
import type {JourneyTDto} from '@cohort/shared/schema/templating/journey';
import type {PerkTDto} from '@cohort/shared/schema/templating/perk';
import {getImageUrl, getVideoUrl, Sizes} from '@cohort/shared/utils/media';

function imageFileKeyToUrl(fileKey: string | null, size?: keyof typeof Sizes): string | null {
  if (fileKey) {
    return getImageUrl(import.meta.env.COHORT_ENV, fileKey, {
      h: Sizes[size ?? 'S'],
      w: Sizes[size ?? 'S'],
    });
  }
  return null;
}

function videoFileKeyToUrl(fileKey: string | null, size?: keyof typeof Sizes): string | null {
  if (fileKey) {
    return getVideoUrl(import.meta.env.COHORT_ENV, fileKey, {
      h: Sizes[size ?? 'S'],
      w: Sizes[size ?? 'S'],
    });
  }
  return null;
}

type PerkContext = {
  id: string;
  displayName: string;
  description: string | null;
  bannerFileKey: string | null;
  videoFileKey: string | null;
  thumbnailFileKey: string | null;
  type: PerkType;
  integration: {
    perkIntegrationId: PerkIntegrationId;
  } | null;
};

type PerkAccessContext = {
  id: string;
  usagesCount: number;
  usagesLeft: number | null;
  canBeUsed: boolean;
  raffleStatus: RaffleInstanceStatus | null;
};

export function makePerkContext(
  perk: PerkContext,
  perkAccesses?: Array<PerkAccessContext>
): PerkTDto {
  const perkAccess = perkAccesses !== undefined && perkAccesses[0] ? perkAccesses[0] : null;

  return {
    id: perk.id,
    title: perk.displayName,
    description: perk.description,
    imageUrl: imageFileKeyToUrl(perk.bannerFileKey),
    videoUrl: videoFileKeyToUrl(perk.videoFileKey),
    videoThumbnailUrl: imageFileKeyToUrl(perk.thumbnailFileKey),
    type: perk.type,
    raffle: perkAccess?.raffleStatus
      ? {
          status: perkAccess.raffleStatus,
        }
      : null,
    integration: perk.integration ? {type: perk.integration.perkIntegrationId} : null,
    access:
      perkAccesses !== undefined && perkAccess
        ? {
            id: perkAccess.id,
            usagesCount: perkAccesses.reduce((acc, curr) => acc + curr.usagesCount, 0),
            usagesLeft: perkAccesses.some(access => access.usagesLeft === null)
              ? null
              : perkAccesses.reduce((acc, curr) => acc + (curr.usagesLeft ?? 0), 0),
            canBeUsed: perkAccesses.some(perkAccess => perkAccess.canBeUsed),
          }
        : null,
  };
}

type OwnershipContext = {
  id: string | null;
  digitalAsset: {
    collection: {
      title: string;
      description: string;
      imageKey: string;
      animationKey: string | null;
      smartContract: {
        address: string | null;
      } | null;
    };
    tokenId: number | null;
    imageFileKey: string | null;
    animationFileKey: string | null;
  };
  createdAt: Date | null;
};

export function makeDigitalAssetContext(ownership: OwnershipContext): DigitalAssetTDto {
  return {
    title: ownership.digitalAsset.collection.title,
    description: ownership.digitalAsset.collection.description,
    imageUrl:
      imageFileKeyToUrl(ownership.digitalAsset.imageFileKey) ??
      (imageFileKeyToUrl(ownership.digitalAsset.collection.imageKey) as string),
    videoUrl:
      videoFileKeyToUrl(ownership.digitalAsset.animationFileKey) ??
      videoFileKeyToUrl(ownership.digitalAsset.collection.animationKey),
    smartContractAddress: ownership.digitalAsset.collection.smartContract?.address ?? null,
    ownership:
      ownership.id && ownership.createdAt
        ? {
            id: ownership.id,
            tokenId: ownership.digitalAsset.tokenId ?? 0,
            createdAt: ownership.createdAt,
          }
        : null,
  };
}

type JourneyContext = {
  id: string;
  availableSupply: number | null;
  totalSupply: number | null;
  status: PublishedCampaignStatus;
  journey: {
    imageFileKey: string | null;
    animationFileKey: string | null;
    title: string;
    description: string;
    participation: {
      completedAt: Date | null;
    } | null;
    steps: {
      id: string;
      title: string;
      description: string;
      icon: string;
      isCompleted: boolean;
    }[];
  };
  perks: {
    id: string;
    bannerFileKey: string | null;
    description: string | null;
    thumbnailFileKey: string | null;
    videoFileKey: string | null;
    displayName: string;
  }[];
  collections: {
    id: string;
    animationKey: string | null;
    description: string;
    imageKey: string | null;
    title: string;
  }[];
};

export function makeJourneyContext(campaign: JourneyContext): JourneyTDto {
  return {
    id: campaign.id,
    imageUrl: imageFileKeyToUrl(campaign.journey.imageFileKey, 'M'),
    videoUrl: videoFileKeyToUrl(campaign.journey.animationFileKey, 'M'),
    title: campaign.journey.title,
    availableSupply: campaign.availableSupply,
    totalSupply: campaign.totalSupply,
    steps: campaign.journey.steps,
    description: campaign.journey.description,
    status: campaign.status,
    participation: campaign.journey.participation,
    rewards: [
      ...campaign.perks.map(perk => ({
        id: perk.id,
        videoUrl: videoFileKeyToUrl(perk.videoFileKey),
        description: perk.description,
        imageUrl: imageFileKeyToUrl(perk.bannerFileKey),
        title: perk.displayName,
        type: 'perk' as const,
      })),
      ...campaign.collections.map(collection => ({
        id: collection.id,
        videoUrl: videoFileKeyToUrl(collection.animationKey),
        description: collection.description,
        imageUrl: imageFileKeyToUrl(collection.imageKey),
        title: collection.title,
        type: 'digital-asset' as const,
      })),
    ],
  };
}

type MerchantContext = {
  membershipPassConfiguration: {
    bannerFileKey: string | null;
  };
};

type UserContext = {
  id: string;
  email: string;
  membershipPass: {
    memberHandle: string;
    memberId: number;
    subscriptionDate: Date;
  };
  wallet: {
    address: string;
  };
};

export function makeUserContext(
  merchant: MerchantContext,
  user: UserContext | null
): UserTDto | null {
  if (user === null) {
    return null;
  }

  return {
    id: user.id,
    email: user.email,
    membership: {
      memberHandle: user.membershipPass.memberHandle,
      memberId: user.membershipPass.memberId,
      subscriptionDate: user.membershipPass.subscriptionDate,
      imageUrl: imageFileKeyToUrl(merchant.membershipPassConfiguration.bannerFileKey, 'M'),
    },
    walletAddress: user.wallet.address,
  };
}
