import type {NotificationADto} from '@cohort/admin-schemas/notification';
import {formatDate} from '@cohort/shared/utils/format';
import {Popover, PopoverContent} from '@cohort/shared-frontend/components/Popover';
import {CheckCircle, DotsThreeCircle, XCircle} from '@phosphor-icons/react';
import {Anchor} from '@radix-ui/react-popover';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

type Props = {
  lastExecution: NotificationADto | null;
};

const NotificationConfigurationLastExecutionBadge: React.FC<Props> = ({lastExecution}) => {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.notifications.notificationConfigurationLastExecutionBadge',
  });

  const icon = match(lastExecution?.status)
    .with(undefined, () => <DotsThreeCircle size={16} color={'grey'} />)
    .with('success', () => <CheckCircle size={16} color="green" />)
    .with('error', () => <XCircle size={16} color="red" />)
    .exhaustive();

  return (
    <Popover open={open}>
      <Anchor
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        onClick={e => e.preventDefault()}
      >
        {icon}
      </Anchor>
      <PopoverContent className="w-full">
        <div className="p-2 text-xs">
          {!lastExecution && <p>{t('noExecution')}</p>}
          {lastExecution && (
            <Fragment>
              <p>{t('lastExecution', {date: formatDate(lastExecution.createdAt)})}</p>
              <p>{t('status', {status: lastExecution.status})}</p>
              <p>{lastExecution.message}</p>
            </Fragment>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
export default NotificationConfigurationLastExecutionBadge;
