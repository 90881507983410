import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import CampaignCohort from '@cohort/merchants/pages/campaigns/campaign/audience/CampaignCohort';
import CampaignEveryone from '@cohort/merchants/pages/campaigns/campaign/audience/CampaignEveryone';
import CampaignInvitees from '@cohort/merchants/pages/campaigns/campaign/audience/CampaignInvitees';
import {Fragment} from 'react';
import {match} from 'ts-pattern';

const CampaignAudiencePage = (): JSX.Element => {
  const campaign = useCurrentCampaign();
  const content = match(campaign.audience)
    .with('invitees', () => <CampaignInvitees />)
    .with('cohort', () => campaign.cohort !== null && <CampaignCohort />)
    .with('everyone', () => <CampaignEveryone />)
    .with(null, () => <Fragment />)
    .exhaustive();

  return (
    <div className="grid h-full [grid-template-rows:min-content_1fr_min-content]">{content}</div>
  );
};

export default CampaignAudiencePage;
