import type {
  DigitalAssetCollectionADto,
  DuplicateDigitalAssetCollectionADto,
} from '@cohort/admin-schemas/digitalAssetCollection';
import Button from '@cohort/merchants/components/buttons/Button';
import Form from '@cohort/merchants/components/form/Form';
import Input from '@cohort/merchants/components/form/input/Input';
import Header from '@cohort/merchants/components/Header';
import {Dialog, DialogContent} from '@cohort/merchants/components/modals/Dialog';
import {digitalAssetCollectionsKeys} from '@cohort/merchants/hooks/api/DigitalAssetCollections';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {duplicateDigitalAssetCollection} from '@cohort/merchants/lib/api/DigitalAssetCollections';
import {trackDigitalAssetCollectionDuplicateSuccess} from '@cohort/merchants/lib/Tracking';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

interface DuplicateDigitalAssetCollectionModalProps {
  onClose: () => void;
  digitalAssetCollection: DigitalAssetCollectionADto;
}

const DuplicateDigitalAssetCollectionModalSchema = z.object({
  internalName: z.string().min(3, {message: 'errorTooShort3'}),
  nftEnabled: z.boolean(),
  nftNonTransferable: z.boolean(),
});

const DuplicateDigitalAssetCollectionModal: React.FC<DuplicateDigitalAssetCollectionModalProps> = ({
  onClose,
  digitalAssetCollection,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const queryClient = useQueryClient();
  const {t} = useTranslation('pages', {
    keyPrefix: 'digitalAssetCollections.overview.duplicateDigitalAssetCollectionModal',
  });

  const {isLoading, mutate: duplicateDigitalAssetCollectionMutation} = useCohortMutation({
    mutationFn: async (data: DuplicateDigitalAssetCollectionADto) =>
      duplicateDigitalAssetCollection(merchantId, digitalAssetCollection.id, data),
    notifySuccessMessage: t('notificationDuplicateSuccess'),
    onSuccess: async newDigitalAsset => {
      await queryClient.invalidateQueries(digitalAssetCollectionsKeys.list(merchantId));
      onClose();
      trackDigitalAssetCollectionDuplicateSuccess(newDigitalAsset);
    },
  });

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Form
          className="space-y-6 p-6"
          formSchema={DuplicateDigitalAssetCollectionModalSchema}
          defaultValues={{
            internalName: `${digitalAssetCollection.internalName} ${t('copySuffix')}`,
            nftEnabled: digitalAssetCollection.nftEnabled,
            nftNonTransferable: digitalAssetCollection.nftNonTransferable,
          }}
          isSubmitting={isLoading}
          onSubmit={data => duplicateDigitalAssetCollectionMutation(data)}
          testId="duplicate-digital-asset-collection-modal"
          formActions={[
            <Button key="close-duplicate-modal" variant="secondary" onClick={onClose}>
              {t('buttonCancel')}
            </Button>,
            <Button
              data-testid="duplicate-digital-asset-collection-submit-btn"
              type="submit"
              disabled={false}
            >
              {t('buttonDuplicate')}
            </Button>,
          ]}
        >
          {({register, control, watch}) => {
            const nftEnabled = watch('nftEnabled');
            return (
              <Fragment>
                <Header title={t('duplicate')} subtitle={t('subtitle')} />
                <Input
                  type="text"
                  label={t('labelInternalName')}
                  name="internalName"
                  register={register}
                  control={control}
                  description={t('labelInternalNameDescription')}
                />
                <div className="grid h-10 gap-4 [grid-template-columns:max-content_1fr]">
                  <Input
                    type="checkbox"
                    name="nftEnabled"
                    label={t('labelNftEnabled')}
                    labelPosition="right"
                    control={control}
                    register={register}
                  />
                  {nftEnabled && (
                    <Input
                      type="checkbox"
                      name="nftNonTransferable"
                      label={t('labelNftNonTransferable')}
                      labelPosition="right"
                      control={control}
                      register={register}
                    />
                  )}
                </div>
              </Fragment>
            );
          }}
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default DuplicateDigitalAssetCollectionModal;
