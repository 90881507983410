import {getAppsWithTriggerIntegrations} from '@cohort/merchants/apps';
import AppBadge from '@cohort/merchants/apps/AppBadge';
import {useTriggerIntegrations} from '@cohort/merchants/apps/useTriggerIntegrations';
import Button from '@cohort/merchants/components/buttons/Button';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import StepTriggerForm from '@cohort/merchants/pages/campaigns/campaign/edit/settings/journey/StepTriggerForm';
import type {
  CreateEditStepModalType,
  StepTriggerType,
} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/journey/utils';
import {defaultEmptyTrigger} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/journey/utils';
import {PencilSimple, PlusCircle, Trash} from '@phosphor-icons/react';
import React, {Fragment} from 'react';
import type {Control, UseFormRegister} from 'react-hook-form';
import {useController, useFieldArray} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type StepTriggersListProps = {
  control: Control<CreateEditStepModalType>;
  register: UseFormRegister<CreateEditStepModalType>;
};

type StepTriggerItemProps = {
  index: number;
  trigger: StepTriggerType;
  onSaved: (trigger: StepTriggerType) => void;
  onRemove: () => void;
  control: Control<CreateEditStepModalType>;
};

type StepTriggerRowProps = {
  trigger: StepTriggerType;
  onEdit: () => void;
  onRemove: () => void;
};

const StepTriggerRow: React.FC<StepTriggerRowProps> = ({trigger, onEdit, onRemove}) => {
  const merchant = useCurrentMerchant();
  const appsListWithTriggers = getAppsWithTriggerIntegrations();
  const {getTriggerIntegration, getTriggerIntegrationTitle} = useTriggerIntegrations();
  const triggerIntegration = getTriggerIntegration(trigger.triggerIntegrationId);
  const app = appsListWithTriggers.find(app =>
    app.triggerIntegrations.find(
      integration => integration.spec.id === trigger.triggerIntegrationId
    )
  );

  return (
    <div className="flex items-center justify-between rounded-lg bg-gray-100 p-4 text-sm font-semibold">
      {trigger.title?.[merchant.defaultLanguage] ?? getTriggerIntegrationTitle(triggerIntegration)}
      <div className="flex items-center gap-4">
        {app && <AppBadge appId={app.spec.id} />}
        <button type="button" onClick={onEdit}>
          <PencilSimple className="h-5 w-5 text-slate-400" />
        </button>
        <button type="button" onClick={onRemove}>
          <Trash className="h-5 w-5 text-red-400" />
        </button>
      </div>
    </div>
  );
};

const StepTriggerItem: React.FC<StepTriggerItemProps> = ({
  index,
  trigger,
  onSaved,
  onRemove,
  control,
}) => {
  const [editMode, setEditMode] = React.useState(!trigger.triggerIntegrationId);
  const {field: definedLanguagesField} = useController({control, name: 'definedLanguages'});
  const {field: selectedLanguageField} = useController({control, name: 'selectedLanguage'});

  if (editMode) {
    return (
      <StepTriggerForm
        index={index}
        trigger={trigger}
        onRemove={onRemove}
        onSaved={trigger => {
          onSaved(trigger);
          setEditMode(false);
        }}
        selectedLanguage={selectedLanguageField.value}
        definedLanguages={definedLanguagesField.value}
      />
    );
  }

  return <StepTriggerRow trigger={trigger} onEdit={() => setEditMode(true)} onRemove={onRemove} />;
};

const StepTriggersList: React.FC<StepTriggersListProps> = ({control}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.settings.journey.stepTriggersList',
  });
  const merchant = useCurrentMerchant();
  const {fields, append, remove, update} = useFieldArray({
    name: 'triggers',
    control,
    keyName: '_id',
  });

  const {field: selectedLanguageField} = useController({control, name: 'selectedLanguage'});
  const isDefaultLanguageSelected = selectedLanguageField.value === merchant.defaultLanguage;

  return (
    <ul className="space-y-4">
      {fields.map((trigger, index) => {
        if (!isDefaultLanguageSelected && trigger.triggerIntegrationId !== 'cohort.fill-form') {
          return <Fragment />;
        }
        return (
          <StepTriggerItem
            key={trigger._id}
            index={index}
            trigger={trigger}
            onSaved={trigger => update(index, trigger)}
            onRemove={() => remove(index)}
            control={control}
          />
        );
      })}
      <Button variant="ghost" type="button" onClick={() => append(defaultEmptyTrigger)}>
        <PlusCircle className="-ml-1 mr-2 h-5 w-5" />
        {t('addTriggerBtn')}
      </Button>
    </ul>
  );
};

export default StepTriggersList;
