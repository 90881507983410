import AsidePageMenu from '@cohort/merchants/components/AsidePageMenu';
import {useCurrentUser} from '@cohort/merchants/hooks/contexts/currentUser';
import {
  getUserActivityRoute,
  getUserBadgesRoute,
  getUserCohortsRoute,
  getUserDigitalAssetsRoute,
  getUserJourneysRoute,
  getUserOverviewRoute,
  getUserPerksRoute,
} from '@cohort/merchants/lib/Pages';
import {
  ChartPieSlice,
  Flag,
  Gift,
  HouseLine,
  Images,
  ListMagnifyingGlass,
  Medal,
} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

const UserPageMenu = (): JSX.Element => {
  const {t} = useTranslation('pages', {keyPrefix: 'users.user.userPageMenu'});
  const user = useCurrentUser();

  return (
    <AsidePageMenu
      entries={[
        {
          name: t('overview'),
          path: getUserOverviewRoute(user.id).path,
          icon: <HouseLine size={20} />,
          testId: 'user-overview-link',
        },
        {
          name: t('activity'),
          path: getUserActivityRoute(user.id).path,
          icon: <ListMagnifyingGlass size={20} />,
          testId: 'user-activity-link',
        },
        {
          name: t('journeys'),
          path: getUserJourneysRoute(user.id).path,
          icon: <Flag size={20} />,
          testId: 'user-journeys-link',
        },
        {
          name: t('perks'),
          path: getUserPerksRoute(user.id).path,
          icon: <Gift size={20} />,
          testId: 'user-perks-link',
        },
        {
          name: t('digitalAssets'),
          path: getUserDigitalAssetsRoute(user.id).path,
          icon: <Images size={20} />,
          testId: 'user-digital-assets-link',
        },
        {
          name: t('cohorts'),
          path: getUserCohortsRoute(user.id).path,
          icon: <ChartPieSlice size={20} />,
          testId: 'user-cohorts-link',
        },
        {
          name: t('badges'),
          path: getUserBadgesRoute(user.id).path,
          icon: <Medal size={20} />,
          testId: 'user-badges-link',
        },
      ]}
    />
  );
};

export default UserPageMenu;
