import type {OrganizationADto} from '@cohort/admin-schemas/organization';
import MerchantLogoPlaceholder from '@cohort/components-xps/components/MerchantLogoPlaceholder';
import {CardWithRadientBackground} from '@cohort/merchants/components/CardWithRadientBackground';
import {useOrganizations} from '@cohort/merchants/hooks/api/Organizations';
import {getSignInRoute} from '@cohort/merchants/lib/Pages';
import {getOrganizationSigninUrl, waitlistFormRedirect} from '@cohort/merchants/lib/Utils';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {Navigate} from 'react-router-dom';

type OrganizationCardProps = {
  organization: OrganizationADto;
};

const OrganizationCard: React.FC<OrganizationCardProps> = ({organization}) => {
  const {name, merchantLogoFileKey} = organization;
  const organizationSigninUrl = getOrganizationSigninUrl(organization.slug);

  return (
    <a
      className="flex w-full cursor-pointer items-center space-x-3 rounded-md border border-slate-100 p-4 shadow-sm hover:bg-slate-50"
      href={organizationSigninUrl}
    >
      {merchantLogoFileKey !== null ? (
        <img
          src={getImageUrl(import.meta.env.COHORT_ENV, merchantLogoFileKey, {
            w: Sizes.XS,
            h: Sizes.XS,
          })}
          className="h-10 w-10 rounded-md"
          alt={name}
        />
      ) : (
        <MerchantLogoPlaceholder size={40} merchantName={name} />
      )}
      <h1 className="text-center text-sm font-medium text-gray-900">{name}</h1>
    </a>
  );
};

const OrgPickerPage: React.FC = () => {
  const {data: organizations, error: organizationFetchingError} = useOrganizations();
  const {t} = useTranslation('pages', {keyPrefix: 'sign-in.org-picker.page'});

  if (organizations === undefined) {
    return <Fragment />;
  }

  if (organizationFetchingError) {
    return <Navigate to={getSignInRoute().path} replace />;
  }

  if (organizations.length === 0) {
    waitlistFormRedirect();
    return <Fragment />;
  }
  return (
    <CardWithRadientBackground>
      <div className="flex flex-col space-y-3">
        <h1 className="text-2xl font-semibold">{t('title')}</h1>
        <p className="text-sm text-slate-500">{t('subtitle')}</p>
      </div>
      <div className="flex w-full flex-col space-y-4">
        {organizations.map(organization => (
          <OrganizationCard key={organization.id} organization={organization} />
        ))}
      </div>
    </CardWithRadientBackground>
  );
};

export default OrgPickerPage;
