import Button from '@cohort/merchants/components/buttons/Button';
import {EmptyContentContainer} from '@cohort/merchants/components/EmptyContentContainer';
import {XCircle} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

const ErrorState: React.FC = () => {
  const {t} = useTranslation('components', {keyPrefix: 'errorState'});

  return (
    <EmptyContentContainer
      icon={<XCircle size={40} className="text-slate-400" />}
      text={t('title')}
      description={t('description')}
      button={<Button onClick={() => location.reload()}>{t('btnRefresh')}</Button>}
    />
  );
};

export default ErrorState;
