import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import SelectCohort from '@cohort/merchants/components/cohorts/SelectCohort';
import RadioCards from '@cohort/merchants/components/form/RadioCards';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {notify} from '@cohort/merchants/hooks/toast';
import {handleFormErrors} from '@cohort/merchants/lib/form/utils';
import {getCampaignsRoute} from '@cohort/merchants/lib/Pages';
import type {
  CampaignAudienceStepValues,
  FormStepProps,
} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import {CampaignAudienceStepSchema} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import {zodResolver} from '@hookform/resolvers/zod';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {z} from 'zod';

const CampaignAudienceStep: React.FC<FormStepProps> = ({
  isProcessing,
  setStepDirty,
  setStepSaveBtn,
  onStepValidated,
  updateCampaign,
}): JSX.Element => {
  const campaign = useCurrentCampaign();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.campaignAudienceStep',
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    watch,
    setValue,
    formState: {isDirty},
  } = useForm<CampaignAudienceStepValues>({
    defaultValues: {
      audience: campaign.audience ?? 'everyone',
      cohortId: campaign.cohort?.id ?? null,
    },
    resolver: zodResolver(CampaignAudienceStepSchema),
  });
  const navigate = useNavigate();
  const [audience] = watch(['audience']);

  useEffect(() => {
    setStepDirty?.(isDirty);
  }, [isDirty, setStepDirty]);

  useEffect(() => {
    setStepSaveBtn?.(
      <Button
        variant="secondary"
        loading={isProcessing}
        onClick={async () => {
          try {
            const campaignData = CampaignAudienceStepSchema.parse(getValues());

            clearErrors();
            await updateCampaign(campaignData);
            notify('success', t('notificationSuccess'));
            navigate(getCampaignsRoute().path);
          } catch (error) {
            if (error instanceof z.ZodError) {
              handleFormErrors(error, clearErrors, setError);
            } else {
              throw error;
            }
          }
        }}
      >
        {t('saveBtn')}
      </Button>
    );
  }, [t, clearErrors, getValues, setError, updateCampaign, isProcessing, navigate, setStepSaveBtn]);

  return (
    <AdminPage header={{title: t('title'), subtitle: t('subtitle')}}>
      <form
        className="space-y-6"
        id="campaign-audience-step"
        onSubmit={handleSubmit(async data => {
          await updateCampaign(data);
          onStepValidated();
        })}
      >
        <RadioCards
          name="audience"
          direction="column"
          label={t('labelAudience')}
          register={register}
          control={control}
          onChange={() => setValue('cohortId', null)}
          options={[
            {
              label: t('labelEveryone'),
              description:
                campaign.type === 'airdrop'
                  ? t('descriptionEveryoneAirdrop')
                  : t('descriptionEveryone'),
              value: 'everyone',
            },
            ...(campaign.type !== 'journey' && campaign.type !== 'airdrop'
              ? [
                  {
                    label: t('labelInvitees'),
                    description: t('descriptionInvitees'),
                    value: 'invitees',
                  },
                ]
              : []),
            {
              label: t('labelCohort'),
              description:
                campaign.type === 'airdrop'
                  ? t('descriptionCohortAirdrop')
                  : t('descriptionCohort'),
              value: 'cohort',
            },
          ]}
        />
        {audience === 'cohort' && <SelectCohort register={register} allowCohortCreation />}
      </form>
    </AdminPage>
  );
};

export default CampaignAudienceStep;
