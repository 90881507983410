import Button from '@cohort/merchants/components/buttons/Button';
import RadioCards from '@cohort/merchants/components/form/RadioCards';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetTitle,
} from '@cohort/merchants/components/modals/Sheet';
import type {StepTriggerFormType} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/journey/utils';
import type {SpotifyPlaylist} from '@cohort/shared/apps/spotify/schema';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {zodResolver} from '@hookform/resolvers/zod';
import {CheckCircle} from '@phosphor-icons/react';
import {Image} from '@phosphor-icons/react';
import type {UseFormReturn} from 'react-hook-form';
import {useController, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

const SelectPlaylistFormSchema = z.object({
  playlistId: z.string().nullable(),
});
type SelectPlaylistFormValues = z.infer<typeof SelectPlaylistFormSchema>;

type SelectPlaylistSheetProps = {
  playlists: SpotifyPlaylist[];
  formReturn: UseFormReturn<StepTriggerFormType>;
  onClose: () => void;
};
const SelectPlaylistSheet: React.FC<SelectPlaylistSheetProps> = ({
  playlists,
  formReturn,
  onClose,
}) => {
  const {setValue, control} = formReturn;
  const {field: triggerPlaylistIdField} = useController({
    name: 'triggerIntegrationConfig.playlistId',
    control,
  });
  const {t} = useTranslation('app-spotify', {
    keyPrefix: 'components.selectPlaylistSheet',
  });

  const {register: selectPlaylistRegister, control: selectPlaylistControl} =
    useForm<SelectPlaylistFormValues>({
      resolver: zodResolver(SelectPlaylistFormSchema),
      defaultValues: {
        playlistId: triggerPlaylistIdField.value ?? null,
      },
    });
  const {field: playlistIdField} = useController({
    control: selectPlaylistControl,
    name: 'playlistId',
  });

  const onSubmit = (): void => {
    if (playlistIdField.value !== null) {
      setValue('triggerIntegrationConfig.playlistId', playlistIdField.value);
    }
    onClose();
  };

  return (
    <Sheet open onOpenChange={onClose}>
      <SheetContent className="max-h-100vh !grid h-[100vh] gap-0 bg-white [grid-template-rows:min-content_1fr_min-content]">
        <SheetTitle className="p-6">{t('title')}</SheetTitle>
        <div className="m-6 mt-0 flex flex-col gap-6 overflow-y-auto rounded-2xl border border-slate-200 bg-slate-50 p-6">
          <RadioCards
            name="playlistId"
            direction="row"
            register={selectPlaylistRegister}
            control={selectPlaylistControl}
            className="grid grid-cols-4 gap-4"
            options={playlists.map(playlist => ({
              label: (
                <div className="aspect-square overflow-hidden rounded-lg shadow-sm">
                  {playlist.thumbnailUrl !== null ? (
                    <img
                      src={playlist.thumbnailUrl}
                      className={cn(playlist.id === playlistIdField.value && 'opacity-50')}
                    />
                  ) : (
                    <Image size={24} className="text-slate-400" />
                  )}
                </div>
              ),
              value: playlist.id,
            }))}
            labelClassName="p-0"
            withCheckIcon={true}
            checkIconComponent={
              <div className="absolute bottom-3 right-3 rounded-full bg-white p-[1px]">
                <CheckCircle weight="fill" className="h-5 w-5 text-primary-darker" />
              </div>
            }
          />
        </div>
        <SheetFooter className="flex w-full !flex-row !justify-between border-t border-border px-6 py-4">
          <Button variant="secondary" onClick={onClose}>
            {t('buttonCancel')}
          </Button>
          <Button onClick={onSubmit}>{t('buttonConfirm')}</Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default SelectPlaylistSheet;
