import type {PerkAnalyticsADto} from '@cohort/admin-schemas/perk';
import Button from '@cohort/merchants/components/buttons/Button';
import {PerkAppBadge} from '@cohort/merchants/components/perks/PerkAppBadge';
import PerkRaffleBadge from '@cohort/merchants/components/perks/PerkRaffleBadge';
import {PerkStatusBadge} from '@cohort/merchants/components/perks/PerkStatusBadge';
import {PerkTypeBadge} from '@cohort/merchants/components/perks/PerkTypeBadge';
import {PerkVisual} from '@cohort/merchants/components/PerkVisual';
import {Progress} from '@cohort/merchants/components/Progress';
import {getPerkEditRoute, getPerkRoute} from '@cohort/merchants/lib/Pages';
import {trackPerkCardClicked} from '@cohort/merchants/lib/Tracking';
import PerkDropdown from '@cohort/merchants/pages/perks/overview/PerkDropdown';
import {formatDate} from '@cohort/shared/utils/format';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

interface PerkOverviewCardProps {
  perk: PerkAnalyticsADto;
}

const PerkCardHeader: React.FC<PerkOverviewCardProps> = ({perk}) => (
  <section className="flex w-full justify-between">
    <div className="flex items-start gap-4">
      <PerkVisual perk={perk} />
      <PerkStatusBadge status={perk.status} liveCount={perk.campaignsCount} size="small" />
    </div>
    <PerkDropdown perk={perk} />
  </section>
);

const PerkCardContent: React.FC<PerkOverviewCardProps> = ({perk}) => (
  <section className="mt-4 truncate">
    <h3 className="truncate text-lg font-semibold">{perk.internalName}</h3>
    <div className="mt-1 flex flex-wrap items-center gap-2">
      <PerkTypeBadge perkType={perk.type} />
      <PerkAppBadge perkIntegrationId={perk.integration?.perkIntegrationId ?? null} />
      {perk.raffle !== null && <PerkRaffleBadge />}
    </div>
  </section>
);

const PerkCardFooter: React.FC<PerkOverviewCardProps> = ({perk}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'perks.overview.perkCard'});

  const usageProgress =
    perk.perkAccessesCount !== 0
      ? (perk.perkAccessesWithUsageCount / perk.perkAccessesCount) * 100
      : 0;

  if (perk.status === 'draft') {
    return (
      <section className="mt-2">
        <Button variant="secondary">{t('btnFinishDraft')}</Button>
      </section>
    );
  }

  return (
    <section className="mt-2">
      <div className="inline-flex w-full items-center">
        <Progress value={usageProgress} className="h-1" />
        <h3 className="ml-4 whitespace-nowrap text-sm font-medium text-primary">
          {perk.perkAccessesWithUsageCount}/{perk.perkAccessesCount} {t('labelUsed')}
        </h3>
      </div>
      <span data-testid="perk-date" className="text-xs text-gray-500">
        {t('labelLastEdit')} {formatDate(perk.updatedAt)}
      </span>
    </section>
  );
};

const PerkOverviewCard: React.FC<PerkOverviewCardProps> = ({perk}) => (
  <li
    key={perk.id}
    data-testid="campaign-card"
    className="rounded-lg border transition duration-300 ease-in-out md:hover:shadow-md"
  >
    <Link
      className="block h-full w-full"
      to={perk.status === 'draft' ? getPerkEditRoute(perk.id).path : getPerkRoute(perk.id).path}
      onClick={() => trackPerkCardClicked(perk)}
    >
      <div className="grid h-full w-full p-4 [grid-template-rows:min-content_1fr_min-content]">
        <PerkCardHeader perk={perk} />
        <PerkCardContent perk={perk} />
        <PerkCardFooter perk={perk} />
      </div>
    </Link>
  </li>
);

export default PerkOverviewCard;
