import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useCampaignPageStore} from '@cohort/merchants/hooks/stores/campaignPage';
import {SCROLL_SPY_CONTAINER_ID} from '@cohort/merchants/lib/StorePreview';
import {
  trackCampaignPreviewModalClosed,
  trackCampaignPreviewModalOpened,
} from '@cohort/merchants/lib/Tracking';
import CampaignPreviewModal from '@cohort/merchants/pages/campaigns/campaign/CampaignPreviewModal';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {ArrowsOut} from '@phosphor-icons/react';
import React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

type CampaignPreviewAsideProps = {
  children: React.ReactElement;
};

const CampaignPreviewAside: React.FC<CampaignPreviewAsideProps> = ({
  children: previewComponent,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.campaignPreviewAside',
  });
  const campaign = useCurrentCampaign();
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const footer = useCampaignPageStore(state => state.footer);

  return (
    <div className="h-full">
      <div className="hidden w-[var(--preview-width)] gap-4 [grid-template-rows:min-content_1fr] lg:grid">
        <button
          data-testid="open-campaign-preview"
          className="flex cursor-pointer items-center justify-center text-slate-500"
          onClick={() => {
            setPreviewModalOpen(true);
            trackCampaignPreviewModalOpened(campaign);
          }}
        >
          <ArrowsOut className="mr-1 h-4 w-4" />
          <span className="text-sm font-medium">{t('labelPreviewOpen')}</span>
        </button>

        {!previewModalOpen && (
          <div
            id={SCROLL_SPY_CONTAINER_ID}
            className={cn(
              'no-scrollbar relative overflow-auto rounded-md',
              footer
                ? 'max-h-[calc(var(--xps-preview-max-height)-var(--submit-footer-height))]'
                : 'max-h-[var(--xps-preview-max-height)]'
            )}
          >
            {React.cloneElement(previewComponent, {isMobile: true})}
          </div>
        )}
      </div>
      {previewModalOpen && (
        <CampaignPreviewModal
          previewComponent={previewComponent}
          handleOnClose={() => {
            setPreviewModalOpen(false);
            trackCampaignPreviewModalClosed(campaign);
          }}
        />
      )}
    </div>
  );
};

export default CampaignPreviewAside;
