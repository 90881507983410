import type {WrappedField} from '@cohort/merchants/components/form/FieldWrapper';
import FieldWrapper from '@cohort/merchants/components/form/FieldWrapper';
import type {FormField} from '@cohort/merchants/lib/form/utils';
import Switch from '@cohort/shared-frontend/components/Switch';
// eslint-disable-next-line import/no-namespace
import type * as SwitchPrimitives from '@radix-ui/react-switch';
import type {FieldValues} from 'react-hook-form';
import {useController} from 'react-hook-form';

type SwitchProps<T extends FieldValues> = React.ComponentPropsWithoutRef<
  typeof SwitchPrimitives.Root
> &
  WrappedField &
  FormField<T>;

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function SwitchInput<T extends FieldValues>({
  register,
  control,
  label,
  description,
  labelPosition,
  className,
  onCheckedChange,
  ...props
}: SwitchProps<T>): JSX.Element {
  const {field, fieldState} = useController({
    control,
    name: props.name,
  });

  return (
    <FieldWrapper
      label={label}
      name={props.name}
      description={description}
      labelPosition={labelPosition}
      error={fieldState.error?.message}
      className={className}
    >
      <Switch
        {...props}
        ref={field.ref}
        checked={field.value}
        onCheckedChange={e => {
          field.onChange(e);
          if (onCheckedChange) {
            onCheckedChange(e);
          }
        }}
      />
    </FieldWrapper>
  );
}
