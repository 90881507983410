import type {ApiKeyADto} from '@cohort/admin-schemas/apiKeys';
import AdminPage from '@cohort/merchants/components/AdminPage';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import SectionSeparator from '@cohort/merchants/components/SectionSeparator';
import {merchantsKeys, useMerchantApiSettings} from '@cohort/merchants/hooks/api/Merchants';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {deleteApiKey} from '@cohort/merchants/lib/api/ApiKeys';
import {trackApiSettingsPageViewed} from '@cohort/merchants/lib/Tracking';
import ApiKeysTable from '@cohort/merchants/pages/settings/integrations/ApiKeysTable';
import EmbedSettingsForm from '@cohort/merchants/pages/settings/integrations/EmbedForm';
import LinkSection from '@cohort/merchants/pages/settings/integrations/LinkSection';
import ManageApiKeyModal from '@cohort/merchants/pages/settings/integrations/ManageApiKeyModal/ManageApiKeyModal';
import {ArrowSquareOut, Plus} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

const IntegrationsSettingsPage = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const queryClient = useQueryClient();
  const {data: settings} = useMerchantApiSettings(merchantId);
  const [createApiKeyModal, setCreateApiKeyModal] = useState(false);
  const [apiKeyToDelete, setApiKeyToDelete] = useState<string>();
  const [apiKeyToUpdate, setApiKeyToUpdate] = useState<ApiKeyADto | null>(null);

  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.integrations.page',
  });

  const {mutate: deleteApiKeyMutation} = useCohortMutation({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    mutationFn: async () => deleteApiKey(merchantId, apiKeyToDelete!),
    notifySuccessMessage: t('apiKeyDeleteSuccess'),
    onSuccess: async () => {
      await queryClient.invalidateQueries(merchantsKeys.settings(merchantId));
      setApiKeyToDelete(undefined);
    },
  });

  useEffect(() => {
    trackApiSettingsPageViewed();
  }, []);

  if (settings === undefined) {
    return <Fragment />;
  }

  const headerConfig = {
    title: t('header'),
    subtitle: t('subheader'),
  };

  return (
    <AdminPage header={headerConfig} className="space-y-10">
      <SectionSeparator />
      <EmbedSettingsForm merchantId={merchantId} embedUrl={settings.embedUrl} />
      <SectionSeparator />
      <LinkSection
        title={t('webhooksTitle')}
        description={t('webhooksDescription')}
        buttons={[
          {
            label: t('webhooksLinkLabel'),
            url: settings.webhooksPortalLink,
            icon: <ArrowSquareOut className="-ml-1 mr-2 h-5 w-5" />,
          },
        ]}
      />
      <SectionSeparator />
      <LinkSection
        title={t('apiDocumentationTitle')}
        description={t('apiDocumentationDescription')}
        buttons={[
          {
            label: t('apiDocumentationLinkLabel'),
            url: `https://docs.getcohort.com/developers/merchants-api`,
            icon: <ArrowSquareOut className="-ml-1 mr-2 h-5 w-5" />,
          },
        ]}
      />
      <SectionSeparator />
      <LinkSection
        title={t('apiKeyTitle')}
        description={t('apiKeyDescription')}
        buttons={[
          {
            color: 'primary',
            label: t('apiKeyLinkLabel'),
            icon: <Plus className="-ml-1 mr-2 h-5 w-5" />,
            handleOnClick: () => setCreateApiKeyModal(true),
          },
        ]}
      />
      <ApiKeysTable
        apiKeys={settings.apiKeys}
        onDeleteApiKey={apiKeyId => setApiKeyToDelete(apiKeyId)}
        onEditApiKey={apiKey => setApiKeyToUpdate(apiKey)}
      />
      {createApiKeyModal && <ManageApiKeyModal handleOnClose={() => setCreateApiKeyModal(false)} />}
      {apiKeyToUpdate !== null && (
        <ManageApiKeyModal apiKey={apiKeyToUpdate} handleOnClose={() => setApiKeyToUpdate(null)} />
      )}
      <DeletionModal
        title={t('deleteKeyModalTitle')}
        subtitle={t('deleteKeyModalSubtitle')}
        onClose={() => setApiKeyToDelete(undefined)}
        onDelete={() => deleteApiKeyMutation()}
        show={apiKeyToDelete !== undefined}
      />
    </AdminPage>
  );
};

export default IntegrationsSettingsPage;
