/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {ListSyncsParamsADto, SyncADto} from '@cohort/admin-schemas/sync';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getSyncById, listSyncs} from '@cohort/merchants/lib/api/Sync';
import type {UseQueryResult} from '@tanstack/react-query';

export const syncsKeys = {
  syncs: ['syncs'] as const,
  list: (merchantId: string) => [...syncsKeys.syncs, merchantId, 'list'] as const,
  listWithFilters: (merchantId: string, params?: ListSyncsParamsADto) =>
    [...syncsKeys.list(merchantId), params] as const,
  getById: (merchantId: string, syncId: string) =>
    [...syncsKeys.syncs, merchantId, syncId] as const,
};

export const useSync = (merchantId: string, syncId: string, options?: QueryOptions) =>
  useCohortQuery({
    queryKey: syncsKeys.getById(merchantId, syncId),
    queryFn: async () => getSyncById(merchantId, syncId),
    ...options,
  });

export const useSyncs = (
  merchantId: string,
  params?: ListSyncsParamsADto,
  enabled?: boolean
): UseQueryResult<SyncADto[]> =>
  useCohortQuery({
    queryKey: syncsKeys.listWithFilters(merchantId, params),
    queryFn: async () => {
      return listSyncs(merchantId, params);
    },
    enabled,
  });
