import type {SyncADto} from '@cohort/admin-schemas/sync';
import {useSync} from '@cohort/merchants/hooks/api/Syncs';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {createContext, Fragment} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

type CurrentSyncProviderProps = {
  children: React.ReactNode;
};

export type CurrentSyncContextValue = {
  sync: SyncADto;
};

export const CurrentSyncContext = createContext<CurrentSyncContextValue | null>(null);

export const CurrentSyncProvider: React.FC<CurrentSyncProviderProps> = ({children}) => {
  const merchant = useCurrentMerchant();
  const {syncId} = useParams();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const {data: sync, isFetched} = useSync(merchant.id, syncId!, {
    enabled: syncId !== undefined,
  });

  if (syncId === undefined) {
    navigate('/not-found', {replace: true});
  }

  if (isFetched && !sync) {
    navigate('/not-found', {replace: true});
  }

  if (sync === undefined) {
    return <Fragment />;
  }

  return <CurrentSyncContext.Provider value={{sync}}>{children}</CurrentSyncContext.Provider>;
};
