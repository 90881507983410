import {CsvUpload} from '@cohort/merchants/components/form/CsvUpload';
import type {WrappedField} from '@cohort/merchants/components/form/FieldWrapper';
import FieldWrapper from '@cohort/merchants/components/form/FieldWrapper';
import Textarea from '@cohort/merchants/components/form/textarea/Textarea';
import {notify} from '@cohort/merchants/hooks/toast';
import type {FormField} from '@cohort/merchants/lib/form/utils';
import {splitByCommaOrNewLine} from '@cohort/merchants/lib/form/utils';
import type {FieldValues} from 'react-hook-form';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type CsvOrTextInputProps<T extends FieldValues> = WrappedField &
  FormField<T> & {
    validator: (value: string) => boolean;
    placeholder: string;
  };

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function CsvOrTextInput<T extends FieldValues>({
  register,
  control,
  validator,
  placeholder,
  ...props
}: CsvOrTextInputProps<T>): JSX.Element {
  const {t} = useTranslation('components', {
    keyPrefix: 'form.csvOrTextInput',
  });
  const {field} = useController({
    control,
    name: props.name,
  });

  const parseContent = (lines: Array<string>): Array<string> => {
    const formatedLines = lines
      .map(line => line.trim())
      .filter(line => line !== '' && validator(line));
    const uniqueLines = Array.from(new Set(formatedLines));
    return uniqueLines;
  };

  const correctLines = parseContent(splitByCommaOrNewLine(field.value));

  return (
    <FieldWrapper label={props.label} name={props.name}>
      <div className="relative">
        <Textarea
          /* this padding avoid having text under the file input */
          className="pr-[220px]"
          placeholder={placeholder}
          name={props.name}
          register={register}
          control={control}
          rows={8}
        />
        <CsvUpload
          className="absolute right-0 top-0 m-3 w-[200px] bg-slate-50"
          onParse={(lines: string[]) => {
            const formatedLines = parseContent(lines);
            if (formatedLines.length === 0) {
              notify('error', t('errorFileFormat'));
            } else {
              field.onChange(formatedLines.join(','));
            }
          }}
          caption={t('captionCsvUpload')}
          hasHeader={(line: string) => !validator(line)}
        />
      </div>
      <p className="text-sm text-slate-500">
        {t('itemsCount', {
          count: correctLines.length,
        })}
      </p>
    </FieldWrapper>
  );
}
