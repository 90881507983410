import type {UserPropertyADto} from '@cohort/admin-schemas/userProperty';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {userPropertiesKeys} from '@cohort/merchants/hooks/api/UserProperties';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {notify} from '@cohort/merchants/hooks/toast';
import {deleteUserProperty} from '@cohort/merchants/lib/api/UserProperties';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import {useQueryClient} from '@tanstack/react-query';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

type DeleteUserPropertyModalProps = {
  onClose: () => void;
  userProperty: UserPropertyADto;
};

const DeleteUserPropertyModal: React.FC<DeleteUserPropertyModalProps> = ({
  onClose,
  userProperty,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.userProperties.deleteUserPropertyModal',
  });
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();

  const {mutate: deleteUserPropertyMutation} = useCohortMutation({
    mutationFn: async () => deleteUserProperty(merchant.id, userProperty.id),
    onSuccess: async () => {
      await queryClient.invalidateQueries(userPropertiesKeys.userProperties);
      onClose();
    },
    notifySuccessMessage: t('notificationSuccess'),
    onError: err => {
      if (isCohortError(err, 'user-property.used-in-rule')) {
        const cohortList = (err.context.cohorts as Array<{id: string; name: string}>)
          .map(cohort => cohort.name)
          .join(', ');

        return notify('error', t('notificationErrorTitle'), {
          description: (
            <p className="text-sm">
              <Trans
                i18nKey="settings.userProperties.deleteUserPropertyModal.notificationErrorDescription"
                ns="pages"
                components={{
                  list: <span className="font-medium"></span>,
                }}
                values={{
                  cohorts: cohortList,
                }}
              />
            </p>
          ),
          duration: 7000,
        });
      }
      throw err;
    },
  });

  return (
    <DeletionModal
      title={t('title')}
      subtitle={t('subtitle')}
      onClose={onClose}
      onDelete={deleteUserPropertyMutation}
      show
    />
  );
};

export default DeleteUserPropertyModal;
