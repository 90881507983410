import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import {IconBadge} from '@cohort/merchants/components/IconBadge';
import {useUserCohorts} from '@cohort/merchants/hooks/api/Users';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCurrentUser} from '@cohort/merchants/hooks/contexts/currentUser';
import {useBasePageFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import AddUserToCohortModal from '@cohort/merchants/pages/users/user/cohorts/AddUserToCohortModal';
import UserCohortsTable from '@cohort/merchants/pages/users/user/cohorts/UserCohortsTable';
import {ListBullets, Plus} from '@phosphor-icons/react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

const PAGE_SIZE = 20;

const UserCohortsPage = (): JSX.Element => {
  const user = useCurrentUser();
  const merchant = useCurrentMerchant();
  const [addUserToCohortModalOpen, setAddUserToCohortModalOpen] = useState(false);

  const {t} = useTranslation('pages', {keyPrefix: 'users.user.cohorts.page'});

  const filters = useBasePageFilters(/^(\/test)?\/users\/.*\/cohorts$/u, null);

  const {data, isLoading} = useUserCohorts(merchant.id, user.id, {
    page: filters.page,
    pageSize: PAGE_SIZE,
  });

  const [pagination, cohorts] = data ?? [];

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
    titleBadge: pagination && (
      <IconBadge
        key="digital-assets"
        icon={<ListBullets className="mr-1.5 h-4 w-4 text-slate-500" />}
        text={t('labelCohorts', {count: pagination.total})}
      />
    ),
    topRightElements: [
      <Button
        key="createCohort"
        variant="primary"
        onClick={() => setAddUserToCohortModalOpen(true)}
      >
        <Plus className="-ml-1 mr-2 h-5 w-5" />
        {t('buttonAddToCohort')}
      </Button>,
    ],
  };

  const paginationConfig = {
    pagination,
    onPageChange: filters.setPage,
  };

  return (
    <AdminPage header={headerConfig} pagination={paginationConfig}>
      <UserCohortsTable isLoading={isLoading} cohorts={cohorts ?? []} />
      {addUserToCohortModalOpen && (
        <AddUserToCohortModal onClose={() => setAddUserToCohortModalOpen(false)} />
      )}
    </AdminPage>
  );
};
export default UserCohortsPage;
