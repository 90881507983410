import type {PatchBadgeADto} from '@cohort/admin-schemas/badge';
import AdminPage from '@cohort/merchants/components/AdminPage';
import CompletionBreadcrumb from '@cohort/merchants/components/form/CompletionBreadcrumb';
import SubmitFooter from '@cohort/merchants/components/form/SubmitFooter';
import {badgesKeys} from '@cohort/merchants/hooks/api/Badges';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {userEventsKeys} from '@cohort/merchants/hooks/api/UserEvents';
import {useCurrentBadge} from '@cohort/merchants/hooks/contexts/currentBadge';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import useStepForm from '@cohort/merchants/hooks/form/stepForm';
import {useBadgePageStore} from '@cohort/merchants/hooks/stores/badgePage';
import {updateBadge} from '@cohort/merchants/lib/api/Badges';
import {uploadAsset} from '@cohort/merchants/lib/form/utils';
import {getBadgesRoute} from '@cohort/merchants/lib/Pages';
import BadgeAppearanceStep from '@cohort/merchants/pages/users/badges/badge/edit/BadgeAppearanceStep';
import BadgeCohortStep from '@cohort/merchants/pages/users/badges/badge/edit/BadgeCohortStep';
import BadgeVisibilityStep from '@cohort/merchants/pages/users/badges/badge/edit/BadgeVisibilityStep';
import type {BadgeFormStep} from '@cohort/merchants/pages/users/badges/badge/edit/utils';
import {
  BadgeFormSteps,
  badgeFormStepsValidators,
} from '@cohort/merchants/pages/users/badges/badge/edit/utils';
import {notifyError} from '@cohort/merchants/stores/ErrorModalStore';
import type {AssetKind} from '@cohort/shared/schema/common/assets';
import {useQueryClient} from '@tanstack/react-query';
import {useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {match} from 'ts-pattern';
import {shallow} from 'zustand/shallow';

const BadgeEditPage: React.FC = () => {
  const merchant = useCurrentMerchant();
  const badge = useCurrentBadge();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {t} = useTranslation('pages', {
    keyPrefix: 'users.badges.badge.edit.page',
  });

  const {setFooter, setMenu} = useBadgePageStore(
    state => ({
      setFooter: state.setFooter,
      setMenu: state.setMenu,
    }),
    shallow
  );
  const [state, dispatch] = useStepForm<BadgeFormStep>('appearance', () => {
    const steps = [];
    let currentStep: BadgeFormStep | undefined = undefined;

    for (const step of BadgeFormSteps) {
      const isCompleted = badgeFormStepsValidators[step].safeParse({
        ...badge,
        defaultLanguage: merchant.defaultLanguage,
      }).success;
      const isCurrent = !currentStep && !isCompleted;

      if (isCurrent) {
        currentStep = step;
      }

      // i18nOwl-ignore [appearance.title, appearance.subtitle, steps.appearance, steps.cohort, steps.visibility]
      steps.push({
        id: step,
        label: t(`steps.${step}`),
        visited: isCompleted || isCurrent,
        isCurrent,
        isCompleted,
        isDirty: false,
        countsForCompletion: true,
        onClick: () => dispatch({type: 'JUMP_TO', payload: step}),
      });
    }
    return {
      steps,
      step: currentStep || 'visibility',
    };
  });

  const onStepValidated = useCallback(
    async (step: BadgeFormStep) => {
      await queryClient.invalidateQueries(badgesKeys.getById(merchant.id, badge.id));
      dispatch({type: 'GO_TO', payload: step});
    },
    [merchant.id, queryClient, badge.id, dispatch]
  );

  const {isLoading: isUploading, mutateAsync: uploadFileMutation} = useCohortMutation({
    mutationFn: async ({file, assetKind}: {file: File; assetKind: AssetKind}) =>
      uploadAsset(file, assetKind, merchant.id),
    onError: err => notifyError(err, t('errorFileUploadFailed')),
  });

  const {isLoading: isUpdating, mutateAsync: updateBadgeMutation} = useCohortMutation({
    mutationFn: async (data: PatchBadgeADto) => updateBadge(merchant.id, badge.id, data),
    notifyErrorMessage: t('notificationErrorUpdate'),
    onSuccess: async () => {
      queryClient.invalidateQueries(badgesKeys.getById(merchant.id, badge.id));
      queryClient.invalidateQueries(badgesKeys.list(merchant.id));
      queryClient.invalidateQueries(userEventsKeys.userEvents);
    },
  });

  useEffect(() => {
    setMenu(<CompletionBreadcrumb steps={state.steps} />);
    return () => setMenu(null);
  }, [setMenu, state.steps]);

  useEffect(() => {
    const currentStep = state.step;
    const previousStep = BadgeFormSteps[BadgeFormSteps.indexOf(currentStep) - 1];

    setFooter(
      <SubmitFooter
        formName={`badge-${state.step}-step`}
        loading={isUpdating || isUploading}
        submitLabel={state.step === 'visibility' ? t('publishBtn') : t('continueBtn')}
        additionalAction={state.stepSaveBtn}
        withBackButton={
          previousStep && {
            label: t('backBtn'),
            onClick: () => dispatch({type: 'JUMP_TO', payload: previousStep}),
          }
        }
      />
    );
    return () => setFooter(null);
  }, [setFooter, t, isUpdating, state.step, state.stepSaveBtn, isUploading, dispatch]);

  const setStepDirty = useCallback(
    (dirty: boolean) => dispatch({type: 'SET_STEP_DIRTY', payload: dirty}),
    [dispatch]
  );
  const setStepSaveBtn = useCallback(
    (btn: JSX.Element) => dispatch({type: 'SET_STEP_SAVE_BTN', payload: btn}),
    [dispatch]
  );

  // i18nOwl-ignore [cohort.subtitle, cohort.title, visibility.subtitle, visibility.title]
  const stepContent = match(state.step)
    .with('appearance', () => (
      <BadgeAppearanceStep
        isProcessing={isUpdating || isUploading}
        setStepDirty={setStepDirty}
        setStepSaveBtn={setStepSaveBtn}
        onStepValidated={async () => {
          await onStepValidated('cohort');
        }}
        updateBadgeMutation={updateBadgeMutation}
        uploadMutation={uploadFileMutation}
      />
    ))
    .with('cohort', () => (
      <BadgeCohortStep
        isProcessing={isUpdating || isUploading}
        setStepDirty={setStepDirty}
        setStepSaveBtn={setStepSaveBtn}
        onStepValidated={() => onStepValidated('visibility')}
        updateBadgeMutation={updateBadgeMutation}
      />
    ))
    .with('visibility', () => (
      <BadgeVisibilityStep
        isProcessing={isUpdating || isUploading}
        setStepDirty={setStepDirty}
        setStepSaveBtn={setStepSaveBtn}
        onStepValidated={async () => {
          await queryClient.invalidateQueries(badgesKeys.getById(merchant.id, badge.id));
          navigate(getBadgesRoute().path);
        }}
        updateBadgeMutation={updateBadgeMutation}
      />
    ))
    .exhaustive();

  const headerConfig = {
    title: t(`${state.step}.title`),
    subtitle: t(`${state.step}.subtitle`),
  };

  return <AdminPage header={headerConfig}>{stepContent}</AdminPage>;
};

export default BadgeEditPage;
