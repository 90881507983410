import {IconBadge} from '@cohort/merchants/components/IconBadge';
import {BatteryEmpty, BatteryFull, BatteryMedium} from '@phosphor-icons/react';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

interface CampaignSupplyBadgeProps {
  currentSupply: number;
  maxSupply: number | null;
  size?: 'large' | 'small';
}

const CampaignSupplyBadge: React.FC<CampaignSupplyBadgeProps> = ({
  currentSupply,
  maxSupply,
  size = 'small',
}) => {
  const {t} = useTranslation('components', {keyPrefix: 'campaigns.campaignSupplyBadge'});

  return (
    <IconBadge
      size={size}
      text={
        <Fragment>
          {currentSupply}
          {maxSupply && `/${maxSupply}`}
          &nbsp;{t('labelUnits')}
        </Fragment>
      }
      icon={
        currentSupply === 0 ? (
          <BatteryEmpty className="mr-1 h-4 w-4 text-slate-500" />
        ) : currentSupply === maxSupply ? (
          <BatteryFull className="mr-1 h-4 w-4 text-slate-500" />
        ) : (
          <BatteryMedium className="mr-1 h-4 w-4 text-slate-500" />
        )
      }
    />
  );
};

export default CampaignSupplyBadge;
