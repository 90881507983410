import type {
  CampaignADto,
  RedeemCodeADto,
  RedeemCodeStatusADto,
} from '@cohort/admin-schemas/campaign';
import {Badge} from '@cohort/merchants/components/Badge';
import Button from '@cohort/merchants/components/buttons/Button';
import CopyToClipboard from '@cohort/merchants/components/buttons/CopyToClipboard';
import {ConfirmationModal} from '@cohort/merchants/components/modals/ConfirmationModal';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {campaignsKeys} from '@cohort/merchants/hooks/api/Campaigns';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {deleteRedeemCode} from '@cohort/merchants/lib/api/Campaigns';
import {
  trackDeleteRedeemCodeBtnClicked,
  trackDeleteRedeemCodeSuccess,
} from '@cohort/merchants/lib/Tracking';
import {redeemCodeToRedeemLink} from '@cohort/shared/utils/format';
import {Trash} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {createColumnHelper} from '@tanstack/react-table';
import React, {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

type CampaignInvitesTableProps = {
  redeemCodes: Array<RedeemCodeADto>;
  campaign: CampaignADto;
  isLoading: boolean;
};

const CampaignInvitesTable: React.FC<CampaignInvitesTableProps> = ({
  redeemCodes,
  campaign,
  isLoading,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const queryClient = useQueryClient();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.audience.campaignInvitesTable',
  });
  const [redeemCodeToDelete, setRedeemCodeToDelete] = useState<RedeemCodeADto>();
  const columnHelper = createColumnHelper<RedeemCodeADto>();
  const columns = [
    columnHelper.accessor('code', {
      header: t('tableHeaderInvite'),
      cell: data => (
        <div className="flex items-center gap-2 font-medium">
          {data.getValue()}
          <CopyToClipboard
            target={redeemCodeToRedeemLink(campaign.store?.shopUrl ?? null, data.getValue())}
          />
        </div>
      ),
      minSize: 450,
      enableSorting: false,
    }),
    columnHelper.accessor('status', {
      header: t('tableHeaderStatus'),
      cell: data => renderStatus(data.getValue()),
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => null,
      cell: ({row}) => {
        const redeemCode = row.original;

        return redeemCode.status === 'available' ? (
          <div className="text-right">
            <Button
              size="icon"
              variant="secondary"
              onClick={() => {
                trackDeleteRedeemCodeBtnClicked(redeemCode);
                setRedeemCodeToDelete(redeemCode);
              }}
            >
              <Trash size={20} className="text-red-500" />
            </Button>
          </div>
        ) : null;
      },
    }),
  ];
  const table = useCohortTable({
    data: redeemCodes,
    columns,
  });

  const {mutate: handleDeleteRedeemCode} = useCohortMutation({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    mutationFn: async () => deleteRedeemCode(merchantId, campaign.id, redeemCodeToDelete!.id),
    notifyErrorMessage: t('errorNotDeletable'),
    notifySuccessMessage: t('successDelete'),
    onSuccess: async () => {
      setRedeemCodeToDelete(undefined);
      await queryClient.invalidateQueries(campaignsKeys.redeemCodes(merchantId, campaign.id));
    },
  });

  function renderStatus(status: RedeemCodeStatusADto): JSX.Element {
    return match(status)
      .with('available', () => (
        <Badge
          textColor="text-green-800"
          backgroundColor="bg-green-100"
          dotColor="text-green-400"
          text={t('statusAvailable')}
        />
      ))
      .with('consumed', () => (
        <Badge
          textColor="text-primary"
          backgroundColor="bg-primary/10"
          dotColor="text-primary"
          text={t('statusConsumed')}
        />
      ))
      .exhaustive();
  }

  return (
    <Fragment>
      <DataTable
        table={table}
        columnsLength={columns.length}
        isLoading={isLoading}
        emptyStatePlaceholder={t('emptyPlaceholder')}
      />
      {redeemCodeToDelete !== undefined && (
        <ConfirmationModal
          title={t('modalConfirmTitle')}
          content={<div>{t('modalConfirmContent')}</div>}
          submitButtonText={t('modalConfirmButton')}
          show={true}
          onClose={() => {
            trackDeleteRedeemCodeSuccess(redeemCodeToDelete);
            setRedeemCodeToDelete(undefined);
          }}
          onSubmit={handleDeleteRedeemCode}
        />
      )}
    </Fragment>
  );
};

export default CampaignInvitesTable;
