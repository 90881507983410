import type {WrappedField} from '@cohort/merchants/components/form/FieldWrapper';
import FieldWrapper from '@cohort/merchants/components/form/FieldWrapper';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {forwardRef} from 'react';

type BaseInputProps = Omit<JSX.IntrinsicElements['input'], 'name' | 'ref'> &
  WrappedField & {
    error?: string;
    name: string;
  };

const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(
  (
    {insetPrefix, label, description, labelPosition, name, optional, error, className, ...props},
    ref
  ) => (
    <FieldWrapper
      insetPrefix={insetPrefix}
      label={label}
      name={name}
      description={description}
      labelPosition={labelPosition}
      optional={optional}
      error={error}
    >
      <input
        className={cn(
          'rounded-md border border-border p-2 text-sm shadow-sm focus:border-primary focus:ring-primary',
          props.disabled && 'cursor-not-allowed bg-slate-100 text-slate-400',
          props.type !== 'checkbox' ? 'w-full' : 'text-primary',
          props.type === 'radio' && 'text-primary',
          className
        )}
        style={{
          textIndent: insetPrefix ? `${insetPrefix.size}px` : undefined,
        }}
        data-testid={name}
        name={name}
        {...props}
        ref={ref}
      />
    </FieldWrapper>
  )
);

export default BaseInput;
