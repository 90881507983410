import {EventIdSchema} from '@cohort/shared/apps';
import {DateSchema} from '@cohort/shared/schema/common';
import {DataTypeSchema} from '@cohort/shared/schema/common/rules/dataTypes';
import {
  CohortMembershipCriterionOperatorSchema,
  UserEventCriterionFilterOperatorSchema,
  UserEventCriterionOperatorSchema,
  UserPropertyCriterionOperatorSchema,
} from '@cohort/shared/schema/common/rules/operators';
import {match} from 'ts-pattern';
import {z} from 'zod';

const CriterionInputValueSchema = z
  .string()
  .or(z.array(z.string()).min(1, {message: 'errorRequired'}))
  .or(DateSchema)
  .or(z.number())
  .or(z.boolean())
  .or(z.null());

export const UserPropertyCriterionSchema = z.object({
  id: z.string().uuid().nullable(),
  userPropertyId: z.string().uuid().optional(),
  operator: UserPropertyCriterionOperatorSchema.optional(),
  value: CriterionInputValueSchema.optional(),
  valueType: DataTypeSchema.optional(),
  type: z.enum(['user-property']),
});
export const UserPropertyCriterionSchemaRequired = UserPropertyCriterionSchema.required();
type UserPropertyCriterionType = z.infer<typeof UserPropertyCriterionSchema>;

export const UserEventCriterionFilterSchema = z.object({
  id: z.string().uuid().nullable(),
  propertyName: z.string().optional(),
  operator: UserEventCriterionFilterOperatorSchema.optional(),
  value: CriterionInputValueSchema.optional(),
  valueType: DataTypeSchema.optional(),
});
export const UserEventCriterionFilterSchemaRequired = UserEventCriterionFilterSchema.required();
type UserEventCriterionFilterType = z.infer<typeof UserEventCriterionFilterSchema>;

export const UserEventCriterionSchema = z.object({
  id: z.string().uuid().nullable(),
  userEventId: EventIdSchema.optional(),
  operator: UserEventCriterionOperatorSchema.optional(),
  count: z.number().optional(),
  filters: z.array(UserEventCriterionFilterSchema).optional(),
  type: z.enum(['user-event']),
});
export const UserEventCriterionSchemaRequired = UserEventCriterionSchema.extend({
  filters: z.array(UserEventCriterionFilterSchemaRequired),
}).required();

export const CohortMembershipCriterionSchema = z.object({
  id: z.string().uuid().nullable(),
  cohortId: z.string().uuid().optional(),
  operator: CohortMembershipCriterionOperatorSchema.optional(),
  type: z.enum(['cohort-membership']),
});
export const CohortMembershipCriterionSchemaRequired = CohortMembershipCriterionSchema.required();

export const NullCriterionSchema = z.object({
  id: z.string().uuid().nullable(),
  type: z.null(),
});

export const CriteronSchema = z.discriminatedUnion('type', [
  UserPropertyCriterionSchema,
  UserEventCriterionSchema,
  CohortMembershipCriterionSchema,
  NullCriterionSchema,
]);
export type CriteronType = z.infer<typeof CriteronSchema>;

// Zod has a deepPartial method but it doesn't provide a deepRequired method
const CriterionSchemaRequired = z
  .discriminatedUnion('type', [
    UserPropertyCriterionSchemaRequired,
    UserEventCriterionSchemaRequired,
    CohortMembershipCriterionSchemaRequired,
  ])
  .transform((criterion, ctx) => {
    const validateCriterion = (
      input: UserPropertyCriterionType | UserEventCriterionFilterType,
      errorPath: Array<string | number>
    ): boolean => {
      if (input.valueType === 'null') {
        input.value = null;
      }

      if (input.valueType !== 'null' && (input.value === null || input.value === '')) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'errorRequired',
          path: errorPath,
        });

        return false;
      }
      return true;
    };

    if (criterion.type === 'user-property') {
      const validated = validateCriterion(criterion, ['value']);

      if (!validated) {
        return z.NEVER;
      }
    }

    if (criterion.type === 'user-event') {
      if (
        criterion.filters.some(
          (filter, idx) => !validateCriterion(filter, ['filters', idx, 'value'])
        )
      ) {
        return z.NEVER;
      }
    }
    return criterion;
  });

export const RuleGroupSchema = z.object({
  id: z.string().uuid().nullable(),
  criteria: z.array(CriteronSchema),
});
export type RuleGroupType = z.infer<typeof RuleGroupSchema>;

const RuleGroupSchemaRequired = z.object({
  id: z.string().uuid().nullable(),
  criteria: z.array(CriterionSchemaRequired),
});

export const DynamicCohortRuleSchema = z.object({
  id: z.string().uuid().nullable(),
  groups: z.array(RuleGroupSchema),
});
export type DynamicCohortRuleType = z.infer<typeof DynamicCohortRuleSchema>;

export const DynamicCohortRuleSchemaRequired = z.object({
  id: z.string().uuid().nullable(),
  groups: z.array(RuleGroupSchemaRequired),
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getDefaultCriteriaProps(type: CriteronType['type']) {
  return match(type)
    .with('user-property', () => ({
      operator: null,
      userPropertyId: null,
      value: null,
      valueType: null,
    }))
    .with('user-event', () => ({
      userEventId: null,
      operator: null,
      count: null,
      filters: [],
    }))
    .with('cohort-membership', () => ({
      cohortId: null,
      operator: null,
    }))
    .otherwise(() => ({type: null}));
}
