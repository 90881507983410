import {DateSchema, LanguageSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const MembershipPassTSchema = z.object({
  memberHandle: z.string(),
  memberId: z.number(),
  subscriptionDate: DateSchema,
  imageUrl: z.string().nullable(),
});
export type MembershipPassTDto = z.infer<typeof MembershipPassTSchema>;

export const UserTSchema = z.object({
  id: z.string(),
  email: z.string().email(),
  membership: MembershipPassTSchema,
  walletAddress: z.string(),
});
export type UserTDto = z.infer<typeof UserTSchema>;

export const DefaultContextSchema = z.object({
  user: UserTSchema.nullable(),
  lang: LanguageSchema,
  isMobile: z.boolean(),
});
export type DefaultContextDto = z.infer<typeof DefaultContextSchema>;

export const MembershipPassContextTSchema = DefaultContextSchema.extend({
  user: UserTSchema,
});
export type MembershipPassContextTDto = z.infer<typeof MembershipPassContextTSchema>;
