import type {AssetADto} from '@cohort/admin-schemas/asset';
import type {PatchCampaignADto} from '@cohort/admin-schemas/campaign';
import {
  isDefaultLanguageTranslationConsistent,
  isDefaultTranslationDefined,
  removeUndefinedLanguages,
} from '@cohort/merchants/lib/form/localization';
import {fileValidatorOptional, hexColorValidator} from '@cohort/merchants/lib/form/validators';
import {BaseCampaignSettingsSchema} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/campaign';
import type {CampaignSettingsStepValues} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import {uploadCampaignAssets} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import {LocalizedRichTextSchema, LocalizedStringSchema} from '@cohort/shared/schema/common';
import type {AssetKind} from '@cohort/shared/schema/common/assets';
import {CampaignPricingSchema} from '@cohort/shared/schema/common/campaign';
import type {Price} from '@cohort/shared/schema/common/currency';
import {CurrencySchema, CurrencySpecs, PricesSchema} from '@cohort/shared/schema/common/currency';
import {isVideoFile} from '@cohort/shared/utils/mimeTypes';
import {isFile} from '@cohort/shared-frontend/utils/isFile';
import type {UseMutateAsyncFunction} from '@tanstack/react-query';
import {isEmpty} from 'remeda';
import {z} from 'zod';

// We need a dummy price schema for the preprocessing as they wouln't be validated.
const PriceSchema = z.object({
  amount: z.number(),
  currency: CurrencySchema,
});

function formPricesToDto(arg: unknown): Price[] {
  const prices = z.array(PriceSchema).parse(arg);
  const processedPrices = [];
  for (const price of prices) {
    const parsedAmount = CurrencySpecs.fromDecimal(price.currency, price.amount);
    processedPrices.push({
      currency: price.currency,
      amount: parsedAmount,
    });
  }
  return processedPrices;
}

export const BaseCampaignStoreSettingsStepSchema = BaseCampaignSettingsSchema.merge(
  z.object({
    store: z.object({
      title: LocalizedStringSchema,
      description: LocalizedRichTextSchema,
      // Computed field, need to be optional for automatic step validation
      storeThemeColorType: z.enum(['experience-space', 'custom']).optional(),
      backgroundColorCode: z
        .string()
        .min(1, {message: 'errorRequired'})
        .refine(hexColorValidator, 'errorHexColor'),
      accentColorCode: z
        .string()
        .min(1, {message: 'errorRequired'})
        .refine(hexColorValidator, 'errorHexColor'),
      pricing: CampaignPricingSchema,
      defaultCurrency: CurrencySchema.nullable(),
      prices: z.preprocess(formPricesToDto, PricesSchema),
      slug: z
        .string()
        .min(3)
        .refine(value => /^[0-9a-z-]+$/u.test(value))
        .nullable(),
      imageFileKey: z.any().refine(fileValidatorOptional),
      animationFileKey: z.any().refine(fileValidatorOptional),
    }),
  })
);

export const CampaignStoreSettingsStepSchema = BaseCampaignStoreSettingsStepSchema.superRefine(
  ({store, defaultLanguage}, ctx) => {
    if (defaultLanguage === undefined) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorRequired',
        path: ['defaultLanguage'],
      });
    }

    if (!isDefaultTranslationDefined(store.title, defaultLanguage)) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorTooShort3',
        path: ['store.title'],
      });
    }

    if (!isDefaultTranslationDefined(store.description, defaultLanguage)) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorTooShort3',
        path: ['store.description'],
      });
    }
    if (store.storeThemeColorType === 'custom') {
      if (isEmpty(store.backgroundColorCode)) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'errorRequired',
          path: ['store.backgroundColorCode'],
        });
      }

      if (isEmpty(store.accentColorCode)) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'errorRequired',
          path: ['store.accentColorCode'],
        });
      }
    }
  }
).transform(data => {
  data.store.description = removeUndefinedLanguages(data.store.description, data.definedLanguages);
  return data;
});

export const DraftCampaignStoreSettingsStepSchema = BaseCampaignStoreSettingsStepSchema.superRefine(
  ({store, defaultLanguage, definedLanguages}, ctx) => {
    if (!defaultLanguage) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorRequired',
        path: ['defaultLanguage'],
      });
      return false;
    }

    if (!definedLanguages) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorRequired',
        path: ['definedLanguages'],
      });
      return false;
    }

    // If the data in default language is not defined but it is defined in another language, we need to throw an error.
    if (definedLanguages.length < 2) {
      return true;
    } else {
      if (!isDefaultLanguageTranslationConsistent(store.title, defaultLanguage)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'errorTooShort3',
          path: ['store.title'],
        });
        return false;
      }

      if (!isDefaultLanguageTranslationConsistent(store.description, defaultLanguage)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'errorTooShort3',
          path: ['store.description'],
        });
        return false;
      }
    }

    return true;
  }
);

export const uploadCampaignStoreAssets = async (
  formData: Partial<CampaignSettingsStepValues>,
  uploadMutation: UseMutateAsyncFunction<
    AssetADto | null,
    unknown,
    {file: File; assetKind: AssetKind},
    unknown
  >
): Promise<PatchCampaignADto> => {
  // upload base campaign assets
  const updatedData = await uploadCampaignAssets(formData, uploadMutation);

  if (!updatedData.store || !formData.store) {
    throw new Error('Store data is missing');
  }

  // Upload Store assets.
  if (formData.store.imageFileKey && isFile(formData.store.imageFileKey)) {
    const asset = await uploadMutation({
      file: formData.store.imageFileKey,
      assetKind: 'storeVisual',
    });

    if (asset) {
      updatedData.store.animationFileKey = isVideoFile(asset.mimeType) ? asset.fileKey : null;
      updatedData.store.imageFileKey = isVideoFile(asset.mimeType) ? null : asset.fileKey;
    }
  } else if (!updatedData.store.imageFileKey || updatedData.store.imageFileKey.length === 0) {
    updatedData.store.imageFileKey = null;
    updatedData.store.animationFileKey = null;
  }

  return updatedData;
};
