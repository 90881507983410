// i18nOwl-ignore [boolean, date, number, string, resource_list, string_list]
import type {UserPropertyADto} from '@cohort/admin-schemas/userProperty';
import DataTypeIcon from '@cohort/merchants/components/DataTypeIcon';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
  userProperty: UserPropertyADto;
}
export const UserPropertyDataTypeBadge: React.FC<Props> = ({userProperty}): JSX.Element => {
  const {t} = useTranslation('components', {
    keyPrefix: 'userPropertyDataTypeBadge',
  });

  return (
    <div className="flex flex-row space-x-1.5">
      <DataTypeIcon dataType={userProperty.dataType} />
      <p className="text-sm font-normal text-slate-700">{t(userProperty.dataType)}</p>
    </div>
  );
};
