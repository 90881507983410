/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getQrReaders} from '@cohort/merchants/lib/api/QrReaders';
import type {PaginationParamsDto} from '@cohort/shared/schema/common/pagination';

export const qrReadersKeys = {
  qrReaders: ['qrReaders'] as const,
  list: (merchantId: string) => [...qrReadersKeys.qrReaders, merchantId] as const,
  listWithPagination: (merchantId: string, pagination: PaginationParamsDto) =>
    [...qrReadersKeys.list(merchantId), pagination] as const,
};

export const useQrReadersWithPagination = (merchantId: string, pagination: PaginationParamsDto) =>
  useCohortQuery({
    queryKey: qrReadersKeys.listWithPagination(merchantId, pagination),
    queryFn: async () => getQrReaders(merchantId, pagination),
  });
