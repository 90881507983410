import type {
  CreateQrReadersADto,
  CreateQrReadersResponseADto,
  QrReaderADto,
} from '@cohort/admin-schemas/qrcode';
import {CreateQrReadersResponseASchema, QrReaderASchema} from '@cohort/admin-schemas/qrcode';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import type {ExportDto} from '@cohort/shared/schema/common/export';
import {ExportSchema} from '@cohort/shared/schema/common/export';
import type {PaginationDto, PaginationParamsDto} from '@cohort/shared/schema/common/pagination';
import {paginatedParser} from '@cohort/shared/utils/parser';

export async function getQrReaders(
  merchantId: string,
  params: PaginationParamsDto
): Promise<[PaginationDto, QrReaderADto[]]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/qr-readers`, {
    expect: HttpCodes.SUCCESS,
    parser: paginatedParser(QrReaderASchema),
    params,
  });
}

export async function createQrReaders(
  merchantId: string,
  data: CreateQrReadersADto
): Promise<CreateQrReadersResponseADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/qr-readers`, {
    expect: HttpCodes.CREATED,
    parser: CreateQrReadersResponseASchema.parse,
    body: data,
  });
}

export async function deleteQrReader(merchantId: string, qrReaderId: string): Promise<{}> {
  return apiCall('DELETE', `/v1/merchants/${merchantId}/qr-readers/${qrReaderId}`, {
    expect: HttpCodes.SUCCESS,
    parser: () => ({}),
  });
}

export async function exportQrReaders(merchantId: string): Promise<ExportDto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/qr-readers/export`, {
    expect: HttpCodes.CREATED,
    parser: ExportSchema.parse,
  });
}
