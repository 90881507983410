import AsidePageMenu from '@cohort/merchants/components/AsidePageMenu';
import {useCurrentApp} from '@cohort/merchants/hooks/contexts/currentApp';
import {useCurrentSync} from '@cohort/merchants/hooks/contexts/currentSync';
import {getAppSyncUsersImportRoute} from '@cohort/merchants/lib/Pages';
import {GearSix} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

const SyncEditPageMenu: React.FC = () => {
  const {appId} = useCurrentApp();
  const {sync} = useCurrentSync();
  const {t} = useTranslation('pages', {
    keyPrefix: 'apps.app.syncs.edit.syncEditPageMenu',
  });

  return (
    <AsidePageMenu
      entries={[
        {
          name: t('usersImport'),
          path: getAppSyncUsersImportRoute(appId, sync.id).path,
          icon: <GearSix size={20} />,
        },
      ]}
    />
  );
};

export default SyncEditPageMenu;
