import AdminPage from '@cohort/merchants/components/AdminPage';
import CopyToClipboard from '@cohort/merchants/components/buttons/CopyToClipboard';
import KPICard from '@cohort/merchants/components/KPICard';
import SearchInput from '@cohort/merchants/components/SearchInput';
import {useAirdropActivities} from '@cohort/merchants/hooks/api/AirdropActivities';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useAirdropParticipationsListFilters} from '@cohort/merchants/hooks/filters/airdropParticipationsListFilters';
import AirdropActivitiesTable from '@cohort/merchants/pages/campaigns/campaign/activity/airdrop/AirdropActivitiesTable';
import {CheckCircle} from '@phosphor-icons/react';
import {Trans, useTranslation} from 'react-i18next';

const PAGE_SIZE = 20;

const AirdropActivityPage: React.FC = () => {
  const campaign = useCurrentCampaign();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.activity.airdrop.airdropActivityPage',
  });
  const merchant = useCurrentMerchant();
  const filters = useAirdropParticipationsListFilters();

  const {data: airdropActivitiesData, isLoading: isAirdropActivitiesLoading} = useAirdropActivities(
    merchant.id,
    campaign.id,
    {
      page: filters.page,
      pageSize: PAGE_SIZE,
      searchEmail: filters.searchEmail,
    }
  );

  const [pagination, airdropActivities] = airdropActivitiesData ?? [];

  const headerConfig = {
    title: t('title'),
    subtitle: campaign.journey?.spaceUrl ? (
      <p className="line-clamp-4">
        <Trans
          i18nKey="campaigns.campaign.activity.journey.journeyActivityPage.accessSubtitle"
          ns="pages"
          values={{
            storeUrl: campaign.journey.spaceUrl,
            copyButton: <CopyToClipboard target={campaign.journey.spaceUrl} />,
          }}
          components={{
            underline: (
              <a
                href={campaign.journey.spaceUrl}
                className="font-medium underline"
                target="_blank"
              />
            ),
            copyButton: <CopyToClipboard target={campaign.journey.spaceUrl} />,
          }}
        />
      </p>
    ) : undefined,
    topRightElements: (
      <KPICard
        icon={<CheckCircle className="h-4 w-4 text-slate-500" />}
        text={t('labelCompleted')}
        value={pagination?.total ?? 0}
      />
    ),
  };

  const paginationConfig = {
    pagination,
    onPageChange: filters.setPage,
  };

  return (
    <AdminPage header={headerConfig} pagination={paginationConfig}>
      <SearchInput
        value={filters.searchEmail}
        onChange={value => filters.setSearchEmail(value)}
        placeholder={t('placeholderSearch')}
        delay={300}
        className="!h-9"
      />
      <AirdropActivitiesTable
        airdropActivities={airdropActivities ?? []}
        isLoading={isAirdropActivitiesLoading}
      />
    </AdminPage>
  );
};

export default AirdropActivityPage;
