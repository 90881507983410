import type {DigitalAssetCollectionADto} from '@cohort/admin-schemas/digitalAssetCollection';
import type {PerkADto} from '@cohort/admin-schemas/perk';
import PreviewLayout from '@cohort/merchants/components/campaigns/preview/PreviewLayout';
import PreviewPrimaryButton from '@cohort/merchants/components/campaigns/preview/PreviewPrimaryButton';
import ContentPanel from '@cohort/merchants/components/campaigns/preview/store/ContentPanel';
import LeftPanel from '@cohort/merchants/components/campaigns/preview/store/LeftPanel';
import usePreviewStyle from '@cohort/merchants/hooks/previewStyle';
import type {Language} from '@cohort/shared/schema/common';
import type {CampaignPricing, LocalizedFaqs} from '@cohort/shared/schema/common/campaign';
import type {Currency, Price} from '@cohort/shared/schema/common/currency';
import {ShoppingBag} from '@phosphor-icons/react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useInView} from 'react-intersection-observer';

export type CampaignStorePreviewValues = {
  backgroundColorCode: string;
  accentColorCode: string;
  campaignTitle?: string;
  imageFileKey: string | File | null;
  animationFileKey: string | File | null;
  perks?: PerkADto[];
  collections?: DigitalAssetCollectionADto[];
  description?: string;
  faqs: LocalizedFaqs;
  supportEmail?: string;
  defaultCurrency: Currency | null;
  pricing: CampaignPricing;
  prices: Price[];
  selectedLanguage: Language;
};

type CampaignStorePreviewProps = {
  values: CampaignStorePreviewValues;
  className?: string;
  isMobile?: boolean;
  inPreviewModal?: boolean;
};

export const CampaignStorePreview: React.FC<CampaignStorePreviewProps> = ({
  values,
  className,
  isMobile = false,
  inPreviewModal,
}): React.ReactElement => {
  const {
    backgroundColorCode,
    accentColorCode,
    campaignTitle,
    animationFileKey,
    imageFileKey,
    perks,
    collections,
    description,
    faqs,
    supportEmail,
    defaultCurrency,
    prices,
    pricing,
    selectedLanguage,
  } = values;
  const {ref: buyButtonRef, inView: buyButtonInView} = useInView();
  const {t} = useTranslation('components', {
    keyPrefix: 'previews.CampaignStorePreview',
    lng: selectedLanguage,
  });

  usePreviewStyle(backgroundColorCode, accentColorCode);

  const buyButton = (
    <div className="flex flex-row">
      <ShoppingBag className="mr-2 h-5 w-5" aria-hidden="true" />
      {pricing === 'free' && t('buttonGet')}
      {pricing === 'paid' && t('buttonBuy')}
    </div>
  );

  const rightContent = (
    <ContentPanel
      isMobile={isMobile}
      backgroundColor={backgroundColorCode}
      accentColor={accentColorCode}
      perks={perks}
      collections={collections}
      description={description}
      faqs={faqs[selectedLanguage] ?? []}
      supportEmail={supportEmail}
      showGetButton={!buyButtonInView}
      selectedLanguage={selectedLanguage}
    >
      <PreviewPrimaryButton accentColorCode={accentColorCode} text={buyButton} />
    </ContentPanel>
  );

  const leftPanel = (
    <div className="h-full w-full">
      <LeftPanel
        isMobile={isMobile}
        animationFileKey={animationFileKey}
        imageFileKey={imageFileKey}
        campaignTitle={campaignTitle}
        defaultCurrency={defaultCurrency}
        pricing={pricing}
        prices={prices}
        selectedLanguage={selectedLanguage}
      >
        <div ref={buyButtonRef}>
          <PreviewPrimaryButton accentColorCode={accentColorCode} text={buyButton} />
        </div>
      </LeftPanel>

      {isMobile && rightContent}
    </div>
  );

  return (
    <PreviewLayout
      isMobile={isMobile}
      className={className}
      leftPanelContent={leftPanel}
      rightPanelContent={rightContent}
      inPreviewModal={inPreviewModal}
    />
  );
};
