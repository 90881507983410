import {Tabs, TabsList, TabsTrigger} from '@cohort/shared-frontend/components/Tabs';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {CirclesFour, List} from '@phosphor-icons/react';

type DisplayType = 'card' | 'list';

interface CardListDisplayTogglerProps {
  display: DisplayType;
  setDisplay: (display: DisplayType) => void;
}

const CardListDisplayToggler: React.FC<CardListDisplayTogglerProps> = ({display, setDisplay}) => {
  const options = [
    {
      value: 'card',
      label: <CirclesFour size={20} />,
    },
    {
      value: 'list',
      label: <List size={20} />,
    },
  ];
  return (
    <Tabs defaultValue={display} onValueChange={val => setDisplay(val as DisplayType)}>
      <TabsList className="grid w-full grid-cols-2">
        {options.map(option => (
          <TabsTrigger key={option.value} value={option.value}>
            <span className={cn(display === option.value && 'text-primary')}>{option.label}</span>
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
};

export default CardListDisplayToggler;
