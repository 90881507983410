import type {PatchDigitalAssetCollectionRequestADto} from '@cohort/admin-schemas/digitalAssetCollection';
import BreadCrumb from '@cohort/merchants/components/BreadCrumb';
import Button from '@cohort/merchants/components/buttons/Button';
import DigitalAssetCollectionAirdropModal from '@cohort/merchants/components/digital-assets/DigitalAssetCollectionAirdropModal';
import {DigitalAssetCollectionStatusBadge} from '@cohort/merchants/components/digital-assets/DigitalAssetCollectionStatusBadge';
import {DigitalAssetVisual} from '@cohort/merchants/components/DigitalAssetVisual';
import {digitalAssetCollectionsKeys} from '@cohort/merchants/hooks/api/DigitalAssetCollections';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentDigitalAssetCollection} from '@cohort/merchants/hooks/contexts/currentDigitalAssetCollection';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {patchDigitalAssetCollection} from '@cohort/merchants/lib/api/DigitalAssetCollections';
import {trackBreadcrumbBackButtonClicked} from '@cohort/merchants/lib/Tracking';
import DigitalAssetCollectionDropdown from '@cohort/merchants/pages/digital-asset-collections/overview/DigitalAssetCollectionDropdown';
import {PaperPlaneRight} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

const PatchDigitalAssetCollectionFormSchema = z.object({
  internalName: z.string().min(3, {message: 'errorTooShort3'}),
});

const DigitalAssetCollectionPageBreadcrumb = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {t} = useTranslation('pages', {
    keyPrefix:
      'digitalAssetCollections.digitalAssetCollection.digitalAssetCollectionPageBreadcrumb',
  });
  const queryClient = useQueryClient();
  const digitalAssetCollection = useCurrentDigitalAssetCollection();
  const [airdropModalOpen, setAirdropModalOpen] = useState(false);

  const {mutate: updateDigitalAssetCollection} = useCohortMutation({
    mutationFn: async (digitalAssetCollectionData: PatchDigitalAssetCollectionRequestADto) =>
      patchDigitalAssetCollection(
        merchantId,
        digitalAssetCollection.id,
        digitalAssetCollectionData
      ),
    notifyErrorMessage: t('notificationErrorUpdate'),
    notifySuccessMessage: t('notificationSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(
        digitalAssetCollectionsKeys.getById(merchantId, digitalAssetCollection.id)
      );
      queryClient.invalidateQueries(digitalAssetCollectionsKeys.list(merchantId));
    },
  });

  return (
    <Fragment>
      {airdropModalOpen && (
        <DigitalAssetCollectionAirdropModal onClose={() => setAirdropModalOpen(false)} />
      )}
      <BreadCrumb
        title={digitalAssetCollection.internalName}
        handleTitleChange={(name: string) => updateDigitalAssetCollection({internalName: name})}
        validator={(name: string) =>
          PatchDigitalAssetCollectionFormSchema.safeParse({internalName: name}).success
        }
        image={
          <DigitalAssetVisual
            digitalAssetImageFileKey={digitalAssetCollection.imageFileKey}
            animationFileKey={digitalAssetCollection.animationFileKey}
            className="rounded-md border"
            size={48}
          />
        }
        badges={[
          <DigitalAssetCollectionStatusBadge
            key="status-badge"
            campaignsCount={digitalAssetCollection.campaignsCount}
            size="small"
          />,
        ]}
        onBackClick={() => {
          trackBreadcrumbBackButtonClicked(
            'digitalAssetCollection',
            undefined,
            undefined,
            digitalAssetCollection
          );
        }}
      >
        <div className="ml-4 flex items-center gap-4">
          <Button variant="secondary" onClick={() => setAirdropModalOpen(true)}>
            <PaperPlaneRight size={16} className="mr-1 -rotate-90 text-primary" />
            {t('labelAirdrop')}
          </Button>
          <DigitalAssetCollectionDropdown digitalAssetCollection={digitalAssetCollection} />
        </div>
      </BreadCrumb>
    </Fragment>
  );
};

export default DigitalAssetCollectionPageBreadcrumb;
