import type {
  JourneyStepInputADto,
  JourneyStepTriggerADto,
  UpdateJourneyStepsADto,
} from '@cohort/admin-schemas/journeySteps';
import {Badge} from '@cohort/merchants/components/Badge';
import Button from '@cohort/merchants/components/buttons/Button';
import CampaignJourneyStepAppBadges from '@cohort/merchants/components/campaigns/journeys/CampaignJourneyStepAppBadges';
import DraggableList from '@cohort/merchants/components/form/DraggableList';
import DraggableListItem from '@cohort/merchants/components/form/DraggableListItem';
import Loader from '@cohort/merchants/components/Loader';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import {ValueNotTranslatedLabel} from '@cohort/merchants/components/ValueNotTranslatedLabel';
import {campaignsKeys, useJourneySteps} from '@cohort/merchants/hooks/api/Campaigns';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {notify} from '@cohort/merchants/hooks/toast';
import {updateJourneySteps} from '@cohort/merchants/lib/api/Campaigns';
import type {CampaignSettingsStepValues} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import CreateEditStepModal from '@cohort/merchants/pages/campaigns/campaign/edit/settings/journey/CreateEditStepModal';
import type {Language} from '@cohort/shared/schema/common';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import type {JourneyStepOrchestration} from '@cohort/shared/schema/common/journeyStep';
import {formatDate} from '@cohort/shared/utils/format';
import {Plus, Stack} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import type {ReactNode} from 'react';
import React, {Fragment, useState} from 'react';
import type {Control, UseFormRegister} from 'react-hook-form';
import {useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {mapToObj} from 'remeda';

type JourneyStepsSectionProps = {
  control: Control<CampaignSettingsStepValues>;
  register: UseFormRegister<CampaignSettingsStepValues>;
};

type JourneyStepsEmptyStateProps = {
  isFetched: boolean;
  onAddStepClicked: () => void;
};

type StepModalState = {
  open: boolean;
  step?: JourneyStepInputADto;
};

type OrchestrationBadgeProps = {
  text: string;
};

const OrchestrationBadge: React.FC<OrchestrationBadgeProps> = ({text}) => (
  <Badge
    text={text}
    textColor="text-slate-800"
    backgroundColor="bg-white"
    borderColor="border-slate-500"
    size="small"
  />
);

const JourneyStepsEmptyState: React.FC<JourneyStepsEmptyStateProps> = ({
  isFetched,
  onAddStepClicked,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.settings.journey.journeyStepsSection',
  });

  return (
    <section className="mt-4 flex flex-col items-center justify-center rounded-md bg-slate-50 py-16">
      {!isFetched ? (
        <Fragment>
          <Loader size={30} color="gray" />
          <p className="mt-2 font-medium text-gray-900">{t('fetchingLabel')}</p>
        </Fragment>
      ) : (
        <Fragment>
          <Stack className="h-10 w-10 text-slate-400" />
          <p className="mt-2 font-medium text-gray-900">{t('emptyStateLabel')}</p>
          <div className="mt-4">
            <Button onClick={() => onAddStepClicked()}>
              <Plus className="-ml-1 mr-2 h-5 w-5" />
              {t('addStepBtn')}
            </Button>
          </div>
        </Fragment>
      )}
    </section>
  );
};

const JourneyStepListItem: React.FC<{
  icon: string;
  title?: string | null;
  defaultTitle?: string | null;
  triggers: Array<JourneyStepTriggerADto>;
  selectedLanguage: Language;
  badges?: React.ReactNode;
  onEdit: () => void;
  onRemove: () => void;
}> = ({icon, title, defaultTitle, triggers, onEdit, onRemove, selectedLanguage, badges}) => (
  <DraggableListItem onEdit={onEdit} onRemove={onRemove} className="bg-slate-50">
    <div className="flex items-center justify-between">
      <div className="flex flex-col">
        <div className="flex flex-row items-center gap-4">
          <div>{icon}</div>
          {title ? (
            <p className="truncate text-sm font-medium text-gray-900">{title}</p>
          ) : (
            <ValueNotTranslatedLabel
              defaultValue={defaultTitle ?? null}
              language={selectedLanguage}
            />
          )}
        </div>
        {badges && <div className="mt-0.5 flex flex-row flex-wrap gap-2">{badges}</div>}
      </div>
      <CampaignJourneyStepAppBadges triggers={triggers} />
    </div>
  </DraggableListItem>
);

const JourneyStepsSection: React.FC<JourneyStepsSectionProps> = ({control}) => {
  const queryClient = useQueryClient();
  const merchant = useCurrentMerchant();
  const campaign = useCurrentCampaign();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.settings.journey.journeyStepsSection',
  });
  const {data: journeySteps, isFetched} = useJourneySteps(merchant.id, campaign.id);
  const [addStepModal, setAddStepModal] = useState<StepModalState>({
    open: false,
    step: undefined,
  });
  const [stepToRemove, setStepToRemove] = useState<JourneyStepInputADto>();

  const selectedLanguage = useWatch({
    control,
    name: 'selectedLanguage',
  });
  const definedLanguages = useWatch({
    control,
    name: 'definedLanguages',
  });

  const stepIsNotRemovable = journeySteps?.length === 1 && campaign.status !== 'draft';

  const {isLoading, mutate: createJourneyStepMutation} = useCohortMutation({
    mutationFn: async (data: UpdateJourneyStepsADto) =>
      updateJourneySteps(merchant.id, campaign.id, data),
    notifySuccessMessage: t('journeyUpdateSuccess'),
    onMutate: async newJourneySteps => {
      queryClient.cancelQueries(campaignsKeys.journeySteps(merchant.id, campaign.id));
      const previousJourneySteps = queryClient.getQueryData(
        campaignsKeys.journeySteps(merchant.id, campaign.id)
      );

      queryClient.setQueryData(
        campaignsKeys.journeySteps(merchant.id, campaign.id),
        () => newJourneySteps
      );
      return {previousJourneySteps};
    },
    onError: (err, newJourneySteps, context) => {
      if (typeof context === 'object' && context && 'previousJourneySteps' in context) {
        queryClient.setQueryData(
          campaignsKeys.journeySteps(merchant.id, campaign.id),
          context.previousJourneySteps
        );
      }
      notify(
        'error',
        isCohortError(err, 'trigger-integration.invalid-config')
          ? t('triggerIntegrationError')
          : t('stepUpdateError')
      );
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(campaignsKeys.journeySteps(merchant.id, campaign.id));
    },
    onSuccess: () => {
      setAddStepModal({
        open: false,
        step: undefined,
      });
      setStepToRemove(undefined);
    },
  });

  if (!selectedLanguage) {
    return <Fragment />;
  }

  const getBadges = ({startDate, preRequiredSteps}: JourneyStepOrchestration): ReactNode => {
    return (
      <Fragment>
        {startDate && (
          <OrchestrationBadge text={t('badgeStartDate', {date: formatDate(startDate)})} />
        )}
        {preRequiredSteps &&
          preRequiredSteps.map(stepId => {
            const step = journeySteps?.find(s => s.id === stepId);
            return (
              <OrchestrationBadge
                key={stepId}
                text={t('badgePreRequiredStep', {step: step?.title[selectedLanguage]})}
              />
            );
          })}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div className="mb-2 flex w-full items-center justify-between">
        <label className="block text-sm font-medium text-slate-700">{t('title')}</label>
        {journeySteps && journeySteps.length > 0 && (
          <Button
            onClick={() =>
              setAddStepModal({
                open: true,
              })
            }
          >
            <Plus className="-ml-1 mr-2 h-5 w-5" />
            {t('addStepBtn')}
          </Button>
        )}
      </div>
      {!journeySteps || journeySteps.length === 0 ? (
        <JourneyStepsEmptyState
          isFetched={isFetched}
          onAddStepClicked={() =>
            setAddStepModal({
              open: true,
            })
          }
        />
      ) : (
        <DraggableList
          handleOnReorder={journeyStepsIds => {
            const stepsByIds = mapToObj(journeySteps, step => [step.id, step]);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const newJourneySteps = journeyStepsIds.map(id => stepsByIds[id]!);

            createJourneyStepMutation(newJourneySteps);
          }}
          items={journeySteps.map(step => ({
            id: step.id,
            item: (
              <JourneyStepListItem
                icon={step.icon}
                title={step.title[selectedLanguage]}
                defaultTitle={step.title[merchant.defaultLanguage]}
                triggers={step.triggers}
                selectedLanguage={selectedLanguage}
                badges={getBadges(step.orchestration)}
                onEdit={() =>
                  setAddStepModal({
                    open: true,
                    step,
                  })
                }
                onRemove={() => {
                  if (stepIsNotRemovable) {
                    return notify('error', t('errorEmptySteps'));
                  }
                  setStepToRemove(step);
                }}
              />
            ),
          }))}
        />
      )}
      {addStepModal.open && journeySteps && (
        <CreateEditStepModal
          journeySteps={journeySteps}
          step={addStepModal.step}
          isLoading={isLoading}
          journeyDefinedLanguages={definedLanguages ?? [merchant.defaultLanguage]}
          onSubmit={journeyStep => {
            if (addStepModal.step) {
              const newJourneySteps = journeySteps.map(s =>
                s.id === addStepModal.step?.id ? journeyStep : s
              );

              return createJourneyStepMutation(newJourneySteps);
            }
            return createJourneyStepMutation([...journeySteps, journeyStep]);
          }}
          onClose={() =>
            setAddStepModal({
              open: false,
              step: undefined,
            })
          }
        />
      )}
      {stepToRemove && (
        <DeletionModal
          title={t('removeStepModalTitle')}
          subtitle={t('removeStepModalSubtitle')}
          show
          onClose={() => setStepToRemove(undefined)}
          onDelete={() => {
            const newJourneySteps = journeySteps?.filter(s => s.id !== stepToRemove.id) ?? [];
            for (const newJourneyStep of newJourneySteps) {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              if (newJourneyStep.orchestration.preRequiredSteps?.includes(stepToRemove.id!)) {
                newJourneyStep.orchestration.preRequiredSteps =
                  newJourneyStep.orchestration.preRequiredSteps.filter(
                    id => id !== stepToRemove.id
                  );
              }
            }
            createJourneyStepMutation(newJourneySteps);
          }}
        />
      )}
    </Fragment>
  );
};

export default JourneyStepsSection;
