import type {Network} from '@cohort/shared/schema/common/blockchain';

export function blockExplorerContractUrl(network: Network, smartContractAddress: string): string {
  switch (network) {
    case 'local':
      return `http://ottersgang.localhost/echo/address/${smartContractAddress}`;
    case 'polygon-mumbai':
      return `https://mumbai.polygonscan.com/address/${smartContractAddress}`;
    case 'polygon-amoy':
      return `https://www.oklink.com/amoy/address/${smartContractAddress}`;
    case 'polygon':
      return `https://polygonscan.com/address/${smartContractAddress}`;
  }
}

export function blockExplorerTokenUrl(
  network: Network,
  smartContractAddress: string,
  tokenId: string | number
): string {
  switch (network) {
    case 'local':
      return `http://ottersgang.localhost/echo/nft/${smartContractAddress}/${tokenId}`;
    case 'polygon-mumbai':
      return `https://mumbai.polygonscan.com/nft/${smartContractAddress}/${tokenId}`;
    case 'polygon-amoy':
      return `https://www.oklink.com/amoy/assets/${smartContractAddress}/${tokenId}`;
    case 'polygon':
      return `https://polygonscan.com/nft/${smartContractAddress}/${tokenId}`;
  }
}

export function openSeaContractUrl(network: Network, smartContractAddress: string): string | null {
  switch (network) {
    case 'local':
      return `http://ottersgang.localhost/echo/assets/local/${smartContractAddress}`;
    case 'polygon-mumbai':
      return `https://testnets.opensea.io/assets/mumbai/${smartContractAddress}`;
    case 'polygon-amoy':
      return null;
    case 'polygon':
      return `https://opensea.io/assets/matic/${smartContractAddress}`;
  }
}

export function openSeaTokenUrl(
  network: Network,
  smartContractAddress: string,
  tokenId: string | number
): string | null {
  switch (network) {
    case 'local':
      return `http://ottersgang.localhost/echo/assets/local/${smartContractAddress}/${tokenId}`;
    case 'polygon-mumbai':
      return `https://testnets.opensea.io/assets/mumbai/${smartContractAddress}/${tokenId}`;
    case 'polygon-amoy':
      return null;
    case 'polygon':
      return `https://opensea.io/assets/matic/${smartContractAddress}/${tokenId}`;
  }
}
