import type {UserAttributeADto} from '@cohort/admin-schemas/userAttributes';
import type {UserPropertyADto} from '@cohort/admin-schemas/userProperty';
import AppBadge from '@cohort/merchants/apps/AppBadge';
import HighlightText from '@cohort/merchants/components/HighlightText';
import PaginationFooter from '@cohort/merchants/components/PaginationFooter';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {UserPropertyDataTypeBadge} from '@cohort/merchants/components/UserPropertyDataTypeBadge';
import {useUserAttributes} from '@cohort/merchants/hooks/api/UserAttributes';
import {usePaginatedUserProperties} from '@cohort/merchants/hooks/api/UserProperties';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCurrentUser} from '@cohort/merchants/hooks/contexts/currentUser';
import {useUserPropertiesUserFilters} from '@cohort/merchants/hooks/filters/userPropertiesUserFilters';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import useHiddenUserProperties from '@cohort/merchants/hooks/useHiddenUserProperties';
import {getUserPropertiesSettingsRoute} from '@cohort/merchants/lib/Pages';
import UserAttributeField from '@cohort/merchants/pages/users/user/overview/UserAttributeField';
import {CohortEngagementScoreUserPropertySpec} from '@cohort/shared/apps/cohort/userProperties/engagementScore';
import type {PaginationDto} from '@cohort/shared/schema/common/pagination';
import {formatDate} from '@cohort/shared/utils/format';
import {createColumnHelper} from '@tanstack/react-table';
import {Fragment} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const PAGE_SIZE = 100;

type UserPropertiesTableProps = {
  userProperties: Array<UserPropertyADto>;
  userAttributes: Array<UserAttributeADto>;
  pagination?: PaginationDto & {onPageChange: (page: number) => void};
  isLoading?: boolean;
  orderBy: string;
  setOrderBy: (orderBy: string) => void;
};
const UserPropertiesTable: React.FC<UserPropertiesTableProps> = ({
  userProperties,
  userAttributes,
  pagination,
  isLoading,
  orderBy,
  setOrderBy,
}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'users.user.overview.userPropertiesSection'});

  const columnHelper = createColumnHelper<UserPropertyADto>();
  const columns = [
    columnHelper.accessor('name', {
      header: t('tableHeaderName'),
      cell: data => (
        <span className="text-ellipsis font-medium text-foreground">{data.getValue()}</span>
      ),
      minSize: 220,
    }),
    columnHelper.accessor('dataType', {
      id: 'dataType',
      header: t('tableHeaderFormat'),
      cell: data => <UserPropertyDataTypeBadge userProperty={data.row.original} />,
    }),
    columnHelper.display({
      id: 'appId',
      header: t('tableHeaderUserSync'),
      cell: data =>
        data.row.original.appId !== null ? (
          <AppBadge appId={data.row.original.appId} />
        ) : (
          <div className="flex h-8 items-center">-</div>
        ),
    }),
    columnHelper.display({
      header: t('tableHeaderValue'),
      cell: data => {
        const userAttribute = userAttributes.find(
          attribute => attribute.userPropertyId === data.row.original.id
        );
        return (
          <UserAttributeField
            userAttribute={userAttribute ?? null}
            userProperty={data.row.original}
          />
        );
      },
      minSize: 400,
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: t('tableHeaderCreatedAt'),
      minSize: 200,
      cell: data => {
        const userAttribute = userAttributes.find(
          attribute => attribute.userPropertyId === data.row.original.id
        );
        return (
          <div className="line-clamp-2 font-normal">
            {userAttribute ? formatDate(userAttribute.createdAt) : '-'}
          </div>
        );
      },
    }),
    columnHelper.accessor('updatedAt', {
      id: 'updatedAt',
      header: t('tableHeaderUpdatedAt'),
      enableSorting: false,
      minSize: 200,
      cell: data => {
        const userAttribute = userAttributes.find(
          attribute => attribute.userPropertyId === data.row.original.id
        );
        return (
          <div className="line-clamp-2 font-normal">
            {userAttribute ? formatDate(userAttribute.updatedAt) : '-'}
          </div>
        );
      },
    }),
  ];
  const table = useCohortTable(
    {
      columns,
      data: userProperties,
      initialState: {
        columnPinning: {
          left: ['name'],
        },
      },
    },
    {
      sortBy: orderBy,
      onSortUpdate: setOrderBy,
    }
  );

  return (
    <div className="space-y-4">
      <DataTable
        table={table}
        columnsLength={columns.length}
        isLoading={isLoading}
        emptyStatePlaceholder={t('emptyPlaceholder')}
      />
      {pagination && pagination.pages > 1 && (
        <PaginationFooter pagination={pagination} onPageChange={pagination.onPageChange} />
      )}
    </div>
  );
};

const UserPropertiesSection: React.FC = () => {
  const user = useCurrentUser();
  const merchant = useCurrentMerchant();
  const navigate = useNavigate();
  const filters = useUserPropertiesUserFilters();
  const excludedReferenceIds = useHiddenUserProperties();

  const {data: properties} = usePaginatedUserProperties(merchant.id, {
    page: filters.page,
    pageSize: PAGE_SIZE,
    visible: true,
    excludedReferenceIds,
    orderBy: filters.orderBy,
  });
  const [pagination, data] = properties ?? [];
  const userProperties =
    data !== undefined
      ? data.filter(({referenceId}) => referenceId !== CohortEngagementScoreUserPropertySpec.id)
      : undefined;

  const hasUserProperties = userProperties !== undefined && userProperties.length > 0;

  const {data: userAttributes, isLoading: isAttributesLoading} = useUserAttributes(
    merchant.id,
    {
      userId: user.id,
      userPropertyIds: userProperties?.map(property => property.id) ?? [],
    },
    {
      enabled: hasUserProperties,
    }
  );

  if (userProperties === undefined) {
    return <Fragment />;
  }

  // should refresh when any attribute is added, removed or updated
  const refreshKey = userAttributes?.map(attribute => attribute.updatedAt.toString()).join() ?? '';

  return (
    <div className="flex flex-col space-y-8 pb-10">
      <UserPropertiesTable
        userProperties={userProperties}
        userAttributes={userAttributes ?? []}
        pagination={
          pagination !== undefined ? {...pagination, onPageChange: filters.setPage} : undefined
        }
        key={refreshKey}
        isLoading={hasUserProperties && isAttributesLoading}
        orderBy={filters.orderBy}
        setOrderBy={filters.setOrderBy as (value: string) => void}
      />
      <HighlightText
        type="info"
        text={
          <Trans
            i18nKey="users.user.overview.userPropertiesSection.helper"
            ns="pages"
            components={{
              pageLink: (
                <a
                  className="font-medium text-blue-600 underline"
                  href={getUserPropertiesSettingsRoute().path}
                  onClick={e => {
                    e.preventDefault();
                    navigate(getUserPropertiesSettingsRoute().path);
                  }}
                />
              ),
            }}
          />
        }
      />
    </div>
  );
};

export default UserPropertiesSection;
