import type {BadgeADto} from '@cohort/admin-schemas/badge';
import StackedImages from '@cohort/merchants/components/StackedImages';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';

type CohortBadgesVisualsProps = {
  badges: Array<BadgeADto>;
  imgSize?: number;
};

const CohortBadgesVisuals: React.FC<CohortBadgesVisualsProps> = ({badges, imgSize}) => {
  const imageData = badges.map(badge => {
    if (!badge.imageFileKey) {
      throw new Error('Badge image file key is missing');
    }
    return {
      url: getImageUrl(import.meta.env.COHORT_ENV, badge.imageFileKey, {h: Sizes.S, w: Sizes.S}),
      tooltipContent: badge.internalName,
    };
  });

  return <StackedImages imageData={imageData} imgSize={imgSize} />;
};

export default CohortBadgesVisuals;
