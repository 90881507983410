export const SCROLL_SPY_CONTAINER_ID = 'campaign-preview';
export const SMOOTH_SCROLL_DURATION = 750;
export const SCROLL_SPY_OFFSET = -70;

export type CampaignStorePreviewSection = 'description' | 'rewards' | 'faq';

export type MobileNavigationItem = {
  name: CampaignStorePreviewSection;
  sectionTitle: string;
  navigationTitle: string;
};
