/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type {CampaignADto} from '@cohort/admin-schemas/campaign';
import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import type {CampaignStorePreviewValues} from '@cohort/merchants/components/campaigns/CampaignStorePreview';
import {CampaignStorePreview} from '@cohort/merchants/components/campaigns/CampaignStorePreview';
import type {CampaignJourneyPreviewValues} from '@cohort/merchants/components/campaigns/journeys/CampaignJourneyPreview';
import CampaignJourneyPreview from '@cohort/merchants/components/campaigns/journeys/CampaignJourneyPreview';
import {useJourneySteps} from '@cohort/merchants/hooks/api/Campaigns';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCampaignPageStore} from '@cohort/merchants/hooks/stores/campaignPage';
import {notify} from '@cohort/merchants/hooks/toast';
import {getCampaignsRoute} from '@cohort/merchants/lib/Pages';
import CampaignPreviewAside from '@cohort/merchants/pages/campaigns/campaign/CampaignPreviewAside';
import type {CampaignFormStep} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import AudienceSectionContent from '@cohort/merchants/pages/campaigns/campaign/edit/review/AudienceSectionContent';
import EmptyRewardsSectionContent from '@cohort/merchants/pages/campaigns/campaign/edit/review/EmptyRewardsSectionContent';
import PriceSectionContent from '@cohort/merchants/pages/campaigns/campaign/edit/review/PriceSectionContent';
import ReviewSection from '@cohort/merchants/pages/campaigns/campaign/edit/review/ReviewSection';
import RewardSectionContent from '@cohort/merchants/pages/campaigns/campaign/edit/review/RewardSectionContent';
import StepsSectionContent from '@cohort/merchants/pages/campaigns/campaign/edit/review/StepsSectionContent';
import StoreOptionsSectionContent from '@cohort/merchants/pages/campaigns/campaign/edit/review/StoreOptionsContent';
import TypeSectionContent from '@cohort/merchants/pages/campaigns/campaign/edit/review/TypeSectionContent';
import {DEFAULT_ACCENT_COLOR, DEFAULT_BACKGROUND_COLOR} from '@cohort/shared/schema/common';
import type {UseMutateFunction} from '@tanstack/react-query';
import {Fragment, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

type CampaignReviewProps = {
  onEditStep: (step: CampaignFormStep) => void;
  setStepSaveBtn: (btn: JSX.Element) => void;
  publishCampaign: UseMutateFunction<CampaignADto | null, unknown, void, unknown>;
};

const CampaignReviewStep: React.FC<CampaignReviewProps> = ({
  onEditStep,
  setStepSaveBtn,
  publishCampaign,
}): JSX.Element => {
  const campaign = useCurrentCampaign();
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.campaignReviewStep',
  });
  const {data: journeySteps} = useJourneySteps(merchant.id, campaign.id, {
    enabled: campaign.type === 'journey',
  });
  const setCampaignPreview = useCampaignPageStore(state => state.setCampaignPreview);
  const navigate = useNavigate();
  const hasReward =
    campaign.rewardBundle.collections.length > 0 || campaign.rewardBundle.perks.length > 0;

  useEffect(() => {
    setStepSaveBtn(
      <Button
        variant="secondary"
        onClick={() => {
          notify('success', t('notificationSuccess'));
          navigate(getCampaignsRoute().path);
        }}
      >
        {t('saveBtn')}
      </Button>
    );
  }, [t, navigate, setStepSaveBtn]);

  useEffect(() => {
    if (campaign.type === 'store' && campaign.store !== null) {
      const previewValues = {
        accentColorCode: campaign.store.accentColorCode!,
        imageFileKey: campaign.store.imageFileKey,
        animationFileKey: campaign.store.animationFileKey,
        backgroundColorCode: campaign.store.backgroundColorCode!,
        campaignTitle: campaign.store.title[merchant.defaultLanguage] ?? '',
        defaultCurrency: campaign.store.defaultCurrency,
        description: campaign.store.description[merchant.defaultLanguage] ?? undefined,
        faqs: campaign.faqs,
        perks: campaign.rewardBundle.perks.map(perk => perk.perk),
        collections: campaign.rewardBundle.collections.map(collection => collection.collection),
        prices: campaign.store.prices,
        pricing: campaign.store.pricing,
        selectedLanguage: merchant.defaultLanguage,
        supportEmail: merchant.supportEmail ?? '',
      } satisfies CampaignStorePreviewValues;

      setCampaignPreview(
        <CampaignPreviewAside>
          <CampaignStorePreview values={previewValues} />
        </CampaignPreviewAside>
      );
    }

    if (campaign.type === 'journey' && campaign.journey !== null) {
      const previewValues = {
        accentColorCode: merchant.accentColorCode ?? DEFAULT_ACCENT_COLOR,
        animationFileKey: campaign.journey.animationFileKey,
        backgroundColorCode: merchant.backgroundColorCode ?? DEFAULT_BACKGROUND_COLOR,
        campaignTotalSupply: campaign.totalSupply ?? null,
        description: campaign.journey.description[merchant.defaultLanguage] ?? null,
        imageFileKey: campaign.journey.imageFileKey,
        faqs: campaign.faqs[merchant.defaultLanguage] ?? [],
        optInCta: campaign.journey.optInCta[merchant.defaultLanguage] ?? null,
        selectedLanguage: merchant.defaultLanguage,
        steps: journeySteps ?? [],
        supportEmail: merchant.supportEmail,
        title: campaign.journey.title[merchant.defaultLanguage] ?? null,
      } satisfies CampaignJourneyPreviewValues;

      setCampaignPreview(
        <CampaignPreviewAside>
          <CampaignJourneyPreview previewValues={previewValues} />
        </CampaignPreviewAside>
      );
    }

    return () => setCampaignPreview(null);
  }, [setCampaignPreview, campaign, merchant, journeySteps]);

  return (
    <AdminPage header={{title: t('title'), subtitle: t('subtitle')}}>
      <div>
        <form
          id="campaign-review-step"
          onSubmit={e => {
            e.preventDefault();
            publishCampaign();
          }}
        />
        <div className="flex flex-col gap-6">
          <ReviewSection
            title={t('sectionType')}
            content={<TypeSectionContent campaign={campaign} />}
            onEditStep={() => onEditStep('type')}
          />
          <ReviewSection
            title={t('sectionAudience')}
            content={<AudienceSectionContent campaign={campaign} />}
            onEditStep={() => onEditStep('audience')}
          />
          <ReviewSection
            title={t('sectionRewards')}
            content={hasReward ? <Fragment /> : <EmptyRewardsSectionContent />}
            onEditStep={() => onEditStep('reward')}
          >
            {hasReward ? (
              <div className="mt-6 border-t pt-6">
                <RewardSectionContent campaign={campaign} />
              </div>
            ) : undefined}
          </ReviewSection>
          {campaign.type === 'store' && (
            <ReviewSection
              title={t('sectionPrice')}
              content={<PriceSectionContent campaign={campaign} />}
              onEditStep={() => onEditStep('settings')}
            />
          )}
          {campaign.type === 'journey' && (
            <ReviewSection
              title={t('sectionSteps')}
              content={<Fragment />}
              onEditStep={() => onEditStep('settings')}
            >
              <div className="mt-6 border-t pt-6">
                <StepsSectionContent campaign={campaign} />
              </div>
            </ReviewSection>
          )}
          {campaign.totalSupply !== null && (
            <ReviewSection
              title={t('sectionStoreOptions')}
              content={<StoreOptionsSectionContent campaign={campaign} />}
              onEditStep={() => onEditStep('settings')}
            />
          )}
        </div>
      </div>
    </AdminPage>
  );
};

export default CampaignReviewStep;
