import {Badge} from '@cohort/merchants/components/Badge';
import Loader from '@cohort/merchants/components/Loader';
import type {Network} from '@cohort/shared/schema/common/blockchain';
import {blockExplorerContractUrl} from '@cohort/shared/utils/blockchain';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

type DigitalAssetTokenIdBadgeProps = {
  tokenId: number | null;
  nft: {
    network: Network;
    blockExplorerUrl: string | null;
  } | null;
};

const DigitalAssetTokenIdBadge: React.FC<DigitalAssetTokenIdBadgeProps> = ({tokenId, nft}) => {
  function handleAddressClick(network: Network, address: string): void {
    const url = blockExplorerContractUrl(network, address);
    window.open(url, '_blank');
  }

  return (
    <div className="flex items-center gap-2">
      <Badge
        text={
          tokenId === null ? (
            <Loader size={16} color="gray" />
          ) : (
            <span className="text-sm font-medium">#{tokenId}</span>
          )
        }
        backgroundColor="bg-slate-50"
        textColor="text-slate-600"
      />
      {nft?.network && nft.blockExplorerUrl && (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        <button onClick={() => handleAddressClick(nft.network, nft.blockExplorerUrl!)}>
          <SvgAppIcon name="polygon" height={16} width={16} />
        </button>
      )}
    </div>
  );
};

export default DigitalAssetTokenIdBadge;
