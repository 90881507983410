import type {SpotifyFollowPlaylistVerificationAttemptData} from '@cohort/shared/apps/spotify/triggers/followPlaylist';
import {Trans} from 'react-i18next';

const SpotifyFollowPlaylistTriggerStepCompletionTitleComponent: React.FC<{
  data: SpotifyFollowPlaylistVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  if (!completionSuccess) {
    return (
      <Trans
        i18nKey="triggerIntegrations.follow-playlist.stepCompletionTitleComponent.error"
        ns="app-spotify"
        values={{username: data.userName}}
        components={{
          a: (
            <a
              className="font-semibold text-gray-900"
              target="_blank"
              rel="noopener noreferrer nofollow"
              href={data.userUrl}
            />
          ),
        }}
      />
    );
  }

  return (
    <Trans
      i18nKey="triggerIntegrations.follow-playlist.stepCompletionTitleComponent.success"
      ns="app-spotify"
      values={{username: data.userName}}
      components={{
        a: (
          <a
            className="font-semibold text-gray-900"
            target="_blank"
            rel="noopener noreferrer nofollow"
            href={data.userUrl}
          />
        ),
      }}
    />
  );
};

export default SpotifyFollowPlaylistTriggerStepCompletionTitleComponent;
