import type {OrganizationMemberADto} from '@cohort/admin-schemas/organizationMember';
import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import {MembershipStatusBadge} from '@cohort/merchants/components/MembershipStatusBadge';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {
  organizationMembersKeys,
  useOrganizationMembers,
} from '@cohort/merchants/hooks/api/OrganizationMembers';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {notify} from '@cohort/merchants/hooks/toast';
import {
  inviteOrganizationMembers,
  removeOrganizationMember,
} from '@cohort/merchants/lib/api/OrganizationMembers';
import {
  trackMembersSettingsPageViewed,
  trackOrganizationMembershipInviteButtonClicked,
} from '@cohort/merchants/lib/Tracking';
import EditMemberModal from '@cohort/merchants/pages/settings/members/EditMemberModal';
import InviteMemberModal from '@cohort/merchants/pages/settings/members/InviteMemberModal';
import {PencilSimple, UserPlus} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {createColumnHelper} from '@tanstack/react-table';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

type MembersTableProps = {
  members: OrganizationMemberADto[];
  loading: boolean;
  handleEditMember: (member: OrganizationMemberADto) => void;
};

const MembershipsTable = ({members, loading, handleEditMember}: MembersTableProps): JSX.Element => {
  const {t} = useTranslation('pages', {keyPrefix: 'settings.members.page'});
  const columnHelper = createColumnHelper<OrganizationMemberADto>();
  const columns = [
    columnHelper.accessor('email', {
      header: t('tableHeaderEmail'),
      cell: data => <span className="font-medium text-foreground">{data.getValue()}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor('status', {
      header: t('tableHeaderStatus'),
      cell: data => <MembershipStatusBadge status={data.getValue()} />,
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'actions',
      cell: data => (
        <div className="text-right">
          <Button
            variant="secondary"
            size="icon"
            onClick={() => handleEditMember(data.row.original)}
          >
            <PencilSimple size={20} />
          </Button>
        </div>
      ),
    }),
  ];
  const table = useCohortTable({
    columns,
    data: members,
  });

  return (
    <DataTable
      table={table}
      columnsLength={columns.length}
      emptyStatePlaceholder={t('emptyPlaceholder')}
      isLoading={loading}
    />
  );
};

const MembersSettingsPage = (): JSX.Element => {
  const queryClient = useQueryClient();
  const {t} = useTranslation('pages', {keyPrefix: 'settings.members.page'});
  const [inviteMemberModalOpened, setInviteMemberModalOpened] = useState<boolean>(false);
  const [editedMember, setEditedMember] = useState<OrganizationMemberADto | undefined>();
  const {data: members, isLoading: isMembershipsLoading} = useOrganizationMembers();

  useEffect(() => trackMembersSettingsPageViewed(), []);

  const {isLoading: addMemberLoading, mutate: addMember} = useCohortMutation({
    mutationFn: async (email: string) => inviteOrganizationMembers([email]),
    notifyErrorMessage: t('notificationInvitationFailed'),
    onSuccess: async (res, variables) => {
      void res;
      notify('success', t('notificationInvitationSentTo', {email: variables}));
      await queryClient.invalidateQueries(organizationMembersKeys.list());
      setInviteMemberModalOpened(false);
    },
  });

  const {isLoading: removeMemberLoading, mutate: removeMember} = useCohortMutation({
    mutationFn: async (memberId: string) => removeOrganizationMember(memberId),
    notifySuccessMessage: t('notificationMemberRemoved'),
    notifyErrorMessage: t('notificationRemovingFailed'),
    onSuccess: async () => {
      await queryClient.invalidateQueries(organizationMembersKeys.list());
    },
  });

  function handleInviteUserClick(): void {
    setInviteMemberModalOpened(true);
    trackOrganizationMembershipInviteButtonClicked();
  }

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
    topRightElements: (
      <Button key="invite-user-btn" onClick={handleInviteUserClick} data-testid="invite-user">
        <UserPlus className="-ml-1 mr-2 h-5 w-5" />
        {t('labelInviteUser')}
      </Button>
    ),
  };

  return (
    <AdminPage header={headerConfig}>
      <MembershipsTable
        members={members ?? []}
        loading={isMembershipsLoading || addMemberLoading || removeMemberLoading}
        handleEditMember={setEditedMember}
      />
      <InviteMemberModal
        addMembership={addMember}
        show={inviteMemberModalOpened}
        handleClose={() => setInviteMemberModalOpened(false)}
        isLoading={addMemberLoading}
      />
      {editedMember && (
        <EditMemberModal
          member={editedMember}
          members={members ?? []}
          addMember={addMember}
          removeMember={removeMember}
          show
          loading={addMemberLoading || removeMemberLoading}
          handleClose={() => setEditedMember(undefined)}
        />
      )}
    </AdminPage>
  );
};

export default MembersSettingsPage;
