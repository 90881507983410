/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getOrders} from '@cohort/merchants/lib/api/Orders';
import type {PaginationParamsDto} from '@cohort/shared/schema/common/pagination';

export const ordersKeys = {
  orders: ['orders'] as const,
  list: (merchantId: string, campaignId: string) =>
    [...ordersKeys.orders, merchantId, campaignId] as const,
  listWithPagination: (
    merchantId: string,
    campaignId: string,
    paginationParams: PaginationParamsDto
  ) => [...ordersKeys.list(merchantId, campaignId), paginationParams] as const,
};

export const useOrdersWithPagination = (
  merchantId: string,
  campaignId: string,
  paginationParams: PaginationParamsDto,
  enabled?: boolean,
  autoRefresh?: boolean
) =>
  useCohortQuery({
    queryKey: ordersKeys.listWithPagination(merchantId, campaignId, paginationParams),
    queryFn: async () => getOrders(merchantId, campaignId, paginationParams),
    enabled,
    refetchInterval: autoRefresh ? 5000 : false,
  });
