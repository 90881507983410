import Title from '@cohort/components-xps/components/Title';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import React from 'react';

type CampaignStoreInfoProps = {
  title: string;
  subtitle: React.ReactNode;
  isMobile?: boolean;
};

const CampaignStoreInfo: React.FC<CampaignStoreInfoProps> = ({
  title,
  subtitle,
  isMobile,
}): React.ReactElement => (
  <div className={cn('flex flex-col gap-2', isMobile ? 'text-center' : 'text-left')}>
    <Title content={title} />
    <h3>{subtitle}</h3>
  </div>
);

export default CampaignStoreInfo;
