import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import ExportCSV from '@cohort/merchants/components/buttons/ExportCSV';
import {IconBadge} from '@cohort/merchants/components/IconBadge';
import ImportUsersModal from '@cohort/merchants/components/modals/ImportUsersModal/ImportUsersModal';
import {useCohortUsers} from '@cohort/merchants/hooks/api/Cohorts';
import {useCurrentCohort} from '@cohort/merchants/hooks/contexts/currentCohort';
import {useBasePageFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {exportCohortUsers} from '@cohort/merchants/lib/api/Cohorts';
import MembersTable from '@cohort/merchants/pages/users/cohort/members/MembersTable';
import {Users} from '@phosphor-icons/react';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

const PAGE_SIZE = 20;

const CohortMembersPage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);

  const cohort = useCurrentCohort();
  const [importUsersModalOpen, setImportUsersModalOpen] = useState(false);
  const [usersCount, setUsersCount] = useState<number | null>(null);
  const filters = useBasePageFilters(/^(\/test)?\/cohorts\/.*\/members$/u, null);

  const {t} = useTranslation('pages', {
    keyPrefix: 'users.cohort.members.page',
  });

  const {data, isLoading} = useCohortUsers(merchantId, cohort.id, {
    page: filters.page,
    pageSize: PAGE_SIZE,
  });
  const [pagination, users] = data ?? [];

  useEffect(() => {
    if (pagination !== undefined) {
      setUsersCount(pagination.total);
    }
  }, [pagination]);

  const onImportUsersClose = (): void => setImportUsersModalOpen(false);

  const headerConfig = {
    title: t('title'),
    titleBadge: (
      <IconBadge
        key="cohorts"
        icon={<Users className="mr-1.5 h-4 w-4 text-slate-500" />}
        text={t('membersBadge', {count: usersCount ?? 0})}
      />
    ),
    subtitle: t('subtitle'),
    topRightElements: [
      <ExportCSV
        disabled={isLoading || (users?.length ?? 0) === 0}
        downloadFunction={async () => exportCohortUsers(merchantId, cohort.id, {format: 'csv'})}
        withCache={false}
        key="exportUsers"
      />,
      cohort.type === 'static' && (
        <Button key="importUsersBtn" onClick={() => setImportUsersModalOpen(true)}>
          {t('buttonImportUsers')}
        </Button>
      ),
    ],
  };

  return (
    <AdminPage
      header={headerConfig}
      pagination={{
        pagination,
        onPageChange: filters.setPage,
      }}
    >
      <MembersTable users={users ?? []} isLoading={isLoading} />
      {importUsersModalOpen && <ImportUsersModal onClose={onImportUsersClose} cohort={cohort} />}
    </AdminPage>
  );
};

export default CohortMembersPage;
