import AsidePageMenu from '@cohort/merchants/components/AsidePageMenu';
import {useMembershipPassConfigurationPageStore} from '@cohort/merchants/hooks/stores/membershipPassConfigurationPage';
import {useTestModeStore} from '@cohort/merchants/hooks/stores/testMode';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import type {PageLink} from '@cohort/merchants/lib/Pages';
import {
  getAppsRoute,
  getBrandRoute,
  getCustomizationRoute,
  getIntegrationsSettingsRoute,
  getLanguagesRoute,
  getMembershipPassConfigurationRoute,
  getNotificationsSettingsRoute,
  getPaymentSettingsRoute,
  getQrReadersSettingsRoute,
  getTeamRoute,
  getUserPropertiesSettingsRoute,
} from '@cohort/merchants/lib/Pages';
import {
  Bell,
  Code,
  CreditCard,
  Desktop,
  DeviceMobile,
  IdentificationCard,
  Palette,
  Swatches,
  Translate,
  UserList,
  Users,
} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';
import {Outlet, useLocation} from 'react-router-dom';

const SettingsLayout = (): JSX.Element => {
  const {t} = useTranslation('pages', {keyPrefix: 'settings.page'});
  const location = useLocation();
  const testModeEnabled = useTestModeStore(state => state.testModeEnabled);
  const membershipPassConfigurationPreview = useMembershipPassConfigurationPageStore(
    store => store.membershipPassConfigurationPagePreview
  );

  type NavItem = {
    name: string;
    path: string;
    page: PageLink;
    icon: JSX.Element;
  };

  const nav: Array<NavItem> = [
    {
      name: t('linkBrandSpace'),
      path: getBrandRoute().path,
      page: getBrandRoute(),
      icon: <Swatches size={20} />,
    },
    {
      name: t('linkCustomization'),
      path: getCustomizationRoute().path,
      page: getCustomizationRoute(),
      icon: <Palette size={20} />,
    },
    {
      name: t('linkMembershipPass'),
      path: getMembershipPassConfigurationRoute().path,
      page: getMembershipPassConfigurationRoute(),
      icon: <IdentificationCard size={20} />,
    },

    {
      name: t('linkConnections'),
      path: getAppsRoute().path,
      page: getAppsRoute(),
      icon: <Desktop size={20} />,
    },
    {
      name: t('linkNotifications'),
      path: getNotificationsSettingsRoute().path,
      page: getNotificationsSettingsRoute(),
      icon: <Bell size={20} />,
    },
    ...(!testModeEnabled
      ? [
          {
            name: t('linkPaymentSettings'),
            path: getPaymentSettingsRoute().path,
            page: getPaymentSettingsRoute(),
            icon: <CreditCard size={20} />,
          },
          {
            name: t('linkTeamMembers'),
            path: getTeamRoute().path,
            page: getTeamRoute(),
            icon: <Users size={20} />,
          },
        ]
      : []),
    {
      name: t('linkIntegrations'),
      path: getIntegrationsSettingsRoute().path,
      page: getIntegrationsSettingsRoute(),
      icon: <Code size={20} />,
    },
    {
      name: t('linkQrReaders'),
      path: getQrReadersSettingsRoute().path,
      page: getQrReadersSettingsRoute(),
      icon: <DeviceMobile size={20} />,
    },
    {
      name: t('linkLanguages'),
      path: getLanguagesRoute().path,
      icon: <Translate size={20} />,
      page: getLanguagesRoute(),
    },
    {
      name: t('linkUserProperties'),
      path: getUserPropertiesSettingsRoute().path,
      icon: <UserList size={20} />,
      page: getUserPropertiesSettingsRoute(),
    },
  ];
  const activeTab = nav.find(link => location.pathname.includes(link.path))?.name ?? '';

  return (
    <PageLayout
      title={activeTab}
      menu={<AsidePageMenu entries={nav} />}
      rightAside={membershipPassConfigurationPreview}
    >
      <Outlet />
    </PageLayout>
  );
};

export default SettingsLayout;
