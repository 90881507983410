import type {FilterOption} from '@cohort/merchants/components/filters/Filters';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Menu, Transition} from '@headlessui/react';
import {CaretDown} from '@phosphor-icons/react';
import React, {Fragment} from 'react';

interface SingleChoiceFilterProps {
  options: FilterOption[];
  onChange: (option: FilterOption) => void;
  prefix?: string;
  border?: boolean;
  className?: string;
}

export const SingleChoiceFilter: React.FC<SingleChoiceFilterProps> = ({
  options,
  onChange,
  prefix,
  border,
  className,
}) => {
  const activeOption = options.find(option => option.active);

  return (
    <div className="flex items-center justify-between">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className={cn(
              'group inline-flex justify-center text-sm font-medium text-slate-500 hover:text-slate-600',
              border === true && 'rounded-md border bg-white px-2 py-1',
              className
            )}
          >
            {prefix}
            {prefix !== undefined && activeOption && ' : '}
            {activeOption?.label}
            <CaretDown
              className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-slate-400 group-hover:text-slate-500"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-0"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-30 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {options.map(option => (
                <Menu.Item key={option.value}>
                  {({active}) => (
                    <div
                      onClick={() => onChange(option)}
                      className={cn(
                        'block cursor-pointer px-4 py-2 text-sm',
                        active && 'bg-slate-100',
                        activeOption?.value === option.value
                          ? 'font-medium'
                          : 'font-normal text-slate-500'
                      )}
                    >
                      {option.label}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default SingleChoiceFilter;
