import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import CardListDisplayToggler from '@cohort/merchants/components/buttons/CardListDisplayToggler';
import PerksFilters from '@cohort/merchants/components/perks/PerksFilters';
import PerksSortBy from '@cohort/merchants/components/perks/PerksSortBy';
import {usePerks} from '@cohort/merchants/hooks/api/Perks';
import {usePerksListFilters} from '@cohort/merchants/hooks/filters/perksListFilters';
import {usePerksDisplay} from '@cohort/merchants/hooks/stores/perksDisplay';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import {getPerkCreateRoute} from '@cohort/merchants/lib/Pages';
import {
  trackPerkCreateButtonClicked,
  trackPerksDisplayChanged,
  trackPerksListPageViewed,
} from '@cohort/merchants/lib/Tracking';
import PerksList from '@cohort/merchants/pages/perks/overview/PerksList';
import RewardsMenu from '@cohort/merchants/pages/rewards/RewardsMenu';
import {Plus} from '@phosphor-icons/react';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {shallow} from 'zustand/shallow';

const PAGE_SIZE = 20;

const PerksOverviewPage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {t} = useTranslation('pages', {keyPrefix: 'perks.overview.page'});
  const navigate = useNavigate();
  const {display, setDisplay} = usePerksDisplay(
    store => ({
      display: store.display,
      setDisplay: store.setDisplay,
    }),
    shallow
  );
  const filters = usePerksListFilters(/^(\/test)?\/perks$/u);

  const {data, isLoading, isError} = usePerks(merchantId, {
    page: filters.page,
    pageSize: PAGE_SIZE,
    orderBy: filters.sort,
    status: filters.status,
    integration: filters.integrations,
    type: filters.types,
  });
  const [pagination, perks] = data ?? [];

  function handleCreatePerkClick(): void {
    trackPerkCreateButtonClicked();
    navigate(getPerkCreateRoute().path);
  }

  useEffect(() => {
    trackPerksListPageViewed();
  }, []);

  const headerConfig = {
    title: t('perks'),
    subtitle: t('subtitle'),
    topRightElements: (
      <Button key="createPerkBtn" onClick={handleCreatePerkClick}>
        <Plus className="-ml-1 mr-2 h-5 w-5" />
        {t('createPerk')}
      </Button>
    ),
  };

  const paginationConfig = {
    pagination,
    onPageChange: filters.setPage,
  };

  return (
    <PageLayout title={t('perks')} menu={<RewardsMenu />}>
      <AdminPage header={headerConfig} pagination={paginationConfig}>
        <div className="mb-7 flex w-full flex-col justify-between sm:flex-row sm:items-center">
          <PerksFilters filters={filters} />
          <div className="flex flex-row space-x-4">
            <PerksSortBy filters={filters} />
            <CardListDisplayToggler
              display={display}
              setDisplay={display => {
                trackPerksDisplayChanged(display);
                setDisplay(display);
              }}
            />
          </div>
        </div>
        <PerksList
          perks={perks}
          isError={isError}
          isLoading={isLoading}
          onHandleCreatePerkClick={handleCreatePerkClick}
        />
      </AdminPage>
    </PageLayout>
  );
};

export default PerksOverviewPage;
