import type {ListUserEventsParamsADto, UserEventADto} from '@cohort/admin-schemas/userEvents';
import {UserEventASchema} from '@cohort/admin-schemas/userEvents';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import type {PaginationDto} from '@cohort/shared/schema/common/pagination';
import {paginatedParser} from '@cohort/shared/utils/parser';

export async function getUserEvents(
  merchantId: string,
  params: ListUserEventsParamsADto
): Promise<[PaginationDto, UserEventADto[]]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/user-events`, {
    expect: HttpCodes.SUCCESS,
    parser: paginatedParser(UserEventASchema),
    params,
  });
}
