import type {CampaignADto} from '@cohort/admin-schemas/campaign';
import {AirdropVisual} from '@cohort/merchants/components/AirdropVisual';
import {getImageUrl, getVideoUrl, Sizes} from '@cohort/shared/utils/media';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Image} from '@phosphor-icons/react';
import React from 'react';
import {match} from 'ts-pattern';

interface VisualProps {
  imageFileKey: string | null;
  animationFileKey: string | null;
  className?: string;
  size: number;
}

export const Visual: React.FC<VisualProps> = ({
  imageFileKey,
  animationFileKey,
  className,
  size,
}) => {
  if (animationFileKey !== null) {
    return (
      <video
        controls={false}
        autoPlay
        playsInline
        loop
        muted
        className={cn('flex-shrink-0', className)}
        height={size}
        width={size}
      >
        <source
          src={getVideoUrl(import.meta.env.COHORT_ENV, animationFileKey, {h: Sizes.S, w: Sizes.S})}
        />
      </video>
    );
  }
  if (imageFileKey !== null) {
    return (
      <img
        className={cn('flex-shrink-0', className)}
        height={size}
        width={size}
        src={getImageUrl(import.meta.env.COHORT_ENV, imageFileKey, {
          h: Sizes.S,
          w: Sizes.S,
        })}
      />
    );
  }
  return (
    <div
      className={cn(
        'flex flex-shrink-0 items-center justify-center bg-slate-50 text-slate-500',
        className
      )}
      style={{height: size, width: size}}
    >
      <Image size={24} className="text-slate-400" />
    </div>
  );
};

interface Props {
  campaign: CampaignADto;
  className?: string;
  size: number;
}

export const CampaignVisual: React.FC<Props> = ({campaign, className, size}) => {
  return match(campaign.type)
    .with('journey', () => (
      <Visual
        imageFileKey={campaign.journey?.imageFileKey ?? null}
        animationFileKey={campaign.journey?.animationFileKey ?? null}
        className={className}
        size={size}
      />
    ))
    .with('store', () => (
      <Visual
        imageFileKey={campaign.store?.imageFileKey ?? null}
        animationFileKey={campaign.store?.animationFileKey ?? null}
        className={className}
        size={size}
      />
    ))
    .with('airdrop', () => <AirdropVisual className={className} size={size} />)
    .with(null, () => (
      <Visual imageFileKey={null} animationFileKey={null} className={className} size={size} />
    ))
    .exhaustive();
};

export default CampaignVisual;
