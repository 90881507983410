import {cn} from '@cohort/shared-frontend/utils/classNames';
import {FileArrowDown} from '@phosphor-icons/react';

interface CsvUploadProps {
  onParse: (lines: string[]) => void;
  hasHeader?: (line: string) => boolean;
  caption: string;
  dataTestId?: string;
  className?: string;
}

export const CsvUpload: React.FC<CsvUploadProps> = ({
  onParse,
  hasHeader,
  caption,
  dataTestId,
  className,
}) => {
  function readAndParseCsv(file: File, inputRef: EventTarget & HTMLInputElement): void {
    const reader = new FileReader();

    reader.readAsText(file);
    reader.onload = () => {
      const csv = reader.result as string;
      const lines = csv.split('\n').filter(Boolean); // Remove empty lines

      if (lines.length === 1) {
        onParse([csv]);
      } else {
        // Real CSV
        const firstLine = lines[0];
        const trimmedLines = [];

        if (hasHeader !== undefined && firstLine !== undefined && hasHeader(firstLine)) {
          lines.shift();
        }

        for (const line of lines) {
          let trimmedLine = line.trim();
          if (trimmedLine.endsWith(',') || trimmedLine.endsWith(';')) {
            trimmedLine = trimmedLine.slice(0, -1);
          }
          if (trimmedLine === '') {
            continue;
          }
          trimmedLines.push(trimmedLine);
        }
        onParse(trimmedLines);
      }
      inputRef.value = '';
    };
  }

  return (
    <div
      className={cn(
        'relative flex flex-col items-center justify-center rounded-md border-2 border-dashed p-4 text-center text-slate-400',
        className
      )}
    >
      <FileArrowDown className="mb-2 h-6 w-6" />
      <input
        data-testid={dataTestId}
        className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
        type="file"
        accept=".csv"
        onChange={e => {
          const file = e.target.files?.[0];
          if (file) {
            readAndParseCsv(file, e.target);
          }
        }}
      />
      <p className="mx-2 text-sm">{caption}</p>
    </div>
  );
};
