import Button from '@cohort/merchants/components/buttons/Button';
import ConnectionPicker from '@cohort/merchants/components/connections/ConnectionPicker';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@cohort/merchants/components/modals/Dialog';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {syncsKeys} from '@cohort/merchants/hooks/api/Syncs';
import {useCurrentApp} from '@cohort/merchants/hooks/contexts/currentApp';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {createSync} from '@cohort/merchants/lib/api/Sync';
import {getAppSyncRoute} from '@cohort/merchants/lib/Pages';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {z} from 'zod';

interface CreateSyncModalProps {
  onClose: () => void;
}

const CreateSyncFormSchema = z.object({
  connectionId: z.string().uuid(),
});
type CreateSyncFormValues = z.infer<typeof CreateSyncFormSchema>;

const CreateSyncModal: React.FC<CreateSyncModalProps> = ({onClose}) => {
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {appId} = useCurrentApp();
  const {t} = useTranslation('pages', {keyPrefix: 'apps.app.syncs.page.createSyncModal'});

  const {isLoading, mutate: createSyncMutation} = useCohortMutation({
    mutationFn: async (data: CreateSyncFormValues) => createSync(merchant.id, {...data, appId}),
    onSuccess: async newSync => {
      await queryClient.invalidateQueries(syncsKeys.list(merchant.id));
      navigate(getAppSyncRoute(appId, newSync.id).path);
    },
  });

  const {register, handleSubmit, control} = useForm<CreateSyncFormValues>({
    resolver: zodResolver(CreateSyncFormSchema),
  });

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="[width:480px]">
        <form onSubmit={handleSubmit(data => createSyncMutation(data))}>
          <DialogHeader>
            <DialogTitle className="text-lg">{t('title')}</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <ConnectionPicker
              appId={appId}
              control={control}
              register={register}
              connectionIdFieldName="connectionId"
              withCreateButton
            />
          </DialogBody>
          <DialogFooter>
            <Button variant="secondary" onClick={onClose}>
              {t('buttonCancel')}
            </Button>
            <Button type="submit" disabled={false} loading={isLoading}>
              {t('buttonCreate')}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateSyncModal;
