import type {CreateAirdropDataADto} from '@cohort/admin-schemas/airdrops';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';

export async function createAirdrop(merchantId: string, data: CreateAirdropDataADto): Promise<{}> {
  return apiCall('POST', `/v1/merchants/${merchantId}/airdrops`, {
    expect: HttpCodes.CREATED,
    parser: () => ({}),
    body: data,
  });
}
