import {CardWithRadientBackground} from '@cohort/merchants/components/CardWithRadientBackground';
import {useCurrentOrganization} from '@cohort/merchants/hooks/api/Organizations';
import {useOrganizationSlug} from '@cohort/merchants/hooks/useOrganizationSlug';
import {getSignInRoute} from '@cohort/merchants/lib/Pages';
import {getSigninSubdomainUrl} from '@cohort/merchants/lib/Utils';
import ForgotPasswordForm from '@cohort/merchants/pages/forgot-password/ForgotPasswordForm';
import React, {Fragment, useEffect} from 'react';
import {Navigate} from 'react-router-dom';

const ForgotPasswordPage: React.FC = () => {
  const organizationSlug = useOrganizationSlug();
  const {data: organization, error: organizationFetchError} = useCurrentOrganization({
    enabled: Boolean(organizationSlug),
  });

  useEffect(() => {
    if (organizationFetchError) {
      window.location.assign(getSigninSubdomainUrl());
    }
  }, [organizationFetchError]);

  // Password reset is only relevant when scoped to an organization
  if (organizationSlug === null) {
    return <Navigate to={getSignInRoute().path} replace />;
  }

  if (!organization) {
    // The organization is loading
    return <Fragment />;
  }

  return (
    <CardWithRadientBackground className="!w-[500px] space-y-0 p-6">
      <ForgotPasswordForm organization={organization} />
    </CardWithRadientBackground>
  );
};

export default ForgotPasswordPage;
