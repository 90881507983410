import type {PerkAccessOrderByADto} from '@cohort/admin-schemas/perkAccesses';
import type {PaginationFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import {useBasePageFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import type {PerkAccessStatus} from '@cohort/shared/schema/common/perkAccesses';

export const statusFilter = ['used', 'unused'] as const;
export const sortFilter = ['createdAt', '-createdAt'] as const;

export type PerkAccessesFilters = {
  status: Array<PerkAccessStatus>;
  setStatus: (status: Array<PerkAccessStatus>) => void;
  sort: PerkAccessOrderByADto;
  setSort: (sort: PerkAccessOrderByADto) => void;
  reset: () => void;
};

export const usePerkAccessesFilters = (
  pagePath: RegExp,
  defaultOrderBy: PerkAccessOrderByADto
): PaginationFilters & PerkAccessesFilters =>
  useBasePageFilters<PerkAccessesFilters>(pagePath, set => ({
    status: [],
    setStatus: status => {
      set({status, page: 1});
    },
    sort: defaultOrderBy,
    setSort: sort => set({sort}),
    reset: () => set({status: [], sort: defaultOrderBy}),
  }));
