/* eslint-disable @typescript-eslint/naming-convention */
import type {ConnectionADto} from '@cohort/admin-schemas/connection';
import type {ConnectionEditComponentProps} from '@cohort/merchants/apps';
import OAuthFlowHandler from '@cohort/merchants/components/connections/OAuthFlowHandler';
import ErrorState from '@cohort/merchants/components/ErrorState';
import Header from '@cohort/merchants/components/Header';
import {notify} from '@cohort/merchants/hooks/toast';
import type {AppId} from '@cohort/shared/apps';
import {getAppSpec} from '@cohort/shared/apps';
import type {CohortErrorCode} from '@cohort/shared/schema/common/errors';
import {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

export type CustomOAuthErrorMessage = (cause: CohortErrorCode) => string | undefined;

export type PreConnectionConfigComponentProps = {
  onConfigFinished: (config: Record<string, unknown>) => void;
};

export type PostConnectionConfigComponentProps = {
  connection: ConnectionADto;
  onConfigFinished: (connection: ConnectionADto) => void;
};

type OAuthEditConnectionComponentProps = ConnectionEditComponentProps & {
  appId: AppId;
  customOauthErrorMessage?: CustomOAuthErrorMessage;
  PreConnectionConfigComponent?: React.FC<PreConnectionConfigComponentProps>;
  PostConnectionConfigComponent?: React.FC<PostConnectionConfigComponentProps>;
};

const OAuthEditConnectionComponent: React.FC<OAuthEditConnectionComponentProps> = ({
  existingConnection,
  onClose,
  appId,
  customOauthErrorMessage,
  PreConnectionConfigComponent,
  PostConnectionConfigComponent,
}) => {
  const [connection, setConnection] = useState<ConnectionADto | undefined>(existingConnection);
  const [oauthError, setOauthError] = useState(false);
  const [preConfig, setPreConfig] = useState<Record<string, unknown> | undefined>(undefined);
  const appSpec = getAppSpec(appId);
  const needsPreConfig = PreConnectionConfigComponent && preConfig === undefined;

  const {t} = useTranslation('components', {
    keyPrefix: 'connections.oauthEditConnectionComponent',
  });

  useEffect(() => {
    if (connection?.status === 'ready') {
      notify('success', t('connectionSuccessful', {appName: appSpec.name}));
      onClose(connection);
    }
  }, [appId, appSpec, connection, onClose, t]);

  const handleOauthFlowCompleted = (connection: ConnectionADto | null): void => {
    if (connection) {
      setConnection(connection);
    } else {
      setOauthError(true);
    }
  };

  const content = match({connection, oauthError, needsPreConfig})
    .with({needsPreConfig: true}, () =>
      PreConnectionConfigComponent ? (
        <PreConnectionConfigComponent onConfigFinished={setPreConfig} />
      ) : (
        <Fragment />
      )
    )
    .with({connection: undefined}, {connection: {status: 'broken'}}, () => (
      <OAuthFlowHandler
        appSpec={appSpec}
        onCompleted={handleOauthFlowCompleted}
        existingConnectionId={connection?.id}
        preConfig={preConfig}
        customOauthErrorMessage={customOauthErrorMessage}
      />
    ))
    .with({oauthError: true}, () => <ErrorState />)
    .with({connection: {status: 'config_needed'}}, ({connection}) =>
      PostConnectionConfigComponent ? (
        <PostConnectionConfigComponent connection={connection} onConfigFinished={setConnection} />
      ) : (
        <Fragment />
      )
    )
    .otherwise(() => <Fragment />);

  return (
    <div className="flex flex-col items-center justify-center space-y-4 p-6">
      <Header title={t('title', {appName: appSpec.name})} />
      {content}
    </div>
  );
};

export default OAuthEditConnectionComponent;
