import type {CampaignADto} from '@cohort/admin-schemas/campaign';
import {Broadcast, Flag, Storefront} from '@phosphor-icons/react';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const TypeSectionContent: React.FC<{campaign: CampaignADto}> = ({campaign}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.review.typeSectionContent',
  });
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const logoAndName = match(campaign.type!)
    .with('store', () => (
      <Fragment>
        <div className="rounded-md bg-green-100 p-2">
          <Storefront className="h-6 w-6 text-green-500" />
        </div>
        {t('storeType')}
      </Fragment>
    ))
    .with('journey', () => (
      <Fragment>
        <div className="rounded-md bg-primary/10 p-2">
          <Flag className="h-6 w-6 text-primary" />
        </div>
        {t('journeyType')}
      </Fragment>
    ))
    .with('airdrop', () => (
      <Fragment>
        <div className="rounded-md p-2 [background-color:#E0F2FE]">
          <Broadcast className="h-6 w-6 [color:#0EA5E9]" />
        </div>
        {t('airdropType')}
      </Fragment>
    ))
    .exhaustive();

  return <div className="flex items-center gap-4 font-medium">{logoAndName}</div>;
};

export default TypeSectionContent;
