import type {DigitalAssetCollectionAnalyticsADto} from '@cohort/admin-schemas/digitalAssetCollection';
import PolygonButton from '@cohort/merchants/components/buttons/PolygonButton';
import DigitalAssetCollectionOwnersBadge from '@cohort/merchants/components/digital-assets/DigitalAssetCollectionOwnersBadge';
import {DigitalAssetCollectionStatusBadge} from '@cohort/merchants/components/digital-assets/DigitalAssetCollectionStatusBadge';
import {DigitalAssetVisual} from '@cohort/merchants/components/DigitalAssetVisual';
import Loader from '@cohort/merchants/components/Loader';
import {getDigitalAssetRoute} from '@cohort/merchants/lib/Pages';
import DigitalAssetDropdown from '@cohort/merchants/pages/digital-asset-collections/overview/DigitalAssetCollectionDropdown';
import {formatDate} from '@cohort/shared/utils/format';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

interface DigitalAssetCollectionCardProps {
  digitalAssetCollection: DigitalAssetCollectionAnalyticsADto;
}

const DigitalAssetCollectionCardHeader: React.FC<DigitalAssetCollectionCardProps> = ({
  digitalAssetCollection,
}) => (
  <section className="flex w-full justify-between">
    <div className="flex items-start gap-4">
      <DigitalAssetVisual
        digitalAssetImageFileKey={digitalAssetCollection.imageFileKey}
        animationFileKey={digitalAssetCollection.animationFileKey}
        className="rounded-md"
        size={80}
      />
      <DigitalAssetCollectionStatusBadge
        campaignsCount={digitalAssetCollection.campaignsCount}
        size="small"
      />
    </div>
    <DigitalAssetDropdown digitalAssetCollection={digitalAssetCollection} />
  </section>
);

const DigitalAssetCollectionCardContent: React.FC<DigitalAssetCollectionCardProps> = ({
  digitalAssetCollection,
}) => (
  <section className="mt-4">
    <h3 className="flex items-center gap-x-1 truncate text-lg font-semibold">
      {digitalAssetCollection.internalName}
      {digitalAssetCollection.nftEnabled && digitalAssetCollection.smartContract === null && (
        <Loader size={15} color="gray" />
      )}
      {digitalAssetCollection.nftEnabled &&
        digitalAssetCollection.smartContract !== null &&
        digitalAssetCollection.smartContract.blockExplorerUrl !== null && (
          <PolygonButton size={15} href={digitalAssetCollection.smartContract.blockExplorerUrl} />
        )}
    </h3>
    <div className="mt-2 flex items-center gap-2">
      <DigitalAssetCollectionOwnersBadge ownersCount={digitalAssetCollection.digitalAssetsCount} />
    </div>
  </section>
);

const DigitalAssetCollectionCard: React.FC<DigitalAssetCollectionCardProps> = ({
  digitalAssetCollection,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'digitalAssetCollections.overview.digitalAssetCollectionCard',
  });
  const navigate = useNavigate();
  const handleClick = (e: React.MouseEvent<HTMLLIElement>): void => {
    e.stopPropagation();
    navigate(getDigitalAssetRoute(digitalAssetCollection.id).path);
  };

  return (
    <li
      key={digitalAssetCollection.id}
      data-testid="digital-asset-collection-card"
      className="rounded-lg border transition duration-300 ease-in-out hover:cursor-pointer md:hover:shadow-md"
      onClick={handleClick}
    >
      <div className="p-4">
        <DigitalAssetCollectionCardHeader digitalAssetCollection={digitalAssetCollection} />
        <DigitalAssetCollectionCardContent digitalAssetCollection={digitalAssetCollection} />
        <div data-testid="last-edit" className="mt-2 text-xs text-slate-500">
          {t('labelLastEdit')} {formatDate(digitalAssetCollection.updatedAt)}
        </div>
      </div>
    </li>
  );
};

export default DigitalAssetCollectionCard;
