import {TriggerIntegrationIdSchema} from '@cohort/shared/apps';
import {LocalizedRichTextSchema, LocalizedStringSchema} from '@cohort/shared/schema/common';
import {JourneyStepOrchestrationSchema} from '@cohort/shared/schema/common/journeyStep';
import {z} from 'zod';

export const JourneyStepTriggerASchema = z.object({
  id: z.string().uuid(),
  title: LocalizedStringSchema,
  triggerIntegrationId: TriggerIntegrationIdSchema,
  triggerIntegrationConfig: z.unknown(),
  connectionId: z.string().uuid().nullable(),
  maxVerificationAttempts: z.number().int().min(1).nullable(),
  verificationAttemptsLeft: z.number().int().nullable(),
});
export type JourneyStepTriggerADto = z.infer<typeof JourneyStepTriggerASchema>;

export const JourneyStepASchema = z.object({
  id: z.string().uuid(),
  triggers: JourneyStepTriggerASchema.array(),
  title: LocalizedStringSchema,
  description: LocalizedRichTextSchema,
  icon: z.string().emoji(),
  orchestration: JourneyStepOrchestrationSchema,
});
export type JourneyStepADto = z.infer<typeof JourneyStepASchema>;

export const JourneyStepsResponseASchema = z.array(JourneyStepASchema);
export type JourneyStepsResponseADto = z.infer<typeof JourneyStepsResponseASchema>;

export const JourneyStepTriggerInputASchema = z.object({
  id: z.string().uuid().nullable(),
  triggerIntegrationId: TriggerIntegrationIdSchema,
  triggerIntegrationConfig: z.unknown(),
  connectionId: z.string().uuid().nullable(),
  maxVerificationAttempts: z.number().int().min(1).nullable(),
  title: LocalizedStringSchema.optional(),
});
export type JourneyStepTriggerInputADto = z.infer<typeof JourneyStepTriggerInputASchema>;

export const JourneyStepInputASchema = z.object({
  id: z.string().uuid().nullable(),
  triggers: JourneyStepTriggerInputASchema.array(),
  title: LocalizedStringSchema,
  description: LocalizedRichTextSchema,
  icon: z.string().emoji(),
  orchestration: JourneyStepOrchestrationSchema,
});
export type JourneyStepInputADto = z.infer<typeof JourneyStepInputASchema>;

export const UpdateJourneyStepsASchema = z.array(JourneyStepInputASchema);
export type UpdateJourneyStepsADto = z.infer<typeof UpdateJourneyStepsASchema>;
