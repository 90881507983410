import {cn} from '@cohort/shared-frontend/utils/classNames';

type CardListDisplayProps = {
  testId?: string;
  display: 'card' | 'list';
  children: React.ReactNode;
};

const CardListDisplay: React.FC<CardListDisplayProps> = ({testId, display, children}) => (
  <ul
    data-testid={testId}
    role="list"
    className={cn(
      'mb-6 grid',
      display === 'card' && 'gap-6 [grid-template-columns:repeat(auto-fill,minmax(350px,1fr))]',
      display === 'list' && 'grid-cols-1'
    )}
  >
    {children}
  </ul>
);

export default CardListDisplay;
