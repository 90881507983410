import type {AssetADto} from '@cohort/admin-schemas/asset';
import type {CampaignADto, PatchCampaignADto} from '@cohort/admin-schemas/campaign';
import {
  removeEmptyLanguages,
  removeUndefinedLanguages,
} from '@cohort/merchants/lib/form/localization';
import {CampaignRewardStepSchema} from '@cohort/merchants/pages/campaigns/campaign/edit/CampaignRewardStep';
import {CampaignTypeStepSchema} from '@cohort/merchants/pages/campaigns/campaign/edit/CampaignTypeStep';
import {BaseCampaignAirdropSettingsStepSchema} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/airdrop';
import {BaseCampaignJourneySettingsStepSchema} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/journey';
import {BaseCampaignStoreSettingsStepSchema} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/store';
import type {AssetKind} from '@cohort/shared/schema/common/assets';
import {CampaignAudienceSchema} from '@cohort/shared/schema/common/campaign';
import {isFile, isFileList} from '@cohort/shared-frontend/utils/isFile';
import type {UseMutateAsyncFunction} from '@tanstack/react-query';
import {z} from 'zod';

export const CampaignAudienceStepSchema = z
  .object({
    audience: CampaignAudienceSchema,
    cohortId: z.string().uuid().nullable(),
  })
  .superRefine(({audience, cohortId}, ctx) => {
    if (audience === 'cohort' && cohortId === null) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorRequired',
        path: ['cohortId'],
      });
    }
  });
export type CampaignAudienceStepValues = z.infer<typeof CampaignAudienceStepSchema>;

const BaseCampaignSettingsStepSchema = BaseCampaignAirdropSettingsStepSchema.merge(
  BaseCampaignStoreSettingsStepSchema
)
  .merge(BaseCampaignJourneySettingsStepSchema)
  .partial({
    airdrop: true,
    journey: true,
    store: true,
  });

export const CampaignSettingsStepSchema = BaseCampaignSettingsStepSchema.superRefine(
  ({campaignType, defaultLanguage}, ctx) => {
    if (campaignType === null) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorRequired',
        path: ['campaignType'],
      });
    }

    if (defaultLanguage === undefined) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorRequired',
        path: ['defaultLanguage'],
      });
    }
  }
);

export type CampaignSettingsStepValues = z.infer<typeof CampaignSettingsStepSchema>;

export const uploadCampaignAssets = async (
  formData: Partial<CampaignSettingsStepValues>,
  uploadMutation: UseMutateAsyncFunction<
    AssetADto | null,
    unknown,
    {file: File; assetKind: AssetKind},
    unknown
  >
): Promise<PatchCampaignADto> => {
  const updatedData = {
    ...formData,
    totalSupply: formData.withMaxSupply ? formData.totalSupply : null,
  } satisfies PatchCampaignADto;

  // TosFileKey
  if (formData.tosFileKey && isFile(formData.tosFileKey)) {
    const asset = await uploadMutation({
      file: formData.tosFileKey,
      assetKind: 'termsAndConditions',
    });

    if (asset) {
      updatedData.tosFileKey = asset.fileKey;
    }
  } else if (
    (!formData.tosFileKey && !isFile(formData.tosFileKey)) ||
    // This happens when canceling the file upload.
    (isFileList(formData.tosFileKey) && formData.tosFileKey.length === 0)
  ) {
    updatedData.tosFileKey = null; // Nullify if the file has been removed by the merchant.
  }

  // Faq
  if (updatedData.faqs !== undefined && updatedData.definedLanguages !== undefined) {
    updatedData.faqs = removeEmptyLanguages(updatedData.faqs);
    updatedData.faqs = removeUndefinedLanguages(updatedData.faqs, updatedData.definedLanguages);
  }

  return updatedData;
};

export const CampaignFormSteps = ['type', 'audience', 'reward', 'settings', 'review'] as const;

export type CampaignFormStep = (typeof CampaignFormSteps)[number];

type CampaignFormStepsValidators = Record<
  CampaignFormStep,
  z.ZodObject<z.ZodRawShape> | z.ZodEffects<z.ZodObject<z.ZodRawShape>>
>;

export const campaignFormStepsValidators: CampaignFormStepsValidators = {
  type: CampaignTypeStepSchema,
  audience: CampaignAudienceStepSchema,
  reward: CampaignRewardStepSchema,
  settings: BaseCampaignSettingsStepSchema,
  review: z.object({
    foo: z.string(),
  }),
};

export type FormStepProps = {
  isProcessing: boolean;
  setStepDirty?: (dirty: boolean) => void;
  setStepSaveBtn?: (btn: JSX.Element) => void;
  onStepValidated: () => void;
  updateCampaign: UseMutateAsyncFunction<CampaignADto | null, unknown, PatchCampaignADto, unknown>;
};

export type FormStepPropsWithUpload = {
  uploadMutation: UseMutateAsyncFunction<
    AssetADto | null,
    unknown,
    {file: File; assetKind: AssetKind},
    unknown
  >;
};
