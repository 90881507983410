import type {PaginationFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import {useBasePageFilters} from '@cohort/merchants/hooks/filters/basePageFilters';

export const sortFilter = ['createdAt', '-createdAt'] as const;

type AirdropParticipationsListFilters = {
  searchEmail: string;
  setSearchEmail: (searchEmail: string) => void;
};

export const useAirdropParticipationsListFilters = (): PaginationFilters &
  AirdropParticipationsListFilters =>
  useBasePageFilters<AirdropParticipationsListFilters>(
    /^(\/test)?\/campaigns\/.*\/activity$/u,
    set => ({
      searchEmail: '',
      setSearchEmail: searchEmail => set({searchEmail}),
    })
  );
