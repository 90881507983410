import type {FilterOption} from '@cohort/merchants/components/filters/Filters';
import {useSearchParams} from 'react-router-dom';

interface Output {
  searchParams: URLSearchParams;
  writeFiltersInUrl: (filterName: string, options: FilterOption[]) => void;
  patchSearchParams: (params: Record<string, string>) => void;
  deleteSearchParam: (params: string) => void;
}

export const useUrlParams = (): Output => {
  const [searchParams, setSearchParams] = useSearchParams();

  const patchSearchParams = (params: Record<string, string>): void =>
    setSearchParams({...Object.fromEntries([...searchParams]), ...params});

  const deleteSearchParam = (param: string): void => {
    const params = searchParams;

    params.delete(param);
    setSearchParams(params);
  };

  const writeFiltersInUrl = (filterName: string, options: FilterOption[]): void => {
    if (options.length > 0) {
      const filters: Record<string, string> = {};

      if (searchParams.get('page') !== null) {
        filters.page = '1';
      }
      filters[filterName] = options.map(o => o.value).join(',');
      patchSearchParams(filters);
    } else {
      deleteSearchParam(filterName);
    }
  };
  return {searchParams, writeFiltersInUrl, patchSearchParams, deleteSearchParam};
};
