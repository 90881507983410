import type {GetDigitalAssetCollectionsParamsADto} from '@cohort/admin-schemas/digitalAssetCollection';
import SingleChoiceFilter from '@cohort/merchants/components/filters/SingleChoiceFilter';
import type {DigitalAssetCollectionsListFilters} from '@cohort/merchants/hooks/filters/digitalAssetCollectionsListFilters';
import {sortFilter} from '@cohort/merchants/hooks/filters/digitalAssetCollectionsListFilters';
import {trackDigitalAssetCollectionsSortingChanged} from '@cohort/merchants/lib/Tracking';
import {useTranslation} from 'react-i18next';

type DigitalAssetCollectionsSortByProps = {
  filters: DigitalAssetCollectionsListFilters;
};

const DigitalAssetCollectionsSortBy: React.FC<DigitalAssetCollectionsSortByProps> = ({filters}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'digitalAssets.digitalAssetCollectionsSortBy',
  });

  // i18nOwl-ignore [sortBy.internalName, sortBy.-updatedAt]
  const sortingOptions = sortFilter.map(sort => ({
    value: sort,
    label: t(`sortBy.${sort}`),
    active: filters.sort === sort,
  }));

  return (
    <SingleChoiceFilter
      options={sortingOptions}
      onChange={option => {
        trackDigitalAssetCollectionsSortingChanged(option.value);
        filters.setSort(option.value as GetDigitalAssetCollectionsParamsADto['orderBy']);
      }}
      prefix={t('title')}
    />
  );
};
export default DigitalAssetCollectionsSortBy;
