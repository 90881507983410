import type {
  ResourceListDataType,
  UserPropertyDataType,
} from '@cohort/shared/schema/common/userProperty';
import {formatDate} from '@cohort/shared/utils/format';
import Tooltip from '@cohort/shared-frontend/components/Tooltip';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {match} from 'ts-pattern';

const TAG_LIST_MAX_CHARACTERS_DISPLAYED = 45;

export type TagsListDisplayMode = 'badges' | 'list';

type TagListProps = {
  tags: Array<string>;
  displayMode?: TagsListDisplayMode;
};
const TagList: React.FC<TagListProps> = ({tags, displayMode = 'badges'}) => {
  let currentCaractersCount = 0;
  const displayedTags = [];
  const hiddenTags = [];

  for (const tag of tags) {
    // space between tags is considered as 4 characters wide
    currentCaractersCount += tag.length + 4;
    if (currentCaractersCount < TAG_LIST_MAX_CHARACTERS_DISPLAYED) {
      displayedTags.push(tag);
    } else {
      hiddenTags.push(tag);
    }
  }

  return (
    <div className="flex flex-wrap">
      {displayMode === 'badges' ? (
        displayedTags.map(tag => (
          <p key={tag} className="m-1 rounded-sm bg-slate-50 px-1.5 py-1 text-sm text-slate-700">
            {tag}
          </p>
        ))
      ) : (
        <div className="text-sm text-slate-700">{displayedTags.join(', ')}</div>
      )}
      {hiddenTags.length > 0 && (
        <Tooltip
          content={
            <div className="flex flex-col">
              {hiddenTags.map(tag => (
                <p key={tag} className="text-sm text-slate-700">
                  {tag}
                </p>
              ))}
            </div>
          }
        >
          <div
            className={cn(
              displayMode === 'badges'
                ? 'm-1 rounded-sm bg-slate-50 px-2 py-1'
                : 'ml-1.5 text-sm text-slate-700'
            )}
          >
            <p>+ {hiddenTags.length}</p>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

type UserAttributeCellProps = {
  dataType: UserPropertyDataType;
  value: unknown;
  className?: string;
  tagsListDisplayMode?: TagsListDisplayMode;
};

const UserAttributeCell: React.FC<UserAttributeCellProps> = ({
  dataType,
  value,
  className,
  tagsListDisplayMode = 'badges',
}) => {
  if (
    value === undefined ||
    value === null ||
    value === '' ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return <p className="text-slate-500">-</p>;
  }

  return match(dataType)
    .with('date', () => (
      <p className={cn('line-clamp-2', className)}>{formatDate(value as Date)}</p>
    ))
    .with('resource_list', () => (
      <TagList
        tags={(value as ResourceListDataType).map(value => value.name)}
        displayMode={tagsListDisplayMode}
      />
    ))
    .with('string_list', () => (
      <TagList tags={value as Array<string>} displayMode={tagsListDisplayMode} />
    ))
    .otherwise(() => <p className={cn('line-clamp-2', className)}>{String(value)}</p>);
};

export default UserAttributeCell;
