import {cn} from '@cohort/shared-frontend/utils/classNames';
import {PencilSimple} from '@phosphor-icons/react';
import {useState} from 'react';

interface EditabletextProps {
  value: string;
  textSize?: 'small' | 'normal';
  onChange: (value: string) => void;
  validator?: (value: string) => boolean;
}

const Editabletext: React.FC<EditabletextProps> = ({
  value,
  onChange,
  validator,
  textSize = 'normal',
}) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [editMode, setEditMode] = useState(false);
  const [inputWidth, setInputWidth] = useState<number>(200);
  const [error, setError] = useState<boolean>(false);

  const handleEdit = (spanWidth: number): void => {
    setEditMode(true);
    setInputWidth(spanWidth);
  };

  const handleSubmit = (): void => {
    setEditMode(false);
    if (currentValue !== value && !error) {
      onChange(currentValue);
    }
    if (error) {
      setError(false);
      setCurrentValue(value);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setCurrentValue(e.target.value);
    if (validator !== undefined && !validator(e.target.value)) {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <div className="group relative cursor-text text-foreground">
      {editMode ? (
        <input
          type="text"
          name="editableCell"
          autoFocus
          style={{width: `${inputWidth}px`}}
          className={cn(
            'max-w-xs overflow-hidden text-ellipsis rounded-md border-0 p-2 pr-8 focus:ring-2 lg:max-w-lg',
            error ? 'focus:ring-red-500' : 'focus:ring-primary',
            textSize === 'small' ? 'text-sm' : 'text-base font-semibold'
          )}
          value={currentValue}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
          onFocus={e => e.currentTarget.select()}
          onChange={handleChange}
          onBlur={handleSubmit}
        />
      ) : (
        <button
          onClick={e => handleEdit(e.currentTarget.scrollWidth)}
          className={cn(
            'block min-h-[36px] min-w-[100px] max-w-xs overflow-hidden text-ellipsis whitespace-nowrap rounded-md p-2 pr-8 hover:bg-slate-100 group-hover:text-slate-700 lg:max-w-lg',
            textSize === 'small' ? 'text-sm' : 'text-base font-semibold'
          )}
        >
          {currentValue}
          <PencilSimple
            size={18}
            className="absolute right-2 top-3 text-slate-400 group-hover:text-slate-500"
          />
        </button>
      )}
    </div>
  );
};

export default Editabletext;
