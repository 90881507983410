import {activateOrganizationMember} from '@cohort/merchants/lib/api/OrganizationMembers';
import {getSignInRoute} from '@cohort/merchants/lib/Pages';
import {
  trackInvitationActivationError,
  trackInvitationActivationSuccess,
  trackInvitationPageViewed,
} from '@cohort/merchants/lib/Tracking';
import {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

const message = (msg: string): JSX.Element => {
  return <div className="text-xl font-bold">{msg}</div>;
};

const InvitationPage = (): JSX.Element => {
  const [status, setStatus] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const [organizationId, organizationMemberId, invitationCode] = [
      'organizationId',
      'organizationMemberId',
      'invitationCode',
    ].map(key => searchParams.get(key)?.trim());

    if (
      organizationId === undefined ||
      organizationMemberId === undefined ||
      invitationCode === undefined
    ) {
      navigate(getSignInRoute().path, {replace: true});
      return;
    }
    trackInvitationPageViewed();

    activateOrganizationMember(organizationMemberId, invitationCode)
      .then(async ({link}) => {
        setStatus('success');
        trackInvitationActivationSuccess();
        const url = new URL(link);
        navigate(
          {
            pathname: url.pathname,
            search: url.search,
          },
          {replace: true}
        );
      })
      .catch((err: Error) => {
        if (err.message.endsWith('invitation_expired')) {
          setStatus('expired');
          trackInvitationActivationError({errorReason: 'invitation_expired'});
        } else {
          setStatus('error');
          trackInvitationActivationError({errorReason: 'unknown'});
        }
      });
  }, [searchParams, navigate]);

  return (
    <div className="bg-creamlight flex h-screen w-screen flex-col items-center justify-center">
      {status === 'expired' && message('Your invitation has expired, please request a new one.')}
      {status === 'error' && message('Oops, we could not activate your membership.')}
    </div>
  );
};

export default InvitationPage;
