import type {JourneyStepInputADto} from '@cohort/admin-schemas/journeySteps';
import PreviewFaqs from '@cohort/components-xps/components/CampaignFaqs';
import type {AboutJourneyType} from '@cohort/components-xps/components/journeys/AboutJourney';
import AboutJourney from '@cohort/components-xps/components/journeys/AboutJourney';
import {JourneySteps} from '@cohort/components-xps/components/journeys/JourneySteps';
import RewardList from '@cohort/components-xps/components/rewards/RewardList';
import Title from '@cohort/components-xps/components/Title';
import PreviewLayout from '@cohort/merchants/components/campaigns/preview/PreviewLayout';
import PreviewPrimaryButton from '@cohort/merchants/components/campaigns/preview/PreviewPrimaryButton';
import {useCustomComponents} from '@cohort/merchants/hooks/api/CustomComponents';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import usePreviewStyle from '@cohort/merchants/hooks/previewStyle';
import {getCustomComponent, getUserContext} from '@cohort/merchants/lib/CustomComponents';
import type {Language} from '@cohort/shared/schema/common';
import type {FaqItem} from '@cohort/shared/schema/common/campaign';
import {isFutureDate} from '@cohort/shared/utils/date';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import type {JourneyStepWDto} from '@cohort/wallet-schemas/journeySteps';
import {useTranslation} from 'react-i18next';

export type CampaignJourneyPreviewValues = {
  accentColorCode: string;
  backgroundColorCode: string;
  animationFileKey: string | File | null | undefined;
  imageFileKey: string | File | null | undefined;
  title: string | null;
  description: string | null;
  faqs: FaqItem[];
  campaignTotalSupply: number | null;
  supportEmail: string | null;
  optInCta: string | null;
  steps: Array<JourneyStepInputADto>;
  selectedLanguage: Language;
};

type CampaignJourneyPreviewProps = {
  previewValues: CampaignJourneyPreviewValues;
  className?: string;
  isMobile?: boolean;
  inPreviewModal?: boolean;
};

const CampaignJourneyPreview: React.FC<CampaignJourneyPreviewProps> = ({
  previewValues,
  className,
  isMobile = false,
  inPreviewModal,
}) => {
  const {
    animationFileKey,
    backgroundColorCode,
    accentColorCode,
    imageFileKey,
    title,
    campaignTotalSupply,
    description,
    faqs,
    supportEmail,
    optInCta,
    steps,
    selectedLanguage,
  } = previewValues;
  const merchant = useCurrentMerchant();
  const campaign = useCurrentCampaign();
  const {t} = useTranslation('components', {
    keyPrefix: 'campaigns.journeys.campaignJourneyPreview',
  });
  const {data: components} = useCustomComponents(merchant.id);
  const perkCustomComponent = getCustomComponent(components, 'perk', true);
  const digitalAssetCustomComponent = getCustomComponent(components, 'digital-asset', true);

  usePreviewStyle(backgroundColorCode, accentColorCode);

  const collections = campaign.rewardBundle.collections
    .map(collectionItem => collectionItem.collection)
    .map(collection => ({
      id: collection.id,
      title: collection.title[selectedLanguage] ?? '',
      description: collection.description[selectedLanguage] ?? '',
      smartContract: collection.smartContract,
      imageKey: collection.imageFileKey,
      animationKey: collection.animationFileKey,
      maxOwnershipsPerUser: collection.maxOwnershipsPerUser,
    }));

  const perks = campaign.rewardBundle.perks
    .map(perkItems => perkItems.perk)
    .map(perk => ({
      id: perk.id,
      description: perk.description[selectedLanguage] ?? '',
      displayName: perk.displayName[selectedLanguage] ?? '',
      videoFileKey: perk.videoFileKey,
      thumbnailFileKey: perk.thumbnailFileKey,
      bannerFileKey: perk.bannerFileKey,
      maxAccessesPerUser: perk.maxAccessesPerUser,
      integration: perk.integration,
      type: perk.type,
    }));

  const aboutJourney = {
    animationFileKey: animationFileKey ?? null,
    backgroundColor: backgroundColorCode,
    imageFileKey: imageFileKey ?? null,
    title: title ?? t('defaultTitle', {merchantName: merchant.name}),
    description: description ?? t('defaultDescription'),
    availableSupply: campaignTotalSupply,
    isCampaignEnded: campaign.status === 'ended',
  } satisfies AboutJourneyType;

  const previewSteps: JourneyStepWDto[] = steps.map((step, idx) => ({
    id: step.id ?? `step-${idx}`,
    icon: step.icon,
    title: step.title[selectedLanguage] ?? step.title[merchant.defaultLanguage] ?? '',
    description:
      step.description[selectedLanguage] ?? step.description[merchant.defaultLanguage] ?? '',
    triggers: step.triggers.map((trigger, index) => ({
      ...trigger,
      id: trigger.id ?? `step-${idx}-trigger-${index}`,
      title: trigger.title
        ? trigger.title[selectedLanguage] ?? trigger.title[merchant.defaultLanguage] ?? ''
        : '',
      verificationAttemptsLeft: null,
    })),
    isCompleted: false,
    availability:
      step.orchestration.startDate && isFutureDate(step.orchestration.startDate)
        ? {locked: true, reason: 'start-date'}
        : {locked: false},
    orchestration: step.orchestration,
  }));
  const customComponentContext = {
    user: getUserContext(merchant),
    lang: selectedLanguage,
    isMobile,
  };

  const leftPanelContent = (
    <div className={cn(isMobile ? 'p-4' : 'p-8')}>
      <div className="space-y-6">
        <AboutJourney aboutJourney={aboutJourney}>
          <PreviewPrimaryButton
            accentColorCode={accentColorCode}
            text={optInCta ?? t('defaultOptInCta')}
          />
        </AboutJourney>

        {isMobile && (
          <div className="space-y-6">
            {previewSteps.length > 0 && (
              <section className="space-y-4">
                <Title content={t('stepsTitle')} />
                <JourneySteps.StepsList>
                  {previewSteps.map(step => (
                    <JourneySteps.StepCard
                      key={step.id}
                      step={step}
                      isMobile={isMobile}
                      isCampaignEnded={campaign.status === 'ended'}
                    />
                  ))}
                </JourneySteps.StepsList>
              </section>
            )}
            <section className="space-y-4">
              <Title content={t('rewardsTitle')} />
              <RewardList
                perks={perks}
                collections={collections}
                isMobile
                customComponent={{
                  perk: perkCustomComponent?.template ?? undefined,
                  digitalAsset: digitalAssetCustomComponent?.template ?? undefined,
                  context: customComponentContext,
                }}
              />
            </section>

            <div className="space-y-2">
              <Title content={t('fasqTitle')} />
              <PreviewFaqs faqs={faqs} supportEmail={supportEmail} />
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const rightPanelContent = (
    <div className="space-y-6">
      {previewSteps.length > 0 && (
        <section className="space-y-4">
          <Title content={t('stepsTitle')} />
          <JourneySteps.StepsList>
            {previewSteps.map(step => (
              <JourneySteps.StepCard
                key={step.id}
                step={step}
                isMobile={isMobile}
                isCampaignEnded={campaign.status === 'ended'}
              />
            ))}
          </JourneySteps.StepsList>
        </section>
      )}
      <section className="space-y-4">
        <Title content={t('rewardsTitle')} />
        <RewardList
          perks={perks}
          collections={collections}
          customComponent={{
            perk: perkCustomComponent?.template ?? undefined,
            digitalAsset: digitalAssetCustomComponent?.template ?? undefined,
            context: customComponentContext,
          }}
        />
      </section>

      <section className="space-y-2">
        <Title content={t('fasqTitle')} />
        <PreviewFaqs faqs={faqs} supportEmail={supportEmail} />
      </section>
    </div>
  );

  return (
    <PreviewLayout
      isMobile={isMobile}
      leftPanelContent={leftPanelContent}
      rightPanelContent={rightPanelContent}
      className={className}
      inPreviewModal={inPreviewModal}
    />
  );
};

export default CampaignJourneyPreview;
