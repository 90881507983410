import {EmailSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

// Send OTP
export const SendSigninOtpASchema = z.object({
  email: EmailSchema,
});
export type SendSigninOtpADto = z.infer<typeof SendSigninOtpASchema>;

// Verify OTP
export const VerifySigninOtpASchema = z.object({
  email: EmailSchema,
  code: z.string(),
});
export type VerifySigninOtpADto = z.infer<typeof VerifySigninOtpASchema>;

export const VerifySigninOtpResponseASchema = z.object({
  token: z.string(),
});
export type VerifySigninOtpResponseADto = z.infer<typeof VerifySigninOtpResponseASchema>;

// Change password
export const RequestChangePasswordASchema = z.object({
  email: EmailSchema,
});
export type RequestChangePasswordADto = z.infer<typeof RequestChangePasswordASchema>;

export const PasswordRequirements = ['length', 'number', 'uppercase', 'special'] as const;

export const PasswordSchema = z.string().superRefine((password, ctx) => {
  const errors = [];

  if (password.length < 12) {
    errors.push('length');
  }

  if (!/\d/u.test(password)) {
    errors.push('number');
  }

  if (!/[A-Z]/u.test(password)) {
    errors.push('uppercase');
  }

  if (!/[!@#$%^|.&*?<>_[\]{}(),":;~]/u.test(password)) {
    errors.push('special');
  }

  if (errors.length > 0) {
    return ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: errors.join(','),
    });
  }
});

export const ChangePasswordASchema = z.object({
  email: EmailSchema,
  code: z.string(),
  password: PasswordSchema,
});
export type ChangePasswordADto = z.infer<typeof ChangePasswordASchema>;
