import CampaignTypeBadge from '@cohort/merchants/components/campaigns/CampaignTypeBadge';
import {IconBadge} from '@cohort/merchants/components/IconBadge';
import {getCampaignRoute} from '@cohort/merchants/lib/Pages';
import type {OwnershipObtentionType} from '@cohort/shared/schema/common/ownerships';
import type {PerkAccessObtentionType} from '@cohort/shared/schema/common/perkAccesses';
import {ArrowCircleRight, PaperPlaneRight} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {match} from 'ts-pattern';

type ObtentionTypeBadgeProps = {
  type: OwnershipObtentionType | PerkAccessObtentionType;
  campaign: {
    id: string;
    internalName: string;
  } | null;
};

const ObtentionTypeBadge: React.FC<ObtentionTypeBadgeProps> = ({type, campaign}) => {
  const navigate = useNavigate();
  const {t} = useTranslation('components', {
    keyPrefix: 'obtentionTypeBadge',
  });

  const badge = match(type)
    .with('order', () => <CampaignTypeBadge type="store" size="small" />)
    .with('journey', () => <CampaignTypeBadge type="journey" size="small" />)
    .with('airdrop', () =>
      campaign !== null ? (
        <CampaignTypeBadge type="airdrop" size="small" />
      ) : (
        <IconBadge
          icon={<PaperPlaneRight size={16} className="mr-1 -rotate-90 text-primary" />}
          text={t('airdropType')}
          size="small"
        />
      )
    )
    .with('transfer', () => (
      <IconBadge
        icon={<ArrowCircleRight size={16} className="mr-1 text-yellow-500" />}
        text={t('transferType')}
        size="small"
      />
    ))
    .exhaustive();

  return (
    <div className="flex items-center gap-4 truncate">
      {badge}
      {campaign !== null && (
        <h3
          onClick={e => {
            e.stopPropagation();
            navigate(getCampaignRoute(campaign.id).path);
          }}
          className="cursor-pointer truncate text-xs font-medium text-gray-500"
        >
          {campaign.internalName}
        </h3>
      )}
    </div>
  );
};
export default ObtentionTypeBadge;
