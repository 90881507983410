import type {UserPropertyADto} from '@cohort/admin-schemas/userProperty';
import {findCsvEmailHeader} from '@cohort/merchants/lib/form/utils';
import type {
  UserPropertyDataType,
  UserPropertyValue,
} from '@cohort/shared/schema/common/userProperty';
import {match} from 'ts-pattern';
import {z} from 'zod';

export type UserPropertyOption = {
  value: UserPropertyADto;
  label: JSX.Element;
  isDisabled?: boolean;
};

export type ImportUsersModalUserAttributeData =
  | {
      userPropertyId: string;
      value: UserPropertyValue | null;
    }[]
  | undefined;
export type ImportUsersModalData = {
  email: string;
  userAttributes?: ImportUsersModalUserAttributeData;
}[];

export const parseAndValidateDataType = (
  value: string | undefined,
  dataType: UserPropertyDataType
): UserPropertyValue | undefined => {
  if (value === undefined || value.trim() === '') return undefined;

  return match(dataType)
    .with('string', () => value)
    .with('number', () => {
      const number = parseFloat(value);
      return !isNaN(number) ? number : undefined;
    })
    .with('boolean', () => {
      const booleanValue = value.trim().toLowerCase();
      return booleanValue === 'true' ? true : booleanValue === 'false' ? false : undefined;
    })
    .with('date', () => {
      const date = new Date(value);
      return !isNaN(date.getTime()) ? date : undefined;
    })
    .with('string_list', () => {
      try {
        const parsedJson = JSON.parse(value);
        const parsedStringList = z.array(z.string()).parse(parsedJson);
        return parsedStringList;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        return undefined;
      }
    })
    .with('resource_list', () => {
      // resource_list cannot be imported from CSV
      return undefined;
    })
    .exhaustive();
};

export const prepareImportUsersPayload = (
  csvUsers: Record<string, string | undefined>[],
  csvHeaders: string[],
  mappedProperties: Array<UserPropertyADto | undefined> | undefined
): ImportUsersModalData | undefined => {
  if (mappedProperties === undefined) {
    mappedProperties = [];
  }

  const emailHeader = findCsvEmailHeader(csvHeaders, csvUsers);
  const userAttributes = mappedProperties
    .map((userProperty, index) => {
      return {index, userProperty};
    })
    .filter(({userProperty}) => userProperty !== undefined);

  if (csvUsers.length > 0 && emailHeader != null) {
    const users = csvUsers.map(user => {
      const attributes: ImportUsersModalUserAttributeData = userAttributes.reduce(
        (acc, {index, userProperty}) => {
          if (userProperty === undefined) {
            return acc;
          }
          const header = csvHeaders[index];
          const stringValue = header !== undefined ? user[header] : undefined;
          const convertedValue = parseAndValidateDataType(stringValue, userProperty.dataType);
          if (convertedValue !== undefined) {
            acc.push({userPropertyId: userProperty.id, value: convertedValue});
          }
          return acc;
        },
        [] as NonNullable<ImportUsersModalUserAttributeData>
      );

      return {
        email: user[emailHeader] as string,
        userAttributes: attributes,
      };
    }) satisfies ImportUsersModalData;

    return users;
  }
};
