import type {CompletionStep} from '@cohort/merchants/components/form/CompletionBreadcrumb';
import type {Dispatch} from 'react';
import {useReducer} from 'react';

export type FormState<T extends string> = {
  step: T;
  steps: Array<CompletionStep<T>>;
  stepSaveBtn?: JSX.Element;
};

type GoTo<T extends string> = {
  type: 'GO_TO';
  payload: T;
};

type JumpTo<T extends string> = {
  type: 'JUMP_TO';
  payload: T;
};

type SetStepSaveBtn = {
  type: 'SET_STEP_SAVE_BTN';
  payload: JSX.Element;
};

type SetStepDirty = {
  type: 'SET_STEP_DIRTY';
  payload: boolean;
};

type FormStateAction<T extends string> = GoTo<T> | JumpTo<T> | SetStepSaveBtn | SetStepDirty;

function formStateReducer<T extends string>(
  state: FormState<T>,
  {type, payload}: FormStateAction<T>
): FormState<T> {
  switch (type) {
    case 'JUMP_TO':
    case 'GO_TO': {
      const currentStepIdx = state.steps.findIndex(step => step.id === state.step);
      const nextStepIdx = state.steps.findIndex(step => step.id === payload);

      if (currentStepIdx === -1) {
        throw new Error(`Step ${state.step} not found`);
      }

      if (nextStepIdx === -1) {
        throw new Error(`Step ${payload} not found`);
      }
      // Because we are accessing through index, Typescript is not smart enough to be sure it exists
      const currentStep = {...state.steps[currentStepIdx]} as CompletionStep<T>;
      const nextStep = {...state.steps[nextStepIdx]} as CompletionStep<T>;
      const steps = [...state.steps];

      // GO_TO is called when the step form is validated
      if (type === 'GO_TO') {
        currentStep.isCompleted = true;
      }
      currentStep.isCurrent = false;
      currentStep.isDirty = false;
      currentStep.visited = true;
      nextStep.isCurrent = true;

      steps[currentStepIdx] = currentStep;
      steps[nextStepIdx] = nextStep;
      return {
        ...state,
        step: payload,
        steps,
      };
    }
    case 'SET_STEP_SAVE_BTN':
      return {
        ...state,
        stepSaveBtn: payload,
      };
    case 'SET_STEP_DIRTY': {
      const currentStepIdx = state.steps.findIndex(step => step.id === state.step);
      const currentStep = {...state.steps[currentStepIdx]} as CompletionStep<T>;
      const steps = [...state.steps];

      currentStep.isDirty = payload;
      steps[currentStepIdx] = currentStep;
      return {
        ...state,
        steps,
      };
    }
    default:
      throw new Error();
  }
}

export default function useStepForm<T extends string>(
  defaultStep: T,
  initFunction: () => FormState<T>
): readonly [FormState<T>, Dispatch<FormStateAction<T>>] {
  const [state, dispatch] = useReducer(
    formStateReducer,
    {
      step: defaultStep,
      steps: [],
    } satisfies FormState<T>,
    initFunction
  );

  return [state, dispatch] as [FormState<T>, Dispatch<FormStateAction<T>>];
}
