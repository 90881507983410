import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Popover, Transition} from '@headlessui/react';
import {DotsThreeVertical} from '@phosphor-icons/react';

interface DropdownEntries {
  id: string;
  label: string | JSX.Element;
  icon: JSX.Element;
  handleOnClick: () => void;
  hidden?: boolean;
  disabled?: boolean;
  testId?: string;
}

interface DropdownButtonProps {
  className?: string;
  entries: DropdownEntries[];
  handleClick?: () => void;
}

const DropdownEntry: React.FC<DropdownEntries> = ({
  label,
  icon,
  disabled = false,
  handleOnClick,
  testId,
}) => (
  <button
    data-testid={testId}
    className={cn(
      'flex w-full items-center gap-4 p-2 pl-4 text-left text-sm text-gray-700 hover:bg-slate-100',
      disabled && 'cursor-not-allowed opacity-50'
    )}
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();
      handleOnClick();
    }}
    disabled={disabled}
  >
    {icon}
    <p>{label}</p>
  </button>
);

const DropdownButton: React.FC<DropdownButtonProps> = ({entries, className, handleClick}) => (
  <Popover className={cn('relative', className)}>
    <Popover.Button
      data-testid="dropdown-button"
      className="flex items-center rounded-md border bg-white p-2 text-slate-400 shadow-sm [height:36px] [width:36px]"
      onClick={handleClick}
    >
      <DotsThreeVertical size={20} />
    </Popover.Button>
    <Transition
      className="relative z-30"
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Popover.Panel
        className="absolute right-0 mt-2 rounded-md border bg-white shadow-md [min-width:200px]"
        as="ul"
      >
        {entries
          .filter(entry => entry.hidden !== true)
          .map(entry => (
            <DropdownEntry key={entry.id} {...entry} testId={entry.id} />
          ))}
      </Popover.Panel>
    </Transition>
  </Popover>
);

export default DropdownButton;
