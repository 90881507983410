import {AppIdSchema} from '@cohort/shared/apps';
import {asArray, BooleanQueryStringSchema, DateSchema} from '@cohort/shared/schema/common';
import {PaginationParamsSchema} from '@cohort/shared/schema/common/pagination';
import {
  UserPropertyDataTypeSchema,
  UserPropertyNameSchema,
} from '@cohort/shared/schema/common/userProperty';
import {z} from 'zod';

export const UserPropertyASchema = z.object({
  id: z.string().uuid(),
  appId: AppIdSchema.nullable(),
  name: UserPropertyNameSchema,
  referenceId: z.string(),
  merchantConnectionId: z.string().nullable(),
  dataType: UserPropertyDataTypeSchema,
  createdAt: DateSchema,
  updatedAt: DateSchema,
  visible: z.boolean(),
});
export type UserPropertyADto = z.infer<typeof UserPropertyASchema>;

// Create User Property
export const CreateUserPropertyDataASchema = z.object({
  name: UserPropertyNameSchema,
  dataType: UserPropertyDataTypeSchema.refine(
    value => value !== 'resource_list',
    'resource_list is not allowed'
  ),
  referenceId: z
    .string()
    .optional()
    .transform(value => (value === '' ? undefined : value)),
});
export type CreateUserPropertyDataADto = z.infer<typeof CreateUserPropertyDataASchema>;

// Update User Property
export const UpdateUserPropertyDataASchema = z
  .object({
    name: UserPropertyNameSchema,
    referenceId: z.string(),
    visible: z.boolean(),
  })
  .partial();
export type UpdateUserPropertyDataADto = z.infer<typeof UpdateUserPropertyDataASchema>;

export const UpdateUserPropertiesDataASchema = z.array(
  UpdateUserPropertyDataASchema.extend({
    id: z.string().uuid(),
  })
);
export type UpdateUserPropertiesDataADto = z.infer<typeof UpdateUserPropertiesDataASchema>;

// List User Properties
export const ListUserPropertiesOrderByOptionsSchema = z
  .enum(['createdAt', '-createdAt', 'name', '-name', 'dataType', '-dataType'])
  .default('-name');

export type ListUserPropertiesOrderByOptions = z.infer<
  typeof ListUserPropertiesOrderByOptionsSchema
>;

export const ListUserPropertiesParamsASchema = PaginationParamsSchema.extend({
  search: z.string().min(1).optional(),
  dataTypes: z.preprocess(asArray, z.array(UserPropertyDataTypeSchema)).optional(),
  appId: AppIdSchema.optional(),
  hasAppId: BooleanQueryStringSchema.optional(),
  visible: BooleanQueryStringSchema.optional(),
  excludedReferenceIds: z.preprocess(asArray, z.array(z.string())).optional(),
  orderBy: ListUserPropertiesOrderByOptionsSchema,
});
export type ListUserPropertiesParamsADto = z.infer<typeof ListUserPropertiesParamsASchema>;

export const CheckReferenceIdAvailabilityResponseASchema = z.object({
  available: z.boolean(),
});
export type CheckReferenceIdAvailabilityResponseADto = z.infer<
  typeof CheckReferenceIdAvailabilityResponseASchema
>;

// Check Reference Id Availability
export const GetReferenceIdAvailabilityParamsASchema = z.object({
  referenceId: z.string().min(1),
  appId: AppIdSchema.optional(),
});
export type GetReferenceIdAvailabilityParamsADto = z.infer<
  typeof GetReferenceIdAvailabilityParamsASchema
>;
