import type {PatchBadgeADto} from '@cohort/admin-schemas/badge';
import AdminPage from '@cohort/merchants/components/AdminPage';
import SubmitFooter from '@cohort/merchants/components/form/SubmitFooter';
import {badgesKeys} from '@cohort/merchants/hooks/api/Badges';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentBadge} from '@cohort/merchants/hooks/contexts/currentBadge';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useBadgePageStore} from '@cohort/merchants/hooks/stores/badgePage';
import {updateBadge} from '@cohort/merchants/lib/api/Badges';
import {uploadAsset} from '@cohort/merchants/lib/form/utils';
import {getBadgeCohortRoute} from '@cohort/merchants/lib/Pages';
import BadgeAppearanceStep from '@cohort/merchants/pages/users/badges/badge/edit/BadgeAppearanceStep';
import {notifyError} from '@cohort/merchants/stores/ErrorModalStore';
import type {AssetKind} from '@cohort/shared/schema/common/assets';
import {useQueryClient} from '@tanstack/react-query';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const BadgeAppearancePage: React.FC = () => {
  const merchant = useCurrentMerchant();
  const badge = useCurrentBadge();
  const queryClient = useQueryClient();
  const {t} = useTranslation('pages', {keyPrefix: 'users.badges.badge.appearance.page'});
  const setFooter = useBadgePageStore(state => state.setFooter);
  const navigate = useNavigate();

  const {isLoading: isUploadingFile, mutateAsync: uploadFileMutation} = useCohortMutation({
    mutationFn: async ({file, assetKind}: {file: File; assetKind: AssetKind}) =>
      uploadAsset(file, assetKind, merchant.id),
    onError: err => notifyError(err, t('errorFileUploadFailed')),
  });

  const {isLoading: isUpdatingBadge, mutateAsync: updateBadgeMutation} = useCohortMutation({
    mutationFn: async (data: PatchBadgeADto) => updateBadge(merchant.id, badge.id, data),
    notifyErrorMessage: t('notificationErrorUpdate'),
    notifySuccessMessage: t('notificationUpdateSuccess'),
  });

  useEffect(() => {
    setFooter(
      <SubmitFooter
        formName="badge-appearance-step"
        loading={isUpdatingBadge || isUploadingFile}
        submitLabel={t('saveBtn')}
      />
    );
    return () => setFooter(null);
  }, [setFooter, t, isUpdatingBadge, isUploadingFile]);

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
  };

  return (
    <AdminPage header={headerConfig}>
      <BadgeAppearanceStep
        isProcessing={isUpdatingBadge || isUploadingFile}
        onStepValidated={() => {
          queryClient.invalidateQueries(badgesKeys.getById(merchant.id, badge.id));
          navigate(getBadgeCohortRoute(badge.id).path);
        }}
        updateBadgeMutation={updateBadgeMutation}
        uploadMutation={uploadFileMutation}
      />
    </AdminPage>
  );
};

export default BadgeAppearancePage;
