import CardVisual from '@cohort/components-xps/components/cards/CardVisual';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import React from 'react';

type DigitalAssetVisualProps = {
  tokenId?: number | null;
  title: string;
  blockExplorerUrl?: string | null;
  digitalAssetImageFileKey: string | null;
  animationFileKey: string | null;
};

export const DigitalAssetVisual: React.FC<DigitalAssetVisualProps> = ({
  tokenId,
  blockExplorerUrl,
  digitalAssetImageFileKey,
  animationFileKey,
  title,
}) => (
  <div className="relative">
    <CardVisual
      bannerFileKey={digitalAssetImageFileKey}
      thumbnailFileKey={null}
      videoFileKey={animationFileKey}
      visualName={title}
      autoPlayVideo
    />
    <div className="absolute bottom-4 right-4 flex items-center gap-2">
      {tokenId && (
        <div className="rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-slate-500">
          ID #{tokenId}
        </div>
      )}
      {blockExplorerUrl && (
        <a
          className="rounded-full border border-white"
          href={blockExplorerUrl}
          target="_blank"
          onClick={e => e.stopPropagation()}
        >
          <SvgAppIcon name="polygon" height={20} width={20} />
        </a>
      )}
    </div>
  </div>
);
