/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {CustomTermsADto} from '@cohort/admin-schemas/customTerms';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getCustomTerms, postCustomTerms} from '@cohort/merchants/lib/api/CutomTerms';

export const customTermsKeys = {
  customTerms: ['customTerms'] as const,
  getByMerchantId: (merchantId: string) => [...customTermsKeys.customTerms, merchantId] as const,
};

export const useCustomTerms = (merchantId: string) =>
  useCohortQuery({
    queryKey: customTermsKeys.getByMerchantId(merchantId),
    queryFn: async () => getCustomTerms(merchantId),
  });

export const updateCustomTerms = (merchantId: string, data: CustomTermsADto) =>
  useCohortQuery({
    queryKey: customTermsKeys.getByMerchantId(merchantId),
    queryFn: async () => postCustomTerms(merchantId, data),
  });
