import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Broadcast} from '@phosphor-icons/react';
import React from 'react';

interface Props {
  className?: string;
  size: number;
}

export const AirdropVisual: React.FC<Props> = ({className, size}) => (
  <div
    className={cn('flex flex-shrink-0 items-center justify-center bg-slate-50', className)}
    style={{height: size, width: size}}
  >
    <Broadcast size={24} className="text-slate-400" />
  </div>
);
