import type {
  ContentCategoryADto,
  CreateContentCategoryADto,
  PatchContentCategoryADto,
  ReorderContentCategoriesADto,
} from '@cohort/admin-schemas/contentCategory';
import {ContentCategoryASchema} from '@cohort/admin-schemas/contentCategory';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import {z} from 'zod';

export async function getContentCategories(
  merchantId: string
): Promise<Array<ContentCategoryADto>> {
  return apiCall('GET', `/v1/merchants/${merchantId}/content-categories`, {
    expect: HttpCodes.SUCCESS,
    parser: z.array(ContentCategoryASchema).parse,
  });
}

export async function createContentCategory(
  merchantId: string,
  data: CreateContentCategoryADto
): Promise<ContentCategoryADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/content-categories`, {
    expect: HttpCodes.CREATED,
    parser: ContentCategoryASchema.parse,
    body: data,
  });
}

export async function updateContentCategory(
  merchantId: string,
  contentCategoryId: string,
  data: PatchContentCategoryADto
): Promise<ContentCategoryADto> {
  return apiCall('PATCH', `/v1/merchants/${merchantId}/content-categories/${contentCategoryId}`, {
    expect: HttpCodes.SUCCESS,
    parser: ContentCategoryASchema.parse,
    body: data,
  });
}

export async function reorderContentCategories(
  merchantId: string,
  data: ReorderContentCategoriesADto
): Promise<{}> {
  return apiCall('POST', `/v1/merchants/${merchantId}/content-categories/reorder`, {
    expect: HttpCodes.CREATED,
    parser: z.array(ContentCategoryASchema).parse,
    body: data,
  });
}

export async function deleteContentCategory(
  merchantId: string,
  contentCategoryId: string
): Promise<{}> {
  return apiCall('DELETE', `/v1/merchants/${merchantId}/content-categories/${contentCategoryId}`, {
    expect: HttpCodes.SUCCESS,
    parser: () => ({}),
  });
}
