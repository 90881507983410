import type {
  CampaignStorePreviewSection,
  MobileNavigationItem,
} from '@cohort/merchants/lib/StorePreview';
import {
  SCROLL_SPY_CONTAINER_ID,
  SCROLL_SPY_OFFSET,
  SMOOTH_SCROLL_DURATION,
} from '@cohort/merchants/lib/StorePreview';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import React from 'react';
import {Link} from 'react-scroll';

type MobileNavigationProps = {
  mobileNavigationItems: MobileNavigationItem[];
  accentColor: string;
  selectedTab?: CampaignStorePreviewSection;
  onTabChanged: (tab: CampaignStorePreviewSection) => void;
};

const MobileNavigation = ({
  mobileNavigationItems,
  accentColor,
  selectedTab,
  onTabChanged,
}: MobileNavigationProps): React.ReactElement => (
  <div className="no-scrollbar sticky top-0 z-40 flex gap-4 overflow-x-auto overflow-y-hidden border-b px-4 backdrop-blur-sm">
    {mobileNavigationItems.map(mobileNavigationItem => {
      return (
        <div key={mobileNavigationItem.name}>
          <button
            key={mobileNavigationItem.sectionTitle}
            style={{
              borderColor: selectedTab === mobileNavigationItem.name ? accentColor : 'transparent',
            }}
            className={cn(
              '-mb-px cursor-pointer whitespace-nowrap border-b-2 text-sm',
              selectedTab === mobileNavigationItem.name
                ? `font-bold text-[--xps-color]`
                : `border-transparent font-medium text-[--xps-navbar-link-color]`
            )}
          >
            <Link
              className="block py-4 !text-[--xps-color] !no-underline"
              containerId={SCROLL_SPY_CONTAINER_ID}
              to={mobileNavigationItem.name}
              spy={true}
              smooth={true}
              duration={SMOOTH_SCROLL_DURATION}
              offset={SCROLL_SPY_OFFSET}
              onSetActive={() => onTabChanged(mobileNavigationItem.name)}
            >
              {mobileNavigationItem.navigationTitle}
            </Link>
          </button>
        </div>
      );
    })}
  </div>
);

export default MobileNavigation;
