import i18n from '@cohort/components-xps/lib/i18n';
import AdminPage from '@cohort/merchants/components/AdminPage';
import {EmptyContentContainer} from '@cohort/merchants/components/EmptyContentContainer';
import HighlightText from '@cohort/merchants/components/HighlightText';
import Loader from '@cohort/merchants/components/Loader';
import SectionSeparator from '@cohort/merchants/components/SectionSeparator';
import {useCustomComponents} from '@cohort/merchants/hooks/api/CustomComponents';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {getCustomComponent} from '@cohort/merchants/lib/CustomComponents';
import ComponentsCustomisationForm from '@cohort/merchants/pages/settings/customization/ComponentsCustomisationForm';
import StyleCustomisationForm from '@cohort/merchants/pages/settings/customization/StyleCustomisationForm';
import LinkSection from '@cohort/merchants/pages/settings/integrations/LinkSection';
import type {Language} from '@cohort/shared/schema/common';
import type {MembershipPassContextTDto} from '@cohort/shared/schema/templating/common';
import {MembershipPassContextTSchema} from '@cohort/shared/schema/templating/common';
import type {DigitalAssetContextTDto} from '@cohort/shared/schema/templating/digitalAsset';
import {DigitalAssetContextTSchema} from '@cohort/shared/schema/templating/digitalAsset';
import type {JourneyContextTDto} from '@cohort/shared/schema/templating/journey';
import {JourneyContextTSchema, JourneyTSchema} from '@cohort/shared/schema/templating/journey';
import type {PerkContextTDto} from '@cohort/shared/schema/templating/perk';
import {PerkContextTSchema, PerkTSchema} from '@cohort/shared/schema/templating/perk';
import {Fragment, useRef} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {z} from 'zod';

const PerkSuggestionsSchema = z.object({
  perk: PerkTSchema,
});
const JourneySuggestionsSchema = z.object({
  journey: JourneyTSchema,
});

const CustomizationSettingsPage = (): JSX.Element => {
  const merchant = useCurrentMerchant();
  const ref = useRef<HTMLDivElement | null>(null);
  const {t} = useTranslation('pages', {keyPrefix: 'settings.customization.page'});
  const {data: components, isFetched} = useCustomComponents(merchant.id);
  const defaultContext = {
    lang: i18n.language as Language,
    isMobile: false,
  };

  return (
    <AdminPage
      ref={ref}
      header={{
        title: t('title'),
        subtitle: t('subtitle'),
      }}
    >
      <StyleCustomisationForm
        onEditorLoaded={() => {
          // Don't know why and how to prevent this but when the editor is loaded, the page scrolls down to it
          const parent = ref.current?.parentElement;

          if (parent) {
            parent.scrollTo({top: 0, behavior: 'instant'});
          }
        }}
      />
      <SectionSeparator />
      <section className="space-y-6">
        <LinkSection
          title={t('customComponentsSectionTitle')}
          description={t('customComponentsSectionSubtitle')}
        />
        <HighlightText
          type="info"
          text={
            <Trans
              i18nKey="settings.customization.page.customComponentsHint"
              ns="pages"
              components={{
                pageLink: (
                  <a
                    className="font-medium text-blue-600 underline"
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://docs.getcohort.com/concepts/experience-space/customizing"
                  />
                ),
              }}
            />
          }
        />
        {!isFetched ? (
          <EmptyContentContainer icon={<Loader size={50} color="gray" />} />
        ) : (
          <Fragment>
            <ComponentsCustomisationForm<DigitalAssetContextTDto>
              customComponent={getCustomComponent(components, 'digital-asset')}
              type="digital-asset"
              contextSchema={DigitalAssetContextTSchema}
              contextData={{
                digitalAsset: {
                  imageUrl: 'https://via.placeholder.com/300',
                  ownership: {
                    tokenId: 1,
                  },
                },
                ...defaultContext,
              }}
            />
            <ComponentsCustomisationForm<PerkContextTDto>
              customComponent={getCustomComponent(components, 'perk')}
              type="perk"
              contextSchema={PerkContextTSchema}
              suggestionsSchema={PerkSuggestionsSchema}
              contextData={{
                perk: {
                  imageUrl: 'https://via.placeholder.com/300',
                  videoThumbnailUrl: null,
                  videoUrl: null,
                  raffle: null,
                  access: {
                    canBeUsed: true,
                    usagesLeft: null,
                    usagesCount: 0,
                  },
                },
                ...defaultContext,
              }}
            />
            <ComponentsCustomisationForm<JourneyContextTDto>
              customComponent={getCustomComponent(components, 'journey')}
              type="journey"
              contextSchema={JourneyContextTSchema}
              suggestionsSchema={JourneySuggestionsSchema}
              contextData={{
                journey: {
                  availableSupply: null,
                  imageUrl: 'https://via.placeholder.com/600',
                  status: 'published',
                  totalSupply: null,
                  rewards: [
                    {
                      id: '7c0de977-ab0e-4e51-a396-048e30d277f6',
                      videoUrl: null,
                      description: null,
                      imageUrl: 'https://via.placeholder.com/300',
                      title: 'Reward 1',
                      type: 'perk',
                    },
                    {
                      id: '89d83eab-28a0-4444-9d80-a4976da9d4a2',
                      videoUrl: null,
                      description: null,
                      imageUrl: 'https://via.placeholder.com/300',
                      title: 'Reward 2',
                      type: 'digital-asset',
                    },
                  ],
                  steps: [
                    {
                      id: '8bbfc0c6-a010-4df3-acd9-2cd49a27c05e',
                      description: 'Step #1 description',
                      icon: '🎉',
                      isCompleted: false,
                      title: 'Step #1',
                    },
                    {
                      id: '99e4f66d-b927-4d49-a9c8-d80675c825dc',
                      description: 'Step #2 description',
                      icon: '👾',
                      isCompleted: false,
                      title: 'Step #2',
                    },
                  ],
                },
                ...defaultContext,
              }}
            />
            <ComponentsCustomisationForm<MembershipPassContextTDto>
              customComponent={getCustomComponent(components, 'membership-pass')}
              type="membership-pass"
              contextSchema={MembershipPassContextTSchema}
              suggestionsSchema={false}
              contextData={{
                user: {
                  membership: {
                    imageUrl: 'https://via.placeholder.com/600',
                    memberId: 42,
                  },
                },
                ...defaultContext,
              }}
            />
          </Fragment>
        )}
      </section>
    </AdminPage>
  );
};

export default CustomizationSettingsPage;
