import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import EmptyState from '@cohort/merchants/components/EmptyState';
import ErrorState from '@cohort/merchants/components/ErrorState';
import {IconBadge} from '@cohort/merchants/components/IconBadge';
import LoadingState from '@cohort/merchants/components/LoadingState';
import {useConnections} from '@cohort/merchants/hooks/api/Connections';
import {useSyncs} from '@cohort/merchants/hooks/api/Syncs';
import {useCurrentApp} from '@cohort/merchants/hooks/contexts/currentApp';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import CreateSyncModal from '@cohort/merchants/pages/apps/app/syncs/CreateSyncModal';
import SyncsTable from '@cohort/merchants/pages/apps/app/syncs/SyncsTable';
import type {MerchantConnectorId} from '@cohort/shared/apps';
import {ArrowsClockwise} from '@phosphor-icons/react';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const AppSyncsPage: React.FC = () => {
  const {appId, appSpec} = useCurrentApp();
  const {t} = useTranslation('pages', {keyPrefix: 'apps.app.syncs.page'});
  const [showCreateSyncModal, setShowCreateSyncModal] = useState(false);
  const merchant = useCurrentMerchant();

  const merchantConnectorId = appSpec.merchantConnector.id as MerchantConnectorId;

  const {data: syncs, isLoading: isSyncsLoading, isError} = useSyncs(merchant.id, {appId});
  const {data: connections, isLoading: isConnectionsLoading} = useConnections(merchant.id, {
    connectorId: [appSpec.merchantConnector.id as MerchantConnectorId],
  });

  const isLoading = isSyncsLoading || isConnectionsLoading;
  const syncsCount = syncs?.length ?? 0;

  const pageContent: JSX.Element = match({
    syncs,
    isLoading,
    isError,
    appSpec,
    merchantConnectorId,
  })
    .with({isLoading: true}, () => {
      return <LoadingState />;
    })
    .with({isError: true}, () => <ErrorState />)
    .with({isLoading: undefined}, {syncs: undefined}, {syncs: []}, () => {
      return (
        <Fragment>
          <EmptyState title={t('titleEmpty')} description={t('descriptionEmpty')} />
          {showCreateSyncModal && <CreateSyncModal onClose={() => setShowCreateSyncModal(false)} />}
        </Fragment>
      );
    })
    .otherwise(() => {
      return (
        <div className="space-y-8">
          <SyncsTable
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            syncs={syncs!}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            connections={connections!}
            isLoading={isLoading}
          />
          {showCreateSyncModal && <CreateSyncModal onClose={() => setShowCreateSyncModal(false)} />}
        </div>
      );
    });

  const headerConfig = {
    title: t('title'),
    titleBadge: (
      <IconBadge
        key="syncs-count"
        icon={<ArrowsClockwise className="mr-1.5 h-4 w-4 text-slate-500" />}
        text={t('syncsCount', {count: syncsCount})}
      />
    ),
    topRightElements: (
      <Button
        key="createSync"
        onClick={() => setShowCreateSyncModal(true)}
        disabled={isConnectionsLoading || connections?.length === 0}
      >
        {t('createSyncButton')}
      </Button>
    ),
  };

  return <AdminPage header={headerConfig}>{pageContent}</AdminPage>;
};

export default AppSyncsPage;
