import {DateSchema} from '@cohort/shared/schema/common';
import {DefaultContextSchema} from '@cohort/shared/schema/templating/common';
import {z} from 'zod';

export const DigitalAssetTSchema = z.object({
  title: z.string(),
  description: z.string(),
  imageUrl: z.string().url(),
  videoUrl: z.string().url().nullable(),
  smartContractAddress: z.string().nullable(),
  ownership: z
    .object({
      id: z.string().uuid(),
      tokenId: z.number(),
      createdAt: DateSchema,
    })
    .nullable(),
});
export type DigitalAssetTDto = z.infer<typeof DigitalAssetTSchema>;

export const DigitalAssetContextTSchema = DefaultContextSchema.extend({
  digitalAsset: DigitalAssetTSchema,
});
export type DigitalAssetContextTDto = z.infer<typeof DigitalAssetContextTSchema>;
