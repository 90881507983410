import type {MerchantADto} from '@cohort/admin-schemas/merchant';
import type {MembershipPassCardProps} from '@cohort/components-xps/components/cards/MembershipPassCard';
import MembershipPassCard from '@cohort/components-xps/components/cards/MembershipPassCard';
import {useCustomComponents} from '@cohort/merchants/hooks/api/CustomComponents';
import usePreviewStyle from '@cohort/merchants/hooks/previewStyle';
import {getCustomComponent, getUserContext} from '@cohort/merchants/lib/CustomComponents';
import {
  trackMembershipPassPreviewModalClosed,
  trackMembershipPassPreviewModalOpened,
} from '@cohort/merchants/lib/Tracking';
import MembershipPassPreviewModal from '@cohort/merchants/pages/settings/membership-pass-configuration/MembershipPassPreviewModal';
import type {MembershipPassSettingsFormType} from '@cohort/merchants/pages/settings/membership-pass-configuration/page';
import {DEFAULT_ACCENT_COLOR, DEFAULT_BACKGROUND_COLOR} from '@cohort/shared/schema/common';
import type {UserTDto} from '@cohort/shared/schema/templating/common';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import {isFile} from '@cohort/shared-frontend/utils/isFile';
import {ArrowsOut} from '@phosphor-icons/react';
import {Fragment, useEffect, useRef, useState} from 'react';
import type {Control} from 'react-hook-form';
import {useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type MembershipPassPreviewAsideProps = {
  control: Control<MembershipPassSettingsFormType>;
  merchant: MerchantADto;
};
const MembershipPassPreviewAside: React.FC<MembershipPassPreviewAsideProps> = ({
  control,
  merchant,
}) => {
  const [refreshKey, setRefreshKey] = useState(Date.now());
  const initialMembershipPassBackgroundColor = useRef<string | null>(null);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const {t} = useTranslation('pages', {keyPrefix: 'settings.membershipPass.page'});
  const backgroundColorCode = useWatch({
    control,
    name: 'backgroundColorCode',
  });
  const selectedLanguage = useWatch({
    control,
    name: 'selectedLanguage',
  });
  const title = useWatch({
    control,
    name: 'title',
  });
  const bannerFileKey = useWatch({
    control,
    name: 'bannerFileKey',
  });
  const nftEnabled = useWatch({
    control,
    name: 'nftEnabled',
  });
  const {data: components} = useCustomComponents(merchant.id);
  const membershipPassCustomComponent = getCustomComponent(components, 'membership-pass', true);

  usePreviewStyle(
    merchant.backgroundColorCode ?? DEFAULT_BACKGROUND_COLOR,
    merchant.accentColorCode ?? DEFAULT_ACCENT_COLOR
  );

  const imageUrl = bannerFileKey
    ? isFile(bannerFileKey)
      ? URL.createObjectURL(bannerFileKey)
      : getImageUrl(import.meta.env.COHORT_ENV, bannerFileKey, {
          h: Sizes.S,
          w: Sizes.S,
        })
    : null;
  const merchantLogoUrl = merchant.logoFileKey
    ? getImageUrl(import.meta.env.COHORT_ENV, merchant.logoFileKey, {
        h: Sizes.S,
        w: Sizes.S,
      })
    : null;

  const membershipPass: MembershipPassCardProps['membershipPass'] = {
    title: title[selectedLanguage] ?? t('defaultTitle', {merchantName: merchant.name}),
    imageUrl: imageUrl ?? merchantLogoUrl,
    memberHandle: 'userName',
    subscriptionDate: new Date(),
    memberId: 1245,
    blockExplorerUrl: nftEnabled ? '' : null,
  };
  const customComponentContext = {
    user: getUserContext(merchant) as UserTDto,
    lang: selectedLanguage,
    isMobile: false,
  };
  const customComponent =
    membershipPassCustomComponent && membershipPassCustomComponent.template
      ? {
          context: customComponentContext,
          template: membershipPassCustomComponent.template,
        }
      : undefined;

  // Store and restore the initial value of the membership pass background color
  useEffect(() => {
    initialMembershipPassBackgroundColor.current = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--xps-membership-pass-background-color');

    return () => {
      document.documentElement.style.setProperty(
        '--xps-membership-pass-background-color',
        initialMembershipPassBackgroundColor.current
      );
    };
  }, []);

  // Update the membership pass background color
  useEffect(() => {
    if (backgroundColorCode) {
      document.documentElement.style.setProperty(
        '--xps-membership-pass-background-color',
        backgroundColorCode
      );

      setRefreshKey(Date.now());
    }
  }, [backgroundColorCode]);

  return (
    <Fragment>
      <div className="hidden w-[var(--membership-pass-preview-width)] gap-4 [grid-template-rows:min-content_1fr] lg:grid">
        <button
          data-testid="open-membership-pass-preview"
          className="flex cursor-pointer flex-row items-center justify-center text-slate-500"
          onClick={() => {
            setPreviewModalOpen(true);
            trackMembershipPassPreviewModalOpened();
          }}
        >
          <ArrowsOut className="mr-1 h-4 w-4" />
          <span className="text-sm font-medium">{t('labelPreviewOpen')}</span>
        </button>
        <div id="preview" className="rounded-xl p-4">
          <MembershipPassCard
            key={refreshKey}
            membershipPass={membershipPass}
            selectedLanguage={selectedLanguage}
            customComponent={customComponent}
          />
        </div>
      </div>
      {previewModalOpen && (
        <MembershipPassPreviewModal
          membershipPass={membershipPass}
          selectedLanguage={selectedLanguage}
          customComponent={customComponent}
          handleOnClose={() => {
            setPreviewModalOpen(false);
            trackMembershipPassPreviewModalClosed();
          }}
        />
      )}
    </Fragment>
  );
};

export default MembershipPassPreviewAside;
