import CardVisual from '@cohort/components-xps/components/cards/CardVisual';
import ExpandableDesc from '@cohort/components-xps/components/ExpandableDesc';
import JourneyStatusBadge from '@cohort/components-xps/components/journeys/JourneyStatusBadge';
import Title from '@cohort/components-xps/components/Title';

export type AboutJourneyType = {
  animationFileKey: string | File | null;
  availableSupply: number | null;
  backgroundColor: string;
  description: string;
  imageFileKey: string | File | null;
  title: string;
  isCampaignEnded: boolean;
};

type AboutJourneyProps = {
  aboutJourney: AboutJourneyType;
  children: React.ReactElement;
};

const AboutJourney: React.FC<AboutJourneyProps> = ({children, aboutJourney}) => {
  const {
    animationFileKey,
    backgroundColor,
    imageFileKey,
    title,
    availableSupply,
    description,
    isCampaignEnded,
  } = aboutJourney;

  return (
    <div className="flex flex-col gap-4">
      {(imageFileKey || animationFileKey) && (
        <CardVisual
          bannerFileKey={imageFileKey ?? null}
          thumbnailFileKey={null}
          videoFileKey={animationFileKey ?? null}
          visualName={title}
          autoPlayVideo
        />
      )}
      <div className="flex flex-col gap-1">
        <Title content={title} />
        <JourneyStatusBadge
          isCampaignEnded={isCampaignEnded}
          completedAt={null}
          availableSupply={availableSupply}
        />
      </div>
      {children}
      <div className="flex text-sm">
        <ExpandableDesc backgroundColor={backgroundColor} description={description} />
      </div>
    </div>
  );
};

export default AboutJourney;
