import {InformationTooltip} from '@cohort/merchants/components/InformationTooltip';
import RewardVisual from '@cohort/merchants/components/rewards/RewardVisual';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import RewardListItem from '@cohort/merchants/pages/campaigns/campaign/edit/reward/RewardListItem';
import type {PerkItem} from '@cohort/merchants/pages/campaigns/campaign/edit/reward/RewardSelectionModal';
import Switch from '@cohort/shared-frontend/components/Switch';
import {useTranslation} from 'react-i18next';

type RewardPerkListItemProps = {
  perkItem: PerkItem;
  index: number;
  handleRemove: () => void;
  setRevocable: (revocable: boolean) => void;
};

const RewardPerkListItem: React.FC<RewardPerkListItemProps> = ({
  perkItem,
  handleRemove,
  setRevocable,
}) => {
  const {perk} = perkItem;
  const campaign = useCurrentCampaign();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.reward.rewardPerkListItem',
  });
  const revocableOption = campaign.type === 'airdrop' && campaign.cohortId !== null;

  return (
    <RewardListItem
      visual={<RewardVisual type="perk" perk={perk} />}
      limitPerUser={perkItem.perk.maxAccessesPerUser}
      handleRemove={handleRemove}
    >
      {revocableOption && (
        <div className="flex flex-row items-center gap-x-2">
          <Switch checked={perkItem.revocable} onCheckedChange={setRevocable} />
          <p className="text-sm font-medium text-slate-700">{t('labelRevocable')}</p>
          <InformationTooltip
            className="h-5 w-5"
            content={t('tooltipPerk', {cohortName: campaign.cohort?.name})}
          />
        </div>
      )}
    </RewardListItem>
  );
};
export default RewardPerkListItem;
