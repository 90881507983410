import AdminPage from '@cohort/merchants/components/AdminPage';
import CopyToClipboard from '@cohort/merchants/components/buttons/CopyToClipboard';
import ExportCSV from '@cohort/merchants/components/buttons/ExportCSV';
import SingleChoiceFilter from '@cohort/merchants/components/filters/SingleChoiceFilter';
import KPICard from '@cohort/merchants/components/KPICard';
import Loader from '@cohort/merchants/components/Loader';
import {useCampaignRevenue} from '@cohort/merchants/hooks/api/Campaigns';
import {useOrdersWithPagination} from '@cohort/merchants/hooks/api/Orders';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import usePageSearchParam from '@cohort/merchants/hooks/pageSearchParam';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {exportOrders} from '@cohort/merchants/lib/api/Orders';
import StoreOrdersTable from '@cohort/merchants/pages/campaigns/campaign/activity/store/StoreOrdersTable';
import {CurrencySpecs} from '@cohort/shared/schema/common/currency';
import {formatAmount} from '@cohort/shared/utils/format';
import {Money, Stack} from '@phosphor-icons/react';
import {useEffect, useMemo, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

const PAGE_SIZE = 20;

const StoreActivityPage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const campaign = useCurrentCampaign();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.activity.store.storeActivityPage',
  });
  const page = usePageSearchParam();
  const [currency, setCurrency] = useState<string | null>(null);

  const {data, isLoading} = useOrdersWithPagination(merchantId, campaign.id, {
    page,
    pageSize: PAGE_SIZE,
  });

  const {data: revenueData, isFetched: isRevenueFetched} = useCampaignRevenue(
    merchantId,
    campaign.id,
    {enabled: campaign.type === 'store'}
  );
  const [pagination, orders] = data ?? [];

  useEffect(() => {
    if (campaign.store && campaign.store.defaultCurrency !== null) {
      setCurrency(campaign.store.defaultCurrency);
    } else if (revenueData && revenueData.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setCurrency(revenueData[0]!.currency);
    }
  }, [campaign.store, campaign.store?.defaultCurrency, revenueData]);

  const currencyFilterOptions = useMemo(() => {
    const campaignCurrencies = (campaign.store?.prices ?? []).map(price => price.currency);
    const revenueCurrencies = (revenueData ?? []).map(revenue => revenue.currency);
    const availableCurrencis = [...new Set([...campaignCurrencies, ...revenueCurrencies])].sort();
    const options = [];
    for (const availableCurrency of availableCurrencis) {
      const spec = CurrencySpecs.getOrThrow(availableCurrency);
      options.push({
        label: `${spec.currency.toUpperCase()} (${spec.symbol})`,
        value: spec.currency,
        active: spec.currency === currency,
      });
    }
    return options;
  }, [campaign.store?.prices, revenueData, currency]);

  const showRevenue = campaign.type !== 'journey' && currencyFilterOptions.length > 0;

  const getAmount = (currency: string): string => {
    const revenue = (revenueData ?? []).find(revenue => revenue.currency === currency);
    const amount = revenue ? revenue.amount : 0;
    return formatAmount(amount, currency);
  };

  const headerConfig = {
    title: t('title'),
    subtitle: campaign.store?.shopUrl ? (
      <p className="line-clamp-4">
        <Trans
          i18nKey="campaigns.campaign.activity.store.storeActivityPage.accessSubtitle"
          ns="pages"
          values={{
            storeUrl: campaign.store.shopUrl,
            copyButton: <CopyToClipboard target={campaign.store.shopUrl} />,
          }}
          components={{
            underline: (
              <a href={campaign.store.shopUrl} className="font-medium underline" target="_blank" />
            ),
            copyButton: <CopyToClipboard target={campaign.store.shopUrl} />,
          }}
        />
      </p>
    ) : undefined,
    topRightElements: [
      <KPICard
        icon={<Stack className="h-4 w-4 text-gray-500" />}
        text={t('ordersKPI')}
        value={pagination?.total}
        key="ordersKPI"
      />,
      ...[
        showRevenue && currency ? (
          <KPICard
            icon={<Money className="h-4 w-4 text-gray-500" />}
            text={
              <SingleChoiceFilter
                prefix={t('revenueKPI')}
                options={currencyFilterOptions}
                onChange={option => {
                  setCurrency(option.value);
                }}
              />
            }
            value={!isRevenueFetched ? <Loader color="gray" size={24} /> : getAmount(currency)}
            key="revenueKPI"
          />
        ) : null,
      ],
    ],
  };

  return (
    <AdminPage header={headerConfig} pagination={{pagination}}>
      <div className="flex justify-end">
        <ExportCSV
          disabled={isLoading || (orders?.length ?? 0) === 0}
          downloadFunction={async () => exportOrders(merchantId, campaign.id, 'csv')}
        />
      </div>
      <StoreOrdersTable orders={orders ?? []} isLoading={isLoading === true} />
    </AdminPage>
  );
};

export default StoreActivityPage;
