/* eslint-disable require-unicode-regexp */
import {getPropertiesPathFromZodSchema} from '@cohort/shared/utils/zod';
import type {Liquid} from 'liquidjs';
import type {editor, IRange} from 'monaco-editor';
import {MarkerSeverity} from 'monaco-editor';
import type {z} from 'zod';

export type ValidationReport = {
  successes: Array<{
    entity: string;
    property: string;
  }>;
  errors: Array<{
    entity: string;
    property: string;
  }>;
};

type Variables = {
  entity: string;
  property: string;
};

class LiquidTemplateValidator {
  #template: string;
  #variables: Array<Variables> = [];
  #contextSchema: z.ZodSchema;

  constructor(template: string, contextSchema: z.ZodSchema) {
    this.#template = template;
    this.#contextSchema = contextSchema;
  }

  #extractFromFilters(content: string): void {
    const filters = content.split('|');
    const filterRegex = /(\w+)\s*:\s*([^,]+)/g;
    const functionRegex = /(\w+)\s*:\s*((?:\w+\s*:\s*[^,]+(?:,\s*)?)*)/g;
    let match;

    for (const filter of filters) {
      while ((match = functionRegex.exec(filter)) !== null) {
        // const functionName = match[1];
        const paramsString = match[2];
        let paramMatch;

        if (!paramsString) {
          continue;
        }
        while ((paramMatch = filterRegex.exec(paramsString)) !== null) {
          // const paramName = paramMatch[1];
          const paramValue = paramMatch[2]?.trim().replace(/['"]/g, ''); // Remove quotes from param values

          if (!paramValue) {
            continue;
          }
          const [entity, property] = paramValue.split('.');

          if (entity && property) {
            this.#variables.push({
              entity,
              property,
            });
          }
        }
      }
    }
  }

  #extractFromStatements(content: string): void {
    const regex = /\{\{\s*([^{}]+)\s*\}\}/g;
    const variables: Set<string> = new Set();
    let match;

    while ((match = regex.exec(content))) {
      const [, variable] = match;

      // ignore if it's a translation filter
      if (!variable || variable.includes('| t')) {
        continue;
      }
      variables.add(variable.trim());
    }

    for (const variable of variables) {
      const [entity, ...rest] = variable.split('.');
      const property = rest.join('.');

      if (variable.includes('|')) {
        const [newVariable, ...filters] = variable.split('|');

        if (newVariable && newVariable.includes('.')) {
          const [entity, ...rest] = newVariable.trim().split('.');
          const property = rest.join('.');

          if (entity && property) {
            this.#variables.push({
              entity,
              property,
            });
          }
        }
        this.#extractFromFilters(filters.join('|'));
      } else if (entity && property) {
        this.#variables.push({
          entity,
          property,
        });
      }
    }
  }

  #extractLogicalBlocks(template: string): string {
    const logicalBlocksRegex = /{%\s*(if|elsif|for|unless|case)\s+(.*?)\s*%}/g;
    let match;

    while ((match = logicalBlocksRegex.exec(template)) !== null) {
      const condition = match[2];
      const parts = condition?.split(' ');

      if (!parts) {
        continue;
      }
      for (const statement of parts) {
        // the variable could be anywhere in the parts, therefore we test all of them
        this.#extractFromStatements(`{{${statement}}}`);
      }
    }

    return template;
  }

  #extractVariablesFromTemplate(template: string): void {
    this.#extractLogicalBlocks(template);
    this.#extractFromStatements(template);
  }

  #validateVariables(): ValidationReport {
    const report: ValidationReport = {
      successes: [],
      errors: [],
    };

    for (const variable of this.#variables) {
      const {entity, property} = variable;
      const schemaProperties = getPropertiesPathFromZodSchema(this.#contextSchema);
      const rootSchemaProperties = schemaProperties.filter(path => path.split('.').length === 1);
      const schemaPath = `${entity}.${property}`;

      // Only check for root schema properties since we cannot validate the schema otherwise
      if (rootSchemaProperties.includes(entity) && !schemaProperties.includes(schemaPath)) {
        report.errors.push({
          entity,
          property,
        });
      } else {
        report.successes.push({
          entity,
          property,
        });
      }
    }

    return report;
  }

  validateTemplate(): ValidationReport {
    this.#extractVariablesFromTemplate(this.#template);

    return this.#validateVariables();
  }
}

function findTextRanges(model: editor.ITextModel, entity: string, property: string): Array<IRange> {
  const content = model.getValue();
  const ranges: Array<IRange> = [];
  const regexes = [
    new RegExp(`{{\\s*(${entity}\\.${property}).*}}`, 'g'),
    new RegExp(`{%.*(${entity}\\.${property}).*%}`, 'g'),
  ];

  regexes.forEach(regex => {
    let match;

    while ((match = regex.exec(content)) !== null) {
      const index = match.index;
      const startPos = model.getPositionAt(index);
      const endPos = model.getPositionAt(index + match[0].length);

      ranges.push({
        startLineNumber: startPos.lineNumber,
        startColumn: startPos.column,
        endLineNumber: endPos.lineNumber,
        endColumn: endPos.column,
      });
    }
  });

  return ranges;
}

export function validateTemplate(
  model: editor.ITextModel,
  contextSchema: z.ZodSchema
): Array<editor.IMarkerData> {
  const text = model.getValue();
  const validator = new LiquidTemplateValidator(text, contextSchema);
  const report = validator.validateTemplate();
  const errors: editor.IMarkerData[] = [];

  report.errors.forEach(({entity, property}) => {
    const ranges = findTextRanges(model, entity, property);

    ranges.forEach(range => {
      errors.push({
        severity: MarkerSeverity.Error,
        ...range,
        message: `Property '${property}' does not exist on type '${entity}'`,
      });
    });
  });

  return errors;
}

type LiquidSafeParseResult =
  | {
      success: true;
      template: string;
    }
  | {
      success: false;
      report: editor.IMarkerData;
    };

export function parseAndReportLiquidTemplate(
  liquid: Liquid,
  template: string,
  context?: Record<string, unknown>
): LiquidSafeParseResult {
  try {
    const rendered = liquid.parseAndRenderSync(template, context);

    return {
      success: true,
      template: rendered,
    };
  } catch (e) {
    if (e instanceof Error) {
      const regex = /line:(\d+), col:(\d+)/;
      const match = e.message.match(regex);

      if (match) {
        if (!match[1] || !match[2]) {
          throw e;
        }
        const line = parseInt(match[1], 10);
        const col = parseInt(match[2], 10);

        return {
          success: false,
          report: {
            severity: MarkerSeverity.Error,
            startLineNumber: line,
            startColumn: col,
            endLineNumber: line,
            endColumn: col,
            message: e.message,
          },
        };
      }
    }
    throw e;
  }
}

export function getBasePath(lineContent: string): string {
  const match = lineContent.match(/[\w.]*$/u);

  return match ? match[0].split('.').slice(0, -1).join('.') : '';
}
