import type {ContentCategoryADto} from '@cohort/admin-schemas/contentCategory';
import Button from '@cohort/merchants/components/buttons/Button';
import Input from '@cohort/merchants/components/form/input/Input';
import LocalizedInput from '@cohort/merchants/components/form/input/LocalizedInput';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import {Dialog, DialogContent, DialogTitle} from '@cohort/merchants/components/modals/Dialog';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {
  changeSelectedLanguageIfNeeded,
  getDefinedLanguages,
  isDefaultTranslationDefined,
  removeUndefinedLanguages,
} from '@cohort/merchants/lib/form/localization';
import {handleFormErrors} from '@cohort/merchants/lib/form/utils';
import {LanguageSchema, LocalizedStringSchema} from '@cohort/shared/schema/common';
import {zodResolver} from '@hookform/resolvers/zod';
import React from 'react';
import {useForm, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

const ContentCategoryFormSchema = z
  .object({
    internalName: z.string().min(3, {message: 'errorTooShort3'}),
    title: LocalizedStringSchema,
    defaultLanguage: LanguageSchema,
    selectedLanguage: LanguageSchema,
    definedLanguages: z.array(LanguageSchema),
  })
  .superRefine(({title, defaultLanguage}, ctx) => {
    if (!isDefaultTranslationDefined(title, defaultLanguage)) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorTooShort3',
        path: ['title'],
      });
    }
  })
  .transform(data => {
    data.title = removeUndefinedLanguages(data.title, data.definedLanguages);
    return data;
  });

export type ContentCategoryFormValues = z.infer<typeof ContentCategoryFormSchema>;

type CreateEditContentCategoryModalProps = {
  onClose: () => void;
  onSubmit: (data: ContentCategoryFormValues) => void;
  isLoading: boolean;
  contentCategoryToEdit?: ContentCategoryADto;
};

const CreateEditContentCategoryModal: React.FC<CreateEditContentCategoryModalProps> = ({
  onClose,
  onSubmit,
  isLoading,
  contentCategoryToEdit,
}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {
    keyPrefix: 'content-categories.overview.createContentCategoryModal',
  });

  const {register, control, handleSubmit, setValue, setError, clearErrors} =
    useForm<ContentCategoryFormValues>({
      defaultValues: {
        internalName: contentCategoryToEdit?.internalName,
        title: contentCategoryToEdit?.title,
        defaultLanguage: merchant.defaultLanguage,
        selectedLanguage: merchant.defaultLanguage,
        definedLanguages: getDefinedLanguages(merchant.defaultLanguage, [
          ...(contentCategoryToEdit !== undefined ? [contentCategoryToEdit.title] : []),
        ]),
      },
      resolver: zodResolver(ContentCategoryFormSchema),
    });

  const selectedLanguage = useWatch({
    control,
    name: 'selectedLanguage',
  });
  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent displayCloseButton={false} className="min-w-[678px]">
        <form
          className="flex flex-col gap-y-6 p-6"
          id="create-content-category-form"
          onSubmit={handleSubmit(
            async data => {
              try {
                const parsedData = ContentCategoryFormSchema.parse(data);
                onSubmit(parsedData);
              } catch (error) {
                if (error instanceof z.ZodError) {
                  handleFormErrors(error, clearErrors, setError);
                  changeSelectedLanguageIfNeeded(error, merchant.defaultLanguage, lang =>
                    setValue('selectedLanguage', lang)
                  );
                } else {
                  throw error;
                }
              }
            },
            errors => {
              changeSelectedLanguageIfNeeded(errors, merchant.defaultLanguage, lang =>
                setValue('selectedLanguage', lang)
              );
            }
          )}
        >
          <div className="flex flex-col justify-center">
            <DialogTitle className="text-lg">{t('create')}</DialogTitle>
          </div>
          <div>
            <Input
              label={t('categoryInternalNameLabel')}
              type="text"
              name="internalName"
              register={register}
              control={control}
              description={t('contextInternalName')}
              placeholder={t('placeholderInternalName')}
              optional={false}
            />
          </div>
          <div className="flex flex-col gap-y-6">
            <LanguageSelectorInput
              control={control}
              definedLanguagesPath="definedLanguages"
              selectedLanguagePath="selectedLanguage"
            />
            <LocalizedInput
              type="text"
              name="title"
              label={t('categoryTitleLabel')}
              placeholder={t('placeholderTitle')}
              register={register}
              control={control}
              selectedLanguage={selectedLanguage}
              optional={!isDefaultLanguageSelected}
              description={t('contextTitle')}
            />
          </div>
          <div className="flex justify-between">
            <Button
              onClick={onClose}
              data-testid="create-content-category-submit-btn"
              type="button"
              form="create-content-category-form"
              loading={isLoading}
              variant="secondary"
            >
              {t('buttonCancel')}
            </Button>
            <Button
              data-testid="create-content-category-submit-btn"
              type="submit"
              form="create-content-category-form"
              loading={isLoading}
            >
              {contentCategoryToEdit ? t('buttonEdit') : t('buttonCreate')}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateEditContentCategoryModal;
