import {useApps} from '@cohort/merchants/apps/useApps';
import {Badge} from '@cohort/merchants/components/Badge';
import Button from '@cohort/merchants/components/buttons/Button';
import {getAppConnectionsRoute} from '@cohort/merchants/lib/Pages';
import type {AppId} from '@cohort/shared/apps';
import {getAppSpec} from '@cohort/shared/apps';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import ConnectionErrorBadge from '@cohort/shared-frontend/components/ConnectionErrorBadge';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {ArrowsClockwise, CheckCircle} from '@phosphor-icons/react';
import {Link} from '@phosphor-icons/react';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {match} from 'ts-pattern';

type ConnectionsCountBadgeProps = {
  connectionsCount: number;
};
const ConnectionsCountBadge: React.FC<ConnectionsCountBadgeProps> = ({connectionsCount}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'settings.apps.appCard'});

  return (
    <Badge
      text={
        <div className="flex items-center gap-x-1.5">
          <Link size={14} className="text-slate-400" />
          {t('connectionsCount', {count: connectionsCount})}
        </div>
      }
      textColor="text-slate-700"
      backgroundColor="bg-slate-100"
    />
  );
};

type SyncsCountBadgeProps = {
  syncsCount: number;
};
const SyncsCountBadge: React.FC<SyncsCountBadgeProps> = ({syncsCount}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'settings.apps.appCard'});

  return (
    <Badge
      text={
        <div className="flex items-center gap-x-1.5">
          <ArrowsClockwise size={14} className="text-slate-400" />
          {t('syncsCount', {count: syncsCount})}
        </div>
      }
      textColor="text-slate-700"
      backgroundColor="bg-slate-100"
    />
  );
};

type AppCardProps = {
  appId: AppId;
  connectionsCount?: number;
  syncsCount?: number;
  hasBrokenConnection?: boolean;
  onConnectClicked: (appId: AppId) => void;
};

const AppCard: React.FC<AppCardProps> = ({
  appId,
  connectionsCount = 0,
  syncsCount = 0,
  hasBrokenConnection = false,
  onConnectClicked,
}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'settings.apps.appCard'});
  const navigate = useNavigate();
  const appSpec = getAppSpec(appId);
  const {getAppDescription, appDisabled} = useApps();

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const hasMerchantConnector = appSpec.merchantConnector !== null;

  const hideCreateConnectionButton = appDisabled(appId);

  const cardButton = match({
    hasMerchantConnector,
    connectionsCount,
    hideCreateConnectionButton,
  })
    .with({hasMerchantConnector: true, connectionsCount: 0}, () => (
      <Button onClick={() => onConnectClicked(appId)}>{t('connectButton')}</Button>
    ))
    .with({hasMerchantConnector: false}, () => (
      <div className="flex items-center gap-1 text-xs text-green-700">
        <CheckCircle size={20} /> {t('noConnectionNeeded')}
      </div>
    ))
    .otherwise(() => <Fragment />);

  return (
    <div
      className={cn(
        'flex flex-col gap-4 rounded-xl border p-4 shadow-sm',
        hasMerchantConnector && 'hover:cursor-pointer'
      )}
      onClick={() => {
        if (connectionsCount > 0) {
          navigate(getAppConnectionsRoute(appId).path);
        } else if (hasMerchantConnector && !hideCreateConnectionButton) {
          onConnectClicked(appId);
        }
      }}
    >
      <div className="flex items-start justify-between">
        <SvgAppIcon name={appId} height={60} width={60} />
        {cardButton}
      </div>
      <div className="font-semibold">{appSpec.name}</div>
      <div className="flex flex-row flex-wrap gap-2">
        {connectionsCount > 0 && (
          <Fragment>
            <ConnectionsCountBadge connectionsCount={connectionsCount} />
            {hasBrokenConnection && (
              <Badge
                text={
                  <ConnectionErrorBadge
                    label={t('connectionErrorLabel')}
                    iconSize="sm"
                    textColor="text-red-800"
                  />
                }
                textColor="text-red-800"
                backgroundColor="bg-red-100"
              />
            )}
          </Fragment>
        )}
        {syncsCount > 0 && <SyncsCountBadge syncsCount={syncsCount} />}
      </div>
      <div className="text-sm text-slate-500">{getAppDescription(appSpec)}</div>
    </div>
  );
};

export default AppCard;
