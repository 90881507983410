import Button from '@cohort/merchants/components/buttons/Button';
import Editabletext from '@cohort/merchants/components/form/Editabletext';
import useBackNavigationStore from '@cohort/merchants/hooks/stores/backNavigation';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {ArrowLeft} from '@phosphor-icons/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';

type BreadcrumbProps = {
  title: string;
  onBackClick?: () => {preventNavigate?: boolean} | void;
  handleTitleChange?: (value: string) => void;
  editableTextPrefix?: JSX.Element;
  validator?: (value: string) => boolean;
  image?: JSX.Element;
  badges?: JSX.Element[];
  children?: JSX.Element;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  badges,
  children,
  title,
  image,
  handleTitleChange,
  editableTextPrefix,
  validator,
  onBackClick,
}) => {
  const navigate = useNavigate();
  const backPath = useBackNavigationStore(state => state.backPath);

  const handleBackClick = (): void => {
    if (onBackClick !== undefined) {
      const result = onBackClick();

      if (typeof result === 'object' && result.preventNavigate) {
        // skip to allow the caller to handle the navigation
        return;
      }
    }

    navigate(backPath);
  };

  return (
    <div className="w-full border-b bg-white">
      <div className="m-auto max-w-[var(--content-max-width)] px-8">
        <div className="flex min-h-[4rem] w-full items-center justify-between space-x-2 py-2 text-sm font-medium">
          <div className="flex items-center">
            <div className="flex shrink-0 items-center space-x-6">
              <Button
                onClick={handleBackClick}
                variant="secondary"
                className="!px-0 [height:38px] [width:38px]"
              >
                <ArrowLeft className="h-5 w-5 text-slate-400" />
              </Button>
              {image}
            </div>
            <div className={cn('mx-2 flex flex-wrap items-center', image === undefined && 'pl-4')}>
              {handleTitleChange === undefined ? (
                <span className="text-base font-semibold">{title}</span>
              ) : (
                <div className="flex items-center space-x-2">
                  {editableTextPrefix}
                  <Editabletext value={title} onChange={handleTitleChange} validator={validator} />
                </div>
              )}
              <div className="flex flex-wrap items-center space-x-2 px-2">{badges}</div>
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
