import {DateSchema} from '@cohort/shared/schema/common';
import {PaginationParamsSchema} from '@cohort/shared/schema/common/pagination';
import {z} from 'zod';

export const QrReaderASchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  accessToken: z.string(),
  lastScanDate: DateSchema.nullable(),
  url: z.string().url(),
});
export type QrReaderADto = z.infer<typeof QrReaderASchema>;

// Requests

export const CreateQrReadersASchema = z
  .array(
    z.object({
      name: z.string().min(3, 'Name must be at least 3 characters long.'),
    })
  )
  .min(1);
export type CreateQrReadersADto = z.infer<typeof CreateQrReadersASchema>;

export const CreateQrReadersResponseASchema = z.array(QrReaderASchema);
export type CreateQrReadersResponseADto = z.infer<typeof CreateQrReadersResponseASchema>;

export const GetQrScansParamsASchema = PaginationParamsSchema.extend({
  perkId: z.string().uuid().optional(),
});
export type GetQrScansParamsADto = z.infer<typeof GetQrScansParamsASchema>;
