import type {ExecuteActionResponseADto} from '@cohort/admin-schemas/apps';
import type {TestQueryActionStruct} from '@cohort/shared/apps/postgresql/actions/testQuery';
import type {PostgresqlUserPropertyColumn} from '@cohort/shared/apps/postgresql/sync';
import {DateSchema} from '@cohort/shared/schema/common';
import {getValidUserPropertyDataTypes} from '@cohort/shared/schema/common/userAttribute';
import {isEmailValid} from '@cohort/shared/utils/validation';
import type {TFunction} from 'i18next';

export const checkIfTestQueryError = (
  data: ExecuteActionResponseADto<TestQueryActionStruct>,
  t: TFunction
): string | null => {
  if (data.output.error !== undefined) {
    return `${t('errors.invalidQuery')} ${data.output.error}`;
  }
  if (data.output.rows[0] === undefined) {
    return t('errors.noRowsReturned');
  }
  if (
    data.output.rows[0]['email'] === undefined ||
    !isEmailValid(String(data.output.rows[0]['email']))
  ) {
    return t('errors.noEmailOrInvalidEmail');
  }
  if (
    data.output.rows[0]['updated_at'] === undefined ||
    !DateSchema.safeParse(data.output.rows[0]['updated_at']).success
  ) {
    return t('errors.noUpdatedAtOrInvalidDate');
  }
  return null;
};

export type PostgresqlUserPropertyColumnWithSampleData = PostgresqlUserPropertyColumn & {
  sampleData: string[];
};
export const getUserPropertyColumnsFromRows = (
  rows: Record<string, unknown>[]
): PostgresqlUserPropertyColumnWithSampleData[] => {
  if (rows.length === 0) {
    return [];
  }

  const result: PostgresqlUserPropertyColumnWithSampleData[] = [];

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const columnNames = Object.keys(rows[0]!).filter(
    columnName => columnName !== 'email' && columnName !== 'updated_at'
  );
  columnNames.forEach(columnName => {
    const values = rows.map(row => row[columnName]);
    const matchingDataTypes = getValidUserPropertyDataTypes(values);

    // split on camel case boundaries, underscores, and hyphens
    const propertyName = columnName
      .split(/(?=[A-Z])|[_-]/u)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');

    result.push({
      dataType: matchingDataTypes[0] ?? 'string',
      columnName,
      referenceId: columnName,
      propertyName,
      sampleData: values.slice(0, 3).map(value => String(value)),
    });
  });

  return result;
};
