import type {ConnectionADto} from '@cohort/admin-schemas/connection';
import {useApps} from '@cohort/merchants/apps/useApps';
import {Dialog, DialogContent} from '@cohort/merchants/components/modals/Dialog';
import {getAppConnectionsRoute} from '@cohort/merchants/lib/Pages';
import type {AppId} from '@cohort/shared/apps';
import type {AppStruct} from '@cohort/shared/apps/app';
import {Fragment} from 'react';
import {useNavigate} from 'react-router-dom';

type EditConnectionModalProps<T extends AppStruct> = {
  onClose: () => void;
  appId: T['Id'];
  connectionToEdit?: ConnectionADto;
};

const EditConnectionModal = <T extends AppStruct>({
  appId,
  onClose,
  connectionToEdit,
}: EditConnectionModalProps<T>): React.ReactElement => {
  const {getApp} = useApps();
  const navigate = useNavigate();
  const app = getApp(appId);

  const handleOnClose = (connection: ConnectionADto | null): void => {
    onClose();
    if (connection) {
      navigate(getAppConnectionsRoute(appId as AppId).path);
    }
  };

  if (app === null || !app.ConnectionEditComponent) {
    return <Fragment />;
  }
  return (
    <Dialog onOpenChange={onClose} open>
      <DialogContent displayCloseButton={false} className="min-w-[50%]">
        <app.ConnectionEditComponent
          existingConnection={connectionToEdit}
          onClose={handleOnClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditConnectionModal;
