import {z} from 'zod';

export const StripeCountries = {
  AU: 'Australia',
  AT: 'Austria',
  BE: 'Belgium',
  BR: 'Brazil',
  BG: 'Bulgaria',
  CA: 'Canada',
  HR: 'Croatia',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  EE: 'Estonia',
  FI: 'Finland',
  FR: 'France',
  DE: 'Germany',
  GI: 'Gibraltar',
  GR: 'Greece',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IE: 'Ireland',
  IT: 'Italy',
  LV: 'Latvia',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MY: 'Malaysia',
  MT: 'Malta',
  MX: 'Mexico',
  NL: 'Netherlands',
  NZ: 'New Zealand',
  NO: 'Norway',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SG: 'Singapore',
  SK: 'Slovakia',
  SI: 'Slovenia',
  ES: 'Spain',
  SE: 'Sweden',
  CH: 'Switzerland',
  TH: 'Thailand',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
} as const;

export const StripeConnectAccountDayOfWeekSchema = z.enum([
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]);

export const StripeCountryCodeSchema = z
  .string()
  .length(2)
  .refine(val => val in StripeCountries, {
    message: `Must be one of ${Object.keys(StripeCountries)}`,
  });

export type StripeCountryCode = keyof typeof StripeCountries;

export const StripeBalanceSchema = z.object({
  amount: z.number(),
  currency: z.string().length(3),
});
export type StripeBalanceDto = z.infer<typeof StripeBalanceSchema>;
