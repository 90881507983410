import Input from '@cohort/merchants/components/form/input/Input';
import type {FieldValues} from 'react-hook-form';

type UrlInputProps<T extends FieldValues> = React.ComponentProps<typeof Input<T>>;

const UrlInputPrefix: React.FC = () => (
  <div className="pointer-events-none absolute inset-y-0 left-0 mb-px ml-px mt-px flex items-center rounded-bl-md rounded-tl-md border-r bg-slate-50 px-3 text-sm text-slate-500">
    https://
  </div>
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function UrlInput<T extends FieldValues>(props: UrlInputProps<T>): JSX.Element {
  return (
    <Input
      insetPrefix={{
        component: <UrlInputPrefix />,
        size: 75,
      }}
      {...props}
      type="text"
    />
  );
}
