import Button from '@cohort/merchants/components/buttons/Button';
import BaseTextarea from '@cohort/merchants/components/form/textarea/BaseTextarea';
import {CsvUpload} from '@cohort/merchants/components/form/v2/CsvUpload';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {
  filterValidAndUniqueEmailsFromCsv,
  parseCsvFile,
  splitByCommaOrNewLine,
} from '@cohort/merchants/lib/form/utils';
import {isEmailValid} from '@cohort/shared/utils/validation';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';

type ImportUsersMode = 'upload' | 'textarea';

type UploadStepProps = {
  setCsvHeaders: (headers: string[] | undefined) => void;
  setCsvUsers: (users: Record<string, string | undefined>[] | undefined) => void;
};
const UploadStep: React.FC<UploadStepProps> = ({setCsvHeaders, setCsvUsers}) => {
  const {t} = useTranslation('components', {keyPrefix: 'modals.importUsersModal.uploadStep'});

  const [importUsersMode, setImportUsersMode] = useState<ImportUsersMode>('upload');
  const [noLinesWithEmailError, setNoLinesWithEmailError] = useState(false);

  const parseTextareaContent = (content: string): Array<string> => {
    const lines = splitByCommaOrNewLine(content);
    const formatedLines = lines
      .map(line => line.trim())
      .filter(line => line !== '' && isEmailValid(line));
    const uniqueLines = Array.from(new Set(formatedLines));
    return uniqueLines;
  };

  const onCsvReset = (): void => {
    setCsvHeaders(undefined);
    setCsvUsers(undefined);
  };

  const switchToMode: ImportUsersMode = importUsersMode === 'upload' ? 'textarea' : 'upload';
  const switchToModeText =
    importUsersMode === 'upload' ? t('switchToTextarea') : t('switchToUpload');

  return (
    <Fragment>
      <p className="text-base font-medium text-gray-900">{t('title')}</p>
      <p className="pb-6 text-sm text-slate-700">{t('subtitle')}</p>
      <div className="flex-1">
        {importUsersMode === 'upload' ? (
          <Fragment>
            {noLinesWithEmailError && (
              <div className="mb-6">
                <HighlightText type="error" text={t('noLinesWithEmailError')} />
              </div>
            )}
            <CsvUpload
              onParse={(lines: string[][]) => {
                const {headers, items: rows} = parseCsvFile({lines});
                const users = filterValidAndUniqueEmailsFromCsv(headers, rows);
                if (users.length === 0) {
                  setNoLinesWithEmailError(true);
                  onCsvReset();
                  return;
                }
                setNoLinesWithEmailError(false);
                setCsvHeaders(headers);
                setCsvUsers(users);
              }}
              fileSizeLimitInMb={10}
              onReset={onCsvReset}
            />
          </Fragment>
        ) : (
          <BaseTextarea
            name="csv"
            label={t('labelEmails')}
            placeholder={t('placeholderEmails')}
            rows={5}
            error={undefined}
            onChange={event => {
              const lines = parseTextareaContent(event.target.value);
              setCsvHeaders(['email']);
              setCsvUsers(
                lines.map(line => {
                  return {email: line};
                })
              );
            }}
          />
        )}
        <div>
          <Button
            className="px-0"
            variant="link"
            onClick={() => {
              setImportUsersMode(switchToMode);
              setNoLinesWithEmailError(false);
              onCsvReset();
            }}
          >
            {switchToModeText}
          </Button>
        </div>
      </div>
    </Fragment>
  );
};
export default UploadStep;
