import type {DigitalAssetCardProps} from '@cohort/components-xps/components/cards/DigitalAssetCard';
import {DigitalAssetCard} from '@cohort/components-xps/components/cards/DigitalAssetCard';
import type {DefaultContextDto} from '@cohort/shared/schema/templating/common';
import {makeDigitalAssetContext} from '@cohort/shared/utils/templating';

export type DigitalAssetReward = {
  id: string;
  title: string;
  description: string;
  smartContract: {
    blockExplorerUrl: string | null;
  } | null;
  imageKey: string;
  animationKey: string | null;
  maxOwnershipsPerUser: number | null;
};

type DigitalAssetRewardProps = {
  collection: DigitalAssetReward;
  customComponent?: {
    template: string;
    context: DefaultContextDto;
  };
} & Omit<DigitalAssetCardProps, 'digitalAsset' | 'customComponent'>;

const DigitalAssetReward: React.FC<DigitalAssetRewardProps> = ({
  collection,
  customComponent,
  ...props
}) => {
  let customComponentConfig = undefined;

  if (customComponent) {
    customComponentConfig = {
      template: customComponent.template,
      context: {
        digitalAsset: makeDigitalAssetContext({
          id: null,
          digitalAsset: {
            collection: {
              title: collection.title,
              description: collection.description,
              imageKey: collection.imageKey,
              animationKey: collection.animationKey,
              smartContract: collection.smartContract
                ? {
                    address: collection.smartContract.blockExplorerUrl,
                  }
                : null,
            },
            tokenId: null,
            imageFileKey: collection.imageKey,
            animationFileKey: collection.animationKey,
          },
          createdAt: null,
        }),
        ...customComponent.context,
      },
    };
  }
  return (
    <DigitalAssetCard
      digitalAsset={{
        animationFileKey: collection.animationKey,
        imageFileKey: collection.imageKey,
        collection: {
          title: collection.id,
        },
        nft: {
          blockExplorerUrl: collection.smartContract?.blockExplorerUrl ?? null,
        },
        tokenId: null,
      }}
      customComponent={customComponentConfig}
      {...props}
    />
  );
};

export default DigitalAssetReward;
