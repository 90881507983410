import type {CampaignADto} from '@cohort/admin-schemas/campaign';
import type {
  JourneyParticipationADto,
  JourneyParticipationsOrderBy,
} from '@cohort/admin-schemas/journeyParticipation';
import JourneyParticipationStatusBadge from '@cohort/merchants/components/campaigns/journeys/JourneyParticipationStatusBadge';
import JourneyParticipationStepper from '@cohort/merchants/components/campaigns/journeys/JourneyParticipationStepper';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {useJourneySteps} from '@cohort/merchants/hooks/api/Campaigns';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {formatDate} from '@cohort/shared/utils/format';
import {CaretDown, CaretUp} from '@phosphor-icons/react';
import {createColumnHelper, getExpandedRowModel} from '@tanstack/react-table';
import React from 'react';
import {useTranslation} from 'react-i18next';

type JourneyParticipationsTableProps = {
  journeyParticipations: Array<JourneyParticipationADto>;
  isLoading: boolean;
  campaign: CampaignADto;
  orderBy: JourneyParticipationsOrderBy;
  setOrderBy: (orderBy: JourneyParticipationsOrderBy) => void;
};

const JourneyParticipationsTable: React.FC<JourneyParticipationsTableProps> = ({
  journeyParticipations,
  isLoading,
  campaign,
  orderBy,
  setOrderBy,
}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.activity.journey.journeyParticipationsTable',
  });

  const {data: journeySteps} = useJourneySteps(merchant.id, campaign.id);
  const columnHelper = createColumnHelper<JourneyParticipationADto>();
  const columns = [
    columnHelper.accessor('userEmail', {
      header: t('tableHeaderEmail'),
      cell: data => <span className="font-medium text-foreground">{data.getValue()}</span>,
      enableSorting: false,
      minSize: 250,
    }),
    columnHelper.accessor(row => formatDate(row.createdAt), {
      id: 'createdAt',
      header: t('tableHeaderDate'),
      minSize: 200,
    }),
    columnHelper.accessor('status', {
      id: 'completedAt',
      header: t('tableHeaderStatus'),
      cell: data => <JourneyParticipationStatusBadge status={data.getValue()} />,
      enableSorting: false,
      minSize: 200,
    }),
    columnHelper.accessor(
      row =>
        `${
          row.stepCompletionAttempts.filter(attempt => attempt.success).length
        }/${journeySteps?.length}`,
      {
        id: 'stepCompletionAttempts',
        header: t('tableHeaderSteps'),
        enableSorting: false,
        minSize: 150,
      }
    ),
    columnHelper.display({
      id: 'details',
      header: () => null,
      cell: ({row}) => (
        <button onClick={row.getToggleExpandedHandler()}>
          {row.getIsExpanded() ? (
            <span className="font-medium text-primary">
              {t('buttonHideDetails')}
              <CaretUp size={16} className="ml-1 inline" />
            </span>
          ) : (
            <span className="font-medium text-primary">
              {t('buttonShowDetails')}
              <CaretDown size={16} className="ml-1 inline" />
            </span>
          )}
        </button>
      ),
    }),
  ];
  const table = useCohortTable(
    {
      data: journeyParticipations,
      columns,
      getRowCanExpand: () => true,
      getExpandedRowModel: getExpandedRowModel(),
    },
    {
      sortBy: orderBy,
      onSortUpdate: setOrderBy,
    }
  );

  return (
    <DataTable
      table={table}
      columnsLength={columns.length}
      isLoading={isLoading}
      emptyStatePlaceholder={t('emptyPlaceholder')}
      renderExpandedRow={({row}) => (
        <JourneyParticipationStepper journeyParticipation={row.original} />
      )}
    />
  );
};

export default JourneyParticipationsTable;
