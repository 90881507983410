/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getNotificationConfigurations} from '@cohort/merchants/lib/api/Notifications';

export const notificationConfigurationsKeys = {
  notificationConfigurations: ['notificationConfigurations'] as const,
  list: (merchantId: string) =>
    [...notificationConfigurationsKeys.notificationConfigurations, merchantId] as const,
};

export const useNotificationConfigurations = (merchantId: string) =>
  useCohortQuery({
    queryKey: notificationConfigurationsKeys.list(merchantId),
    queryFn: async () => {
      return await getNotificationConfigurations(merchantId);
    },
  });
