import type {CohortADto} from '@cohort/admin-schemas/cohort';
import Button from '@cohort/merchants/components/buttons/Button';
import {CohortTypeBadge} from '@cohort/merchants/components/cohorts/CohortTypeBadge';
import EmptyState from '@cohort/merchants/components/EmptyState';
import {IconBadge} from '@cohort/merchants/components/IconBadge';
import Loader from '@cohort/merchants/components/Loader';
import CreateCohortModal from '@cohort/merchants/components/modals/CreateCohortModal';
import SearchInput from '@cohort/merchants/components/SearchInput';
import {useCohortsWithInfiniteQuery} from '@cohort/merchants/hooks/api/Cohorts';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import type {CohortType} from '@cohort/shared/schema/common/cohort';
import type {PaginationDto} from '@cohort/shared/schema/common/pagination';
import {Plus, Users} from '@phosphor-icons/react';
import type {InfiniteData} from '@tanstack/react-query';
import type {ChangeEvent} from 'react';
import {Fragment, useEffect, useState} from 'react';
import {flushSync} from 'react-dom';
import type {Path, UseFormRegister} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useInView} from 'react-intersection-observer';
import {match, P} from 'ts-pattern';

const PAGE_SIZE = 10;

type SelectCohortProps<TFieldValues extends {cohortId: string | null}> = {
  register: UseFormRegister<TFieldValues>;
  onChange?: (cohort: CohortADto) => void;
  allowCohortCreation?: boolean;
  type?: CohortType;
  height?: number;
};
const SelectCohort = <TFieldValues extends {cohortId: string | null}>({
  register,
  onChange,
  allowCohortCreation = false,
  type,
  height = 320,
}: SelectCohortProps<TFieldValues>): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const [search, setSearch] = useState('');
  const [createCohortModalOpen, setCreateCohortModalOpen] = useState(false);
  const {ref, inView} = useInView();

  const {t} = useTranslation('components', {keyPrefix: 'cohorts.selectCohort'});

  function handleCreateCohortClick(): void {
    setCreateCohortModalOpen(true);
  }

  const {
    data: cohortsData,
    fetchNextPage,
    hasNextPage,
    status,
    refetch,
    isFetching,
  } = useCohortsWithInfiniteQuery(merchantId, PAGE_SIZE, search, type);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (onChange === undefined || !event.target.checked || cohortsData === undefined) {
      return;
    }

    for (const page of cohortsData.pages) {
      const foundCohort = page.data.find(cohort => cohort.id === event.target.value);
      if (foundCohort) {
        onChange(foundCohort);
        break;
      }
    }
  };

  const emptyGuard = (
    cohortsData: InfiniteData<{data: CohortADto[]; meta: PaginationDto}> | undefined
  ): boolean => cohortsData !== undefined && cohortsData.pages[0]?.data.length === 0;

  const content = match({cohortsData, status, isFetching})
    .with(
      {
        cohortsData: P.select(P.when(emptyGuard)),
        isFetching: false,
      },
      () => <EmptyState title={t('titleEmpty')} description={t('descriptionEmpty')} />
    )
    .otherwise(() => (
      <div className="no-scrollbar overflow-y-auto" style={{maxHeight: height}}>
        <ul>
          {cohortsData?.pages.map((cohorts, pageIdx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={pageIdx}>
              {cohorts.data.map(cohort => (
                <li key={cohort.id} className="border-t-2 border-slate-100 py-4">
                  <label className="flex w-full items-center gap-4">
                    <input
                      data-testid="cohortId"
                      type="radio"
                      className="form-radio ml-1 h-4 w-4 text-primary focus:ring-primary"
                      value={cohort.id}
                      {...register('cohortId' as Path<TFieldValues>)}
                      onChange={handleChange}
                    />
                    <div className="flex flex-1 items-center justify-between gap-6">
                      <section className="flex  items-center truncate">
                        <p className="truncate text-sm font-medium">{cohort.name}</p>
                      </section>
                      <section className="flex items-center gap-4 truncate">
                        <IconBadge
                          key="users-badge"
                          icon={<Users className="mr-1.5 h-4 w-4 text-slate-500" />}
                          backgroundColor="bg-slate-50"
                          text={`${cohort.usersCount ?? 0}`}
                          size="small"
                        />
                        <CohortTypeBadge key="type-badge" type={cohort.type} size="small" />
                      </section>
                    </div>
                  </label>
                </li>
              ))}
            </Fragment>
          ))}
          <li ref={ref}></li>
        </ul>
        {isFetching && (
          <div className="flex justify-center">
            <Loader size={30} color="gray" />
          </div>
        )}
      </div>
    ));

  return (
    <div>
      <div className="mb-4 mt-4 flex w-full justify-between">
        <div className="flex items-center space-x-2">
          <SearchInput
            value={search}
            onChange={value => {
              flushSync(() => setSearch(value));
              refetch();
            }}
            placeholder={t('placeholderSearch')}
            delay={300}
            className="!h-9 w-64"
          />
        </div>
        {allowCohortCreation && (
          <div>
            <Button key="createCohort" variant="secondary" onClick={handleCreateCohortClick}>
              <Plus className="-ml-1 mr-2 h-5 w-5" />
              {t('buttonCreateCohort')}
            </Button>
          </div>
        )}
      </div>
      {content}

      {createCohortModalOpen && (
        <CreateCohortModal onClose={() => setCreateCohortModalOpen(false)} />
      )}
    </div>
  );
};

export default SelectCohort;
