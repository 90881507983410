/* eslint-disable react-refresh/only-export-components */
import type {TriggerIntegration} from '@cohort/merchants/apps';
import type {AppSpec, AppStruct} from '@cohort/shared/apps/app';
import type {
  ConnectAccountTriggerIntegrationStruct,
  ConnectAccountVerificationAttemptData,
} from '@cohort/shared/apps/trigger';
import {createConnectAccountTriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import {Fragment} from 'react';
import {Trans} from 'react-i18next';

const ConfigComponent: React.FC = () => <Fragment />;

function getStepCompletionTitleComponent(appSpec: AppSpec<AppStruct<string>>): React.FC<{
  data: ConnectAccountVerificationAttemptData;
  completionSuccess: boolean;
}> {
  return ({data, completionSuccess}) => {
    if (!completionSuccess) {
      return (
        <Trans
          i18nKey="common.triggers.connectAccount.stepCompletionTitleComponent.error"
          ns="apps"
          values={{appName: appSpec.name}}
        />
      );
    }

    return (
      <Trans
        i18nKey="common.triggers.connectAccount.stepCompletionTitleComponent.success"
        ns="apps"
        values={{displayName: data.displayName, appName: appSpec.name}}
      />
    );
  };
}

export function createConnectAccountTriggerIntegration<Id extends string>(
  appSpec: AppSpec<AppStruct<Id>>
): TriggerIntegration<ConnectAccountTriggerIntegrationStruct<`${Id}.connect-account`>> {
  return {
    spec: createConnectAccountTriggerIntegrationSpec(appSpec.id),
    configComponent: ConfigComponent,
    stepCompletionTitleComponent: getStepCompletionTitleComponent(appSpec),
    getTitle: t => t('common.triggers.connectAccount.title', {ns: 'apps', appName: appSpec.name}),
  };
}
