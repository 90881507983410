import type {GetDigitalAssetCollectionsParamsADto} from '@cohort/admin-schemas/digitalAssetCollection';
import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import CardListDisplayToggler from '@cohort/merchants/components/buttons/CardListDisplayToggler';
import DigitalAssetCollectionsSortBy from '@cohort/merchants/components/digital-assets/DigitalAssetCollectionSortBy';
import Filters from '@cohort/merchants/components/filters/Filters';
import {useDigitalAssetCollections} from '@cohort/merchants/hooks/api/DigitalAssetCollections';
import {
  statusFilter,
  useDigitalAssetCollectionsListFilters,
} from '@cohort/merchants/hooks/filters/digitalAssetCollectionsListFilters';
import {useDigitalAssetCollectionsDisplay} from '@cohort/merchants/hooks/stores/digitalAssetCollectionsDisplay';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import {
  trackDigitalAssetCollectionCreateButtonClicked,
  trackDigitalAssetCollectionsDisplayChanged,
  trackDigitalAssetCollectionsOverviewPageViewed,
  trackDigitalAssetCollectionsStatusFilterChanged,
} from '@cohort/merchants/lib/Tracking';
import CreateDigitalAssetCollectionModal from '@cohort/merchants/pages/digital-asset-collections/overview/CreateDigitalAssetCollectionModal';
import DigitalAssetCollectionsList from '@cohort/merchants/pages/digital-asset-collections/overview/DigitalAssetsCollectionList';
import RewardsMenu from '@cohort/merchants/pages/rewards/RewardsMenu';
import {Plus} from '@phosphor-icons/react';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {shallow} from 'zustand/shallow';

const PAGE_SIZE = 20;

const DigitalAssetCollectionsOverviewPage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {t} = useTranslation('pages', {keyPrefix: 'digitalAssetCollections.overview.page'});
  const {display, setDisplay} = useDigitalAssetCollectionsDisplay(
    store => ({
      display: store.display,
      setDisplay: store.setDisplay,
    }),
    shallow
  );
  const filters = useDigitalAssetCollectionsListFilters(/^(\/test)?\/digital-assets$/u);
  const [searchParams] = useSearchParams();
  const [needPolling, setNeedPolling] = useState(false);

  const [createDigitalAssetCollectionModalOpen, setCreateDigitalAssetCollectionModalOpen] =
    useState(() => searchParams.has('create'));

  // i18nOwl-ignore [status.live, status.ready]
  const statusFilterOptions = statusFilter.map(status => ({
    value: status,
    label: t(`status.${status}`),
    active: filters.status ? filters.status.includes(status) : false,
  }));

  const {data, isLoading, isError} = useDigitalAssetCollections(
    merchantId,
    {
      status: filters.status,
      page: filters.page,
      pageSize: PAGE_SIZE,
      orderBy: filters.sort,
    },
    {enabled: true, autoRefresh: needPolling}
  );
  const [pagination, digitalAssetCollections] = data ?? [];

  useEffect(() => {
    if (
      digitalAssetCollections &&
      digitalAssetCollections.some(
        collection => collection.nftEnabled && collection.smartContract === null
      )
    ) {
      setNeedPolling(true);
    } else if (needPolling) {
      setNeedPolling(false);
    }
  }, [digitalAssetCollections, needPolling]);

  useEffect(() => {
    trackDigitalAssetCollectionsOverviewPageViewed();
  }, []);

  function handleCreateDigitalAssetCollectionClick(): void {
    setCreateDigitalAssetCollectionModalOpen(true);
    trackDigitalAssetCollectionCreateButtonClicked();
  }

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
    topRightElements: (
      <Button
        key="createDigitalAssetCollection"
        onClick={handleCreateDigitalAssetCollectionClick}
        data-testid="create-digital-asset-collection-btn"
      >
        <Plus className="-ml-1 mr-2 h-5 w-5" />
        {t('buttonAddDigitalAssetCollection')}
      </Button>
    ),
  };

  const paginationConfig = {
    pagination,
    onPageChange: filters.setPage,
  };

  return (
    <PageLayout title={t('title')} menu={<RewardsMenu />}>
      <AdminPage header={headerConfig} pagination={paginationConfig}>
        <div className="mb-7 flex w-full flex-col justify-between space-x-2 sm:flex-row sm:items-center">
          <Filters
            filters={[
              {
                label: t('filterByStatus'),
                options: statusFilterOptions,
                type: 'multiple',
                onChange: statusOptions => {
                  const activeFilterOptions = statusOptions.filter(o => o.active);

                  trackDigitalAssetCollectionsStatusFilterChanged(
                    activeFilterOptions.map(o => o.value)
                  );
                  filters.setStatus(
                    activeFilterOptions.map(
                      o => o.value
                    ) as GetDigitalAssetCollectionsParamsADto['status']
                  );
                },
              },
            ]}
          />
          <div className="flex flex-row items-center space-x-4">
            <DigitalAssetCollectionsSortBy filters={filters} />
            <CardListDisplayToggler
              display={display}
              setDisplay={display => {
                trackDigitalAssetCollectionsDisplayChanged(display);
                setDisplay(display);
              }}
            />
          </div>
        </div>
        <DigitalAssetCollectionsList
          digitalAssetCollections={digitalAssetCollections}
          isError={isError}
          isLoading={isLoading}
          onHandleCreateDigitalAssetCollectionClick={handleCreateDigitalAssetCollectionClick}
        />
        {createDigitalAssetCollectionModalOpen && (
          <CreateDigitalAssetCollectionModal
            onClose={() => setCreateDigitalAssetCollectionModalOpen(false)}
          />
        )}
      </AdminPage>
    </PageLayout>
  );
};

export default DigitalAssetCollectionsOverviewPage;
