import {z} from 'zod';

export const notificationConfigurationResourceTypes = [
  'airdrop',
  'badge',
  'perk',
  'digitalAssetCollection',
  'journey',
  'store',
] as const;

export const NotificationConfigurationResourceTypeSchema = z.enum(
  notificationConfigurationResourceTypes
);

export type NotificationConfigurationResourceType = z.infer<
  typeof NotificationConfigurationResourceTypeSchema
>;

export const NotificationStatusSchema = z.enum(['success', 'error']);
export type NotificationStatus = z.infer<typeof NotificationStatusSchema>;
