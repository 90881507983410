import Button from '@cohort/merchants/components/buttons/Button';
import Input from '@cohort/merchants/components/form/input/Input';
import TextEditorInput from '@cohort/merchants/components/form/textEditor/TextEditorInput';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetTitle,
} from '@cohort/merchants/components/modals/Sheet';
import type {FaqItem} from '@cohort/shared/schema/common/campaign';
import {zodResolver} from '@hookform/resolvers/zod';
import {X} from '@phosphor-icons/react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

type CampaignSettingsFaqModalProps = {
  title: string;
  faq?: FaqItem;
  onClose: () => void;
  onSave: (data: CampaignFaqFormValues) => void;
};

const CampaignFaqFormSchema = z.object({
  question: z.string().min(3, {message: 'errorTooShort3'}),
  answer: z.string().min(10, {message: 'errorTooShort3'}),
});

export type CampaignFaqFormValues = z.infer<typeof CampaignFaqFormSchema>;

const CampaignSettingsFaqModal: React.FC<CampaignSettingsFaqModalProps> = ({
  title,
  faq,
  onClose,
  onSave,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.campaignSettingsFaqModal',
  });
  const {register, control, handleSubmit} = useForm<CampaignFaqFormValues>({
    defaultValues: {
      question: faq?.question ?? '',
      answer: faq?.answer ?? '',
    },
    resolver: zodResolver(CampaignFaqFormSchema),
  });

  return (
    <Sheet open onOpenChange={onClose}>
      <SheetContent>
        <form
          id="campaign-form-faq"
          className="grid h-full [grid-template-rows:max-content_1fr_max-content]"
          onSubmit={e => {
            // otherwise it will submit the main campaign form (dunno why since i'm using the form attribute in the button)
            e.stopPropagation();
            handleSubmit(data => onSave(data))(e);
          }}
        >
          <SheetTitle className="p-6 text-lg font-medium">{title}</SheetTitle>
          <div className="flex h-full flex-col gap-6 overflow-y-scroll px-6">
            <Input
              name="question"
              placeholder={t('placeholderQuestion')}
              label={t('labelQuestion')}
              control={control}
              register={register}
            />
            <TextEditorInput
              name="answer"
              label={t('labelAnswer')}
              placeholder={t('placeholderAnswer')}
              register={register}
              control={control}
            />
          </div>

          <SheetFooter className="flex justify-end gap-4 border-t p-4">
            <Button variant="secondary" onClick={onClose} data-testid="cancel">
              <X className="-ml-1 mr-2 h-5 w-5" />
              {t('buttonCancel')}
            </Button>
            <Button form="campaign-form-faq" type="submit" data-testid="modal-faq-save">
              {faq ? t('buttonEdit') : t('buttonAdd')}
            </Button>
          </SheetFooter>
        </form>
      </SheetContent>
    </Sheet>
  );
};

export default CampaignSettingsFaqModal;
