import {create} from 'zustand';
import {persist} from 'zustand/middleware';

type CampaignsDisplay = 'card' | 'list';

interface CampaignsDisplayStore {
  display: CampaignsDisplay;
  setDisplay: (display: CampaignsDisplay) => void;
}

export const useCampaignsDisplay = create<CampaignsDisplayStore>()(
  persist(
    set => ({
      display: 'card',
      setDisplay: display => set({display}),
    }),
    {
      name: 'campaigns-display',
    }
  )
);
