import type {
  ContentCategoryADto,
  ReorderContentCategoriesADto,
} from '@cohort/admin-schemas/contentCategory';
import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import EmptyState from '@cohort/merchants/components/EmptyState';
import ErrorState from '@cohort/merchants/components/ErrorState';
import {IconBadge} from '@cohort/merchants/components/IconBadge';
import LoadingState from '@cohort/merchants/components/LoadingState';
import ReorderItemsDrawer from '@cohort/merchants/components/ReorderItemsDrawer';
import {
  contentCategoriesKeys,
  useContentCategories,
} from '@cohort/merchants/hooks/api/ContentCategories';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {notify} from '@cohort/merchants/hooks/toast';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import {
  createContentCategory,
  reorderContentCategories,
} from '@cohort/merchants/lib/api/ContentCategories';
import ContentCategoriesList from '@cohort/merchants/pages/content-categories/overview/ContentCategoriesList';
import type {ContentCategoryFormValues} from '@cohort/merchants/pages/content-categories/overview/CreateEditContentCategoryModal';
import CreateEditContentCategoryModal from '@cohort/merchants/pages/content-categories/overview/CreateEditContentCategoryModal';
import ContentsOverviewMenu from '@cohort/merchants/pages/contents/overview/ContentsOverviewMenu';
import {CirclesFour, ListNumbers, Plus} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {match, P} from 'ts-pattern';

const ContentCategoriesOverviewPage: React.FC = () => {
  const [showContentCategoryModal, setShowContentCategoryModal] = useState(false);
  const [showReorderCategoriesDrawer, setShowReorderCategoriesDrawer] = useState(false);
  const {t} = useTranslation('pages', {keyPrefix: 'content-categories.overview.page'});
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();

  const {data: contentCategories, isLoading, isError} = useContentCategories(merchant.id);
  const contentCategoriesCounter = contentCategories?.length ?? 0;

  const {isLoading: isReorderingCategories, mutateAsync: reorderCategoriesMutation} =
    useCohortMutation({
      mutationFn: async (data: ReorderContentCategoriesADto) =>
        reorderContentCategories(merchant.id, data),
      notifyErrorMessage: t('notificationOrderErrorUpdate'),
      notifySuccessMessage: t('notificationOrderUpdateSuccess'),
      onSuccess: () => {
        queryClient.invalidateQueries(contentCategoriesKeys.list(merchant.id));
        setShowReorderCategoriesDrawer(false);
      },
    });

  const headerConfig = {
    title: t('title'),
    ...(contentCategoriesCounter > 0 && {
      titleBadge: (
        <IconBadge
          key="contentCategories"
          icon={<CirclesFour className="mr-1.5 h-4 w-4 text-slate-500" />}
          text={t('contentCategoriesBadge', {count: contentCategoriesCounter})}
        />
      ),
    }),
    subtitle: t('subtitle'),
    topRightElements: (
      <div className="space-x-4">
        <Button
          key="reorderBadge"
          onClick={() => setShowReorderCategoriesDrawer(true)}
          variant="secondary"
        >
          <ListNumbers className="-ml-1 mr-2 h-5 w-5" />
          {t('buttonReorderCategories')}
        </Button>
        <Button
          key="createContent"
          onClick={() => setShowContentCategoryModal(true)}
          data-testid="create-content-category"
        >
          <Plus className="-ml-1 mr-2 h-5 w-5" />
          {t('buttonCreateContentCategory')}
        </Button>
      </div>
    ),
  };

  const contentCategoriesGuard = (
    contentCategories: Array<ContentCategoryADto> | undefined
  ): contentCategories is Array<ContentCategoryADto> =>
    contentCategories !== undefined && contentCategories.length > 0;

  const {isLoading: isCreationLoading, mutate: createContentCategoryMutation} = useCohortMutation({
    mutationFn: async (data: ContentCategoryFormValues) => createContentCategory(merchant.id, data),
    onSuccess: async () => {
      queryClient.invalidateQueries(contentCategoriesKeys.list(merchant.id));
      setShowContentCategoryModal(false);
      notify('success', t('createContentCategorySuccess'));
    },
  });

  const content = match({contentCategories, isLoading, isError})
    .with({isLoading: true}, () => <LoadingState />)
    .with({isError: true}, () => <ErrorState />)
    .with(
      {
        contentCategories: P.select(P.when(contentCategoriesGuard)),
      },
      contentCategories => <ContentCategoriesList contentCategories={contentCategories} />
    )
    .otherwise(() => (
      <EmptyState
        title={t('titleEmpty')}
        description={t('descriptionEmpty')}
        cta={
          <Button
            onClick={() => setShowContentCategoryModal(true)}
            data-testid="create-content-category-empty"
          >
            <Plus className="-ml-1 mr-2 h-5 w-5" />
            {t('buttonCreateContentCategory')}
          </Button>
        }
      />
    ));

  return (
    <PageLayout title={t('title')} menu={<ContentsOverviewMenu />}>
      <AdminPage header={headerConfig}>{content}</AdminPage>
      {showContentCategoryModal && (
        <CreateEditContentCategoryModal
          onClose={() => setShowContentCategoryModal(false)}
          onSubmit={createContentCategoryMutation}
          isLoading={isCreationLoading}
        />
      )}
      {showReorderCategoriesDrawer && (
        <ReorderItemsDrawer
          title={t('reorderCategoriesTitle')}
          subtitle={t('reorderCategoriesSubtitle')}
          items={contentCategories ?? []}
          onSave={patchData => reorderCategoriesMutation(patchData)}
          onClose={() => setShowReorderCategoriesDrawer(false)}
          nameDisplayer={category => category.internalName}
          isLoading={isReorderingCategories}
        />
      )}
    </PageLayout>
  );
};

export default ContentCategoriesOverviewPage;
