// i18nOwl-ignore [app-discord.description]
import type {App, TriggerIntegration} from '@cohort/merchants/apps';
import {makeOauthEditConnectionComponent} from '@cohort/merchants/apps/appUtils';
import {createConnectAccountTriggerIntegration} from '@cohort/merchants/apps/common/triggers/ConnectAccountTriggerIntegration';
import translationEn from '@cohort/merchants/apps/discord/locales/en.json';
import translationFr from '@cohort/merchants/apps/discord/locales/fr.json';
import {DiscordPrivateAccessPerkIntegration} from '@cohort/merchants/apps/discord/perkIntegrations/privateAccess/PerkIntegration';
import {DiscordHasRoleTriggerIntegration} from '@cohort/merchants/apps/discord/triggerIntegrations/hasRole/TriggerIntegration';
import type {DiscordAppStruct} from '@cohort/shared/apps/discord';
import {DiscordAppSpec} from '@cohort/shared/apps/discord';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

const DiscordApp: App<DiscordAppStruct> = {
  spec: DiscordAppSpec,
  ConnectionEditComponent: makeOauthEditConnectionComponent('discord'),
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="discord" height={40} width={40} />,
  notificationIntegrations: [],
  perkIntegrations: [DiscordPrivateAccessPerkIntegration],
  triggerIntegrations: [
    createConnectAccountTriggerIntegration(DiscordAppSpec) as TriggerIntegration,
    DiscordHasRoleTriggerIntegration as TriggerIntegration,
  ],
  userEvents: [],
};

export default DiscordApp;
