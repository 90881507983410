import {CardWithRadientBackground} from '@cohort/merchants/components/CardWithRadientBackground';
import {useCurrentOrganization} from '@cohort/merchants/hooks/api/Organizations';
import {useOrganizationSlug} from '@cohort/merchants/hooks/useOrganizationSlug';
import {getSignInRoute} from '@cohort/merchants/lib/Pages';
import {getSigninSubdomainUrl} from '@cohort/merchants/lib/Utils';
import ChangePasswordForm from '@cohort/merchants/pages/change-password/ChangePasswordForm';
import OrganizationLogo from '@cohort/merchants/pages/sign-in/OrganizationLogo';
import {Fragment, useEffect} from 'react';
import {Navigate, useSearchParams} from 'react-router-dom';
import {z} from 'zod';

const Params = z.object({
  email: z.string(),
  code: z.string(),
  expiresAt: z.coerce.number(),
});

const ChangePasswordPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const organizationSlug = useOrganizationSlug();
  const {data: organization, error: organizationFetchError} = useCurrentOrganization({
    enabled: Boolean(organizationSlug),
  });

  const params = Params.safeParse({
    email: searchParams.get('email'),
    code: searchParams.get('code'),
    expiresAt: searchParams.get('expiresAt'),
  });

  useEffect(() => {
    if (organizationFetchError) {
      window.location.assign(getSigninSubdomainUrl());
    }
  }, [organizationFetchError]);

  if (!organizationSlug || !params.success) {
    return <Navigate to={getSignInRoute().path} replace />;
  }

  if (!organization) {
    return <Fragment />;
  }

  return (
    <CardWithRadientBackground className="!w-[500px] space-y-0 p-6">
      <div className="flex flex-col space-y-6">
        <OrganizationLogo organization={organization} />
        <ChangePasswordForm {...params.data} />
      </div>
    </CardWithRadientBackground>
  );
};
export default ChangePasswordPage;
