import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {assertConnectionId} from '@cohort/merchants/apps';
import {useYoutubeVideos} from '@cohort/merchants/apps/youtube/actions';
import SelectVideoSheet from '@cohort/merchants/apps/youtube/triggers/SelectVideoSheet';
import Button from '@cohort/merchants/components/buttons/Button';
import ErrorState from '@cohort/merchants/components/ErrorState';
import HighlightText from '@cohort/merchants/components/HighlightText';
import Loader from '@cohort/merchants/components/Loader';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {ArrowsCounterClockwise, Image, ImagesSquare} from '@phosphor-icons/react';
import {Fragment, useEffect, useState} from 'react';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const YoutubeLikeTriggerConfigComponent: React.FC<TriggerIntegrationConfigComponentProps> = ({
  connectionId,
  formReturn,
}) => {
  assertConnectionId(connectionId);

  const merchant = useCurrentMerchant();
  const {setValue, control} = formReturn;
  const {field: videoIdField} = useController({name: 'triggerIntegrationConfig.videoId', control});
  const [selectVideoSheetOpen, setSelectVideoSheetOpen] = useState(false);
  const {t} = useTranslation('app-youtube', {
    keyPrefix: 'triggerIntegrations.like.configComponent',
  });

  const {
    data: videosResponse,
    isLoading: isVideosLoading,
    isFetched: isVideosFetched,
  } = useYoutubeVideos(merchant.id, connectionId, undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const videos = videosResponse?.output.videos ?? [];

  // Set the value of the title to match the videoId
  useEffect(() => {
    const video = videos.find(video => video.id === videoIdField.value);
    if (video === undefined) {
      return;
    }
    setValue('triggerIntegrationConfig.videoTitle', video.title);
  }, [videoIdField, videos, setValue]);

  const formsSelectionContent = match({
    videos,
    isVideosLoading,
    isVideosFetched,
    formIdFieldValue: videoIdField.value,
  })
    .with({isVideosLoading: true}, () => <Loader size={30} color="gray" />)
    .with({videos: []}, () => (
      <div className="flex flex-col space-y-2">
        <p>{t('noVideosMessage')}</p>
      </div>
    ))
    .with({isVideosFetched: true}, () => {
      const selectedVideo = videos.find(video => video.id === videoIdField.value);

      return (
        <Fragment>
          <div>
            <p className="pb-1 text-sm font-medium">{t('titleVideoSelect')}</p>
            <p className="pb-2 text-sm">{t('descriptionVideoSelect')}</p>
            {!videoIdField.value ? (
              <Button variant="ghost" onClick={() => setSelectVideoSheetOpen(true)}>
                <ImagesSquare className="mr-2 h-5 w-5" />
                {t('buttonSelectVideo')}
              </Button>
            ) : (
              <Fragment>
                <div className="flex items-center gap-5">
                  <div className="flex aspect-square w-[120px] items-center justify-center overflow-hidden rounded-lg border border-slate-200 bg-white shadow-sm">
                    {selectedVideo?.thumbnailUrl !== undefined ? (
                      <img src={selectedVideo.thumbnailUrl} />
                    ) : (
                      <Image size={24} className="text-slate-400" />
                    )}
                  </div>
                  <Button variant="secondary" onClick={() => setSelectVideoSheetOpen(true)}>
                    <ArrowsCounterClockwise className="mr-2 h-5 w-5" />
                    {t('buttonChangeVideo')}
                  </Button>
                </div>
                {selectedVideo?.thumbnailUrl === undefined && (
                  <div className="mt-4">
                    <HighlightText type="warning" text={t('videoNotFound')} />
                  </div>
                )}
              </Fragment>
            )}
          </div>
          {selectVideoSheetOpen && (
            <SelectVideoSheet
              formReturn={formReturn}
              videos={videos}
              onClose={() => setSelectVideoSheetOpen(false)}
            />
          )}
        </Fragment>
      );
    })
    .otherwise(() => <ErrorState />);

  return formsSelectionContent;
};

export default YoutubeLikeTriggerConfigComponent;
