import {Apps} from '@cohort/merchants/apps';
import translationEn from '@cohort/merchants/lib/locales/en.json';
import translationFr from '@cohort/merchants/lib/locales/fr.json';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

// Default namespace.
export const defaultNs = 'components';

// Initialize namespaces array with 'components'
const namespaces: string[] = ['components', 'hooks', 'pages'];

export const resources = {
  en: translationEn,
  fr: translationFr,
} as const;

Apps.forEach(async app => {
  const ns = `app-${app.spec.id}`;
  (resources.en as Record<string, unknown>)[ns] = app.locales.en;
  (resources.fr as Record<string, unknown>)[ns] = app.locales.fr;
});

const languageDetector = new LanguageDetector(null, {
  lookupLocalStorage: 'lng-merchants',
});

// eslint-disable-next-line import/no-named-as-default-member
export const i18nInstance = i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: namespaces,
    defaultNS: defaultNs,
    resources,
  });
