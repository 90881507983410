import type {CampaignADto} from '@cohort/admin-schemas/campaign';
import AudienceSectionContentCohort from '@cohort/merchants/pages/campaigns/campaign/edit/review/AudienceSectionContentCohort';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const AudienceSectionContent: React.FC<{campaign: CampaignADto}> = ({campaign}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.review.audienceSectionContent',
  });
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return match(campaign.audience!)
    .with('everyone', () => (
      <Fragment>
        <p className="font-medium">{t('labelEveryone')}</p>
        <p className="text-gray-500">
          {campaign.type === 'airdrop' ? t('descriptionEveryoneAirdrop') : t('descriptionEveryone')}
        </p>
      </Fragment>
    ))
    .with('cohort', () => <AudienceSectionContentCohort campaign={campaign} />)
    .with('invitees', () => (
      <Fragment>
        <p className="font-medium">{t('labelInvitees')}</p>
        <p className="text-gray-500">{t('descriptionInvitees')}</p>
      </Fragment>
    ))
    .exhaustive();
};

export default AudienceSectionContent;
