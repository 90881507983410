import type {CreateNotificationConfigurationADto} from '@cohort/admin-schemas/notification';
import {CreateNotificationConfigurationASchema} from '@cohort/admin-schemas/notification';
import Button from '@cohort/merchants/components/buttons/Button';
import CreateEditNotificationConfigurationModal from '@cohort/merchants/components/notifications/CreateEditNotificationConfigurationModal';
import {notificationConfigurationsKeys} from '@cohort/merchants/hooks/api/Notifications';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {notify} from '@cohort/merchants/hooks/toast';
import {createNotificationConfiguration} from '@cohort/merchants/lib/api/Notifications';
import type {EventId} from '@cohort/shared/apps';
import type {NotificationConfigurationResourceType} from '@cohort/shared/schema/common/notificationConfiguration';
import {PlusCircle} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';

type NotificationSettingsListCreateBtnProps = {
  userEventId: EventId;
  resource?: {
    type: NotificationConfigurationResourceType;
    id: string;
  };
};

const NotificationSettingsListCreateBtn: React.FC<NotificationSettingsListCreateBtnProps> = ({
  userEventId,
  resource,
}) => {
  const [showNotificationCreationModal, setShowNotificationCreationModal] = useState(false);
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();

  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.notifications.notificationSettingsListCreateBtn',
  });

  const {
    isLoading: isCreatingNotificationConfiguration,
    mutate: createNotificationConfigurationMutation,
  } = useCohortMutation({
    mutationFn: async (data: CreateNotificationConfigurationADto) =>
      createNotificationConfiguration(merchant.id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(notificationConfigurationsKeys.list(merchant.id));
    },
  });

  return (
    <Fragment>
      <Button variant="ghost" onClick={() => setShowNotificationCreationModal(true)}>
        <PlusCircle size={20} className="mr-2" /> {t('createNotificationButton')}
      </Button>

      {showNotificationCreationModal && (
        <CreateEditNotificationConfigurationModal
          isLoading={isCreatingNotificationConfiguration}
          userEventId={userEventId}
          onClose={() => setShowNotificationCreationModal(false)}
          resource={resource}
          onSubmit={rawData => {
            const parsedData = CreateNotificationConfigurationASchema.parse(rawData);
            createNotificationConfigurationMutation(parsedData);
            setShowNotificationCreationModal(false);
            notify('success', t('createNotificationSuccess'));
          }}
        />
      )}
    </Fragment>
  );
};

export default NotificationSettingsListCreateBtn;
