import DataTypeIcon from '@cohort/merchants/components/DataTypeIcon';
import EmptyState from '@cohort/merchants/components/EmptyState';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@cohort/merchants/components/tables/Table';
import type {SalesforceUserPropertyColumn} from '@cohort/shared/apps/salesforce/sync';
import {Database} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

type UserPropertyColumnsTableProps = {
  userPropertyColumns: (SalesforceUserPropertyColumn & {sampleData?: string[]})[];
  isQueryConfigured?: boolean;
  hideSampleData?: boolean;
};

const UserPropertyColumnsTable: React.FC<UserPropertyColumnsTableProps> = ({
  userPropertyColumns,
  isQueryConfigured = false,
  hideSampleData = false,
}) => {
  const {t} = useTranslation('app-salesforce', {
    keyPrefix: 'sync.importUsersConfigComponent.userPropertyColumnsTable',
  });

  if (userPropertyColumns.length === 0) {
    return (
      <EmptyState
        className="h-48"
        icon={<Database size={48} className="text-slate-400" />}
        title={isQueryConfigured ? t('emptyDataNoPropertiesTitle') : t('emptyDataNoQueryTitle')}
        description={
          isQueryConfigured
            ? t('emptyDataNoPropertiesDescription')
            : t('emptyDataNoQueryDescription')
        }
      />
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t('tableHeaderColumn')}</TableHead>
          <TableHead>{t('tableHeaderUserProperty')}</TableHead>
          {!hideSampleData && <TableHead>{t('tableHeaderSampleData')}</TableHead>}
        </TableRow>
      </TableHeader>
      <TableBody>
        {userPropertyColumns.map(userPropertyColumn => (
          <TableRow key={userPropertyColumn.referenceId}>
            <TableCell className="text-sm font-medium text-slate-700">
              {userPropertyColumn.columnName}
            </TableCell>
            <TableCell>
              <div className="flex flex-row items-center gap-2">
                <DataTypeIcon dataType={userPropertyColumn.dataType} size={16} />
                {userPropertyColumn.propertyName}
              </div>
            </TableCell>
            {!hideSampleData && (
              <TableCell>
                <ul className="mt-0.5 max-w-48 text-xs text-muted-foreground">
                  {userPropertyColumn.sampleData !== undefined &&
                    userPropertyColumn.sampleData.map((line, index) => (
                      <p
                        // eslint-disable-next-line react/no-array-index-key
                        key={`sample-${index}`}
                        className="overflow-hidden text-ellipsis whitespace-nowrap"
                      >
                        {line}
                      </p>
                    ))}
                </ul>
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default UserPropertyColumnsTable;
