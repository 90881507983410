import type {App} from '@cohort/merchants/apps';
import {getAppsWithTriggerIntegrations} from '@cohort/merchants/apps';
import AppBadge from '@cohort/merchants/apps/AppBadge';
import {useApps} from '@cohort/merchants/apps/useApps';
import {useTriggerIntegrations} from '@cohort/merchants/apps/useTriggerIntegrations';
import Button from '@cohort/merchants/components/buttons/Button';
import ConnectionPicker from '@cohort/merchants/components/connections/ConnectionPicker';
import FieldLabel from '@cohort/merchants/components/form/FieldLabel';
import Input from '@cohort/merchants/components/form/input/Input';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import {handleFormErrors} from '@cohort/merchants/lib/form/utils';
import type {
  StepTriggerFormType,
  StepTriggerType,
} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/journey/utils';
import {
  StepTriggerConnectionRequiredSchema,
  StepTriggerFormSchema,
} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/journey/utils';
import type {AppId, TriggerIntegrationId} from '@cohort/shared/apps';
import type {Language} from '@cohort/shared/schema/common';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {zodResolver} from '@hookform/resolvers/zod';
import {ArrowUUpLeft, Trash} from '@phosphor-icons/react';
import React, {useEffect, useRef} from 'react';
import {useState} from 'react';
import {useController, useForm, useFormState, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

type StepTriggerFormProps = {
  index: number;
  trigger: StepTriggerType;
  onRemove: () => void;
  onSaved: (trigger: StepTriggerType) => void;
  selectedLanguage: Language;
  definedLanguages: Array<Language>;
};

const StepTriggerForm: React.FC<StepTriggerFormProps> = ({
  index,
  trigger,
  onRemove,
  onSaved,
  selectedLanguage,
  definedLanguages,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.settings.journey.stepTriggerForm',
  });
  const {appDisabled} = useApps();
  const {getTriggerIntegration, getTriggerIntegrationTitle, triggerIntegrationDisabled} =
    useTriggerIntegrations();

  const defaultApp = getAppsWithTriggerIntegrations().find(app =>
    app.triggerIntegrations.find(
      integration => integration.spec.id === trigger.triggerIntegrationId
    )
  );
  const [selectedApp, setSelectedApp] = useState<App | undefined>(defaultApp);

  const appsListWithTriggers = getAppsWithTriggerIntegrations().filter(app => {
    // Filter out the apps that are disabled or have no active trigger integrations, unless it's already selected
    if (selectedApp?.spec.id === app.spec.id) {
      return true;
    }
    if (appDisabled(app.spec.id as AppId)) {
      return false;
    }
    const activeIntegrations = app.triggerIntegrations.filter(triggerIntegration => {
      return !triggerIntegrationDisabled(triggerIntegration);
    });
    return activeIntegrations.length > 0;
  });
  const [emptyAppError, setEmptyAppError] = useState(false);
  const emptyAppErrorRef = useRef<HTMLParagraphElement>(null);

  // triggerIntegrationId can be undefined on new trigger form but the typing is not changed
  let triggerIntegrationId = trigger.triggerIntegrationId;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  let triggerIntegration = triggerIntegrationId
    ? getTriggerIntegration(triggerIntegrationId)
    : null;

  const formReturn = useForm<StepTriggerFormType>({
    resolver: zodResolver(StepTriggerFormSchema),
    defaultValues: {
      id: trigger.id ?? null,
      triggerIntegrationId: trigger.triggerIntegrationId,
      triggerIntegrationConfig: trigger.triggerIntegrationConfig
        ? triggerIntegration?.configForm
          ? triggerIntegration.configForm.toForm(trigger.triggerIntegrationConfig)
          : trigger.triggerIntegrationConfig
        : undefined,
      connectionId: trigger.connectionId ?? null,
      withMaxVerificationAttempts: trigger.maxVerificationAttempts !== null,
      maxVerificationAttempts: trigger.maxVerificationAttempts ?? null,
      selectedLanguage,
      definedLanguages,
    },
  });
  const {register, handleSubmit, control, setValue, watch, clearErrors, setError} = formReturn;

  [triggerIntegrationId] = watch(['triggerIntegrationId']);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  triggerIntegration = triggerIntegrationId ? getTriggerIntegration(triggerIntegrationId) : null;
  const {errors} = useFormState({
    control,
    name: 'triggerIntegrationId',
  });
  const withMaxVerificationAttempts = useWatch({
    control,
    name: 'withMaxVerificationAttempts',
  });

  useEffect(() => {
    if (errors.triggerIntegrationId && errors.triggerIntegrationId.type === 'invalid_type') {
      setEmptyAppError(true);
      emptyAppErrorRef.current?.scrollIntoView();
    }
  }, [errors]);

  useEffect(() => {
    // Reset the max attempts count if the trigger integration doesn't support it
    if (!triggerIntegration?.spec.configurableMaxAttempts || !withMaxVerificationAttempts) {
      setValue('maxVerificationAttempts', null);
      setValue('withMaxVerificationAttempts', false);
    }
  }, [triggerIntegration, withMaxVerificationAttempts, setValue]);

  // Update selected language when it changes in the main form
  useEffect(() => {
    setValue('selectedLanguage', selectedLanguage);
  }, [selectedLanguage, setValue]);

  // Update the defined languages when it changes in the main form
  useEffect(() => {
    setValue('definedLanguages', definedLanguages);
  }, [definedLanguages, setValue]);

  const {field: connectionIdField} = useController({name: 'connectionId', control});
  const requiresMerchantConnection = triggerIntegration?.spec.requiresMerchantConnection === true;

  return (
    <li className="space-y-4 rounded-md bg-gray-50 p-4">
      <div className="flex items-center justify-between">
        <h3 className="font-bold text-gray-700">{t('title', {index: index + 1})}</h3>
        <button type="button" onClick={onRemove}>
          <Trash className="h-5 w-5 text-red-400" />
        </button>
      </div>
      <FieldLabel name="app" label={t('labelApp')} />
      {!selectedApp ? (
        <ul className="flex flex-wrap items-center gap-4">
          {appsListWithTriggers.map(app => (
            <button
              key={app.spec.id}
              className="rounded-md"
              type="button"
              onClick={() => {
                const newApp = appsListWithTriggers.find(item => item.spec.id === app.spec.id);

                if (
                  newApp &&
                  newApp.triggerIntegrations.length > 0 &&
                  newApp.triggerIntegrations[0]
                ) {
                  setSelectedApp(app);
                  setValue(
                    'triggerIntegrationId',
                    newApp.triggerIntegrations[0]?.spec.id as TriggerIntegrationId
                  );
                  setValue('connectionId', null);
                  setValue('triggerIntegrationConfig', {});
                  clearErrors('triggerIntegrationId');
                  setEmptyAppError(false);
                }
              }}
            >
              <AppBadge key={app.spec.id} appId={app.spec.id} />
            </button>
          ))}
        </ul>
      ) : (
        <div className="flex items-center gap-2">
          <div className="rounded-md border-2 border-primary">
            <AppBadge key={selectedApp.spec.id} appId={selectedApp.spec.id} />
          </div>
          <button type="button" onClick={() => setSelectedApp(undefined)}>
            <ArrowUUpLeft className="h-5 w-5 text-slate-400" />
          </button>
        </div>
      )}
      <p
        ref={emptyAppErrorRef}
        className={cn('mt-2 text-sm text-red-500', emptyAppError ? 'block' : 'hidden')}
      >
        {t('emptyStepsError')}
      </p>
      {selectedApp && (
        <SelectInput
          name="triggerIntegrationId"
          label={t('labelAction')}
          register={register}
          control={control}
          options={selectedApp.triggerIntegrations
            .filter(integration => {
              if (triggerIntegrationId === integration.spec.id) {
                return true;
              }
              return !triggerIntegrationDisabled(integration);
            })
            .map(action => ({
              value: action.spec.id,
              label: getTriggerIntegrationTitle(action),
            }))}
        />
      )}
      {selectedApp && triggerIntegration && requiresMerchantConnection && (
        <ConnectionPicker
          appId={selectedApp.spec.id}
          control={control}
          register={register}
          connectionIdFieldName="connectionId"
        />
      )}
      {selectedApp &&
        triggerIntegration &&
        requiresMerchantConnection &&
        connectionIdField.value !== null && (
          <triggerIntegration.configComponent
            formReturn={formReturn}
            connectionId={connectionIdField.value}
          />
        )}
      {selectedApp && triggerIntegration && !requiresMerchantConnection && (
        <triggerIntegration.configComponent formReturn={formReturn} />
      )}
      {selectedApp && triggerIntegration && triggerIntegration.spec.configurableMaxAttempts && (
        <div className="grid h-10 gap-4 [grid-template-columns:max-content_1fr]">
          <Input
            type="checkbox"
            name="withMaxVerificationAttempts"
            label={t('labelWithMaxVerificationAttempts')}
            labelPosition="right"
            control={control}
            register={register}
          />
          {withMaxVerificationAttempts && (
            <Input
              type="number"
              name="maxVerificationAttempts"
              label={t('labelMaxVerificationAttempts')}
              className="max-w-[100px]"
              labelPosition="right"
              control={control}
              register={register}
              min={1}
            />
          )}
        </div>
      )}
      <div className="flex justify-end">
        <Button
          type="button"
          onClick={() =>
            handleSubmit(data => {
              if (requiresMerchantConnection) {
                try {
                  StepTriggerConnectionRequiredSchema.parse({connectionId: data.connectionId});
                } catch (error) {
                  if (error instanceof z.ZodError) {
                    return handleFormErrors(error, clearErrors, setError);
                  }
                  throw error;
                }
              }

              try {
                const formSchema = triggerIntegration?.configForm
                  ? triggerIntegration.configForm.schema
                  : triggerIntegration?.spec.configSchema;

                const formValues = formSchema?.parse(data.triggerIntegrationConfig);

                const triggerIntegrationConfig = triggerIntegration?.configForm
                  ? triggerIntegration.configForm.fromForm(formValues, definedLanguages)
                  : formValues;

                onSaved({
                  ...data,
                  triggerIntegrationConfig,
                });
              } catch (error) {
                if (error instanceof z.ZodError) {
                  handleFormErrors(error, clearErrors, setError, 'triggerIntegrationConfig.');
                } else {
                  throw error;
                }
              }
            })()
          }
        >
          {t('btnSave')}
        </Button>
      </div>
    </li>
  );
};

export default StepTriggerForm;
