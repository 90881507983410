import type {PatchCampaignADto} from '@cohort/admin-schemas/campaign';
import SubmitFooter from '@cohort/merchants/components/form/SubmitFooter';
import {campaignsKeys} from '@cohort/merchants/hooks/api/Campaigns';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useCampaignPageStore} from '@cohort/merchants/hooks/stores/campaignPage';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {patchCampaign} from '@cohort/merchants/lib/api/Campaigns';
import {uploadAsset} from '@cohort/merchants/lib/form/utils';
import CampaignSettingsAirdropStep from '@cohort/merchants/pages/campaigns/campaign/edit/CampaignSettingsAirdropStep';
import CampaignSettingsJourneyStep from '@cohort/merchants/pages/campaigns/campaign/edit/CampaignSettingsJourneyStep';
import CampaignSettingsStoreStep from '@cohort/merchants/pages/campaigns/campaign/edit/CampaignSettingsStoreStep';
import {notifyError} from '@cohort/merchants/stores/ErrorModalStore';
import type {AssetKind} from '@cohort/shared/schema/common/assets';
import {useQueryClient} from '@tanstack/react-query';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const CampaignSettingsPage = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const campaign = useCurrentCampaign();
  const queryClient = useQueryClient();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.settings.page',
  });
  const setFooter = useCampaignPageStore(state => state.setFooter);

  const {isLoading: isUploading, mutateAsync: uploadFile} = useCohortMutation({
    mutationFn: async ({file, assetKind}: {file: File; assetKind: AssetKind}) =>
      uploadAsset(file, assetKind, merchantId),
    onError: err => notifyError(err, t('errorFileUploadFailed')),
  });

  const {isLoading: isUpdating, mutateAsync: updateCampaign} = useCohortMutation({
    mutationFn: async (data: PatchCampaignADto) => patchCampaign(merchantId, campaign.id, data),
    notifyErrorMessage: t('notificationErrorUpdate'),
    notifySuccessMessage: t('notificationUpdateSuccess'),
  });

  useEffect(() => {
    setFooter(
      <SubmitFooter
        formName="campaign-settings-step"
        loading={isUpdating || isUploading}
        submitLabel={t('saveBtn')}
      />
    );
    return () => setFooter(null);
  }, [setFooter, t, isUpdating, isUploading]);

  const campaignSettingsContent = match({type: campaign.type})
    .with({type: 'journey'}, () => (
      <CampaignSettingsJourneyStep
        isProcessing={isUpdating || isUploading}
        uploadMutation={uploadFile}
        onStepValidated={() =>
          queryClient.invalidateQueries(campaignsKeys.getById(merchantId, campaign.id))
        }
        updateCampaign={updateCampaign}
      />
    ))
    .with({type: 'store'}, () => (
      <CampaignSettingsStoreStep
        isProcessing={isUpdating || isUploading}
        uploadMutation={uploadFile}
        onStepValidated={() =>
          queryClient.invalidateQueries(campaignsKeys.getById(merchantId, campaign.id))
        }
        updateCampaign={updateCampaign}
      />
    ))
    .with({type: 'airdrop'}, () => (
      <CampaignSettingsAirdropStep
        isProcessing={isUpdating || isUploading}
        uploadMutation={uploadFile}
        onStepValidated={() =>
          queryClient.invalidateQueries(campaignsKeys.getById(merchantId, campaign.id))
        }
        updateCampaign={updateCampaign}
      />
    ))
    .with({type: null}, () => {
      throw new Error('Campaign type is not defined');
    })
    .exhaustive();

  return campaignSettingsContent;
};

export default CampaignSettingsPage;
