import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {assertConnectionId} from '@cohort/merchants/apps';
import {useSpotifyPlaylists} from '@cohort/merchants/apps/spotify/actions';
import SelectPlaylistSheet from '@cohort/merchants/apps/spotify/triggers/SelectPlaylistSheet';
import Button from '@cohort/merchants/components/buttons/Button';
import ErrorState from '@cohort/merchants/components/ErrorState';
import HighlightText from '@cohort/merchants/components/HighlightText';
import Loader from '@cohort/merchants/components/Loader';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {ArrowsCounterClockwise, Image, ImagesSquare} from '@phosphor-icons/react';
import {Fragment, useEffect, useState} from 'react';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const SpotifyFollowPlaylistTriggerConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({connectionId, formReturn}) => {
  assertConnectionId(connectionId);

  const merchant = useCurrentMerchant();
  const {setValue, control} = formReturn;
  const {field: playlistIdField} = useController({
    name: 'triggerIntegrationConfig.playlistId',
    control,
  });
  const [selectPlaylistSheetOpen, setSelectPlaylistSheetOpen] = useState(false);
  const {t} = useTranslation('app-spotify', {
    keyPrefix: 'triggerIntegrations.follow-playlist.configComponent',
  });

  const {
    data: playlistsResponse,
    isLoading: isPlaylistsLoading,
    isFetched: isPlaylistsFetched,
  } = useSpotifyPlaylists(merchant.id, connectionId, undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const playlists = playlistsResponse?.output.playlists ?? [];

  // Set the value of the title to match the playlistId
  useEffect(() => {
    const playlist = playlists.find(playlist => playlist.id === playlistIdField.value);
    if (playlist === undefined) {
      return;
    }
    setValue('triggerIntegrationConfig.playlistName', playlist.name);
    setValue('triggerIntegrationConfig.playlistThumbnailUrl', playlist.thumbnailUrl);
    setValue('triggerIntegrationConfig.playlistUrl', playlist.url);
  }, [playlistIdField, playlists, setValue]);

  const formsSelectionContent = match({
    playlists,
    isPlaylistsLoading,
    isPlaylistsFetched,
    formIdFieldValue: playlistIdField.value,
  })
    .with({isPlaylistsLoading: true}, () => <Loader size={30} color="gray" />)
    .with({playlists: []}, () => (
      <div className="flex flex-col space-y-2">
        <p>{t('noPlaylistsMessage')}</p>
      </div>
    ))
    .with({isPlaylistsFetched: true}, () => {
      const selectedPlaylist = playlists.find(playlist => playlist.id === playlistIdField.value);

      return (
        <Fragment>
          <div>
            <p className="pb-1 text-sm font-medium">{t('titlePlaylistSelect')}</p>
            <p className="pb-2 text-sm">{t('descriptionPlaylistSelect')}</p>
            {!playlistIdField.value ? (
              <Button variant="ghost" onClick={() => setSelectPlaylistSheetOpen(true)}>
                <ImagesSquare className="mr-2 h-5 w-5" />
                {t('buttonSelectPlaylist')}
              </Button>
            ) : (
              <Fragment>
                <div className="flex items-center gap-5">
                  <div className="flex aspect-square w-[120px] items-center justify-center overflow-hidden rounded-lg border border-slate-200 bg-white shadow-sm">
                    {selectedPlaylist?.thumbnailUrl !== undefined &&
                    selectedPlaylist.thumbnailUrl !== null ? (
                      <img src={selectedPlaylist.thumbnailUrl} />
                    ) : (
                      <Image size={24} className="text-slate-400" />
                    )}
                  </div>
                  <Button variant="secondary" onClick={() => setSelectPlaylistSheetOpen(true)}>
                    <ArrowsCounterClockwise className="mr-2 h-5 w-5" />
                    {t('buttonChangePlaylist')}
                  </Button>
                </div>
                {selectedPlaylist?.thumbnailUrl === undefined && (
                  <div className="mt-4">
                    <HighlightText type="warning" text={t('playlistNotFound')} />
                  </div>
                )}
              </Fragment>
            )}
          </div>
          {selectPlaylistSheetOpen && (
            <SelectPlaylistSheet
              formReturn={formReturn}
              playlists={playlists}
              onClose={() => setSelectPlaylistSheetOpen(false)}
            />
          )}
        </Fragment>
      );
    })
    .otherwise(() => <ErrorState />);

  return formsSelectionContent;
};

export default SpotifyFollowPlaylistTriggerConfigComponent;
