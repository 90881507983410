import type {CurrentSyncContextValue} from '@cohort/merchants/pages/apps/app/CurrentSyncContext';
import {CurrentSyncContext} from '@cohort/merchants/pages/apps/app/CurrentSyncContext';
import {useContext} from 'react';

export const useCurrentSync = (): CurrentSyncContextValue => {
  const context = useContext(CurrentSyncContext);

  if (context === null) {
    throw new Error('useCurrentSync must be used within a CurrentSyncProvider');
  }
  return context;
};
