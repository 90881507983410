import {cn} from '@cohort/shared-frontend/utils/classNames';
import React, {useMemo} from 'react';

interface GradientToBottomProps {
  backgroundColor: string;
  classes?: string;
  large?: boolean;
}

const GradientToBottom: React.FC<GradientToBottomProps> = ({classes, large, backgroundColor}) => {
  const customStyle = useMemo(() => {
    let color = backgroundColor.slice(1);

    // Handle edge case, we need a 6 or 8 digits HEX code to make the gradient work as expected.
    if (color.length === 3 || color.length === 4) {
      color = [...color].map(letter => letter + letter).join('');
    }

    return {
      // 00 means 0% transparency on Hex colors, it's needed to fix a transparency bug in Safari.
      background: `linear-gradient(to bottom, #${color}00, #${color})`,
    };
  }, [backgroundColor]);

  return (
    <div
      className={cn('-mb-[1px] w-full', classes, large ? 'h-12' : 'h-10')} // -1px to remove a horizontal line on Chrome.
      style={customStyle}
    />
  );
};

export default GradientToBottom;
