import type {CampaignADto} from '@cohort/admin-schemas/campaign';
import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import CardListDisplay from '@cohort/merchants/components/CardListDisplay';
import EmptyState from '@cohort/merchants/components/EmptyState';
import ErrorState from '@cohort/merchants/components/ErrorState';
import LoadingState from '@cohort/merchants/components/LoadingState';
import {useCampaigns} from '@cohort/merchants/hooks/api/Campaigns';
import {useCurrentDigitalAssetCollection} from '@cohort/merchants/hooks/contexts/currentDigitalAssetCollection';
import usePageSearchParam from '@cohort/merchants/hooks/pageSearchParam';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {useUrlParams} from '@cohort/merchants/hooks/useUrlParams';
import {getCampaignsRoute} from '@cohort/merchants/lib/Pages';
import CampaignListItem from '@cohort/merchants/pages/campaigns/overview/CampaignListItem';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {match, P} from 'ts-pattern';

const PAGE_SIZE = 20;

const DigitalAssetCollectionCampaignsPage = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const digitalAssetCollection = useCurrentDigitalAssetCollection();
  const {t} = useTranslation('pages', {
    keyPrefix: 'digitalAssetCollections.digitalAssetCollection.campaigns.page',
  });
  const {patchSearchParams} = useUrlParams();
  const page = usePageSearchParam();

  const {data, isLoading, isError} = useCampaigns(merchantId, {
    page,
    pageSize: PAGE_SIZE,
    digitalAssetCollectionId: [digitalAssetCollection.id],
    orderBy: 'internalName',
  });
  const [pagination, campaigns] = data ?? [];

  const campaignsGuard = (
    campaigns: Array<CampaignADto> | undefined
  ): campaigns is Array<CampaignADto> => campaigns !== undefined && campaigns.length > 0;

  const content = match({campaigns, isLoading, isError})
    .with({isLoading: true}, () => <LoadingState />)
    .with({isError: true}, () => <ErrorState />)
    .with(
      {
        campaigns: P.select(P.when(campaignsGuard)),
      },
      campaigns => (
        <CardListDisplay display="list">
          {campaigns.map(campaign => (
            <CampaignListItem key={campaign.id} campaign={campaign} action="link" />
          ))}
        </CardListDisplay>
      )
    )
    .otherwise(() => (
      <EmptyState
        title={t('titleEmpty')}
        description={t('descriptionEmpty')}
        cta={
          <Link to={getCampaignsRoute().path}>
            <Button data-testid="digital-asset-collection-campaigns-empty">
              {t('goToCampaignsBtn')}
            </Button>
          </Link>
        }
      />
    ));

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
  };

  const paginationConfig = {
    pagination,
    onPageChange: (page: number) => patchSearchParams({page: String(page)}),
  };

  return (
    <AdminPage header={headerConfig} pagination={paginationConfig}>
      {content}
    </AdminPage>
  );
};

export default DigitalAssetCollectionCampaignsPage;
