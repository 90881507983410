/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getCustomComponents} from '@cohort/merchants/lib/api/CustomComponents';

export const customComponentsKeys = {
  customComponents: ['customComponents'] as const,
  getByMerchantId: (merchantId: string) =>
    [...customComponentsKeys.customComponents, merchantId] as const,
};

export const useCustomComponents = (merchantId: string) =>
  useCohortQuery({
    queryKey: customComponentsKeys.getByMerchantId(merchantId),
    queryFn: async () => getCustomComponents(merchantId),
  });
