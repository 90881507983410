// i18nOwl-ignore [app-cohort.notificationIntegrations.custom-request.title]
import type {NotificationIntegration} from '@cohort/merchants/apps';
import CohortCustomRequestNotificationIntegrationConfigComponent from '@cohort/merchants/apps/cohort/notificationIntegrations/customRequest/ConfigComponent';
import type {CohortCustomRequestNotificationIntegrationStruct} from '@cohort/shared/apps/cohort/notifications/customRequest';
import {
  CohortCustomRequestNotificationConfigSchema,
  CohortCustomRequestNotificationIntegrationSpec,
} from '@cohort/shared/apps/cohort/notifications/customRequest';
import liquid from '@cohort/shared/lib/liquid';
import {
  definedKeyValueValidator,
  urlTransformer,
  urlValidator,
} from '@cohort/shared/utils/validators';
import {z} from 'zod';

const ConfigComponentValidationSchema = CohortCustomRequestNotificationConfigSchema.extend({
  url: z
    .string()
    .refine(urlValidator, 'errorUrl')
    .transform(url => urlTransformer(url)),
  body: z
    .string()
    .nullable()
    .refine(body => {
      if (body === null) {
        return true;
      }
      try {
        liquid.parse(body);

        return true;
      } catch {
        return false;
      }
    }, 'errorLiquidSyntax'),
  headers: z
    .array(
      z.object({
        key: z
          .string()
          .optional()
          .transform(key => (key?.trim() === '' ? undefined : key)),
        value: z
          .string()
          .optional()
          .transform(value => (value?.trim() === '' ? undefined : value)),
      })
    )
    .transform(headers => {
      return headers.filter(({key, value}) => key !== undefined || value !== undefined);
    })
    .refine(definedKeyValueValidator, 'errorDefinedKeyValue'),
});

export const CohortCustomRequestNotificationIntegration: NotificationIntegration<CohortCustomRequestNotificationIntegrationStruct> =
  {
    spec: CohortCustomRequestNotificationIntegrationSpec,
    configComponent: CohortCustomRequestNotificationIntegrationConfigComponent,
    configComponentValidationSchema: ConfigComponentValidationSchema,
  };
