import Button from '@cohort/merchants/components/buttons/Button';
import {cn} from '@cohort/shared-frontend/utils/classNames';

type SubmitFooterProps = {
  formName: string;
  submitLabel: string;
  additionalAction?: JSX.Element;
  loading?: boolean;
  withBackButton?: {
    label: string;
    onClick: () => void;
  };
};

const SubmitFooter: React.FC<SubmitFooterProps> = ({
  submitLabel,
  additionalAction,
  formName,
  loading,
  withBackButton,
}) => (
  <div
    data-testid="submit-footer"
    className={cn(
      '-m-px flex h-[var(--submit-footer-height)] border-l border-t px-8 py-4',
      withBackButton ? 'justify-between' : 'justify-end'
    )}
  >
    {withBackButton && (
      <Button variant="secondary" onClick={withBackButton.onClick}>
        {withBackButton.label}
      </Button>
    )}
    <div className="flex items-center justify-end gap-4">
      {additionalAction}
      <Button form={formName} loading={loading} type="submit" data-testid="save">
        {submitLabel}
      </Button>
    </div>
  </div>
);

export default SubmitFooter;
