import {IconBadge} from '@cohort/merchants/components/IconBadge';
import {Trophy} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

const PerkRaffleBadge: React.FC<{size?: 'small' | 'large'}> = ({size = 'small'}) => {
  const {t} = useTranslation('components', {keyPrefix: 'perks.perkRaffleBadge'});

  return (
    <IconBadge
      size={size}
      icon={<Trophy className="mr-1 inline-block h-4 w-4 text-yellow-400" />}
      text={t('raffle')}
    />
  );
};

export default PerkRaffleBadge;
