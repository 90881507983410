import type {CampaignADto} from '@cohort/admin-schemas/campaign';
import Button from '@cohort/merchants/components/buttons/Button';
import {CampaignArchivedBadge} from '@cohort/merchants/components/campaigns/CampaignArchivedBadge';
import {CampaignStatusBadge} from '@cohort/merchants/components/campaigns/CampaignStatusBadge';
import CampaignSupplyBadge from '@cohort/merchants/components/campaigns/CampaignSupplyBadge';
import CampaignTypeBadge from '@cohort/merchants/components/campaigns/CampaignTypeBadge';
import CampaignVisual from '@cohort/merchants/components/CampaignVisual';
import {getCampaignEditRoute, getCampaignRoute} from '@cohort/merchants/lib/Pages';
import {trackCampaignListItemClicked} from '@cohort/merchants/lib/Tracking';
import CampaignDropdown from '@cohort/merchants/pages/campaigns/overview/CampaignDropdown';
import {formatDate} from '@cohort/shared/utils/format';
import {ArrowCircleRight} from '@phosphor-icons/react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

interface CampaignListItemProps {
  campaign: CampaignADto;
  action?: 'dropdown' | 'link';
}

const CampaignListNameColumn: React.FC<CampaignListItemProps> = ({campaign}) => (
  <div className="flex w-full items-start justify-between gap-2 truncate md:items-center">
    <div className="flex items-center gap-4 truncate">
      <div className="relative overflow-hidden rounded-md">
        <CampaignVisual campaign={campaign} className="rounded-md" size={40} />
      </div>
      <div className="truncate">
        <h3 className="truncate text-sm font-medium  ">{campaign.internalName}</h3>
        <div className="mt-2 flex items-start gap-2">
          {campaign.type && <CampaignTypeBadge type={campaign.type} size="small" />}
          {campaign.status !== 'draft' && (
            <CampaignSupplyBadge
              currentSupply={campaign.numOrdered}
              maxSupply={campaign.totalSupply}
            />
          )}
        </div>
      </div>
    </div>
  </div>
);

const CampaignListStatusColumn: React.FC<CampaignListItemProps> = ({campaign}) => (
  <div className="flex items-center gap-4">
    {campaign.isArchived && <CampaignArchivedBadge size="small" />}
    <CampaignStatusBadge status={campaign.status} size="small" />
  </div>
);

const CampaignListActionsColumn: React.FC<CampaignListItemProps> = ({campaign, action}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'campaigns.overview.campaignListItem'});

  return (
    <div className="flex items-center justify-end gap-8">
      {campaign.status === 'draft' ? (
        <Button variant="secondary">{t('btnFinishDraft')}</Button>
      ) : (
        <div data-testid="campaign-date" className="text-xs text-slate-500">
          {t('labelLastEdit')} {formatDate(campaign.updatedAt)}
        </div>
      )}
      {action === 'dropdown' ? (
        <CampaignDropdown campaign={campaign} />
      ) : (
        <Button variant="secondary" className="!px-2">
          <ArrowCircleRight className="h-6 w-6 text-slate-500" />
        </Button>
      )}
    </div>
  );
};

const CampaignListItem: React.FC<CampaignListItemProps> = ({campaign, action = 'dropdown'}) => {
  return (
    <li key={campaign.id} data-testid="campaign-list-item" className="border-t-2 border-slate-100">
      <Link
        to={
          campaign.status === 'draft'
            ? getCampaignEditRoute(campaign.id).path
            : getCampaignRoute(campaign.id).path
        }
        onClick={() => trackCampaignListItemClicked(campaign)}
      >
        <div className="grid items-center gap-6 py-4 md:[grid-template-columns:1fr_min-content_280px]">
          <CampaignListNameColumn campaign={campaign} />
          <CampaignListStatusColumn campaign={campaign} />
          <CampaignListActionsColumn campaign={campaign} action={action} />
        </div>
      </Link>
    </li>
  );
};

export default CampaignListItem;
