import type {
  NotificationConfigurationADto,
  PatchNotificationConfigurationADto,
} from '@cohort/admin-schemas/notification';
import {PatchNotificationConfigurationASchema} from '@cohort/admin-schemas/notification';
import {useNotificationIntegrations} from '@cohort/merchants/apps/useNotificationIntegrations';
import Button from '@cohort/merchants/components/buttons/Button';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import CreateEditNotificationConfigurationModal from '@cohort/merchants/components/notifications/CreateEditNotificationConfigurationModal';
import NotificationConfigurationLastExecutionBadge from '@cohort/merchants/components/notifications/NotificationConfigurationLastExecutionBadge';
import {notificationConfigurationsKeys} from '@cohort/merchants/hooks/api/Notifications';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {notify} from '@cohort/merchants/hooks/toast';
import {
  deleteNotificationConfiguration,
  patchNotificationConfiguration,
} from '@cohort/merchants/lib/api/Notifications';
import AppLogo from '@cohort/merchants/pages/users/overview/appLogo';
import type {NotificationConfigurationResourceType} from '@cohort/shared/schema/common/notificationConfiguration';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Pen, Trash} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

type NotificationConfigurationCardProps = {
  notificationConfiguration: NotificationConfigurationADto;
  showActionsBtns: boolean;
  disabled: boolean;
  resource?: {
    id: string;
    type: NotificationConfigurationResourceType;
  };
};

const NotificationConfigurationCard: React.FC<NotificationConfigurationCardProps> = ({
  notificationConfiguration,
  showActionsBtns,
  disabled,
  resource,
}) => {
  const [showNotificationCreationModal, setShowNotificationCreationModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();
  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.notifications.notificationsSettingsList',
  });
  const {
    getNotificationIntegrationTitle,
    getAppFromNotificationIntegration,
    getNotificationIntegration,
  } = useNotificationIntegrations();

  const notificationIntegration = getNotificationIntegration(
    notificationConfiguration.notificationIntegrationId
  );
  const app = getAppFromNotificationIntegration(notificationIntegration.spec.id);

  const {mutate: deleteNotificationConfigurationMutation} = useCohortMutation({
    mutationFn: async (notificationConfigurationId: string) =>
      deleteNotificationConfiguration(merchant.id, notificationConfigurationId),
    onSuccess: () => {
      queryClient.invalidateQueries(notificationConfigurationsKeys.list(merchant.id));
    },
  });

  const {
    isLoading: isUpdatingNotificationConfiguration,
    mutate: patchNotificationConfigurationMutation,
  } = useCohortMutation({
    mutationFn: async (data: PatchNotificationConfigurationADto) =>
      patchNotificationConfiguration(merchant.id, notificationConfiguration.id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(notificationConfigurationsKeys.list(merchant.id));
    },
  });

  return (
    <div
      className={cn(
        // @Devs - fixed height to avoid height diffence between the cards (with CTA and without)
        'flex h-[52px] justify-between rounded-lg border border-slate-200 p-3 shadow-sm shadow-black/5',
        disabled && 'opacity-40'
      )}
    >
      <div className="flex items-center space-x-4 text-sm">
        <NotificationConfigurationLastExecutionBadge
          lastExecution={notificationConfiguration.lastExecution}
        />
        <AppLogo app={app} />
        <div
          key={notificationConfiguration.id}
          className="flex items-center space-x-4 text-sm font-medium text-slate-900"
        >
          {getNotificationIntegrationTitle(notificationIntegration)}
        </div>
      </div>

      {showActionsBtns && (
        <div className="flex space-x-4">
          <Button
            className="h-[26px] py-1"
            size="xs"
            variant="secondary"
            onClick={() => setShowNotificationCreationModal(true)}
          >
            <Pen className="mr-1.5 text-slate-600" size={16} />
            {t('notificationConfigurationCard.editButton')}
          </Button>

          <Button
            className="h-[26px] py-1"
            size="xs"
            variant="secondary"
            onClick={() => setShowConfirmationModal(true)}
          >
            <Trash className="mr-1.5 text-slate-600" size={16} />
            {t('notificationConfigurationCard.deleteButton')}
          </Button>
        </div>
      )}
      {showNotificationCreationModal && (
        <CreateEditNotificationConfigurationModal
          isLoading={isUpdatingNotificationConfiguration}
          notificationConfiguration={notificationConfiguration}
          userEventId={notificationConfiguration.userEventId}
          onClose={() => setShowNotificationCreationModal(false)}
          onSubmit={rawData => {
            const parsedData = PatchNotificationConfigurationASchema.parse(rawData);
            patchNotificationConfigurationMutation(parsedData);
            setShowConfirmationModal(false);
            notify('success', t('notificationConfigurationCard.updateSuccessMessage'));
          }}
          resource={resource}
        />
      )}
      {showConfirmationModal && (
        <DeletionModal
          title={t('notificationConfigurationCard.deletionConfirmationModalTitle')}
          subtitle={t('notificationConfigurationCard.deletionConfirmationModalText')}
          show={showConfirmationModal}
          onClose={() => setShowConfirmationModal(false)}
          onDelete={() => {
            deleteNotificationConfigurationMutation(notificationConfiguration.id);
            setShowConfirmationModal(false);
            notify('success', t('notificationConfigurationCard.deletionSuccessMessage'));
          }}
        />
      )}
    </div>
  );
};

export default NotificationConfigurationCard;
