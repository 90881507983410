/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {ListUserAttributesParamsADto} from '@cohort/admin-schemas/userAttributes';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getUserAttributes} from '@cohort/merchants/lib/api/UserAttributes';

export const userAttributesKeys = {
  userAttributes: ['userAttributes'] as const,
  list: (merchantId: string, params: ListUserAttributesParamsADto) =>
    [...userAttributesKeys.userAttributes, merchantId, 'list', params] as const,
};

export const useUserAttributes = (
  merchantId: string,
  params: ListUserAttributesParamsADto,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: userAttributesKeys.list(merchantId, params),
    queryFn: async () => getUserAttributes(merchantId, params),
    ...options,
  });
