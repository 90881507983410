import type {NotificationConfigurationADto} from '@cohort/admin-schemas/notification';
import {useNotificationIntegrations} from '@cohort/merchants/apps/useNotificationIntegrations';
import {useUserEventApps} from '@cohort/merchants/apps/useUserEventApps';
import {Badge} from '@cohort/merchants/components/Badge';
import type {EventId} from '@cohort/shared/apps';
import {CaretDown, CaretUp} from '@phosphor-icons/react';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {groupBy} from 'remeda';

type NotificationIntegrationsBadgesProps = {
  notificationConfigurations: Array<NotificationConfigurationADto>;
};

const NotificationIntegrationsBadges: React.FC<NotificationIntegrationsBadgesProps> = ({
  notificationConfigurations,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.notifications.notificationsSettingsListRowHeader',
  });
  const {getNotificationIntegrationTitle, getNotificationIntegration} =
    useNotificationIntegrations();

  if (notificationConfigurations.length === 0) {
    return (
      <Badge
        text={t('notificationIntegrationsBadges.noNotificationBadge')}
        backgroundColor="bg-gray-100"
        textColor="text-slate-500"
        borderColor="border-gray-100"
        size="small"
      />
    );
  }

  const groupedNotificationConfigurations = groupBy(
    notificationConfigurations,
    notificationConfiguration => notificationConfiguration.notificationIntegrationId
  );

  // Grouping the notification configurations by integrationId to remove duplicated badges (global + override)
  return Object.entries(groupedNotificationConfigurations).map(([integrationId]) => {
    const integration = getNotificationIntegration(integrationId);
    return (
      <Badge
        key={integrationId}
        text={getNotificationIntegrationTitle(integration)}
        backgroundColor="bg-gray-100"
        textColor="text-slate-500"
        borderColor="border-gray-100"
        size="small"
      />
    );
  });
};

type NotificationsSettingsListRowHeaderProps = {
  open: boolean;
  userEventId: EventId;
  notificationConfigurations: Array<NotificationConfigurationADto>;
};

const NotificationsSettingsListRowHeader: React.FC<NotificationsSettingsListRowHeaderProps> = ({
  open,
  userEventId,
  notificationConfigurations,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.notifications.notificationsSettingsListRowHeader',
  });
  const {getUserEventTitle} = useUserEventApps();
  const title = getUserEventTitle(userEventId);

  const overrideNotificationConfigurations = notificationConfigurations.filter(
    nc => nc.resourceType !== null
  );

  return (
    <Fragment>
      <div className="flex w-full justify-between">
        <div className="text-sm font-medium">{title}</div>
        <div className="flex items-center space-x-6">
          <div>
            {open ? (
              <CaretUp size={20} className="text-slate-500" />
            ) : (
              <div className="flex space-x-6">
                {overrideNotificationConfigurations.length > 0 && (
                  <Badge
                    text={t('customBadge')}
                    textColor="text-purple-500"
                    backgroundColor="bg-purple-100"
                    size="small"
                  />
                )}
                <CaretDown size={20} className="text-slate-500" />
              </div>
            )}
          </div>
        </div>
      </div>
      {open ? (
        <p className="mt-1 text-sm font-normal text-slate-500">
          {t('editNotificationIntegrationsSubtitle')}
        </p>
      ) : (
        <div className="mt-1 flex space-x-2">
          <NotificationIntegrationsBadges notificationConfigurations={notificationConfigurations} />
        </div>
      )}
    </Fragment>
  );
};

export default NotificationsSettingsListRowHeader;
