import {getIdToken, logout} from '@cohort/merchants/lib/Firebase';
import {getAmplitudeSessionId, getTraceId, trackError} from '@cohort/merchants/lib/Tracking';
import {getOrganizationSlugFromSubdomain} from '@cohort/merchants/lib/Utils';
import {ADMIN_ORGANIZATION_HEADER} from '@cohort/shared/constants';
import {defaultNetworkErrorMessage} from '@cohort/shared/models';
import type {CohortErrorCode} from '@cohort/shared/schema/common/errors';
import {authErrors, CohortError} from '@cohort/shared/schema/common/errors';
import {asString} from '@cohort/shared/typeUtils';
import type {Parser} from '@cohort/shared/utils/parser';
import {getI18n} from 'react-i18next';
import {ZodError} from 'zod';

const COHORT_ENV = import.meta.env.COHORT_ENV;

export const HttpCodes = {
  SUCCESS: 200,
  CREATED: 201,
  FORBIDDEN_ERROR: 403,
  COHORT_ERROR: 417,
  INTERNAL_SERVER_ERROR: 500,
} as const;

export class RequestError extends Error {
  public message!: string;
  public status!: number;

  public constructor(message: string, status: number) {
    super(message);
    this.name = 'RequestError';
    this.status = status;
  }
}

export class DataValidationError extends Error {
  public message!: string;

  public constructor(message: string) {
    super(message);
    this.name = 'DataValidationError';
  }
}

function handleRes<T>(
  expectedStatus: number,
  parser?: (data: Record<string, unknown>) => T
): (res: Response) => Promise<T> {
  return async res => {
    // If the response is a 500, we get an empty response body
    const data = (await res.json().catch(() => {
      return {};
    })) as Record<string, unknown>;

    if (res.status === expectedStatus && parser) {
      try {
        const parsedData = parser(data);

        return parsedData;
      } catch (err: unknown) {
        if (err instanceof ZodError) {
          if (COHORT_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.error(err.errors);
          }
        }
        trackError(err);
        throw new DataValidationError(defaultNetworkErrorMessage);
      }
    }
    if (res.status === HttpCodes.COHORT_ERROR && data.code !== undefined) {
      if (authErrors.includes(data.code as CohortErrorCode)) {
        await logout();
      }
      throw new CohortError(data.code as CohortErrorCode, data.context as Record<string, unknown>);
    }
    const errMessage = asString(data.message);

    if (res.status === HttpCodes.INTERNAL_SERVER_ERROR || errMessage === undefined) {
      throw new RequestError(defaultNetworkErrorMessage, res.status);
    }
    const unexpectedError = new RequestError(errMessage, res.status);

    throw unexpectedError;
  };
}

async function makeHeaders(idToken?: string, body?: string | FormData): Promise<HeadersInit> {
  const res: HeadersInit = body instanceof FormData ? {} : {'Content-Type': 'application/json'};
  const bearer = idToken ?? (await getIdToken());
  if (bearer !== undefined) {
    res.Authorization = `Bearer ${bearer}`;
  }
  res['Cohort-Trace-Id'] = getTraceId();
  res['Accept-Language'] = getI18n().language;
  res['Amplitude-Session-Id'] = getAmplitudeSessionId()?.toString() ?? '';
  const organizationSlug = getOrganizationSlugFromSubdomain();
  if (organizationSlug !== null) {
    res[ADMIN_ORGANIZATION_HEADER] = organizationSlug;
  }
  return res;
}

function addSearchParam(
  searchParams: URLSearchParams,
  key: string,
  value: string | number | string[] | number[]
): URLSearchParams {
  if (Array.isArray(value)) {
    for (const val of value) {
      searchParams.append(key, String(val));
    }
  } else {
    searchParams.append(key, String(value));
  }
  return searchParams;
}

export async function apiCall<Res>(
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  path: string,
  opts: {
    expect: number;
    parser?: Parser<Res>;
    params?: Record<string, string | number | string[] | number[]> | {};
    body?: Record<string, unknown> | FormData | {};
    idToken?: string;
    headers?: HeadersInit;
  }
): Promise<Res> {
  const {expect, parser, body: bodyObj, idToken, headers, params} = opts;
  // Json stringify body only if it's an object and not an instanceof FormData
  const body = bodyObj && bodyObj instanceof FormData ? bodyObj : JSON.stringify(bodyObj);
  let url = `${import.meta.env.COHORT_API_URL}${path}`;

  if (params) {
    let searchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(params)) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (value !== undefined && value !== null) {
        searchParams = addSearchParam(searchParams, key, value);
      }
    }
    url = `${url}?${searchParams.toString()}`;
  }
  const injectedHeaders = await makeHeaders(idToken, body);
  const res = await fetch(url, {
    method,
    headers: {...injectedHeaders, ...headers},
    body,
  });

  return handleRes(expect, parser)(res);
}
