import {Badge} from '@cohort/merchants/components/Badge';
import type {JourneyParticipationStatus} from '@cohort/shared/schema/common/journeyParticipations';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

type JourneyParticipationStatusBadgeProps = {
  status: JourneyParticipationStatus;
};

const JourneyParticipationStatusBadge: React.FC<JourneyParticipationStatusBadgeProps> = ({
  status,
}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'campaigns.journeys.journeyParticipationStatusBadge',
  });

  return match(status)
    .with('completed', () => (
      <Badge
        text={t('statusCompleted')}
        textColor="text-green-500"
        backgroundColor="bg-green-100"
        dotColor="text-green-400"
        size="small"
      />
    ))
    .with('in-progress', () => (
      <Badge
        text={t('statusInProgress')}
        textColor="text-slate-500"
        backgroundColor="bg-slate-100"
        dotColor="text-slate-400"
        size="small"
      />
    ))
    .exhaustive();
};
export default JourneyParticipationStatusBadge;
