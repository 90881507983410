import type {JourneyParticipationADto} from '@cohort/admin-schemas/journeyParticipation';
import JourneyParticipationStep from '@cohort/merchants/components/campaigns/journeys/JourneyParticipationStep';
import {TimelineItem} from '@cohort/merchants/components/Timeline';
import {formatDate} from '@cohort/shared/utils/format';
import {Gift} from '@phosphor-icons/react';
import React from 'react';
import {useTranslation} from 'react-i18next';
type RewardClaimedStepProps = {
  claimDate: Date;
};
const RewardClaimedStep: React.FC<RewardClaimedStepProps> = ({claimDate}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.activity.journey.journeyParticipationsTable',
  });
  return (
    <TimelineItem showVerticalLine>
      <Gift className="ml-4 h-5 w-5 text-primary" />
      <p className="pl-4">{t('labelCompletedAt')}</p>
      <p className="pl-0.5 font-semibold">{formatDate(claimDate)}</p>
    </TimelineItem>
  );
};

type JourneyParticipationStepperProps = {
  journeyParticipation: JourneyParticipationADto;
};

const JourneyParticipationStepper: React.FC<JourneyParticipationStepperProps> = ({
  journeyParticipation,
}) => (
  <div className="w-full rounded-md bg-slate-100 p-5 text-sm">
    {journeyParticipation.completedAt !== null && (
      <RewardClaimedStep claimDate={journeyParticipation.completedAt} />
    )}
    {journeyParticipation.journeySteps.map((step, index) => (
      <JourneyParticipationStep
        journeyParticipation={journeyParticipation}
        journeyStep={step}
        isLastStep={index === journeyParticipation.journeySteps.length - 1}
        key={step.id}
      />
    ))}
  </div>
);

export default JourneyParticipationStepper;
