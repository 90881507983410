import {trackPerkCreatePageViewed} from '@cohort/merchants/lib/Tracking';
import CreatePerkModal from '@cohort/merchants/pages/perks/new/CreatePerkModal';
import SelectPerkType from '@cohort/merchants/pages/perks/new/SelectPerkType';
import type {PerkType} from '@cohort/shared/schema/common/perks';
import React, {Fragment, useEffect, useState} from 'react';

const CreatePerkPage: React.FC = () => {
  const [type, setType] = useState<PerkType>();
  const [createPerkModalOpen, setCreatePerkModalOpen] = useState(false);

  useEffect(() => trackPerkCreatePageViewed(), []);

  return (
    <Fragment>
      <SelectPerkType
        onTypeSelected={type => {
          setType(type);
          setCreatePerkModalOpen(true);
        }}
      />
      {type && createPerkModalOpen && (
        <CreatePerkModal type={type} onClose={() => setCreatePerkModalOpen(false)} />
      )}
    </Fragment>
  );
};

export default CreatePerkPage;
