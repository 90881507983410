import {CardText} from '@cohort/components-xps/components/cards/Card';
import i18nComponentsXpsInstance from '@cohort/components-xps/lib/i18n';
import {formatDate} from '@cohort/shared/utils/format';
import type {Icon} from '@phosphor-icons/react';
import {BatteryEmpty, BatteryFull, CheckCircle, MinusCircle} from '@phosphor-icons/react';
import React, {Fragment} from 'react';
import {getI18n, useTranslation} from 'react-i18next';

type BaseJourneyStatusBadgeProps = {
  text: string;
  Icon: Icon;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const BaseJourneyStatusBadge: React.FC<BaseJourneyStatusBadgeProps> = ({Icon, text}) => (
  <CardText className="flex items-center gap-2">
    <Icon size={20} />
    {text}
  </CardText>
);

type JourneyStatusBadgeProps = {
  completedAt: Date | null;
  availableSupply: number | null;
  isCampaignEnded: boolean;
};

const JourneyStatusBadge: React.FC<JourneyStatusBadgeProps> = ({
  isCampaignEnded,
  completedAt,
  availableSupply,
}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'journeys.journeyStatus',
    lng: getI18n().language,
    i18n: i18nComponentsXpsInstance,
  });

  if (completedAt !== null) {
    return (
      <BaseJourneyStatusBadge
        Icon={CheckCircle}
        text={t('completed', {date: formatDate(completedAt)})}
      />
    );
  }

  if (isCampaignEnded) {
    return <BaseJourneyStatusBadge Icon={MinusCircle} text={t('ended')} />;
  }

  if (availableSupply !== null) {
    return (
      <BaseJourneyStatusBadge
        Icon={availableSupply === 0 ? BatteryEmpty : BatteryFull}
        text={availableSupply === 0 ? t('noSupply') : t('supply', {count: availableSupply})}
      />
    );
  }

  return <Fragment />;
};

export default JourneyStatusBadge;
