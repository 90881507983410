import type {PatchPerkRequestADto} from '@cohort/admin-schemas/perk';
import {PrivateContentAllowedMimeTypesASchema} from '@cohort/admin-schemas/perk';
import {usePerkIntegrations} from '@cohort/merchants/apps/usePerkIntegrations';
import ConnectionPicker from '@cohort/merchants/components/connections/ConnectionPicker';
import AdvancedOptions from '@cohort/merchants/components/form/AdvancedOptions';
import DynamicKeyValueInput from '@cohort/merchants/components/form/DynamicKeyValueInput';
import FileInput from '@cohort/merchants/components/form/FileInput';
import Input from '@cohort/merchants/components/form/input/Input';
import LocalizedInput from '@cohort/merchants/components/form/input/LocalizedInput';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import RadioCards from '@cohort/merchants/components/form/RadioCards';
import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import LocalizedTextEditorInput from '@cohort/merchants/components/form/textEditor/LocalizedTextEditorInput';
import Header from '@cohort/merchants/components/Header';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {perksKeys} from '@cohort/merchants/hooks/api/Perks';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCurrentPerk} from '@cohort/merchants/hooks/contexts/currentPerk';
import useSectionsScroller from '@cohort/merchants/hooks/sectionScroller';
import {usePerkPageStore} from '@cohort/merchants/hooks/stores/perkPage';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {usePerkUtils} from '@cohort/merchants/hooks/usePerkUtils';
import {usePrompt} from '@cohort/merchants/hooks/usePrompt';
import {patchPerk, publishPerk} from '@cohort/merchants/lib/api/Perks';
import {
  changeSelectedLanguageIfNeeded,
  getDefinedLanguages,
} from '@cohort/merchants/lib/form/localization';
import type {RadioCardOption} from '@cohort/merchants/lib/form/utils';
import {uploadAsset} from '@cohort/merchants/lib/form/utils';
import {
  trackPerkEditPageViewed,
  trackPerkMetadataSettingsSeeDocLinkClicked,
} from '@cohort/merchants/lib/Tracking';
import PerkFormFooter from '@cohort/merchants/pages/perks/perk/edit/PerkFormFooter';
import PerkPreviewAside from '@cohort/merchants/pages/perks/perk/edit/PerkPreviewAside';
import type {PerkFormValues} from '@cohort/merchants/pages/perks/perk/edit/utils';
import {validateIntegrationForm} from '@cohort/merchants/pages/perks/perk/edit/utils';
import {
  formatAndUpload,
  getPerkIntegrationDefaultValues,
  PerkFormSchema,
} from '@cohort/merchants/pages/perks/perk/edit/utils';
import {notifyError} from '@cohort/merchants/stores/ErrorModalStore';
import type {AssetKind} from '@cohort/shared/schema/common/assets';
import {AllowedAssetMimeTypes} from '@cohort/shared/schema/common/assets';
import {ASSETS_MIN_DIMENSIONS} from '@cohort/shared/utils/fileUploads';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment, useEffect, useMemo} from 'react';
import type {UseFormReturn} from 'react-hook-form';
import {useController, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {equals} from 'remeda';
import {shallow} from 'zustand/shallow';

export interface PerkSettingsSectionProps {
  methods: UseFormReturn<Partial<PatchPerkRequestADto>, object>;
  mode?: 'create' | 'edit';
}

const PerkEditPage = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();
  const perk = useCurrentPerk();

  const {t} = useTranslation('pages', {keyPrefix: 'perks.perk.edit.page'});

  const {getPerkTypeData} = usePerkUtils();
  const {
    getPerkIntegration,
    getPerkIntegrationTitle,
    getPerkIntegrationDescription,
    getPerkIntegrationAppId,
    perkIntegrationDisabled,
  } = usePerkIntegrations();
  const perkTypeData = getPerkTypeData(perk.type);
  const sectionsRefs = useSectionsScroller([
    'public_appearance',
    'access',
    'private_content',
    'integration',
  ]);
  const {setPerkPreview, setFooter} = usePerkPageStore(
    store => ({
      setPerkPreview: store.setPerkPreview,
      setFooter: store.setFooter,
    }),
    shallow
  );

  const formReturn = useForm<PerkFormValues>({
    defaultValues: {
      displayName: perk.displayName,
      description: perk.description,
      visual: perk.videoFileKey ? 'video' : perk.bannerFileKey ? 'image' : 'text',
      access: perk.raffle === null ? 'regular' : 'giveaway',
      raffle: perk.raffle
        ? {
            numWinners: perk.raffle.numWinners,
          }
        : null,
      hasIntegration: perk.integration === null ? 'no' : 'yes',
      bannerFileKey: perk.bannerFileKey ?? undefined,
      thumbnailFileKey: perk.thumbnailFileKey ?? undefined,
      // we initialize videoFileKey with thumbnailFileKey because we want to show the video thumbnail in the input if it exists
      videoFileKey: perk.thumbnailFileKey ?? undefined,
      privateContent: perk.privateContent,
      privateContentFileKey:
        perk.privateContentThumbnailFileKey ?? perk.privateContentFileKey ?? undefined,
      privateContentFileType: perk.privateContentFileType ?? undefined,
      privateContentThumbnailFileKey: perk.privateContentThumbnailFileKey ?? undefined,
      metadata: perk.metadata.length > 0 ? perk.metadata : [{key: '', value: ''}],
      integration: getPerkIntegrationDefaultValues(perk),
      integrationId: perk.integration?.perkIntegrationId ?? undefined,
      maxUsagesPerToken: perk.maxUsagesPerToken,
      withMaxAccessesPerUser: perk.maxAccessesPerUser !== null,
      maxAccessesPerUser: perk.maxAccessesPerUser,
      defaultLanguage: merchant.defaultLanguage,
      selectedLanguage: merchant.defaultLanguage,
      definedLanguages: getDefinedLanguages(merchant.defaultLanguage, [
        perk.displayName,
        perk.description,
        perk.privateContent,
      ]),
    },
    resolver: zodResolver(PerkFormSchema),
  });
  const {
    watch,
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    setError,
    clearErrors,
    resetField,
    formState,
  } = formReturn;
  const [visual, integrationId, access, hasIntegration, selectedLanguage, connectionId] = watch([
    'visual',
    'integrationId',
    'access',
    'hasIntegration',
    'selectedLanguage',
    'integration.connectionId',
  ]);

  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  const {field: selectedLanguageField} = useController({
    control,
    name: 'selectedLanguage',
  });
  usePrompt(t('promptLostChangesWarning'), formState.isDirty);

  const selectedIntegration = integrationId ? getPerkIntegration(integrationId) : null;

  const {isLoading: isUpdating, mutate: updatePerk} = useCohortMutation({
    mutationFn: async (data: PatchPerkRequestADto) => patchPerk(merchantId, perk.id, data),
    notifyErrorMessage: t('notificationErrorUpdate'),
    notifySuccessMessage: t('notificationUpdateSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(perksKeys.perks);
      reset({}, {keepValues: true});
    },
  });

  const {isLoading: isUploading, mutateAsync: uploadFile} = useCohortMutation({
    mutationFn: async ({file, assetKind}: {file: File; assetKind: AssetKind}) =>
      uploadAsset(file, assetKind, merchantId),
    onError: err => notifyError(err, t('errorFileUploadFailed')),
  });

  const {mutate: publishPerkMutation, isLoading: isPublishing} = useCohortMutation({
    mutationFn: async (data: PatchPerkRequestADto) => {
      if (perk.status === 'draft') {
        await patchPerk(merchantId, perk.id, data);
        return publishPerk(merchantId, perk.id);
      }
      return patchPerk(merchantId, perk.id, data);
    },
    notifySuccessMessage: t('notificationPublishSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(perksKeys.perks);
      reset({}, {keepValues: true});
    },
  });
  const formFooter = useMemo(
    () => (
      <PerkFormFooter
        getValues={getValues}
        setError={setError}
        clearErrors={clearErrors}
        uploadConfig={{
          mutation: uploadFile,
          isUploading,
        }}
        updateConfig={{
          mutation: updatePerk,
          isUpdating,
        }}
        publishConfig={{
          isPublishing,
        }}
        control={control}
        formState={formState}
      />
    ),
    [
      getValues,
      setError,
      clearErrors,
      uploadFile,
      isUploading,
      updatePerk,
      isUpdating,
      isPublishing,
      control,
      formState,
    ]
  );

  useEffect(() => {
    trackPerkEditPageViewed(perk);
  }, [perk]);

  useEffect(() => {
    setPerkPreview(<PerkPreviewAside control={control} />);

    return () => setPerkPreview(null);
  }, [setPerkPreview, control]);

  useEffect(() => {
    if (
      (formState.isDirty && !equals(Object.keys(formState.dirtyFields), ['selectedLanguage'])) ||
      perk.status === 'draft'
    ) {
      setFooter(formFooter);
    } else {
      setFooter(null);
    }
    return () => setFooter(null);
  }, [formState.isDirty, setFooter, formFooter, perk.status, formState.dirtyFields]);

  function getIntegrationOptions(): RadioCardOption[] {
    const integrationIds =
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (integrationId && [integrationId]) || getPerkTypeData(perk.type).perkIntegrations;

    const integrations = integrationIds.map(id => getPerkIntegration(id));

    return integrations
      .filter(integration => !perkIntegrationDisabled(integration))
      .map(integration => {
        return {
          label: getPerkIntegrationTitle(integration),
          value: integration.spec.id,
          description: getPerkIntegrationDescription(integration),
          prefix: <div className="h-4 w-4 rounded-full bg-primary"></div>,
        };
      });
  }

  return (
    <form
      id="perk-form"
      onSubmit={handleSubmit(
        async data => {
          if (!validateIntegrationForm(data, clearErrors, setError)) {
            return;
          }
          const perkData = await formatAndUpload(data, perk, uploadFile);
          return publishPerkMutation(perkData as PatchPerkRequestADto);
        },
        errors =>
          changeSelectedLanguageIfNeeded(
            errors,
            merchant.defaultLanguage,
            selectedLanguageField.onChange
          )
      )}
    >
      <LanguageSelectorInput
        control={control}
        definedLanguagesPath="definedLanguages"
        selectedLanguagePath="selectedLanguage"
      />
      <div ref={sectionsRefs[0]?.ref} className="space-y-6 pt-6">
        <Header title={t('titlePublicAppearance')} subtitle={t('subtitlePublicAppearance')} />
        <LocalizedInput
          type="text"
          name="displayName"
          label={t('labelTitle')}
          description={t('contentPublicText')}
          placeholder={perkTypeData.perkTitlePlaceholder}
          register={register}
          control={control}
          selectedLanguage={selectedLanguage}
          optional={!isDefaultLanguageSelected}
        />
        <LocalizedTextEditorInput
          name="description"
          label={t('description')}
          placeholder={perkTypeData.perkDescriptionPlaceholder}
          register={register}
          control={control}
          selectedLanguage={selectedLanguage}
          optional
        />
        {isDefaultLanguageSelected && (
          <Fragment>
            <RadioCards
              name="visual"
              direction="row"
              label={t('labelVisual')}
              register={register}
              control={control}
              onChange={() => {
                // We reset the file inputs when the user changes the visual type
                setValue('bannerFileKey', null);
                setValue('thumbnailFileKey', null);
                setValue('videoFileKey', null);
              }}
              options={[
                {
                  label: t('labelText'),
                  value: 'text',
                },
                {
                  label: t('labelImage'),
                  value: 'image',
                },
                {
                  label: t('labelVideo'),
                  value: 'video',
                },
              ]}
            />
            {visual === 'image' && (
              <FileInput
                label={t('labelBanner')}
                name="bannerFileKey"
                register={register}
                control={control}
                acceptHint={t('instructionsBanner')}
                accept={AllowedAssetMimeTypes.perkBanner.options.join(',')}
                assetKind="perkBanner"
                description={t('helperBanner', {
                  width: ASSETS_MIN_DIMENSIONS['perkBanner'].width,
                  height: ASSETS_MIN_DIMENSIONS['perkBanner'].height,
                })}
                withResize
              />
            )}
            {visual === 'video' && (
              <FileInput
                label={t('labelVideo')}
                name="videoFileKey"
                register={register}
                control={control}
                acceptHint={t('instructionsVideo')}
                accept={AllowedAssetMimeTypes.perkVideo.options.join(',')}
                assetKind="perkVideo"
                description={t('helperVideo')}
              />
            )}
          </Fragment>
        )}
      </div>
      {isDefaultLanguageSelected && (
        <div ref={sectionsRefs[1]?.ref} className="space-y-6 pb-2 pt-10">
          <Header title={t('titleAccess')} subtitle={t('subtitleAccess')} />

          <div className="grid items-center gap-4 [grid-template-columns:max-content_1fr]">
            <SwitchInput
              id="withMaxAccessesPerUser"
              name="withMaxAccessesPerUser"
              register={register}
              control={control}
              onCheckedChange={checked => setValue('maxAccessesPerUser', checked ? 1 : null)}
            />
            <div>
              <p className="text-sm font-medium text-slate-700">{t('labelMaxAccessesPerUser')}</p>
              <p className="text-sm text-slate-500">{t('descriptionMaxAccessesPerUser')}</p>
            </div>
          </div>
          <RadioCards
            name="access"
            direction="row"
            label={t('labelAccess')}
            register={register}
            control={control}
            onChange={e => {
              setValue(
                'raffle',
                e.target.value === 'giveaway'
                  ? {
                      numWinners: 1,
                    }
                  : null
              );
            }}
            options={[
              {
                label: t('labelAccessAll'),
                description: t('descriptionAccessAll'),
                value: 'regular',
                disabled: perk.status !== 'draft',
              },
              {
                label: t('labelAccessGiveaway'),
                description: t('descriptionAccessGiveaway'),
                value: 'giveaway',
                disabled: perk.status !== 'draft',
              },
            ]}
          />
          {access === 'giveaway' && (
            <Input
              type="number"
              name="raffle.numWinners"
              label={t('labelRaffleNumWinners')}
              description={t('descriptionRaffleNumWinners')}
              register={register}
              control={control}
              disabled={perk.status !== 'draft'}
            />
          )}
        </div>
      )}
      <div ref={sectionsRefs[2]?.ref} className="space-y-6 pb-2 pt-10">
        <Header title={t('titlePrivateContent')} subtitle={t('subtitlePrivateContent')} />
        <LocalizedTextEditorInput
          name="privateContent"
          label={t('labelPrivateContent')}
          placeholder={perkTypeData.perkDescriptionPlaceholder}
          register={register}
          control={control}
          selectedLanguage={selectedLanguage}
          optional
        />
        {isDefaultLanguageSelected && (
          <FileInput
            name="privateContentFileKey"
            label={t('labelPrivateContentFile')}
            register={register}
            control={control}
            acceptHint={t('helperPrivateContentFile')}
            accept={PrivateContentAllowedMimeTypesASchema.options.join(',')}
            assetKind="privateContent"
            description={t('descriptionPrivateContentFile')}
            disabled={!isDefaultLanguageSelected}
          />
        )}
      </div>

      {(isDefaultLanguageSelected || integrationId === 'cohort.form') && (
        <div ref={sectionsRefs[3]?.ref} className="space-y-6 pb-2 pt-10">
          <Header title={t('titleConfiguration')} subtitle={t('subtitleConfiguration')} />
          {isDefaultLanguageSelected && (
            <Fragment>
              <RadioCards
                name="hasIntegration"
                direction="row"
                label={t('labelIntegration')}
                register={register}
                control={control}
                onChange={() => {
                  resetField('integration', {defaultValue: null});
                  resetField('integrationId', {defaultValue: null});
                }}
                options={[
                  {
                    label: t('labelIntegrationNo'),
                    value: 'no',
                  },
                  {
                    label: t('labelIntegrationYes'),
                    value: 'yes',
                  },
                ]}
              />
              {hasIntegration === 'yes' && (
                <RadioCards
                  name="integrationId"
                  direction="column"
                  register={register}
                  control={control}
                  options={getIntegrationOptions()}
                  onChange={e => {
                    resetField('integration', {defaultValue: null});
                    resetField('integrationId', {defaultValue: null});
                    if (e.target.value) {
                      setValue('integration', {
                        perkIntegrationId: e.target.value,
                        config: {},
                        connectionId: null,
                      } as PerkFormValues['integration']);
                      setValue(
                        'maxUsagesPerToken',
                        getPerkIntegration(e.target.value).spec.defaultMaxUsagesPerToken
                      );
                    } else {
                      setValue('maxUsagesPerToken', null);
                    }
                  }}
                  withChangeBtn={{
                    label: t('buttonChangeApp'),
                  }}
                />
              )}
            </Fragment>
          )}
          {hasIntegration === 'yes' && integrationId && selectedIntegration && (
            <div className="space-y-4">
              {selectedIntegration.spec.requiresMerchantConnection && (
                <Fragment>
                  <ConnectionPicker
                    appId={getPerkIntegrationAppId(selectedIntegration)}
                    register={register}
                    control={control}
                    connectionIdFieldName="integration.connectionId"
                  />
                  {connectionId !== null && (
                    <selectedIntegration.configComponent
                      formReturn={formReturn}
                      connectionId={connectionId}
                    />
                  )}
                </Fragment>
              )}
              {!selectedIntegration.spec.requiresMerchantConnection && (
                <selectedIntegration.configComponent formReturn={formReturn} />
              )}
            </div>
          )}
        </div>
      )}
      {isDefaultLanguageSelected && (
        <div className="mt-4 overflow-visible">
          <AdvancedOptions
            accordionDefaultValue={
              perk.metadata.length > 0 ? 'perk-settings-advanced-options' : undefined
            }
            accordionItemValue="perk-settings-advanced-options"
          >
            <DynamicKeyValueInput
              name="metadata"
              control={control}
              register={register}
              labels={{
                title: t('metadataLabel'),
                addBtn: t('addMetadataBtn'),
                key: t('metadataKey'),
                value: t('metadataValue'),
              }}
            />
            <HighlightText
              text={
                <div>
                  {t('metadataExplainations')}
                  <br />
                  <a
                    href="https://docs.getcohort.com/developers/merchants-api"
                    className="underline"
                    target="_blank"
                    onClick={() => trackPerkMetadataSettingsSeeDocLinkClicked(perk)}
                    rel="noreferrer"
                  >
                    {t('metadataDocs')}
                  </a>
                </div>
              }
              type="info"
            />
          </AdvancedOptions>
        </div>
      )}
    </form>
  );
};

export default PerkEditPage;
