import type {ConnectionADto} from '@cohort/admin-schemas/connection';
import type {SyncADto} from '@cohort/admin-schemas/sync';
import {useApps} from '@cohort/merchants/apps/useApps';
import EditConnectionModal from '@cohort/merchants/pages/connections/new/EditConnectionModal';
import AppCard from '@cohort/merchants/pages/settings/apps/AppCard';
import type {AppId, MerchantConnectorId} from '@cohort/shared/apps';
import {getAppSpec, getAppSpecs} from '@cohort/shared/apps';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {groupBy, sortBy} from 'remeda';
import {match} from 'ts-pattern';

type AppsListProps = {
  merchantConnections?: ConnectionADto[];
  merchantSyncs?: SyncADto[];
};

const AppsList: React.FC<AppsListProps> = ({merchantConnections, merchantSyncs}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'settings.apps.appsList'});
  const {appDisabled} = useApps();
  const [openedConnectionModalAppId, setOpenedConnectionModalAppId] = useState<AppId | undefined>(
    undefined
  );

  // Hide the apps that are disabled unless they have existing connections
  const appsSpecs = getAppSpecs().filter(
    appSpec =>
      !appDisabled(appSpec.id as AppId) ||
      merchantConnections?.some(
        connection => connection.connectorId === appSpec.merchantConnector.id
      )
  );

  const appsList = appsSpecs.map(appSpec => appSpec.id);

  const connectionsByConnectedApps = groupBy(merchantConnections ?? [], connection => {
    const appSpec = appsSpecs.find(
      appSpec =>
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        appSpec.merchantConnector !== null &&
        appSpec.merchantConnector.id === (connection.connectorId as MerchantConnectorId)
    );
    return appSpec?.id;
  });

  const syncsByConnectedApps = groupBy(merchantSyncs ?? [], sync => {
    const appSpec = appsSpecs.find(appSpec => appSpec.id === (sync.appId as AppId));
    return appSpec?.id;
  });

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const orderedAppsList = sortBy(appsList, app => getAppSpec(app).merchantConnector === null);
  const unconnectedApps = orderedAppsList.filter(appId => !connectionsByConnectedApps[appId]);

  const handleOnConnectClicked = (appId: AppId): void => {
    setOpenedConnectionModalAppId(appId);
  };

  const merchantConnectionsContent: JSX.Element = match(merchantConnections)
    .with(undefined, [], () => <div className="pb-14">{t('emptyConnectionsText')}</div>)
    .otherwise(() => (
      <div className="grid grid-cols-3 gap-4">
        {Object.entries(connectionsByConnectedApps).map(([appId, connections]) => (
          <AppCard
            key={appId}
            appId={appId as AppId}
            connectionsCount={connections.length}
            syncsCount={syncsByConnectedApps[appId]?.length}
            hasBrokenConnection={connections.some(connection => connection.status === 'broken')}
            onConnectClicked={handleOnConnectClicked}
          />
        ))}
      </div>
    ));

  return (
    <div className="space-y-4">
      <h3 className="mb-2 text-lg font-semibold">{t('myApps')}</h3>
      {merchantConnectionsContent}

      <h3 className="mb-2 text-lg font-semibold">{t('availableApps')}</h3>
      <div className="grid grid-cols-3 gap-4">
        {unconnectedApps.map(appId => (
          <AppCard key={appId} appId={appId as AppId} onConnectClicked={handleOnConnectClicked} />
        ))}
      </div>
      {openedConnectionModalAppId && (
        <EditConnectionModal
          onClose={() => setOpenedConnectionModalAppId(undefined)}
          appId={openedConnectionModalAppId}
        />
      )}
    </div>
  );
};

export default AppsList;
