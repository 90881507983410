import Input from '@cohort/merchants/components/form/input/Input';
import {Eye, EyeClosed} from '@phosphor-icons/react';
import {useState} from 'react';
import type {FieldValues} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

type PasswordInputProps<T extends FieldValues> = React.ComponentProps<typeof Input<T>>;

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function PasswordInput<T extends FieldValues>(
  props: PasswordInputProps<T>
): JSX.Element {
  const [showPassword, setShowPassword] = useState(false);
  const {t} = useTranslation('components', {keyPrefix: 'form.input.passwordInput'});
  const {Component, onClick} = match(showPassword)
    .with(true, () => ({
      Component: EyeClosed,
      onClick: () => setShowPassword(false),
    }))
    .with(false, () => ({
      Component: Eye,
      onClick: () => setShowPassword(true),
    }))
    .exhaustive();

  return (
    <Input
      label={t('label')}
      placeholder={t('placeholder')}
      type={showPassword ? 'text' : 'password'}
      insetPrefix={{
        component: (
          <Component
            onClick={onClick}
            className="absolute right-3 top-0 h-5 w-5 translate-y-1/2 cursor-pointer text-slate-400"
          />
        ),
        size: 0,
      }}
      {...props}
    />
  );
}
