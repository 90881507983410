import type {BadgeADto, ReorderBadgesADto} from '@cohort/admin-schemas/badge';
import AdminPage from '@cohort/merchants/components/AdminPage';
import {Badge} from '@cohort/merchants/components/Badge';
import Button from '@cohort/merchants/components/buttons/Button';
import {IconBadge} from '@cohort/merchants/components/IconBadge';
import ReorderItemsDrawer from '@cohort/merchants/components/ReorderItemsDrawer';
import {badgesKeys, useBadges} from '@cohort/merchants/hooks/api/Badges';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useBasePageFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import {reorderBadges} from '@cohort/merchants/lib/api/Badges';
import BadgeVisual from '@cohort/merchants/pages/users/badges/BadgeVisual';
import BadgesTable from '@cohort/merchants/pages/users/badges/overview/BadgesTable';
import CreateBadgeModal from '@cohort/merchants/pages/users/badges/overview/CreateBadgeModal';
import UsersOverviewMenu from '@cohort/merchants/pages/users/overview/UsersOverviewMenu';
import Tooltip from '@cohort/shared-frontend/components/Tooltip';
import {EyeSlash, ListNumbers, Medal, Plus} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

const PAGE_SIZE = 20;

const BadgesOverviewPage: React.FC = () => {
  const [showCreateBadgeModal, setShowCreateBadgeModal] = useState(false);
  const [showReorderItemsDrawer, setShowReorderItemsDrawer] = useState(false);
  const [badgesCount, setBadgesCount] = useState<number | null>(null);
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {keyPrefix: 'users.badges.page'});
  const filters = useBasePageFilters(/^(\/test)?\/badges$/u, null);
  const queryClient = useQueryClient();

  const {data, isLoading} = useBadges(merchant.id, {
    page: filters.page,
    pageSize: PAGE_SIZE,
  });

  const [pagination, badges] = data ?? [];

  const {isLoading: isReorderingBadges, mutateAsync: reorderBadgesMutation} = useCohortMutation({
    mutationFn: async (data: ReorderBadgesADto) => reorderBadges(merchant.id, data),
    notifyErrorMessage: t('notificationOrderErrorUpdate'),
    notifySuccessMessage: t('notificationOrderUpdateSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(badgesKeys.list(merchant.id));
      setShowReorderItemsDrawer(false);
    },
  });

  useEffect(() => {
    if (pagination !== undefined) {
      setBadgesCount(pagination.total);
    }
  }, [pagination]);

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
    titleBadge: (
      <IconBadge
        key="badges"
        icon={<Medal className="mr-1.5 h-4 w-4 text-slate-500" />}
        text={t('badgeCount', {count: badgesCount ?? 0})}
      />
    ),
    topRightElements: (
      <div className="space-x-4">
        <Button
          key="reorderBadge"
          onClick={() => setShowReorderItemsDrawer(true)}
          variant="secondary"
        >
          <ListNumbers className="-ml-1 mr-2 h-5 w-5" />
          {t('buttonReorderBadges')}
        </Button>
        <Button key="createBadge" onClick={() => setShowCreateBadgeModal(true)}>
          <Plus className="-ml-1 mr-2 h-5 w-5" />
          {t('buttonCreateBadge')}
        </Button>
      </div>
    ),
  };

  const paginationConfig = {
    pagination,
    onPageChange: filters.setPage,
  };

  const badgeNameDisplayer = (badge: BadgeADto): JSX.Element => {
    return (
      <Fragment>
        {badge.internalName}
        {badge.visibility === 'cohort' && (
          <Tooltip content={t('hiddenBadge')}>
            <EyeSlash size={20} className="text-slate-500" />
          </Tooltip>
        )}
      </Fragment>
    );
  };

  const statusDisplayer = (badge: BadgeADto): JSX.Element | undefined => {
    return badge.status === 'draft' ? (
      <Badge
        size="small"
        text={t('badgeStatusDraft')}
        textColor="text-yellow-800"
        backgroundColor="bg-yellow-100"
        dotColor="text-yellow-400"
      />
    ) : undefined;
  };

  return (
    <PageLayout title={t('title')} menu={<UsersOverviewMenu />}>
      <AdminPage header={headerConfig} pagination={paginationConfig}>
        <BadgesTable
          badges={badges ?? []}
          isLoading={isLoading || isReorderingBadges}
          withBadgeDeletion
        />
      </AdminPage>
      {showReorderItemsDrawer && (
        <ReorderItemsDrawer
          title={t('reorderBadgesTitle')}
          subtitle={t('reorderBadgesSubtitle')}
          items={badges ?? []}
          onSave={patchData => reorderBadgesMutation(patchData)}
          onClose={() => setShowReorderItemsDrawer(false)}
          nameDisplayer={badge => badgeNameDisplayer(badge)}
          statusDisplayer={badge => statusDisplayer(badge)}
          itemVisualComponent={BadgeVisual as React.FC}
          isLoading={isReorderingBadges}
        />
      )}
      {showCreateBadgeModal && <CreateBadgeModal onClose={() => setShowCreateBadgeModal(false)} />}
    </PageLayout>
  );
};

export default BadgesOverviewPage;
