import type {OAuthErrorMessage} from '@cohort/merchants/lib/Messages';
import {sendMessage} from '@cohort/merchants/lib/Messages';
import type {ConnectorId} from '@cohort/shared/apps';
import type {CohortErrorCode} from '@cohort/shared/schema/common/errors';
import {Fragment, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';

const CALLBACK_CLOSE_DELAY_MS = 500;

const OAuthCallbackModal: React.FC = () => {
  const [searchParams] = useSearchParams();
  const error = searchParams.get('oauthError');
  const cause = searchParams.get('oauthErrorCause');
  const connectorId = searchParams.get('oauthConnectorId');
  const connectionId = searchParams.get('connectionId');
  useEffect(() => {
    if (error || !connectionId) {
      sendMessage({
        code: 'oauth-error',
        errorCode: error as OAuthErrorMessage['errorCode'],
        connectorId: connectorId as ConnectorId,
        cause: cause as CohortErrorCode | null,
      });
    } else if (connectionId) {
      sendMessage({
        code: 'oauth-success',
        connectionId,
      });
    }
    setTimeout(() => {
      window.close();
    }, CALLBACK_CLOSE_DELAY_MS); // Delay to wait for msg to be sent
  }, [error, cause, connectorId, connectionId]);
  return <Fragment />;
};

export default OAuthCallbackModal;
