import Button from '@cohort/merchants/components/buttons/Button';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {DownloadSimple} from '@phosphor-icons/react';
import type {MutationFunction} from '@tanstack/react-query';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

type ExportCSVProps = {
  disabled?: boolean;
  downloadFunction: MutationFunction<{url: string}, void>;
  withCache?: boolean;
  className?: string;
};

const ExportCSV: React.FC<ExportCSVProps> = ({
  disabled,
  downloadFunction,
  className,
  withCache = true,
}) => {
  const {t} = useTranslation('components', {keyPrefix: 'buttons.exportCSV'});
  const [downloadUrl, setDownloadUrl] = useState<string | undefined>(undefined);

  const {isLoading: isDownloading, mutate: downloadCsv} = useCohortMutation({
    mutationFn: downloadFunction,
    onSuccess: ({url}) => {
      setDownloadUrl(url);
      window.open(url, '_top');
    },
  });

  return (
    <Button
      disabled={disabled}
      variant="secondary"
      className={className}
      loading={isDownloading}
      onClick={() => {
        if (downloadUrl && withCache) {
          window.open(downloadUrl, '_top');
        } else {
          downloadCsv();
        }
      }}
    >
      <DownloadSimple className="-ml-1 mr-2 h-5 w-5 stroke-2" />
      {t('label')}
    </Button>
  );
};

export default ExportCSV;
