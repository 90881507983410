import type {
  ListUserAttributesParamsADto,
  UpdateUserAttributesDataADto,
  UserAttributeADto,
} from '@cohort/admin-schemas/userAttributes';
import {UserAttributeASchema} from '@cohort/admin-schemas/userAttributes';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import {z} from 'zod';

export async function getUserAttributes(
  merchantId: string,
  params: ListUserAttributesParamsADto
): Promise<UserAttributeADto[]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/user-attributes`, {
    expect: HttpCodes.SUCCESS,
    parser: z.array(UserAttributeASchema).parse,
    params,
  });
}

export async function updateUserAttributes(
  merchantId: string,
  body: UpdateUserAttributesDataADto
): Promise<UserAttributeADto[]> {
  return apiCall('POST', `/v1/merchants/${merchantId}/user-attributes`, {
    expect: HttpCodes.CREATED,
    parser: z.array(UserAttributeASchema).parse,
    body,
  });
}
