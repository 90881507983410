export type ComponentsCustomisationFormState = {
  error?: string;
  customisationEnabled: boolean;
  context: Record<string, unknown>;
  canSave: boolean;
  liquidTemplate: string;
};

type UpdateLiquidTemplate = {
  type: 'UPDATE_LIQUID_TEMPLATE';
  payload: {
    liquidTemplate: string;
    context?: Record<string, unknown>;
  };
};

type UpdateCanSave = {
  type: 'UPDATE_CAN_SAVE';
  payload: boolean;
};

type SetCustomisationEnabled = {
  type: 'SET_CUSTOMISATION_ENABLED';
  payload: boolean;
};

type SetLiquidError = {
  type: 'SET_LIQUID_ERROR';
  payload: string;
};

export type ComponentsCustomisationFormAction =
  | UpdateLiquidTemplate
  | UpdateCanSave
  | SetCustomisationEnabled
  | SetLiquidError;

export function reducer(
  state: ComponentsCustomisationFormState,
  action: ComponentsCustomisationFormAction
): ComponentsCustomisationFormState {
  switch (action.type) {
    case 'UPDATE_LIQUID_TEMPLATE':
      return {
        ...state,
        error: undefined,
        liquidTemplate: action.payload.liquidTemplate,
        context: action.payload.context || state.context,
      };
    case 'UPDATE_CAN_SAVE':
      return {
        ...state,
        canSave: action.payload,
      };
    case 'SET_CUSTOMISATION_ENABLED':
      return {
        ...state,
        customisationEnabled: action.payload,
      };
    case 'SET_LIQUID_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    default:
      throw new Error();
  }
}
