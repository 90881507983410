import Input from '@cohort/merchants/components/form/input/Input';
import type {FieldValues} from 'react-hook-form';
import {useWatch} from 'react-hook-form';

type ColorInputProps<T extends FieldValues> = React.ComponentProps<typeof Input<T>>;

type ColorInputPrefixProps = {
  colorCode?: string;
};

const ColorInputPrefix: React.FC<ColorInputPrefixProps> = ({colorCode}) => (
  <div className="absolute inset-y-0 left-0 flex items-center px-3">
    <div className="h-5 w-5 rounded-full border" style={{backgroundColor: colorCode}} />
  </div>
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function ColorInput<T extends FieldValues>(props: ColorInputProps<T>): JSX.Element {
  const colorCode = useWatch({
    control: props.control,
    name: props.name,
  });

  return (
    <Input
      insetPrefix={{
        component: <ColorInputPrefix colorCode={colorCode} />,
        size: 30,
      }}
      {...props}
      type="text"
    />
  );
}
