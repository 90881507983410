import Button from '@cohort/merchants/components/buttons/Button';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {uploadAsset} from '@cohort/merchants/lib/form/utils';
import {notifyError} from '@cohort/merchants/stores/ErrorModalStore';
import type {AssetKind} from '@cohort/shared/schema/common/assets';
import {isFile} from '@cohort/shared-frontend/utils/isFile';
import {zodResolver} from '@hookform/resolvers/zod';
import React from 'react';
import type {
  Control,
  DefaultValues,
  FieldValues,
  FormState,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import type {ZodType} from 'zod';

type FormProps<T extends FieldValues> = {
  className?: string;
  formSchema?: ZodType<T>;
  defaultValues?: DefaultValues<T>;
  assets?: [
    {
      name: keyof T;
      type: AssetKind;
    },
  ];
  isSubmitting?: boolean;
  formActions?: JSX.Element[];
  onSubmit: (data: T) => void;
  children: ({
    register,
    setValue,
    control,
    formState,
    isUploading,
    watch,
  }: {
    register: UseFormRegister<T>;
    setValue: UseFormSetValue<T>;
    control: Control<T>;
    formState: FormState<T>;
    isUploading: boolean;
    watch: UseFormWatch<T>;
  }) => JSX.Element;
  testId?: string;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function Form<T extends FieldValues>({
  className,
  defaultValues,
  formSchema,
  assets,
  isSubmitting,
  formActions,
  testId,
  onSubmit,
  children,
}: FormProps<T>): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {t} = useTranslation('components', {keyPrefix: 'form.v2.form'});
  const {register, handleSubmit, setValue, control, formState, watch} = useForm<T>({
    defaultValues,
    resolver: formSchema ? zodResolver(formSchema) : undefined,
  });

  const {isLoading: isUploading, mutateAsync: uploadFile} = useCohortMutation({
    mutationFn: async ({file, assetKind}: {file: File; assetKind: AssetKind}) =>
      uploadAsset(file, assetKind, merchantId),
    onError: err => notifyError(err, t('errorFileUploadFailed')),
  });

  return (
    <form
      data-testid={testId}
      className={className}
      onSubmit={handleSubmit(async data => {
        if (assets) {
          for (const {name, type} of assets) {
            if (name in data && isFile(data[name])) {
              const file = await uploadFile({file: data[name], assetKind: type});

              data = {
                ...data,
                [name]: file.fileKey,
              };
            }
          }
        }
        return onSubmit(data);
      })}
    >
      {children({
        register,
        setValue,
        control,
        formState,
        isUploading,
        watch,
      })}
      {formActions ? (
        <div className="flex justify-between space-x-2">
          {formActions.map((action, idx) => {
            // identify submit button and attach form data
            if (action.props.type === 'submit') {
              return React.createElement(action.type, {
                disabled: !formState.isDirty,
                ...action.props,
                // eslint-disable-next-line react/no-array-index-key
                key: `form-submit-btn-${idx}`,
                loading: isSubmitting || isUploading,
              });
            }
            return action;
          })}
        </div>
      ) : (
        <div className="flex justify-end space-x-2">
          <Button
            type="submit"
            loading={isSubmitting || isUploading}
            disabled={!formState.isDirty}
            data-testid="save"
          >
            {t('buttonSave')}
          </Button>
        </div>
      )}
    </form>
  );
}
