import {DateSchema} from '@cohort/shared/schema/common';
import {match} from 'ts-pattern';
import {z} from 'zod';

export const DataTypeSchema = z.enum([
  'boolean',
  'date',
  'number',
  'null',
  'string',
  'string_list',
  'resource',
  'resource_list',
]);
export type DataType = z.infer<typeof DataTypeSchema>;

export function getDataTypeSchema(dataType: DataType): z.ZodTypeAny {
  const schema = match(dataType)
    .with('boolean', () => z.boolean())
    .with('date', () => DateSchema)
    .with('number', () => z.number())
    .with('null', () => z.null())
    .with('string', () => z.string())
    .with('string_list', () => z.array(z.string()))
    .with('resource', () => z.string())
    .with('resource_list', () =>
      z.array(
        z.object({
          id: z.string(),
          name: z.string(),
        })
      )
    )
    .exhaustive();
  return schema;
}

export function parseValue(dataType: DataType, value: unknown): unknown {
  const schema = getDataTypeSchema(dataType);
  return schema.parse(value);
}
