import type {WrappedField} from '@cohort/merchants/components/form/FieldWrapper';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {useTranslation} from 'react-i18next';

type FieldLabelProps = Pick<WrappedField, 'label' | 'optional' | 'labelPosition'> & {
  name: string;
};

const FieldLabel: React.FC<FieldLabelProps> = ({label, optional, name, labelPosition}) => {
  const {t} = useTranslation('components', {keyPrefix: 'form.v2.fieldWrapper'});

  return (
    <label
      className={cn(
        'block text-sm font-medium text-slate-700',
        labelPosition === 'right' && 'order-2 whitespace-nowrap'
      )}
      htmlFor={name}
    >
      {`${label}${optional ? t('labelOptional') : ''}`}
    </label>
  );
};

export default FieldLabel;
