import {cn} from '@cohort/shared-frontend/utils/classNames';
import React from 'react';
import {useTranslation} from 'react-i18next';

type FooterProps = {
  selectedLanguage: string;
  isMobile?: boolean;
};

const Footer = ({selectedLanguage, isMobile}: FooterProps): React.ReactElement => {
  const {t} = useTranslation('components', {
    keyPrefix: 'previews.CampaignStorePreview',
    lng: selectedLanguage,
  });

  const navigation = [
    {
      name: t('labelPrivacy'),
    },
    {
      name: t('labelTerms'),
    },
  ];

  return (
    <footer className="border-t">
      <div
        className={cn(
          'flex flex-col justify-between gap-4 px-4 py-6',
          !isMobile && 'flex-row px-0'
        )}
      >
        <a key={'linkbrand'} target="_blank" rel="noopener noreferrer" className="text-sm">
          {t('labelPowered')}
        </a>

        <div className={cn('flex flex-col gap-4', !isMobile && 'flex-row')}>
          {navigation.map(item => (
            <a key={item.name} target="_blank" rel="noopener noreferrer" className="text-sm">
              {item.name}
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
