import {PerkIntegrationIdSchema} from '@cohort/shared/apps';
import {PerkTypeSchema, RaffleInstanceStatusSchema} from '@cohort/shared/schema/common/perks';
import {DefaultContextSchema} from '@cohort/shared/schema/templating/common';
import {z} from 'zod';

export const PerkTSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  description: z.string().nullable(),
  imageUrl: z.string().url().nullable(),
  videoUrl: z.string().url().nullable(),
  videoThumbnailUrl: z.string().url().nullable(),
  type: PerkTypeSchema,
  raffle: z
    .object({
      status: RaffleInstanceStatusSchema,
    })
    .nullable(),
  integration: z
    .object({
      type: PerkIntegrationIdSchema,
    })
    .nullable(),
  access: z
    .object({
      id: z.string().uuid(),
      usagesCount: z.number().int().min(0),
      usagesLeft: z.number().int().min(0).nullable(),
      canBeUsed: z.boolean(),
    })
    .nullable(),
});

export type PerkTDto = z.infer<typeof PerkTSchema>;

export const PerkContextTSchema = DefaultContextSchema.extend({
  perk: PerkTSchema,
});
export type PerkContextTDto = z.infer<typeof PerkContextTSchema>;
