import {InformationTooltip} from '@cohort/merchants/components/InformationTooltip';
import RewardVisual from '@cohort/merchants/components/rewards/RewardVisual';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import RewardListItem from '@cohort/merchants/pages/campaigns/campaign/edit/reward/RewardListItem';
import type {CollectionItem} from '@cohort/merchants/pages/campaigns/campaign/edit/reward/RewardSelectionModal';
import Switch from '@cohort/shared-frontend/components/Switch';
import {useTranslation} from 'react-i18next';

type RewardCollectionListItemProps = {
  collectionItem: CollectionItem;
  index: number;
  handleRemove: () => void;
};

const RewardCollectionListItem: React.FC<RewardCollectionListItemProps> = ({
  collectionItem,
  handleRemove,
}) => {
  const campaign = useCurrentCampaign();
  const revocableOption = campaign.type === 'airdrop' && campaign.cohortId !== null;
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.reward.rewardCollectionListItem',
  });

  return (
    <RewardListItem
      visual={<RewardVisual type="collection" collection={collectionItem.collection} />}
      limitPerUser={collectionItem.collection.maxOwnershipsPerUser}
      handleRemove={handleRemove}
    >
      {revocableOption && (
        <div className="flex flex-row items-center gap-x-2">
          <Switch checked={false} className="cursor-not-allowed opacity-30" />
          <p className="text-sm font-medium text-slate-700 opacity-30">{t('labelRevocable')}</p>
          <InformationTooltip
            className="h-5 w-5"
            content={t('tooltipCollection', {cohortName: campaign.cohort?.name})}
          />
        </div>
      )}
    </RewardListItem>
  );
};
export default RewardCollectionListItem;
