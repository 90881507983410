import {forwardRef} from 'react';

type CampaignSettingsSectionProps = {
  title: string;
  children: React.ReactNode;
};

const CampaignSettingsSection = forwardRef(
  ({title, children}: CampaignSettingsSectionProps, ref: React.Ref<HTMLDivElement> | undefined) => (
    <section ref={ref} className="space-y-6 border-b pb-8">
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
      </div>
      {children}
    </section>
  )
);

export default CampaignSettingsSection;
