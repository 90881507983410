import App from '@cohort/merchants/App';
import type FeatureFlags from '@cohort/merchants/featureFlags';
import useDefaultRoute from '@cohort/merchants/hooks/useDefaultRoute';
import AuthLayout from '@cohort/merchants/layouts/AuthLayout';
import NoAuthLayout from '@cohort/merchants/layouts/NoAuthLayout';
import OrgPickerLayout from '@cohort/merchants/layouts/OrgPickerLayout';
import {OAUTH_CALLBACK_PATH} from '@cohort/merchants/lib/Pages';
import AppPageLayout from '@cohort/merchants/pages/apps/app/AppPageLayout';
import AppConnectionsPage from '@cohort/merchants/pages/apps/app/connections/page';
import SyncEditPageLayout from '@cohort/merchants/pages/apps/app/syncs/edit/SyncEditPageLayout';
import SyncImportUsersConfigPage from '@cohort/merchants/pages/apps/app/syncs/edit/SyncImportUsersConfigPage';
import AppSyncsPage from '@cohort/merchants/pages/apps/app/syncs/page';
import CampaignActivityPage from '@cohort/merchants/pages/campaigns/campaign/activity/page';
import CampaignAudienceEditPage from '@cohort/merchants/pages/campaigns/campaign/audience/edit/page';
import CampaignAudiencePage from '@cohort/merchants/pages/campaigns/campaign/audience/page';
import CampaignPageLayout from '@cohort/merchants/pages/campaigns/campaign/CampaignPageLayout';
import CampaignEditPage from '@cohort/merchants/pages/campaigns/campaign/edit/page';
import CampaignNotificationsPage from '@cohort/merchants/pages/campaigns/campaign/notifications/page';
import CampaignRewardEditPage from '@cohort/merchants/pages/campaigns/campaign/reward/edit/page';
import CampaignSettingsPage from '@cohort/merchants/pages/campaigns/campaign/settings/page';
import CampaignsOverviewPage from '@cohort/merchants/pages/campaigns/overview/page';
import ChangePasswordPage from '@cohort/merchants/pages/change-password/page';
import OAuthCallbackModal from '@cohort/merchants/pages/connections/OAuthCallbackModal';
import ContentCategoriesOverviewPage from '@cohort/merchants/pages/content-categories/overview/page';
import ContentsOverviewPage from '@cohort/merchants/pages/contents/overview/page';
import DigitalAssetCollectionCampaignsPage from '@cohort/merchants/pages/digital-asset-collections/digital-asset-collection/campaigns/page';
import DigitalAssetCollectionPageLayout from '@cohort/merchants/pages/digital-asset-collections/digital-asset-collection/DigitalAssetCollectionPageLayout';
import DigitalAssetCollectionNotificationsPage from '@cohort/merchants/pages/digital-asset-collections/digital-asset-collection/notifications/page';
import DigitalAssetCollectionOwnersPage from '@cohort/merchants/pages/digital-asset-collections/digital-asset-collection/owners/page';
import DigitalAssetCollectionSettingsPage from '@cohort/merchants/pages/digital-asset-collections/digital-asset-collection/settings/page';
import DigitalAssetsOverviewPage from '@cohort/merchants/pages/digital-asset-collections/overview/page';
import ForgotPasswordPage from '@cohort/merchants/pages/forgot-password/page';
import InvitationPage from '@cohort/merchants/pages/invitation/page';
import NotFoundPage from '@cohort/merchants/pages/not-found/page';
import CreatePerkPage from '@cohort/merchants/pages/perks/new/page';
import PerksOverviewPage from '@cohort/merchants/pages/perks/overview/page';
import PerkCampaignsPage from '@cohort/merchants/pages/perks/perk/campaigns/page';
import PerkEditPage from '@cohort/merchants/pages/perks/perk/edit/page';
import PerkNotificationsPage from '@cohort/merchants/pages/perks/perk/notifications/page';
import PerkOwnersPage from '@cohort/merchants/pages/perks/perk/owners/page';
import PerkPageLayout from '@cohort/merchants/pages/perks/perk/PerkPageLayout';
import SentryTestPage from '@cohort/merchants/pages/sentry/page';
import AppsSettingsPage from '@cohort/merchants/pages/settings/apps/page';
import BrandSettingsPage from '@cohort/merchants/pages/settings/brand/page';
import CustomizationSettingsPage from '@cohort/merchants/pages/settings/customization/page';
import IntegrationsSettingsPage from '@cohort/merchants/pages/settings/integrations/page';
import MembersSettingsPage from '@cohort/merchants/pages/settings/members/page';
import MembershipPassConfigurationSettingsPage from '@cohort/merchants/pages/settings/membership-pass-configuration/page';
import NotificationsSettingsPage from '@cohort/merchants/pages/settings/notifications/page';
import PaymentSettingsPage from '@cohort/merchants/pages/settings/payments/page';
import QrReadersSettingsPage from '@cohort/merchants/pages/settings/qr-readers/page';
import SettingsLayout from '@cohort/merchants/pages/settings/SettingsLayout';
import LanguagesSettingsPage from '@cohort/merchants/pages/settings/supported-languages/page';
import UserPropertiesSettingsPage from '@cohort/merchants/pages/settings/user-properties/page';
import UserPropertyDetailPage from '@cohort/merchants/pages/settings/user-properties/UserPropertyDetailPage';
import OrgPickerPage from '@cohort/merchants/pages/sign-in/org-picker/page';
import SignInPage from '@cohort/merchants/pages/sign-in/page';
import BadgeAppearancePage from '@cohort/merchants/pages/users/badges/badge/appearance/page';
import BadgeCohortPage from '@cohort/merchants/pages/users/badges/badge/cohort/page';
import BadgeEditPage from '@cohort/merchants/pages/users/badges/badge/edit/page';
import BadgeNotificationsPage from '@cohort/merchants/pages/users/badges/badge/notifications/page';
import BadgeVisibilityPage from '@cohort/merchants/pages/users/badges/badge/visibility/page';
import BadgePageLayout from '@cohort/merchants/pages/users/badges/BadgePageLayout';
import BadgesOverviewPage from '@cohort/merchants/pages/users/badges/page';
import CohortPageLayout from '@cohort/merchants/pages/users/cohort/CohortPageLayout';
import CohortMembersPage from '@cohort/merchants/pages/users/cohort/members/page';
import DynamicCohortRulePage from '@cohort/merchants/pages/users/cohort/rule/page';
import CohortsOverviewPage from '@cohort/merchants/pages/users/cohorts/page';
import UsersOverviewPage from '@cohort/merchants/pages/users/overview/page';
import UserActivityPage from '@cohort/merchants/pages/users/user/activity/page';
import UserBadgesPage from '@cohort/merchants/pages/users/user/badges/page';
import UserCohortsPage from '@cohort/merchants/pages/users/user/cohorts/page';
import UserDigitalAssetsPage from '@cohort/merchants/pages/users/user/digital-assets/page';
import UserJourneysPage from '@cohort/merchants/pages/users/user/journeys/page';
import UserOverviewPage from '@cohort/merchants/pages/users/user/overview/page';
import UserPerksPage from '@cohort/merchants/pages/users/user/perks/page';
import UserPageLayout from '@cohort/merchants/pages/users/user/UserPageLayout';
import {wrapUseRoutes} from '@sentry/react';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {Navigate, useRoutes} from 'react-router-dom';

const useSentryRoutes = wrapUseRoutes(useRoutes);

const MerchantsRoutes: React.FC = () => {
  const defaultRoute = useDefaultRoute();
  const {featureContent} = useFlags<FeatureFlags>();

  const notAuthRoutes = [
    {index: true, element: <Navigate to="/sign-in" replace />},
    {path: 'sign-in', element: <SignInPage />},
    {
      path: OAUTH_CALLBACK_PATH,
      element: <OAuthCallbackModal />,
    },
  ];
  const authRoutes = [
    {index: true, element: <Navigate to={defaultRoute} replace />},
    {
      path: 'users/*',
      children: [
        {
          index: true,
          element: <UsersOverviewPage />,
        },
        {
          path: ':userId/*',
          element: <UserPageLayout />,
          children: [
            {
              index: true,
              element: <Navigate to="overview" replace />,
            },
            {
              path: 'activity',
              element: <UserActivityPage />,
            },
            {
              path: 'badges',
              element: <UserBadgesPage />,
            },
            {
              path: 'cohorts',
              element: <UserCohortsPage />,
            },
            {
              path: 'overview',
              element: <UserOverviewPage />,
            },
            {
              path: 'digital-assets',
              element: <UserDigitalAssetsPage />,
            },
            {
              path: 'journeys',
              element: <UserJourneysPage />,
            },
            {
              path: 'perks',
              element: <UserPerksPage />,
            },
          ],
        },
        {
          path: '*',
          element: <Navigate to="/not-found" replace />,
        },
      ],
    },
    {
      path: 'badges/*',
      children: [
        {
          index: true,
          element: <BadgesOverviewPage />,
        },
        {
          path: ':badgeId/*',
          element: <BadgePageLayout />,
          children: [
            {
              index: true,
              element: <Navigate to="appearance" replace />,
            },
            {
              path: 'appearance',
              element: <BadgeAppearancePage />,
            },
            {
              path: 'cohort',
              element: <BadgeCohortPage />,
            },
            {
              path: 'edit',
              element: <BadgeEditPage />,
            },
            {
              path: 'visibility',
              element: <BadgeVisibilityPage />,
            },
            {
              path: 'notifications',
              element: <BadgeNotificationsPage />,
            },
          ],
        },
      ],
    },
    {
      path: 'cohorts/*',
      children: [
        {
          index: true,
          element: <CohortsOverviewPage />,
        },
        {
          path: ':cohortId/*',
          element: <CohortPageLayout />,
          children: [
            {
              index: true,
              element: <Navigate to="members" replace />,
            },
            {
              path: 'members',
              element: <CohortMembersPage />,
            },
            {
              path: 'rule',
              element: <DynamicCohortRulePage />,
            },
          ],
        },
      ],
    },
    {
      path: 'campaigns/*',
      children: [
        {
          index: true,
          element: <CampaignsOverviewPage />,
        },
        {
          path: ':campaignId/*',
          element: <CampaignPageLayout />,
          children: [
            {
              index: true,
              element: <Navigate to="activity" replace />,
            },
            {
              path: 'activity',
              element: <CampaignActivityPage />,
            },
            {
              path: 'audience',
              element: <CampaignAudiencePage />,
            },
            {
              path: 'audience/edit',
              element: <CampaignAudienceEditPage />,
            },
            {
              path: 'edit',
              element: <CampaignEditPage />,
            },
            {
              path: 'notifications',
              element: <CampaignNotificationsPage />,
            },
            {
              path: 'reward',
              element: <CampaignRewardEditPage />,
            },
            {
              path: 'settings',
              element: <CampaignSettingsPage />,
            },
            {
              path: '*',
              element: <Navigate to="/not-found" replace />,
            },
          ],
        },
      ],
    },
    ...(featureContent
      ? [
          {
            path: 'contents',
            children: [
              {
                index: true,
                element: <ContentsOverviewPage />,
              },
            ],
          },
        ]
      : []),
    {
      path: 'content-categories',
      children: [
        {
          index: true,
          element: <ContentCategoriesOverviewPage />,
        },
      ],
    },
    {
      path: 'perks',
      children: [
        {
          index: true,
          element: <PerksOverviewPage />,
        },
        {
          path: 'new',
          element: <CreatePerkPage />,
        },
        {
          path: ':perkId/*',
          element: <PerkPageLayout />,
          children: [
            {
              index: true,
              element: <Navigate to="owners" replace />,
            },
            {
              path: 'owners',
              element: <PerkOwnersPage />,
            },
            {
              path: 'campaigns',
              element: <PerkCampaignsPage />,
            },
            {
              path: 'notifications',
              element: <PerkNotificationsPage />,
            },
            {
              path: 'edit',
              element: <PerkEditPage />,
            },
            {
              path: '*',
              element: <Navigate to="/not-found" replace />,
            },
          ],
        },
      ],
    },
    {
      path: 'digital-assets',
      children: [
        {
          index: true,
          element: <DigitalAssetsOverviewPage />,
        },
        {
          path: ':digitalAssetCollectionId/*',
          element: <DigitalAssetCollectionPageLayout />,
          children: [
            {
              index: true,
              element: <Navigate to="owners" replace />,
            },
            {
              path: 'owners',
              element: <DigitalAssetCollectionOwnersPage />,
            },
            {
              path: 'campaigns',
              element: <DigitalAssetCollectionCampaignsPage />,
            },
            {
              path: 'notifications',
              element: <DigitalAssetCollectionNotificationsPage />,
            },
            {
              path: 'settings',
              element: <DigitalAssetCollectionSettingsPage />,
            },
            {
              path: '*',
              element: <Navigate to="/not-found" replace />,
            },
          ],
        },
        {
          path: '*',
          element: <Navigate to="/not-found" replace />,
        },
      ],
    },
    {
      path: 'apps',
      children: [
        {
          index: true,
          element: <Navigate to="/settings/apps" replace />,
        },
        {
          path: ':appId/*',
          element: <AppPageLayout />,
          children: [
            {
              index: true,
              element: <Navigate to="connections" replace />,
            },
            {
              path: 'connections',
              element: <AppConnectionsPage />,
            },
            {
              path: 'syncs',
              element: <AppSyncsPage />,
            },
          ],
        },
        {
          path: ':appId/syncs/:syncId/*',
          element: <SyncEditPageLayout />,
          children: [
            {
              index: true,
              element: <Navigate to="users/import" replace />,
            },
            {
              path: 'users/import',
              element: <SyncImportUsersConfigPage />,
            },
          ],
        },
      ],
    },
    {
      path: 'settings/*',
      element: <SettingsLayout />,
      children: [
        {
          index: true,
          element: <Navigate to="brand" replace />,
        },
        {
          path: 'brand',
          element: <BrandSettingsPage />,
        },
        {
          path: 'customization',
          element: <CustomizationSettingsPage />,
        },
        {
          path: 'apps',
          children: [
            {
              index: true,
              element: <AppsSettingsPage />,
            },
          ],
        },
        {
          path: 'notifications',
          element: <NotificationsSettingsPage />,
        },
        {
          path: 'membership-pass-configuration',
          element: <MembershipPassConfigurationSettingsPage />,
        },
        {
          path: 'payments',
          element: <PaymentSettingsPage />,
        },
        {
          path: 'qr-readers',
          element: <QrReadersSettingsPage />,
        },
        {
          path: 'integrations',
          element: <IntegrationsSettingsPage />,
        },
        {
          path: 'team',
          element: <MembersSettingsPage />,
        },
        {
          path: 'languages',
          element: <LanguagesSettingsPage />,
        },
        {
          path: 'user-properties',
          children: [
            {
              index: true,
              element: <UserPropertiesSettingsPage />,
            },
            {
              path: ':userPropertyId',
              element: <UserPropertyDetailPage />,
            },
          ],
        },
        {
          path: '*',
          element: <Navigate to="/not-found" replace />,
        },
      ],
    },
  ];

  return useSentryRoutes([
    {
      path: '/',
      element: <App />,
      children: [
        {
          path: '/not-found',
          element: <NotFoundPage />,
        },
        {
          element: <NoAuthLayout />,
          children: notAuthRoutes,
        },
        {
          path: '/invitation',
          element: <InvitationPage />,
        },
        {
          path: '/forgot-password',
          element: <ForgotPasswordPage />,
        },
        {
          path: '/change-password',
          element: <ChangePasswordPage />,
        },
        // make the next line only in development
        ...(import.meta.env.COHORT_ENV === 'development'
          ? [
              {
                path: '/sentry',
                element: <SentryTestPage />,
              },
            ]
          : []),
        {
          element: <AuthLayout />,
          children: [
            {
              path: '/',
              children: authRoutes,
            },
            {
              path: '/test',
              children: authRoutes,
            },
          ],
        },
        {
          element: <OrgPickerLayout />,
          children: [{path: 'sign-in/organizations', element: <OrgPickerPage />}],
        },
        {path: '*', element: <Navigate to="/not-found" replace />},
      ],
    },
  ]);
};

export default MerchantsRoutes;
