import {BadgeASchema} from '@cohort/admin-schemas/badge';
import {DateSchema, EmailSchema} from '@cohort/shared/schema/common';
import {CohortTypeSchema} from '@cohort/shared/schema/common/cohort';
import {PaginationParamsSchema} from '@cohort/shared/schema/common/pagination';
import {z} from 'zod';

export const CohortASchema = z.object({
  id: z.string().uuid(),
  name: z.string().min(3),
  referenceId: z.string(),
  type: CohortTypeSchema,
  lastComputed: DateSchema.nullable(),
  createdAt: DateSchema,
  ruleId: z.string().uuid().nullable(),
  usersCount: z.number().optional(),
});
export type CohortADto = z.infer<typeof CohortASchema>;

export const CohortWithBadgeASchema = CohortASchema.extend({
  badges: z.array(BadgeASchema),
});
export type CohortWithBadgeADto = z.infer<typeof CohortWithBadgeASchema>;

export const CreateCohortASchema = z.object({
  name: z.string().min(3),
  referenceId: z.string().min(3).optional(),
  type: CohortTypeSchema,
});
export type CreateCohortADto = z.infer<typeof CreateCohortASchema>;

export const UpdateCohortASchema = z
  .object({
    name: z.string().min(3),
    referenceId: z.string().min(3),
  })
  .strict()
  .deepPartial();
export type UpdateCohortADto = z.infer<typeof UpdateCohortASchema>;

export const GetCohortsParamsASchema = PaginationParamsSchema.extend({
  search: z.string().optional(),
  type: CohortTypeSchema.optional(),
});
export type GetCohortsParamsADto = z.infer<typeof GetCohortsParamsASchema>;

export const GetCohortUsersParamsASchema = PaginationParamsSchema;
export type GetCohortUsersParamsADto = z.infer<typeof GetCohortUsersParamsASchema>;

export const CohortUserASchema = z.object({
  id: z.string().uuid(),
  email: EmailSchema,
  membershipPassHandle: z.string(),
  memberSince: DateSchema,
  lastActiveAt: DateSchema,
  notificationsEnabled: z.boolean(),
});
export type CohortUserADto = z.infer<typeof CohortUserASchema>;

export const CohortUsersExportParamsASchema = z.object({
  format: z.enum(['csv']),
});
export type CohortUsersExportParamsADto = z.infer<typeof CohortUsersExportParamsASchema>;
