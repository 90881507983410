import type {CohortADto} from '@cohort/admin-schemas/cohort';
import Button from '@cohort/merchants/components/buttons/Button';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import {cohortsKeys} from '@cohort/merchants/hooks/api/Cohorts';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {notify} from '@cohort/merchants/hooks/toast';
import {deleteCohort} from '@cohort/merchants/lib/api/Cohorts';
import {getCohortsRoute} from '@cohort/merchants/lib/Pages';
import type {CohortError} from '@cohort/shared/schema/common/errors';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import {Trash} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

type DeleteCohortProps = {
  cohort: CohortADto;
};

const DeleteCohort: React.FC<DeleteCohortProps> = ({cohort}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {t} = useTranslation('pages', {
    keyPrefix: 'users.cohort.deleteCohort',
  });

  const notifyCohortUsedInRuleError = (err: CohortError): void => {
    const cohortList = (err.context.cohorts as Array<{id: string; name: string}>)
      .map(cohort => cohort.name)
      .join(', ');

    return notify('error', t('cohortDeleteErrorTitle'), {
      description: (
        <p className="text-sm">
          <Trans
            i18nKey="users.cohort.deleteCohort.cohortDeleteErrorDescription"
            ns="pages"
            components={{
              list: <span className="font-medium"></span>,
            }}
            values={{
              cohorts: cohortList,
            }}
          />
        </p>
      ),
      duration: 7000,
    });
  };

  const notifyCohortUsedInBadgeError = (err: CohortError): void => {
    const badgeList = (err.context.badges as Array<{id: string; internalName: string}>)
      .map(badge => badge.internalName)
      .join(', ');

    return notify('error', t('cohortDeleteErrorTitle'), {
      description: (
        <p className="text-sm">
          <Trans
            i18nKey="users.cohort.deleteCohort.cohortDeleteBadgeErrorDescription"
            ns="pages"
            components={{
              list: <span className="font-medium"></span>,
            }}
            values={{
              badges: badgeList,
            }}
          />
        </p>
      ),
      duration: 7000,
    });
  };

  const {mutate: handleCohortDelete} = useCohortMutation({
    mutationFn: async () => deleteCohort(merchantId, cohort.id),
    notifySuccessMessage: t('notificationDeleteSuccess'),
    onSuccess: async () => {
      await queryClient.invalidateQueries(cohortsKeys.list(merchantId));
      navigate(getCohortsRoute().path);
    },
    onError: err => {
      if (isCohortError(err, ['cohort.used-in-rule', 'cohort.used-in-badge'])) {
        if (err.code === 'cohort.used-in-rule') {
          return notifyCohortUsedInRuleError(err);
        }
        return notifyCohortUsedInBadgeError(err);
      }
      throw err;
    },
  });

  return (
    <div>
      <Button variant="secondary" size="icon" onClick={() => setShowDeleteModal(true)}>
        <Trash size={20} className="text-red-400" />
      </Button>
      {showDeleteModal && (
        <DeletionModal
          title={t('deleteModalTitle')}
          subtitle={t('deleteModalSubtitle')}
          onClose={() => setShowDeleteModal(false)}
          show={true}
          onDelete={handleCohortDelete}
        />
      )}
    </div>
  );
};

export default DeleteCohort;
