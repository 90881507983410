import {queryStorage} from '@cohort/merchants/lib/stores/queryStorage';
import {create} from 'zustand';
import {createJSONStorage, persist} from 'zustand/middleware';

export type PaginationFilters = {
  page: number;
  setPage: (page: number) => void;
  reset: () => void;
};

export function useBasePageFilters<T extends Record<string, unknown>>(
  pagePath: RegExp | string,
  additionalState: ((set: (state: Partial<T & PaginationFilters>) => void) => T) | null
): T & PaginationFilters {
  type MergedFilters = T & PaginationFilters;

  const storeOptions = {
    name: pagePath.toString(),
    storage: createJSONStorage(() => queryStorage(pagePath)),
  };

  // default pagination filters state
  const setBaseState: (
    set: (state: Partial<MergedFilters>) => void
  ) => PaginationFilters = set => ({
    page: 1,
    setPage: page => set({page} as Partial<MergedFilters>),
    reset: () => set({page: 1} as Partial<MergedFilters>),
  });

  const store = create<MergedFilters>()(
    persist(set => {
      if (additionalState === null) {
        return setBaseState(set) as MergedFilters;
      }
      // merge of pagination and incoming state
      return {
        ...setBaseState(set),
        ...additionalState(set),
      };
    }, storeOptions)
  )();

  return store;
}
