import type {AssetADto} from '@cohort/admin-schemas/asset';
import type {PerkADto} from '@cohort/admin-schemas/perk';
import {PerkMaxUsagesPerTokenASchema} from '@cohort/admin-schemas/perk';
import {
  removeLanguagesFromQuestions,
  removeUndefinedLanguages,
} from '@cohort/merchants/lib/form/localization';
import {handleFormErrors} from '@cohort/merchants/lib/form/utils';
import {fileValidatorOptional} from '@cohort/merchants/lib/form/validators';
import type {PerkIntegrationId} from '@cohort/shared/apps';
import {PerkIntegrationIdSchema} from '@cohort/shared/apps';
import {ShopifyDiscountPerkTypeSchema} from '@cohort/shared/apps/shopify/perks/discount';
import type {Language, LocalizedString} from '@cohort/shared/schema/common';
import {
  LanguageSchema,
  LocalizedRichTextSchema,
  LocalizedStringSchema,
} from '@cohort/shared/schema/common';
import type {AssetKind} from '@cohort/shared/schema/common/assets';
import type {CohortFormConfig} from '@cohort/shared/schema/common/cohortForm';
import {CohortFormQuestionSchema} from '@cohort/shared/schema/common/cohortForm';
import {PrivateContentPerkContentTypeSchema} from '@cohort/shared/schema/common/perks';
import {contentTypeFromMimeType} from '@cohort/shared/utils/mimeTypes';
import {isFile} from '@cohort/shared-frontend/utils/isFile';
import type {UseMutateAsyncFunction} from '@tanstack/react-query';
import get from 'lodash/get';
import set from 'lodash/set';
import type {UseFormClearErrors, UseFormSetError} from 'react-hook-form';
import {isNil} from 'remeda';
import {z} from 'zod';

const customErrorMap: z.ZodErrorMap = (error, ctx) => {
  if (error.code === z.ZodIssueCode.invalid_type) {
    return {message: 'errorRequired'};
  }
  if (error.code === z.ZodIssueCode.invalid_date) {
    return {message: 'errorInvalidDate'};
  }
  return {message: ctx.defaultError};
};

// Needed to override the default error messages from enums
z.setErrorMap(customErrorMap);

// TODO @nathan: We can remove the "config" later
const DiscordPerkSchema = z.object({
  perkIntegrationId: z.literal(PerkIntegrationIdSchema.enum['discord.private-access']),
  connectionId: z.string(),
  config: z.object({}),
});

const InstagramPerkSchema = z.object({
  perkIntegrationId: z.literal(PerkIntegrationIdSchema.enum['instagram.close-friends-list']),
  connectionId: z.string(),
  config: z.object({
    username: z.string().regex(/^[a-zA-Z0-9._]+$/u, 'errorFormat'),
  }),
});

const QRCodePerkSchema = z.object({
  perkIntegrationId: z.literal(PerkIntegrationIdSchema.enum['cohort.qr-code']),
  connectionId: z.null(),
  config: z.object({
    validity: z.enum(['once', 'unlimited', 'custom']).default('unlimited'),
  }),
});

const TalonOneCouponPerkSchema = z.object({
  perkIntegrationId: z.literal(PerkIntegrationIdSchema.enum['talon-one.coupon']),
  connectionId: z.string(),
  config: z.object({
    campaignId: z.number().min(1, {message: 'errorRequired'}),
    usageLimit: z.number().min(0, {message: 'errorRequired'}),
    storeUrl: z.string().url({message: 'errorInvalidUrl'}),
  }),
});

const TypeformPerkSchema = z.object({
  perkIntegrationId: z.literal(PerkIntegrationIdSchema.enum['typeform.form']),
  connectionId: z.string(),
  config: z.object({
    formId: z.string().min(1, {message: 'errorRequired'}),
  }),
});

const ShopifyPerkSchema = z.object({
  perkIntegrationId: z.literal(PerkIntegrationIdSchema.enum['shopify.discount']),
  connectionId: z.string(),
  config: z
    .object({
      name: z.string().min(1, {message: 'errorRequired'}),
      type: ShopifyDiscountPerkTypeSchema,
      value: z.number().min(1, {message: 'errorRequired'}),
      singleUse: z.boolean(),
    })
    .superRefine(({type, value}, ctx) => {
      if (type === 'percentage' && value > 100) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'errorValue',
          path: ['value'],
        });
      }
    }),
});

const FormPerkSchema = z.object({
  perkIntegrationId: z.literal(PerkIntegrationIdSchema.enum['cohort.form']),
  connectionId: z.null(),
  config: z.object({
    description: LocalizedStringSchema,
    questions: z.array(CohortFormQuestionSchema).min(1, {message: 'errorRequired'}),
  }),
});

const PerkIntegrationSchema = z.discriminatedUnion('perkIntegrationId', [
  DiscordPerkSchema,
  InstagramPerkSchema,
  QRCodePerkSchema,
  TalonOneCouponPerkSchema,
  TypeformPerkSchema,
  ShopifyPerkSchema,
  FormPerkSchema,
]);
type PerkIntegration = z.infer<typeof PerkIntegrationSchema>;

const checkDisplayNameDefaultLanguage = (
  request: {
    displayName: LocalizedString;
    defaultLanguage: Language;
  },
  ctx: z.RefinementCtx
): void => {
  if (
    request.displayName[request.defaultLanguage] === undefined ||
    request.displayName[request.defaultLanguage] === null
  ) {
    return ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'errorRequired',
      path: [`displayName.${request.defaultLanguage}`],
    });
  }
};

const checkFormDescriptionDefaultLanguage = (
  request: {
    integration: PerkIntegration | null;
    integrationId?: PerkIntegrationId | null;
    defaultLanguage: Language;
  },
  ctx: z.RefinementCtx
): void => {
  if (
    request.integrationId === 'cohort.form' &&
    request.integration?.config !== undefined &&
    (request.integration.config as CohortFormConfig).description[request.defaultLanguage] ===
      undefined
  ) {
    return ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'errorRequired',
      path: [`integration.config.description.${request.defaultLanguage}`],
    });
  }
};

export const PerkFormSchema = z
  .object({
    displayName: LocalizedStringSchema,
    description: LocalizedRichTextSchema,
    access: z.enum(['regular', 'giveaway']).default('regular'),
    visual: z.enum(['text', 'image', 'video']).default('text'),
    hasIntegration: z.enum(['yes', 'no']).default('no'),
    bannerFileKey: z.any().refine(fileValidatorOptional),
    thumbnailFileKey: z.any().refine(fileValidatorOptional),
    videoFileKey: z.any().refine(fileValidatorOptional),
    integration: PerkIntegrationSchema.nullable(),
    integrationId: PerkIntegrationIdSchema.optional().nullable(),
    raffle: z
      .object({
        numWinners: z.number().int().min(1),
      })
      .optional()
      .nullable(),
    privateContent: LocalizedStringSchema,
    privateContentFileKey: z.any().refine(fileValidatorOptional, 'errorRequired'),
    privateContentFileType: PrivateContentPerkContentTypeSchema.optional().nullable(),
    privateContentThumbnailFileKey: z
      .string()
      .optional()
      .transform(value => value ?? null),
    withMaxAccessesPerUser: z.boolean(),
    maxAccessesPerUser: z.number().nullable(),
    metadata: z
      .array(
        z.object({
          key: z.string(),
          value: z.string(),
        })
      )
      .transform(metadata =>
        metadata.filter(
          item => !isNil(item.key) && !isNil(item.value) && item.key !== '' && item.value !== ''
        )
      )
      .refine(metadata => {
        const keys = metadata.map(data => data.key);

        return new Set(keys).size === keys.length;
      }, 'errorDuplicateKeys'),
    maxUsagesPerToken: PerkMaxUsagesPerTokenASchema.optional().nullable(),
    defaultLanguage: LanguageSchema,
    selectedLanguage: LanguageSchema,
    definedLanguages: z.array(LanguageSchema),
  })
  .superRefine(checkDisplayNameDefaultLanguage)
  .superRefine(checkFormDescriptionDefaultLanguage)
  .transform(data => {
    data.displayName = removeUndefinedLanguages(data.displayName, data.definedLanguages);
    data.description = removeUndefinedLanguages(data.description, data.definedLanguages);
    data.privateContent = removeUndefinedLanguages(data.privateContent, data.definedLanguages);
    if (data.integrationId === 'cohort.form' && data.integration?.config) {
      const config = data.integration.config as CohortFormConfig;
      config.description = removeUndefinedLanguages(config.description, data.definedLanguages);
      config.questions = removeLanguagesFromQuestions(config.questions, data.definedLanguages);

      data.integration.config = config;
    }
    data.maxAccessesPerUser = data.withMaxAccessesPerUser ? data.maxAccessesPerUser : null;
    return data;
  });
export type PerkFormValues = z.infer<typeof PerkFormSchema>;

export type PerkAppDataFieldsList = Record<PerkIntegrationId, JSX.Element>;

type PerkAssetsUpload = Array<{
  name: 'bannerFileKey' | 'videoFileKey' | 'privateContentFileKey';
  type: AssetKind;
}>;

export async function formatAndUpload<T extends PerkFormValues>(
  data: T,
  perk: PerkADto,
  uploadMutation: UseMutateAsyncFunction<
    AssetADto,
    unknown,
    {file: File; assetKind: AssetKind},
    unknown
  >
): Promise<T | null> {
  const assets: PerkAssetsUpload = [
    {
      name: 'bannerFileKey',
      type: 'perkBanner',
    },
    {
      name: 'videoFileKey',
      type: 'perkVideo',
    },
    {
      name: 'privateContentFileKey',
      type: 'privateContent',
    },
  ];
  // restore videoFileKey to its original value if the user didn't change it
  if (data.videoFileKey && !isFile(data.videoFileKey)) {
    data.videoFileKey = perk.videoFileKey;
  }

  // restore privateContentFileKey to its original value if the user didn't change it
  if (data.privateContentFileKey && !isFile(data.privateContentFileKey)) {
    data.privateContentFileKey = perk.privateContentFileKey;
  }

  // set privateContentFileType to null privateContentFileKey is null
  if (data.privateContentFileKey === null) {
    data.privateContentFileType = null;
  }

  // remove raffle if perk is already published
  if (perk.status !== 'draft') {
    delete data.raffle;
  }

  for (const {name, type} of assets) {
    const fieldValue = get(data, name);

    if (fieldValue && isFile(fieldValue)) {
      const file = await uploadMutation({file: fieldValue, assetKind: type});

      set(data, name, file.fileKey);
      if (name === 'videoFileKey') {
        data.thumbnailFileKey = file.previewFileKey;
      }

      if (name === 'privateContentFileKey') {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const contentType = contentTypeFromMimeType(file!.mimeType);

        set(data, 'privateContentFileType', contentType);
        set(
          data,
          'privateContentThumbnailFileKey',
          contentType === 'video' ? file.previewFileKey : null
        );
      }
    }
  }

  return data;
}

export function getPerkIntegrationDefaultValues(perk: PerkADto): PerkIntegration | null {
  if (perk.integration === null) {
    return null;
  }

  if (perk.integration.perkIntegrationId === 'cohort.qr-code') {
    return {
      perkIntegrationId: 'cohort.qr-code',
      connectionId: null,
      config: {
        validity:
          perk.maxUsagesPerToken === null
            ? 'unlimited'
            : perk.maxUsagesPerToken === 1
              ? 'once'
              : 'custom',
      },
    };
  }

  // This is dirty but this will go away once we split the form in multiple steps
  if (perk.integration.perkIntegrationId === 'discord.private-access') {
    return {
      perkIntegrationId: 'discord.private-access',
      connectionId: perk.integration.connectionId as string,
      config: {},
    };
  }
  return perk.integration as PerkIntegration;
}

export function validateIntegrationForm<T extends PerkFormValues>(
  data: T,
  clearErrors: UseFormClearErrors<T>,
  setError: UseFormSetError<T>
): boolean {
  let schema: z.ZodSchema;
  switch (data.integrationId) {
    case undefined:
    case null:
      return true;
    case 'cohort.form':
      schema = FormPerkSchema;
      break;
    case 'cohort.qr-code':
      schema = QRCodePerkSchema;
      break;
    case 'discord.private-access':
      schema = DiscordPerkSchema;
      break;
    case 'instagram.close-friends-list':
      schema = InstagramPerkSchema;
      break;
    case 'shopify.discount':
      schema = ShopifyPerkSchema;
      break;
    case 'talon-one.coupon':
      schema = TalonOneCouponPerkSchema;
      break;
    case 'typeform.form':
      schema = TypeformPerkSchema;
      break;
  }
  const result = schema.safeParse(data.integration);
  if (!result.success) {
    handleFormErrors(result.error, clearErrors, setError, 'integration.');
    return false;
  }
  return true;
}
