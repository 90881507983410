import AdminPage from '@cohort/merchants/components/AdminPage';
import Filters from '@cohort/merchants/components/filters/Filters';
import KPICard from '@cohort/merchants/components/KPICard';
import {usePerkAccesses} from '@cohort/merchants/hooks/api/PerkAccesses';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCurrentUser} from '@cohort/merchants/hooks/contexts/currentUser';
import {usePerkAccessesFilters} from '@cohort/merchants/hooks/filters/perkAccessesFilters';
import UserPerksTable from '@cohort/merchants/pages/users/user/perks/UserPerksTable';
import type {PerkAccessStatus} from '@cohort/shared/schema/common/perkAccesses';
import {PerkAccessStatusSchema} from '@cohort/shared/schema/common/perkAccesses';
import {Gift, RocketLaunch} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

const PAGE_SIZE = 20;

const UserPerksPage = (): JSX.Element => {
  const user = useCurrentUser();
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {keyPrefix: 'users.user.perks.page'});

  const filters = usePerkAccessesFilters(/^(\/test)?\/users\/.*\/perks$/u, '-createdAt');

  const {data, isLoading} = usePerkAccesses(merchant.id, {
    userId: [user.id],
    page: filters.page,
    pageSize: PAGE_SIZE,
    status: filters.status,
    orderBy: filters.sort,
  });

  const [pagination, perkAccesses] = data ?? [];

  // i18nOwl-ignore [status.used, status.unused]
  const statusFilterOptions = PerkAccessStatusSchema.options.map(status => ({
    value: status,
    label: t(`status.${status}`),
    active: Array.isArray(filters.status)
      ? filters.status.some(s => s === status)
      : filters.status === status,
  }));

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
    topRightElements: [
      <KPICard
        icon={<Gift className="mr-1.5 h-4 w-4 text-slate-500" />}
        text={t('labelAvailablePerks')}
        value={user.perksCount}
        key="availablePerks"
      />,
      <KPICard
        icon={<RocketLaunch className="mr-1.5 h-4 w-4 text-slate-500" />}
        text={t('labelUsedPerks')}
        value={user.perkUsagesCount}
        key="usedPerks"
      />,
    ],
  };

  const paginationConfig = {
    pagination,
    onPageChange: filters.setPage,
  };

  return (
    <AdminPage header={headerConfig} pagination={paginationConfig}>
      <Filters
        filters={[
          {
            label: t('filterByStatus'),
            options: statusFilterOptions,
            type: 'multiple',
            onChange: statusOptions => {
              const activeFilterOptions = statusOptions.filter(o => o.active);
              filters.setStatus(activeFilterOptions.map(o => o.value) as PerkAccessStatus[]);
            },
          },
        ]}
      />
      <UserPerksTable isLoading={isLoading} filters={filters} perkAccesses={perkAccesses ?? []} />
    </AdminPage>
  );
};
export default UserPerksPage;
