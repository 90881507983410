import type {PerkAnalyticsADto} from '@cohort/admin-schemas/perk';
import {usePerk} from '@cohort/merchants/hooks/api/Perks';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {createContext, Fragment} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

type CurrentPerkProviderProps = {
  children: React.ReactNode;
};

export const CurrentPerkContext = createContext<PerkAnalyticsADto | null>(null);

export const CurrentPerkProvider: React.FC<CurrentPerkProviderProps> = ({children}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {perkId} = useParams();
  const navigate = useNavigate();

  const {data: perk, isFetched} = usePerk(
    merchantId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    perkId!,
    {enabled: Boolean(perkId), refetchOnWindowFocus: false}
  );

  if (perkId === undefined) {
    navigate('/not-found', {replace: true});
  }

  if (isFetched && !perk) {
    navigate('/not-found', {replace: true});
  }

  // Maybe one day we could create Skeletons for page loading
  if (perk === undefined) {
    return <Fragment />;
  }

  return <CurrentPerkContext.Provider value={perk}>{children}</CurrentPerkContext.Provider>;
};
