import type {CohortADto} from '@cohort/admin-schemas/cohort';
import type {ImportUsersADto} from '@cohort/admin-schemas/userAnalytics';
import type {UserPropertyADto} from '@cohort/admin-schemas/userProperty';
import Button from '@cohort/merchants/components/buttons/Button';
import CompletionBreadcrumb from '@cohort/merchants/components/form/CompletionBreadcrumb';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@cohort/merchants/components/modals/Dialog';
import FieldMappingStep from '@cohort/merchants/components/modals/ImportUsersModal/FieldMappingStep';
import UploadStep from '@cohort/merchants/components/modals/ImportUsersModal/UploadStep';
import {prepareImportUsersPayload} from '@cohort/merchants/components/modals/ImportUsersModal/utils';
import Separator from '@cohort/merchants/components/Separator';
import {cohortsKeys} from '@cohort/merchants/hooks/api/Cohorts';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {usersKeys} from '@cohort/merchants/hooks/api/Users';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {importUsers} from '@cohort/merchants/lib/api/Users';
import {useQueryClient} from '@tanstack/react-query';
import {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

type ImportUsersStep = 'upload' | 'fieldMapping';
type ImportUsersModalProps = {
  onClose: () => void;
  cohort?: CohortADto;
};
const ImportUsersModal: React.FC<ImportUsersModalProps> = ({onClose, cohort}) => {
  const {t} = useTranslation('components', {keyPrefix: 'modals.importUsersModal'});
  const [importUsersStep, setImportUsersStep] = useState<ImportUsersStep>('upload');
  const [csvHeaders, setCsvHeaders] = useState<string[] | undefined>(undefined);
  const [csvUsers, setCsvUsers] = useState<Record<string, string | undefined>[] | undefined>(
    undefined
  );
  const [mappedProperties, setMappedProperties] = useState<
    Array<UserPropertyADto | undefined> | undefined
  >(undefined);
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();

  const isUploadStep = importUsersStep === 'upload';
  const csvDataReady = csvHeaders !== undefined && csvUsers !== undefined;

  const {mutate: addMembers, isLoading} = useCohortMutation({
    mutationFn: async (data: ImportUsersADto) =>
      importUsers(merchant.id, {...data, cohortId: cohort?.id}),
    notifyErrorMessage: t('notificationImportUsersError'),
    notifySuccessMessage: t('notificationImportUsersSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(usersKeys.users);
      if (cohort !== undefined) {
        queryClient.invalidateQueries(cohortsKeys.getById(merchant.id, cohort.id));
      }
      onClose();
    },
  });

  const onUploadStepSubmit = (): void => {
    setImportUsersStep('fieldMapping');
  };
  const onFieldMappingStepSubmit = (): void => {
    if (!csvDataReady) return;

    const importUsersData = prepareImportUsersPayload(csvUsers, csvHeaders, mappedProperties);
    if (importUsersData !== undefined) {
      addMembers({users: importUsersData});
    }
  };
  const submitButtonOnClick = isUploadStep ? onUploadStepSubmit : onFieldMappingStepSubmit;

  const submitButtonText = isUploadStep ? t('buttonNext') : t('buttonImport');
  const submitButtonDisabled = isUploadStep && (csvUsers === undefined || csvUsers.length === 0);

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="[height:var(--large-modal-height)] [width:var(--large-modal-width)]">
        <DialogHeader>
          <DialogTitle className="pb-4 text-lg font-semibold">{t('importUsers')}</DialogTitle>
          <Separator />
        </DialogHeader>
        <DialogBody>
          <div className="flex h-full flex-row">
            <div className="border-r pr-8">
              <CompletionBreadcrumb
                hideCompletionProgress
                steps={[
                  {
                    id: 'upload',
                    label: t('steps.upload'),
                    visited: true,
                    isCurrent: isUploadStep,
                    isCompleted: (csvUsers?.length ?? 0) > 0 && importUsersStep === 'fieldMapping',
                    isDirty: false,
                    countsForCompletion: false,
                    isDisabled: isLoading,
                    onClick: () => {
                      setImportUsersStep('upload');
                    },
                  },
                  {
                    id: 'fieldMapping',
                    label: t('steps.fieldMapping'),
                    visited: false,
                    isCurrent: importUsersStep === 'fieldMapping',
                    isCompleted: false,
                    isDirty: false,
                    countsForCompletion: false,
                    onClick: () => setImportUsersStep('fieldMapping'),
                  },
                ]}
              />
            </div>
            <div className="flex flex-1 flex-col pl-6">
              {isUploadStep || !csvDataReady ? (
                <UploadStep setCsvHeaders={setCsvHeaders} setCsvUsers={setCsvUsers} />
              ) : (
                <FieldMappingStep
                  csvHeaders={csvHeaders}
                  csvUsers={csvUsers}
                  setMappedProperties={setMappedProperties}
                  mappedProperties={mappedProperties ?? csvHeaders.map(() => undefined)}
                  isLoading={isLoading}
                />
              )}
              <div className="flex flex-row items-center justify-end pt-4">
                {isUploadStep && csvDataReady && (
                  <p className="flex-1 text-sm text-muted-foreground">
                    <Trans
                      i18nKey="modals.importUsersModal.usersCount"
                      ns="components"
                      values={{count: csvUsers.length}}
                      components={{
                        count: <span className="font-medium text-slate-700" />,
                      }}
                    />
                  </p>
                )}
                {!isUploadStep && (
                  <p className="flex-1 text-sm text-muted-foreground">
                    <Trans
                      i18nKey="modals.importUsersModal.mappedPropertiesCount"
                      ns="components"
                      values={{
                        count:
                          (mappedProperties?.filter(attribute => attribute?.id !== undefined)
                            .length ?? 0) + 1, // +1 for email
                        total: csvHeaders?.length ?? 0,
                      }}
                      components={{
                        count: <span className="font-medium text-slate-700" />,
                      }}
                    />
                  </p>
                )}
                <Button onClick={onClose} variant="secondary" data-testid="crop-cancel">
                  {t('buttonCancel')}
                </Button>
                <Button
                  onClick={submitButtonOnClick}
                  className="ml-3"
                  disabled={submitButtonDisabled}
                  loading={isLoading}
                >
                  {submitButtonText}
                </Button>
              </div>
            </div>
          </div>
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};

export default ImportUsersModal;
