import {useUserEventApps} from '@cohort/merchants/apps/useUserEventApps';
import Button from '@cohort/merchants/components/buttons/Button';
import Input from '@cohort/merchants/components/form/input/Input';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import {useOperators} from '@cohort/merchants/hooks/rules/operators';
import {useUserEventPropertiesTranslations} from '@cohort/merchants/hooks/rules/userEventProperties';
import CriteriaInputValue from '@cohort/merchants/pages/users/cohort/rule/criteria/CriteriaInputValue';
import ResourceSelectInput from '@cohort/merchants/pages/users/cohort/rule/criteria/ResourceSelectInput';
import type {DynamicCohortRuleType} from '@cohort/merchants/pages/users/cohort/rule/utils';
import type {EventId} from '@cohort/shared/apps';
import {getRulesEngineUserEventIds, getUserEventSpec} from '@cohort/shared/apps';
import type {OperatorId} from '@cohort/shared/schema/common/rules/operators';
import {getOperatorById} from '@cohort/shared/schema/common/rules/operators';
import {getUserEventPropertyOptions} from '@cohort/shared/schema/common/rules/userEvents';
import {Funnel, X} from '@phosphor-icons/react';
import {Fragment, useEffect, useRef} from 'react';
import type {Control, Path, UseFormRegister} from 'react-hook-form';
import {useController, useFieldArray} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type UserEventResourceInputProps = {
  name: Path<DynamicCohortRuleType>;
  control: Control<DynamicCohortRuleType>;
  propertyName: string;
  userEventId: EventId;
};

const UserEventResourceInput: React.FC<UserEventResourceInputProps> = ({
  name,
  control,
  propertyName,
  userEventId,
}) => {
  const userEventSpec = getUserEventSpec(userEventId);
  const resourceConfig = userEventSpec.resources[propertyName];

  if (resourceConfig === undefined) {
    return null;
  }
  return <ResourceSelectInput name={name} control={control} resourceType={resourceConfig} />;
};

type UserEventCriteriaProps = {
  control: Control<DynamicCohortRuleType>;
  register: UseFormRegister<DynamicCohortRuleType>;
  groupIndex: number;
  criteriaIndex: number;
};

type UserEventCriteriaFilterProps = UserEventCriteriaProps & {
  userEventId: EventId;
  list: {
    filterIndex: number;
    onRemove: () => void;
  };
};

const UserEventCriteriaFilter: React.FC<UserEventCriteriaFilterProps> = ({
  control,
  register,
  groupIndex,
  criteriaIndex,
  list,
  userEventId,
}) => {
  const previousUserEventId = useRef(userEventId);
  const {filterIndex, onRemove} = list;
  const {t} = useTranslation('pages', {
    keyPrefix: 'users.cohort.rule.criteria.userEventCriteria',
  });
  const {field: propertyName} = useController({
    name: `groups.${groupIndex}.criteria.${criteriaIndex}.filters.${filterIndex}.propertyName`,
    control,
  });
  const {field: operator} = useController({
    name: `groups.${groupIndex}.criteria.${criteriaIndex}.filters.${filterIndex}.operator`,
    control,
  });
  const {field: valueType} = useController({
    name: `groups.${groupIndex}.criteria.${criteriaIndex}.filters.${filterIndex}.valueType`,
    control,
  });
  const {field: value} = useController({
    name: `groups.${groupIndex}.criteria.${criteriaIndex}.filters.${filterIndex}.value`,
    control,
  });

  const filterOperators = useOperators('user-event-filter');
  const translateUserEventProperty = useUserEventPropertiesTranslations();
  const userEventProperties = getUserEventPropertyOptions(userEventId);
  const userEventPropertiesOptions = userEventProperties.map(eventProperty => ({
    value: eventProperty.name,
    label: translateUserEventProperty(eventProperty.name),
  }));
  const selectedEventProperty = userEventProperties.find(prop => prop.name === propertyName.value);
  const valueDataType = operator.value
    ? getOperatorById(operator.value as OperatorId).valueDataType
    : undefined;

  // Reset the filter values when the user event changes
  useEffect(() => {
    if (previousUserEventId.current !== userEventId) {
      propertyName.onChange(null);
      operator.onChange(null);
      valueType.onChange(null);
      value.onChange(null);
      previousUserEventId.current = userEventId;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEventId]);

  return (
    <li className="grid items-start gap-4 pl-8 [grid-template-columns:320px_300px_1fr_min-content]">
      <div className="flex items-start gap-4">
        <p className="mt-2 font-semibold">{filterIndex > 0 ? t('and') : t('where')}</p>
        <SelectInput
          name={`groups.${groupIndex}.criteria.${criteriaIndex}.filters.${filterIndex}.propertyName`}
          placeholder={t('filterPropertyPlaceholder')}
          register={register}
          control={control}
          options={userEventPropertiesOptions}
          onChange={() => {
            operator.onChange(null);
            value.onChange(null);
          }}
        />
      </div>
      {selectedEventProperty && (
        <SelectInput
          name={`groups.${groupIndex}.criteria.${criteriaIndex}.filters.${filterIndex}.operator`}
          placeholder={t('operatorPlaceholder')}
          register={register}
          control={control}
          onChange={newOperator => {
            const newValueDataType =
              newOperator && newOperator.value
                ? getOperatorById(newOperator.value as OperatorId).valueDataType
                : undefined;

            if (newValueDataType !== valueDataType) {
              valueType.onChange(newValueDataType);
              value.onChange(null);
            }
          }}
          options={filterOperators.filter(op => op.type === selectedEventProperty.dataType)}
        />
      )}
      {valueDataType && (
        <Fragment>
          {valueDataType === 'resource' || valueDataType === 'resource_list' ? (
            <UserEventResourceInput
              name={`groups.${groupIndex}.criteria.${criteriaIndex}.filters.${filterIndex}.value`}
              userEventId={userEventId}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              propertyName={propertyName.value!}
              control={control}
            />
          ) : (
            <CriteriaInputValue
              operator={operator.value as OperatorId}
              dataType={valueDataType}
              name={`groups.${groupIndex}.criteria.${criteriaIndex}.filters.${filterIndex}.value`}
              register={register}
              control={control}
            />
          )}
        </Fragment>
      )}
      <button className="mt-3" onClick={onRemove}>
        <X size={16} className="text-slate-400" />
      </button>
    </li>
  );
};

const UserEventCriteria: React.FC<UserEventCriteriaProps> = ({
  control,
  register,
  groupIndex,
  criteriaIndex,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'users.cohort.rule.criteria.userEventCriteria',
  });
  const {fields, append, remove} = useFieldArray({
    name: `groups.${groupIndex}.criteria.${criteriaIndex}.filters`,
    control,
    keyName: '_id',
  });
  const operators = useOperators('user-event');
  const {field: userEventId} = useController({
    name: `groups.${groupIndex}.criteria.${criteriaIndex}.userEventId`,
    control,
  });
  const {getUserEventTitle} = useUserEventApps();

  return (
    <ul className="space-y-4">
      <li className="grid gap-4 [grid-template-columns:350px_1fr]">
        <div className="flex items-start gap-4">
          <p className="mt-2 font-semibold">{t('has')}</p>
          <SelectInput
            name={`groups.${groupIndex}.criteria.${criteriaIndex}.userEventId`}
            placeholder={t('userEventPlaceholder')}
            register={register}
            control={control}
            options={getRulesEngineUserEventIds().map(eventId => ({
              value: eventId,
              label: getUserEventTitle(eventId),
            }))}
          />
        </div>
        <div className="flex items-start gap-4">
          <div className="flex w-[300px] min-w-[300px] items-start gap-4">
            <p className="mt-2 font-semibold">{t('occurrences')}</p>
            <SelectInput
              name={`groups.${groupIndex}.criteria.${criteriaIndex}.operator`}
              placeholder={t('operatorPlaceholder')}
              register={register}
              control={control}
              options={operators}
            />
          </div>
          <Input
            type="number"
            name={`groups.${groupIndex}.criteria.${criteriaIndex}.count`}
            placeholder={t('countPlaceholder')}
            register={register}
            control={control}
          />
        </div>
      </li>
      {userEventId.value && (
        <Fragment>
          {fields.map((filter, filterIndex) => (
            <UserEventCriteriaFilter
              key={filter._id}
              control={control}
              register={register}
              groupIndex={groupIndex}
              criteriaIndex={criteriaIndex}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              userEventId={userEventId.value!}
              list={{filterIndex, onRemove: () => remove(filterIndex)}}
            />
          ))}
          <li>
            <Button
              variant="ghost"
              onClick={() =>
                append({
                  id: null,
                  propertyName: undefined,
                  operator: undefined,
                  value: undefined,
                  valueType: undefined,
                })
              }
            >
              <Funnel size={20} className="mr-2" />
              {t('addFilterBtn')}
            </Button>
          </li>
        </Fragment>
      )}
    </ul>
  );
};

export default UserEventCriteria;
