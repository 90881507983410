import type {CreateApiKeyResponseADto} from '@cohort/admin-schemas/apiKeys';
import Button from '@cohort/merchants/components/buttons/Button';
import {
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '@cohort/merchants/components/modals/Dialog';
import {Check} from '@phosphor-icons/react';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';

type ShowCreatedKeyProps = {
  apiKey: CreateApiKeyResponseADto;
  onClose: () => void;
};

const ShowCreatedKey: React.FC<ShowCreatedKeyProps> = ({apiKey, onClose}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'settings.integrations.createApiKeyModal'});
  const [copied, setCopied] = useState(false);

  return (
    <DialogBody>
      <DialogTitle className="flex flex-col items-center gap-4 text-center text-lg font-bold leading-6">
        <div className="rounded-full bg-green-100 p-2 text-green-600">
          <Check className="h-8 w-8" />
        </div>
        {t('createdKeyTitle')}
      </DialogTitle>
      <DialogDescription className="mt-2 text-center text-sm">
        {t('createdKeySubtitle')}
      </DialogDescription>
      <div className="my-8 flex w-full flex-row items-center justify-between gap-4 break-all rounded-md border-2 border-dashed p-4">
        <p className="text-sm">{apiKey.plaintextKey}</p>
        <Button
          variant="secondary"
          onClick={async () => {
            await navigator.clipboard.writeText(apiKey.plaintextKey);
            setCopied(true);
          }}
        >
          {copied ? (
            <Fragment>
              <Check className="mr-2 h-4 w-4 text-green-600" />
              {t('createdKeyCopyBtnCopied')}
            </Fragment>
          ) : (
            t('createdKeyCopyBtn')
          )}
        </Button>
      </div>
      <Button className="w-full" onClick={() => onClose()}>
        {t('createdKeyBtn')}
      </Button>
    </DialogBody>
  );
};

export default ShowCreatedKey;
