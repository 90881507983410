import type {AirdropActivityADto} from '@cohort/admin-schemas/airdropActivities';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {formatDate} from '@cohort/shared/utils/format';
import {createColumnHelper} from '@tanstack/react-table';
import React from 'react';
import {useTranslation} from 'react-i18next';

type AirdropActivitiesTableProps = {
  airdropActivities: Array<AirdropActivityADto>;
  isLoading: boolean;
};

const AirdropActivitiesTable: React.FC<AirdropActivitiesTableProps> = ({
  airdropActivities,
  isLoading,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.activity.airdrop.airdropActivitiesTable',
  });
  const columnHelper = createColumnHelper<AirdropActivityADto>();
  const columns = [
    columnHelper.accessor('user.email', {
      header: t('tableHeaderInternalName'),
      cell: data => <span className="font-medium text-foreground">{data.getValue()}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor(row => formatDate(row.createdAt), {
      id: 'createdAt',
      header: t('tableHeaderDate'),
      enableSorting: false,
    }),
  ];
  const table = useCohortTable({
    data: airdropActivities,
    columns,
  });

  return (
    <DataTable
      table={table}
      columnsLength={columns.length}
      emptyStatePlaceholder={t('emptyPlaceholder')}
      isLoading={isLoading}
    />
  );
};

export default AirdropActivitiesTable;
