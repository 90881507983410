import type {PatchMerchantADto} from '@cohort/admin-schemas/merchant';
import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import FileInput from '@cohort/merchants/components/form/FileInput';
import LocalizedInput from '@cohort/merchants/components/form/input/LocalizedInput';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import HighlightText from '@cohort/merchants/components/HighlightText';
import Loader from '@cohort/merchants/components/Loader';
import {merchantsKeys, useMerchant} from '@cohort/merchants/hooks/api/Merchants';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useMembershipPassConfigurationPageStore} from '@cohort/merchants/hooks/stores/membershipPassConfigurationPage';
import {notify} from '@cohort/merchants/hooks/toast';
import {patchMerchant} from '@cohort/merchants/lib/api/Merchants';
import {
  changeSelectedLanguageIfNeeded,
  getDefinedLanguages,
  removeUndefinedLanguages,
} from '@cohort/merchants/lib/form/localization';
import {uploadAsset} from '@cohort/merchants/lib/form/utils';
import {fileValidatorOptional, hexColorValidator} from '@cohort/merchants/lib/form/validators';
import {trackMembershipPassConfigurationSettingsSaveButtonClicked} from '@cohort/merchants/lib/Tracking';
import MembershipPassPreviewAside from '@cohort/merchants/pages/settings/membership-pass-configuration/MembershipPassPreviewAside';
import {notifyError} from '@cohort/merchants/stores/ErrorModalStore';
import type {Language} from '@cohort/shared/schema/common';
import {
  DEFAULT_ACCENT_COLOR,
  LanguageSchema,
  LocalizedStringSchema,
} from '@cohort/shared/schema/common';
import type {AssetKind} from '@cohort/shared/schema/common/assets';
import {AllowedAssetMimeTypes} from '@cohort/shared/schema/common/assets';
import {ASSETS_MIN_DIMENSIONS} from '@cohort/shared/utils/fileUploads';
import {isFile} from '@cohort/shared-frontend/utils/isFile';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {z} from 'zod';

const backgroundColorOptions = ['experienceSpaceColors', 'customColors'] as const;
export type BackgroundColorOptionKey = (typeof backgroundColorOptions)[number];

const MembershipPassSettingsFormSchema = z
  .object({
    title: LocalizedStringSchema,
    bannerFileKey: z.any().refine(fileValidatorOptional),
    backgroundColorOption: z.enum(backgroundColorOptions),
    backgroundColorCode: z
      .string()
      .refine(hexColorValidator, 'errorHexColor')
      .nullable()
      .optional(),
    defaultLanguage: LanguageSchema,
    selectedLanguage: LanguageSchema,
    definedLanguages: z.array(LanguageSchema),
    nftEnabled: z.boolean(),
  })
  .transform(data => {
    data.title = removeUndefinedLanguages(data.title, data.definedLanguages);
    return data;
  });
export type MembershipPassSettingsFormType = z.infer<typeof MembershipPassSettingsFormSchema>;

const MembershipPassConfigurationSettingsPage = (): JSX.Element => {
  const {t} = useTranslation('pages', {keyPrefix: 'settings.membershipPass.page'});
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();
  const setMembershipPassPreview = useMembershipPassConfigurationPageStore(
    store => store.setmembershipPassConfigurationPagePreview
  );
  const [needPolling, setNeedPolling] = useState(false);
  const {data} = useMerchant(merchant.id, {autoRefresh: needPolling});
  const {membershipPassConfiguration} = merchant;

  useEffect(() => {
    if (
      data &&
      data.membershipPassConfiguration.nftEnabled &&
      data.membershipPassConfiguration.smartContractAddress === null
    ) {
      setNeedPolling(true);
    } else if (needPolling) {
      setNeedPolling(false);
    }
  }, [data, needPolling]);

  const computeDefaultBackgroundColorOption = (): BackgroundColorOptionKey => {
    if (
      membershipPassConfiguration.backgroundColorCode === null ||
      membershipPassConfiguration.backgroundColorCode === merchant.accentColorCode
    ) {
      return 'experienceSpaceColors';
    }
    return 'customColors';
  };

  const computeDefaultBackgroundColorCode = (): string => {
    if (computeDefaultBackgroundColorOption() === 'experienceSpaceColors') {
      return merchant.accentColorCode ?? DEFAULT_ACCENT_COLOR;
    }
    return membershipPassConfiguration.backgroundColorCode ?? DEFAULT_ACCENT_COLOR;
  };

  const {register, control, handleSubmit, formState, reset, watch, setValue} =
    useForm<MembershipPassSettingsFormType>({
      defaultValues: {
        title: membershipPassConfiguration.title,
        bannerFileKey: membershipPassConfiguration.bannerFileKey,
        backgroundColorOption: computeDefaultBackgroundColorOption(),
        backgroundColorCode: computeDefaultBackgroundColorCode(),
        defaultLanguage: merchant.defaultLanguage,
        selectedLanguage: merchant.defaultLanguage,
        definedLanguages: getDefinedLanguages(merchant.defaultLanguage, [
          membershipPassConfiguration.title,
        ]),
        nftEnabled: membershipPassConfiguration.nftEnabled,
      },
      resolver: zodResolver(MembershipPassSettingsFormSchema),
    });

  const [selectedLanguage] = watch(['selectedLanguage']);

  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  const {isLoading: isUploading, mutateAsync: uploadFile} = useCohortMutation({
    mutationFn: async ({file, assetKind}: {file: File; assetKind: AssetKind}) =>
      uploadAsset(file, assetKind, merchant.id),
    onError: err => notifyError(err, t('errorFileUploadFailed')),
  });

  const {isLoading, mutate: updateMerchant} = useCohortMutation({
    mutationFn: async (data: PatchMerchantADto) => patchMerchant(merchant.id, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries(merchantsKeys.getById(merchant.id));
      notify('success', t('notificationUpdateSuccess'));
      trackMembershipPassConfigurationSettingsSaveButtonClicked();
      reset({}, {keepValues: true});
    },
  });

  useEffect(() => {
    setMembershipPassPreview(<MembershipPassPreviewAside control={control} merchant={merchant} />);
    return () => setMembershipPassPreview(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
  };

  const {nftEnabled, blockExplorerUrl, smartContractAddress} =
    data?.membershipPassConfiguration ?? {};

  return (
    <form
      onSubmit={handleSubmit(
        async data => {
          const patchData: PatchMerchantADto = {
            membershipPassConfiguration: data,
          };
          if (isFile(data.bannerFileKey)) {
            const asset = await uploadFile({
              file: data.bannerFileKey as unknown as File,
              assetKind: 'logo',
            });
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (asset === null) {
              return notifyError(t('errorFileUploadFailed'));
            }
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            patchData.membershipPassConfiguration!.bannerFileKey = asset.fileKey!;
          }
          updateMerchant(patchData);
        },
        errors =>
          changeSelectedLanguageIfNeeded(errors, merchant.defaultLanguage, (language: Language) =>
            setValue('selectedLanguage', language)
          )
      )}
    >
      <AdminPage header={headerConfig}>
        <LanguageSelectorInput
          control={control}
          definedLanguagesPath="definedLanguages"
          selectedLanguagePath="selectedLanguage"
        />
        <LocalizedInput
          type="text"
          label={t('labelTitle')}
          name="title"
          register={register}
          control={control}
          placeholder={t('defaultTitle', {merchantName: merchant.name})}
          selectedLanguage={selectedLanguage}
          optional
        />
        {isDefaultLanguageSelected && (
          <Fragment>
            <FileInput
              label={t('labelVisual')}
              name="bannerFileKey"
              register={register}
              control={control}
              acceptHint={t('instructionVisual')}
              accept={AllowedAssetMimeTypes.membershipPassBanner.options.join(',')}
              assetKind="membershipPassBanner"
              description={t('helperVisual', {
                width: ASSETS_MIN_DIMENSIONS['membershipPassBanner'].width,
                height: ASSETS_MIN_DIMENSIONS['membershipPassBanner'].height,
              })}
              withResize
            />
            <div>
              <div className="flex flex-row space-x-6">
                <div className="flex-grow">
                  <span className="block text-sm font-medium text-slate-700">
                    {t('labelNftEnabled')}
                  </span>

                  {!nftEnabled && (
                    <div className="mt-1 flex">
                      <span className="mr-1 text-slate-500">
                        <Trans
                          i18nKey="settings.membershipPass.page.nftDisabledDescription"
                          ns="pages"
                          components={{
                            pageLink: (
                              <a
                                className="font-medium text-primary"
                                target="_blank"
                                rel="noopener noreferrer nofollow"
                                href="https://opensea.io/learn/nft/what-are-soulbound-tokens"
                              />
                            ),
                          }}
                        />
                      </span>
                    </div>
                  )}
                  {data !== undefined && (nftEnabled || blockExplorerUrl !== null) && (
                    <div className="mt-1 flex">
                      <span className="mr-1 text-slate-500">{t('nftEnabledDescription')}</span>
                      {blockExplorerUrl !== null && smartContractAddress !== null ? (
                        <a
                          target="_blank"
                          href={blockExplorerUrl}
                          className="font-medium text-primary"
                        >
                          {smartContractAddress?.slice(0, 10)}...{smartContractAddress?.slice(-12)}
                        </a>
                      ) : (
                        <Loader size={20} color="gray" />
                      )}
                    </div>
                  )}
                </div>
                <SwitchInput
                  id="nftEnabled"
                  name="nftEnabled"
                  className="w-auto justify-center"
                  register={register}
                  control={control}
                />
              </div>

              {nftEnabled && (
                <div className="mt-4">
                  <HighlightText
                    type="info"
                    text={
                      <Trans
                        i18nKey="settings.membershipPass.page.nftEnabledHint"
                        ns="pages"
                        components={{
                          pageLink: (
                            <a
                              className="font-medium text-blue-600"
                              target="_blank"
                              rel="noopener noreferrer nofollow"
                              href="https://opensea.io/learn/nft/what-are-soulbound-tokens"
                            />
                          ),
                        }}
                      />
                    }
                  />
                </div>
              )}
            </div>
          </Fragment>
        )}
        <div className="flex justify-end space-x-2">
          <Button
            type="submit"
            loading={isLoading || isUploading}
            disabled={!formState.isDirty}
            data-testid="save"
          >
            {t('buttonSave')}
          </Button>
        </div>
      </AdminPage>
    </form>
  );
};

export default MembershipPassConfigurationSettingsPage;
