import type {TriggerIntegration} from '@cohort/merchants/apps';
import StravaBeActiveTriggerIntegrationConfigComponent from '@cohort/merchants/apps/strava/triggerIntegratons/beActive/ConfigComponent';
import StravaBeActiveTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/strava/triggerIntegratons/beActive/StepCompletionTitleComponent';
import {StravaActivityGroupsSchema} from '@cohort/shared/apps/strava/activity';
import type {
  StravaBeActiveTriggerConfig,
  StravaBeActiveTriggerStruct,
} from '@cohort/shared/apps/strava/triggers/beActive';
import {
  StravaBeActiveTriggerConfigMetricSchema,
  StravaBeActiveTriggerIntegrationSpec,
} from '@cohort/shared/apps/strava/triggers/beActive';
import {startDateBeforeEndDateRefinement} from '@cohort/shared/schema/common/dates';
import {toKilometers, toMeters, toMinutes, toSeconds} from '@cohort/shared/utils/convert';
import {z} from 'zod';

export const StravaBeActiveTriggerFormConfigSchema = z
  .object({
    activityGroups: z
      .array(StravaActivityGroupsSchema)
      .nonempty({message: 'errorNoOptionsSelected'})
      .nullable()
      .optional(),
    hasActivityTypes: z.boolean().optional().default(false),
    endDate: z.date({coerce: true}),
    metric: StravaBeActiveTriggerConfigMetricSchema,
    startDate: z.date({coerce: true}),
    value: z.number().min(0),
  })
  .superRefine(
    startDateBeforeEndDateRefinement({
      startDatePropertyName: 'startDate',
      endDatePropertyName: 'endDate',
    })
  );

export type StravaBeActiveTriggerFormConfig = z.infer<typeof StravaBeActiveTriggerFormConfigSchema>;

function fromForm(formValues: StravaBeActiveTriggerFormConfig): StravaBeActiveTriggerConfig {
  const result: StravaBeActiveTriggerConfig = {
    ...formValues,
    endDateTimestamp: new Date(formValues.endDate).getTime(),
    startDateTimestamp: new Date(formValues.startDate).getTime(),
    activityGroups: formValues.hasActivityTypes ? formValues.activityGroups ?? null : null,
  };

  switch (formValues.metric) {
    case 'distance':
      result.value = toMeters(formValues.value);
      break;
    case 'duration':
      result.value = toSeconds(formValues.value);
      break;
    case 'elevation':
      result.value = formValues.value;
      break;
  }

  return result;
}

function toForm(config: StravaBeActiveTriggerConfig): StravaBeActiveTriggerFormConfig {
  const result: StravaBeActiveTriggerFormConfig = {
    ...config,
    endDate: new Date(config.endDateTimestamp),
    startDate: new Date(config.startDateTimestamp),
    hasActivityTypes: config.activityGroups !== null,
    activityGroups: config.activityGroups,
  };

  switch (config.metric) {
    case 'distance':
      result.value = toKilometers(config.value);
      break;
    case 'duration':
      result.value = toMinutes(config.value);
      break;
    case 'elevation':
      result.value = config.value;
      break;
  }

  return result;
}

export const StravaBeActiveTriggerIntegration: TriggerIntegration<StravaBeActiveTriggerStruct> = {
  spec: StravaBeActiveTriggerIntegrationSpec,
  configComponent: StravaBeActiveTriggerIntegrationConfigComponent,
  configForm: {
    schema: StravaBeActiveTriggerFormConfigSchema,
    toForm,
    fromForm,
  },
  stepCompletionTitleComponent: StravaBeActiveTriggerIntegrationStepCompletionTitleComponent,
  getTitle: t => t('triggerIntegrations.be-active.title', {ns: 'app-strava'}),
};
