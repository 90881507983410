import {useApps} from '@cohort/merchants/apps/useApps';
import {Badge} from '@cohort/merchants/components/Badge';
import AppLogo from '@cohort/merchants/pages/users/overview/appLogo';
import React, {Fragment} from 'react';

type AppBadgeProps = {
  appId: string;
};

const AppBadge: React.FC<AppBadgeProps> = ({appId}) => {
  const apps = useApps();
  const app = apps.getApp(appId);

  if (app === null) {
    return <Fragment />;
  }

  return (
    <Badge
      text={
        <div className="flex items-center gap-2 py-1">
          <AppLogo app={app} />
          {app.spec.name}
        </div>
      }
      backgroundColor="bg-gray-50"
      textColor="text-slate-800"
      borderColor="border-border"
    />
  );
};

export default AppBadge;
