import type {PerkAnalyticsADto} from '@cohort/admin-schemas/perk';
import Button from '@cohort/merchants/components/buttons/Button';
import {PerkAppBadge} from '@cohort/merchants/components/perks/PerkAppBadge';
import PerkRaffleBadge from '@cohort/merchants/components/perks/PerkRaffleBadge';
import {PerkStatusBadge} from '@cohort/merchants/components/perks/PerkStatusBadge';
import {PerkTypeBadge} from '@cohort/merchants/components/perks/PerkTypeBadge';
import {PerkVisual} from '@cohort/merchants/components/PerkVisual';
import {Progress} from '@cohort/merchants/components/Progress';
import {getPerkEditRoute, getPerkRoute} from '@cohort/merchants/lib/Pages';
import {trackPerkListItemClicked} from '@cohort/merchants/lib/Tracking';
import PerkDropdown from '@cohort/merchants/pages/perks/overview/PerkDropdown';
import {formatDate} from '@cohort/shared/utils/format';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

interface PerkListItemProps {
  perk: PerkAnalyticsADto;
}

const PerkListLeftColumn: React.FC<PerkListItemProps> = ({perk}) => (
  <div className="flex w-full items-start justify-between gap-2 truncate md:items-center">
    <div className="flex items-center gap-4 truncate">
      <PerkVisual perk={perk} />
      <div className="truncate">
        <h3 className="truncate text-sm font-medium">{perk.internalName}</h3>
        <div className="mt-1 flex items-center space-x-2">
          <PerkTypeBadge perkType={perk.type} />
          <PerkAppBadge perkIntegrationId={perk.integration?.perkIntegrationId ?? null} />
          {perk.raffle !== null && <PerkRaffleBadge />}
        </div>
      </div>
    </div>
    <div className="block md:hidden">
      <PerkDropdown perk={perk} />
    </div>
  </div>
);

const PerkListMiddleColumn: React.FC<PerkListItemProps> = ({perk}) => (
  <div>
    <PerkStatusBadge status={perk.status} liveCount={perk.campaignsCount} size="small" />
  </div>
);

const PerkListRightColumn: React.FC<PerkListItemProps> = ({perk}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'perks.overview.perkListItem'});

  const usageProgress =
    perk.perkAccessesCount !== 0
      ? (perk.perkAccessesWithUsageCount / perk.perkAccessesCount) * 100
      : 0;

  return (
    <div className="flex w-full items-center justify-end gap-8" data-testid="visible-in-tests">
      {perk.status === 'draft' ? (
        <Button variant="secondary">{t('btnFinishDraft')}</Button>
      ) : (
        <div className="flex w-full flex-col items-end gap-2">
          <div className="flex items-center gap-4">
            <Progress value={usageProgress} className="h-1" />
            <h3 className="whitespace-nowrap text-sm font-medium text-slate-700">
              {perk.perkAccessesWithUsageCount}/{perk.perkAccessesCount} {t('labelUsed')}
            </h3>
          </div>
          <span data-testid="perk-date" className="text-xs text-gray-500">
            {t('labelLastEdit')} {formatDate(perk.updatedAt)}
          </span>
        </div>
      )}
      <div className="hidden md:block">
        <PerkDropdown perk={perk} />
      </div>
    </div>
  );
};

const PerkListItem: React.FC<PerkListItemProps> = ({perk}) => (
  <li key={perk.id} data-testid="perk-list-item" className="border-t-2 border-slate-100">
    <Link
      to={perk.status === 'draft' ? getPerkEditRoute(perk.id).path : getPerkRoute(perk.id).path}
      onClick={() => {
        trackPerkListItemClicked(perk);
      }}
    >
      <div className="grid items-center gap-6 py-4 md:[grid-template-columns:1fr_min-content_280px]">
        <PerkListLeftColumn perk={perk} />
        <PerkListMiddleColumn perk={perk} />
        <PerkListRightColumn perk={perk} />
      </div>
    </Link>
  </li>
);

export default PerkListItem;
