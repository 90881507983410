import type {WrappedField} from '@cohort/merchants/components/form/FieldWrapper';
import type {BaseTextEditorProps} from '@cohort/merchants/components/form/textEditor/BaseTextEditor';
import BaseTextEditor from '@cohort/merchants/components/form/textEditor/BaseTextEditor';
import type {FormField} from '@cohort/merchants/lib/form/utils';
import type {FieldValues} from 'react-hook-form';
import {useController} from 'react-hook-form';

type TextEditorInputProps<T extends FieldValues> = WrappedField &
  FormField<T> &
  Pick<
    BaseTextEditorProps,
    'placeholder' | 'disabled' | 'extensions' | 'editorClassName' | 'suggestions'
  >;

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function TextEditorInput<T extends FieldValues>({
  label,
  description,
  name,
  placeholder,
  disabled,
  control,
  optional,
  extensions,
  editorClassName,
  suggestions,
}: TextEditorInputProps<T>): JSX.Element {
  const {field, fieldState} = useController({
    control,
    name,
  });

  return (
    <BaseTextEditor
      label={label}
      name={name}
      description={description}
      error={fieldState.error?.message}
      onChange={field.onChange}
      onBlur={field.onBlur}
      placeholder={placeholder}
      disabled={disabled}
      value={field.value}
      optional={optional}
      extensions={extensions}
      editorClassName={editorClassName}
      suggestions={suggestions}
    />
  );
}
