import PreviewNavBar from '@cohort/merchants/components/campaigns/preview/PreviewNavbar';
import {SCROLL_SPY_CONTAINER_ID} from '@cohort/merchants/lib/StorePreview';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {useEffect, useRef, useState} from 'react';

type PreviewLayoutProps = {
  isMobile: boolean;
  leftPanelContent: React.ReactElement;
  rightPanelContent: React.ReactElement;
  className?: string;
  inPreviewModal?: boolean;
};

const PreviewLayout: React.FC<PreviewLayoutProps> = ({
  isMobile,
  leftPanelContent,
  rightPanelContent,
  className,
  inPreviewModal = false,
}) => {
  const [navBarHeight, setNavBarHeight] = useState<number>(0);
  const [previewContainerHeight, setPreviewContainerHeight] = useState<number>(0);
  const previewContainerRef = useRef<HTMLDivElement>(null);
  const navBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (previewContainerRef.current) {
      const height = previewContainerRef.current.clientHeight;
      setPreviewContainerHeight(height);
    }
    if (navBarRef.current) {
      const height = navBarRef.current.offsetHeight;
      setNavBarHeight(height);
    }
  }, [setNavBarHeight, setPreviewContainerHeight]);

  const borderLeft = (
    <div className="py-8">
      <div className="h-full w-[1px] bg-[--xps-border-color]" />
    </div>
  );

  return (
    <div
      id={inPreviewModal ? SCROLL_SPY_CONTAINER_ID : undefined}
      ref={previewContainerRef}
      className={cn('flex max-w-[var(--content-max-width)] flex-col rounded-lg border', className)}
    >
      <div id="preview">
        <div ref={navBarRef}>
          <PreviewNavBar />
        </div>
        <div
          className="flex grow"
          style={{
            ...(inPreviewModal && !isMobile
              ? {height: `calc(${previewContainerHeight}px - ${navBarHeight}px)`}
              : {}),
          }}
        >
          <div className="w-[var(--xps-preview-desktop-left-panel-width)]">{leftPanelContent}</div>
          {!isMobile && borderLeft}
          {!isMobile && (
            <div className="no-scrollbar grow overflow-auto p-8">{rightPanelContent}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreviewLayout;
