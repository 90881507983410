import type {
  CreateApiKeyDataADto,
  CreateApiKeyResponseADto,
  UpdateApiKeyDataADto,
  UpdateApiKeyResponseADto,
} from '@cohort/admin-schemas/apiKeys';
import {
  CreateApiKeyResponseASchema,
  UpdateApiKeyResponseASchema,
} from '@cohort/admin-schemas/apiKeys';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';

export async function createApiKey(
  merchantId: string,
  data: CreateApiKeyDataADto
): Promise<CreateApiKeyResponseADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/api-keys`, {
    expect: HttpCodes.CREATED,
    parser: CreateApiKeyResponseASchema.parse,
    body: data,
  });
}

export async function patchApiKey(
  merchantId: string,
  apiKeyId: string,
  data: UpdateApiKeyDataADto
): Promise<UpdateApiKeyResponseADto> {
  return apiCall('PATCH', `/v1/merchants/${merchantId}/api-keys/${apiKeyId}`, {
    expect: HttpCodes.SUCCESS,
    parser: UpdateApiKeyResponseASchema.parse,
    body: data,
  });
}

export async function deleteApiKey(merchantId: string, apiKeyId: string): Promise<void> {
  return apiCall('DELETE', `/v1/merchants/${merchantId}/api-keys/${apiKeyId}`, {
    expect: HttpCodes.SUCCESS,
    parser: () => {},
  });
}
