import HighlightText from '@cohort/merchants/components/HighlightText';
import {bytesToKb, bytesToMb, mbToBytes} from '@cohort/merchants/lib/Utils';
import {File, FileArrowDown, XCircle} from '@phosphor-icons/react';
import Papa from 'papaparse';
import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

type FileSelectedProps = {
  name: string;
  size: number;
  onReset: () => void;
};
const FileSelected: React.FC<FileSelectedProps> = ({name, size, onReset}) => (
  <div className="flex flex-col gap-6">
    <div className="border-1 relative flex flex-col items-center justify-center rounded-md border-2 border-transparent bg-slate-50 p-4 text-center  text-slate-400">
      <File
        width={40}
        height={40}
        className="mb-2 rounded-md bg-slate-100 p-2 text-muted-foreground"
      />
      <div className="mx-10 mb-2 flex flex-row text-sm ">
        <p className="font-medium text-slate-700">{name}</p>
        <XCircle
          weight="bold"
          onClick={onReset}
          className="ml-2 mt-0.5 cursor-pointer text-muted-foreground"
          size={18}
        />
      </div>
      <p className="mx-10 text-xs text-muted-foreground">
        <p className="mx-10 text-xs text-muted-foreground">
          {bytesToMb(size) < 0.01
            ? `${Math.round(bytesToKb(size) * 100) / 100}KB`
            : `${Math.round(bytesToMb(size) * 100) / 100}MB`}
        </p>
      </p>
    </div>
  </div>
);

type CsvUploadProps = {
  onParse: (lines: string[][]) => void;
  onReset?: () => void;
  hasHeader?: boolean;
  dataTestId?: string;
  fileSizeLimitInMb?: number;
};
export const CsvUpload: React.FC<CsvUploadProps> = ({
  onParse,
  hasHeader = false,
  onReset,
  dataTestId,
  fileSizeLimitInMb,
}) => {
  const {t} = useTranslation('components', {keyPrefix: 'form.v2.csvUpload'});
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);

  function readAndParseCsv(file: File, inputRef: EventTarget & HTMLInputElement): void {
    if (fileSizeLimitInMb !== undefined && file.size > mbToBytes(fileSizeLimitInMb)) {
      setErrorMessage(t('validation.errorFileSize', {size: `${fileSizeLimitInMb}`}));
      inputRef.value = '';
      setSelectedFile(undefined);
      return;
    }

    Papa.parse<string[]>(file, {
      complete: result => {
        const {data, errors} = result;
        if (errors.length > 0) {
          setErrorMessage(t('validation.errorFileType'));
        } else {
          onParse(data);
          setSelectedFile(file);
          setErrorMessage(undefined);
        }
      },
      error: () => {
        setErrorMessage(t('validation.errorFileType'));
        inputRef.value = '';
        setSelectedFile(undefined);
      },
      skipEmptyLines: true,
      transform: value => value.trim(),
      header: hasHeader,
    });

    inputRef.value = '';
  }

  if (selectedFile) {
    return (
      <FileSelected
        name={selectedFile.name}
        size={selectedFile.size}
        onReset={() => {
          setSelectedFile(undefined);
          onReset !== undefined && onReset();
        }}
      />
    );
  }

  return (
    <div className="flex flex-col gap-6">
      {errorMessage && <HighlightText type="error" text={errorMessage} />}
      <div className="relative flex flex-col items-center justify-center rounded-md border-2 border-dashed p-4 text-center text-slate-400">
        <FileArrowDown
          width={40}
          height={40}
          className="mb-2 rounded-md bg-slate-100 p-2 text-muted-foreground"
        />
        <input
          data-testid={dataTestId}
          className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
          type="file"
          accept=".csv"
          onChange={e => {
            const file = e.target.files?.[0];
            if (!file) {
              return;
            }
            if (file.type !== 'text/csv' && file.name.split('.').pop() !== 'csv') {
              setErrorMessage(t('validation.errorFileType'));
              return;
            }
            readAndParseCsv(file, e.target);
          }}
        />
        <p className="mx-10 mb-2 text-sm font-medium text-slate-700">
          <Trans
            i18nKey="form.v2.csvUpload.instructions"
            ns="components"
            components={{
              clickable: <span className="text-primary" />,
            }}
          />
        </p>
        <p className="mx-10 text-xs text-muted-foreground">
          {t('format')}
          {fileSizeLimitInMb && `, ${t('fileSize', {size: fileSizeLimitInMb})}`}
        </p>
      </div>
    </div>
  );
};
