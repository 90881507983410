import type {OrganizationMemberADto} from '@cohort/admin-schemas/organizationMember';
import Button from '@cohort/merchants/components/buttons/Button';
import BaseInput from '@cohort/merchants/components/form/input/BaseInput';
import {MembershipStatusBadge} from '@cohort/merchants/components/MembershipStatusBadge';
import {Dialog, DialogContent} from '@cohort/merchants/components/modals/Dialog';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {logout} from '@cohort/merchants/lib/Firebase';
import {PaperPlaneRight, UserMinus} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

interface EditMembershipModalProps {
  member: OrganizationMemberADto;
  members: OrganizationMemberADto[];
  addMember: CallableFunction;
  removeMember: CallableFunction;
  show: boolean;
  loading: boolean;
  handleClose: () => void;
}

const EditMemberModal = ({
  member,
  members,
  addMember,
  removeMember,
  show,
  loading,
  handleClose,
}: EditMembershipModalProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const organizationMember = useUserSessionStore(store => store.organizationMember!);
  const navigate = useNavigate();
  const {t} = useTranslation('pages', {keyPrefix: 'settings.members.editMemberModal'});
  const status = member.status;
  const email = member.email;
  const isCurrentMember = member.email === organizationMember.email;
  const activeMemberships = members.filter(member => member.status === 'active');
  const showReSendInvitation = status !== 'active';
  const showDeleteMembership =
    status !== 'deactivated' && !(activeMemberships.length === 1 && isCurrentMember);

  return (
    <Dialog open={show} onOpenChange={handleClose}>
      <DialogContent>
        <div className="space-y-4 p-6">
          <div className="text-center text-lg font-semibold">{t('labelEditUser')}</div>
          <div className="text-center">
            <MembershipStatusBadge status={status} />
          </div>
          <BaseInput
            type="text"
            disabled
            placeholder={t('placeholderEnterMail')}
            defaultValue={email}
            /* this input just displays a value */
            name=""
          />
          {showReSendInvitation && (
            <Button
              loading={loading}
              onClick={async () => {
                await addMember(email);
                handleClose();
              }}
              className="h-12 w-full"
            >
              {t('buttonReSend')} <PaperPlaneRight className="ml-3 h-4 w-4 text-white" />
            </Button>
          )}
          {showDeleteMembership && (
            <Button
              variant="destructive"
              loading={loading}
              onClick={async () => {
                await removeMember(member.id);

                if (isCurrentMember) {
                  await logout();
                  navigate('/', {replace: true});
                }
                handleClose();
              }}
              className="h-12 w-full"
            >
              {isCurrentMember ? t('buttonLeave') : t('buttonRemoveUser')}{' '}
              <UserMinus className="ml-3 h-4 w-4" />
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditMemberModal;
