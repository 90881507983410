import type {
  ActivateOrganizationMemberResponseADto,
  OrganizationMemberADto,
} from '@cohort/admin-schemas/organizationMember';
import {
  ActivateOrganizationMemberResponseASchema,
  OrganizationMemberASchema,
} from '@cohort/admin-schemas/organizationMember';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import z from 'zod';

export async function getOrganizationMembers(): Promise<OrganizationMemberADto[]> {
  return apiCall('GET', `/v1/organization-members`, {
    expect: HttpCodes.SUCCESS,
    parser: z.array(OrganizationMemberASchema).parse,
  });
}

export async function inviteOrganizationMembers(
  emails: string[]
): Promise<OrganizationMemberADto[]> {
  return apiCall('POST', `/v1/organization-members`, {
    expect: HttpCodes.CREATED,
    parser: z.array(OrganizationMemberASchema).parse,
    body: {
      emails,
    },
  });
}

export async function removeOrganizationMember(
  memberId: string
): Promise<OrganizationMemberADto[]> {
  return apiCall('DELETE', `/v1/organization-members/${memberId}`, {
    expect: HttpCodes.SUCCESS,
    parser: z.array(OrganizationMemberASchema).parse,
  });
}

export async function activateOrganizationMember(
  organizationMemberId: string,
  invitationCode: string
): Promise<ActivateOrganizationMemberResponseADto> {
  return apiCall('POST', `/v1/organization-members/${organizationMemberId}/activate`, {
    expect: HttpCodes.CREATED,
    parser: ActivateOrganizationMemberResponseASchema.parse,
    body: {invitationCode},
  });
}
