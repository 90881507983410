import type {PatchCampaignADto} from '@cohort/admin-schemas/campaign';
import SubmitFooter from '@cohort/merchants/components/form/SubmitFooter';
import {campaignsKeys} from '@cohort/merchants/hooks/api/Campaigns';
import {digitalAssetCollectionsKeys} from '@cohort/merchants/hooks/api/DigitalAssetCollections';
import {perksKeys} from '@cohort/merchants/hooks/api/Perks';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useCampaignPageStore} from '@cohort/merchants/hooks/stores/campaignPage';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {patchCampaign} from '@cohort/merchants/lib/api/Campaigns';
import {getCampaignRewardRoute} from '@cohort/merchants/lib/Pages';
import CampaignRewardStep from '@cohort/merchants/pages/campaigns/campaign/edit/CampaignRewardStep';
import {useQueryClient} from '@tanstack/react-query';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const CampaignRewardEditPage = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const campaign = useCurrentCampaign();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.reward.edit.page',
  });
  const setFooter = useCampaignPageStore(state => state.setFooter);

  const {isLoading: isUpdating, mutateAsync: updateCampaign} = useCohortMutation({
    mutationFn: async (data: PatchCampaignADto) => patchCampaign(merchantId, campaign.id, data),
    notifyErrorMessage: t('notificationErrorUpdate'),
    notifySuccessMessage: t('notificationUpdateSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(digitalAssetCollectionsKeys.list(merchantId));
      queryClient.invalidateQueries(perksKeys.list(merchantId));
    },
  });

  useEffect(() => {
    setFooter(
      <SubmitFooter
        formName={`campaign-reward-step`}
        loading={isUpdating}
        submitLabel={t('saveBtn')}
        withBackButton={{
          label: t('backBtn'),
          onClick: () => navigate(-1),
        }}
      />
    );
    return () => setFooter(null);
  }, [setFooter, t, isUpdating, navigate]);

  return (
    <CampaignRewardStep
      enableDigitalAssetCreation={false}
      isProcessing={isUpdating}
      onStepValidated={async () => {
        await queryClient.invalidateQueries(campaignsKeys.getById(merchantId, campaign.id));
        navigate(getCampaignRewardRoute(campaign.id).path);
      }}
      updateCampaign={updateCampaign}
    />
  );
};

export default CampaignRewardEditPage;
