// i18nOwl-ignore [standaloneLinkSubtitle, xpsLinkSubtitle]
import AdminPage from '@cohort/merchants/components/AdminPage';
import CopyToClipboard from '@cohort/merchants/components/buttons/CopyToClipboard';
import ExportCSV from '@cohort/merchants/components/buttons/ExportCSV';
import Filters from '@cohort/merchants/components/filters/Filters';
import SingleChoiceFilter from '@cohort/merchants/components/filters/SingleChoiceFilter';
import KPICard from '@cohort/merchants/components/KPICard';
import SearchInput from '@cohort/merchants/components/SearchInput';
import type FeatureFlags from '@cohort/merchants/featureFlags';
import {useJourneySteps} from '@cohort/merchants/hooks/api/Campaigns';
import {
  useJourneyParticipations,
  useParticipationAnalytics,
} from '@cohort/merchants/hooks/api/JourneyParticipations';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {
  statusFilter,
  useJourneyParticipationsListFilters,
} from '@cohort/merchants/hooks/filters/journeyParticipationsListFilters';
import {exportParticipations} from '@cohort/merchants/lib/api/JourneyParticipations';
import JourneyParticipationsTable from '@cohort/merchants/pages/campaigns/campaign/activity/journey/JourneyParticipationsTable';
import type {JourneyParticipationStatus} from '@cohort/shared/schema/common/journeyParticipations';
import {CheckCircle, Stack} from '@phosphor-icons/react';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

const PAGE_SIZE = 20;

type JourneyLinkProps = {
  url: string;
  translationKey: string;
};

const JourneyLink: React.FC<JourneyLinkProps> = ({url, translationKey}) => (
  <p className="mb-2 line-clamp-4">
    <Trans
      i18nKey={`campaigns.campaign.activity.journey.journeyActivityPage.${translationKey}`}
      ns="pages"
      values={{url}}
      components={{
        underline: <a href={url} className="pt-1 font-medium underline" target="_blank" />,
        copyButton: <CopyToClipboard target={url} />,
      }}
    />
  </p>
);

const JourneyActivityPage: React.FC = () => {
  const campaign = useCurrentCampaign();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.activity.journey.journeyActivityPage',
  });
  const merchant = useCurrentMerchant();
  const {data: journeySteps} = useJourneySteps(merchant.id, campaign.id);
  const {featureStandaloneJourneys} = useFlags<FeatureFlags>();

  const [selectedKpiStep, setSelectedKpiStep] = useState<string | null>(null);

  if (journeySteps?.[0] !== undefined && selectedKpiStep === null) {
    setSelectedKpiStep(journeySteps[0].id);
  }

  const filters = useJourneyParticipationsListFilters();

  // i18nOwl-ignore [status.claimed, status.completed, status.in-progress]
  const statusFilterOptions = statusFilter.map(status => ({
    value: status,
    label: t(`status.${status}`),
    active: filters.status.includes(status),
  }));

  const {data, isLoading} = useJourneyParticipations(merchant.id, {
    campaignId: campaign.id,
    page: filters.page,
    pageSize: PAGE_SIZE,
    statuses: filters.status,
    searchEmail: filters.searchEmail,
    orderBy: filters.orderBy,
  });

  const [pagination, participations] = data ?? [];

  const {data: participationAnalytics} = useParticipationAnalytics(merchant.id, campaign.id);

  const getStepCompletionPercentage = (stepId: string | null): number => {
    const total = participationAnalytics?.countTotal;
    const completedSteps = participationAnalytics?.countCompletedBySteps.find(
      completion => completion.journeyStepId === stepId
    )?.countCompleted;
    if (total === undefined || stepId === null || total === 0 || completedSteps === undefined) {
      return 0;
    }
    return Math.round((completedSteps / total) * 100);
  };

  const headerConfig = {
    title: t('title'),
    subtitle: [
      featureStandaloneJourneys && campaign.journey?.standaloneUrl ? (
        <JourneyLink url={campaign.journey.standaloneUrl} translationKey="standaloneLinkSubtitle" />
      ) : undefined,
      !featureStandaloneJourneys && campaign.journey?.spaceUrl ? (
        <JourneyLink url={campaign.journey.spaceUrl} translationKey="xpsLinkSubtitle" />
      ) : undefined,
    ],
    topRightElements: [
      <KPICard
        icon={<CheckCircle className="h-4 w-4 text-slate-500" />}
        text={t('labelCompleted')}
        value={`
          ${participationAnalytics?.countCompleted ?? 0}/${
            participationAnalytics?.countTotal ?? 0
          }`}
        key="completed"
      />,
      <KPICard
        icon={<Stack className="h-4 w-4 text-slate-500" />}
        text={
          <div className="flex items-center whitespace-pre">
            <p>{t('labelCompletionFor')}</p>
            <SingleChoiceFilter
              options={
                journeySteps?.map((step, index) => ({
                  value: step.id,
                  label: `${t('labelStep')} ${index + 1}`,
                  active: selectedKpiStep === step.id,
                })) ?? []
              }
              onChange={option => {
                setSelectedKpiStep(option.value);
              }}
              className="font-bold"
            />
          </div>
        }
        value={getStepCompletionPercentage(selectedKpiStep) + '%'}
        key="completion"
      />,
    ],
  };

  const paginationConfig = {
    pagination,
    onPageChange: filters.setPage,
  };

  return (
    <AdminPage header={headerConfig} pagination={paginationConfig}>
      <div className="mb-4 mt-8 flex w-full justify-between">
        <div className="flex items-center space-x-2">
          <SearchInput
            value={filters.searchEmail}
            onChange={value => filters.setSearchEmail(value)}
            placeholder={t('placeholderSearch')}
            delay={300}
            className="!h-9"
          />
          <Filters
            filters={[
              {
                label: t('filterByStatus'),
                options: statusFilterOptions,
                type: 'multiple',
                onChange: statusOptions => {
                  const activeFilterOptions = statusOptions.filter(o => o.active);

                  filters.setStatus(
                    activeFilterOptions.map(o => o.value) as JourneyParticipationStatus[]
                  );
                },
                className: '!h-9 !focus:ring-0',
              },
            ]}
          />
        </div>
        <ExportCSV
          disabled={isLoading || participations === undefined || participations.length === 0}
          downloadFunction={async () => exportParticipations(merchant.id, campaign.id, 'csv')}
          className="!h-9"
        />
      </div>
      <JourneyParticipationsTable
        campaign={campaign}
        journeyParticipations={participations ?? []}
        isLoading={isLoading}
        orderBy={filters.orderBy}
        setOrderBy={filters.setOrderBy}
      />
    </AdminPage>
  );
};

export default JourneyActivityPage;
