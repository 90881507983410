import AdminPage from '@cohort/merchants/components/AdminPage';
import ErrorState from '@cohort/merchants/components/ErrorState';
import LoadingState from '@cohort/merchants/components/LoadingState';
import {useConnections} from '@cohort/merchants/hooks/api/Connections';
import {useSyncs} from '@cohort/merchants/hooks/api/Syncs';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import AppsList from '@cohort/merchants/pages/settings/apps/AppsList';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const AppsSettingsPage: React.FC = () => {
  const {t} = useTranslation('pages', {keyPrefix: 'settings.apps.page'});
  const merchant = useCurrentMerchant();
  const {
    data: merchantConnections,
    isLoading: isConnectionsLoading,
    isError: isConnectionsError,
  } = useConnections(merchant.id);
  const {
    data: merchantSyncs,
    isLoading: isSyncsLoading,
    isError: isSyncsError,
  } = useSyncs(merchant.id);

  const pageContent: JSX.Element = match({
    merchantConnections,
    isConnectionsLoading,
    isSyncsLoading,
    isConnectionsError,
    isSyncsError,
  })
    .with({isConnectionsLoading: true}, {isSyncsLoading: true}, () => {
      return <LoadingState />;
    })
    .with({isConnectionsError: true}, {isSyncsError: true}, () => <ErrorState />)
    .otherwise(() => {
      return <AppsList merchantConnections={merchantConnections} merchantSyncs={merchantSyncs} />;
    });

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
  };

  return <AdminPage header={headerConfig}>{pageContent}</AdminPage>;
};

export default AppsSettingsPage;
