import type {CohortADto} from '@cohort/admin-schemas/cohort';
import type {ImportUsersADto} from '@cohort/admin-schemas/userAnalytics';
import Button from '@cohort/merchants/components/buttons/Button';
import SelectCohort from '@cohort/merchants/components/cohorts/SelectCohort';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@cohort/merchants/components/modals/Dialog';
import {cohortsKeys} from '@cohort/merchants/hooks/api/Cohorts';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {usersKeys} from '@cohort/merchants/hooks/api/Users';
import {useCurrentUser} from '@cohort/merchants/hooks/contexts/currentUser';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {importUsers} from '@cohort/merchants/lib/api/Users';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

type AddUserToCohortModalProps = {
  onClose: () => void;
};

const AddUserToCohortFormSchema = z.object({
  cohortId: z.string().uuid(),
});
type AddUserToCohortFormValues = z.infer<typeof AddUserToCohortFormSchema>;

const AddUserToCohortModal: React.FC<AddUserToCohortModalProps> = ({onClose}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const user = useCurrentUser();
  const queryClient = useQueryClient();

  const [selectedCohort, setSelectedCohort] = useState<CohortADto | undefined>(undefined);

  const {t} = useTranslation('pages', {
    keyPrefix: 'users.user.cohorts.addUserToCohortModal',
  });

  const {mutate: addUserToCohort, isLoading} = useCohortMutation({
    mutationFn: async (data: ImportUsersADto) => importUsers(merchantId, data),
    notifyErrorMessage: t('notificationAddToCohortError'),
    notifySuccessMessage: t('notificationAddToCohortSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(cohortsKeys.list(merchantId));
      queryClient.invalidateQueries(usersKeys.getById(merchantId, user.id));
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      queryClient.invalidateQueries(cohortsKeys.getById(merchantId, selectedCohort!.id));
      onClose();
    },
  });

  const {register, handleSubmit} = useForm<AddUserToCohortFormValues>({
    resolver: zodResolver(AddUserToCohortFormSchema),
    defaultValues: {
      cohortId: undefined,
    },
  });

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="w-[80vw] max-w-[700px]">
        <form
          onSubmit={handleSubmit(async data =>
            addUserToCohort({users: [{email: user.email}], cohortId: data.cohortId})
          )}
        >
          <DialogHeader>
            <DialogTitle className="text-lg font-semibold">{t('modalTitle')}</DialogTitle>
            <DialogDescription className="m-0 text-sm">{t('modalDescription')}</DialogDescription>
          </DialogHeader>
          <DialogBody className="mt-0 pt-0">
            <SelectCohort register={register} type="static" onChange={setSelectedCohort} />
          </DialogBody>
          <DialogFooter>
            <Button key="close-add-user-to-cohort-modal" variant="secondary" onClick={onClose}>
              {t('buttonCancel')}
            </Button>
            <Button type="submit" loading={isLoading} disabled={selectedCohort === undefined}>
              {t('buttonConfirm')}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddUserToCohortModal;
