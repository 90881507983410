import RewardLimitBadge from '@cohort/merchants/pages/campaigns/campaign/edit/reward/RewardLimitBadge';
import {DotsSixVertical, Trash} from '@phosphor-icons/react';

type RewardListItemProps = {
  visual: JSX.Element;
  limitPerUser: number | null;
  handleRemove: () => void;
  children?: React.ReactNode;
};

const RewardListItem: React.FC<RewardListItemProps> = ({
  visual,
  limitPerUser,
  handleRemove,
  children,
}) => {
  return (
    <div className="flex flex-row items-center border-t bg-white py-4">
      <div className="flex flex-grow flex-row items-center gap-x-4">
        <DotsSixVertical className="h-5 w-5 text-slate-400" />
        {visual}
      </div>
      <div className="flex flex-row items-center gap-x-6">
        {limitPerUser && <RewardLimitBadge limitPerUser={limitPerUser} />}
        {children}
        <button
          className="h-full rounded-sm border px-2 py-2 hover:bg-slate-100"
          type="button"
          onClick={handleRemove}
        >
          <Trash className="h-4 w-4 text-red-400" />
        </button>
      </div>
    </div>
  );
};
export default RewardListItem;
