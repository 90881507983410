import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import useSectionsScroller from '@cohort/merchants/hooks/sectionScroller';
import {notify} from '@cohort/merchants/hooks/toast';
import {
  changeSelectedLanguageIfNeeded,
  getDefinedLanguages,
} from '@cohort/merchants/lib/form/localization';
import {handleFormErrors} from '@cohort/merchants/lib/form/utils';
import {getCampaignsRoute} from '@cohort/merchants/lib/Pages';
import {
  CampaignJourneyAirdropStepSchema,
  DraftCampaignAirdropSettingsStepSchema,
} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/airdrop';
import type {CampaignSettingsStepValues} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import type {
  FormStepProps,
  FormStepPropsWithUpload,
} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import {
  CampaignSettingsStepSchema,
  uploadCampaignAssets,
} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import CampaignSettingsSection from '@cohort/merchants/pages/campaigns/campaign/edit/settings/CampaignSettingsSection';
import OptionsSection from '@cohort/merchants/pages/campaigns/campaign/edit/settings/OptionsSection';
import {zodResolver} from '@hookform/resolvers/zod';
import get from 'lodash/get';
import {useEffect} from 'react';
import type {FieldErrors} from 'react-hook-form';
import {useController, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {z} from 'zod';

const CampaignSettingsAirdropStep: React.FC<FormStepProps & FormStepPropsWithUpload> = ({
  isProcessing,
  uploadMutation,
  setStepDirty,
  setStepSaveBtn,
  onStepValidated,
  updateCampaign,
}): JSX.Element => {
  const campaign = useCurrentCampaign();
  const merchant = useCurrentMerchant();

  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.campaignSettingsAirdropStep',
  });
  const navigate = useNavigate();

  const {register, control, handleSubmit, getValues, setError, clearErrors, formState} =
    useForm<CampaignSettingsStepValues>({
      defaultValues: {
        airdrop: {},
        campaignType: campaign.type,
        withMaxSupply: campaign.totalSupply !== null,
        totalSupply: campaign.totalSupply ?? null,
        tosFileKey: campaign.tosFileKey,
        faqs: campaign.faqs,
        defaultLanguage: merchant.defaultLanguage,
        selectedLanguage: merchant.defaultLanguage,
        definedLanguages: getDefinedLanguages(merchant.defaultLanguage, [campaign.faqs]),
      },
      resolver: zodResolver(CampaignSettingsStepSchema),
    });

  const sectionsRefs = useSectionsScroller([
    'appearance',
    'price',
    'options',
    'customer-information',
  ]);

  const {field: selectedLanguageField} = useController({
    control,
    name: 'selectedLanguage',
  });

  useEffect(() => {
    setStepDirty?.(formState.isDirty);
  }, [formState.isDirty, setStepDirty]);

  useEffect(() => {
    setStepSaveBtn?.(
      <Button
        loading={isProcessing}
        variant="secondary"
        onClick={async () => {
          try {
            const formData = DraftCampaignAirdropSettingsStepSchema.partial().parse(getValues());
            const patchCampaignData = await uploadCampaignAssets(formData, uploadMutation);
            clearErrors();
            await updateCampaign(patchCampaignData);
            notify('success', t('notificationSuccess'));
            navigate(getCampaignsRoute().path);
          } catch (error) {
            if (error instanceof z.ZodError) {
              handleFormErrors(error, clearErrors, setError);
              changeSelectedLanguageIfNeeded(
                error,
                merchant.defaultLanguage,
                selectedLanguageField.onChange
              );
            } else {
              throw error;
            }
          }
        }}
      >
        {t('saveBtn')}
      </Button>
    );
  }, [
    t,
    clearErrors,
    getValues,
    setError,
    updateCampaign,
    isProcessing,
    setStepSaveBtn,
    navigate,
    uploadMutation,
    formState.errors,
    merchant.defaultLanguage,
    selectedLanguageField.onChange,
  ]);

  return (
    <AdminPage header={{title: t('title'), subtitle: t('subtitle')}}>
      <form
        className="space-y-8"
        id="campaign-settings-step"
        onSubmit={handleSubmit(
          async data => {
            try {
              const formData = CampaignJourneyAirdropStepSchema.parse(data);
              const patchCampaignData = await uploadCampaignAssets(formData, uploadMutation);
              await updateCampaign(patchCampaignData);
              onStepValidated();
            } catch (error) {
              if (error instanceof z.ZodError) {
                handleFormErrors(error, clearErrors, setError);
                changeSelectedLanguageIfNeeded(
                  error,
                  merchant.defaultLanguage,
                  selectedLanguageField.onChange
                );
              } else {
                throw error;
              }
            }
          },
          errors => {
            changeSelectedLanguageIfNeeded(
              {...errors, ...get(errors, 'store')} as FieldErrors,
              merchant.defaultLanguage,
              selectedLanguageField.onChange
            );
          }
        )}
      >
        <CampaignSettingsSection ref={sectionsRefs[2]?.ref} title={t('titleAirdropOptions')}>
          <OptionsSection control={control} register={register} />
        </CampaignSettingsSection>
      </form>
    </AdminPage>
  );
};

export default CampaignSettingsAirdropStep;
