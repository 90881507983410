import {formatDate} from '@cohort/shared/utils/format';
import {useTranslation} from 'react-i18next';

type LastEditLabelProps = {
  date: Date;
};

const LastEditLabel: React.FC<LastEditLabelProps> = ({date}) => {
  const {t} = useTranslation('components', {keyPrefix: 'lastEditLabel'});
  return (
    <span className="min-w-[210px] text-right text-xs text-slate-500">
      {t('text')} {formatDate(date)}
    </span>
  );
};
export default LastEditLabel;
