import {PerkAppBadge} from '@cohort/merchants/components/perks/PerkAppBadge';
import {PerkTypeBadge} from '@cohort/merchants/components/perks/PerkTypeBadge';
import BaseReferenceComponent from '@cohort/merchants/components/references/BaseReferenceComponent';
import {getPerkRoute} from '@cohort/merchants/lib/Pages';
import type {PerkIntegrationId} from '@cohort/shared/apps';
import type {PerkType} from '@cohort/shared/schema/common/perks';

type PerkReferenceComponentProps = {
  perkId: string;
  internalName: string;
  perkType: PerkType;
  perkIntegrationId: PerkIntegrationId | null;
};

const PerkReferenceComponent: React.FC<PerkReferenceComponentProps> = ({
  internalName,
  perkId,
  perkType,
  perkIntegrationId,
}) => (
  <BaseReferenceComponent
    title={internalName}
    redirectPath={getPerkRoute(perkId).path}
    titleAddon={
      <div className="space-x-2">
        <PerkTypeBadge
          perkType={perkType}
          iconColor="text-slate-400"
          size="small"
          backgroundColor="bg-slate-100"
        />

        <PerkAppBadge perkIntegrationId={perkIntegrationId} />
      </div>
    }
  />
);

export default PerkReferenceComponent;
