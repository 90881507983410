import type {UserAnalyticsADto} from '@cohort/admin-schemas/userAnalytics';
import {useUser} from '@cohort/merchants/hooks/api/Users';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {createContext, Fragment} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

type CurrentUserProviderProps = {
  children: React.ReactNode;
};

export const CurrentUserContext = createContext<UserAnalyticsADto | null>(null);

export const CurrentUserProvider: React.FC<CurrentUserProviderProps> = ({children}) => {
  const merchant = useCurrentMerchant();
  const {userId} = useParams();
  const navigate = useNavigate();

  const {data: user, isFetched} = useUser(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    userId!,
    merchant.id
  );

  if (userId === undefined) {
    navigate('/not-found', {replace: true});
  }

  if (isFetched && !user) {
    navigate('/not-found', {replace: true});
  }

  // Maybe one day we could create Skeletons for page loading
  if (user === undefined) {
    return <Fragment />;
  }

  return <CurrentUserContext.Provider value={user}>{children}</CurrentUserContext.Provider>;
};
