import type {PatchPerkRequestADto} from '@cohort/admin-schemas/perk';
import BreadCrumb from '@cohort/merchants/components/BreadCrumb';
import Button from '@cohort/merchants/components/buttons/Button';
import PerkAirdropModal from '@cohort/merchants/components/perks/PerkAirdropModal';
import {PerkAppBadge} from '@cohort/merchants/components/perks/PerkAppBadge';
import PerkRaffleBadge from '@cohort/merchants/components/perks/PerkRaffleBadge';
import {PerkStatusBadge} from '@cohort/merchants/components/perks/PerkStatusBadge';
import {PerkTypeBadge} from '@cohort/merchants/components/perks/PerkTypeBadge';
import {PerkVisual} from '@cohort/merchants/components/PerkVisual';
import {campaignsKeys} from '@cohort/merchants/hooks/api/Campaigns';
import {perksKeys} from '@cohort/merchants/hooks/api/Perks';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentPerk} from '@cohort/merchants/hooks/contexts/currentPerk';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {patchPerk} from '@cohort/merchants/lib/api/Perks';
import {trackBreadcrumbBackButtonClicked} from '@cohort/merchants/lib/Tracking';
import {PerkInternalNameFormSchema} from '@cohort/merchants/pages/perks/new/CreatePerkModal';
import PerkDropdown from '@cohort/merchants/pages/perks/overview/PerkDropdown';
import {PaperPlaneRight} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';

const PerkPageBreadcrumb = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {t} = useTranslation('pages', {keyPrefix: 'perks.perk.perkPageBreadcrumb'});
  const queryClient = useQueryClient();
  const perk = useCurrentPerk();
  const [airdropModalOpen, setAirdropModalOpen] = useState(false);

  const {mutateAsync: updatePerk} = useCohortMutation({
    mutationFn: async (perkData: PatchPerkRequestADto) => patchPerk(merchantId, perk.id, perkData),
    notifyErrorMessage: t('notificationErrorUpdate'),
    notifySuccessMessage: t('notificationSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(campaignsKeys.campaigns);
      queryClient.invalidateQueries(perksKeys.perks);
    },
  });

  return (
    <Fragment>
      {airdropModalOpen && <PerkAirdropModal onClose={() => setAirdropModalOpen(false)} />}
      <BreadCrumb
        title={perk.internalName}
        handleTitleChange={(name: string) => updatePerk({internalName: name})}
        validator={(internalName: string) =>
          PerkInternalNameFormSchema.safeParse({internalName}).success
        }
        image={<PerkVisual perk={perk} className="rounded-md border" />}
        badges={[
          <PerkStatusBadge
            key="status"
            size="small"
            status={perk.status}
            liveCount={perk.campaignsCount}
          />,
          <PerkTypeBadge key="type" perkType={perk.type} />,
          <PerkAppBadge
            key="app"
            perkIntegrationId={perk.integration?.perkIntegrationId ?? null}
          />,
          perk.raffle !== null ? (
            <PerkRaffleBadge key="raffle" />
          ) : (
            <Fragment key="raffle-fragment" />
          ),
        ]}
        onBackClick={() => {
          trackBreadcrumbBackButtonClicked('perk', undefined, perk);
        }}
      >
        <div className="ml-4 flex items-center gap-4">
          <Button variant="secondary" onClick={() => setAirdropModalOpen(true)}>
            <PaperPlaneRight className="mr-1 -rotate-90 text-primary" />
            {t('labelAirdrop')}
          </Button>
          <PerkDropdown perk={perk} />
        </div>
      </BreadCrumb>
    </Fragment>
  );
};

export default PerkPageBreadcrumb;
