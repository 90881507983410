import {useEffect, useMemo, useRef} from 'react';
import {useSearchParams} from 'react-router-dom';

type SectionScrollerItem = {
  name: string;
  ref: React.RefObject<HTMLDivElement>;
};

export default function useSectionsScroller(sections: string[]): SectionScrollerItem[] {
  const [searchParams] = useSearchParams();
  const refs = Array.from({length: sections.length}, () => useRef<HTMLDivElement>(null));
  const previousSection = useRef<string | null>(null);

  const sectionsRef: SectionScrollerItem[] = useMemo(
    () =>
      sections.map((section, index) => ({
        name: section,
        ref: refs[index] as React.RefObject<HTMLDivElement>,
      })),
    [sections, refs]
  );

  useEffect(() => {
    const currentSection = searchParams.get('section');

    if (currentSection && currentSection !== previousSection.current) {
      const sectionRef = sectionsRef.find(ref => ref.name === currentSection);

      if (sectionRef) {
        sectionRef.ref.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
        previousSection.current = currentSection;
      }
    }
  }, [refs, searchParams]);

  return sectionsRef;
}
