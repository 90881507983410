import type {
  GetUserAnalyticsOrderByParamADto,
  UserAnalyticsADto,
} from '@cohort/admin-schemas/userAnalytics';
import {useApps} from '@cohort/merchants/apps/useApps';
import {IconBadge} from '@cohort/merchants/components/IconBadge';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {useUserProperties} from '@cohort/merchants/hooks/api/UserProperties';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import useHiddenUserProperties from '@cohort/merchants/hooks/useHiddenUserProperties';
import {getUserRoute} from '@cohort/merchants/lib/Pages';
import {getUserAttributeValue} from '@cohort/merchants/lib/Utils';
import AppLogo from '@cohort/merchants/pages/users/overview/appLogo';
import UserAttributeCell from '@cohort/merchants/pages/users/overview/UserAttributeCell';
import {formatDate} from '@cohort/shared/utils/format';
import {Flag, Gift, Stack, UserList} from '@phosphor-icons/react';
import {createColumnHelper} from '@tanstack/react-table';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

type UsersTableProps = {
  users: UserAnalyticsADto[];
  isLoading: boolean;
  orderBy: GetUserAnalyticsOrderByParamADto;
  setOrderBy: (orderBy: GetUserAnalyticsOrderByParamADto) => void;
};

const UsersTable: React.FC<UsersTableProps> = ({users, isLoading, orderBy, setOrderBy}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {keyPrefix: 'users.overview.usersTable'});
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<UserAnalyticsADto>();
  const apps = useApps();
  const excludedReferenceIds = useHiddenUserProperties();

  const {data} = useUserProperties(merchant.id, {
    visible: true,
    excludedReferenceIds,
    orderBy: 'name',
  });
  const userPropertiesData = data ?? [];

  // Dynamic columns: User properties added by the merchant.
  const dynamicColumns = userPropertiesData.map(userProperty => {
    const app = apps.getApp(userProperty.appId);
    return columnHelper.display({
      id: userProperty.id,
      header: () => (
        <div className="flex w-max items-center gap-x-2">
          <AppLogo
            app={app}
            fallbackLogo={<UserList size={20} className="text-slate-400" />}
            tooltipContent={app?.spec.name ?? t('customProperty')}
          />
          {userProperty.name}
        </div>
      ),
      cell: data => {
        const userAttribute =
          data.row.original.userAttributes?.find(
            attribute => attribute.referenceId === userProperty.referenceId
          ) ?? null;
        return (
          <UserAttributeCell
            dataType={userProperty.dataType}
            value={getUserAttributeValue(userProperty, userAttribute)}
            className="line-clamp-none max-w-[450px] truncate whitespace-nowrap font-normal text-slate-700"
          />
        );
      },
      minSize: ['resource_list', 'string_list'].includes(userProperty.dataType) ? 400 : 280,
    });
  });

  // Static columns: User properties that are always displayed.
  const staticColumns = [
    columnHelper.accessor('email', {
      header: t('tableHeaderEmail'),
      cell: data => <span className="font-medium text-foreground">{data.getValue()}</span>,
      minSize: 250,
      enableSorting: false,
    }),
    columnHelper.accessor(row => formatDate(row.joinedAt), {
      id: 'joinedAt',
      header: t('tableHeaderJoinedAt'),
      cell: data => <span className="font-normal">{data.getValue()}</span>,
      minSize: 220,
    }),
    columnHelper.accessor(row => formatDate(row.lastActiveAt), {
      id: 'lastActiveAt',
      header: t('tableHeaderLastActiveAt'),
      cell: data => <span className="font-normal">{data.getValue()}</span>,
      sortingFn: 'datetime',
      minSize: 220,
    }),
    columnHelper.accessor(row => `${row.journeysCompletedCount}/${row.journeysCount}`, {
      id: 'journeysCompletedCount',
      header: t('tableHeaderJourneys'),
      cell: data => (
        <IconBadge icon={<Flag className="mr-2 h-4 w-4 text-slate-400" />} text={data.getValue()} />
      ),
      minSize: 200,
    }),
    columnHelper.accessor(row => row.ownershipsCount.toString(), {
      id: 'ownershipsCount',
      header: t('tableHeaderDigitalAssets'),
      cell: data => (
        <IconBadge
          icon={<Stack className="mr-2 h-4 w-4 text-slate-400" />}
          text={data.getValue()}
        />
      ),
    }),
    columnHelper.accessor(row => `${row.perkUsagesCount}/${row.perksCount}`, {
      id: 'perkUsagesCount',
      header: t('tableHeaderPerks'),
      cell: data => (
        <IconBadge icon={<Gift className="mr-2 h-4 w-4 text-slate-400" />} text={data.getValue()} />
      ),
    }),
    columnHelper.accessor('engagementScore', {
      header: t('tableHeaderEngagementScore'),
      cell: data => <UserAttributeCell dataType="number" value={data.getValue()} />,
      minSize: 200,
    }),
  ];

  const columns = [...staticColumns, ...dynamicColumns];
  const table = useCohortTable(
    {
      data: users,
      columns,
      initialState: {
        columnPinning: {
          left: ['email'],
        },
      },
    },
    {
      sortBy: orderBy,
      onSortUpdate: setOrderBy,
    }
  );

  return (
    <DataTable
      table={table}
      columnsLength={columns.length}
      emptyStatePlaceholder={t('emptyPlaceholder')}
      isLoading={isLoading}
      onRowClick={user => navigate(getUserRoute(user.id).path)}
    />
  );
};

export default UsersTable;
