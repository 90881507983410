import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import type {CampaignStorePreviewValues} from '@cohort/merchants/components/campaigns/CampaignStorePreview';
import {CampaignStorePreview} from '@cohort/merchants/components/campaigns/CampaignStorePreview';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import useSectionsScroller from '@cohort/merchants/hooks/sectionScroller';
import {useCampaignPageStore} from '@cohort/merchants/hooks/stores/campaignPage';
import {notify} from '@cohort/merchants/hooks/toast';
import {
  changeSelectedLanguageIfNeeded,
  getDefinedLanguages,
} from '@cohort/merchants/lib/form/localization';
import {getFileType, handleFormErrors} from '@cohort/merchants/lib/form/utils';
import {hexColorValidator} from '@cohort/merchants/lib/form/validators';
import {getCampaignsRoute} from '@cohort/merchants/lib/Pages';
import CampaignPreviewAside from '@cohort/merchants/pages/campaigns/campaign/CampaignPreviewAside';
import type {CampaignSettingsStepValues} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import type {
  FormStepProps,
  FormStepPropsWithUpload,
} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import {CampaignSettingsStepSchema} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import {
  CampaignStoreSettingsStepSchema,
  DraftCampaignStoreSettingsStepSchema,
  uploadCampaignStoreAssets,
} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/store';
import CampaignSettingsSection from '@cohort/merchants/pages/campaigns/campaign/edit/settings/CampaignSettingsSection';
import FaqSection from '@cohort/merchants/pages/campaigns/campaign/edit/settings/FaqSection';
import OptionsSection from '@cohort/merchants/pages/campaigns/campaign/edit/settings/OptionsSection';
import StoreAppearanceSection from '@cohort/merchants/pages/campaigns/campaign/edit/settings/store/StoreAppearanceSection';
import PriceSection from '@cohort/merchants/pages/campaigns/campaign/edit/settings/store/StorePriceSection';
import {DEFAULT_ACCENT_COLOR, DEFAULT_BACKGROUND_COLOR} from '@cohort/shared/schema/common';
import type {Currency, Price} from '@cohort/shared/schema/common/currency';
import {CurrencySpecs} from '@cohort/shared/schema/common/currency';
import {toSlug} from '@cohort/shared/utils/format';
import {isFileList} from '@cohort/shared-frontend/utils/isFile';
import {zodResolver} from '@hookform/resolvers/zod';
import get from 'lodash/get';
import {Fragment, useEffect} from 'react';
import type {FieldErrors} from 'react-hook-form';
import {useController, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {z} from 'zod';

function formatFormPrice(price: Price): {amount: number; currency: Currency} {
  return {
    amount: CurrencySpecs.toDecimal(price.currency, price.amount),
    currency: price.currency,
  };
}

const CampaignSettingsStoreStep: React.FC<FormStepProps & FormStepPropsWithUpload> = ({
  isProcessing,
  uploadMutation,
  setStepDirty,
  setStepSaveBtn,
  onStepValidated,
  updateCampaign,
}): JSX.Element => {
  const campaign = useCurrentCampaign();
  const merchant = useCurrentMerchant();

  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.campaignSettingsStoreStep',
  });
  const setCampaignPreview = useCampaignPageStore(state => state.setCampaignPreview);
  const navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState,
    watch,
    setValue,
  } = useForm<CampaignSettingsStepValues>({
    defaultValues: {
      store: {
        title: campaign.store?.title ?? {},
        imageFileKey: campaign.store?.imageFileKey ?? campaign.store?.animationFileKey ?? null,
        animationFileKey: campaign.store?.animationFileKey ?? null,
        slug: campaign.store?.slug ?? `${toSlug(campaign.internalName)}-${campaign.id.slice(0, 4)}`,
        description: campaign.store?.description ?? {},
        backgroundColorCode:
          campaign.store?.backgroundColorCode ||
          merchant.backgroundColorCode ||
          DEFAULT_BACKGROUND_COLOR,
        accentColorCode:
          campaign.store?.accentColorCode || merchant.accentColorCode || DEFAULT_ACCENT_COLOR,
        pricing: campaign.store?.pricing ?? 'free',
        defaultCurrency: campaign.store?.defaultCurrency ?? null,
        prices: (campaign.store?.prices ?? []).map(price => formatFormPrice(price)),
      },
      campaignType: campaign.type,
      withMaxSupply: campaign.totalSupply !== null,
      totalSupply: campaign.totalSupply ?? null,
      tosFileKey: campaign.tosFileKey,
      faqs: campaign.faqs,
      defaultLanguage: merchant.defaultLanguage,
      selectedLanguage: merchant.defaultLanguage,
      definedLanguages: getDefinedLanguages(merchant.defaultLanguage, [
        campaign.store?.description ?? {},
        campaign.faqs,
      ]),
    },
    resolver: zodResolver(CampaignSettingsStepSchema),
  });
  const [
    title,
    imageFileKey,
    animationFileKey,
    accentColorCode,
    backgroundColorCode,
    description,
    faqs,
    selectedLanguage,
  ] = watch([
    'store.title',
    'store.imageFileKey',
    'store.animationFileKey',
    'store.accentColorCode',
    'store.backgroundColorCode',
    'store.description',
    'faqs',
    'selectedLanguage',
  ]);

  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  const sectionsRefs = useSectionsScroller([
    'appearance',
    'price',
    'options',
    'customer-information',
  ]);

  const {field: selectedLanguageField} = useController({
    control,
    name: 'selectedLanguage',
  });

  useEffect(() => {
    setStepDirty?.(formState.isDirty);
  }, [formState.isDirty, setStepDirty]);

  useEffect(() => {
    setStepSaveBtn?.(
      <Button
        loading={isProcessing}
        variant="secondary"
        onClick={async () => {
          try {
            const formData = DraftCampaignStoreSettingsStepSchema.parse(getValues());
            const patchCampaignData = await uploadCampaignStoreAssets(formData, uploadMutation);
            clearErrors();
            await updateCampaign(patchCampaignData);
            notify('success', t('notificationSuccess'));
            navigate(getCampaignsRoute().path);
          } catch (error) {
            if (error instanceof z.ZodError) {
              handleFormErrors(error, clearErrors, setError);
              changeSelectedLanguageIfNeeded(
                error,
                merchant.defaultLanguage,
                selectedLanguageField.onChange
              );
            } else {
              throw error;
            }
          }
        }}
      >
        {t('saveBtn')}
      </Button>
    );
  }, [
    t,
    clearErrors,
    getValues,
    setError,
    updateCampaign,
    isProcessing,
    setStepSaveBtn,
    navigate,
    uploadMutation,
    formState.errors,
    merchant.defaultLanguage,
    selectedLanguageField.onChange,
  ]);

  useEffect(() => {
    if (selectedLanguage === undefined) {
      return;
    }

    const imageFileType = getFileType(imageFileKey);
    const computedImageFileKey = imageFileType === 'image' ? imageFileKey : null;
    const isVideoFileDefined = isFileList(imageFileKey) && animationFileKey;

    const computedAnimationFileKey = (): string | File | null => {
      if (isVideoFileDefined) {
        return animationFileKey;
      } else if (imageFileType === 'video') {
        return imageFileKey;
      }
      return null;
    };

    const previewValues = {
      accentColorCode: hexColorValidator(accentColorCode) ? accentColorCode : DEFAULT_ACCENT_COLOR,
      animationFileKey: computedAnimationFileKey(),
      backgroundColorCode: hexColorValidator(backgroundColorCode)
        ? backgroundColorCode
        : DEFAULT_BACKGROUND_COLOR,
      campaignTitle: title[selectedLanguage] ?? t('defaultName'),
      defaultCurrency: campaign.store?.defaultCurrency ?? 'eur',
      description: description[selectedLanguage] ?? t('defaultDescription'),
      imageFileKey: isFileList(computedImageFileKey) ? null : computedImageFileKey,
      faqs: faqs,
      perks: campaign.rewardBundle.perks.map(perk => perk.perk),
      collections: campaign.rewardBundle.collections.map(collection => collection.collection),
      prices: campaign.store?.prices ?? [],
      pricing: campaign.store?.pricing ?? 'free',
      supportEmail: merchant.supportEmail ?? '',
      selectedLanguage,
    } satisfies CampaignStorePreviewValues;

    setCampaignPreview(
      <CampaignPreviewAside>
        <CampaignStorePreview values={previewValues} />
      </CampaignPreviewAside>
    );

    return () => setCampaignPreview(null);
  }, [
    accentColorCode,
    backgroundColorCode,
    campaign.rewardBundle.perks,
    merchant.supportEmail,
    merchant.defaultLanguage,
    description,
    faqs,
    setCampaignPreview,
    selectedLanguage,
    t,
    campaign.store?.defaultCurrency,
    campaign.store?.prices,
    campaign.store?.pricing,
    campaign.rewardBundle.collections,
    animationFileKey,
    title,
    imageFileKey,
  ]);

  return (
    <AdminPage header={{title: t('title'), subtitle: t('subtitle')}}>
      <LanguageSelectorInput
        control={control}
        definedLanguagesPath="definedLanguages"
        selectedLanguagePath="selectedLanguage"
      />
      <form
        className="space-y-8"
        id="campaign-settings-step"
        onSubmit={handleSubmit(
          async data => {
            try {
              const formData = CampaignStoreSettingsStepSchema.parse(data);
              const patchCampaignData = await uploadCampaignStoreAssets(formData, uploadMutation);
              await updateCampaign(patchCampaignData);
              onStepValidated();
            } catch (error) {
              if (error instanceof z.ZodError) {
                handleFormErrors(error, clearErrors, setError);
                changeSelectedLanguageIfNeeded(
                  error,
                  merchant.defaultLanguage,
                  selectedLanguageField.onChange
                );
              } else {
                throw error;
              }
            }
          },
          errors => {
            changeSelectedLanguageIfNeeded(
              {...errors, ...get(errors, 'store')} as FieldErrors,
              merchant.defaultLanguage,
              selectedLanguageField.onChange
            );
          }
        )}
      >
        <CampaignSettingsSection ref={sectionsRefs[0]?.ref} title={t('titleAppearance')}>
          <StoreAppearanceSection control={control} register={register} setValue={setValue} />
        </CampaignSettingsSection>
        {isDefaultLanguageSelected && (
          <Fragment>
            <CampaignSettingsSection ref={sectionsRefs[1]?.ref} title={t('titlePrice')}>
              <PriceSection control={control} register={register} setValue={setValue} />
            </CampaignSettingsSection>
            <CampaignSettingsSection ref={sectionsRefs[2]?.ref} title={t('titleStoreOptions')}>
              <OptionsSection control={control} register={register} />
            </CampaignSettingsSection>
          </Fragment>
        )}
        <CampaignSettingsSection ref={sectionsRefs[3]?.ref} title={t('titleCustomerInformation')}>
          <FaqSection control={control} register={register} key={selectedLanguage} />
        </CampaignSettingsSection>
      </form>
    </AdminPage>
  );
};

export default CampaignSettingsStoreStep;
