import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Image} from '@phosphor-icons/react';
import React from 'react';

interface BadgeVisualProps {
  imageFileKey: string | null;
  size?: number;
}

const BadgeVisual: React.FC<BadgeVisualProps> = ({imageFileKey, size}) =>
  imageFileKey !== null ? (
    <img
      className={cn(
        'rounded-full border border-slate-100 object-cover',
        size ? `h-${size} w-${size}` : 'h-12 w-12'
      )}
      src={getImageUrl(import.meta.env.COHORT_ENV, imageFileKey, {h: Sizes.S, w: Sizes.S})}
    />
  ) : (
    <div
      className={cn(
        'flex flex-col items-center justify-center rounded-full border border-slate-100 bg-slate-100 text-slate-400',
        size ? `h-${size} w-${size}` : 'h-12 w-12'
      )}
    >
      <Image size={20} className="text-slate-400" />
    </div>
  );

export default BadgeVisual;
