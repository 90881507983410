import {EmptyContentContainer} from '@cohort/merchants/components/EmptyContentContainer';
import {FileMagnifyingGlass} from '@phosphor-icons/react';

export type EmptyStateProps = {
  title: JSX.Element | string;
  description?: JSX.Element | string;
  cta?: JSX.Element;
  icon?: JSX.Element;
  className?: string;
};

const EmptyState: React.FC<EmptyStateProps> = ({title, description, cta, icon, className}) => (
  <EmptyContentContainer
    icon={icon ?? <FileMagnifyingGlass size={40} className="text-slate-400" />}
    text={title}
    description={description}
    button={cta}
    className={className}
  />
);

export default EmptyState;
