import type FeatureFlags from '@cohort/merchants/featureFlags';
import {USER_PROPERTIES_REF_IDS_TO_HIDE} from '@cohort/shared/constants';
import {useFlags} from 'launchdarkly-react-client-sdk';

const useHiddenUserProperties = (): Array<string> => {
  const {appsInstagramUserMediaPermission} = useFlags<FeatureFlags>();
  const hiddenReferenceIds = [...USER_PROPERTIES_REF_IDS_TO_HIDE];

  if (!appsInstagramUserMediaPermission) {
    hiddenReferenceIds.push('instagram.medias-count');
  }

  return hiddenReferenceIds;
};

export default useHiddenUserProperties;
