import type {WrappedField} from '@cohort/merchants/components/form/FieldWrapper';
import BaseInput from '@cohort/merchants/components/form/input/BaseInput';
import type {FormField} from '@cohort/merchants/lib/form/utils';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import dayjs from 'dayjs';
import get from 'lodash/get';
import {useEffect, useState} from 'react';
import type {FieldValues} from 'react-hook-form';
import {useFormState, useWatch} from 'react-hook-form';
import {z} from 'zod';

type InputProps<T extends FieldValues> = Omit<JSX.IntrinsicElements['input'], 'name'> &
  WrappedField &
  FormField<T> & {
    variant?: 'datetime' | 'date';
  };

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function DatetimeInput<T extends FieldValues>({
  register,
  control,
  rules,
  variant = 'datetime',
  ...props
}: InputProps<T>): JSX.Element {
  const [value, setValue] = useState<string>('');

  const {errors} = useFormState({
    control,
    name: props.name,
  });

  const formValue = useWatch({
    control,
    name: props.name,
  });

  useEffect(() => {
    if ((formValue as unknown) instanceof Date) {
      setValue(dayjs(formValue).format(variant === 'datetime' ? 'YYYY-MM-DDTHH:mm' : 'YYYY-MM-DD'));
    } else {
      setValue('');
    }
  }, [formValue, variant]);

  return (
    <BaseInput
      className={cn('w-fit', props.className)}
      type={variant === 'datetime' ? 'datetime-local' : 'date'}
      error={get(errors, `${props.name}.message`) as string | undefined}
      value={value}
      {...props}
      {...register(props.name, {
        ...rules,
        setValueAs: (value: unknown) => {
          const parsed = z.coerce.date().safeParse(value);
          if (!parsed.success) {
            return '';
          }
          return parsed.data;
        },
      })}
    />
  );
}
