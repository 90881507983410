import type {
  StripeConnectAccountBalanceADto,
  StripeConnectAccountPayoutScheduleADto,
  UpdateStripeConnectAccountPayoutScheduleDataADto,
} from '@cohort/admin-schemas/stripe';
import {UpdateStripeConnectAccountPayoutScheduleDataASchema} from '@cohort/admin-schemas/stripe';
import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import ErrorState from '@cohort/merchants/components/ErrorState';
import type {SelectOption} from '@cohort/merchants/components/form/select/SelectPicker';
import {SelectPicker} from '@cohort/merchants/components/form/select/SelectPicker';
import LoadingState from '@cohort/merchants/components/LoadingState';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {
  stripeKeys,
  useStripeConnectAccountBalance,
  useStripeConnectAccountPayoutSchedule,
} from '@cohort/merchants/hooks/api/Stripe';
import {
  getStripeConnectLoginLink,
  updateStripeConnectAccountPayoutSchedule,
} from '@cohort/merchants/lib/api/Stripe';
import {
  useDaysOfWeekOptions,
  useIntervalOptions,
} from '@cohort/merchants/pages/settings/payments/utils';
import {formatAmount} from '@cohort/shared/utils/format';
import {zodResolver} from '@hookform/resolvers/zod';
import {CheckCircle} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

interface BalanceSectionProps {
  balance: StripeConnectAccountBalanceADto;
  schedule: StripeConnectAccountPayoutScheduleADto;
}

interface IntervalOptionProps {
  option: SelectOption;
  selected: boolean;
  onSelect: (option: SelectOption) => void;
}

interface DayOfWeekOption {
  value: Exclude<UpdateStripeConnectAccountPayoutScheduleDataADto['dayOfWeek'], null>;
  label: string;
}

interface ScheduleSectionProps {
  schedule: StripeConnectAccountPayoutScheduleADto;
}

const IntervalOptionButton = (props: IntervalOptionProps): JSX.Element => {
  const {option, selected, onSelect} = props;

  return (
    <Button variant={selected ? 'primary' : 'secondary'} onClick={() => onSelect(option)}>
      {option.label}
    </Button>
  );
};

const BalanceSection = (props: BalanceSectionProps): JSX.Element => {
  const {t} = useTranslation('pages', {keyPrefix: 'settings.payments.paymentSettings'});

  const [currencyFilter, setCurrencyFilter] = useState<SelectOption>({label: '', value: ''});
  const [loading, setLoading] = useState(false);

  const {balance, schedule} = props;
  const availableBalance = balance.available.find(data => data.currency === currencyFilter.value);
  const pendingBalance = balance.pending.find(data => data.currency === currencyFilter.value);

  const currencyOptions = useMemo(() => {
    const set = new Set<string>();
    for (const {currency} of [...balance.available, ...balance.pending]) {
      set.add(currency);
    }
    return [...set].map(currency => ({label: currency.toUpperCase(), value: currency}));
  }, [balance]);

  const handleViewDashboard = useCallback<React.MouseEventHandler>(async event => {
    event.preventDefault();
    setLoading(true);
    const {url} = await getStripeConnectLoginLink();
    window.open(url, '_blank');
    setLoading(false);
  }, []);

  useEffect(() => {
    const defaultCurrency = balance.available[0]?.currency;
    if (defaultCurrency !== undefined) {
      setCurrencyFilter({label: defaultCurrency.toUpperCase(), value: defaultCurrency});
    }
  }, [balance.available]);

  return (
    <div className="flex flex-row items-center justify-between py-4">
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-row items-center">
          <p className="text-base font-medium leading-6 text-slate-500">
            {t('labelAvailableBalance')}
          </p>
          {currencyOptions.length > 1 && (
            <div className="ml-4 w-24">
              <SelectPicker
                options={currencyOptions}
                value={currencyFilter}
                onChange={option => {
                  if (option !== null) {
                    setCurrencyFilter(option);
                  }
                }}
                name="currency"
              />
            </div>
          )}
        </div>
        <div className="flex flex-row divide-x divide-slate-600">
          {availableBalance && (
            <span className="pr-4">
              <span className="text-lg font-medium text-slate-800">{t('labelAvailable')}</span>
              <span className="ml-3 text-2xl font-semibold leading-8">
                {formatAmount(availableBalance.amount, availableBalance.currency)}
              </span>
            </span>
          )}
          {pendingBalance && (
            <span className="pl-4">
              <span className="text-lg font-medium text-slate-800">{t('labelPending')}</span>
              <span className="ml-3 text-2xl font-semibold leading-8">
                {formatAmount(pendingBalance.amount, pendingBalance.currency)}
              </span>
            </span>
          )}
        </div>
        <p className="text-sm font-normal leading-5 text-slate-500">
          {t('descriptionAvailableBalance', {delayDays: schedule.delayDays})}
        </p>
      </div>
      <Button onClick={handleViewDashboard} loading={loading}>
        {t('buttonViewDashboard')}
      </Button>
    </div>
  );
};

const ScheduleSection = (props: ScheduleSectionProps): JSX.Element => {
  const queryClient = useQueryClient();
  const {t} = useTranslation('pages', {keyPrefix: 'settings.payments.paymentSettings'});
  const intervals = useIntervalOptions();
  const daysOfWeek = useDaysOfWeekOptions();
  const {schedule} = props;

  const daysOfMonth = useMemo(
    () => [...Array(31).keys()].map(i => ({label: String(i + 1), value: String(i + 1)})),
    []
  );

  const {
    handleSubmit,
    reset,
    setValue,
    formState: {isDirty},
    watch,
  } = useForm<UpdateStripeConnectAccountPayoutScheduleDataADto>({
    resolver: zodResolver(UpdateStripeConnectAccountPayoutScheduleDataASchema),
    mode: 'onBlur',
  });

  const {interval, dayOfWeek, dayOfMonth} = watch();

  const handleReset = useCallback(() => {
    reset(schedule);
  }, [schedule, reset]);

  const {isLoading, mutate: updatePayoutSchedule} = useCohortMutation({
    mutationFn: async (data: UpdateStripeConnectAccountPayoutScheduleDataADto) =>
      updateStripeConnectAccountPayoutSchedule(data),
    onSuccess: async () => queryClient.invalidateQueries(stripeKeys.payoutSchedule()),
  });

  const handleChangeInterval = useCallback(
    (option: SelectOption) => {
      const value = option.value as UpdateStripeConnectAccountPayoutScheduleDataADto['interval'];
      setValue('interval', value, {shouldDirty: true});
      switch (value) {
        case 'daily':
          setValue('dayOfWeek', null, {shouldDirty: true});
          setValue('dayOfMonth', null, {shouldDirty: true});
          break;
        case 'weekly':
          setValue('dayOfWeek', 'monday', {shouldDirty: true});
          setValue('dayOfMonth', null, {shouldDirty: true});
          break;
        case 'monthly':
          setValue('dayOfWeek', null, {shouldDirty: true});
          setValue('dayOfMonth', 1, {shouldDirty: true});
          break;
        default:
          break;
      }
    },
    [setValue]
  );

  useEffect(() => {
    reset(schedule);
  }, [schedule, reset]);

  return (
    <Fragment>
      <div className="flex flex-row items-center justify-between py-4">
        <p className="font-medium">{t('labelFrequency')}</p>
        <div className="flex flex-row gap-x-3">
          {intervals.map(option => (
            <IntervalOptionButton
              key={option.value}
              option={option}
              selected={interval === option.value}
              onSelect={handleChangeInterval}
            />
          ))}
        </div>
      </div>
      {interval !== 'daily' && (
        <div className="flex flex-row items-center justify-between py-4">
          <p className="font-medium">
            {interval === 'weekly' && t('labelDayOfWeek')}
            {interval === 'monthly' && t('labelDayOfMonth')}
          </p>
          <div className="w-40">
            {interval === 'weekly' && (
              <SelectPicker<DayOfWeekOption>
                value={daysOfWeek.find(d => d.value === dayOfWeek) ?? {label: '', value: 'monday'}}
                options={daysOfWeek}
                onChange={option => {
                  if (option !== null) {
                    setValue('dayOfWeek', option.value, {shouldDirty: true});
                  }
                }}
                name="dayOfWeek"
              />
            )}
            {interval === 'monthly' && (
              <SelectPicker
                value={{label: String(dayOfMonth), value: String(dayOfMonth)}}
                options={daysOfMonth}
                onChange={option => {
                  if (option !== null) {
                    setValue('dayOfMonth', Number(option.value), {shouldDirty: true});
                  }
                }}
                name="dayOfMonth"
              />
            )}
          </div>
        </div>
      )}
      <div className="flex flex-row items-center justify-end gap-x-3 py-4">
        <Button variant="secondary" disabled={!isDirty || isLoading} onClick={handleReset}>
          {t('buttonReset')}
        </Button>
        <Button
          disabled={!isDirty}
          loading={isLoading}
          onClick={handleSubmit(data => updatePayoutSchedule(data))}
        >
          {t('buttonSave')}
        </Button>
      </div>
    </Fragment>
  );
};

const PaymentSettings = (): JSX.Element => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.payments.paymentSettings',
  });
  const {isLoading: scheduleLoading, data: schedule} = useStripeConnectAccountPayoutSchedule();
  const {isLoading: balanceLoading, data: balance} = useStripeConnectAccountBalance();
  const isLoading = scheduleLoading || balanceLoading;

  const headerConfig = {
    title: t('header'),
    subtitle: (
      <p>
        <CheckCircle className="inline h-5 text-green-400" /> {t('subheaderActive')}
      </p>
    ),
  };

  return (
    <AdminPage header={headerConfig}>
      {isLoading && <LoadingState />}
      {!isLoading && schedule && balance && (
        <div className="flex flex-col divide-y divide-slate-200">
          <BalanceSection balance={balance} schedule={schedule} />
          <ScheduleSection schedule={schedule} />
        </div>
      )}
      {!isLoading && (!schedule || !balance) && <ErrorState />}
    </AdminPage>
  );
};

export default PaymentSettings;
