import type {CampaignADto} from '@cohort/admin-schemas/campaign';
import {useCohort} from '@cohort/merchants/hooks/api/Cohorts';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

const AudienceSectionContentCohort: React.FC<{campaign: CampaignADto}> = ({campaign}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);

  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.review.audienceSectionContentCohort',
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const {data: cohort} = useCohort(merchantId, campaign.cohort!.id);

  return (
    <Fragment>
      <p className="font-medium">{t('labelCohort')}</p>
      <p className="text-gray-500">{t('eligibleUsers', {count: cohort?.usersCount ?? 0})}</p>
    </Fragment>
  );
};

export default AudienceSectionContentCohort;
