import type {ApiKeyADto, CreateApiKeyResponseADto} from '@cohort/admin-schemas/apiKeys';
import {Dialog, DialogContent} from '@cohort/merchants/components/modals/Dialog';
import ManageApiKeyForm from '@cohort/merchants/pages/settings/integrations/ManageApiKeyModal/ManageApiKeyForm';
import ShowCreatedKey from '@cohort/merchants/pages/settings/integrations/ManageApiKeyModal/ShowCreatedKey';
import {useState} from 'react';

type ManageApiKeyModalProps = {
  apiKey?: ApiKeyADto;
  handleOnClose: () => void;
};

const ManageApiKeyModal: React.FC<ManageApiKeyModalProps> = ({apiKey, handleOnClose}) => {
  const [createdKey, setCreatedKey] = useState<CreateApiKeyResponseADto>();

  return (
    <Dialog open onOpenChange={handleOnClose}>
      <DialogContent className="w-[650px]">
        {createdKey === undefined && (
          <ManageApiKeyForm
            apiKey={apiKey}
            onCancel={handleOnClose}
            onCreate={key => setCreatedKey(key)}
            handleOnClose={handleOnClose}
          />
        )}
        {createdKey && <ShowCreatedKey apiKey={createdKey} onClose={handleOnClose} />}
      </DialogContent>
    </Dialog>
  );
};

export default ManageApiKeyModal;
