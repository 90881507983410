import type {StripeConnectAccountADto} from '@cohort/admin-schemas/stripe';
import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import {getStripeConnectOnboardingLink} from '@cohort/merchants/lib/api/Stripe';
import {IdentificationCard, Wrench} from '@phosphor-icons/react';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
  account: StripeConnectAccountADto;
}

const PaymentSettingsPendingAccount = (props: Props): JSX.Element => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.payments.paymentSettingsPendingAccount',
  });
  const [loading, setLoading] = useState(false);
  const {account} = props;

  const handleSetupAccount = useCallback(async () => {
    setLoading(true);
    const {url} = await getStripeConnectOnboardingLink();
    window.location.href = url;
  }, []);

  const detailsSubmitted = account.detailsSubmitted;

  const Icon = detailsSubmitted ? Wrench : IdentificationCard;

  const title = detailsSubmitted ? t('titleProcessing') : t('titlePending');

  const subtitle = detailsSubmitted ? t('subtitleProcessing') : t('subtitlePending');

  const buttonText = detailsSubmitted ? t('buttonViewRequest') : t('buttonSetup');

  const headerConfig = {
    title: t('header'),
    subtitle: t('subheaderNotActive'),
  };

  return (
    <AdminPage header={headerConfig}>
      <div className="flex w-full flex-grow flex-row items-center justify-start rounded-xl bg-slate-50 p-10">
        <div className="mr-10">
          <Icon className="w-14 text-slate-400" />
        </div>
        <div className="flex flex-grow flex-col">
          <p className="mt-2 font-semibold leading-5">{title}</p>
          <p className="mt-2 font-normal leading-5 text-slate-500">{subtitle}</p>
          <Button className="mt-5 self-end" onClick={handleSetupAccount} loading={loading}>
            {buttonText}
          </Button>
        </div>
      </div>
    </AdminPage>
  );
};

export default PaymentSettingsPendingAccount;
