import type {CreateDigitalAssetCollectionADto} from '@cohort/admin-schemas/digitalAssetCollection';
import Button from '@cohort/merchants/components/buttons/Button';
import FileInput from '@cohort/merchants/components/form/FileInput';
import Input from '@cohort/merchants/components/form/input/Input';
import LocalizedInput from '@cohort/merchants/components/form/input/LocalizedInput';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import LocalizedTextEditorInput from '@cohort/merchants/components/form/textEditor/LocalizedTextEditorInput';
import Header from '@cohort/merchants/components/Header';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from '@cohort/merchants/components/modals/Dialog';
import Separator from '@cohort/merchants/components/Separator';
import {digitalAssetCollectionsKeys} from '@cohort/merchants/hooks/api/DigitalAssetCollections';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {createDigitalAssetCollection} from '@cohort/merchants/lib/api/DigitalAssetCollections';
import {
  changeSelectedLanguageIfNeeded,
  getDefinedLanguages,
  removeUndefinedLanguages,
} from '@cohort/merchants/lib/form/localization';
import {uploadAsset} from '@cohort/merchants/lib/form/utils';
import {fileValidatorRequired} from '@cohort/merchants/lib/form/validators';
import {trackDigitalAssetCollectionCreateSuccess} from '@cohort/merchants/lib/Tracking';
import DigitalAssetCollectionNftInput from '@cohort/merchants/pages/digital-asset-collections/digital-asset-collection/DigitalAssetCollectionNftSettingsInput';
import DigitalAssetCollectionOwnershipsInput from '@cohort/merchants/pages/digital-asset-collections/digital-asset-collection/DigitalAssetCollectionOwnershipsSettings';
import {notifyError} from '@cohort/merchants/stores/ErrorModalStore';
import {
  LanguageSchema,
  LocalizedRichTextSchema,
  LocalizedStringSchema,
} from '@cohort/shared/schema/common';
import type {AssetKind} from '@cohort/shared/schema/common/assets';
import {AllowedAssetMimeTypes} from '@cohort/shared/schema/common/assets';
import {isVideoFile} from '@cohort/shared/utils/mimeTypes';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment} from 'react';
import {useController, useForm, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

interface CreateDigitalAssetCollectionModalProps {
  onClose: () => void;
}

const CreateDigitalAssetCollectionModalSchema = z
  .object({
    title: LocalizedStringSchema,
    internalName: z.string().min(3, {message: 'errorTooShort3'}),
    description: LocalizedRichTextSchema,
    media: z.any().refine(fileValidatorRequired, 'errorRequired'),
    defaultLanguage: LanguageSchema,
    selectedLanguage: LanguageSchema,
    definedLanguages: z.array(LanguageSchema),
    withMaxOwnershipsPerUser: z.boolean(),
    maxOwnershipsPerUser: z.number().nullable(),
    nftEnabled: z.boolean(),
    nftNonTransferable: z.boolean(),
  })
  .superRefine(({title, defaultLanguage}, ctx) => {
    if (title[defaultLanguage] === undefined) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorRequired',
        path: [`title.${defaultLanguage}`],
      });
    }
  })
  .superRefine(({description, defaultLanguage}, ctx) => {
    if (description[defaultLanguage] === undefined) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorRequired',
        path: [`description.${defaultLanguage}`],
      });
    }
  })
  .transform(data => {
    data.description = removeUndefinedLanguages(data.description, data.definedLanguages);
    data.title = removeUndefinedLanguages(data.title, data.definedLanguages);
    return data;
  });

export type CreateDigitalAssetCollectionModalType = z.infer<
  typeof CreateDigitalAssetCollectionModalSchema
>;

const CreateDigitalAssetCollectionModal: React.FC<CreateDigitalAssetCollectionModalProps> = ({
  onClose,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();
  const {t} = useTranslation('pages', {
    keyPrefix: 'digitalAssetCollections.overview.createDigitalAssetCollectionModal',
  });

  const {register, handleSubmit, control} = useForm<CreateDigitalAssetCollectionModalType>({
    resolver: zodResolver(CreateDigitalAssetCollectionModalSchema),
    defaultValues: {
      internalName: '',
      title: {},
      description: {},
      media: null,
      defaultLanguage: merchant.defaultLanguage,
      selectedLanguage: merchant.defaultLanguage,
      definedLanguages: getDefinedLanguages(merchant.defaultLanguage),
      withMaxOwnershipsPerUser: false,
      maxOwnershipsPerUser: null,
      nftEnabled: false,
      nftNonTransferable: false,
    },
  });

  const {field: selectedLanguageField} = useController({
    control,
    name: 'selectedLanguage',
  });
  const selectedLanguage = useWatch({
    control,
    name: 'selectedLanguage',
  });
  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  const withMaxOwnershipsPerUser = useWatch({
    control,
    name: 'withMaxOwnershipsPerUser',
  });

  const {isLoading, mutate: createDigitalAssetCollectionMutation} = useCohortMutation({
    mutationFn: async (data: CreateDigitalAssetCollectionADto) =>
      createDigitalAssetCollection(merchantId, data),
    notifySuccessMessage: t('createSuccessNotification'),
    onSuccess: async newDigitalAsset => {
      await queryClient.invalidateQueries(digitalAssetCollectionsKeys.list(merchantId));
      onClose();
      trackDigitalAssetCollectionCreateSuccess(newDigitalAsset);
    },
  });

  const {isLoading: isUploading, mutateAsync: uploadFile} = useCohortMutation({
    mutationFn: async ({file, assetKind}: {file: File; assetKind: AssetKind}) =>
      uploadAsset(file, assetKind, merchantId),
    onError: err => notifyError(err, t('errorFileUploadFailed')),
  });

  const onSubmit = handleSubmit(
    async data => {
      const asset = await uploadFile({
        file: data.media as unknown as File,
        assetKind: 'digitalAssetVisual',
      });
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (asset === null) {
        return notifyError(t('errorFileUploadFailed'));
      }
      createDigitalAssetCollectionMutation({
        internalName: data.internalName,
        title: data.title,
        description: data.description,
        imageFileKey: isVideoFile(asset.mimeType)
          ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            asset.previewFileKey!
          : asset.fileKey,
        animationFileKey: isVideoFile(asset.mimeType) ? asset.fileKey : null,
        nftEnabled: data.nftEnabled,
        nftNonTransferable: data.nftNonTransferable,
        maxOwnershipsPerUser: withMaxOwnershipsPerUser ? data.maxOwnershipsPerUser : null,
      });
    },
    errors =>
      changeSelectedLanguageIfNeeded(
        errors,
        merchant.defaultLanguage,
        selectedLanguageField.onChange
      )
  );

  return (
    <Dialog open>
      <DialogContent displayCloseButton={false} className="min-w-[750px]">
        <DialogHeader className="space-y-6">
          <Header title={t('create')} subtitle={t('subtitle')} />
          <LanguageSelectorInput
            control={control}
            definedLanguagesPath="definedLanguages"
            selectedLanguagePath="selectedLanguage"
          />
        </DialogHeader>
        <DialogBody>
          {isDefaultLanguageSelected && (
            <Input
              type="text"
              label={t('labelInternalName')}
              name="internalName"
              placeholder={t('placeholderInternalName')}
              register={register}
              control={control}
              description={t('labelInternalNameDescription')}
            />
          )}
          <LocalizedInput
            type="text"
            label={t('labelTitle')}
            name="title"
            placeholder={t('placeholderTitle')}
            register={register}
            control={control}
            selectedLanguage={selectedLanguage}
            optional={!isDefaultLanguageSelected}
          />
          <LocalizedTextEditorInput
            label={t('labelDescription')}
            name="description"
            placeholder={t('placeholderDescription')}
            register={register}
            control={control}
            selectedLanguage={selectedLanguage}
            optional={!isDefaultLanguageSelected}
          />
          {isDefaultLanguageSelected && (
            <Fragment>
              <FileInput
                label={t('labelVisual')}
                name="media"
                register={register}
                control={control}
                acceptHint={t('instructionVisual')}
                accept={AllowedAssetMimeTypes.digitalAssetVisual.options.join(',')}
                assetKind="digitalAssetVisual"
                description={t('helperVisual')}
                withResize
              />
              <Separator />
              <DigitalAssetCollectionNftInput control={control} register={register} />
              <Separator />
              <DigitalAssetCollectionOwnershipsInput control={control} register={register} />
            </Fragment>
          )}
        </DialogBody>
        <DialogFooter>
          <Button variant="secondary" onClick={onClose}>
            {t('buttonCancel')}
          </Button>
          <Button
            data-testid="create-digital-asset-collection-submit-btn"
            onClick={onSubmit}
            loading={isLoading || isUploading}
          >
            {t('buttonCreate')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateDigitalAssetCollectionModal;
