import AdminPage from '@cohort/merchants/components/AdminPage';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import CampaignAudience from '@cohort/merchants/pages/campaigns/campaign/audience/CampaignAudience';
import {Users} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

const CampaignEveryone: React.FC = () => {
  const campaign = useCurrentCampaign();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.audience.campaignEveryone',
  });

  return (
    <AdminPage header={{title: t('title')}}>
      <CampaignAudience />
      <section className="mt-4 flex h-80 flex-col items-center justify-center rounded-md bg-slate-50">
        <Users className="h-10 w-10 text-slate-400" />
        <span className="mt-2 font-medium text-gray-900">
          {campaign.type === 'airdrop' ? t('subtitleAirdrop') : t('subtitle')}
        </span>
      </section>
    </AdminPage>
  );
};

export default CampaignEveryone;
