import type {CampaignADto} from '@cohort/admin-schemas/campaign';
import Button from '@cohort/merchants/components/buttons/Button';
import {CampaignArchivedBadge} from '@cohort/merchants/components/campaigns/CampaignArchivedBadge';
import {CampaignStatusBadge} from '@cohort/merchants/components/campaigns/CampaignStatusBadge';
import CampaignSupplyBadge from '@cohort/merchants/components/campaigns/CampaignSupplyBadge';
import CampaignTypeBadge from '@cohort/merchants/components/campaigns/CampaignTypeBadge';
import CampaignVisual from '@cohort/merchants/components/CampaignVisual';
import {getCampaignEditRoute, getCampaignRoute} from '@cohort/merchants/lib/Pages';
import {trackCampaignCardClicked} from '@cohort/merchants/lib/Tracking';
import CampaignDropdown from '@cohort/merchants/pages/campaigns/overview/CampaignDropdown';
import {formatDate} from '@cohort/shared/utils/format';
import React, {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

interface CampaignCardProps {
  campaign: CampaignADto;
}

const CampaignCardHeader: React.FC<CampaignCardProps> = ({campaign}) => (
  <section className="flex w-full justify-between">
    <div className="flex items-start gap-4">
      <CampaignVisual campaign={campaign} className="rounded-md" size={80} />
      <CampaignStatusBadge status={campaign.status} size="small" />
      {campaign.isArchived && <CampaignArchivedBadge size="small" />}
    </div>
    <CampaignDropdown campaign={campaign} />
  </section>
);

const CampaignCardContent: React.FC<CampaignCardProps> = ({campaign}) => (
  <section className="mt-4 truncate">
    <h3 className="truncate text-lg font-semibold">{campaign.internalName}</h3>
    <div className="mt-1 flex flex-wrap items-center gap-2">
      {campaign.type && <CampaignTypeBadge type={campaign.type} />}
      {campaign.status !== 'draft' && (
        <CampaignSupplyBadge currentSupply={campaign.numOrdered} maxSupply={campaign.totalSupply} />
      )}
    </div>
  </section>
);

const CampaignCardFooter: React.FC<CampaignCardProps> = ({campaign}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'campaigns.overview.campaignCard'});

  return (
    <section className="mt-2">
      {campaign.status === 'draft' ? (
        <div className="mt-2">
          <Button variant="secondary">{t('btnFinishDraft')}</Button>
        </div>
      ) : (
        <Fragment>
          <div data-testid="campaign-date" className="mt-2 text-xs text-slate-500">
            {t('labelLastEdit')} {formatDate(campaign.updatedAt)}
          </div>
        </Fragment>
      )}
    </section>
  );
};

const CampaignCard: React.FC<CampaignCardProps> = ({campaign}) => (
  <li
    key={campaign.id}
    data-testid="campaign-card"
    className="rounded-lg border transition duration-300 ease-in-out md:hover:shadow-md"
  >
    <Link
      className="block h-full w-full"
      to={
        campaign.status === 'draft'
          ? getCampaignEditRoute(campaign.id).path
          : getCampaignRoute(campaign.id).path
      }
      onClick={() => trackCampaignCardClicked(campaign)}
    >
      <div className="grid h-full w-full p-4 [grid-template-rows:min-content_1fr_min-content]">
        <CampaignCardHeader campaign={campaign} />
        <CampaignCardContent campaign={campaign} />
        <CampaignCardFooter campaign={campaign} />
      </div>
    </Link>
  </li>
);

export default CampaignCard;
