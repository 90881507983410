import {BaseCampaignSettingsSchema} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/campaign';
import {z} from 'zod';

export const BaseCampaignAirdropSettingsStepSchema = BaseCampaignSettingsSchema.merge(
  z.object({airdrop: z.object({})})
);

export const CampaignJourneyAirdropStepSchema = BaseCampaignAirdropSettingsStepSchema;

export const DraftCampaignAirdropSettingsStepSchema = BaseCampaignAirdropSettingsStepSchema;
