import AdminPage from '@cohort/merchants/components/AdminPage';
import Filters from '@cohort/merchants/components/filters/Filters';
import {IconBadge} from '@cohort/merchants/components/IconBadge';
import {useJourneyParticipations} from '@cohort/merchants/hooks/api/JourneyParticipations';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCurrentUser} from '@cohort/merchants/hooks/contexts/currentUser';
import {
  statusFilter,
  useUserJourneysFilters,
} from '@cohort/merchants/hooks/filters/userJourneysFilter';
import UserJourneysTable from '@cohort/merchants/pages/users/user/journeys/UserJourneysTable';
import type {JourneyParticipationStatus} from '@cohort/shared/schema/common/journeyParticipations';
import {Flag} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

const PAGE_SIZE = 20;

const UserJourneysPage = (): JSX.Element => {
  const user = useCurrentUser();
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {keyPrefix: 'users.user.journeys.page'});

  const filters = useUserJourneysFilters();

  const {data, isLoading} = useJourneyParticipations(merchant.id, {
    userId: user.id,
    page: filters.page,
    pageSize: PAGE_SIZE,
    statuses: filters.status,
    orderBy: filters.orderBy,
  });

  const [pagination, journeyParticipations] = data ?? [];

  // i18nOwl-ignore [status.completed, status.in-progress]
  const statusFilterOptions = statusFilter.map(status => ({
    value: status,
    label: t(`status.${status}`),
    active: filters.status.includes(status),
  }));

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
    titleBadge: pagination && (
      <IconBadge
        key="journeys"
        icon={<Flag className="mr-1.5 h-4 w-4 text-slate-500" />}
        text={t('labelJourneys', {count: pagination.total})}
      />
    ),
  };

  const paginationConfig = {
    pagination,
    onPageChange: filters.setPage,
  };

  return (
    <AdminPage header={headerConfig} pagination={paginationConfig}>
      <Filters
        filters={[
          {
            label: t('filterByStatus'),
            options: statusFilterOptions,
            type: 'multiple',
            onChange: statusOptions => {
              const activeFilterOptions = statusOptions.filter(o => o.active);
              filters.setStatus(
                activeFilterOptions.map(o => o.value) as JourneyParticipationStatus[]
              );
            },
          },
        ]}
      />
      <UserJourneysTable
        isLoading={isLoading}
        journeyParticipations={journeyParticipations ?? []}
        orderBy={filters.orderBy}
        setOrderBy={filters.setOrderBy}
      />
    </AdminPage>
  );
};
export default UserJourneysPage;
