import GradientToBottom from '@cohort/components-xps/components/GradientToBottom';
import i18nComponentsXpsInstance from '@cohort/components-xps/lib/i18n';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Disclosure} from '@headlessui/react';
import {CaretDown} from '@phosphor-icons/react';
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

type ExpandableDescProps = {
  backgroundColor: string;
  description: string | undefined;
};

const ExpandableDesc: React.FC<ExpandableDescProps> = ({backgroundColor, description}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'expandableDesc',
    lng: i18nComponentsXpsInstance.language,
    i18n: i18nComponentsXpsInstance,
  });
  const refClamp = useRef<HTMLHeadingElement>(null);
  const refFull = useRef<HTMLHeadingElement>(null);

  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  useEffect(() => {
    if (refClamp.current) {
      setScrollHeight(refClamp.current.scrollHeight);
      setClientHeight(refClamp.current.clientHeight);
    }
  }, []);

  if (description === undefined) {
    return <Fragment />;
  }

  return (
    <div className="relative">
      <Disclosure>
        {({open}) => (
          <React.Fragment>
            {open ? (
              <Disclosure.Panel as="div">
                <div
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                  className="prose-sm block max-w-none cursor-text text-base leading-6 focus:outline-none"
                  ref={refFull}
                />
              </Disclosure.Panel>
            ) : (
              <Disclosure.Button className="text-left">
                <div className="relative">
                  {scrollHeight !== clientHeight && (
                    <GradientToBottom
                      backgroundColor={backgroundColor}
                      classes="absolute bottom-0"
                      large
                    />
                  )}
                  <p
                    ref={refClamp}
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                    className="prose-sm block max-h-36 max-w-none cursor-text overflow-y-hidden text-base leading-6 focus:outline-none"
                  />
                </div>
              </Disclosure.Button>
            )}

            {scrollHeight !== clientHeight && (
              <Disclosure.Button className="mt-5 inline-flex w-full items-center rounded bg-transparent px-3 py-2 text-sm font-medium leading-4">
                <CaretDown
                  className={cn(
                    open ? 'rotate-180' : 'rotate-0',
                    ' -ml-1 mr-2 h-5 w-5 transition duration-500 ease-in-out'
                  )}
                  aria-hidden="true"
                />
                {open ? t('readLess') : t('readMore')}
              </Disclosure.Button>
            )}
          </React.Fragment>
        )}
      </Disclosure>
    </div>
  );
};

export default ExpandableDesc;
