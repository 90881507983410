import type {MembershipPassCardProps} from '@cohort/components-xps/components/cards/MembershipPassCard';
import MembershipPassCard from '@cohort/components-xps/components/cards/MembershipPassCard';
import Button from '@cohort/merchants/components/buttons/Button';
import {X} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

type MembershipPassPreviewModalProps = {
  handleOnClose: () => void;
} & MembershipPassCardProps;

const MembershipPassPreviewModal: React.FC<MembershipPassPreviewModalProps> = ({
  handleOnClose,
  ...props
}) => {
  const {t} = useTranslation('components', {keyPrefix: 'previews.membershipPassPreviewPanel'});

  return (
    <div className="fixed left-0 top-0 z-50 flex h-full w-full flex-col items-center bg-slate-50 p-8">
      <div className="flex w-full flex-row items-center justify-between">
        <Button
          variant="secondary"
          data-testid="close-membership-pass-preview"
          onClick={handleOnClose}
        >
          <X size={20} className="-ml-1 mr-2 h-5 w-5" />
          {t('labelPreviewClose')}
        </Button>
      </div>
      <div className="flex flex-grow flex-col justify-center">
        <div id="preview" className="w-[--xps-left-panel-width] rounded-xl p-8">
          <MembershipPassCard {...props} />
        </div>
      </div>
    </div>
  );
};

export default MembershipPassPreviewModal;
