import BreadCrumb from '@cohort/merchants/components/BreadCrumb';
import {usePerkUtils} from '@cohort/merchants/hooks/usePerkUtils';
import {useUrlParams} from '@cohort/merchants/hooks/useUrlParams';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import {trackBreadcrumbBackButtonClicked} from '@cohort/merchants/lib/Tracking';
import AsideMenu from '@cohort/merchants/pages/perks/new/AsideMenu';
import PerkTypeCard from '@cohort/merchants/pages/perks/new/PerkTypeCard';
import type {PerkIntegrationId} from '@cohort/shared/apps';
import type {PerkType} from '@cohort/shared/schema/common/perks';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface SelectPerkTypeProps {
  onTypeSelected: (type: PerkType) => void;
}

const SelectPerkType: React.FC<SelectPerkTypeProps> = ({onTypeSelected}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'perks.new.selectPerkType'});
  const {perkTypesData} = usePerkUtils();
  const {searchParams} = useUrlParams();
  const filter = searchParams.get('integrationId');

  return (
    <PageLayout
      title={t('titleCreatePerk')}
      breadcrumb={
        <BreadCrumb
          onBackClick={() => {
            trackBreadcrumbBackButtonClicked('create-perk');
          }}
          title={t('titleCreatePerk')}
        />
      }
      menu={<AsideMenu filter={filter} />}
    >
      <div>
        <div className="mb-16">
          <h1 className="text-4xl font-bold">{t('labelSelectType')}</h1>
          <p className="mt-5 text-lg font-medium text-slate-400">{t('subtitlePerks')}</p>
        </div>

        <ul
          role="list"
          className="grid gap-6 [grid-template-columns:repeat(auto-fill,minmax(285px,1fr))]"
        >
          {perkTypesData
            .filter(perkTypeData => {
              if (filter === null || filter === '') {
                return true;
              }
              return (
                perkTypeData.perkIntegrations.includes(filter as PerkIntegrationId) &&
                perkTypeData.type !== 'custom'
              );
            })
            .map(perkTypeData => (
              <PerkTypeCard
                key={perkTypeData.type}
                perkTypeData={perkTypeData}
                onClick={onTypeSelected}
              />
            ))}
        </ul>
      </div>
    </PageLayout>
  );
};

export default SelectPerkType;
