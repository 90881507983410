import Button from '@cohort/merchants/components/buttons/Button';
import type {SelectOption} from '@cohort/merchants/components/form/select/SelectPicker';
import {SelectPicker} from '@cohort/merchants/components/form/select/SelectPicker';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@cohort/merchants/components/modals/Dialog';
import useTranslateLanguage from '@cohort/merchants/hooks/useTranslateLanguage';
import type {Language} from '@cohort/shared/schema/common';
import {LANGUAGE_FLAGS} from '@cohort/shared/schema/common';
import {Translate} from '@phosphor-icons/react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

type AddLanguagesModalProps = {
  onClose: () => void;
  onLanguagesAdded: (languages: Array<Language>) => void;
  languageOptions: Language[];
  isLoading?: boolean;
  title?: string;
  subtitle?: string;
  helper?: JSX.Element;
};

export const AddLanguageModal: React.FC<AddLanguagesModalProps> = ({
  languageOptions,
  onClose,
  onLanguagesAdded,
  isLoading,
  title,
  subtitle,
  helper,
}) => {
  const {t} = useTranslation('components', {keyPrefix: 'modals.addLanguageModal'});
  const getLanguageTranslation = useTranslateLanguage();

  const options: Array<SelectOption> = languageOptions.map(language => {
    return {
      value: language,
      label: `${LANGUAGE_FLAGS[language]}  ${getLanguageTranslation(language)}`,
    };
  });
  const [selectedLanguageOptions, setSelectedLanguageOptions] = useState<Array<SelectOption>>(
    options.length === 1 ? options : []
  );

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="[width:500px]">
        <DialogHeader className="space-y-6">
          <div className="flex justify-center">
            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-primary/10 text-primary">
              <Translate className="h-8 w-8" />
            </div>
          </div>
          {title !== undefined && (
            <DialogTitle className="mb-2 mt-5 text-center text-lg font-medium">{title}</DialogTitle>
          )}
          {subtitle !== undefined && (
            <DialogDescription className="w-full text-center text-sm font-normal">
              {subtitle}
            </DialogDescription>
          )}
        </DialogHeader>
        <DialogBody>
          {languageOptions.length === 0 ? (
            <p className="w-full rounded-md bg-slate-50 py-3 text-center text-sm text-slate-500">
              {t('noLanguageToAdd')}
            </p>
          ) : (
            <SelectPicker
              isMulti
              options={options}
              onChange={options => setSelectedLanguageOptions([...options])}
              value={selectedLanguageOptions}
              name="language"
              className="p-1"
            />
          )}
          {helper !== undefined && <div className="w-full">{helper}</div>}
        </DialogBody>
        <DialogFooter className="mt-8 flex w-full items-center gap-4">
          <Button disabled={isLoading} variant="secondary" onClick={onClose} className="w-1/2">
            {t('buttonCancel')}
          </Button>
          <Button
            loading={isLoading}
            onClick={() =>
              onLanguagesAdded(
                selectedLanguageOptions.map(option => option.value) as Array<Language>
              )
            }
            className="w-1/2"
            disabled={languageOptions.length === 0}
          >
            {t('buttonAdd')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
