import type {CampaignADto} from '@cohort/admin-schemas/campaign';
import Button from '@cohort/merchants/components/buttons/Button';
import CardListDisplay from '@cohort/merchants/components/CardListDisplay';
import EmptyState from '@cohort/merchants/components/EmptyState';
import ErrorState from '@cohort/merchants/components/ErrorState';
import LoadingState from '@cohort/merchants/components/LoadingState';
import {useCampaignsDisplay} from '@cohort/merchants/hooks/stores/campaignsDisplay';
import CampaignCard from '@cohort/merchants/pages/campaigns/overview/CampaignCard';
import CampaignListItem from '@cohort/merchants/pages/campaigns/overview/CampaignListItem';
import {Plus} from '@phosphor-icons/react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {match, P} from 'ts-pattern';

interface CampaignsListProps {
  campaigns?: CampaignADto[];
  isLoading: boolean;
  isError: boolean;
  onHandleCreateCampaignClick: () => void;
}

const CampaignsList: React.FC<CampaignsListProps> = ({
  campaigns,
  isLoading,
  isError,
  onHandleCreateCampaignClick,
}) => {
  const display = useCampaignsDisplay(store => store.display);
  const {t} = useTranslation('pages', {keyPrefix: 'campaigns.overview.campaignsList'});

  const campaignsGuard = (campaigns: CampaignADto[] | undefined): campaigns is CampaignADto[] =>
    campaigns !== undefined && campaigns.length > 0;

  const content = match({campaigns, isLoading, isError})
    .with({isLoading: true}, () => <LoadingState />)
    .with({isError: true}, () => <ErrorState />)
    .with(
      {
        campaigns: P.select(P.when(campaignsGuard)),
      },
      campaigns => (
        <CardListDisplay display={display} testId="campaigns-list">
          {campaigns.map(campaign =>
            display === 'card' ? (
              <CampaignCard key={campaign.id} campaign={campaign} />
            ) : (
              <CampaignListItem key={campaign.id} campaign={campaign} />
            )
          )}
        </CardListDisplay>
      )
    )
    .otherwise(() => (
      <EmptyState
        title={t('titleEmpty')}
        description={t('descriptionEmpty')}
        cta={
          <Button onClick={() => onHandleCreateCampaignClick()} data-testid="create-campaign-empty">
            <Plus className="-ml-1 mr-2 h-5 w-5" />
            {t('buttonAddCampaign')}
          </Button>
        }
      />
    ));

  return content;
};

export default CampaignsList;
