import type {PatchMerchantADto} from '@cohort/admin-schemas/merchant';
import Button from '@cohort/merchants/components/buttons/Button';
import Form from '@cohort/merchants/components/form/Form';
import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {merchantsKeys} from '@cohort/merchants/hooks/api/Merchants';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {notify} from '@cohort/merchants/hooks/toast';
import {patchMerchant} from '@cohort/merchants/lib/api/Merchants';
import LinkSection from '@cohort/merchants/pages/settings/integrations/LinkSection';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

const XpsAccessFormSchema = z.object({
  emailSigninEnabled: z.boolean(),
});

const XpsAccessForm: React.FC = () => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {keyPrefix: 'settings.brand.xpsAccessForm'});
  const queryClient = useQueryClient();

  const {isLoading, mutate: updateMerchant} = useCohortMutation({
    mutationFn: async (data: PatchMerchantADto) => patchMerchant(merchant.id, data),
    onSuccess: async () => {
      queryClient.invalidateQueries(merchantsKeys.getById(merchant.id));
      notify('success', t('notificationUpdateSuccess'));
    },
  });

  return (
    <Fragment>
      <LinkSection title={t('title')} description={t('subtitle')} />
      <Form
        testId="xps-access-form"
        formSchema={XpsAccessFormSchema}
        defaultValues={{
          emailSigninEnabled: merchant.emailSigninEnabled,
        }}
        isSubmitting={isLoading}
        onSubmit={data => updateMerchant(data)}
        formActions={[
          <div key="placeholder" />,
          <Button disabled={false} type="submit" key="save">
            {t('saveBtn')}
          </Button>,
        ]}
      >
        {({register, control}) => (
          <div className="mt-4">
            <SwitchInput
              id="emailSigninEnabled"
              name="emailSigninEnabled"
              label={t('labelEmailSigninEnabled')}
              labelPosition="left"
              register={register}
              control={control}
            />
            <div className="my-4">
              <HighlightText type="info" text={t('emailSigninHint')} />
            </div>
          </div>
        )}
      </Form>
    </Fragment>
  );
};

export default XpsAccessForm;
