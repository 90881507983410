import type {WrappedField} from '@cohort/merchants/components/form/FieldWrapper';
import BaseInput from '@cohort/merchants/components/form/input/BaseInput';
import type {FormField} from '@cohort/merchants/lib/form/utils';
import get from 'lodash/get';
import type {FieldValues} from 'react-hook-form';
import {useFormState} from 'react-hook-form';

type InputProps<T extends FieldValues> = Omit<JSX.IntrinsicElements['input'], 'name'> &
  WrappedField &
  FormField<T> & {
    hideError?: boolean;
  };

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function Input<T extends FieldValues>({
  insetPrefix,
  register,
  control,
  label,
  description,
  rules,
  labelPosition,
  hideError,
  ...props
}: InputProps<T>): JSX.Element {
  const {errors} = useFormState({
    control,
    name: props.name,
  });

  return (
    <BaseInput
      insetPrefix={insetPrefix}
      label={label}
      description={description}
      labelPosition={labelPosition}
      error={(!hideError ? get(errors, `${props.name}.message`) : undefined) as string | undefined}
      {...props}
      {...register(props.name, {
        ...rules,
        setValueAs: (value: unknown) => {
          if (props.type === 'number') {
            return value === '' || value === null ? null : parseFloat(value as string);
          }
          return value;
        },
      })}
    />
  );
}
