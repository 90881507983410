import {Badge} from '@cohort/merchants/components/Badge';
import FieldLabel from '@cohort/merchants/components/form/FieldLabel';
import FieldWrapper from '@cohort/merchants/components/form/FieldWrapper';
import Input from '@cohort/merchants/components/form/input/Input';
import RadioCards from '@cohort/merchants/components/form/RadioCards';
import {SelectPicker} from '@cohort/merchants/components/form/select/SelectPicker';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {useStripeConnectAccount} from '@cohort/merchants/hooks/api/Stripe';
import {useTestModeStore} from '@cohort/merchants/hooks/stores/testMode';
import {getPaymentSettingsRoute} from '@cohort/merchants/lib/Pages';
import type {CampaignSettingsStepValues} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import {CountrySpecs, TEST_MODE_STRIPE_CONNECT_COUNTRY} from '@cohort/shared/schema/common/country';
import {CurrencySpecs} from '@cohort/shared/schema/common/currency';
import {Plus, Trash} from '@phosphor-icons/react';
import Big from 'big.js';
import {Fragment} from 'react';
import type {Control, UseFormRegister, UseFormSetValue} from 'react-hook-form';
import {useFieldArray, useWatch} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';

type StorePriceSectionProps = {
  control: Control<CampaignSettingsStepValues>;
  register: UseFormRegister<CampaignSettingsStepValues>;
  setValue: UseFormSetValue<CampaignSettingsStepValues>;
};

const StorePriceSection: React.FC<StorePriceSectionProps> = ({control, register, setValue}) => {
  const testModeEnabled = useTestModeStore(store => store.testModeEnabled);

  const {data: stripeConnectAccount} = useStripeConnectAccount();

  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.settings.store.storePriceSection',
  });

  const {fields, append, remove, update} = useFieldArray({
    name: `store.prices`,
    control,
  });

  const pricing = useWatch({
    control,
    name: 'store.pricing',
  });

  const defaultCurrency = useWatch({
    control,
    name: 'store.defaultCurrency',
  });

  const connectAccount = stripeConnectAccount?.account;

  const connectAccountCountry = testModeEnabled
    ? TEST_MODE_STRIPE_CONNECT_COUNTRY
    : connectAccount?.country ?? null;

  const countrySpecs =
    connectAccountCountry === null ? null : CountrySpecs.get(connectAccountCountry);

  const supportedCurrencies = countrySpecs?.stripeAcceptedCurrencies ?? [];

  const paidCampaignDisabled =
    supportedCurrencies.length === 0 || (!testModeEnabled && connectAccount?.ready !== true);

  const currencyOptions = supportedCurrencies.map(currency => ({
    label: currency.toUpperCase(),
    value: currency,
  }));

  const remainingCurrencyOptions = currencyOptions.filter(option => {
    return !fields.some(price => price.currency === option.value);
  });

  return (
    <Fragment>
      <RadioCards
        name="store.pricing"
        direction="row"
        label={t('labelPricing')}
        register={register}
        control={control}
        onChange={e => {
          if (e.target.value === 'free') {
            setValue('store.defaultCurrency', null);
            setValue('store.prices', []);
          }
          if (e.target.value === 'paid') {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const price = {currency: supportedCurrencies[0]!, amount: 0};
            setValue('store.defaultCurrency', price.currency);
            setValue('store.prices', [price]);
          }
        }}
        options={[
          {
            label: t('labelFree'),
            description: t('descriptionFree'),
            value: 'free',
          },
          {
            label: t('labelPaid'),
            description: t('descriptionPaid'),
            value: 'paid',
            disabled: paidCampaignDisabled,
            suffix: paidCampaignDisabled && (
              <span className="pl-3 text-left text-sm font-medium leading-5 text-primary">
                {t('setupPaymentRequired')}
              </span>
            ),
          },
        ]}
      />
      {paidCampaignDisabled && connectAccount?.ready !== true && (
        <HighlightText
          type="warning"
          text={
            <p>
              <Trans
                i18nKey="campaigns.campaign.edit.settings.store.storePriceSection.setupPaymentInstructions"
                ns="pages"
                components={{
                  setupLink: (
                    <a
                      href={getPaymentSettingsRoute().path}
                      className="cursor-pointer font-semibold text-primary"
                    />
                  ),
                }}
              />
            </p>
          }
        />
      )}
      {connectAccountCountry !== null && supportedCurrencies.length === 0 && (
        <HighlightText
          type="warning"
          text={
            <p>
              <Trans
                i18nKey="campaigns.campaign.edit.settings.store.storePriceSection.setupCountryRequired"
                ns="pages"
                values={{country: connectAccountCountry}}
              />
            </p>
          }
        />
      )}
      {pricing === 'paid' && (
        <FieldWrapper name="store.sdf">
          <FieldLabel label={t('labelAmount')} name="store.prices" />
          <div className="grid max-w-[600px] place-items-center items-start gap-3 [grid-template-columns:20px_1fr_2fr_1fr]">
            {fields.map((price, index) => {
              const currencySpec = CurrencySpecs.getOrThrow(price.currency);
              const currencyOption = {value: price.currency, label: price.currency.toUpperCase()};
              return (
                <Fragment key={price.currency}>
                  <div className="mt-[10px] text-primary">
                    <Input
                      className="rounded-full"
                      type="radio"
                      name="store.defaultCurrency"
                      value={price.currency}
                      control={control}
                      register={register}
                    />
                  </div>
                  <SelectPicker
                    options={[currencyOption, ...remainingCurrencyOptions]}
                    value={currencyOption}
                    onChange={option => {
                      if (option !== null) {
                        update(index, {...price, currency: option.value});
                        if (defaultCurrency === price.currency) {
                          setValue('store.defaultCurrency', option.value);
                        }
                      }
                    }}
                    name={`store.prices.${index}.currency`}
                  />
                  <Input
                    insetPrefix={{
                      component: (
                        <div className="pointer-events-none absolute inset-y-0 left-0 mb-px ml-px mt-px flex w-9 items-center rounded-bl-md rounded-tl-md border-r bg-slate-50 px-3 text-sm text-slate-500">
                          {currencySpec.symbol}
                        </div>
                      ),
                      size: 40,
                    }}
                    type="number"
                    step={Number(Big(1).div(Big(10).pow(currencySpec.decimals)).toString())}
                    name={`store.prices.${index}.amount`}
                    control={control}
                    register={register}
                  />
                  <div className="mt-2 justify-self-start">
                    {defaultCurrency === price.currency ? (
                      <Badge
                        text={t('labelDefault')}
                        textColor="text-primary"
                        backgroundColor="bg-primary/10"
                        size="small"
                      />
                    ) : (
                      <button
                        type="button"
                        className="relative inline-flex items-center rounded-md bg-white text-sm font-medium text-red-400 hover:bg-slate-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary"
                        onClick={() => remove(index)}
                      >
                        <Trash className="h-5 w-5" />
                      </button>
                    )}
                  </div>
                </Fragment>
              );
            })}
          </div>
          <div className="ml-7 mt-[10px]">
            {remainingCurrencyOptions.length > 0 && (
              <button
                type="button"
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  append({currency: remainingCurrencyOptions[0]!.value, amount: 0});
                }}
              >
                <span className="text-sm font-medium text-primary">
                  <Plus className="inline h-4 w-4" /> {t('buttonAddCurrency')}
                </span>
              </button>
            )}
          </div>
        </FieldWrapper>
      )}
    </Fragment>
  );
};

export default StorePriceSection;
