import {fileValidatorOptional} from '@cohort/merchants/lib/form/validators';
import {LanguageSchema} from '@cohort/shared/schema/common';
import {CampaignTypeSchema, LocalizedFaqsSchema} from '@cohort/shared/schema/common/campaign';
import {z} from 'zod';

// this cannot be placed under common.ts file due to a circular dependency
export const BaseCampaignSettingsSchema = z.object({
  campaignType: CampaignTypeSchema.nullable(),
  // Computed field, need to be optional for automatic step validation
  withMaxSupply: z.boolean().default(true),
  totalSupply: z.number().nullable(),
  tosFileKey: z.any().refine(fileValidatorOptional),
  faqs: LocalizedFaqsSchema,
  // Computed field, need to be optional for automatic step validation
  defaultLanguage: LanguageSchema.optional(),
  selectedLanguage: LanguageSchema.optional(),
  definedLanguages: z.array(LanguageSchema).optional(),
});
