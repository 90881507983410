import Button from '@cohort/merchants/components/buttons/Button';
import Input from '@cohort/merchants/components/form/input/Input';
import RadioCards from '@cohort/merchants/components/form/RadioCards';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@cohort/merchants/components/modals/Dialog';
import {cohortsKeys} from '@cohort/merchants/hooks/api/Cohorts';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {createCohort} from '@cohort/merchants/lib/api/Cohorts';
import {getCohortMembersRoute} from '@cohort/merchants/lib/Pages';
import type {CohortType} from '@cohort/shared/schema/common/cohort';
import {CohortTypeSchema} from '@cohort/shared/schema/common/cohort';
import {zodResolver} from '@hookform/resolvers/zod';
import {Lightning, ListBullets} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {z} from 'zod';

interface CreateCohortModalProps {
  onClose: () => void;
  redirectAfterCreate?: boolean;
}

export const CohortFormSchema = z.object({
  name: z.string().min(3, {message: 'errorTooShort3'}),
  type: CohortTypeSchema,
});
type CohortFormValues = z.infer<typeof CohortFormSchema>;

const CreateCohortModal: React.FC<CreateCohortModalProps> = ({
  onClose,
  redirectAfterCreate = false,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {t} = useTranslation('components', {keyPrefix: 'modals.createCohortModal'});

  const {register, handleSubmit, control} = useForm<CohortFormValues>({
    resolver: zodResolver(CohortFormSchema),
    defaultValues: {
      name: t('placeholderName'),
      type: 'static' as CohortType,
    },
  });

  const {isLoading, mutate: createCohortMutation} = useCohortMutation({
    mutationFn: async (data: CohortFormValues) => createCohort(merchantId, data),
    onSuccess: async newCohort => {
      await queryClient.invalidateQueries(cohortsKeys.list(merchantId));
      if (redirectAfterCreate) {
        navigate(getCohortMembersRoute(newCohort.id).path);
      }
      onClose();
    },
  });

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="[width:600px]">
        <form onSubmit={handleSubmit(data => createCohortMutation(data))}>
          <DialogHeader>
            <DialogTitle className="text-lg">{t('create')}</DialogTitle>
          </DialogHeader>
          <DialogBody className="space-y-6">
            <Input
              label={t('labelName')}
              type="text"
              name="name"
              register={register}
              control={control}
            />
            <RadioCards
              name="type"
              alignment="items-start"
              direction="row"
              label={t('labelType')}
              register={register}
              control={control}
              options={[
                {
                  label: t('labelStatic'),
                  description: <div className="w-32">{t('descriptionStatic')}</div>,
                  value: 'static',
                  prefix: (
                    <ListBullets
                      width={40}
                      height={40}
                      className="rounded-md bg-slate-50 p-2 text-muted-foreground"
                    />
                  ),
                },
                {
                  label: t('labelDynamic'),
                  description: <div className="w-32">{t('descriptionDynamic')}</div>,
                  value: 'dynamic',
                  prefix: (
                    <Lightning
                      width={40}
                      height={40}
                      className="rounded-md bg-slate-50 p-2 text-muted-foreground"
                    />
                  ),
                },
              ]}
            />
          </DialogBody>
          <DialogFooter>
            <Button variant="secondary" onClick={onClose}>
              {t('buttonCancel')}
            </Button>
            <Button type="submit" disabled={false} loading={isLoading}>
              {t('buttonCreate')}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateCohortModal;
