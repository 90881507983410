import type {DigitalAssetCollectionADto} from '@cohort/admin-schemas/digitalAssetCollection';
import type {PerkADto} from '@cohort/admin-schemas/perk';
import PreviewFaqs from '@cohort/components-xps/components/CampaignFaqs';
import ExpandableDesc from '@cohort/components-xps/components/ExpandableDesc';
import RewardList from '@cohort/components-xps/components/rewards/RewardList';
import SectionTitle from '@cohort/components-xps/components/SectionTitle';
import Footer from '@cohort/merchants/components/campaigns/preview/store/Footer';
import MobileNavigation from '@cohort/merchants/components/campaigns/preview/store/MobileNavigation';
import {useCustomComponents} from '@cohort/merchants/hooks/api/CustomComponents';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {getCustomComponent, getUserContext} from '@cohort/merchants/lib/CustomComponents';
import type {
  CampaignStorePreviewSection,
  MobileNavigationItem,
} from '@cohort/merchants/lib/StorePreview';
import type {Language} from '@cohort/shared/schema/common';
import type {FaqItem} from '@cohort/shared/schema/common/campaign';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Element as ScrollElement, Events, scrollSpy} from 'react-scroll';

const MOBILE_CONTENT_PADDING_CLASSES = 'px-4 py-5';

type DescriptionSectionProps = {
  backgroundColor: string;
  sectionName?: string;
  description: string;
  isMobile: boolean;
  selectedLanguage: Language;
};

const DescriptionSection: React.FC<DescriptionSectionProps> = ({
  backgroundColor,
  sectionName,
  description,
  isMobile,
  selectedLanguage,
}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'previews.CampaignStorePreview',
    lng: selectedLanguage,
  });

  return (
    <ScrollElement name={sectionName ?? 'description'} className={cn('mt-5', !isMobile && 'mt-0')}>
      <SectionTitle content={t('sectionDescription')} isMobile={isMobile} />

      <div className={cn(isMobile && MOBILE_CONTENT_PADDING_CLASSES)}>
        <ExpandableDesc backgroundColor={backgroundColor} description={description} />
      </div>
    </ScrollElement>
  );
};

type PerksProps = {
  sectionName?: string;
  perks?: PerkADto[];
  collections?: DigitalAssetCollectionADto[];
  isMobile: boolean;
  selectedLanguage: Language;
};

const Rewards: React.FC<PerksProps> = ({
  sectionName,
  perks,
  collections,
  isMobile,
  selectedLanguage,
}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('components', {
    keyPrefix: 'previews.CampaignStorePreview',
    lng: selectedLanguage,
  });
  const {data: components} = useCustomComponents(merchant.id);
  const perkCustomComponent = getCustomComponent(components, 'perk', true);
  const digitalAssetCustomComponent = getCustomComponent(components, 'digital-asset', true);

  const collectionsInput = collections?.map(collection => ({
    id: collection.id,
    title: collection.title[selectedLanguage] ?? '',
    description: collection.description[selectedLanguage] ?? '',
    smartContract: collection.smartContract,
    imageKey: collection.imageFileKey,
    animationKey: collection.animationFileKey,
    maxOwnershipsPerUser: collection.maxOwnershipsPerUser,
  }));

  const perksInput = perks?.map(perk => ({
    id: perk.id,
    description: perk.description[selectedLanguage] ?? '',
    displayName: perk.displayName[selectedLanguage] ?? '',
    videoFileKey: perk.videoFileKey,
    thumbnailFileKey: perk.thumbnailFileKey,
    bannerFileKey: perk.bannerFileKey,
    maxAccessesPerUser: perk.maxAccessesPerUser,
    integration: perk.integration,
    type: perk.type,
  }));
  const customComponentContext = {
    user: getUserContext(merchant),
    lang: selectedLanguage,
    isMobile,
  };

  return (
    <ScrollElement
      className={cn('z-10 mt-5', !isMobile && 'mt-10', perks?.length === 0 && 'hidden')}
      name={sectionName ?? 'perks'}
    >
      <SectionTitle content={t('sectionRewards')} isMobile={isMobile} />
      <div className={cn(isMobile && MOBILE_CONTENT_PADDING_CLASSES)}>
        <RewardList
          perks={perksInput ?? []}
          collections={collectionsInput ?? []}
          isMobile={isMobile}
          customComponent={{
            perk: perkCustomComponent?.template ?? undefined,
            digitalAsset: digitalAssetCustomComponent?.template ?? undefined,
            context: customComponentContext,
          }}
        />
      </div>
    </ScrollElement>
  );
};

type FaqProps = {
  sectionName?: string;
  isMobile: boolean;
  faqs: FaqItem[];
  supportEmail?: string;
  selectedLanguage: Language;
};

const Faq: React.FC<FaqProps> = ({sectionName, isMobile, faqs, supportEmail, selectedLanguage}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'previews.CampaignStorePreview',
    lng: selectedLanguage,
  });

  return (
    <ScrollElement
      className={cn(
        'mt-5 flex flex-col pb-8',
        !isMobile && 'mt-10',
        faqs.length === 0 && supportEmail === undefined && 'hidden'
      )}
      name={sectionName ?? 'faq'}
    >
      <SectionTitle content={t('sectionQuestions')} isMobile={isMobile} />

      <div className={cn(isMobile && MOBILE_CONTENT_PADDING_CLASSES)}>
        <PreviewFaqs faqs={faqs} supportEmail={supportEmail ?? null} />
      </div>
    </ScrollElement>
  );
};

type ContentPanelProps = {
  isMobile: boolean;
  accentColor: string;
  backgroundColor: string;
  description?: string;
  perks?: PerkADto[];
  collections?: DigitalAssetCollectionADto[];
  showGetButton: boolean;
  faqs: FaqItem[];
  supportEmail?: string;
  children: React.ReactNode;
  initialSection?: CampaignStorePreviewSection;
  selectedLanguage: Language;
};

const ContentPanel: React.FC<ContentPanelProps> = ({
  isMobile,
  backgroundColor,
  accentColor,
  description,
  perks,
  collections,
  showGetButton,
  faqs,
  supportEmail,
  children,
  selectedLanguage,
}) => {
  const [selectedTab, setSelectedTab] = useState<CampaignStorePreviewSection>('description');

  const {t} = useTranslation('components', {
    keyPrefix: 'previews.CampaignStorePreview',
    lng: selectedLanguage,
  });

  useEffect(() => {
    Events.scrollEvent.register('end', to => {
      setSelectedTab(to as CampaignStorePreviewSection);
    });

    scrollSpy.update();
  });

  const mobileNavigationItems: MobileNavigationItem[] = useMemo(
    () => [
      {
        name: 'description',
        navigationTitle: t('linkDescription'),
        sectionTitle: t('sectionDescription'),
      },
      {
        name: 'rewards',
        navigationTitle: t('linkRewards'),
        sectionTitle: t('sectionRewards'),
      } as MobileNavigationItem,
      ...(supportEmail !== undefined || faqs.length > 0
        ? [
            {
              name: 'faq',
              navigationTitle: t('linkQuestions'),
              sectionTitle: t('sectionQuestions'),
            } as MobileNavigationItem,
          ]
        : []),
    ],
    [faqs, supportEmail, t]
  );

  return (
    <div className="flex flex-col">
      {/* Nav menu on mobile only */}
      {isMobile && (
        <MobileNavigation
          mobileNavigationItems={mobileNavigationItems}
          accentColor={accentColor}
          selectedTab={selectedTab}
          onTabChanged={setSelectedTab}
        />
      )}

      <div className="gap-10">
        {/* Description */}
        {description && description.length > 0 && (
          <DescriptionSection
            backgroundColor={backgroundColor}
            sectionName={mobileNavigationItems[0]?.name}
            description={description}
            isMobile={isMobile}
            selectedLanguage={selectedLanguage}
          />
        )}

        <Rewards
          sectionName={mobileNavigationItems[1]?.name}
          perks={perks}
          collections={collections}
          isMobile={isMobile}
          selectedLanguage={selectedLanguage}
        />

        {/* FAQ */}
        {(faqs.length > 0 || supportEmail !== '') && (
          <Faq
            sectionName={mobileNavigationItems[2]?.name}
            isMobile={isMobile}
            faqs={faqs}
            supportEmail={supportEmail}
            selectedLanguage={selectedLanguage}
          />
        )}

        <Footer selectedLanguage={selectedLanguage} isMobile={isMobile} />
      </div>
      <div
        className={cn(
          'sticky bottom-0 z-20 flex flex-col opacity-0 transition duration-500 ease-in-out',
          showGetButton === true ? '!opacity-100' : 'pointer-events-none',
          !isMobile && 'hidden'
        )}
      >
        <div className="p-5" style={{backgroundColor}}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ContentPanel;
