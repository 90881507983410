import type {SpotifyFollowUserVerificationAttemptData} from '@cohort/shared/apps/spotify/triggers/followUser';
import {Trans} from 'react-i18next';

const SpotifyFollowUserTriggerStepCompletionTitleComponent: React.FC<{
  data: SpotifyFollowUserVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  if (!completionSuccess) {
    return (
      <Trans
        i18nKey="triggerIntegrations.follow-user.stepCompletionTitleComponent.error"
        ns="app-spotify"
      />
    );
  }

  return (
    <Trans
      i18nKey="triggerIntegrations.follow-user.stepCompletionTitleComponent.success"
      ns="app-spotify"
      values={{username: data.userName}}
      components={{
        a: (
          <a
            className="font-semibold text-gray-900"
            target="_blank"
            rel="noopener noreferrer nofollow"
            href={data.userUrl}
          />
        ),
      }}
    />
  );
};

export default SpotifyFollowUserTriggerStepCompletionTitleComponent;
