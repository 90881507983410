import type {App} from '@cohort/merchants/apps';
import {getAppsWithNotificationIntegrations, getUserEvents} from '@cohort/merchants/apps';
import AdminPage from '@cohort/merchants/components/AdminPage';
import type {SelectOption} from '@cohort/merchants/components/form/select/SelectPicker';
import {SelectPicker} from '@cohort/merchants/components/form/select/SelectPicker';
import NotificationsSettingsList from '@cohort/merchants/components/notifications/NotificationsSettingsList';
import SectionSeparator from '@cohort/merchants/components/SectionSeparator';
import type {AppId, EventId} from '@cohort/shared/apps';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

const NotificationsSettingsPage: React.FC = () => {
  const appsWithNotificationIntegrations = getAppsWithNotificationIntegrations();
  const cohortApp = appsWithNotificationIntegrations.find(app => app.spec.id === 'cohort');

  if (cohortApp === undefined) {
    throw new Error('Never happens: Cohort app not found in apps with notification integrations');
  }
  // @Devs - Cohort app must be selected by default.
  const [selectedApp, setSelectedApp] = useState<App>(cohortApp);
  const {t} = useTranslation('pages', {keyPrefix: 'settings.notifications.page'});

  const selectPickerOptions = appsWithNotificationIntegrations.map(app => ({
    value: app.spec.id,
    label: app.spec.name,
  }));

  const formatOptionLabel = (data: SelectOption): JSX.Element => {
    const {label, value} = data;

    return (
      <div className="flex items-center space-x-2">
        <SvgAppIcon name={value as AppId} height={20} width={20} />
        <div>{label}</div>
      </div>
    );
  };

  const userEvents = getUserEvents(selectedApp.spec.id as AppId);
  const userEventIds = userEvents.map(userEvent => userEvent.spec.id as EventId);

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
  };

  return (
    <AdminPage header={headerConfig}>
      <SelectPicker
        className="w-1/4"
        value={{value: selectedApp.spec.id, label: selectedApp.spec.name}}
        label={t('labelUserEventsFrom')}
        options={selectPickerOptions}
        onChange={option => {
          const app = appsWithNotificationIntegrations.find(app => app.spec.id === option?.value);
          if (app === undefined) {
            throw new Error('Never happens: Selected app not found');
          }
          setSelectedApp(app);
        }}
        name="triggerIntegrationConfig.metric"
        formatOptionLabel={formatOptionLabel}
      />
      <SectionSeparator />
      <p className="font-semibold">{t('whenAUserHas')}</p>

      <div className="ml-4">
        <NotificationsSettingsList userEventIds={userEventIds} />
      </div>
    </AdminPage>
  );
};

export default NotificationsSettingsPage;
