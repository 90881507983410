import {useApps} from '@cohort/merchants/apps/useApps';
import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import EmptyState from '@cohort/merchants/components/EmptyState';
import ErrorState from '@cohort/merchants/components/ErrorState';
import {IconBadge} from '@cohort/merchants/components/IconBadge';
import LoadingState from '@cohort/merchants/components/LoadingState';
import {useConnections} from '@cohort/merchants/hooks/api/Connections';
import {useCurrentApp} from '@cohort/merchants/hooks/contexts/currentApp';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import ConnectionsTable from '@cohort/merchants/pages/apps/app/connections/ConnectionsTable';
import EditConnectionModal from '@cohort/merchants/pages/connections/new/EditConnectionModal';
import type {AppId, MerchantConnectorId} from '@cohort/shared/apps';
import {Link} from '@phosphor-icons/react';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const AppConnectionsPage: React.FC = () => {
  const {appId, appSpec} = useCurrentApp();
  const {t} = useTranslation('pages', {keyPrefix: 'apps.app.connections.page'});
  const [showConnectionModal, setShowConnectionModal] = useState(false);
  const merchant = useCurrentMerchant();

  const {appDisabled, getAppDescription} = useApps();

  const hideCreateConnectionButton = appDisabled(appSpec.id as AppId);

  const merchantConnectorId = appSpec.merchantConnector.id as MerchantConnectorId;

  const {
    data: merchantConnections,
    isLoading: isConnectionsLoading,
    isError,
  } = useConnections(merchant.id, {
    connectorId: [merchantConnectorId],
  });

  const connectionsCount = merchantConnections?.length ?? 0;

  const pageContent: JSX.Element = match({
    merchantConnections,
    isConnectionsLoading,
    isError,
    appSpec,
    merchantConnectorId,
  })
    .with({isConnectionsLoading: true}, () => {
      return <LoadingState />;
    })
    .with({isError: true}, () => <ErrorState />)
    .with(
      {merchantConnectorId: undefined},
      {merchantConnections: undefined},
      {merchantConnections: []},
      () => {
        return (
          <Fragment>
            <EmptyState title={t('titleEmpty')} description={t('descriptionEmpty')} />
            {showConnectionModal && (
              <EditConnectionModal onClose={() => setShowConnectionModal(false)} appId={appId} />
            )}
          </Fragment>
        );
      }
    )
    .otherwise(() => {
      return (
        <div className="space-y-8">
          <ConnectionsTable
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            merchantConnections={merchantConnections!}
            isConnectionsLoading={isConnectionsLoading}
            appSpec={appSpec}
          />
          {showConnectionModal && (
            <EditConnectionModal onClose={() => setShowConnectionModal(false)} appId={appId} />
          )}
        </div>
      );
    });

  const headerConfig = {
    title: t('title'),
    subtitle: getAppDescription(appSpec),
    titleBadge: (
      <IconBadge
        key="syncs-count"
        icon={<Link className="mr-1.5 h-4 w-4 text-slate-500" />}
        text={t('connectionsCount', {count: connectionsCount})}
      />
    ),
    topRightElements: !hideCreateConnectionButton && (
      <Button key="addConnection" onClick={() => setShowConnectionModal(true)}>
        {t('addConnectionButton')}
      </Button>
    ),
  };

  return <AdminPage header={headerConfig}>{pageContent}</AdminPage>;
};

export default AppConnectionsPage;
