import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import {CohortTypeBadge} from '@cohort/merchants/components/cohorts/CohortTypeBadge';
import {IconBadge} from '@cohort/merchants/components/IconBadge';
import Loader from '@cohort/merchants/components/Loader';
import {useCohort} from '@cohort/merchants/hooks/api/Cohorts';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {getCohortMembersRoute} from '@cohort/merchants/lib/Pages';
import CampaignAudience from '@cohort/merchants/pages/campaigns/campaign/audience/CampaignAudience';
import {ArrowCircleRight, Users} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const CampaignCohort = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const campaign = useCurrentCampaign();
  const navigate = useNavigate();

  const {t} = useTranslation('pages', {keyPrefix: 'campaigns.campaign.audience.campaignCohort'});

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const {data: cohort, isLoading} = useCohort(merchantId, campaign.cohort!.id);

  return (
    <AdminPage header={{title: t('title')}}>
      <CampaignAudience />
      {isLoading && (
        <div className="flex justify-center">
          <Loader size={30} color="gray" />
        </div>
      )}
      {cohort !== undefined && (
        <div className="mt-4 flex flex-1 items-center justify-between gap-6 border-y-2 border-slate-100 py-4">
          <section className="flex  items-center truncate">
            <p className="truncate text-sm font-medium">{cohort.name}</p>
          </section>
          <section className="flex items-center gap-4 truncate">
            <IconBadge
              key="users-badge"
              icon={<Users className="mr-1.5 h-4 w-4 text-slate-500" />}
              backgroundColor="bg-gray-100"
              text={`${cohort.usersCount ?? 0}`}
              size="small"
            />
            <CohortTypeBadge key="type-badge" type={cohort.type} size="small" />
            <Button
              variant="secondary"
              className="ml-6 !px-2"
              onClick={() => navigate(getCohortMembersRoute(cohort.id).path)}
            >
              <ArrowCircleRight className="h-6 w-6 text-slate-500" />
            </Button>
          </section>
        </div>
      )}
    </AdminPage>
  );
};

export default CampaignCohort;
