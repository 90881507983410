import type {NotificationConfigurationADto} from '@cohort/admin-schemas/notification';
import RadioCards from '@cohort/merchants/components/form/RadioCards';
import HighlightText from '@cohort/merchants/components/HighlightText';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import NotificationConfigurationCard from '@cohort/merchants/components/notifications/NotificationConfigurationCard';
import NotificationSettingsListCreateBtn from '@cohort/merchants/components/notifications/NotificationSettingsListCreateBtn';
import {notificationConfigurationsKeys} from '@cohort/merchants/hooks/api/Notifications';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {notify} from '@cohort/merchants/hooks/toast';
import {deleteNotificationConfigurations} from '@cohort/merchants/lib/api/Notifications';
import {getNotificationsSettingsRoute} from '@cohort/merchants/lib/Pages';
import type {EventId} from '@cohort/shared/apps';
import type {NotificationConfigurationResourceType} from '@cohort/shared/schema/common/notificationConfiguration';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {z} from 'zod';

type NotificationResourceSettingsListRowBodyProps = {
  notificationConfigurations: Array<NotificationConfigurationADto>;
  userEventId: EventId;
  resource: {
    id: string;
    type: NotificationConfigurationResourceType;
  };
};

const NotificationResourceSettingsRadioCardsFormSchema = z.object({
  selectedRadio: z.enum(['global', 'override']),
});
type NotificationResourceSettingsRadioCardsFormValues = z.infer<
  typeof NotificationResourceSettingsRadioCardsFormSchema
>;

const NotificationResourceSettingsListRowBody: React.FC<
  NotificationResourceSettingsListRowBodyProps
> = ({notificationConfigurations, resource, userEventId}) => {
  const [showChangeDefaultSettingsDangerModal, setShowChangeDefaultSettingsDangerModal] =
    useState(false);
  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.notifications.notificationResourceSettingsListRowBody',
  });
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();

  const overrideNotificationConfigurations = notificationConfigurations.filter(
    nc => nc.resourceType === resource.type && nc.resourceId === resource.id
  );

  const {register, control, watch, setValue} =
    useForm<NotificationResourceSettingsRadioCardsFormValues>({
      defaultValues: {
        selectedRadio: overrideNotificationConfigurations.length > 0 ? 'override' : 'global',
      },
      resolver: zodResolver(NotificationResourceSettingsRadioCardsFormSchema),
    });

  const selectedRadio = watch('selectedRadio');

  const handleBackToDefaultSettings = (): void => {
    setShowChangeDefaultSettingsDangerModal(false);
    deleteNotificationConfigurationsMutation(overrideNotificationConfigurations.map(c => c.id));
    setValue('selectedRadio', 'global');
  };

  const {mutate: deleteNotificationConfigurationsMutation} = useCohortMutation({
    mutationFn: async (notificationConfigurationIds: Array<string>) =>
      deleteNotificationConfigurations(merchant.id, {notificationConfigurationIds}),
    onSuccess: () => {
      queryClient.invalidateQueries(notificationConfigurationsKeys.list(merchant.id));
    },
    notifySuccessMessage: t('successfullDeletionMessage'),
    onError: () => {
      notify('error', t('errorDeletionMessage'));
    },
  });

  return (
    <div className="space-y-4">
      <div>
        <RadioCards
          name="selectedRadio"
          direction="row"
          register={register}
          control={control}
          onClick={e => {
            if (
              e.currentTarget.value === 'global' &&
              overrideNotificationConfigurations.length > 0
            ) {
              setShowChangeDefaultSettingsDangerModal(true);
            }
          }}
          options={[
            {
              label: t('labelDefault'),
              value: 'global',
            },
            {
              label: t('labelOverride'),
              value: 'override',
            },
          ]}
        />
      </div>

      <Fragment>
        {notificationConfigurations.map(notificationConfiguration => {
          if (notificationConfiguration.resourceType === null && selectedRadio === 'override') {
            return;
          }

          return (
            <div className="mt-2" key={notificationConfiguration.id}>
              <NotificationConfigurationCard
                notificationConfiguration={notificationConfiguration}
                showActionsBtns={notificationConfiguration.resourceType !== null}
                resource={resource}
                disabled={false}
              />
            </div>
          );
        })}
      </Fragment>

      {selectedRadio === 'override' && (
        <Fragment>
          <NotificationSettingsListCreateBtn userEventId={userEventId} resource={resource} />

          <HighlightText
            text={
              <Trans
                i18nKey="settings.notifications.notificationResourceSettingsListRowBody.overrideSettings"
                ns="pages"
                components={{
                  notificationsSettingsLink: (
                    <Link
                      className="cursor-pointer underline"
                      to={getNotificationsSettingsRoute().path}
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                }}
              />
            }
            type="info"
          />
        </Fragment>
      )}

      {showChangeDefaultSettingsDangerModal && (
        <DeletionModal
          title={t('labelChangeDefaultSettingsDangerModalTitle')}
          subtitle={t('labelChangeDefaultSettingsDangerModalContent')}
          show={showChangeDefaultSettingsDangerModal}
          onClose={() => {
            setShowChangeDefaultSettingsDangerModal(false);
            setValue('selectedRadio', 'override');
          }}
          onDelete={() => handleBackToDefaultSettings()}
        />
      )}
    </div>
  );
};

export default NotificationResourceSettingsListRowBody;
