import type {GetPerksParamsADto, PerksOrderByADto} from '@cohort/admin-schemas/perk';
import type {PaginationFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import {useBasePageFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import type {PerkIntegrationId} from '@cohort/shared/apps';
import {PerkIntegrationIdSchema} from '@cohort/shared/apps';
import type {PerkType} from '@cohort/shared/schema/common/perks';
import {PerkTypeSchema} from '@cohort/shared/schema/common/perks';
import {useState} from 'react';

export const statusFilter = ['live', 'draft', 'archived'] as const;
export const sortFilter = ['-updatedAt', '-createdAt', 'internalName', 'type'] as const;
export const typeFilter = PerkTypeSchema.options as PerkType[];
export const integrationFilter = PerkIntegrationIdSchema.options as PerkIntegrationId[];

export type PerksListFilters = {
  status: GetPerksParamsADto['status'];
  setStatus: (status: GetPerksParamsADto['status']) => void;
  integrations: GetPerksParamsADto['integration'];
  setIntegrations: (integrations: GetPerksParamsADto['integration']) => void;
  types: GetPerksParamsADto['type'];
  setTypes: (types: GetPerksParamsADto['type']) => void;
  sort: PerksOrderByADto;
  setSort: (sort: PerksOrderByADto) => void;
  reset: () => void;
};

const useStateFilters = (): PaginationFilters & PerksListFilters => {
  const [page, setPage] = useState<number>(1);
  const [status, setStatus] = useState<GetPerksParamsADto['status']>(['live', 'draft']);
  const [integrations, setIntegrations] = useState<GetPerksParamsADto['integration']>();
  const [types, setTypes] = useState<GetPerksParamsADto['type']>();
  const [sort, setSort] = useState<PerksOrderByADto>('-updatedAt');
  const reset = (): void => {
    setPage(1);
    setStatus(['live', 'draft']);
    setIntegrations(undefined);
    setSort('-updatedAt');
  };
  return {
    page,
    setPage,
    status,
    setStatus: status => {
      setStatus(status);
      setPage(1);
    },
    integrations,
    setIntegrations: integrations => {
      setIntegrations(integrations);
      setPage(1);
    },
    types,
    setTypes: types => {
      setTypes(types);
      setPage(1);
    },
    sort,
    setSort,
    reset,
  };
};

// pagePath is a param because perk filters are used at multiple places
export const usePerksListFilters = (pagePath?: RegExp): PaginationFilters & PerksListFilters =>
  pagePath === undefined
    ? useStateFilters()
    : useBasePageFilters<PerksListFilters>(pagePath, set => ({
        status: ['live', 'draft'],
        setStatus: status => {
          set({status, page: 1});
        },
        integrations: undefined,
        setIntegrations: integrations => {
          set({integrations, page: 1});
        },
        types: undefined,
        setTypes: types => {
          set({types, page: 1});
        },
        sort: '-updatedAt',
        setSort: sort => set({sort}),
        reset: () =>
          set({
            status: ['live', 'draft'],
            integrations: undefined,
            sort: '-updatedAt',
          }),
      }));
