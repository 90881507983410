import type {ListUserPropertiesOrderByOptions} from '@cohort/admin-schemas/userProperty';
import type {PaginationFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import {useBasePageFilters} from '@cohort/merchants/hooks/filters/basePageFilters';

type UserPropertiesSettingsFilters = {
  orderBy: ListUserPropertiesOrderByOptions;
  setOrderBy: (orderBy: ListUserPropertiesOrderByOptions) => void;
};

export const useUserPropertiesSettingsFilters = (): PaginationFilters &
  UserPropertiesSettingsFilters =>
  useBasePageFilters<UserPropertiesSettingsFilters>(
    /^(\/test)?\/settings\/user-properties$/u,
    set => ({
      orderBy: 'name',
      setOrderBy: orderBy => set({orderBy}),
      reset: () => set({orderBy: 'name'}),
    })
  );
