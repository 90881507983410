import {Badge} from '@cohort/merchants/components/Badge';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

interface DigitalAssetCollectionStatusBadgeProps {
  size?: 'large' | 'small';
  campaignsCount: number;
}

export const DigitalAssetCollectionStatusBadge: React.FC<
  DigitalAssetCollectionStatusBadgeProps
> = ({size, campaignsCount}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'digitalAssets.digitalAssetCollectionStatusBadge',
  });
  const status = campaignsCount > 0 ? 'live' : 'ready';

  const badgeData = match(status)
    .with('ready', () => ({
      text: t('statusReady'),
      backgroundColor: 'bg-green-100',
      textColor: 'text-green-800',
      dotColor: 'text-green-400',
    }))
    .with('live', () => ({
      text: campaignsCount < 1 ? t('statusLive') : t('statusLive', {count: campaignsCount}),
      backgroundColor: 'bg-green-100',
      textColor: 'text-green-800',
      dotColor: 'text-green-400',
    }))
    .exhaustive();

  return (
    <Badge
      text={badgeData.text}
      backgroundColor={badgeData.backgroundColor}
      textColor={badgeData.textColor}
      dotColor={badgeData.dotColor}
      size={size ?? 'large'}
    />
  );
};
