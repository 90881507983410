import type {WrappedField} from '@cohort/merchants/components/form/FieldWrapper';
import NetworkedSelect from '@cohort/merchants/components/form/select/NetworkedSelect';
import type {
  SelectDisplayMode,
  SelectOption,
} from '@cohort/merchants/components/form/select/SelectPicker';
import {SelectPicker} from '@cohort/merchants/components/form/select/SelectPicker';
import type {FormField} from '@cohort/merchants/lib/form/utils';
import {isEqual} from 'lodash';
import type {FieldValues} from 'react-hook-form';
import {useController} from 'react-hook-form';

type SelectOptionProps<T extends FieldValues> = WrappedField &
  FormField<T> & {
    options: SelectOption[];
    disabled?: boolean;
    displayMode?: SelectDisplayMode;
    formatOptionLabel?: (data: SelectOption) => JSX.Element;
    isClearable?: boolean;
    onInputChange?: (newValue: string) => void;
    inputChangeMode?: 'default' | 'debounced';
    required?: boolean;
    placeholder?: string;
    onChange?: (value: SelectOption | null) => void;
  };

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function SelectInput<T extends FieldValues>({
  register,
  control,
  rules,
  label,
  name,
  description,
  onChange,
  inputChangeMode = 'default',
  ...props
}: SelectOptionProps<T>): JSX.Element {
  const {field, fieldState} = useController({
    control,
    name,
  });

  const Input = inputChangeMode === 'default' ? SelectPicker : NetworkedSelect;

  return (
    <Input
      label={label}
      name={name}
      description={description}
      error={fieldState.error?.message}
      onChange={value => {
        onChange?.(value);
        field.onChange(value?.value);
      }}
      onBlur={field.onBlur}
      isDisabled={props.disabled}
      value={
        props.options.find(opt => {
          if (typeof opt.value === 'object') {
            return isEqual(opt.value, field.value);
          }

          return opt.value === field.value;
        }) ?? null
      }
      {...props}
    />
  );
}
