import NetworkedSelect from '@cohort/merchants/components/form/select/NetworkedSelect';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import {useCohorts} from '@cohort/merchants/hooks/api/Cohorts';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useOperators} from '@cohort/merchants/hooks/rules/operators';
import type {DynamicCohortRuleType} from '@cohort/merchants/pages/users/cohort/rule/utils';
import {useState} from 'react';
import type {Control, UseFormRegister} from 'react-hook-form';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const PAGE_SIZE = 50;

type CohortMembershipCriteriaProps = {
  control: Control<DynamicCohortRuleType>;
  register: UseFormRegister<DynamicCohortRuleType>;
  groupIndex: number;
  criteriaIndex: number;
};

const CohortMembershipCriteria: React.FC<CohortMembershipCriteriaProps> = ({
  control,
  register,
  groupIndex,
  criteriaIndex,
}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {
    keyPrefix: 'users.cohort.rule.criteria.cohortMembershipCriteria',
  });
  const [search, setSearch] = useState<string | undefined>(undefined);
  const operators = useOperators('cohort-membership');
  const {field: cohortId, fieldState: cohortIdState} = useController({
    name: `groups.${groupIndex}.criteria.${criteriaIndex}.cohortId`,
    control,
  });

  const {data: cohortsData, isLoading} = useCohorts(merchant.id, {
    pageSize: PAGE_SIZE,
    page: 1,
    ...(search !== undefined && search.trim().length > 0 && {search}),
    type: 'static',
  });
  const [, cohorts] = cohortsData ?? [];

  const cohortsOptions =
    cohorts?.map(cohort => ({
      value: cohort.id,
      label: cohort.name,
    })) ?? [];
  const selectedCohort = cohorts?.find(prop => prop.id === cohortId.value);

  return (
    <div className="grid gap-4 [grid-template-columns:350px_1fr]">
      <div className="flex items-start gap-4">
        <p className="mt-2 font-semibold">{t('user')}</p>
        <SelectInput
          name={`groups.${groupIndex}.criteria.${criteriaIndex}.operator`}
          placeholder={t('operatorPlaceholder')}
          register={register}
          control={control}
          options={operators}
        />
      </div>
      <NetworkedSelect
        name={`groups.${groupIndex}.criteria.${criteriaIndex}.cohortId`}
        error={cohortIdState.error?.message}
        placeholder={t('cohortPlaceholder')}
        options={cohortsOptions}
        onInputChange={setSearch}
        isLoading={isLoading}
        onChange={val => cohortId.onChange(val?.value)}
        value={
          cohortId.value
            ? {
                value: cohortId.value,
                label: selectedCohort?.name,
              }
            : null
        }
      />
    </div>
  );
};

export default CohortMembershipCriteria;
