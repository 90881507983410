import type {PerkAccessADto} from '@cohort/admin-schemas/perkAccesses';
import {Badge} from '@cohort/merchants/components/Badge';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const PerkAccessStatusBadge = ({perkAccess}: {perkAccess: PerkAccessADto}): JSX.Element => {
  const {t} = useTranslation('components', {keyPrefix: 'perks.perkAccessStatusBadge'});
  const {removed, status} = perkAccess;
  if (removed) {
    return (
      <Badge
        text={t('statusRemoved')}
        textColor="text-red-600"
        dotColor="text-red-400"
        backgroundColor="bg-red-100"
      />
    );
  }

  return match(status)
    .with('used', () => (
      <Badge
        text={t('statusUsed')}
        textColor="text-green-600"
        dotColor="text-green-400"
        backgroundColor="bg-green-100"
      />
    ))
    .otherwise(() => (
      <Badge
        text={t('statusUnused')}
        textColor="text-slate-500"
        dotColor="text-slate-400"
        backgroundColor="bg-slate-100"
      />
    ));
};
export default PerkAccessStatusBadge;
