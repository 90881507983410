import {Badge} from '@cohort/merchants/components/Badge';
import {useTranslation} from 'react-i18next';

interface CampaignArchivedBadgeProps {
  size?: 'large' | 'small';
}

export const CampaignArchivedBadge: React.FC<CampaignArchivedBadgeProps> = ({size}) => {
  const {t} = useTranslation('components', {keyPrefix: 'campaigns.campaignArchivedBadge'});

  return (
    <Badge
      text={t('archived')}
      backgroundColor="bg-white"
      textColor="text-gray-500"
      borderColor="border-gray-500"
      size={size ?? 'large'}
    />
  );
};
