import type {PatchCampaignADto} from '@cohort/admin-schemas/campaign';
import SubmitFooter from '@cohort/merchants/components/form/SubmitFooter';
import {campaignsKeys} from '@cohort/merchants/hooks/api/Campaigns';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useCampaignPageStore} from '@cohort/merchants/hooks/stores/campaignPage';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {patchCampaign} from '@cohort/merchants/lib/api/Campaigns';
import {getCampaignAudienceRoute} from '@cohort/merchants/lib/Pages';
import CampaignAudienceStep from '@cohort/merchants/pages/campaigns/campaign/edit/CampaignAudienceStep';
import {useQueryClient} from '@tanstack/react-query';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const CampaignAudienceEditPage = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const campaign = useCurrentCampaign();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.audience.edit.page',
  });
  const setFooter = useCampaignPageStore(state => state.setFooter);

  const {isLoading: isUpdating, mutateAsync: updateCampaign} = useCohortMutation({
    mutationFn: async (data: PatchCampaignADto) => patchCampaign(merchantId, campaign.id, data),
    notifyErrorMessage: t('notificationErrorUpdate'),
    notifySuccessMessage: t('notificationUpdateSuccess'),
  });

  useEffect(() => {
    setFooter(
      <SubmitFooter
        formName={`campaign-audience-step`}
        loading={isUpdating}
        submitLabel={t('saveBtn')}
        withBackButton={{
          label: t('backBtn'),
          onClick: () => navigate(-1),
        }}
      />
    );
    return () => setFooter(null);
  }, [setFooter, t, isUpdating, navigate]);

  return (
    <CampaignAudienceStep
      isProcessing={isUpdating}
      onStepValidated={async () => {
        await queryClient.invalidateQueries(campaignsKeys.getById(merchantId, campaign.id));
        navigate(getCampaignAudienceRoute(campaign.id).path);
      }}
      updateCampaign={updateCampaign}
    />
  );
};

export default CampaignAudienceEditPage;
