import type {PerkADto} from '@cohort/admin-schemas/perk';
import DropdownButton from '@cohort/merchants/components/buttons/DropdownButton';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import {perksKeys} from '@cohort/merchants/hooks/api/Perks';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {
  archivePerk,
  deletePerk,
  duplicatePerk,
  unarchivePerk,
} from '@cohort/merchants/lib/api/Perks';
import {getPerksRoute} from '@cohort/merchants/lib/Pages';
import {
  trackPerkDropdownArchiveClicked,
  trackPerkDropdownClicked,
  trackPerkDropdownDeleteClicked,
  trackPerkDropdownDuplicateClicked,
  trackPerkDropdownUnarchiveClicked,
} from '@cohort/merchants/lib/Tracking';
import {Archive, Copy, Trash} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

interface PerkDropdownProps {
  perk: PerkADto;
}

const PerkDropdown: React.FC<PerkDropdownProps> = ({perk}) => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const queryClient = useQueryClient();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {t} = useTranslation('pages', {keyPrefix: 'perks.overview.perkDropdown'});
  async function onMutationSuccess(): Promise<void> {
    await queryClient.invalidateQueries(perksKeys.list(merchantId));
  }

  const {mutate: handlePerkArchive} = useCohortMutation({
    mutationFn: async () => archivePerk(merchantId, perk.id),
    notifySuccessMessage: t('notificationArchiveSuccess'),
    onSuccess: onMutationSuccess,
  });

  const {mutate: handlePerkUnarchive} = useCohortMutation({
    mutationFn: async () => unarchivePerk(merchantId, perk.id),
    notifySuccessMessage: t('notificationUnarchiveSuccess'),
    onSuccess: onMutationSuccess,
  });

  const {mutate: handlePerkDuplicate} = useCohortMutation({
    mutationFn: async () => duplicatePerk(merchantId, perk.id),
    notifySuccessMessage: t('notificationDuplicateSuccess'),
    onSuccess: onMutationSuccess,
  });

  const {mutate: handlePerkDelete} = useCohortMutation({
    mutationFn: async () => deletePerk(merchantId, perk.id),
    notifySuccessMessage: t('notificationDeleteSuccess'),
    onSuccess: async () => {
      navigate(getPerksRoute().path);
      await onMutationSuccess();
    },
  });

  return (
    <Fragment>
      <DropdownButton
        handleClick={() => trackPerkDropdownClicked(perk)}
        entries={[
          {
            id: 'archive',
            label: perk.status === 'archived' ? t('labelUnarchive') : t('labelArchive'),
            icon: <Archive className="h-4 w-4 text-slate-400" />,
            handleOnClick: () => {
              if (perk.status === 'archived') {
                trackPerkDropdownUnarchiveClicked(perk);
                handlePerkUnarchive();
              }
              if (perk.status === 'live') {
                trackPerkDropdownArchiveClicked(perk);
                handlePerkArchive();
              }
            },
            hidden: perk.status === 'draft',
          },
          {
            id: 'delete',
            label: t('labelDelete'),
            icon: <Trash className="h-4 w-4 text-slate-400" />,
            handleOnClick: () => {
              trackPerkDropdownDeleteClicked(perk);
              setShowDeleteModal(true);
            },
            hidden: perk.status !== 'draft',
          },
          {
            id: 'duplicate',
            label: t('labelDuplicate'),
            icon: <Copy className="h-4 w-4 text-slate-400" />,
            handleOnClick: () => {
              trackPerkDropdownDuplicateClicked(perk);
              handlePerkDuplicate();
            },
          },
        ]}
      />
      <DeletionModal
        title={t('deleteModalTitle')}
        subtitle={t('deleteModalSubtitle')}
        onClose={() => setShowDeleteModal(false)}
        show={showDeleteModal}
        onDelete={() => handlePerkDelete()}
      />
    </Fragment>
  );
};

export default PerkDropdown;
