import type {
  CustomComponentADto,
  CustomComponentInputADto,
  CustomComponentListADto,
} from '@cohort/admin-schemas/customComponent';
import {
  CustomComponentASchema,
  CustomComponentListASchema,
} from '@cohort/admin-schemas/customComponent';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';

export async function getCustomComponents(merchantId: string): Promise<CustomComponentListADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/custom-components`, {
    expect: HttpCodes.SUCCESS,
    parser: CustomComponentListASchema.parse,
  });
}

export async function upsertCustomComponent(
  merchantId: string,
  data: CustomComponentInputADto
): Promise<CustomComponentADto> {
  return apiCall('PUT', `/v1/merchants/${merchantId}/custom-components`, {
    expect: HttpCodes.SUCCESS,
    parser: CustomComponentASchema.parse,
    body: data,
  });
}
