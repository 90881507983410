import type {CohortUserADto} from '@cohort/admin-schemas/cohort';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {getUserRoute} from '@cohort/merchants/lib/Pages';
import DeleteMember from '@cohort/merchants/pages/users/cohort/members/DeleteMember';
import {formatDate} from '@cohort/shared/utils/format';
import {createColumnHelper} from '@tanstack/react-table';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

type MembersTableProps = {
  users: CohortUserADto[];
  isLoading: boolean;
};

const MembersTable: React.FC<MembersTableProps> = ({users, isLoading}) => {
  const navigate = useNavigate();
  const {t} = useTranslation('pages', {
    keyPrefix: 'users.cohort.members.membersTable',
  });
  const columnHelper = createColumnHelper<CohortUserADto>();
  const columns = [
    columnHelper.accessor('email', {
      header: t('tableHeaderEmail'),
      cell: data => <span className="font-medium text-foreground">{data.getValue()}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor('membershipPassHandle', {
      header: t('tableHeaderMemberHandle'),
      enableSorting: false,
    }),
    columnHelper.accessor(row => formatDate(row.memberSince), {
      id: 'memberSince',
      header: t('tableHeaderMemberSince'),
      enableSorting: false,
    }),
    columnHelper.accessor(row => formatDate(row.lastActiveAt), {
      id: 'lastActiveAt',
      header: t('tableHeaderLastActiveAt'),
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => null,
      cell: data => (
        <div className="flex w-full justify-end">
          <DeleteMember user={data.row.original} />
        </div>
      ),
    }),
  ];
  const table = useCohortTable({
    columns,
    data: users,
  });

  return (
    <DataTable
      table={table}
      isLoading={isLoading}
      columnsLength={columns.length}
      emptyStatePlaceholder={t('emptyPlaceholder')}
      onRowClick={row => navigate(getUserRoute(row.id).path)}
    />
  );
};

export default MembersTable;
