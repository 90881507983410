import type {UserPropertyADto} from '@cohort/admin-schemas/userProperty';
import {useApps} from '@cohort/merchants/apps/useApps';
import {SelectPicker} from '@cohort/merchants/components/form/select/SelectPicker';
import {useUserProperties} from '@cohort/merchants/hooks/api/UserProperties';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import useHiddenUserProperties from '@cohort/merchants/hooks/useHiddenUserProperties';
import AppLogo from '@cohort/merchants/pages/users/overview/appLogo';
import {UserList} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

type UserPropertyOptionLabelProps = {
  userProperty: UserPropertyADto | undefined;
};

const UserPropertyOptionLabel: React.FC<UserPropertyOptionLabelProps> = ({userProperty}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'users.overview.userPropertiesDrawer.hiddenUserPropertiesSelector',
  });
  const apps = useApps();

  if (userProperty === undefined) {
    return <div>{t('labelPropertyNotFound')}</div>;
  }
  const app = apps.getApp(userProperty.appId);

  return (
    <div className="flex justify-between">
      <div className="mr-1">{userProperty.name}</div>
      <AppLogo
        app={app}
        fallbackLogo={<UserList size={20} className="text-slate-400" />}
        tooltipContent={app?.spec.name ?? t('customProperty')}
      />
    </div>
  );
};

type HiddenUserPropertiesSelectorProps = {
  setUserPropertiesToShow: (userProperties: UserPropertyADto[]) => void;
  userPropertiesToShow: UserPropertyADto[];
};

const HiddenUserPropertiesSelector: React.FC<HiddenUserPropertiesSelectorProps> = ({
  setUserPropertiesToShow,
  userPropertiesToShow,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'users.overview.userPropertiesDrawer.hiddenUserPropertiesSelector',
  });
  const merchant = useCurrentMerchant();
  const excludedReferenceIds = useHiddenUserProperties();

  const {data} = useUserProperties(merchant.id, {
    visible: false,
    excludedReferenceIds,
    orderBy: 'name',
  });
  const hiddenUserProperties = data ?? [];

  const hiddenUserPropertiesOptions = hiddenUserProperties.map(userProperty => ({
    value: userProperty.id,
    label: userProperty.name,
  }));

  return (
    <div className="space-y-2">
      <div className="text-sm font-medium">{t('labelPropertiesToDisplay')}</div>
      <SelectPicker
        isMulti
        options={hiddenUserPropertiesOptions}
        menuPlacement="top"
        formatOptionLabel={data => (
          <UserPropertyOptionLabel
            userProperty={hiddenUserProperties.find(property => property.id === data.value)}
          />
        )}
        onChange={options => {
          const propertiesIds = options.map(option => option.value);
          const propertiesToShow = hiddenUserProperties.filter(property =>
            propertiesIds.includes(property.id)
          );
          setUserPropertiesToShow(propertiesToShow);
        }}
        value={userPropertiesToShow.map(property => ({
          value: property.id,
          label: property.name,
        }))}
        name="language"
        autoFocus
        placeholder={t('placeholderPropertiesToDisplay')}
      />
    </div>
  );
};

export default HiddenUserPropertiesSelector;
