import Header from '@cohort/merchants/components/Header';
import PaginationFooter from '@cohort/merchants/components/PaginationFooter';
import type {PaginationDto} from '@cohort/shared/schema/common/pagination';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import React, {forwardRef} from 'react';

type AdminPageHeaderProps = {
  title: string | React.ReactNode;
  titleBadge?: React.ReactNode;
  subtitle?: string | React.ReactNode;
  topRightElements?: React.ReactNode | React.ReactNode[];
};

type AdminPagePaginationProps = {
  pagination?: PaginationDto;
  onPageChange?: (page: number) => void;
};

type AdminPageProps = {
  header: AdminPageHeaderProps;
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  pagination?: AdminPagePaginationProps;
};

const AdminPage = forwardRef<HTMLDivElement, AdminPageProps>(
  ({header, children, className, pagination}, ref) => {
    return (
      <div ref={ref} className="flex flex-col space-y-10 text-sm">
        <section>
          <Header
            title={header.title}
            subtitle={header.subtitle}
            badge={header.titleBadge}
            actions={header.topRightElements}
          />
        </section>
        <section className={cn('flex flex-col space-y-6', className)}>{children}</section>
        <section>
          {pagination && pagination.pagination && pagination.pagination.pages > 1 && (
            <PaginationFooter
              pagination={pagination.pagination}
              onPageChange={pagination.onPageChange}
            />
          )}
        </section>
      </div>
    );
  }
);

export default AdminPage;
