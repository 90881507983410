import DigitalAssetCollectionBadge from '@cohort/merchants/components/digital-assets/DigitalAssetCollectionBadge';
import {DigitalAssetVisual} from '@cohort/merchants/components/DigitalAssetVisual';
import {PerkAppBadge} from '@cohort/merchants/components/perks/PerkAppBadge';
import {PerkTypeBadge} from '@cohort/merchants/components/perks/PerkTypeBadge';
import {PerkVisual} from '@cohort/merchants/components/PerkVisual';
import type {PerkIntegrationId} from '@cohort/shared/apps';
import type {PerkType} from '@cohort/shared/schema/common/perks';
import React, {Fragment} from 'react';
import {match} from 'ts-pattern';

type RewardVisualProps =
  | {
      type: 'perk';
      perk: {
        bannerFileKey: string | null;
        thumbnailFileKey: string | null;
        internalName: string;
        type: PerkType;
        integration: {
          perkIntegrationId: PerkIntegrationId | null;
        } | null;
      };
    }
  | {
      type: 'collection';
      collection: {
        imageFileKey: string | null;
        animationFileKey: string | null;
        internalName: string;
      };
    };

const RewardVisual: React.FC<RewardVisualProps> = props => {
  const data: {visual: JSX.Element; name: string; badges: JSX.Element | JSX.Element[]} = match(
    props
  )
    .with({type: 'perk'}, ({perk}) => ({
      visual: <PerkVisual perk={perk} className="!h-11 !w-16" />,
      name: perk.internalName,
      badges: (
        <Fragment>
          <PerkTypeBadge perkType={perk.type} />
          <PerkAppBadge perkIntegrationId={perk.integration?.perkIntegrationId ?? null} />
        </Fragment>
      ),
    }))
    .with({type: 'collection'}, ({collection}) => ({
      visual: (
        <DigitalAssetVisual
          digitalAssetImageFileKey={collection.imageFileKey}
          animationFileKey={collection.animationFileKey}
          className="rounded-md"
          size={44}
        />
      ),
      name: collection.internalName,
      badges: <DigitalAssetCollectionBadge />,
    }))
    .exhaustive();

  return (
    <div className="flex items-center space-x-4">
      {data.visual}
      <div className="flex flex-col items-start space-y-1">
        <span className="text-sm font-medium">{data.name}</span>
        <div className="flex items-center gap-x-2">{data.badges}</div>
      </div>
    </div>
  );
};
export default RewardVisual;
