import {useCurrentCohort} from '@cohort/merchants/hooks/contexts/currentCohort';
import {useCohortPageStore} from '@cohort/merchants/hooks/stores/cohortPage';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import CohortPageBreadcrumb from '@cohort/merchants/pages/users/cohort/CohortPageBreadcrumb';
import {CurrentCohortProvider} from '@cohort/merchants/pages/users/cohort/CurrentCohortContext';
import DynamicCohortPageMenu from '@cohort/merchants/pages/users/cohort/DynamicCohortPageMenu';
import {Outlet} from 'react-router-dom';

const CohortPageSkeleton: React.FC = () => {
  const cohort = useCurrentCohort();
  const footer = useCohortPageStore(store => store.footer);

  return (
    <PageLayout
      title={cohort.name}
      breadcrumb={<CohortPageBreadcrumb />}
      menu={cohort.type === 'dynamic' ? <DynamicCohortPageMenu /> : undefined}
      footer={footer}
    >
      <Outlet />
    </PageLayout>
  );
};

const CohortPageLayout = (): JSX.Element => (
  <CurrentCohortProvider>
    <CohortPageSkeleton />
  </CurrentCohortProvider>
);

export default CohortPageLayout;
