import type {MerchantFontInputADto, PatchMerchantADto} from '@cohort/admin-schemas/merchant';
import Button from '@cohort/merchants/components/buttons/Button';
import Input from '@cohort/merchants/components/form/input/Input';
import UrlInput from '@cohort/merchants/components/form/input/UrlInput';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {Dialog, DialogContent, DialogTitle} from '@cohort/merchants/components/modals/Dialog';
import {merchantsKeys} from '@cohort/merchants/hooks/api/Merchants';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {notify} from '@cohort/merchants/hooks/toast';
import {patchMerchant} from '@cohort/merchants/lib/api/Merchants';
import {defaultErrorMessage} from '@cohort/shared/models';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import {FontStyleSchema, FontTypeSchema, FontWeightSchema} from '@cohort/shared/schema/common/font';
import {urlTransformer, urlValidator} from '@cohort/shared/utils/validators';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

type AddFontModalProps = {
  onClose: () => void;
};

const CustomFontFormSchema = z.object({
  id: z.null(),
  family: z.string().min(3, {message: 'errorTooShort3'}),
  src: z
    .string()
    .refine(urlValidator, 'errorUrl')
    .transform(url => urlTransformer(url)),
  type: FontTypeSchema,
  weight: FontWeightSchema.nullable(),
  style: FontStyleSchema.nullable(),
});
type CustomFontForm = z.infer<typeof CustomFontFormSchema>;

function formatFont(font: CustomFontForm): MerchantFontInputADto {
  if (font.type === 'embedded') {
    return {
      ...font,
      type: 'embedded',
      weight: null,
      style: null,
    };
  }
  return {
    ...font,
    type: 'manual',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    weight: font.weight!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    style: font.style!,
  };
}

const AddFontModal: React.FC<AddFontModalProps> = ({onClose}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {keyPrefix: 'settings.customization.addFontModal'});
  const queryClient = useQueryClient();

  const {register, control, handleSubmit} = useForm<CustomFontForm>({
    defaultValues: {
      id: null,
      type: 'embedded',
      weight: null,
      style: null,
    },
    resolver: zodResolver(CustomFontFormSchema),
  });

  const {isLoading, mutate: updateMerchant} = useCohortMutation({
    mutationFn: async (data: PatchMerchantADto) => patchMerchant(merchant.id, data),
    notifySuccessMessage: t('fontAddedSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(merchantsKeys.getById(merchant.id));
      onClose();
    },
    onError: err => {
      if (isCohortError(err, 'merchant.invalid-font-stylesheet-url')) {
        return notify('error', t('errorInvalidFontUrl'));
      }
      notify('error', defaultErrorMessage);
    },
  });

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <div className="p-6 [width:500px]">
          <div className="flex flex-col justify-center text-center">
            <DialogTitle className="text-lg font-bold leading-6">{t('formTitle')}</DialogTitle>
          </div>
          <form
            className="mt-8 space-y-4"
            onSubmit={handleSubmit(data =>
              updateMerchant({
                fonts: [...merchant.fonts, formatFont(data)],
              })
            )}
          >
            <Input
              label={t('labelFontName')}
              placeholder={t('placeholderFontName')}
              type="text"
              autoFocus
              name="family"
              register={register}
              control={control}
            />
            <UrlInput
              label={t('labelFontUrl')}
              placeholder={t('placeholderFontUrl')}
              type="text"
              name="src"
              register={register}
              control={control}
            />
            <HighlightText type="info" text={t('styleSheetHint')} />
            <div className="flex items-center justify-end gap-4">
              <Button variant="secondary" disabled={isLoading} onClick={() => onClose()}>
                {t('cancelBtn')}
              </Button>
              <Button type="submit" loading={isLoading}>
                {t('createBtn')}
              </Button>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddFontModal;
