import type {
  NotificationConfigurationType,
  NotificationIntegrationConfigComponentProps,
} from '@cohort/merchants/apps';
import {Badge} from '@cohort/merchants/components/Badge';
import RadioCards from '@cohort/merchants/components/form/RadioCards';
import {COHORT_EMAIL_SUPPORTED_USER_EVENTS_SPECS_IDS} from '@cohort/shared/apps/cohort/notifications/email';
import {Envelope, Pen} from '@phosphor-icons/react';
import {useController, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const CohortEmailNotificationIntegrationConfigComponent: React.FC<
  NotificationIntegrationConfigComponentProps
> = () => {
  const {control, register} = useFormContext<NotificationConfigurationType>();
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'notificationIntegrations.email.configComponent',
  });

  const {field: userEventIdField} = useController({
    control,
    name: 'userEventId',
  });

  const disableDefaultEmailOption = !COHORT_EMAIL_SUPPORTED_USER_EVENTS_SPECS_IDS.includes(
    userEventIdField.value
  );

  return (
    <div>
      <p className="mb-2 text-sm font-medium">{t('chooseEmailLabel')}</p>
      <RadioCards
        name="notificationIntegrationConfig.template"
        direction="row"
        register={register}
        control={control}
        options={[
          {
            label: t('defaultEmailLabel'),
            value: 'default',
            prefix: <Envelope size={20} className="text-slate-400" />,
            disabled: disableDefaultEmailOption,
            ...(disableDefaultEmailOption && {
              suffix: (
                <Badge
                  size="small"
                  text={t('notAvailable')}
                  backgroundColor="bg-slate-200"
                  textColor="text-slate-900"
                />
              ),
              tooltipContent: t('defaultEmailNotAvailableTooltip'),
            }),
          },
          {
            label: t('customEmailLabel'),
            value: 'custom',
            disabled: true,
            prefix: <Pen size={20} className="text-slate-400" />,
            suffix: (
              <Badge
                size="small"
                text={t('comingSoon')}
                backgroundColor="bg-slate-200"
                textColor="text-slate-900"
              />
            ),
          },
        ]}
        withCheckIcon
      />
    </div>
  );
};

export default CohortEmailNotificationIntegrationConfigComponent;
