import BaseReferenceComponent from '@cohort/merchants/components/references/BaseReferenceComponent';
import {getCampaignRoute} from '@cohort/merchants/lib/Pages';

type CampaignReferenceComponentProps = {
  campaignId: string;
  internalName: string;
  imageFileKey: string | null;
};

const CampaignReferenceComponent: React.FC<CampaignReferenceComponentProps> = ({
  campaignId,
  internalName,
  imageFileKey,
}) => (
  <BaseReferenceComponent
    title={internalName}
    redirectPath={getCampaignRoute(campaignId).path}
    imageFileKey={imageFileKey}
  />
);

export default CampaignReferenceComponent;
