import type {FilterOption} from '@cohort/merchants/components/filters/Filters';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Popover, Transition} from '@headlessui/react';
import {CaretDown} from '@phosphor-icons/react';
import React, {Fragment} from 'react';

interface MultipleChoicesFilterProps {
  label: string;
  options: FilterOption[];
  onChange?: (options: FilterOption[]) => void;
  icon?: JSX.Element;
  className?: string;
  short?: boolean;
}

const MultipleChoicesFilter: React.FC<MultipleChoicesFilterProps> = ({
  options,
  onChange,
  label,
  icon,
  className,
  short,
}) => {
  const handleCheckboxChange = (optionValue: string, checked: boolean): void => {
    const newOptions = options.map((opt: FilterOption) => {
      if (opt.value === optionValue) {
        opt.active = checked;
      }
      return {...opt};
    });
    onChange?.(newOptions);
  };

  const activeLabels = options.filter(o => o.active).map(o => o.label);
  const isActive = activeLabels.length !== 0;

  let totalChars = 0;
  let innerText = '';

  if (short) {
    innerText = activeLabels.length === 0 ? '' : `: ${activeLabels.length}`;
  } else {
    // build the label text
    for (const [index, currentLabel] of activeLabels.entries()) {
      if (totalChars === 0) {
        innerText += ': ';
      }
      if (totalChars > 0) {
        innerText += ', ';
      }
      if (totalChars < 20) {
        innerText += currentLabel;
        totalChars += currentLabel.length;
      } else {
        innerText += ` +${activeLabels.length - index}`;
        break;
      }
    }
  }

  return (
    <Popover as="div" key={label} className="relative inline-block text-left">
      <div>
        <Popover.Button
          className={cn(
            'group inline-flex items-center justify-center rounded-md border px-2 py-1 text-sm text-slate-500',
            isActive && 'border-primary/10 bg-primary/10 !text-primary-darker',
            className
          )}
        >
          <div className={cn(isActive ? 'text-primary-darker' : 'text-slate-400')}>
            {icon !== undefined ? (
              icon
            ) : (
              <svg
                className={cn(
                  'mr-1.5 h-2 w-2 ',
                  isActive ? 'text-primary-darker' : 'text-slate-400'
                )}
                fill="currentColor"
                viewBox="0 0 8 8"
              >
                <circle cx={4} cy={4} r={3} />
              </svg>
            )}
          </div>
          <span className="font-medium">{label}</span>

          <span className="overflow-hidden overflow-ellipsis whitespace-nowrap font-normal">
            {innerText}
          </span>
          <CaretDown
            className={cn(
              '-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-slate-400',
              isActive && '!text-primary'
            )}
            aria-hidden="true"
          />
        </Popover.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transdiv opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="absolute left-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="space-y-4">
            {options.map(option => (
              <div key={option.value} className="flex items-center">
                <input
                  id={`filter-${option.value}`}
                  name={option.value}
                  checked={option.active}
                  type="checkbox"
                  className="h-4 w-4 cursor-pointer rounded text-primary-darker focus:ring-primary"
                  onChange={() => {
                    handleCheckboxChange(option.value, !option.active);
                  }}
                />
                <label
                  htmlFor={`filter-${option.value}`}
                  className="cursor-pointer whitespace-nowrap pl-3 pr-6 text-sm font-medium"
                >
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default MultipleChoicesFilter;
