import StoreLogo from '@cohort/components-xps/components/navbar/StoreLogo';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useTranslation} from 'react-i18next';

const PreviewNavBar = (): JSX.Element => {
  const merchant = useCurrentMerchant();
  const {name: merchantName, logoFileKey} = merchant;
  const {t} = useTranslation('components', {
    keyPrefix: 'campaigns.preview.navbar',
  });

  return (
    <div className="flex items-center border-b px-4 py-3">
      <StoreLogo logoImageFileKey={logoFileKey} name={merchantName} />
      <h3 className="mr-1.5 line-clamp-2 text-left">
        {t('experienceStore', {name: merchant.name})}
      </h3>
    </div>
  );
};

export default PreviewNavBar;
