import type {DigitalAssetCollectionADto} from '@cohort/admin-schemas/digitalAssetCollection';
import type {PerkADto} from '@cohort/admin-schemas/perk';
import Button from '@cohort/merchants/components/buttons/Button';
import DigitalAssetCollectionsSortBy from '@cohort/merchants/components/digital-assets/DigitalAssetCollectionSortBy';
import EmptyState from '@cohort/merchants/components/EmptyState';
import BaseInput from '@cohort/merchants/components/form/input/BaseInput';
import LastEditLabel from '@cohort/merchants/components/LastEditLabel';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@cohort/merchants/components/modals/Dialog';
import PerksFilters from '@cohort/merchants/components/perks/PerksFilters';
import PerksSortBy from '@cohort/merchants/components/perks/PerksSortBy';
import RewardVisual from '@cohort/merchants/components/rewards/RewardVisual';
import {userDigitalAssetCollectionsWithInfiniteQuery} from '@cohort/merchants/hooks/api/DigitalAssetCollections';
import {userPerksWithInfiniteQuery} from '@cohort/merchants/hooks/api/Perks';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useDigitalAssetCollectionsListFilters} from '@cohort/merchants/hooks/filters/digitalAssetCollectionsListFilters';
import {usePerksListFilters} from '@cohort/merchants/hooks/filters/perksListFilters';
import {PerkIntegrationIdSchema} from '@cohort/shared/apps';
import {DateSchema} from '@cohort/shared/schema/common';
import {PerkTypeSchema} from '@cohort/shared/schema/common/perks';
import {Tabs, TabsList, TabsTrigger} from '@cohort/shared-frontend/components/Tabs';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {zodResolver} from '@hookform/resolvers/zod';
import {Gift, ImagesSquare} from '@phosphor-icons/react';
import type {ChangeEvent} from 'react';
import React, {useEffect, useState} from 'react';
import type {Control, UseFormRegister} from 'react-hook-form';
import {useFieldArray, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useInView} from 'react-intersection-observer';
import {z} from 'zod';

const PAGE_SIZE = 20;

export const CollectionItemSchema = z.object({
  collectionId: z.string(),
  updatedAt: DateSchema,
  collection: z.object({
    id: z.string().uuid(),
    imageFileKey: z.string(),
    animationFileKey: z.string().nullable(),
    internalName: z.string(),
    maxOwnershipsPerUser: z.number().nullable(),
  }),
});
export type CollectionItem = z.infer<typeof CollectionItemSchema>;

export const PerkItemSchema = z.object({
  perkId: z.string(),
  updatedAt: DateSchema,
  revocable: z.boolean(),
  perk: z.object({
    bannerFileKey: z.string().nullable(),
    thumbnailFileKey: z.string().nullable(),
    internalName: z.string(),
    type: PerkTypeSchema,
    integration: z
      .object({
        perkIntegrationId: PerkIntegrationIdSchema,
      })
      .nullable(),
    maxAccessesPerUser: z.number().nullable(),
  }),
});
export type PerkItem = z.infer<typeof PerkItemSchema>;

const RewardsSelectionSchema = z.object({
  collections: z.array(CollectionItemSchema),
  perks: z.array(PerkItemSchema),
});
type RewardsSelection = z.infer<typeof RewardsSelectionSchema>;

type PerkSelectionTabProps = {
  register: UseFormRegister<RewardsSelection>;
  control: Control<RewardsSelection>;
  currentPerks: PerkItem[];
};

type RewardItemProps = {
  visual: JSX.Element;
  selected: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  updatedAt: Date;
};

const RewardItem: React.FC<RewardItemProps> = ({visual, selected, handleChange, updatedAt}) => {
  return (
    <div className="flex flex-row items-center gap-x-4 py-4">
      <div>
        <BaseInput name="perk" type="checkbox" checked={selected} onChange={handleChange} />
      </div>
      <div className="grow">{visual}</div>
      <div>
        <LastEditLabel date={updatedAt} />
      </div>
    </div>
  );
};

const PerkSelectionTab: React.FC<PerkSelectionTabProps> = ({control, currentPerks}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.reward.rewardSelectionModal',
  });
  const filters = usePerksListFilters();
  const {ref, inView} = useInView();
  const merchant = useCurrentMerchant();
  const currentPerkIds = currentPerks.map(perk => perk.perkId);

  const {data, fetchNextPage, hasNextPage} = userPerksWithInfiniteQuery(merchant.id, {
    pageSize: PAGE_SIZE,
    integration: filters.integrations,
    type: filters.types,
    orderBy: filters.sort,
    status: ['live'],
  });

  const {fields, replace, append} = useFieldArray({
    control,
    name: 'perks',
  });

  const selected = fields.map(perk => perk.perkId);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage, data?.pages.length]);

  const handleChange =
    (perk: PerkADto) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (event.target.checked) {
        append({perkId: perk.id, updatedAt: perk.updatedAt, revocable: false, perk});
      } else {
        replace(fields.filter(field => field.perkId !== perk.id));
      }
    };

  const perks = data?.pages
    .flatMap(page => page.data)
    .filter(perk => !currentPerkIds.includes(perk.id));

  return (
    <div className="flex flex-col gap-6">
      <div className="flex h-8 flex-row justify-between">
        <PerksFilters filters={filters} hideStatusFilter short />
        <PerksSortBy filters={filters} />
      </div>
      <div>
        {perks && perks.length < 1 && <EmptyState title={t('titleNoPerks')} />}
        {perks && perks.length > 0 && (
          <ul className="mt-1">
            {perks.map(perk => (
              <li key={perk.id} className="border-t">
                <RewardItem
                  visual={<RewardVisual type="perk" perk={perk} />}
                  selected={selected.includes(perk.id)}
                  handleChange={handleChange(perk)}
                  updatedAt={perk.updatedAt}
                />
              </li>
            ))}
            <li ref={ref}></li>
          </ul>
        )}
      </div>
    </div>
  );
};

type CollectionSelectionTabProps = {
  register: UseFormRegister<RewardsSelection>;
  control: Control<RewardsSelection>;
  currentCollections: CollectionItem[];
};

const CollectionSelectionTab: React.FC<CollectionSelectionTabProps> = ({
  control,
  currentCollections,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.reward.rewardSelectionModal',
  });
  const filters = useDigitalAssetCollectionsListFilters();
  const {ref, inView} = useInView();
  const merchant = useCurrentMerchant();
  const currentCollectionIds = currentCollections.map(collection => collection.collectionId);

  const {data, fetchNextPage, hasNextPage} = userDigitalAssetCollectionsWithInfiniteQuery(
    merchant.id,
    {
      pageSize: PAGE_SIZE,
      orderBy: filters.sort,
    }
  );

  const {fields, replace, append} = useFieldArray({
    control,
    name: 'collections',
  });

  const selected = fields.map(collection => collection.collectionId);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  const handleChange =
    (collection: DigitalAssetCollectionADto) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (event.target.checked) {
        append({collectionId: collection.id, updatedAt: collection.updatedAt, collection});
      } else {
        replace(fields.filter(field => field.collectionId !== collection.id));
      }
    };

  const collections = data?.pages
    .flatMap(page => page.data)
    .filter(collection => !currentCollectionIds.includes(collection.id));

  return (
    <div className="flex flex-col gap-6">
      <div className="flex h-8 flex-row justify-end">
        <DigitalAssetCollectionsSortBy filters={filters} />
      </div>
      <div>
        {collections && collections.length < 1 && <EmptyState title={t('titleNoCollections')} />}
        {collections && collections.length > 0 && (
          <ul className="mt-1">
            {collections.map(collection => (
              <li key={collection.id} className="border-t">
                <RewardItem
                  visual={<RewardVisual type="collection" collection={collection} />}
                  selected={selected.includes(collection.id)}
                  handleChange={handleChange(collection)}
                  updatedAt={collection.updatedAt}
                />
              </li>
            ))}
            <li ref={ref}></li>
          </ul>
        )}
      </div>
    </div>
  );
};

type RewardSelectionModalProps = {
  currentCollections: CollectionItem[];
  currentPerks: PerkItem[];
  addPerks: (perkItem: PerkItem[]) => void;
  addCollections: (collectionItem: CollectionItem[]) => void;
  onClose: () => void;
};

type TabFilter = 'perks' | 'collections';

const RewardSelectionModal: React.FC<RewardSelectionModalProps> = ({
  currentCollections,
  currentPerks,
  addPerks,
  addCollections,
  onClose,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.reward.rewardSelectionModal',
  });

  const [tab, setTab] = useState<TabFilter>('perks');

  const {register, control, watch} = useForm<RewardsSelection>({
    resolver: zodResolver(RewardsSelectionSchema),
    defaultValues: {
      collections: [],
      perks: [],
    },
  });

  const collections = watch('collections');
  const perks = watch('perks');

  const onSubmit = (): void => {
    addCollections(collections);
    addPerks(perks);
    onClose();
  };

  const tabOptions = [
    {
      value: 'perks',
      label: t('perksTab'),
      icon: <Gift size={20} />,
    },
    {
      value: 'collections',
      label: t('collectionsTab'),
      icon: <ImagesSquare size={20} />,
    },
  ] as const;

  const rewardsCount = collections.length + perks.length;

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent data-testid="campaign-airdrop-modal">
        <DialogHeader>
          <DialogTitle className="text-lg font-semibold">{t('modalTitle')}</DialogTitle>
          <DialogDescription className="m-0 text-sm">{t('modalDescription')}</DialogDescription>
        </DialogHeader>
        <DialogBody className="mt-6 h-[600px] w-[800px] py-0">
          <Tabs defaultValue={tab} onValueChange={val => setTab(val as TabFilter)}>
            <TabsList className="grid w-full grid-cols-2">
              {tabOptions.map(option => (
                <TabsTrigger
                  key={option.value}
                  value={option.value}
                  className="flex items-center gap-2"
                >
                  <span className={cn(tab === option.value && 'text-primary')}>{option.icon}</span>
                  {option.label}
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>
          {tab === 'perks' && (
            <PerkSelectionTab control={control} register={register} currentPerks={currentPerks} />
          )}
          {tab === 'collections' && (
            <CollectionSelectionTab
              control={control}
              register={register}
              currentCollections={currentCollections}
            />
          )}
        </DialogBody>
        <DialogFooter className="mt-6 flex flex-col gap-4 bg-transparent">
          <div className="flex justify-between">
            <Button key="close-duplicate-modal" variant="secondary" onClick={onClose}>
              {t('cancelBtn')}
            </Button>
            <Button onClick={onSubmit} disabled={rewardsCount < 1}>
              {rewardsCount < 1 ? t('addBtnNoRewards') : t('addBtnRewards', {count: rewardsCount})}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
export default RewardSelectionModal;
