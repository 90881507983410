import {cn} from '@cohort/shared-frontend/utils/classNames';

type CardWithRadientBackgroundProps = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
};

export const CardWithRadientBackground: React.FC<CardWithRadientBackgroundProps> = ({
  children,
  className,
}) => (
  <div className="bg-radial-gradient flex min-h-screen w-screen flex-row items-center justify-center overflow-y-auto bg-[--radial-gradient-fallback-color]">
    <div
      className={cn(
        'my-20 flex flex-col justify-center space-y-6 rounded-xl bg-white p-8 shadow-md md:w-1/2 xl:w-1/3',
        className
      )}
    >
      {children}
    </div>
  </div>
);
