import type {CampaignADto} from '@cohort/admin-schemas/campaign';
import CampaignJourneyStepAppBadges from '@cohort/merchants/components/campaigns/journeys/CampaignJourneyStepAppBadges';
import {useJourneySteps} from '@cohort/merchants/hooks/api/Campaigns';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {Fragment} from 'react';

const StepsSectionContent: React.FC<{campaign: CampaignADto}> = ({campaign}) => {
  const merchant = useCurrentMerchant();
  const {data} = useJourneySteps(merchant.id, campaign.id);

  if (!campaign.journey) {
    return <Fragment />;
  }
  const journeySteps = data || [];

  return (
    <div className="grid gap-8 [grid-template-columns:max-content_1fr]">
      <ul className="flex flex-col gap-2">
        {journeySteps.map(step => (
          <li key={step.id} className="mt-2 flex items-center gap-4">
            <span>{step.icon}</span>
            <p className="text-sm font-medium">{step.title[merchant.defaultLanguage]}</p>
          </li>
        ))}
      </ul>
      <ul className="flex flex-col gap-2">
        {journeySteps.map(step => (
          <li key={step.id}>
            <CampaignJourneyStepAppBadges triggers={step.triggers} />
            {step.triggers.length === 0 && <div className="h-[2rem]" />}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StepsSectionContent;
