import type {NotificationConfigurationADto} from '@cohort/admin-schemas/notification';
import type {
  EventResourcesAndProperties,
  NotificationConfigurationType,
  NotificationIntegration,
  UserEvent,
} from '@cohort/merchants/apps';
import {getNotificationIntegrations, NotificationConfigurationSchema} from '@cohort/merchants/apps';
import {useNotificationIntegrations} from '@cohort/merchants/apps/useNotificationIntegrations';
import {useUserEventApps} from '@cohort/merchants/apps/useUserEventApps';
import Button from '@cohort/merchants/components/buttons/Button';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import type {SelectOption} from '@cohort/merchants/components/form/select/SelectPicker';
import Header from '@cohort/merchants/components/Header';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from '@cohort/merchants/components/modals/Dialog';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {handleFormErrors} from '@cohort/merchants/lib/form/utils';
import AppLogo from '@cohort/merchants/pages/users/overview/appLogo';
import type {EventId, ResourceType} from '@cohort/shared/apps';
import {getResourceSpec, NotificationIntegrationIdSchema} from '@cohort/shared/apps';
import type {UserEventStruct} from '@cohort/shared/apps/userEvent';
import type {NotificationConfigurationResourceType} from '@cohort/shared/schema/common/notificationConfiguration';
import {zodResolver} from '@hookform/resolvers/zod';
import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

type CreateEditNotificationModalProps = {
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: (data: NotificationConfigurationType) => void;
  userEventId: EventId;
  notificationConfiguration?: NotificationConfigurationADto;
  resource?: {
    type: NotificationConfigurationResourceType;
    id: string;
  };
};

function buildEventResourcesAndProperties(
  userEvent: UserEvent<UserEventStruct>
): EventResourcesAndProperties {
  const resourcePropertiesRegistry: Record<ResourceType, z.ZodSchema> = {} as Record<
    ResourceType,
    z.ZodSchema
  >;

  for (const [, resource] of Object.entries(userEvent.spec.resources)) {
    if (!resource) {
      continue;
    }
    const resourceSpec = getResourceSpec(resource);

    resourcePropertiesRegistry[resource] = resourceSpec.schema;
  }

  return {
    properties: userEvent.spec.propertiesSchema,
    resources: resourcePropertiesRegistry,
  };
}

const CreateEditNotificationConfigurationModal: React.FC<CreateEditNotificationModalProps> = ({
  isLoading = false,
  onClose,
  onSubmit,
  notificationConfiguration,
  userEventId,
  resource,
}) => {
  const merchant = useCurrentMerchant();
  const {
    getNotificationIntegrationTitle,
    getNotificationIntegration,
    getAppFromNotificationIntegration,
    notificationIntegrationDisabled,
  } = useNotificationIntegrations();
  const {getUserEvent} = useUserEventApps();
  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.notifications.createEditNotificationModal',
  });

  const useFormProps = useForm<NotificationConfigurationType>({
    resolver: zodResolver(NotificationConfigurationSchema),
    defaultValues: {
      merchantId: merchant.id,
      userEventId: userEventId,
      connectionId: notificationConfiguration?.connectionId ?? null,
      notificationIntegrationId: notificationConfiguration?.notificationIntegrationId ?? null,
      notificationIntegrationConfig: notificationConfiguration?.notificationIntegrationConfig ?? {},
      resourceType: notificationConfiguration?.resourceType ?? resource?.type ?? null,
      resourceId: notificationConfiguration?.resourceId ?? resource?.id ?? null,
    },
  });
  const {
    watch,
    handleSubmit,
    clearErrors,
    setError,
    register,
    control,
    formState: {isValid},
  } = useFormProps;

  const [notificationIntegrationId] = watch(['notificationIntegrationId']);

  let notificationIntegrations: Array<NotificationIntegration> = [];
  notificationIntegrations = getNotificationIntegrations().filter(integration => {
    // Filter out disabled integrations unless it's the currently selected one
    if (integration.spec.id === notificationIntegrationId) {
      return true;
    }
    return !notificationIntegrationDisabled(integration);
  });
  const selectedNotificationIntegration = notificationIntegrationId
    ? getNotificationIntegration(notificationIntegrationId)
    : null;
  const userEvent = getUserEvent(userEventId);

  const formatOptionLabel = (data: SelectOption): JSX.Element => {
    const {label, value} = data;
    const notificationIntegrationId = NotificationIntegrationIdSchema.parse(value);
    const app = getAppFromNotificationIntegration(notificationIntegrationId);

    return (
      <div className="flex items-center">
        <div className="flex items-center space-x-2">
          <AppLogo app={app} />
          <div>{label}</div>
        </div>
      </div>
    );
  };

  const selectInputOptions: Array<SelectOption> = notificationIntegrations.map(
    notificationIntegration => {
      return {
        value: notificationIntegration.spec.id,
        label: getNotificationIntegrationTitle(notificationIntegration),
      } satisfies SelectOption;
    }
  );

  return (
    <Dialog open>
      <DialogContent className="w-[1200px]">
        <FormProvider {...useFormProps}>
          <form
            onSubmit={e => {
              e.preventDefault();
              handleSubmit(data => {
                if (selectedNotificationIntegration === null) {
                  return;
                }
                try {
                  const validationSchema =
                    selectedNotificationIntegration.configComponentValidationSchema ??
                    selectedNotificationIntegration.spec.configSchema;
                  const parsedConfig = validationSchema.parse(data.notificationIntegrationConfig);
                  const parsedData = {
                    ...data,
                    notificationIntegrationConfig: parsedConfig,
                  };
                  onSubmit(parsedData);
                  onClose();
                } catch (error) {
                  if (error instanceof z.ZodError) {
                    handleFormErrors(
                      error,
                      clearErrors,
                      setError,
                      'notificationIntegrationConfig.'
                    );
                  } else {
                    throw error;
                  }
                }
              })(e);
            }}
          >
            <DialogHeader className="space-y-6">
              <Header title={notificationConfiguration ? t('titleEdit') : t('titleCreate')} />
            </DialogHeader>
            <DialogBody className="pb-3 pt-0">
              <div className="w-[320px]">
                <SelectInput
                  labelPosition="top"
                  label={t('integrationDropdownLabel')}
                  options={selectInputOptions}
                  name="notificationIntegrationId"
                  register={register}
                  control={control}
                  formatOptionLabel={formatOptionLabel}
                />
              </div>

              <div>
                {selectedNotificationIntegration && (
                  <selectedNotificationIntegration.configComponent
                    event={buildEventResourcesAndProperties(userEvent)}
                  />
                )}
              </div>
            </DialogBody>
            <DialogFooter className="bg-white">
              <Button disabled={isLoading} variant="secondary" onClick={onClose}>
                {t('btnCancel')}
              </Button>
              <Button
                loading={isLoading}
                disabled={isLoading || selectedNotificationIntegration === null || !isValid}
                type="submit"
              >
                {notificationConfiguration ? t('submitBtnEdit') : t('submitBtnCreate')}
              </Button>
            </DialogFooter>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default CreateEditNotificationConfigurationModal;
