import Button from '@cohort/merchants/components/buttons/Button';
import Input from '@cohort/merchants/components/form/input/Input';
import {Dialog, DialogContent} from '@cohort/merchants/components/modals/Dialog';
import {EmailSchema} from '@cohort/shared/schema/common';
import {zodResolver} from '@hookform/resolvers/zod';
import {PaperPlaneRight} from '@phosphor-icons/react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

const InviteUserFormSchema = z.object({
  email: EmailSchema,
});
type InviteUserForm = z.infer<typeof InviteUserFormSchema>;

interface InviteMemberModalProps {
  addMembership: (email: string) => void;
  show: boolean;
  handleClose: () => void;
  isLoading: boolean;
}

const InviteMemberModal = ({
  addMembership,
  show,
  handleClose,
  isLoading,
}: InviteMemberModalProps): JSX.Element => {
  const {t} = useTranslation('pages', {keyPrefix: 'settings.members.inviteMemberModal'});

  const {register, control, handleSubmit, formState} = useForm<InviteUserForm>({
    defaultValues: {
      email: '',
    },
    mode: 'onBlur',
    resolver: zodResolver(InviteUserFormSchema),
  });

  return (
    <Dialog open={show} onOpenChange={handleClose}>
      <DialogContent>
        <form onSubmit={handleSubmit(data => addMembership(data.email))} className="space-y-4 p-6">
          <div className="text-center text-lg font-semibold">{t('labelInviteUser')}</div>
          <Input
            type="text"
            register={register}
            control={control}
            name="email"
            placeholder="Enter the email"
            description={t('labelSendEmail')}
          />
          <div>
            <Button
              type="submit"
              disabled={!formState.isDirty}
              loading={isLoading}
              className="h-12 w-full"
              data-testid="send-invitation"
            >
              {t('buttonSendInvitation')}
              <PaperPlaneRight className="ml-3 h-5 w-5" />
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default InviteMemberModal;
