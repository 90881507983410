import {cn} from '@cohort/shared-frontend/utils/classNames';
import {forwardRef} from 'react';

type SectionTitleProps = {
  content: string;
  titleButton?: JSX.Element;
  isMobile?: boolean;
};

const SectionTitle = forwardRef<HTMLDivElement, SectionTitleProps>((props, ref) => {
  const {content, titleButton, isMobile = false} = props;

  return (
    <section
      ref={ref}
      className={cn(
        'grid snap-start scroll-mt-[55px] grid-cols-2 gap-x-2 px-4 pb-5',
        !isMobile && 'md:px-0',
        isMobile && 'border-b'
      )}
    >
      {isMobile ? <h2>{content}</h2> : <h1>{content}</h1>}
      {titleButton && <div className="flex items-center">{titleButton}</div>}
    </section>
  );
});

export default SectionTitle;
