import Button from '@cohort/merchants/components/buttons/Button';
import DatetimeInput from '@cohort/merchants/components/form/DatetimeInput';
import BaseInput from '@cohort/merchants/components/form/input/BaseInput';
import ListInput from '@cohort/merchants/components/form/ListInput';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@cohort/merchants/components/modals/Dialog';
import type {FormField} from '@cohort/merchants/lib/form/utils';
import UserAttributeCell from '@cohort/merchants/pages/users/overview/UserAttributeCell';
import type {
  UserPropertyDataType,
  UserPropertyValue,
} from '@cohort/shared/schema/common/userProperty';
import {PencilSimple, TrashSimple} from '@phosphor-icons/react';
import {Fragment, useState} from 'react';
import type {FieldValues} from 'react-hook-form';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

type EditModalWrapperProps = {
  children: React.ReactNode;
  onCancel: () => void;
  onSubmit: () => void;
};

const EditModalWrapper: React.FC<EditModalWrapperProps> = ({children, onCancel, onSubmit}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'users.user.overview.editableField'});

  return (
    <Dialog open onOpenChange={onCancel}>
      <DialogContent className="w-[600px]">
        <DialogHeader className="space-y-6">
          <DialogTitle className="text-lg font-semibold tracking-normal">
            {t('modalTitle')}
          </DialogTitle>
        </DialogHeader>
        <DialogBody>{children} </DialogBody>
        <DialogFooter className="mt-2 flex w-full items-center gap-4">
          <Button variant="secondary" onClick={onCancel} className="w-1/2">
            {t('buttonCancel')}
          </Button>
          <Button onClick={onSubmit} className="w-1/2">
            {t('buttonSave')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

type EditModeFieldProps<T extends FieldValues> = Omit<JSX.IntrinsicElements['input'], 'name'> &
  FormField<T> & {
    dataType: UserPropertyDataType;
  };

// eslint-disable-next-line @typescript-eslint/naming-convention
function EditModeField<T extends FieldValues>({
  dataType,
  control,
  register,
  name,
}: EditModeFieldProps<T>): JSX.Element {
  const {field} = useController({name, control});

  return match(dataType)
    .with('date', () => <DatetimeInput control={control} name={name} register={register} />)
    .with('string', () => (
      <BaseInput {...register(name)} value={field.value} placeholder="-" type="text" />
    ))
    .with('number', () => (
      <BaseInput
        {...register(name, {
          setValueAs: (value: string) => (value === '' ? '' : parseFloat(value)),
        })}
        value={field.value}
        placeholder="-"
        type="number"
      />
    ))
    .with('boolean', () => (
      <SelectInput
        control={control}
        name={name}
        register={register}
        options={[
          {label: 'true', value: true},
          {label: 'false', value: false},
        ]}
      />
    ))
    .with('string_list', () => <ListInput name={name} register={register} control={control} />)
    .with('resource_list', () => <Fragment />) // Resource list is not editable
    .exhaustive();
}

type EditableFieldProps<T extends FieldValues> = Omit<
  JSX.IntrinsicElements['input'],
  'name' | 'defaultValue'
> &
  FormField<T> & {
    dataType: UserPropertyDataType;
    defaultValue: UserPropertyValue;
    onSubmit?: () => void;
    withRemoveButton?: boolean;
    disabled?: boolean;
    userAttributeClassName?: string;
  };

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function EditableField<T extends FieldValues>({
  dataType,
  control,
  register,
  name,
  defaultValue,
  onSubmit,
  withRemoveButton = false,
  disabled = false,
  userAttributeClassName,
}: EditableFieldProps<T>): JSX.Element {
  const {field} = useController({name, control});
  const [editMode, setEditMode] = useState(false);

  return editMode ? (
    <EditModalWrapper
      onCancel={() => {
        setEditMode(false);
        field.onChange(defaultValue);
      }}
      onSubmit={() => {
        setEditMode(false);
        onSubmit?.();
      }}
    >
      <div className="flex flex-row items-center justify-between space-x-3 text-sm">
        <EditModeField control={control} name={name} register={register} dataType={dataType} />
        {dataType === 'date' && withRemoveButton === true && (
          <TrashSimple
            className="h-5 w-5 flex-shrink-0 cursor-pointer text-slate-400"
            onClick={() => field.onChange('')}
          />
        )}
      </div>
    </EditModalWrapper>
  ) : (
    <div className="flex flex-row items-center justify-between space-x-3 text-sm">
      <UserAttributeCell
        dataType={dataType}
        value={field.value}
        className={userAttributeClassName}
      />
      {disabled === false && (
        <PencilSimple
          className="h-5 w-5 flex-shrink-0 cursor-pointer text-slate-400"
          onClick={() => setEditMode(true)}
        />
      )}
    </div>
  );
}
