import type {YoutubeSubscribeVerificationAttemptData} from '@cohort/shared/apps/youtube/triggers/subscribe';
import {Trans} from 'react-i18next';

const YOUTUBE_URL = 'https://youtube.com';

const YoutubeSubscribeTriggerStepCompletionTitleComponent: React.FC<{
  data: YoutubeSubscribeVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  if (!completionSuccess) {
    return (
      <Trans
        i18nKey="triggerIntegrations.subscribe.stepCompletionTitleComponent.error"
        ns="app-youtube"
      />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const channelTitle = data.channelTitle!;
  const channelHandle = data.channelHandle;

  return (
    <Trans
      i18nKey="triggerIntegrations.subscribe.stepCompletionTitleComponent.success"
      ns="app-youtube"
      values={{username: channelTitle}}
      components={{
        a: (
          <a
            className="font-semibold text-gray-900"
            target="_blank"
            rel="noopener noreferrer nofollow"
            href={`${YOUTUBE_URL}/${channelHandle}`}
          />
        ),
      }}
    />
  );
};

export default YoutubeSubscribeTriggerStepCompletionTitleComponent;
