import CampaignStoreInfo from '@cohort/components-xps/components/CampaignStoreInfo';
import CardVisual from '@cohort/components-xps/components/cards/CardVisual';
import type {CampaignPricing} from '@cohort/shared/schema/common/campaign';
import type {Currency, Price} from '@cohort/shared/schema/common/currency';
import {formatAmount} from '@cohort/shared/utils/format';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import React from 'react';
import {useTranslation} from 'react-i18next';

type LeftPanelProps = {
  isMobile: boolean;
  animationFileKey: string | File | null;
  imageFileKey: string | File | null;
  campaignTitle?: string;
  children: React.ReactNode;
  defaultCurrency: Currency | null;
  pricing: CampaignPricing;
  prices: Price[];
  selectedLanguage: string;
};

const LeftPanel: React.FC<LeftPanelProps> = ({
  isMobile,
  animationFileKey,
  imageFileKey,
  campaignTitle,
  children,
  defaultCurrency,
  pricing,
  prices,
  selectedLanguage,
}) => {
  const price = prices.find(price => price.currency === defaultCurrency);

  const {t} = useTranslation('components', {
    keyPrefix: 'previews.CampaignStorePreview',
    lng: selectedLanguage,
  });

  return (
    <div
      className={cn('flex flex-col items-center justify-between', isMobile ? 'p-4' : 'h-full p-8')}
    >
      <div>
        {(imageFileKey || animationFileKey) && (
          <CardVisual
            bannerFileKey={imageFileKey ?? null}
            thumbnailFileKey={null}
            videoFileKey={animationFileKey ?? null}
            visualName={campaignTitle ?? ''}
            autoPlayVideo
          />
        )}
      </div>

      <div className="mt-8 w-full">
        <CampaignStoreInfo
          isMobile={isMobile}
          title={campaignTitle ?? 'Campaign Title'}
          subtitle={
            <div>
              {pricing === 'free' && t('labelFree')}
              {pricing === 'paid' && price && formatAmount(price.amount, price.currency)}
            </div>
          }
        />

        <div className="mt-8">{children}</div>
      </div>
    </div>
  );
};

export default LeftPanel;
