import {
  StripeConnectAccountDayOfWeekSchema,
  StripeCountryCodeSchema,
} from '@cohort/shared/schema/common/stripe';
import {z} from 'zod';

export const StripeConnectAccountASchema = z.object({
  detailsSubmitted: z.boolean(),
  chargesEnabled: z.boolean(),
  payoutsEnabled: z.boolean(),
  ready: z.boolean(),
  country: z.string(),
});
export type StripeConnectAccountADto = z.infer<typeof StripeConnectAccountASchema>;

export const StripeConnectAccountResponseASchema = z.object({
  account: StripeConnectAccountASchema.nullable(),
});
export type StripeConnectAccountResponseADto = z.infer<typeof StripeConnectAccountResponseASchema>;

export const CreateStripeConnectAccountDataASchema = z.object({
  country: StripeCountryCodeSchema,
});
export type CreateStripeConnectAccountDataADto = z.infer<
  typeof CreateStripeConnectAccountDataASchema
>;

export const StripeConnectOnboardingLinkASchema = z.object({
  url: z.string(),
});
export type StripeConnectOnboardingLinkADto = z.infer<typeof StripeConnectOnboardingLinkASchema>;

export const StripeConnectLoginLinkASchema = z.object({
  url: z.string(),
});
export type StripeConnectLoginLinkADto = z.infer<typeof StripeConnectLoginLinkASchema>;

export const StripeBalanceASchema = z.object({
  amount: z.number(),
  currency: z.string().length(3),
});
export type StripeBalanceADto = z.infer<typeof StripeBalanceASchema>;

export const StripeConnectAccountBalanceASchema = z.object({
  available: z.array(StripeBalanceASchema),
  pending: z.array(StripeBalanceASchema),
});
export type StripeConnectAccountBalanceADto = z.infer<typeof StripeConnectAccountBalanceASchema>;

export const StripeConnectAccountPayoutScheduleASchema = z.object({
  delayDays: z.number().min(0),
  interval: z.enum(['manual', 'daily', 'weekly', 'monthly']),
  dayOfMonth: z.number().min(1).max(31).nullable(),
  dayOfWeek: StripeConnectAccountDayOfWeekSchema.nullable(),
});
export type StripeConnectAccountPayoutScheduleADto = z.infer<
  typeof StripeConnectAccountPayoutScheduleASchema
>;

export const UpdateStripeConnectAccountPayoutScheduleDataASchema =
  StripeConnectAccountPayoutScheduleASchema.omit({
    delayDays: true,
  }).refine(
    data =>
      (data.interval === 'daily' && data.dayOfWeek === null && data.dayOfMonth === null) ||
      (data.interval === 'weekly' && data.dayOfWeek !== null && data.dayOfMonth === null) ||
      (data.interval === 'monthly' && data.dayOfMonth !== null && data.dayOfWeek === null)
  );
export type UpdateStripeConnectAccountPayoutScheduleDataADto = z.infer<
  typeof UpdateStripeConnectAccountPayoutScheduleDataASchema
>;
