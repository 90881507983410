import type {
  DigitalAssetAnalyticsADto,
  DigitalAssetOrderByADto,
} from '@cohort/admin-schemas/digitalAsset';
import DigitalAssetTokenIdBadge from '@cohort/merchants/components/digital-assets/DigitalAssetTokenIdBadge';
import ObtentionTypeBadge from '@cohort/merchants/components/ObtentionTypeBadge';
import DigitalAssetCollectionReferenceComponent from '@cohort/merchants/components/references/DigitalAssetCollectionReferenceComponent';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {formatDate} from '@cohort/shared/utils/format';
import {createColumnHelper} from '@tanstack/react-table';
import {useTranslation} from 'react-i18next';

type UserDigitalAssetsTableProps = {
  isLoading: boolean;
  digitalAssets: DigitalAssetAnalyticsADto[];
  sortBy: DigitalAssetOrderByADto;
  onSort: (key: string) => void;
};

const UserDigitalAssetsTable: React.FC<UserDigitalAssetsTableProps> = ({
  sortBy,
  onSort,
  isLoading,
  digitalAssets,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'users.user.digitalAssets.userDigitalAssetsTable',
  });
  const columnHelper = createColumnHelper<DigitalAssetAnalyticsADto>();
  const columns = [
    columnHelper.accessor('digitalAssetCollection.internalName', {
      header: t('tableHeaderCollection'),
      cell: data => (
        <DigitalAssetCollectionReferenceComponent
          digitalAssetCollectionId={data.row.original.digitalAssetCollection.id}
          internalName={data.row.original.digitalAssetCollection.internalName}
          imageFileKey={data.row.original.digitalAssetCollection.imageFileKey}
        />
      ),
      enableSorting: false,
    }),
    columnHelper.accessor(row => `#${row.tokenId}`, {
      header: t('tableHeaderTokenId'),
      cell: data => (
        <DigitalAssetTokenIdBadge tokenId={data.row.original.tokenId} nft={data.row.original.nft} />
      ),
      minSize: 100,
      enableSorting: false,
    }),
    columnHelper.accessor('ownership.obtentionType', {
      header: t('tableHeaderObtentionMethod'),
      cell: data => (
        <ObtentionTypeBadge type={data.getValue()} campaign={data.row.original.campaign} />
      ),
      enableSorting: false,
      minSize: 200,
    }),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    columnHelper.accessor(row => formatDate(row.ownership.obtentionDate!), {
      id: 'obtentionDate',
      header: t('tableHeaderObtentionDate'),
      minSize: 150,
    }),
  ];
  const table = useCohortTable(
    {
      columns,
      data: digitalAssets,
    },
    {
      sortBy,
      onSortUpdate: onSort,
    }
  );

  return (
    <DataTable
      table={table}
      columnsLength={columns.length}
      isLoading={isLoading}
      emptyStatePlaceholder={t('emptyPlaceholder')}
    />
  );
};
export default UserDigitalAssetsTable;
