import type {GetPerkAccessesParamsADto} from '@cohort/admin-schemas/perkAccesses';
import AdminPage from '@cohort/merchants/components/AdminPage';
import ExportCSV from '@cohort/merchants/components/buttons/ExportCSV';
import type {FilterOption} from '@cohort/merchants/components/filters/Filters';
import Filters from '@cohort/merchants/components/filters/Filters';
import KPICard from '@cohort/merchants/components/KPICard';
import {usePerkAccesses} from '@cohort/merchants/hooks/api/PerkAccesses';
import {useCurrentPerk} from '@cohort/merchants/hooks/contexts/currentPerk';
import {usePerkAccessesFilters} from '@cohort/merchants/hooks/filters/perkAccessesFilters';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {exportPerkAccesses} from '@cohort/merchants/lib/api/PerksAccesses';
import {trackPerkUsagesPageViewed} from '@cohort/merchants/lib/Tracking';
import PerkOwnersTable from '@cohort/merchants/pages/perks/perk/owners/PerkOwnersTable';
import type {PerkAccessStatus} from '@cohort/shared/schema/common/perkAccesses';
import {PerkAccessStatusSchema} from '@cohort/shared/schema/common/perkAccesses';
import {Stack} from '@phosphor-icons/react';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

const PAGE_SIZE = 20;

const PerkOwnersPage = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const perk = useCurrentPerk();
  const {t} = useTranslation('pages', {keyPrefix: 'perks.perk.owners.page'});
  const filters = usePerkAccessesFilters(/^(\/test)?\/perks\/.*\/owners$/u, '-lastUsageDate');

  const {data, isLoading} = usePerkAccesses(merchantId, {
    perkId: [perk.id],
    page: filters.page,
    pageSize: PAGE_SIZE,
    status: filters.status,
    orderBy: filters.sort,
  });
  const [pagination, perkAccesses] = data ?? [];

  useEffect(() => {
    trackPerkUsagesPageViewed(perk);
  }, [perk]);

  // i18nOwl-ignore [status.used, status.unused]
  const usageFilterOptions: FilterOption[] = PerkAccessStatusSchema.options.map(status => ({
    label: t(`status.${status}`),
    value: status,
    active: Array.isArray(filters.status)
      ? filters.status.some(s => s === status)
      : filters.status === status,
  }));

  const headerConfig = {
    title: t('titleOwners'),
    subtitle: t('subtitleOwners'),
    topRightElements: (
      <KPICard
        icon={<Stack className="h-4 w-4 text-gray-500" />}
        text={t('labelTotal')}
        value={`${perk.perkAccessesWithUsageCount} / ${perk.perkAccessesCount}`}
      />
    ),
  };

  const paginationConfig = {
    pagination,
    onPageChange: filters.setPage,
  };

  return (
    <AdminPage header={headerConfig} pagination={paginationConfig}>
      <div className="flex flex-row items-center justify-between">
        <Filters
          filters={[
            {
              label: t('filterByStatus'),
              options: usageFilterOptions,
              type: 'multiple',
              onChange: statusOptions => {
                const activeFilterOptions = statusOptions.filter(o => o.active);
                filters.setStatus(activeFilterOptions.map(o => o.value) as PerkAccessStatus[]);
              },
            },
          ]}
        />
        <ExportCSV
          disabled={!(pagination && pagination.total > 0)}
          downloadFunction={() =>
            exportPerkAccesses(merchantId, {
              perkId: perk.id,
              orderBy: filters.sort,
              status: filters.status,
              format: 'csv',
            })
          }
        />
      </div>
      <PerkOwnersTable
        perkAccesses={perkAccesses ?? []}
        sortBy={filters.sort}
        onSort={sort => filters.setSort(sort as GetPerkAccessesParamsADto['orderBy'])}
        isLoading={isLoading}
      />
    </AdminPage>
  );
};

export default PerkOwnersPage;
