import type {DigitalAssetOrderByADto} from '@cohort/admin-schemas/digitalAsset';
import AdminPage from '@cohort/merchants/components/AdminPage';
import ExportCSV from '@cohort/merchants/components/buttons/ExportCSV';
import KPICard from '@cohort/merchants/components/KPICard';
import {digitalAssetCollectionsKeys} from '@cohort/merchants/hooks/api/DigitalAssetCollections';
import {useDigitalAssets} from '@cohort/merchants/hooks/api/DigitalAssets';
import {useCurrentDigitalAssetCollection} from '@cohort/merchants/hooks/contexts/currentDigitalAssetCollection';
import {useDigitalAssetsFilters} from '@cohort/merchants/hooks/filters/digitalAssetsFilter';
import usePageSearchParam from '@cohort/merchants/hooks/pageSearchParam';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {exportDigitalAssets} from '@cohort/merchants/lib/api/DigitalAssets';
import DigitalAssetCollectionOwnersTable from '@cohort/merchants/pages/digital-asset-collections/digital-asset-collection/owners/DigitalAssetCollectionOwnersTable';
import {DeprecatedNetworks} from '@cohort/shared/schema/common/blockchain';
import {Users} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

const PAGE_SIZE = 20;

const DigitalAssetCollectionOwnersPage = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const digitalAssetCollection = useCurrentDigitalAssetCollection();
  const queryClient = useQueryClient();
  const {t} = useTranslation('pages', {
    keyPrefix: 'digitalAssetCollections.digitalAssetCollection.owners.page',
  });
  const page = usePageSearchParam();
  const [needPolling, setNeedPolling] = useState(false);
  const filters = useDigitalAssetsFilters(
    /^(\/test)?\/digital-assets\/.*\/owners$/u,
    '-obtentionDate'
  );

  const {data, isLoading} = useDigitalAssets(
    merchantId,
    {
      digitalAssetCollectionId: [digitalAssetCollection.id],
      page,
      pageSize: PAGE_SIZE,
      orderBy: filters.sort,
    },
    {
      autoRefresh: needPolling,
    }
  );
  const [pagination, digitalAssets] = data ?? [];

  const nftEnabled =
    digitalAssetCollection.nftEnabled &&
    !(
      digitalAssetCollection.smartContract?.network !== undefined &&
      DeprecatedNetworks.includes(digitalAssetCollection.smartContract.network)
    );

  useEffect(() => {
    if (
      digitalAssets &&
      (digitalAssets.some(digitalAsset => digitalAsset.tokenId === null) ||
        (digitalAssets.some(digitalAsset => digitalAsset.nft === null) && nftEnabled))
    ) {
      setNeedPolling(true);
    } else if (needPolling) {
      setNeedPolling(false);
      // we burst cache here to restore pagination computation
      queryClient.invalidateQueries(
        digitalAssetCollectionsKeys.getById(merchantId, digitalAssetCollection.id)
      );
    }
  }, [digitalAssetCollection.id, digitalAssets, merchantId, needPolling, nftEnabled, queryClient]);

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
    topRightElements: (
      <KPICard
        icon={<Users className="h-4 w-4 text-gray-500" />}
        text={t('labelOwnersCount')}
        value={pagination?.total ?? 0}
      />
    ),
  };

  const paginationConfig = {
    pagination,
  };

  return (
    <AdminPage header={headerConfig} pagination={paginationConfig}>
      <div className="flex items-center justify-end">
        <ExportCSV
          disabled={isLoading || (digitalAssets?.length ?? 0) === 0}
          downloadFunction={async () => exportDigitalAssets(merchantId, digitalAssetCollection.id)}
        />
      </div>
      <DigitalAssetCollectionOwnersTable
        sortBy={filters.sort}
        onSort={sort => filters.setSort(sort as DigitalAssetOrderByADto)}
        digitalAssets={digitalAssets ?? []}
        isLoading={isLoading}
      />
    </AdminPage>
  );
};

export default DigitalAssetCollectionOwnersPage;
