import type {Currency} from '@cohort/shared/schema/common/currency';
import {CohortError} from '@cohort/shared/schema/common/errors';
import {z} from 'zod';

type CountrySpec = {
  code: string;
  defaultLocale: string;
  defaultCurrency: Currency;
  stripeAcceptedCurrencies: ReadonlyArray<Currency>;
};

const specs = [
  {
    code: 'FR',
    defaultLocale: 'fr-FR',
    defaultCurrency: 'eur',
    stripeAcceptedCurrencies: ['eur', 'usd', 'jpy', 'krw', 'gbp'],
  },
  {
    code: 'US',
    defaultLocale: 'en-US',
    defaultCurrency: 'usd',
    stripeAcceptedCurrencies: ['usd', 'eur'],
  },
] as const satisfies ReadonlyArray<CountrySpec>;

export type Country = (typeof specs)[number]['code'];
const countries: [Country, ...Country[]] = [
  specs[0].code,
  ...specs.slice(1).map(spec => spec.code),
];
export const CountrySchema = z.enum(countries);

export const TEST_MODE_STRIPE_CONNECT_COUNTRY = 'FR';

export const CountrySpecs = {
  specs,
  get(code: string): CountrySpec | null {
    const spec = this.specs.find(spec => spec.code === code);
    return spec ?? null;
  },
  getOrThrow(code: string): CountrySpec {
    const spec = this.get(code);
    if (!spec) {
      throw new CohortError('country-spec.not-found', {code});
    }
    return spec;
  },
};
