import Button from '@cohort/merchants/components/buttons/Button';
import {Tabs, TabsList, TabsTrigger} from '@cohort/shared-frontend/components/Tabs';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {X} from '@phosphor-icons/react';
import {Desktop, DeviceMobile} from '@phosphor-icons/react';
import React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

type CampaignPreviewModalProps = {
  previewComponent: React.ReactElement;
  handleOnClose: () => void;
};

type PreviewType = 'web' | 'mobile';

const CampaignPreviewModal: React.FC<CampaignPreviewModalProps> = ({
  previewComponent,
  handleOnClose,
}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'campaigns.campaign.campaignPreviewModal'});
  const [previewMode, setPreviewMode] = useState<PreviewType>('web');
  const isMobile = previewMode === 'mobile';

  const previewModes = [
    {
      value: 'web',
      label: 'Web',
      icon: <Desktop size={20} />,
    },
    {
      value: 'mobile',
      label: 'Mobile',
      icon: <DeviceMobile size={20} />,
    },
  ];

  return (
    <div className="fixed left-0 top-0 z-50 flex h-full w-full flex-col items-end space-y-6 bg-slate-50 p-8">
      <div className="flex w-full flex-row items-center justify-between">
        <Button variant="secondary" data-testid="close-campaign-preview" onClick={handleOnClose}>
          <X size={20} className="-ml-1 mr-2 h-5 w-5" />
          {t('labelPreviewClose')}
        </Button>
        <Tabs defaultValue={previewMode} onValueChange={val => setPreviewMode(val as PreviewType)}>
          <TabsList className="grid w-full grid-cols-2">
            {previewModes.map(mode => (
              <TabsTrigger key={mode.value} value={mode.value} className="flex items-center gap-2">
                <span className={cn(previewMode === mode.value && 'text-primary')}>
                  {mode.icon}
                </span>
                {mode.label}
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>
      </div>

      <div
        className={cn(
          'flex w-full grow justify-center',
          isMobile && 'max-h-[calc(var(--xps-preview-max-height)-var(--submit-footer-height))]'
        )}
      >
        {React.cloneElement(previewComponent, {
          isMobile: isMobile,
          inPreviewModal: true,
          className: cn(
            isMobile
              ? 'overflow-y-auto no-scrollbar w-[var(--preview-width)]'
              : 'h-[calc(100vh-8rem)] overflow-hidden w-full'
          ),
        })}
      </div>
    </div>
  );
};

export default CampaignPreviewModal;
