import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import {EmptyContentContainer} from '@cohort/merchants/components/EmptyContentContainer';
import DraggableList from '@cohort/merchants/components/form/DraggableList';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {notify} from '@cohort/merchants/hooks/toast';
import {getCampaignsRoute} from '@cohort/merchants/lib/Pages';
import type {FormStepProps} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import RewardCollectionListItem from '@cohort/merchants/pages/campaigns/campaign/edit/reward/RewardCollectionListItem';
import RewardPerkListItem from '@cohort/merchants/pages/campaigns/campaign/edit/reward/RewardPerkListItem';
import RewardSelectionModal, {
  CollectionItemSchema,
  PerkItemSchema,
} from '@cohort/merchants/pages/campaigns/campaign/edit/reward/RewardSelectionModal';
import {CampaignTypeSchema} from '@cohort/shared/schema/common/campaign';
import {zodResolver} from '@hookform/resolvers/zod';
import {Plus} from '@phosphor-icons/react';
import {Gift} from '@phosphor-icons/react/dist/ssr';
import {Fragment, useEffect, useState} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {isEmpty} from 'remeda';
import {z} from 'zod';

export const CampaignRewardStepSchema = z
  .object({
    campaignType: CampaignTypeSchema,
    rewardBundle: z.object({
      collections: z.array(CollectionItemSchema),
      perks: z.array(PerkItemSchema),
    }),
  })
  .superRefine(({campaignType, rewardBundle}, ctx) => {
    // only journeys can have no rewards
    const hasAtLeastOneReward =
      rewardBundle.collections.length > 0 || rewardBundle.perks.length > 0;

    if (campaignType !== 'journey' && !hasAtLeastOneReward) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'minLength',
        path: ['rewardBundle'],
      });
    }
  });

export type CampaignRewardStepValues = z.infer<typeof CampaignRewardStepSchema>;

const CampaignRewardStep: React.FC<
  FormStepProps & {
    enableDigitalAssetCreation?: boolean;
  }
> = ({updateCampaign, setStepDirty, setStepSaveBtn, onStepValidated}): JSX.Element => {
  const campaign = useCurrentCampaign();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.campaignRewardStep',
  });
  const navigate = useNavigate();
  const [modalOpened, setModalOpened] = useState(false);

  const {
    control,
    handleSubmit,
    formState: {errors, isDirty},
    getValues,
  } = useForm<CampaignRewardStepValues>({
    defaultValues: {
      rewardBundle: campaign.rewardBundle,
      campaignType: campaign.type ?? undefined,
    },
    resolver: zodResolver(CampaignRewardStepSchema),
  });

  const {
    fields: collections,
    remove: removeCollection,
    replace: replaceCollections,
    append: addCollections,
  } = useFieldArray({
    control,
    name: 'rewardBundle.collections',
  });

  const {
    fields: perks,
    update: updatePerk,
    remove: removePerk,
    replace: replacePerks,
    append: addPerks,
  } = useFieldArray({
    control,
    name: 'rewardBundle.perks',
  });

  useEffect(() => {
    setStepDirty?.(isDirty);
  }, [isDirty, setStepDirty]);

  useEffect(() => {
    setStepSaveBtn?.(
      <Button
        variant="secondary"
        onClick={async () => {
          const formData = CampaignRewardStepSchema.parse(getValues());
          await updateCampaign(formData);
          notify('success', t('notificationSuccess'));
          navigate(getCampaignsRoute().path);
        }}
      >
        {t('saveBtn')}
      </Button>
    );
  }, [t, navigate, setStepSaveBtn, getValues, updateCampaign]);

  const rewardsCount = collections.length + perks.length;
  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
    topRightElements:
      rewardsCount === 0
        ? undefined
        : [
            <Button key="add-reward-btn" onClick={() => setModalOpened(true)}>
              {t('btnAddRewards')}
            </Button>,
          ],
  };

  return (
    <AdminPage header={headerConfig}>
      {modalOpened && (
        <RewardSelectionModal
          currentCollections={collections}
          currentPerks={perks}
          addCollections={addCollections}
          addPerks={addPerks}
          onClose={() => setModalOpened(false)}
        />
      )}
      <form
        className="space-y-12"
        id="campaign-reward-step"
        onSubmit={handleSubmit(async data => {
          await updateCampaign(data);
          onStepValidated();
        })}
      >
        {rewardsCount === 0 ? (
          <EmptyContentContainer
            icon={<Gift weight="duotone" size={40} className="text-slate-400" />}
            text={t('titleEmpty')}
            description={
              <Trans
                i18nKey="campaigns.campaign.edit.campaignRewardStep.descriptionEmpty"
                ns="pages"
              />
            }
            button={
              <Button
                onClick={() => setModalOpened(true)}
                data-testid="create-digital-asset-collection-empty-state"
              >
                <Plus className="-ml-1 mr-2 h-5 w-5" />
                {t('btnAddRewards')}
              </Button>
            }
          />
        ) : (
          <Fragment>
            {collections.length > 0 && (
              <div className="flex flex-col gap-4">
                <h2 className="text-lg font-semibold">{t('sectionDigitalAssets')}</h2>
                <DraggableList
                  handleOnReorder={data =>
                    replaceCollections(
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      data.map(id => collections.find(c => c.collectionId === id)!)
                    )
                  }
                  items={collections.map((collectionItem, idx) => ({
                    id: collectionItem.collectionId,
                    item: (
                      <RewardCollectionListItem
                        key={collectionItem.collectionId}
                        index={idx}
                        collectionItem={collectionItem}
                        handleRemove={() => removeCollection(idx)}
                      />
                    ),
                  }))}
                />
              </div>
            )}
            {perks.length > 0 && (
              <div className="flex flex-col gap-4">
                <h2 className="text-lg font-semibold">{t('sectionPerks')}</h2>
                <DraggableList
                  handleOnReorder={data =>
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    replacePerks(data.map(id => perks.find(p => p.perkId === id)!))
                  }
                  items={perks.map((perkItem, idx) => ({
                    id: perkItem.perkId,
                    item: (
                      <RewardPerkListItem
                        index={idx}
                        key={perkItem.perkId}
                        perkItem={perkItem}
                        handleRemove={() => removePerk(idx)}
                        setRevocable={revocable => {
                          updatePerk(idx, {...perkItem, revocable});
                        }}
                      />
                    ),
                  }))}
                />
              </div>
            )}
          </Fragment>
        )}
        {!isEmpty(errors) && (
          <p className="text-sm text-red-500" data-testid="digitalAssetCollectionId-error">
            {t('rewardsRequired')}
          </p>
        )}
      </form>
    </AdminPage>
  );
};

export default CampaignRewardStep;
