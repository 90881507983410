import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import type {CampaignJourneyPreviewValues} from '@cohort/merchants/components/campaigns/journeys/CampaignJourneyPreview';
import CampaignJourneyPreview from '@cohort/merchants/components/campaigns/journeys/CampaignJourneyPreview';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import {useJourneySteps} from '@cohort/merchants/hooks/api/Campaigns';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import useSectionsScroller from '@cohort/merchants/hooks/sectionScroller';
import {useCampaignPageStore} from '@cohort/merchants/hooks/stores/campaignPage';
import {notify} from '@cohort/merchants/hooks/toast';
import {
  changeSelectedLanguageIfNeeded,
  getDefinedLanguages,
} from '@cohort/merchants/lib/form/localization';
import {getFileType, handleFormErrors} from '@cohort/merchants/lib/form/utils';
import {getCampaignsRoute} from '@cohort/merchants/lib/Pages';
import CampaignPreviewAside from '@cohort/merchants/pages/campaigns/campaign/CampaignPreviewAside';
import type {
  CampaignSettingsStepValues,
  FormStepProps,
  FormStepPropsWithUpload,
} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import {CampaignSettingsStepSchema} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import {
  CampaignJourneySettingsStepSchema,
  DraftCampaignJourneySettingsStepSchema,
  uploadCampaignJourneyAssets,
} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/journey';
import CampaignSettingsSection from '@cohort/merchants/pages/campaigns/campaign/edit/settings/CampaignSettingsSection';
import FaqSection from '@cohort/merchants/pages/campaigns/campaign/edit/settings/FaqSection';
import JourneyAppearanceSection from '@cohort/merchants/pages/campaigns/campaign/edit/settings/journey/JourneyAppearanceSection';
import JourneyStepsSection from '@cohort/merchants/pages/campaigns/campaign/edit/settings/journey/JourneyStepsSection';
import OptionsSection from '@cohort/merchants/pages/campaigns/campaign/edit/settings/OptionsSection';
import {DEFAULT_ACCENT_COLOR, DEFAULT_BACKGROUND_COLOR} from '@cohort/shared/schema/common';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {isFileList} from '@cohort/shared-frontend/utils/isFile';
import {zodResolver} from '@hookform/resolvers/zod';
import get from 'lodash/get';
import {useEffect, useRef, useState} from 'react';
import {flushSync} from 'react-dom';
import type {FieldErrors} from 'react-hook-form';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {z} from 'zod';

const CampaignSettingsJourneyStep: React.FC<FormStepProps & FormStepPropsWithUpload> = ({
  isProcessing,
  uploadMutation,
  setStepDirty,
  setStepSaveBtn,
  onStepValidated,
  updateCampaign,
}): JSX.Element => {
  const campaign = useCurrentCampaign();
  const merchant = useCurrentMerchant();

  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.campaignSettingsJourneyStep',
  });
  const {
    data: journeySteps,
    isLoading: isJourneyStepsLoading,
    isFetched,
  } = useJourneySteps(merchant.id, campaign.id);

  const setCampaignPreview = useCampaignPageStore(state => state.setCampaignPreview);
  const navigate = useNavigate();
  const [emptyStepsError, setEmptyStepsError] = useState(false);
  const emptyStepsErrorRef = useRef<HTMLParagraphElement>(null);

  if (journeySteps && journeySteps.length > 0 && emptyStepsError === true) {
    setEmptyStepsError(false);
  }

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState,
    watch,
    setValue,
  } = useForm<CampaignSettingsStepValues>({
    defaultValues: {
      journey: {
        description: campaign.journey?.description ?? {},
        title: campaign.journey?.title ?? {},
        optInCta: campaign.journey?.optInCta ?? {},
        imageFileKey:
          campaign.journey?.imageFileKey ?? campaign.journey?.animationFileKey ?? undefined,
        animationFileKey: campaign.journey?.animationFileKey ?? undefined,
      },
      campaignType: campaign.type,
      withMaxSupply: campaign.totalSupply !== null,
      totalSupply: campaign.totalSupply ?? null,
      tosFileKey: campaign.tosFileKey,
      faqs: campaign.faqs,
      defaultLanguage: merchant.defaultLanguage,
      selectedLanguage: merchant.defaultLanguage,
      definedLanguages: getDefinedLanguages(merchant.defaultLanguage, [
        campaign.journey?.description ?? {},
        campaign.journey?.title ?? {},
        campaign.journey?.optInCta ?? {},
        campaign.faqs,
      ]),
    },
    resolver: zodResolver(CampaignSettingsStepSchema),
  });

  const [
    title,
    description,
    imageFileKey,
    animationFileKey,
    faqs,
    selectedLanguage,
    campaignWithMaxSupply,
    campaignTotalSupply,
    optInCta,
  ] = watch([
    'journey.title',
    'journey.description',
    'journey.imageFileKey',
    'journey.animationFileKey',
    'faqs',
    'selectedLanguage',
    'withMaxSupply',
    'totalSupply',
    'journey.optInCta',
  ]);
  const sectionsRefs = useSectionsScroller([
    'appearance',
    'steps',
    'options',
    'customer-information',
  ]);

  useEffect(() => {
    if (isFetched && journeySteps && journeySteps.length > 0) {
      setValue(
        'definedLanguages',
        getDefinedLanguages(merchant.defaultLanguage, [
          campaign.journey?.description ?? {},
          campaign.journey?.title ?? {},
          campaign.journey?.optInCta ?? {},
          campaign.faqs,
          ...journeySteps.map(step => ({
            ...step.title,
            ...step.description,
          })),
        ])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, journeySteps]);

  useEffect(() => {
    setStepDirty?.(formState.isDirty);
  }, [formState.isDirty, setStepDirty]);

  useEffect(() => {
    setStepSaveBtn?.(
      <Button
        loading={isProcessing}
        variant="secondary"
        onClick={async () => {
          try {
            const formData = DraftCampaignJourneySettingsStepSchema.parse(getValues());
            const patchCampaignData = await uploadCampaignJourneyAssets(formData, uploadMutation);
            clearErrors();
            await updateCampaign(patchCampaignData);
            notify('success', t('notificationSuccess'));
            navigate(getCampaignsRoute().path);
          } catch (error) {
            if (error instanceof z.ZodError) {
              handleFormErrors(error, clearErrors, setError);
              changeSelectedLanguageIfNeeded(error, merchant.defaultLanguage, lang =>
                setValue('selectedLanguage', lang)
              );
            } else {
              throw error;
            }
          }
        }}
      >
        {t('saveBtn')}
      </Button>
    );
  }, [
    clearErrors,
    getValues,
    setValue,
    isProcessing,
    merchant.defaultLanguage,
    navigate,
    setError,
    setStepSaveBtn,
    t,
    updateCampaign,
    uploadMutation,
  ]);

  useEffect(() => {
    if (selectedLanguage === undefined) {
      return;
    }

    const imageFileType = getFileType(imageFileKey);
    const computedImageFileKey = imageFileType === 'image' ? imageFileKey : null;
    const isVideoFileDefined = isFileList(imageFileKey) && animationFileKey;

    const computedAnimationFileKey = (): string | File | null => {
      if (isVideoFileDefined) {
        return animationFileKey;
      } else if (imageFileType === 'video') {
        return imageFileKey;
      }
      return null;
    };

    const previewValues = {
      accentColorCode: merchant.accentColorCode ?? DEFAULT_ACCENT_COLOR,
      animationFileKey: computedAnimationFileKey(),
      backgroundColorCode: merchant.backgroundColorCode ?? DEFAULT_BACKGROUND_COLOR,
      campaignTotalSupply: campaignWithMaxSupply ? campaignTotalSupply : null,
      description: description[selectedLanguage] ?? description[merchant.defaultLanguage] ?? null,
      imageFileKey: isFileList(computedImageFileKey) ? null : computedImageFileKey,
      faqs: faqs[selectedLanguage] ?? [],
      optInCta: optInCta[selectedLanguage] ?? optInCta[merchant.defaultLanguage] ?? null,
      selectedLanguage,
      steps: journeySteps ?? [],
      supportEmail: merchant.supportEmail,
      title: title[selectedLanguage] ?? title[merchant.defaultLanguage] ?? null,
    } satisfies CampaignJourneyPreviewValues;

    setCampaignPreview(
      <CampaignPreviewAside>
        <CampaignJourneyPreview previewValues={previewValues} />
      </CampaignPreviewAside>
    );

    return () => setCampaignPreview(null);
  }, [
    animationFileKey,
    campaignTotalSupply,
    campaignWithMaxSupply,
    description,
    faqs,
    // need to do this because FAQs reference is stable and useEffect is not deeply comparing changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(faqs),
    imageFileKey,
    optInCta,
    merchant.accentColorCode,
    merchant.backgroundColorCode,
    merchant.defaultLanguage,
    merchant.supportEmail,
    selectedLanguage,
    setCampaignPreview,
    title,
    journeySteps,
  ]);

  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  return (
    <AdminPage header={{title: t('title'), subtitle: t('subtitle')}}>
      <LanguageSelectorInput
        control={control}
        definedLanguagesPath="definedLanguages"
        selectedLanguagePath="selectedLanguage"
      />
      <form
        className="space-y-8"
        id="campaign-settings-step"
        onSubmit={handleSubmit(
          async data => {
            try {
              if (isJourneyStepsLoading) {
                return notify('error', t('notificationErrorLoadingSteps'));
              }

              if (journeySteps && journeySteps.length === 0) {
                flushSync(() => {
                  setEmptyStepsError(true);
                });
                return emptyStepsErrorRef.current?.scrollIntoView();
              }
              const formData = CampaignJourneySettingsStepSchema.parse(data);
              const patchCampaignData = await uploadCampaignJourneyAssets(formData, uploadMutation);
              await updateCampaign(patchCampaignData);
              onStepValidated();
            } catch (error) {
              if (error instanceof z.ZodError) {
                setEmptyStepsError(false);
                handleFormErrors(error, clearErrors, setError);
                changeSelectedLanguageIfNeeded(error, merchant.defaultLanguage, lang =>
                  setValue('selectedLanguage', lang)
                );
              } else {
                throw error;
              }
            }
          },
          errors => {
            changeSelectedLanguageIfNeeded(
              {...errors, ...get(errors, 'journey')} as FieldErrors,
              merchant.defaultLanguage,
              lang => setValue('selectedLanguage', lang)
            );
          }
        )}
      >
        <CampaignSettingsSection ref={sectionsRefs[0]?.ref} title={t('titleAppearance')}>
          <JourneyAppearanceSection control={control} register={register} />
        </CampaignSettingsSection>
        <CampaignSettingsSection ref={sectionsRefs[1]?.ref} title={t('titleSteps')}>
          <JourneyStepsSection control={control} register={register} />
          <p
            ref={emptyStepsErrorRef}
            className={cn('mt-2 text-sm text-red-500', emptyStepsError ? 'block' : 'hidden')}
          >
            {t('emptyStepsError')}
          </p>
        </CampaignSettingsSection>
        {isDefaultLanguageSelected && (
          <CampaignSettingsSection ref={sectionsRefs[2]?.ref} title={t('titleJourneyOptions')}>
            <OptionsSection control={control} register={register} />
          </CampaignSettingsSection>
        )}
        <CampaignSettingsSection ref={sectionsRefs[3]?.ref} title={t('titleCustomerInformation')}>
          <FaqSection control={control} register={register} key={selectedLanguage} />
        </CampaignSettingsSection>
      </form>
    </AdminPage>
  );
};

export default CampaignSettingsJourneyStep;
