import type {ListUserPropertiesOrderByOptions} from '@cohort/admin-schemas/userProperty';
import type {PaginationFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import {useBasePageFilters} from '@cohort/merchants/hooks/filters/basePageFilters';

type UserPropertiesUserFilters = {
  orderBy: ListUserPropertiesOrderByOptions;
  setOrderBy: (orderBy: ListUserPropertiesOrderByOptions) => void;
};

export const useUserPropertiesUserFilters = (): PaginationFilters & UserPropertiesUserFilters =>
  useBasePageFilters<UserPropertiesUserFilters>(/^(\/test)?\/users\/.*\/overview$/u, set => ({
    orderBy: 'name',
    setOrderBy: orderBy => set({orderBy}),
    reset: () => set({orderBy: 'name'}),
  }));
