import {getUserEventSpec} from '@cohort/shared/apps';
import type {UserEventStruct} from '@cohort/shared/apps/userEvent';
import type {DataType} from '@cohort/shared/schema/common/rules/dataTypes';
import {match} from 'ts-pattern';
import {z} from 'zod';

type UserEventPropertyOption = {
  name: string;
  dataType: DataType;
};

const eventDateOption: UserEventPropertyOption = {
  name: 'eventDate',
  dataType: 'date',
} as const;

export function getUserEventPropertyOptions<UE extends UserEventStruct>(
  eventId: UE['Id']
): UserEventPropertyOption[] {
  const eventStruct = getUserEventSpec(eventId);
  const propertiesSchema = eventStruct.propertiesSchema;
  const options = [eventDateOption];
  for (const name of eventStruct.rulesEngineConfig.exposedFields) {
    const resource = eventStruct.resources[name];
    const dataType =
      resource !== undefined ? 'resource' : getDataTypeFromSchema(propertiesSchema, name);

    if (dataType !== undefined) {
      options.push({
        name: String(name),
        dataType,
      });
    }
  }
  return options;
}

function getDataTypeFromSchema<UE extends UserEventStruct>(
  propertiesSchema: z.ZodSchema<UE['Properties']>,
  key: keyof UE['Properties']
): DataType | undefined {
  const schema = propertiesSchema[key as keyof typeof propertiesSchema];
  const dataType = match(schema)
    .when(
      schema => schema instanceof z.ZodString,
      (): DataType => 'string'
    )
    .when(
      schema => schema instanceof z.ZodArray && schema.element instanceof z.ZodString,
      (): DataType => 'string_list'
    )
    .when(
      schema => schema instanceof z.ZodNumber,
      (): DataType => 'number'
    )
    .when(
      schema => schema instanceof z.ZodBoolean,
      (): DataType => 'boolean'
    )
    .when(
      schema => schema instanceof z.ZodDate,
      (): DataType => 'date'
    )
    .otherwise(() => undefined);
  return dataType;
}
