import type {PerkIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import Input from '@cohort/merchants/components/form/input/Input';
import RadioCards from '@cohort/merchants/components/form/RadioCards';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {getQrReadersSettingsRoute} from '@cohort/merchants/lib/Pages';
import {useController} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

const CohortQrCodePerkIntegrationConfigComponent: React.FC<PerkIntegrationConfigComponentProps> = ({
  formReturn: {register, control},
}) => {
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'perkIntegrations.qr-code.configComponent',
  });

  const {field: validity} = useController({control, name: 'integration.config.validity'});
  const {field: maxUsagesPerkToken} = useController({control, name: 'maxUsagesPerToken'});

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!validity.value) {
    validity.onChange('unlimited');
  }

  return (
    <div className="space-y-6">
      <HighlightText
        type="info"
        text={
          <label>
            <Trans
              i18nKey="perkIntegrations.qr-code.configComponent.information"
              ns="app-cohort"
              components={{
                pageLink: (
                  <Link
                    className="cursor-pointer underline"
                    to={getQrReadersSettingsRoute().path}
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          </label>
        }
      />

      <RadioCards
        name="integration.config.validity"
        direction="row"
        label={t('labelUsage')}
        register={register}
        control={control}
        onChange={e => {
          if (e.target.value === 'once') {
            return maxUsagesPerkToken.onChange(1);
          }

          if (e.target.value === 'unlimited') {
            return maxUsagesPerkToken.onChange(null);
          }
        }}
        options={[
          {
            label: t('labelUsageOnce'),
            value: 'once',
          },
          {
            label: t('labelUsageUnlimited'),
            value: 'unlimited',
          },
          {
            label: t('labelUsageCustom'),
            value: 'custom',
          },
        ]}
      />
      {validity.value === 'custom' && (
        <Input
          type="number"
          name="maxUsagesPerToken"
          label={t('labelUsageNumber')}
          register={register}
          control={control}
        />
      )}
    </div>
  );
};

export default CohortQrCodePerkIntegrationConfigComponent;
