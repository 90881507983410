import {PasswordRequirements, PasswordSchema} from '@cohort/admin-schemas/auth';
import Button from '@cohort/merchants/components/buttons/Button';
import Input from '@cohort/merchants/components/form/input/Input';
import PasswordInput from '@cohort/merchants/components/form/input/PasswordInput';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {notify} from '@cohort/merchants/hooks/toast';
import {changePassword, requestChangePassword} from '@cohort/merchants/lib/api/Auth';
import {logout} from '@cohort/merchants/lib/Firebase';
import {getSignInRoute} from '@cohort/merchants/lib/Pages';
import {defaultErrorMessage} from '@cohort/shared/models';
import {EmailSchema} from '@cohort/shared/schema/common';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import {useTurnstile} from '@cohort/shared-frontend/hooks/useTurnstile';
import {zodResolver} from '@hookform/resolvers/zod';
import {Check, Circle} from '@phosphor-icons/react';
import React, {Fragment, useState} from 'react';
import type {Control} from 'react-hook-form';
import {useForm, useFormState} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {z} from 'zod';

type ChangePasswordFormProps = {
  email: string;
  code: string;
  expiresAt: number;
};

const FormSchema = z.object({
  email: EmailSchema,
  password: PasswordSchema,
});
type Form = z.infer<typeof FormSchema>;

type PasswordVerificationsProps = {
  control: Control<Form>;
};

const PasswordVerifications: React.FC<PasswordVerificationsProps> = ({control}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'change-password.changePasswordForm'});

  const {isDirty, errors: formErrors} = useFormState({
    control,
    name: 'password',
  });
  const errors = !isDirty ? PasswordRequirements : formErrors.password?.message?.split(',') ?? [];

  // i18nOwl-ignore [errors.length, errors.number, errors.uppercase, errors.special]
  return (
    <div className="grid grid-cols-2 space-y-1 text-xs text-slate-500">
      {PasswordRequirements.map(requirement => (
        <div key={requirement} className="flex items-center gap-x-2">
          <div className="w-3">
            {errors.includes(requirement) ? (
              <Circle className="h-2 w-2" />
            ) : (
              <Check className="h-3 w-3  text-green-500" weight="bold" />
            )}
          </div>
          {t(`errors.${requirement}`)}
        </div>
      ))}
    </div>
  );
};

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({email, code, expiresAt}) => {
  const [linkExpired, setLinkExpired] = useState(expiresAt < new Date().getTime());
  const [emailSent, setEmailSent] = useState(false);
  const {token, resetToken} = useTurnstile();

  const {t} = useTranslation('pages', {keyPrefix: 'change-password.changePasswordForm'});
  const navigate = useNavigate();

  const {mutate: handleChangePassword, isLoading: changePasswordLoading} = useCohortMutation({
    mutationFn: async ({password}: Form) => {
      if (!token) {
        return;
      }
      await changePassword(email, code, password, token);
    },
    onSuccess: async () => {
      await logout();
      navigate(getSignInRoute().path, {
        state: {
          passwordReset: 'true',
          email,
        },
        replace: true,
      });
    },
    onError: err => {
      if (isCohortError(err, 'auth.invalid-otp-code')) {
        setLinkExpired(true);
      } else {
        notify('error', defaultErrorMessage);
      }
    },
    onSettled: () => {
      resetToken?.();
    },
  });

  const {mutate: handleResendEmail, isLoading: resendEmailLoading} = useCohortMutation({
    mutationFn: async () => {
      if (!token) {
        return;
      }
      const res = await requestChangePassword(email, token);
      return res;
    },
    onSuccess: async () => {
      resetToken?.();
      setEmailSent(true);
    },
  });

  const {register, control, handleSubmit, formState} = useForm<Form>({
    defaultValues: {
      email,
      password: '',
    },
    mode: 'onChange',
    resolver: zodResolver(FormSchema),
  });

  if (emailSent) {
    return (
      <div className="flex flex-col space-y-2">
        <h1 className="text-2xl font-semibold text-slate-900">{t('titleEmailSent')}</h1>
        <p className="text-sm text-slate-500">{t('subtitleEmailSent')}</p>
      </div>
    );
  }

  if (linkExpired) {
    return (
      <Fragment>
        <h1 className="text-2xl font-semibold text-slate-900">{t('titleChangePassword')}</h1>
        <HighlightText type="error" text={t('infoLinkExpired')} />
        <Input
          name="email"
          register={register}
          control={control}
          label={t('labelEmail')}
          disabled
          hideError
        />
        <Button
          type="button"
          className="h-11 w-full"
          onClick={() => handleResendEmail()}
          loading={resendEmailLoading}
        >
          {t('buttonResendEmail')}
        </Button>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <h1 className="text-2xl font-semibold text-slate-900">{t('titleChangePassword')}</h1>
      <form
        className="flex flex-col space-y-6"
        onSubmit={handleSubmit(data => handleChangePassword(data))}
      >
        <Input
          name="email"
          register={register}
          control={control}
          label={t('labelEmail')}
          disabled
          hideError
        />
        <PasswordInput
          name="password"
          register={register}
          control={control}
          disabled={changePasswordLoading}
          hideError
        />
        <PasswordVerifications control={control} />
        <Button
          type="submit"
          className="h-11 w-full"
          loading={changePasswordLoading}
          disabled={!formState.isValid}
        >
          {t('buttonChangePassword')}
        </Button>
      </form>
    </Fragment>
  );
};
export default ChangePasswordForm;
