import {ConnectorIdSchema} from '@cohort/shared/apps';
import {DateSchema} from '@cohort/shared/schema/common';
import {asArray} from '@cohort/shared/schema/common';
import {ConnectionStatusSchema} from '@cohort/shared/schema/common/connection';
import {z} from 'zod';

export const ConnectionASchema = z.object({
  id: z.string().uuid(),
  merchantId: z.string().uuid(),
  name: z.string(),
  status: ConnectionStatusSchema,
  connectorId: ConnectorIdSchema,
  createdAt: DateSchema,
  updatedAt: DateSchema,
});
export type ConnectionADto = z.infer<typeof ConnectionASchema>;

// Create Connection

export const CreateConnectionDataASchema = z.object({
  connectorId: ConnectorIdSchema,
  name: z.string().nullable(),
  credentials: z.unknown(),
  /**
   * Pass this if you want to recreate an existing connection.
   */
  existingConnectionId: z.string().uuid().optional(),
});
export type CreateConnectionDataADto = z.infer<typeof CreateConnectionDataASchema>;

// Update Connection

export const ConnectionNameSchema = z.string().min(3, {message: 'errorTooShort3'});
export const PatchConnectionDataASchema = z.object({
  name: ConnectionNameSchema.optional(),
  expiresAt: DateSchema.nullable().optional(),
});
export type PatchConnectionDataADto = z.infer<typeof PatchConnectionDataASchema>;

// Configure Connection
export const ConfigureConnectionDataASchema = z.object({
  config: z.unknown(),
});
export type ConfigureConnectionDataADto = z.infer<typeof ConfigureConnectionDataASchema>;

// List Connections

export const ListConnectionsParamsASchema = z.object({
  connectorId: z.preprocess(asArray, z.array(ConnectorIdSchema)).optional(),
});
export type ListConnectionsParamsADto = z.infer<typeof ListConnectionsParamsASchema>;

export const ListConnectionsResponseASchema = z.array(ConnectionASchema);
export type ListConnectionsResponseADto = z.infer<typeof ListConnectionsResponseASchema>;

// Get Authorization URL

export const GetAuthorizationUrlDataASchema = z.object({
  connectorId: ConnectorIdSchema,
  existingConnectionId: z.string().uuid().optional(),
  adminRedirectUrl: z.string().url(),
  context: z.record(z.unknown()).optional(),
});
export type GetAuthorizationUrlDataADto = z.infer<typeof GetAuthorizationUrlDataASchema>;

export const GetAuthorizationUrlResponseASchema = z.object({
  authorizationUrl: z.string(),
});
export type GetAuthorizationUrlResponseADto = z.infer<typeof GetAuthorizationUrlResponseASchema>;

// Connection Profile Dto
export const ConnectionProfileASchema = z.object({
  id: z.string(),
  displayName: z.string(),
  properties: z.record(z.unknown()),
});
export type ConnectionProfileADto = z.infer<typeof ConnectionProfileASchema>;
