import AsidePageMenu from '@cohort/merchants/components/AsidePageMenu';
import {getBadgesRoute, getCohortsRoute, getUsersRoute} from '@cohort/merchants/lib/Pages';
import {ChartPieSlice, Medal, Users} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

const UsersOverviewMenu = (): JSX.Element => {
  const {t} = useTranslation('pages', {keyPrefix: 'users.overview.usersOverviewMenu'});

  return (
    <AsidePageMenu
      entries={[
        {
          name: t('labelUsers'),
          path: getUsersRoute().path,
          icon: <Users size={20} />,
          testId: 'users-link',
        },
        {
          name: t('labelCohorts'),
          path: getCohortsRoute().path,
          icon: <ChartPieSlice size={20} />,
          testId: 'users-cohorts-link',
        },
        {
          name: t('labelBadges'),
          path: getBadgesRoute().path,
          icon: <Medal size={20} />,
          testId: 'badges-link',
        },
      ]}
    />
  );
};

export default UsersOverviewMenu;
