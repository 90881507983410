import {Card, CardText, CardTitle} from '@cohort/components-xps/components/cards/Card';
import {OwnedCardOverlay} from '@cohort/components-xps/components/cards/CardOverlays';
import CardVisual from '@cohort/components-xps/components/cards/CardVisual';
import CustomComponentCard from '@cohort/components-xps/components/cards/CustomComponentCard';
import i18nComponentsXpsInstance from '@cohort/components-xps/lib/i18n';
import type {RaffleInstanceStatus} from '@cohort/shared/schema/common/perks';
import type {PerkContextTDto} from '@cohort/shared/schema/templating/perk';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {CaretRight} from '@phosphor-icons/react';
import React from 'react';
import {getI18n, useTranslation} from 'react-i18next';

export type PerkCardProps = {
  availableInstances: number;
  canBeObtained?: boolean;
  canBeUsed?: boolean;
  customComponent?: {
    context: PerkContextTDto;
    template: string;
  };
  hideMediaCover?: boolean;
  onClick?: () => void;
  perk: {
    bannerFileKey: string | null | File;
    displayName: string;
    raffle?: {
      status?: RaffleInstanceStatus | null;
    };
    statusMessage?: string;
    thumbnailFileKey: string | null | File;
    videoFileKey: string | null | File;
  };
};

const PerkCard: React.FC<PerkCardProps> = ({
  perk,
  canBeUsed,
  customComponent,
  onClick,
  hideMediaCover,
  availableInstances,
  canBeObtained = true,
}): React.ReactElement => {
  const {t} = useTranslation('components', {
    keyPrefix: 'cards.perkCard',
    lng: getI18n().language,
    i18n: i18nComponentsXpsInstance,
  });

  const hasVideoMedia = perk.videoFileKey !== null;
  const enabled =
    canBeUsed !== false || perk.raffle?.status === 'pending' || perk.raffle?.status === 'won';

  const getStatusMessage = (): string => {
    if (perk.raffle?.status !== null && perk.raffle?.status !== undefined) {
      if (perk.raffle.status === 'won' && canBeUsed === false) {
        return t('used');
      }
      if (perk.statusMessage === undefined) {
        throw new Error('Raflle is defined and StatusMessage is undefined');
      }
      return perk.statusMessage;
    }
    if (canBeUsed === true) {
      return `${availableInstances > 1 ? `${availableInstances} ` : ''}${t('available')}`;
    }
    return t('used');
  };

  return (
    <div
      onClick={onClick}
      className={cn(
        'h-full',
        !enabled && 'pointer-events-none',
        enabled && onClick !== undefined && 'cursor-pointer'
      )}
    >
      {customComponent ? (
        <div className={cn('relative', !enabled && 'opacity-40')}>
          <CustomComponentCard
            context={customComponent.context}
            template={customComponent.template}
          />
          {!canBeObtained && <OwnedCardOverlay text={t('alreadyOwned')} />}
        </div>
      ) : (
        <Card
          className={cn(
            'grid h-full w-full gap-4 overflow-hidden',
            hideMediaCover
              ? 'grid-cols-1'
              : '[grid-template-rows:var(--perk-card-visual-height)_1fr]'
          )}
          onClick={e => {
            if (!hasVideoMedia && onClick !== undefined) {
              e.stopPropagation();
              onClick();
            }
          }}
        >
          {!hideMediaCover && (
            <div className="relative">
              <CardVisual
                bannerFileKey={perk.bannerFileKey}
                thumbnailFileKey={perk.thumbnailFileKey}
                videoFileKey={perk.videoFileKey}
                visualName={perk.displayName}
                enforce16x9AspectRatio
                className={cn(!enabled && 'opacity-40')}
              />
              {!canBeObtained && <OwnedCardOverlay text={t('alreadyOwned')} />}
            </div>
          )}
          <div
            className="flex flex-col justify-between gap-2 rounded-b-xl"
            onClick={
              onClick !== undefined
                ? e => {
                    e.stopPropagation();
                    onClick();
                  }
                : undefined
            }
          >
            <CardTitle className="flex items-center justify-between">
              {perk.displayName}
              {onClick !== undefined && enabled && (
                <CaretRight
                  size={24}
                  style={{
                    color: 'var(--xps-accent-color)',
                  }}
                />
              )}
            </CardTitle>
            {canBeUsed !== undefined && (
              <div className="flex space-x-2">
                <div
                  className={cn(
                    'my-auto h-2 w-2 rounded-full bg-[--xps-color]',
                    !enabled ? 'opacity-20' : 'opacity-100'
                  )}
                />
                <CardText>{getStatusMessage()}</CardText>
              </div>
            )}
          </div>
        </Card>
      )}
    </div>
  );
};

export default PerkCard;
