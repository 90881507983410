import type {PerkUsageADto} from '@cohort/admin-schemas/perkAccesses';
import {usePerkIntegrations} from '@cohort/merchants/apps/usePerkIntegrations';
import Button from '@cohort/merchants/components/buttons/Button';
import {SelectPicker} from '@cohort/merchants/components/form/select/SelectPicker';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@cohort/merchants/components/modals/Dialog';
import {formatDate} from '@cohort/shared/utils/format';
import {FileMagnifyingGlass} from '@phosphor-icons/react';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';

type PerkUsageRowProps = {
  usage: PerkUsageADto;
};

const PerkUsageRow: React.FC<PerkUsageRowProps> = ({usage}) => {
  const {getPerkIntegration} = usePerkIntegrations();

  const integration =
    usage.perkIntegrationId === null ? null : getPerkIntegration(usage.perkIntegrationId);

  return (
    <div>
      <div className="flex flex-row pb-6 text-sm font-normal text-slate-700">
        <FileMagnifyingGlass className="inline h-5 w-5 text-slate-400" />
        <div className="ml-2 space-y-2">
          <div className="text-xs text-slate-500">{formatDate(usage.usedAt)}</div>
        </div>
      </div>
      {integration !== null && (
        <div className="rounded-md border border-slate-200 bg-slate-50 px-4 text-sm text-slate-700">
          <integration.usageViewComponent usage={usage} />
        </div>
      )}
    </div>
  );
};

type PerkUsagesSelectorProps = {
  perkAnalyticsUsages: Array<PerkUsageADto>;
};

const PerkUsagesSelector: React.FC<PerkUsagesSelectorProps> = ({perkAnalyticsUsages}) => {
  const [selectedPerkAnalyticsUsage, setSelectedPerkAnalyticsUsage] = useState<
    PerkUsageADto | undefined
  >(perkAnalyticsUsages[0]);
  const {t} = useTranslation('components', {
    keyPrefix: 'perks.perkUsageDetailsModal',
  });

  if (selectedPerkAnalyticsUsage === undefined) {
    return <Fragment />;
  }

  if (perkAnalyticsUsages.length === 1) {
    const usage = perkAnalyticsUsages[0];
    if (usage === undefined) {
      throw new Error('Never happens: Perk usage is undefined');
    }
    return <PerkUsageRow usage={usage} />;
  }

  const selectPickerValue = {
    value: selectedPerkAnalyticsUsage.id,
    label: t('perkUsagesSelector.usageLabel', {
      atttemptNumber: perkAnalyticsUsages.indexOf(selectedPerkAnalyticsUsage) + 1,
    }),
  };

  const selectPickerOptions = perkAnalyticsUsages.map((usage, index) => {
    return {
      value: usage.id,
      label: t('perkUsagesSelector.usageLabel', {
        atttemptNumber: index + 1,
      }),
    };
  });

  return (
    <div className="space-y-6">
      <SelectPicker
        value={selectPickerValue}
        options={selectPickerOptions}
        className="w-1/3"
        name="step completion attempts selector"
        onChange={option => {
          if (option !== null) {
            setSelectedPerkAnalyticsUsage(
              perkAnalyticsUsages.find(usage => {
                return usage.id === option.value;
              })
            );
          }
        }}
      />
      <PerkUsageRow usage={selectedPerkAnalyticsUsage} />
    </div>
  );
};

type PerkUsageDetailsModalProps = {
  onClose: () => void;
  title: string;
  perkAnalyticsUsages: Array<PerkUsageADto>;
};

const PerkUsageDetailsModal: React.FC<PerkUsageDetailsModalProps> = ({
  onClose,
  title,
  perkAnalyticsUsages,
}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'perks.perkUsageDetailsModal',
  });

  return (
    <Dialog onOpenChange={onClose} open>
      <DialogContent className="w-[600px]">
        <DialogHeader className="space-y-6">
          <DialogTitle className="text-lg font-semibold tracking-normal">{title}</DialogTitle>
        </DialogHeader>

        <DialogBody className="pb-3">
          {perkAnalyticsUsages.length > 0 && (
            <PerkUsagesSelector perkAnalyticsUsages={perkAnalyticsUsages} />
          )}
        </DialogBody>

        <DialogFooter className="flex justify-end bg-transparent">
          <Button onClick={onClose} variant="primary">
            {t('buttonClose')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PerkUsageDetailsModal;
