import type {ContentCategoryADto} from '@cohort/admin-schemas/contentCategory';
import DropdownButton from '@cohort/merchants/components/buttons/DropdownButton';
import CardListDisplay from '@cohort/merchants/components/CardListDisplay';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import RankBadge from '@cohort/merchants/components/RankBadge';
import {contentCategoriesKeys} from '@cohort/merchants/hooks/api/ContentCategories';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {notify} from '@cohort/merchants/hooks/toast';
import {
  deleteContentCategory,
  updateContentCategory,
} from '@cohort/merchants/lib/api/ContentCategories';
import type {ContentCategoryFormValues} from '@cohort/merchants/pages/content-categories/overview/CreateEditContentCategoryModal';
import CreateEditContentCategoryModal from '@cohort/merchants/pages/content-categories/overview/CreateEditContentCategoryModal';
import {PencilSimple, Trash} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';

type ContentCategoryListProps = {
  contentCategory: ContentCategoryADto;
  handleEditContentCategory: (contentCategory: ContentCategoryADto) => void;
  handleDeleteContentCategory: (contentCategory: ContentCategoryADto) => void;
};

const ContentCategoryListItem: React.FC<ContentCategoryListProps> = ({
  contentCategory,
  handleEditContentCategory,
  handleDeleteContentCategory,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'content-categories.overview.contentCategoriesList',
  });

  return (
    <li data-testid="content-category-card" className="flex justify-between border-t py-4">
      <div className="flex items-center space-x-4">
        <RankBadge rank={contentCategory.rank} />
        <div className="text-sm font-medium">{contentCategory.internalName}</div>
      </div>
      <DropdownButton
        entries={[
          {
            id: 'edit',
            label: <span className="text-slate-700">{t('contentCategoryListItem.labelEdit')}</span>,
            icon: <PencilSimple className="h-4 w-4 text-slate-400" />,
            handleOnClick: () => handleEditContentCategory(contentCategory),
          },
          {
            id: 'delete',
            label: <span className="text-red-500">{t('contentCategoryListItem.labelDelete')}</span>,
            icon: <Trash className="h-4 w-4 text-red-500" />,
            handleOnClick: () => handleDeleteContentCategory(contentCategory), // deleteContentCategoryMutation
          },
        ]}
      />
    </li>
  );
};

type ContentCategoriesListProps = {
  contentCategories: Array<ContentCategoryADto>;
};

const ContentCategoriesList: React.FC<ContentCategoriesListProps> = ({contentCategories}) => {
  const [contentCategoryToEdit, setContentCategoryToEdit] = useState<ContentCategoryADto | null>(
    null
  );
  const [contentCategoryToDelete, setContentCategoryToDelete] =
    useState<ContentCategoryADto | null>(null);
  const {t} = useTranslation('pages', {
    keyPrefix: 'content-categories.overview.contentCategoriesList',
  });
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();

  const handleEditContentCategory = (contentCategory: ContentCategoryADto): void => {
    setContentCategoryToEdit(contentCategory);
  };

  const handleDeleteContentCategory = (contentCategory: ContentCategoryADto): void => {
    setContentCategoryToDelete(contentCategory);
  };

  const {isLoading: isUpdateLoading, mutate: updateContentCategoryMutation} = useCohortMutation({
    mutationFn: async (data: ContentCategoryFormValues) => {
      if (contentCategoryToEdit === null) return;
      return updateContentCategory(merchant.id, contentCategoryToEdit.id, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(contentCategoriesKeys.list(merchant.id));
      setContentCategoryToEdit(null);
      notify('success', t('updateContentCategorySuccess'));
    },
  });

  const {mutate: deleteContentCategoryMutation} = useCohortMutation({
    mutationFn: async (categoryId: string) => deleteContentCategory(merchant.id, categoryId),
    onSuccess: () => {
      queryClient.invalidateQueries(contentCategoriesKeys.list(merchant.id));
      notify('success', t('deleteContentCategorySuccess'));
    },
  });

  return (
    <Fragment>
      <CardListDisplay display="list">
        {contentCategories.map(contentCategory => (
          <ContentCategoryListItem
            key={contentCategory.id}
            contentCategory={contentCategory}
            handleEditContentCategory={handleEditContentCategory}
            handleDeleteContentCategory={handleDeleteContentCategory}
          />
        ))}
      </CardListDisplay>
      {contentCategoryToEdit !== null && (
        <CreateEditContentCategoryModal
          onClose={() => setContentCategoryToEdit(null)}
          contentCategoryToEdit={contentCategoryToEdit}
          onSubmit={updateContentCategoryMutation}
          isLoading={isUpdateLoading}
        />
      )}
      {contentCategoryToDelete !== null && (
        <DeletionModal
          title={t('deletionConfirmationModalTitle')}
          subtitle={t('deletionConfirmationModalText')}
          show
          onClose={() => setContentCategoryToDelete(null)}
          onDelete={() => {
            deleteContentCategoryMutation(contentCategoryToDelete.id);
            setContentCategoryToDelete(null);
          }}
        />
      )}
    </Fragment>
  );
};

export default ContentCategoriesList;
