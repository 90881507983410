import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import RadioCards from '@cohort/merchants/components/form/RadioCards';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {notify} from '@cohort/merchants/hooks/toast';
import {handleFormErrors} from '@cohort/merchants/lib/form/utils';
import {getCampaignsRoute} from '@cohort/merchants/lib/Pages';
import type {FormStepProps} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import {CampaignTypeSchema} from '@cohort/shared/schema/common/campaign';
import {zodResolver} from '@hookform/resolvers/zod';
import {Broadcast, Flag, Storefront} from '@phosphor-icons/react';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {z} from 'zod';

export const CampaignTypeStepSchema = z.object({
  type: CampaignTypeSchema,
});

type CampaignTypeStepValues = z.infer<typeof CampaignTypeStepSchema>;

const CampaignTypeStep: React.FC<FormStepProps> = ({
  isProcessing,
  setStepSaveBtn,
  onStepValidated,
  updateCampaign,
}): JSX.Element => {
  const campaign = useCurrentCampaign();
  const {t} = useTranslation('pages', {keyPrefix: 'campaigns.campaign.edit.campaignTypeStep'});
  const {register, control, handleSubmit, getValues, setError, clearErrors} =
    useForm<CampaignTypeStepValues>({
      defaultValues: {
        type: campaign.type ?? 'store',
      },
      resolver: zodResolver(CampaignTypeStepSchema),
    });
  const navigate = useNavigate();

  useEffect(() => {
    setStepSaveBtn?.(
      <Button
        variant="secondary"
        loading={isProcessing}
        onClick={async () => {
          try {
            const campaignData = CampaignTypeStepSchema.partial().parse(getValues());
            const hasChangedCampaignType = campaignData.type !== campaign.type;

            clearErrors();
            await updateCampaign({
              ...campaignData,
              ...(hasChangedCampaignType && {audience: null, cohortId: null}),
            });
            notify('success', t('notificationSuccess'));
            navigate(getCampaignsRoute().path);
          } catch (error) {
            if (error instanceof z.ZodError) {
              handleFormErrors(error, clearErrors, setError);
            } else {
              throw error;
            }
          }
        }}
      >
        {t('saveBtn')}
      </Button>
    );
  }, [
    t,
    clearErrors,
    getValues,
    setError,
    updateCampaign,
    isProcessing,
    navigate,
    setStepSaveBtn,
    campaign.type,
  ]);

  return (
    <AdminPage header={{title: t('title'), subtitle: t('subtitle')}}>
      <form
        id="campaign-type-step"
        onSubmit={handleSubmit(async data => {
          const hasChangedCampaignType = data.type !== campaign.type;

          await updateCampaign({
            ...data,
            ...(hasChangedCampaignType && {audience: null, cohortId: null}),
          });
          onStepValidated();
        })}
      >
        <RadioCards
          name="type"
          direction="column"
          label={t('labelType')}
          register={register}
          control={control}
          options={[
            {
              prefix: (
                <div className="rounded-md bg-green-100 p-2">
                  <Storefront className="h-6 w-6 text-green-500" />
                </div>
              ),
              label: t('labelStore'),
              description: t('descriptionStore'),
              value: 'store',
            },
            {
              prefix: (
                <div className="rounded-md bg-primary/10 p-2">
                  <Flag className="h-6 w-6 text-primary" />
                </div>
              ),
              label: t('labelJourney'),
              description: t('descriptionJourney'),
              value: 'journey',
            },
            {
              prefix: (
                <div className="rounded-md bg-sky-100 p-2">
                  <Broadcast className="h-6 w-6 text-sky-500" />
                </div>
              ),
              label: t('labelAirdrop'),
              description: t('descriptionAirdrop'),
              value: 'airdrop',
            },
          ]}
        />
      </form>
    </AdminPage>
  );
};

export default CampaignTypeStep;
