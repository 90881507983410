import type {NotificationConfigurationADto} from '@cohort/admin-schemas/notification';
import NotificationConfigurationCard from '@cohort/merchants/components/notifications/NotificationConfigurationCard';
import NotificationSettingsListCreateBtn from '@cohort/merchants/components/notifications/NotificationSettingsListCreateBtn';
import type {EventId} from '@cohort/shared/apps';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Fragment} from 'react';

type NotificationGlobalSettingsListRowBodyProps = {
  notificationConfigurations: NotificationConfigurationADto[];
  userEventId: EventId;
};

const NotificationGlobalSettingsListRowBody: React.FC<
  NotificationGlobalSettingsListRowBodyProps
> = ({notificationConfigurations, userEventId}) => {
  return (
    <Fragment>
      <div className={cn('space-y-4', notificationConfigurations.length > 0 && 'mb-4')}>
        {notificationConfigurations
          .filter(notificationConfiguration => notificationConfiguration.resourceType === null)
          .map(notificationConfiguration => {
            return (
              <NotificationConfigurationCard
                key={notificationConfiguration.id}
                notificationConfiguration={notificationConfiguration}
                disabled={false}
                showActionsBtns
              />
            );
          })}
      </div>
      <NotificationSettingsListCreateBtn userEventId={userEventId} />
    </Fragment>
  );
};

export default NotificationGlobalSettingsListRowBody;
