import type {DigitalAssetCollectionAnalyticsADto} from '@cohort/admin-schemas/digitalAssetCollection';
import {useDigitalAssetCollection} from '@cohort/merchants/hooks/api/DigitalAssetCollections';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {createContext, Fragment} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

type CurrentDigitalAssetCollectionProviderProps = {
  children: React.ReactNode;
};

export const CurrentDigitalAssetCollectionContext =
  createContext<DigitalAssetCollectionAnalyticsADto | null>(null);

export const CurrentDigitalAssetCollectionProvider: React.FC<
  CurrentDigitalAssetCollectionProviderProps
> = ({children}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {digitalAssetCollectionId} = useParams();
  const navigate = useNavigate();

  const {data: digitalAssetCollection, isFetched} = useDigitalAssetCollection(
    merchantId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    digitalAssetCollectionId!,
    {enabled: digitalAssetCollectionId !== undefined}
  );

  if (digitalAssetCollectionId === undefined) {
    navigate('/not-found', {replace: true});
  }

  if (isFetched && !digitalAssetCollection) {
    navigate('/not-found', {replace: true});
  }

  // Maybe one day we could create Skeletons for page loading
  if (digitalAssetCollection === undefined) {
    return <Fragment />;
  }

  return (
    <CurrentDigitalAssetCollectionContext.Provider value={digitalAssetCollection}>
      {children}
    </CurrentDigitalAssetCollectionContext.Provider>
  );
};
