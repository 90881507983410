import type {OrderADto} from '@cohort/admin-schemas/orders';
import UserReferenceComponent from '@cohort/merchants/components/references/UserReferenceComponent';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {CurrencySpecs} from '@cohort/shared/schema/common/currency';
import {formatDate} from '@cohort/shared/utils/format';
import {createColumnHelper} from '@tanstack/react-table';
import React from 'react';
import {useTranslation} from 'react-i18next';

type StoreOrdersTableProps = {
  orders: Array<OrderADto>;
  isLoading: boolean;
};

const StoreOrdersTable: React.FC<StoreOrdersTableProps> = ({orders, isLoading}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.activity.store.storeOrdersTable',
  });
  const campaign = useCurrentCampaign();
  const columnHelper = createColumnHelper<OrderADto>();
  const columns = [
    columnHelper.accessor('user', {
      header: t('tableHeaderUser'),
      minSize: 250,
      cell: data => <UserReferenceComponent user={data.getValue()} />,
      enableSorting: false,
    }),
    columnHelper.accessor(row => formatDate(row.createdAt), {
      id: 'createdAt',
      header: t('tableHeaderDate'),
      minSize: 200,
      enableSorting: false,
    }),
    columnHelper.accessor('id', {
      header: t('tableHeaderOrderId'),
      minSize: 300,
      enableSorting: false,
    }),
    columnHelper.accessor(row => getPrice(row), {
      id: 'amount',
      header: t('tableHeaderPaidPrice'),
      minSize: 200,
      enableSorting: false,
    }),
  ];

  const getPrice = (order: OrderADto): string => {
    if (order.amount === 0 || order.currency === null) {
      return '-';
    }
    const amount = CurrencySpecs.toDecimal(order.currency, order.amount);
    return `${amount} ${order.currency.toUpperCase()}`;
  };
  const table = useCohortTable({
    data: orders,
    columns,
  });

  return (
    <DataTable
      table={table}
      columnsLength={columns.length}
      emptyStatePlaceholder={
        campaign.audience === 'invitees' ? t('titleEmptyInvitees') : t('titleEmptyOrders')
      }
      isLoading={isLoading}
    />
  );
};

export default StoreOrdersTable;
