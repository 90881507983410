import {cn} from '@cohort/shared-frontend/utils/classNames';
import {isDark} from '@cohort/shared-frontend/utils/isDark';
import {useEffect} from 'react';

type BtnTextTransform = 'none' | 'uppercase' | 'lowercase' | 'capitalize';

type PreviewPrimaryButtonProps = {
  accentColorCode: string;
  text: string | JSX.Element;
};

const PreviewPrimaryButton: React.FC<PreviewPrimaryButtonProps> = ({accentColorCode, text}) => {
  const hasDarkAccent = isDark(accentColorCode);

  useEffect(() => {
    const root = document.documentElement;

    root.style.setProperty('--xps-primary-btn-color', hasDarkAccent ? '#ffffff' : '#000000');
    root.style.setProperty('--xps-primary-btn-hover-color', hasDarkAccent ? '#ffffff' : '#000000');
  }, [hasDarkAccent]);

  return (
    <button
      className={cn(
        'flex h-11 w-full items-center justify-center text-sm font-medium',
        'hover:bg-[--xps-primary-btn-hover-background-color] hover:text-[--xps-primary-btn-hover-color]',
        'border-[--xps-primary-btn-border-color]',
        'text-[--xps-primary-btn-color] hover:text-[--xps-primary-btn-hover-color]'
      )}
      style={{
        backgroundColor: accentColorCode,
        borderRadius: 'var(--xps-primary-btn-border-radius)',
        textTransform: 'var(--xps-primary-btn-text-transform)' as BtnTextTransform,
      }}
      type="button"
    >
      {text}
    </button>
  );
};

export default PreviewPrimaryButton;
