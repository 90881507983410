import type {
  BadgeADto,
  CreateBadgeADto,
  GetBadgesParamsADto,
  PatchBadgeADto,
  ReorderBadgesADto,
} from '@cohort/admin-schemas/badge';
import {BadgeASchema} from '@cohort/admin-schemas/badge';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import type {PaginationDto} from '@cohort/shared/schema/common/pagination';
import {paginatedParser} from '@cohort/shared/utils/parser';
import {z} from 'zod';

export const getBadges = async (
  merchantId: string,
  params: GetBadgesParamsADto
): Promise<[PaginationDto, BadgeADto[]]> => {
  return apiCall('GET', `/v1/merchants/${merchantId}/badges`, {
    expect: HttpCodes.SUCCESS,
    parser: paginatedParser(BadgeASchema),
    params,
  });
};

export const getBadge = async (merchantId: string, badgeId: string): Promise<BadgeADto> => {
  return apiCall('GET', `/v1/merchants/${merchantId}/badges/${badgeId}`, {
    expect: HttpCodes.SUCCESS,
    parser: BadgeASchema.parse,
  });
};

export const createBadge = async (
  merchantId: string,
  data: CreateBadgeADto
): Promise<BadgeADto> => {
  return apiCall('POST', `/v1/merchants/${merchantId}/badges`, {
    expect: HttpCodes.CREATED,
    parser: BadgeASchema.parse,
    body: data,
  });
};

export const updateBadge = async (
  merchantId: string,
  badgeId: string,
  data: PatchBadgeADto
): Promise<BadgeADto> => {
  return apiCall('PATCH', `/v1/merchants/${merchantId}/badges/${badgeId}`, {
    expect: HttpCodes.SUCCESS,
    parser: BadgeASchema.parse,
    body: data,
  });
};

export const reorderBadges = async (
  merchantId: string,
  data: ReorderBadgesADto
): Promise<BadgeADto[]> => {
  return apiCall('POST', `/v1/merchants/${merchantId}/badges/reorder`, {
    expect: HttpCodes.CREATED,
    parser: z.array(BadgeASchema).parse,
    body: data,
  });
};

export const deleteBadge = async (merchantId: string, badgeId: string): Promise<{}> => {
  return apiCall('DELETE', `/v1/merchants/${merchantId}/badges/${badgeId}`, {
    expect: HttpCodes.SUCCESS,
    parser: () => ({}),
  });
};
