import type {
  DigitalAssetCollectionADto,
  DigitalAssetCollectionAnalyticsADto,
} from '@cohort/admin-schemas/digitalAssetCollection';
import Button from '@cohort/merchants/components/buttons/Button';
import CardListDisplay from '@cohort/merchants/components/CardListDisplay';
import EmptyState from '@cohort/merchants/components/EmptyState';
import ErrorState from '@cohort/merchants/components/ErrorState';
import LoadingState from '@cohort/merchants/components/LoadingState';
import {useDigitalAssetCollectionsDisplay} from '@cohort/merchants/hooks/stores/digitalAssetCollectionsDisplay';
import DigitalAssetCollectionCard from '@cohort/merchants/pages/digital-asset-collections/overview/DigitalAssetCollectionCard';
import DigitalAssetCollectionListItem from '@cohort/merchants/pages/digital-asset-collections/overview/DigitalAssetCollectionListItem';
import {Plus} from '@phosphor-icons/react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {match, P} from 'ts-pattern';

interface DigitalAssetCollectionsListProps {
  digitalAssetCollections?: DigitalAssetCollectionAnalyticsADto[];
  isLoading: boolean;
  isError: boolean;
  onHandleCreateDigitalAssetCollectionClick: () => void;
}

const DigitalAssetCollectionsList: React.FC<DigitalAssetCollectionsListProps> = ({
  digitalAssetCollections,
  isLoading,
  isError,
  onHandleCreateDigitalAssetCollectionClick,
}) => {
  const display = useDigitalAssetCollectionsDisplay(store => store.display);

  const {t} = useTranslation('pages', {
    keyPrefix: 'digitalAssetCollections.overview.digitalAssetCollectionList',
  });

  const digitalAssetCollectionsGuard = (
    digitalAssetCollections: DigitalAssetCollectionADto[] | undefined
  ): digitalAssetCollections is DigitalAssetCollectionADto[] =>
    digitalAssetCollections !== undefined && digitalAssetCollections.length > 0;

  const content = match({digitalAssetCollections, isLoading, isError})
    .with({isLoading: true}, () => <LoadingState />)
    .with({isError: true}, () => <ErrorState />)
    .with(
      {
        digitalAssetCollections: P.select(P.when(digitalAssetCollectionsGuard)),
      },
      digitalAssetCollections => (
        <CardListDisplay display={display} testId="digital-asset-collections-list">
          {digitalAssetCollections.map(digitalAssetCollection =>
            display === 'card' ? (
              <DigitalAssetCollectionCard
                key={digitalAssetCollection.id}
                digitalAssetCollection={digitalAssetCollection}
              />
            ) : (
              <DigitalAssetCollectionListItem
                key={digitalAssetCollection.id}
                digitalAssetCollection={digitalAssetCollection}
              />
            )
          )}
        </CardListDisplay>
      )
    )
    .otherwise(() => (
      <EmptyState
        title={t('titleEmpty')}
        description={t('descriptionEmpty')}
        cta={
          <Button
            onClick={() => onHandleCreateDigitalAssetCollectionClick()}
            data-testid="create-digitalAssetCollection-empty"
          >
            <Plus className="-ml-1 mr-2 h-5 w-5" />
            {t('buttonAddDigitalAssetCollection')}
          </Button>
        }
      />
    ));

  return content;
};

export default DigitalAssetCollectionsList;
