import type {JourneyStepADto, JourneyStepInputADto} from '@cohort/admin-schemas/journeySteps';
import {JourneyStepInputASchema} from '@cohort/admin-schemas/journeySteps';
import Button from '@cohort/merchants/components/buttons/Button';
import DatetimeInput from '@cohort/merchants/components/form/DatetimeInput';
import EmojiInput from '@cohort/merchants/components/form/EmojiInput';
import FieldLabel from '@cohort/merchants/components/form/FieldLabel';
import Input from '@cohort/merchants/components/form/input/Input';
import LocalizedInput from '@cohort/merchants/components/form/input/LocalizedInput';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import {SelectPicker} from '@cohort/merchants/components/form/select/SelectPicker';
import LocalizedTextEditorInput from '@cohort/merchants/components/form/textEditor/LocalizedTextEditorInput';
import Header from '@cohort/merchants/components/Header';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from '@cohort/merchants/components/modals/Dialog';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {changeSelectedLanguageIfNeeded} from '@cohort/merchants/lib/form/localization';
import StepTriggersList from '@cohort/merchants/pages/campaigns/campaign/edit/settings/journey/StepTriggersList';
import type {CreateEditStepModalType} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/journey/utils';
import {CreateEditStepModalSchema} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/journey/utils';
import type {Language} from '@cohort/shared/schema/common';
import {zodResolver} from '@hookform/resolvers/zod';
import type {TFunction} from 'i18next';
import {get} from 'lodash';
import React, {Fragment, useMemo} from 'react';
import type {Control, UseFormRegister, UseFormSetValue} from 'react-hook-form';
import {useForm, useFormState, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type OrchestrationSectionProps = {
  t: TFunction<'pages', 'campaigns.campaign.edit.settings.journey.createEditStepModal'>;
  control: Control<CreateEditStepModalType>;
  register: UseFormRegister<CreateEditStepModalType>;
  setValue: UseFormSetValue<CreateEditStepModalType>;
  step?: JourneyStepInputADto;
  journeySteps: JourneyStepADto[];
  selectedLanguage: Language;
};

type CreateEditStepModalProps = {
  journeySteps: JourneyStepADto[];
  step?: JourneyStepInputADto;
  isLoading: boolean;
  journeyDefinedLanguages: Language[];
  onSubmit: (data: JourneyStepInputADto) => void;
  onClose: () => void;
};

const OrchestrationSection: React.FC<OrchestrationSectionProps> = ({
  t,
  control,
  register,
  setValue,
  step,
  journeySteps,
  selectedLanguage,
}) => {
  const withStartDate = useWatch({
    control,
    name: 'orchestration.withStartDate',
  });

  const withPreRequiredSteps = useWatch({
    control,
    name: 'orchestration.withPreRequiredSteps',
  });

  const preRequiredSteps = useWatch({
    control,
    name: 'orchestration.preRequiredSteps',
  });

  const preRequiredStepsOptions = useMemo(() => {
    let eligibleSteps: JourneyStepADto[];
    if (!step) {
      eligibleSteps = journeySteps;
    } else {
      eligibleSteps = journeySteps.filter(s => s.id !== step.id);
    }
    return eligibleSteps.map(step => ({
      label: step.title[selectedLanguage],
      value: step.id,
    }));
  }, [journeySteps, selectedLanguage, step]);

  const {errors} = useFormState({
    control,
    name: 'orchestration.preRequiredSteps',
  });

  return (
    <div className="space-y-2">
      <FieldLabel name="triggers" label={t('labelOrchestration')} />
      <div className="grid items-start gap-x-4 [grid-template-columns:max-content_1fr]">
        <div className="mt-[10px] min-h-[40px]">
          <Input
            type="checkbox"
            name="orchestration.withStartDate"
            label={t('labelWithStartDate')}
            labelPosition="right"
            control={control}
            register={register}
            rules={{
              onChange: value => {
                if (value.target.checked === false) {
                  setValue('orchestration.startDate', undefined);
                }
              },
            }}
          />
        </div>
        {withStartDate ? (
          <DatetimeInput name="orchestration.startDate" control={control} register={register} />
        ) : (
          <div />
        )}
        {preRequiredStepsOptions.length > 0 && (
          <Fragment>
            <div className="mt-[10px] min-h-[40px]">
              <Input
                type="checkbox"
                name="orchestration.withPreRequiredSteps"
                label={t('labelWithPreRequiredSteps')}
                labelPosition="right"
                control={control}
                register={register}
                rules={{
                  onChange: value => {
                    if (value.target.checked === false) {
                      setValue('orchestration.preRequiredSteps', undefined);
                    }
                  },
                }}
              />
            </div>
            {withPreRequiredSteps ? (
              <SelectPicker
                isMulti
                options={preRequiredStepsOptions}
                onChange={(options): void =>
                  setValue(
                    'orchestration.preRequiredSteps',
                    options.map(opt => opt.value)
                  )
                }
                value={preRequiredStepsOptions.filter(opt => preRequiredSteps?.includes(opt.value))}
                name="orchestration.preRequiredSteps"
                error={get(errors, `orchestration.preRequiredSteps.message`)}
              />
            ) : (
              <div />
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

const CreateEditStepModal: React.FC<CreateEditStepModalProps> = ({
  journeySteps,
  step,
  isLoading,
  journeyDefinedLanguages,
  onSubmit,
  onClose,
}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.settings.journey.createEditStepModal',
  });

  const {register, handleSubmit, control, watch, setValue} = useForm<CreateEditStepModalType>({
    resolver: zodResolver(CreateEditStepModalSchema),
    defaultValues: {
      id: step?.id ?? null,
      icon: step?.icon ?? '🎯',
      title: step?.title,
      description: step?.description,
      defaultLanguage: merchant.defaultLanguage,
      selectedLanguage: merchant.defaultLanguage,
      definedLanguages: journeyDefinedLanguages,
      triggers: step?.triggers,
      orchestration: {
        startDate: step?.orchestration.startDate,
        withStartDate: step?.orchestration.startDate !== undefined,
        preRequiredSteps:
          step?.orchestration.preRequiredSteps && step.orchestration.preRequiredSteps.length > 0
            ? step.orchestration.preRequiredSteps
            : undefined,
        withPreRequiredSteps:
          step?.orchestration.preRequiredSteps !== undefined &&
          step.orchestration.preRequiredSteps.length > 0
            ? true
            : false,
      },
    },
  });

  const [selectedLanguage] = watch(['selectedLanguage']);
  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;
  const hasCohortFillFormTrigger = step?.triggers.some(
    trigger => trigger.triggerIntegrationId === 'cohort.fill-form'
  );

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader className="space-y-6">
          <Header title={step ? t('edit') : t('create')} />
          <LanguageSelectorInput
            control={control}
            definedLanguagesPath="definedLanguages"
            selectedLanguagePath="selectedLanguage"
          />
        </DialogHeader>
        <DialogBody className="w-[1200px]">
          <form id="create-edit-journey-step-form" className="space-y-6">
            <EmojiInput label={t('labelIcon')} name="icon" register={register} control={control}>
              <LocalizedInput
                type="text"
                label={t('labelTitle')}
                name="title"
                placeholder={t('placeholderTitle')}
                register={register}
                control={control}
                selectedLanguage={selectedLanguage}
                optional={!isDefaultLanguageSelected}
              />
            </EmojiInput>
            <LocalizedTextEditorInput
              label={t('labelDescription')}
              name="description"
              placeholder={t('placeholderDescription')}
              register={register}
              control={control}
              selectedLanguage={selectedLanguage}
              optional={!isDefaultLanguageSelected}
            />
            <OrchestrationSection
              t={t}
              control={control}
              register={register}
              setValue={setValue}
              step={step}
              journeySteps={journeySteps}
              selectedLanguage={selectedLanguage}
            />
            {(isDefaultLanguageSelected || hasCohortFillFormTrigger) && (
              <div className="space-y-2">
                <FieldLabel name="triggers" label={t('labelTriggers')} />
                <StepTriggersList control={control} register={register} />
              </div>
            )}
            <HighlightText type="info" text={t('manualTriggerHint')} />
          </form>
        </DialogBody>
        <DialogFooter>
          <Button variant="secondary" onClick={onClose}>
            {t('btnCancel')}
          </Button>
          <Button
            form="create-edit-journey-step-form"
            type="button"
            loading={isLoading}
            onClick={() => {
              handleSubmit(
                data => {
                  onSubmit(JourneyStepInputASchema.parse(data));
                },
                errors =>
                  changeSelectedLanguageIfNeeded(errors, merchant.defaultLanguage, lang =>
                    setValue('selectedLanguage', lang)
                  )
              )();
            }}
          >
            {step ? t('btnEdit') : t('btnCreate')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateEditStepModal;
