import BreadCrumb from '@cohort/merchants/components/BreadCrumb';
import {useCurrentUser} from '@cohort/merchants/hooks/contexts/currentUser';

const UserPageBreadcrumb = (): JSX.Element => {
  const user = useCurrentUser();

  return <BreadCrumb title={user.email} />;
};

export default UserPageBreadcrumb;
