/* eslint-disable @typescript-eslint/naming-convention */
import type {UserEventADto} from '@cohort/admin-schemas/userEvents';
import type {
  UserEventDetailComponentProps,
  UserEventDetailModalComponentProps,
} from '@cohort/merchants/apps';
import {useApps} from '@cohort/merchants/apps/useApps';
import {useUserEventApps} from '@cohort/merchants/apps/useUserEventApps';
import {Badge} from '@cohort/merchants/components/Badge';
import Button from '@cohort/merchants/components/buttons/Button';
import {Dialog, DialogContent, DialogTitle} from '@cohort/merchants/components/modals/Dialog';
import AppLogo from '@cohort/merchants/pages/users/overview/appLogo';
import {formatDate} from '@cohort/shared/utils/format';
import {ListMagnifyingGlass} from '@phosphor-icons/react';
import type {FC} from 'react';
import React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

type UserEventDetailModalProps = {
  userEvent: UserEventADto;
  DetailModalComponent: FC<UserEventDetailModalComponentProps>;
  DetailComponent: FC<UserEventDetailComponentProps>;
  onClose: () => void;
};

const UserEventDetailModal: React.FC<UserEventDetailModalProps> = ({
  userEvent,
  DetailModalComponent,
  DetailComponent,
  onClose,
}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'users.user.activity.userEventItem'});
  const apps = useApps();
  const [appId] = userEvent.eventId.split('.');
  const app = apps.getApp(appId);
  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <div className="flex w-[500px] flex-col space-y-6 p-6 text-sm">
          <DialogTitle className="text-lg font-medium">{t('activityDetails')}</DialogTitle>
          <div className="flex flex-row items-center space-x-4">
            <AppLogo app={app} size={28} />
            <div>
              <DetailComponent userEvent={userEvent} />
              <p className="text-slate-500">{formatDate(userEvent.createdAt)}</p>
            </div>
          </div>
          <DetailModalComponent userEvent={userEvent} />
          <div className="flex justify-end">
            <Button onClick={onClose} variant="primary">
              {t('buttonClose')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
type UserEventItemProps = {
  userEvent: UserEventADto;
};

const UserEventItem: React.FC<UserEventItemProps> = ({userEvent}) => {
  const userEventApps = useUserEventApps();
  const userEventApp = userEventApps.getUserEvent(userEvent.eventId);
  const [showModal, setShowModal] = useState(false);
  const {t} = useTranslation('pages', {keyPrefix: 'users.user.activity.userEventItem'});

  return (
    <div className="flex w-full flex-col justify-between px-2 text-sm">
      <div className="flex space-x-2">
        <p>
          <userEventApp.detailComponent userEvent={userEvent} />
        </p>
        <div>
          {userEvent.isResourceDeleted && (
            <Badge
              text={t('resourceDeletedBadge')}
              textColor="text-red-500"
              backgroundColor="bg-red-50"
              size="small"
            />
          )}
        </div>
      </div>
      <p className="text-slate-500">{formatDate(userEvent.eventDate)}</p>
      {userEventApp.detailModalComponent !== undefined && (
        <div>
          <Button onClick={() => setShowModal(true)} variant="secondary" size="xs" className="mt-2">
            <ListMagnifyingGlass className="mr-2 h-4 w-4" />
            {t('buttonViewDetails')}
          </Button>
          {showModal && (
            <UserEventDetailModal
              userEvent={userEvent}
              DetailComponent={userEventApp.detailComponent}
              DetailModalComponent={userEventApp.detailModalComponent}
              onClose={() => setShowModal(false)}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default UserEventItem;
