import BreadCrumb from '@cohort/merchants/components/BreadCrumb';
import {SyncStatusBadge} from '@cohort/merchants/components/syncs/SyncStatusBadge';
import {useCurrentApp} from '@cohort/merchants/hooks/contexts/currentApp';
import {useCurrentSync} from '@cohort/merchants/hooks/contexts/currentSync';
import {getAppSyncsRoute} from '@cohort/merchants/lib/Pages';
import SyncDropdown from '@cohort/merchants/pages/apps/app/syncs/edit/SyncDropdown';
import type {AppId} from '@cohort/shared/apps';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const SyncEditPageBreadcrumb: React.FC = () => {
  const {app, appSpec} = useCurrentApp();
  const {sync} = useCurrentSync();
  const navigate = useNavigate();
  const {t} = useTranslation('pages', {
    keyPrefix: 'apps.app.syncs.edit.syncEditPageBreadcrumb',
  });

  return (
    <BreadCrumb
      title={t('title', {appName: appSpec.name})}
      image={React.cloneElement(app.logo, {height: 24, width: 24})}
      onBackClick={() => {
        navigate(getAppSyncsRoute(appSpec.id as AppId).path);
        return {preventNavigate: true};
      }}
      badges={[
        <div className="ml-2">
          <SyncStatusBadge size="small" status={sync.status} />
        </div>,
      ]}
    >
      <div className="ml-4 flex items-center gap-4">
        <SyncDropdown sync={sync} />
      </div>
    </BreadCrumb>
  );
};

export default SyncEditPageBreadcrumb;
