import {useCurrentApp} from '@cohort/merchants/hooks/contexts/currentApp';
import {useSyncPageStore} from '@cohort/merchants/hooks/stores/syncPage';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import {CurrentAppProvider} from '@cohort/merchants/pages/apps/app/CurrentAppContext';
import {CurrentSyncProvider} from '@cohort/merchants/pages/apps/app/CurrentSyncContext';
import SyncEditPageBreadcrumb from '@cohort/merchants/pages/apps/app/syncs/edit/SyncEditPageBreadcrumb';
import SyncEditPageMenu from '@cohort/merchants/pages/apps/app/syncs/edit/SyncEditPageMenu';
import {Outlet} from 'react-router-dom';

const SyncPageSkeleton: React.FC = () => {
  const app = useCurrentApp();
  const footer = useSyncPageStore(store => store.footer);

  return (
    <PageLayout
      title={app.appSpec.name}
      breadcrumb={<SyncEditPageBreadcrumb />}
      menu={<SyncEditPageMenu />}
      footer={footer}
    >
      <Outlet />
    </PageLayout>
  );
};

const SyncEditPageLayout = (): JSX.Element => (
  <CurrentAppProvider>
    <CurrentSyncProvider>
      <SyncPageSkeleton />
    </CurrentSyncProvider>
  </CurrentAppProvider>
);

export default SyncEditPageLayout;
