import type {SyncADto} from '@cohort/admin-schemas/sync';
import DropdownButton from '@cohort/merchants/components/buttons/DropdownButton';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {syncsKeys} from '@cohort/merchants/hooks/api/Syncs';
import {useCurrentApp} from '@cohort/merchants/hooks/contexts/currentApp';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {deleteSync, pauseSync, resumeSync} from '@cohort/merchants/lib/api/Sync';
import {getAppSyncsRoute} from '@cohort/merchants/lib/Pages';
import {PauseCircle, PlayCircle, Trash} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

interface SyncDropdownProps {
  sync: SyncADto;
}

const SyncDropdown: React.FC<SyncDropdownProps> = ({sync}) => {
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const merchant = useCurrentMerchant();
  const {appId} = useCurrentApp();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {t} = useTranslation('pages', {keyPrefix: 'apps.app.syncs.syncDropdown'});

  async function onMutationSuccess(): Promise<void> {
    await queryClient.invalidateQueries(syncsKeys.list(merchant.id));
    await queryClient.invalidateQueries(syncsKeys.getById(merchant.id, sync.id));
  }

  const {mutate: pauseSyncMutation, isLoading: isPausing} = useCohortMutation({
    mutationFn: async () => pauseSync(merchant.id, sync.id),
    onSuccess: onMutationSuccess,
    notifySuccessMessage: t('notificationPauseSuccess'),
  });

  const {mutate: resumeSyncMutation, isLoading: isResuming} = useCohortMutation({
    mutationFn: async () => resumeSync(merchant.id, sync.id),
    onSuccess: onMutationSuccess,
    notifySuccessMessage: t('notificationResumeSuccess'),
  });

  const {mutate: deleteSyncMutation} = useCohortMutation({
    mutationFn: async () => deleteSync(merchant.id, sync.id),
    notifySuccessMessage: t('notificationDeleteSuccess'),
    notifyErrorMessage: t('notificationDeleteError'),
    onSuccess: async () => {
      setShowDeletionModal(false);
      navigate(getAppSyncsRoute(appId).path);
      await queryClient.invalidateQueries(syncsKeys.list(merchant.id));
    },
  });

  return (
    <Fragment>
      <DropdownButton
        entries={[
          ...(sync.status === 'started'
            ? [
                {
                  id: 'pause',
                  label: <span className="text-slate-700">{t('labelPause')}</span>,
                  icon: <PauseCircle className="h-4 w-4 text-slate-400" />,
                  disabled: isPausing,
                  handleOnClick: () => pauseSyncMutation(),
                },
              ]
            : []),
          ...(sync.status === 'paused'
            ? [
                {
                  id: 'resume',
                  label: <span className="text-slate-700">{t('labelResume')}</span>,
                  icon: <PlayCircle className="h-4 w-4 text-slate-400" />,
                  disabled: isResuming,
                  handleOnClick: () => resumeSyncMutation(),
                },
              ]
            : []),
          {
            id: 'delete',
            label: <span className="text-red-500">{t('labelDelete')}</span>,
            icon: <Trash className="h-4 w-4 text-red-500" />,
            handleOnClick: () => setShowDeletionModal(true),
          },
        ]}
      />
      {showDeletionModal && (
        <DeletionModal
          title={t('deletionModalTitle')}
          subtitle={t('deletionModalSubtitle')}
          show={showDeletionModal}
          onClose={() => setShowDeletionModal(false)}
          onDelete={deleteSyncMutation}
        />
      )}
    </Fragment>
  );
};

export default SyncDropdown;
