import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {assertConnectionId} from '@cohort/merchants/apps';
import {useInstagramMedias} from '@cohort/merchants/apps/instagram/actions';
import SelectMediaSheet from '@cohort/merchants/apps/instagram/triggerIntegrations/commentMedia/SelectMediaSheet';
import Button from '@cohort/merchants/components/buttons/Button';
import ErrorState from '@cohort/merchants/components/ErrorState';
import HighlightText from '@cohort/merchants/components/HighlightText';
import Loader from '@cohort/merchants/components/Loader';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {ArrowsCounterClockwise, Image, ImagesSquare} from '@phosphor-icons/react';
import {Fragment, useEffect, useState} from 'react';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const InstagramCommentMediaTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({connectionId, formReturn}) => {
  assertConnectionId(connectionId);

  const merchant = useCurrentMerchant();
  const {setValue, control} = formReturn;
  const {field: mediaIdField} = useController({name: 'triggerIntegrationConfig.mediaId', control});
  const [selectMediaSheetOpen, setSelectMediaSheetOpen] = useState(false);
  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'triggerIntegrations.comment-media.configComponent',
  });

  const {
    data: mediasResponse,
    isLoading: isMediasLoading,
    isFetched: isMediasFetched,
  } = useInstagramMedias(merchant.id, connectionId);

  const medias = mediasResponse?.output.medias ?? [];
  const sortedMedias = medias.sort(media => (media.isCommentEnabled ? -1 : 1));

  // Set the value of the permalink to match the mediaId
  useEffect(() => {
    const media = sortedMedias.find(media => media.id === mediaIdField.value);
    if (media === undefined) {
      return;
    }
    setValue('triggerIntegrationConfig.mediaPermalink', media.permalink);
  }, [mediaIdField, sortedMedias, setValue]);

  const formsSelectionContent = match({
    sortedMedias,
    isMediasLoading,
    isMediasFetched,
    formIdFieldValue: mediaIdField.value,
  })
    .with({isMediasLoading: true}, () => <Loader size={30} color="gray" />)
    .with({sortedMedias: []}, () => (
      <div className="flex flex-col space-y-2">
        <p>{t('noMediasMessage')}</p>
      </div>
    ))
    .with({isMediasFetched: true}, () => {
      const selectedMedia = medias.find(media => media.id === mediaIdField.value);

      return (
        <Fragment>
          <div>
            <p className="pb-1 text-sm font-medium">{t('titleMediaSelect')}</p>
            <p className="pb-2 text-sm">{t('descriptionMediaSelect')}</p>
            {!mediaIdField.value ? (
              <Button variant="ghost" onClick={() => setSelectMediaSheetOpen(true)}>
                <ImagesSquare className="mr-2 h-5 w-5" />
                {t('buttonSelectMedia')}
              </Button>
            ) : (
              <Fragment>
                <div className="flex items-center gap-5">
                  <div className="flex aspect-square w-[120px] items-center justify-center overflow-hidden rounded-lg border border-slate-200 bg-white shadow-sm">
                    {selectedMedia?.thumbnailUrl !== undefined ? (
                      <img src={selectedMedia.thumbnailUrl} />
                    ) : (
                      <Image size={24} className="text-slate-400" />
                    )}
                  </div>
                  <Button variant="secondary" onClick={() => setSelectMediaSheetOpen(true)}>
                    <ArrowsCounterClockwise className="mr-2 h-5 w-5" />
                    {t('buttonChangeMedia')}
                  </Button>
                </div>
                {selectedMedia?.thumbnailUrl === undefined && (
                  <div className="mt-4">
                    <HighlightText type="warning" text={t('mediaNotFound')} />
                  </div>
                )}
              </Fragment>
            )}
          </div>
          {selectMediaSheetOpen && (
            <SelectMediaSheet
              formReturn={formReturn}
              medias={medias}
              onClose={() => setSelectMediaSheetOpen(false)}
            />
          )}
        </Fragment>
      );
    })
    .otherwise(() => <ErrorState />);

  return formsSelectionContent;
};

export default InstagramCommentMediaTriggerIntegrationConfigComponent;
