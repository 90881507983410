import type {CampaignADto} from '@cohort/admin-schemas/campaign';
import Button from '@cohort/merchants/components/buttons/Button';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@cohort/merchants/components/modals/Dialog';
import {campaignsKeys} from '@cohort/merchants/hooks/api/Campaigns';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {createRedeemCodes} from '@cohort/merchants/lib/api/Campaigns';
import {trackCampaignAirdropModalCloseButtonClicked} from '@cohort/merchants/lib/Tracking';
import {REDEEM_CODES_MAX_QUANTITY} from '@cohort/shared/constants';
import {useQueryClient} from '@tanstack/react-query';
import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

interface CampaignRedeemLinksModalProps {
  merchantId: string;
  campaign: CampaignADto;
  show: boolean;
  handleOnClose: () => void;
  redeemCodesLength: number;
}

const CampaignRedeemLinksModal: React.FC<CampaignRedeemLinksModalProps> = ({
  merchantId,
  campaign,
  show,
  handleOnClose,
  redeemCodesLength,
}) => {
  const queryClient = useQueryClient();
  const [redeemCodesQuantity, setRedeemCodesQuantity] = useState(0);
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.audience.campaignRedeemLinksModal',
  });

  const {isLoading, mutate: generateRedeemLinks} = useCohortMutation({
    mutationFn: async () => createRedeemCodes(merchantId, campaign.id, redeemCodesQuantity),
    notifyErrorMessage: t('notificationRedeemLinksCreationError'),
    notifySuccessMessage: t('notificationRedeemLinksCreationSuccess'),
    onSuccess: async () => {
      await queryClient.invalidateQueries(campaignsKeys.redeemCodes(merchantId, campaign.id));
      setRedeemCodesQuantity(0);
      handleOnClose();
    },
  });

  const maxSupply = useMemo((): number => {
    if (campaign.totalSupply === null) {
      return REDEEM_CODES_MAX_QUANTITY;
    }

    const computedMaxSupply = campaign.totalSupply - campaign.numOrdered - redeemCodesLength;

    return computedMaxSupply > REDEEM_CODES_MAX_QUANTITY
      ? REDEEM_CODES_MAX_QUANTITY
      : computedMaxSupply;
  }, [campaign.numOrdered, campaign.totalSupply, redeemCodesLength]);

  return (
    <Dialog open={show}>
      <DialogContent data-testid="campaign-redeem-link-modal" displayCloseButton={false}>
        <DialogHeader>
          <DialogTitle className="mb-2 text-2xl font-bold">{t('title')}</DialogTitle>
          <DialogDescription className="m-0 text-base leading-5">{t('subtitle')}</DialogDescription>
        </DialogHeader>
        <DialogBody>
          <form className="flex flex-col gap-4">
            <section className="mb-2">
              <label>
                {t('quantityTitle', {maxSupply})}
                <span className="text-red-500">*</span>
              </label>
              <div className="mt-2">
                <input
                  className="w-full rounded-md border px-3 py-2 shadow-sm"
                  name="redeem-codes-quantity"
                  placeholder="0"
                  type="number"
                  min={0}
                  max={maxSupply}
                  value={redeemCodesQuantity}
                  onChange={e => setRedeemCodesQuantity(parseInt(e.target.value, 10))}
                />
              </div>
              {redeemCodesQuantity > maxSupply && (
                <div className="mt-3">
                  <HighlightText type="error" text={t('quantityError', {maxSupply})} />
                </div>
              )}
            </section>
          </form>
        </DialogBody>
        <DialogFooter className="flex items-center justify-end gap-4">
          <Button
            variant="secondary"
            onClick={() => {
              trackCampaignAirdropModalCloseButtonClicked(campaign);
              handleOnClose();
            }}
            disabled={isLoading}
          >
            {t('btnCancel')}
          </Button>
          <Button
            onClick={e => {
              e.preventDefault();

              if (redeemCodesQuantity === 0 || redeemCodesQuantity > maxSupply) {
                return;
              }

              return generateRedeemLinks();
            }}
            loading={isLoading}
            data-testid="redeem-link-modal-submit-btn"
          >
            {t('btnSubmit')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CampaignRedeemLinksModal;
