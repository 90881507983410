import NetworkedSelect from '@cohort/merchants/components/form/select/NetworkedSelect';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import type FeatureFlags from '@cohort/merchants/featureFlags';
import {usePaginatedUserProperties} from '@cohort/merchants/hooks/api/UserProperties';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useOperators} from '@cohort/merchants/hooks/rules/operators';
import CriteriaInputValue from '@cohort/merchants/pages/users/cohort/rule/criteria/CriteriaInputValue';
import ResourceSelectInput from '@cohort/merchants/pages/users/cohort/rule/criteria/ResourceSelectInput';
import type {DynamicCohortRuleType} from '@cohort/merchants/pages/users/cohort/rule/utils';
import type {ResourceType} from '@cohort/shared/apps';
import {getAppUserPropertySpec} from '@cohort/shared/apps';
import type {OperatorId} from '@cohort/shared/schema/common/rules/operators';
import {getOperatorById} from '@cohort/shared/schema/common/rules/operators';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {useState} from 'react';
import type {Control, UseFormRegister} from 'react-hook-form';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const PAGE_SIZE = 50;

type UserPropertyCriteriaProps = {
  control: Control<DynamicCohortRuleType>;
  register: UseFormRegister<DynamicCohortRuleType>;
  groupIndex: number;
  criteriaIndex: number;
};

const UserPropertyCriteria: React.FC<UserPropertyCriteriaProps> = ({
  control,
  register,
  groupIndex,
  criteriaIndex,
}) => {
  const merchant = useCurrentMerchant();
  const {appsInstagramUserMediaPermission} = useFlags<FeatureFlags>();
  const {t} = useTranslation('pages', {
    keyPrefix: 'users.cohort.rule.criteria.userPropertyCriteria',
  });
  const [search, setSearch] = useState<string | undefined>(undefined);
  const operators = useOperators('user-property');
  const {field: userPropertyId, fieldState: userPropertyIdState} = useController({
    name: `groups.${groupIndex}.criteria.${criteriaIndex}.userPropertyId`,
    control,
  });
  const {field: operator} = useController({
    name: `groups.${groupIndex}.criteria.${criteriaIndex}.operator`,
    control,
  });
  const {field: valueType} = useController({
    name: `groups.${groupIndex}.criteria.${criteriaIndex}.valueType`,
    control,
  });
  const {field: value} = useController({
    name: `groups.${groupIndex}.criteria.${criteriaIndex}.value`,
    control,
  });

  const {data: userPropertiesData, isLoading} = usePaginatedUserProperties(merchant.id, {
    pageSize: PAGE_SIZE,
    page: 1,
    ...(search !== undefined && search.trim().length > 0 && {search}),
    orderBy: 'name',
    excludedReferenceIds: appsInstagramUserMediaPermission ? [] : ['instagram.medias-count'],
  });
  const [, userProperties] = userPropertiesData ?? [];

  const userPropertyOptions =
    userProperties?.map(userProperty => ({
      value: userProperty.id,
      label: userProperty.name,
    })) ?? [];
  const selectedUserProperty = userProperties?.find(prop => prop.id === userPropertyId.value);
  const valueDataType = operator.value
    ? getOperatorById(operator.value as OperatorId).valueDataType
    : undefined;

  const userPropertySpec =
    selectedUserProperty !== undefined
      ? getAppUserPropertySpec(selectedUserProperty.referenceId)
      : null;
  const resourceType = userPropertySpec?.resource ?? null;

  return (
    <div className="grid gap-4 [grid-template-columns:350px_300px_1fr]">
      <NetworkedSelect
        name={`groups.${groupIndex}.criteria.${criteriaIndex}.userPropertyId`}
        error={userPropertyIdState.error?.message}
        placeholder={t('userPropertyPlaceholder')}
        options={userPropertyOptions}
        onInputChange={setSearch}
        isLoading={isLoading}
        onChange={val => {
          userPropertyId.onChange(val?.value);
          operator.onChange(null);
          value.onChange(null);
        }}
        value={
          userPropertyId.value
            ? {
                value: userPropertyId.value,
                label: selectedUserProperty?.name,
              }
            : null
        }
      />
      {selectedUserProperty && (
        <SelectInput
          name={`groups.${groupIndex}.criteria.${criteriaIndex}.operator`}
          placeholder={t('operatorPlaceholder')}
          register={register}
          control={control}
          onChange={newOperator => {
            const newValueDataType =
              newOperator && newOperator.value
                ? getOperatorById(newOperator.value as OperatorId).valueDataType
                : undefined;

            if (newValueDataType !== valueDataType) {
              valueType.onChange(newValueDataType);
              value.onChange(null);
            }
          }}
          options={operators.filter(op => op.type === selectedUserProperty.dataType)}
        />
      )}
      {resourceType ? (
        <ResourceSelectInput
          name={`groups.${groupIndex}.criteria.${criteriaIndex}.value`}
          control={control}
          resourceType={resourceType as ResourceType}
          merchantConnectionId={selectedUserProperty?.merchantConnectionId ?? undefined}
        />
      ) : (
        valueDataType &&
        valueDataType !== 'resource' &&
        valueDataType !== 'resource_list' && (
          <CriteriaInputValue
            operator={operator.value as OperatorId}
            dataType={valueDataType}
            name={`groups.${groupIndex}.criteria.${criteriaIndex}.value`}
            register={register}
            control={control}
          />
        )
      )}
    </div>
  );
};

export default UserPropertyCriteria;
