import type {GetCampaignsParamsADto} from '@cohort/admin-schemas/campaign';
import type {PaginationFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import {useBasePageFilters} from '@cohort/merchants/hooks/filters/basePageFilters';

export const statusFilter = ['published', 'draft', 'ended'] as const;
export const sortFilter = ['-updatedAt', 'internalName', '-numOrdered', '-totalSupply'] as const;
export const visibilityFilter = ['all', 'archived', 'visible'] as const;

type CampaignsListFilters = {
  status: GetCampaignsParamsADto['status'];
  setStatus: (status: GetCampaignsParamsADto['status']) => void;
  sort: GetCampaignsParamsADto['orderBy'];
  setSort: (sort: GetCampaignsParamsADto['orderBy']) => void;
  visibility: GetCampaignsParamsADto['visibility'];
  setVisibility: (visibility: GetCampaignsParamsADto['visibility']) => void;
  reset: () => void;
};

export const useCampaignsListFilters = (): PaginationFilters & CampaignsListFilters =>
  useBasePageFilters<CampaignsListFilters>(/^(\/test)?\/campaigns$/u, set => ({
    status: ['published', 'draft'],
    setStatus: status => set({status, page: 1}),
    sort: 'updatedAt',
    setSort: sort => set({sort}),
    visibility: 'visible',
    setVisibility: visibility => set({visibility, page: 1}),
    reset: () =>
      set({
        status: ['published', 'draft'],
        sort: 'updatedAt',
        visibility: 'visible',
      }),
  }));
