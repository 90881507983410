import type {
  UpdateUserAttributesDataADto,
  UserAttributeADto,
} from '@cohort/admin-schemas/userAttributes';
import type {UserPropertyADto} from '@cohort/admin-schemas/userProperty';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {userAttributesKeys} from '@cohort/merchants/hooks/api/UserAttributes';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCurrentUser} from '@cohort/merchants/hooks/contexts/currentUser';
import {updateUserAttributes} from '@cohort/merchants/lib/api/UserAttributes';
import {getUserAttributeValue} from '@cohort/merchants/lib/Utils';
import EditableField from '@cohort/merchants/pages/users/user/overview/EditableField';
import {UserPropertyValueSchema} from '@cohort/shared/schema/common/userProperty';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

export const UpdateUserAttributeFormSchema = z.object({
  id: z.string(),
  value: UserPropertyValueSchema.transform(value => {
    if (value === '' || (Array.isArray(value) && value.length === 0)) {
      return null;
    }
    return value;
  }),
});
type UpdateUserAttributeFormValues = z.infer<typeof UpdateUserAttributeFormSchema>;

type UserAttributeFieldProps = {
  userProperty: UserPropertyADto;
  userAttribute: UserAttributeADto | null;
};
const UserAttributeField: React.FC<UserAttributeFieldProps> = ({userProperty, userAttribute}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'users.user.overview.userAttributeField'});
  const queryClient = useQueryClient();
  const user = useCurrentUser();
  const merchant = useCurrentMerchant();

  const {mutate: updateAttributes} = useCohortMutation({
    mutationFn: async (data: UpdateUserAttributesDataADto) =>
      updateUserAttributes(merchant.id, data),
    notifyErrorMessage: t('notificationUpdateError'),
    notifySuccessMessage: t('notificationUpdateSuccess'),
    onSuccess: async () => {
      await queryClient.invalidateQueries(userAttributesKeys.userAttributes);
      reset({});
    },
  });

  const {register, control, handleSubmit, reset} = useForm<UpdateUserAttributeFormValues>({
    defaultValues: {
      id: userProperty.id,
      value: getUserAttributeValue(userProperty, userAttribute),
    },
    resolver: zodResolver(UpdateUserAttributeFormSchema),
  });

  return (
    <EditableField
      control={control}
      register={register}
      name={`value`}
      dataType={userProperty.dataType}
      defaultValue={getUserAttributeValue(userProperty, userAttribute)}
      withRemoveButton
      disabled={userProperty.appId !== null}
      userAttributeClassName="font-normal"
      onSubmit={() =>
        handleSubmit(data => {
          const values = [
            {userPropertyId: userProperty.id, userId: user.id, value: data.value},
          ] satisfies UpdateUserAttributesDataADto;
          return updateAttributes(values);
        })()
      }
    />
  );
};

export default UserAttributeField;
