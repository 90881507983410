import SvgIcon from '@cohort/merchants/components/Svg';

interface ErrorPageTemplateProps {
  code?: number;
  title: string;
  description: string;
  cta?: JSX.Element;
}

const ErrorPageTemplate: React.FC<ErrorPageTemplateProps> = ({code, title, description, cta}) => (
  <div className="flex min-h-screen">
    <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div className="mx-auto w-full max-w-sm lg:w-96">
        <form className="flex flex-col gap-y-2">
          <p className="text-m font-bold capitalize text-primary">{code}</p>
          <p className="text-3xl font-extrabold">{title}</p>
          <p className="text-xl text-slate-500">{description}</p>
          {cta}
        </form>
      </div>
    </div>

    <div className="relative hidden w-0 flex-1 lg:block">
      <div className="flex h-screen flex-row items-center justify-center">
        <SvgIcon name="SignInBg" className="absolute -z-10 min-h-full min-w-full" />
        <SvgIcon name="CohortLogo" height={50} />
      </div>
    </div>
  </div>
);

export default ErrorPageTemplate;
