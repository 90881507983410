import {ModalBackground} from '@cohort/merchants/components/modals/ModalBackground';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {X} from '@phosphor-icons/react';
import type {DialogProps} from '@radix-ui/react-dialog';
import {
  Close,
  Content,
  Description,
  Overlay,
  Portal,
  Root,
  Title,
  Trigger,
} from '@radix-ui/react-dialog';
import type {ComponentPropsWithoutRef, ElementRef, HTMLAttributes} from 'react';
import {forwardRef} from 'react';
import {useTranslation} from 'react-i18next';

const Dialog: React.FC<DialogProps> = props => (
  <ModalBackground open={props.open}>
    <Root {...props} modal={false} />
  </ModalBackground>
);

const DialogTrigger = Trigger;

const DialogPortal = Portal;

const DialogClose = Close;

const DialogOverlay = forwardRef<
  ElementRef<typeof Overlay>,
  ComponentPropsWithoutRef<typeof Overlay>
>(({className, ...props}, ref) => (
  <Overlay
    ref={ref}
    className={cn(
      'fixed inset-0 z-50 bg-black/50 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className
    )}
    {...props}
  />
));
DialogOverlay.displayName = Overlay.displayName;

const DialogContent = forwardRef<
  ElementRef<typeof Content>,
  ComponentPropsWithoutRef<typeof Content> & {displayCloseButton?: boolean}
>(({className, children, displayCloseButton = true, ...props}, ref) => {
  const {t} = useTranslation('components', {keyPrefix: 'modals.dialog'});

  return (
    <DialogPortal>
      <DialogOverlay />
      <Content
        ref={ref}
        className={cn(
          'fixed left-[50%] top-[50%] z-50 grid max-h-[var(--modal-max-height)] w-max max-w-none translate-x-[-50%] translate-y-[-50%] overflow-y-auto rounded-lg border bg-background bg-white shadow-xl ring-0 duration-200 [grid-template-rows:min-content_1fr_min-content] data-[state=open]:animate-in data-[state=closed]:animate-out	data-[state=closed]:fade-out-0	data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg',
          className
        )}
        {...props}
      >
        {children}
        {displayCloseButton && (
          <Close className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
            <div className="flex h-8 w-8 items-center justify-center rounded-md border border-slate-200">
              <X size={16} className="text-slate-500" />
            </div>
            <span className="sr-only">{t('dialogContent.close')}</span>
          </Close>
        )}
      </Content>
    </DialogPortal>
  );
});
DialogContent.displayName = Content.displayName;

const DialogHeader = ({className, ...props}: HTMLAttributes<HTMLDivElement>): JSX.Element => (
  <div
    className={cn('flex flex-col space-y-1.5 p-6 pb-0 text-center sm:text-left', className)}
    {...props}
  />
);
DialogHeader.displayName = 'DialogHeader';

const DialogBody = ({className, ...props}: HTMLAttributes<HTMLDivElement>): JSX.Element => (
  <div
    className={cn(
      'no-scrollbar mt-3 flex flex-col space-y-6 overflow-y-scroll p-6 pt-3 text-center sm:text-left',
      className
    )}
    {...props}
  />
);
DialogBody.displayName = 'DialogBody';

const DialogFooter = ({className, ...props}: HTMLAttributes<HTMLDivElement>): JSX.Element => (
  <div
    className={cn(
      'sticky bottom-0 flex w-full flex-row justify-between bg-slate-100 px-6 pb-4 pt-3',
      className
    )}
    {...props}
  />
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = forwardRef<ElementRef<typeof Title>, ComponentPropsWithoutRef<typeof Title>>(
  ({className, ...props}, ref) => (
    <Title ref={ref} className={cn('text-2xl font-bold leading-none', className)} {...props} />
  )
);
DialogTitle.displayName = Title.displayName;

const DialogDescription = forwardRef<
  ElementRef<typeof Description>,
  ComponentPropsWithoutRef<typeof Description>
>(({className, ...props}, ref) => (
  <Description
    ref={ref}
    className={cn('mt-2 text-base text-muted-foreground', className)}
    {...props}
  />
));
DialogDescription.displayName = Description.displayName;

export {
  Dialog,
  DialogBody,
  DialogPortal,
  DialogOverlay,
  DialogTrigger,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
};
