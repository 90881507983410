import type {UserEvent} from '@cohort/merchants/apps';
import {resourceToUserEvents} from '@cohort/merchants/apps';
import AdminPage from '@cohort/merchants/components/AdminPage';
import NotificationsSettingsList from '@cohort/merchants/components/notifications/NotificationsSettingsList';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import type {EventId} from '@cohort/shared/apps';
import type {UserEventStruct} from '@cohort/shared/apps/userEvent';
import {useTranslation} from 'react-i18next';
import {Navigate} from 'react-router-dom';
import {match} from 'ts-pattern';

const CampaignNotificationsPage: React.FC = () => {
  const {t} = useTranslation('pages', {keyPrefix: 'campaigns.campaign.notifications.page'});
  const campaign = useCurrentCampaign();

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
  };

  const campaignType = campaign.type;
  // We do not support notifications for airdrop campaigns.
  if (campaignType === null) {
    return <Navigate to="/not-found" replace />;
  }

  let campaignUserEvents: ReadonlyArray<UserEvent<UserEventStruct>> = [];
  // Exhaustive match to get a Type error if we add / remove a campaignType.
  match(campaignType)
    .with('journey', () => {
      campaignUserEvents = resourceToUserEvents['journey'];
    })
    .with('store', () => {
      campaignUserEvents = resourceToUserEvents['store'];
    })
    .with('airdrop', () => {
      campaignUserEvents = resourceToUserEvents['airdrop'];
    })
    .exhaustive();

  const campaignUserEventsIds = campaignUserEvents.map(userEvent => userEvent.spec.id as EventId);

  return (
    <AdminPage header={headerConfig}>
      <p className="font-semibold">{t('whenAUserHas')}</p>
      <div className="ml-4">
        <NotificationsSettingsList
          userEventIds={campaignUserEventsIds}
          resource={{
            type: campaignType,
            id: campaign.id,
          }}
        />
      </div>
    </AdminPage>
  );
};

export default CampaignNotificationsPage;
