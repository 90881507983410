import type {PatchMerchantADto} from '@cohort/admin-schemas/merchant';
import Button from '@cohort/merchants/components/buttons/Button';
import FileInput from '@cohort/merchants/components/form/FileInput';
import Input from '@cohort/merchants/components/form/input/Input';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import LocalizedTextEditorInput from '@cohort/merchants/components/form/textEditor/LocalizedTextEditorInput';
import {merchantsKeys} from '@cohort/merchants/hooks/api/Merchants';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {notify} from '@cohort/merchants/hooks/toast';
import {patchMerchant} from '@cohort/merchants/lib/api/Merchants';
import {
  changeSelectedLanguageIfNeeded,
  getDefinedLanguages,
  removeUndefinedLanguages,
} from '@cohort/merchants/lib/form/localization';
import {uploadAsset} from '@cohort/merchants/lib/form/utils';
import {
  emailValidator,
  fileValidatorOptional,
  hexColorValidator,
} from '@cohort/merchants/lib/form/validators';
import {trackBrandSettingsSaveButtonClicked} from '@cohort/merchants/lib/Tracking';
import LinkSection from '@cohort/merchants/pages/settings/integrations/LinkSection';
import {notifyError} from '@cohort/merchants/stores/ErrorModalStore';
import type {Language} from '@cohort/shared/schema/common';
import {
  DEFAULT_ACCENT_COLOR,
  DEFAULT_BACKGROUND_COLOR,
  LanguageSchema,
  LocalizedRichTextSchema,
} from '@cohort/shared/schema/common';
import type {AssetKind} from '@cohort/shared/schema/common/assets';
import {AllowedAssetMimeTypes} from '@cohort/shared/schema/common/assets';
import {ASSETS_MIN_DIMENSIONS} from '@cohort/shared/utils/fileUploads';
import {isFile} from '@cohort/shared-frontend/utils/isFile';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import {isEmpty} from 'lodash';
import {Fragment} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

const BrandSettingsFormSchema = z
  .object({
    name: z.string().min(3, {message: 'errorTooShort3'}),
    description: LocalizedRichTextSchema,
    logoFileKey: z.any().refine(fileValidatorOptional),
    backgroundColorCode: z
      .string()
      .min(1, {message: 'errorRequired'})
      .refine(hexColorValidator, 'errorHexColor'),
    accentColorCode: z
      .string()
      .min(1, {message: 'errorRequired'})
      .refine(hexColorValidator, 'errorHexColor'),
    supportEmail: z
      .string()
      .nullable()
      .transform(value => (value === '' ? null : value))
      .refine(email => email === null || emailValidator(email), {
        message: 'errorEmail',
      }),
    defaultLanguage: LanguageSchema,
    selectedLanguage: LanguageSchema,
    definedLanguages: z.array(LanguageSchema),
  })
  .superRefine(({description, defaultLanguage, definedLanguages}, ctx) => {
    const refinedDescription = removeUndefinedLanguages(description, definedLanguages);
    if (!isEmpty(refinedDescription) && refinedDescription[defaultLanguage] === undefined) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorRequired',
        path: [`description.${defaultLanguage}`],
      });
    }
  })
  .transform(data => {
    data.description = removeUndefinedLanguages(data.description, data.definedLanguages);
    return data;
  });

export type BrandSettingsFormValues = z.infer<typeof BrandSettingsFormSchema>;

const BrandSettingsForm: React.FC = () => {
  const merchant = useCurrentMerchant();
  const {merchantId, updateOrganizationMerchantLogo} = useUserSessionStore(store => ({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    merchantId: store.merchantId!,
    updateOrganizationMerchantLogo: store.updateOrganizationMerchantLogo,
  }));

  const queryClient = useQueryClient();
  const {t} = useTranslation('pages', {keyPrefix: 'settings.brand.brandSettingsForm'});

  const {register, control, handleSubmit, formState, reset, watch, setValue} =
    useForm<BrandSettingsFormValues>({
      defaultValues: {
        name: merchant.name,
        description: merchant.description,
        logoFileKey: merchant.logoFileKey ?? null,
        backgroundColorCode: merchant.backgroundColorCode ?? DEFAULT_BACKGROUND_COLOR,
        accentColorCode: merchant.accentColorCode ?? DEFAULT_ACCENT_COLOR,
        supportEmail: merchant.supportEmail ?? undefined,
        defaultLanguage: merchant.defaultLanguage,
        selectedLanguage: merchant.defaultLanguage,
        definedLanguages: getDefinedLanguages(merchant.defaultLanguage, [merchant.description]),
      },
      resolver: zodResolver(BrandSettingsFormSchema),
    });

  const selectedLanguage = watch('selectedLanguage');
  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  const {isLoading, mutate: updateMerchant} = useCohortMutation({
    mutationFn: async (data: PatchMerchantADto) => patchMerchant(merchant.id, data),
    onSuccess: async updatedMerchant => {
      await queryClient.invalidateQueries(merchantsKeys.getById(merchantId));
      updateOrganizationMerchantLogo(updatedMerchant.logoFileKey);
      notify('success', t('notificationUpdateSuccess'));
      trackBrandSettingsSaveButtonClicked();
      reset({}, {keepValues: true});
    },
  });

  const {isLoading: isUploading, mutateAsync: uploadFile} = useCohortMutation({
    mutationFn: async ({file, assetKind}: {file: File; assetKind: AssetKind}) =>
      uploadAsset(file, assetKind, merchant.id),
    onError: err => notifyError(err, t('errorFileUploadFailed')),
  });

  return (
    <form
      className="space-y-6"
      onSubmit={handleSubmit(
        async data => {
          const patchData = {
            ...data,
          };
          if (isFile(data.logoFileKey)) {
            const asset = await uploadFile({
              file: data.logoFileKey,
              assetKind: 'logo',
            });
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (asset === null) {
              return notifyError(t('errorFileUploadFailed'));
            }
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            patchData.logoFileKey = asset.fileKey!;
          }
          updateMerchant(patchData);
        },
        errors =>
          changeSelectedLanguageIfNeeded(errors, merchant.defaultLanguage, (language: Language) =>
            setValue('selectedLanguage', language)
          )
      )}
    >
      <LinkSection title={t('title')} description={t('subtitle')} />
      <LanguageSelectorInput
        control={control}
        definedLanguagesPath="definedLanguages"
        selectedLanguagePath="selectedLanguage"
      />
      {isDefaultLanguageSelected && (
        <Input
          type="text"
          label={t('labelName')}
          name="name"
          register={register}
          control={control}
          className="w-1/2"
        />
      )}
      <LocalizedTextEditorInput
        label={t('labelDescription')}
        name="description"
        register={register}
        control={control}
        description={t('subtitleDescription')}
        selectedLanguage={selectedLanguage}
        optional={!isDefaultLanguageSelected}
      />
      {isDefaultLanguageSelected && (
        <Fragment>
          <FileInput
            label={t('labelLogo')}
            name="logoFileKey"
            register={register}
            control={control}
            acceptHint={t('instructionslogo')}
            accept={AllowedAssetMimeTypes.logo.options.join(',')}
            assetKind="logo"
            description={t('helperLogo', {
              width: ASSETS_MIN_DIMENSIONS['logo'].width,
              height: ASSETS_MIN_DIMENSIONS['logo'].height,
            })}
            withResize
          />
          <div className="w-1/2">
            <Input
              type="text"
              label={t('labelContact')}
              name="supportEmail"
              register={register}
              control={control}
              placeholder="support@company.com"
            />
          </div>
        </Fragment>
      )}
      <div className="flex justify-end space-x-2">
        <Button
          type="submit"
          loading={isLoading || isUploading}
          disabled={!formState.isDirty}
          data-testid="save"
        >
          {t('buttonSave')}
        </Button>
      </div>
    </form>
  );
};

export default BrandSettingsForm;
