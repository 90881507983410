import type {JourneyStepTriggerADto} from '@cohort/admin-schemas/journeySteps';
import {getAppsWithTriggerIntegrations} from '@cohort/merchants/apps';
import AppBadge from '@cohort/merchants/apps/AppBadge';
import {Fragment} from 'react';
import {groupBy} from 'remeda';

type CampaignJourneyStepAppBadgesProps = {
  triggers: Array<JourneyStepTriggerADto>;
};

const CampaignJourneyStepAppBadges: React.FC<CampaignJourneyStepAppBadgesProps> = ({triggers}) => {
  const apps = getAppsWithTriggerIntegrations();
  const triggerIntegrationApps = groupBy(
    triggers,
    trigger => trigger.triggerIntegrationId.split('.')[0]
  );
  const triggerApps = apps.filter(app => app.spec.id in triggerIntegrationApps);

  if (triggerApps.length === 0) {
    return <Fragment />;
  }

  return (
    <ul className="flex items-center gap-2">
      {triggerApps.map(app => (
        <li key={app.spec.id} className="align-self-start">
          <AppBadge appId={app.spec.id} />
        </li>
      ))}
    </ul>
  );
};

export default CampaignJourneyStepAppBadges;
