// i18nOwl-ignore [components.digital-asset, components.perk, components.journey, components.membership-pass]
import type {
  CustomComponentADto,
  CustomComponentInputADto,
} from '@cohort/admin-schemas/customComponent';
import Button from '@cohort/merchants/components/buttons/Button';
import JsonEditor from '@cohort/merchants/components/editor/JsonEditor';
import LiquidEditor from '@cohort/merchants/components/editor/LiquidEditor';
import {customComponentsKeys} from '@cohort/merchants/hooks/api/CustomComponents';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import usePreviewStyle from '@cohort/merchants/hooks/previewStyle';
import {upsertCustomComponent} from '@cohort/merchants/lib/api/CustomComponents';
import {reducer} from '@cohort/merchants/pages/settings/customization/utils';
import liquid from '@cohort/shared/lib/liquid';
import {DEFAULT_ACCENT_COLOR, DEFAULT_BACKGROUND_COLOR} from '@cohort/shared/schema/common';
import type {CustomComponentType} from '@cohort/shared/schema/common/customComponents';
import {generateMockObjectFromSchema} from '@cohort/shared/utils/zod';
import Switch from '@cohort/shared-frontend/components/Switch';
import {ArrowSquareOut} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import {Fragment, useReducer, useRef} from 'react';
import type {DeepPartial} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import type {JSONEditor} from 'vanilla-jsoneditor';
import {ValidationSeverity} from 'vanilla-jsoneditor';
import type {z} from 'zod';

type ComponentPreviewProps = {
  template: string;
  type: CustomComponentType;
};

const docAnchorsMapping: Record<CustomComponentType, string> = {
  'digital-asset': 'customizing-digital-asset-cards',
  journey: 'customizing-journey-cards',
  'membership-pass': 'customizing-the-membership-pass',
  perk: 'customizing-perk-cards',
};

const editorDefaultValue = (type: CustomComponentType): string => {
  if (type === 'membership-pass') {
    return `<style>
#membership-pass-card {
  border-radius: var(--xps-membership-pass-border-radius);
  background-color: var(--xps-membership-pass-background-color);
  width: 100%;
}
</style>
<div id="membership-pass-card">
</div>`;
  }
  return `<style>
#${type}-card {
  border: 1px solid var(--xps-card-border-color);
  border-radius: var(--xps-card-border-radius);
  background-color: var(--xps-card-background-color);
  height: 100%;
}
</style>
<div id="${type}-card">
</div>`;
};

const ComponentPreview: React.FC<ComponentPreviewProps> = ({template, type}) => {
  const merchant = useCurrentMerchant();

  usePreviewStyle(
    merchant.backgroundColorCode ?? DEFAULT_BACKGROUND_COLOR,
    merchant.accentColorCode ?? DEFAULT_ACCENT_COLOR
  );

  return (
    <div id="preview" className="flex h-full items-center justify-center rounded-xl p-4">
      <div
        style={{
          width:
            type === 'membership-pass'
              ? `calc(var(--xps-left-panel-width) - 4rem)` // 4rem is the padding size of the left panel
              : `var(--xps-${type}-card-width)`,
          maxWidth: '100%',
        }}
      >
        {parse(template)}
      </div>
    </div>
  );
};

type ComponentCustomisationFormProps<T> = {
  customComponent?: CustomComponentADto;
  type: CustomComponentType;
  contextSchema: z.ZodSchema;
  contextData?: DeepPartial<T>;
  suggestionsSchema?: z.ZodSchema | false;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function ComponentsCustomisationForm<T extends Record<string, unknown>>({
  customComponent,
  type,
  contextSchema,
  contextData,
  suggestionsSchema,
}: ComponentCustomisationFormProps<T>): JSX.Element {
  const merchant = useCurrentMerchant();
  const jsonEditorRef = useRef<JSONEditor>();
  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.customization.componentsCustomisationForm',
  });
  const queryClient = useQueryClient();
  const defaultContext = generateMockObjectFromSchema<T>(contextSchema, contextData);
  const templateDefaultValue = customComponent?.template ?? editorDefaultValue(type);
  const rawTemplate = useRef<string>(templateDefaultValue);
  const [state, dispatch] = useReducer(reducer, {
    canSave: true,
    customisationEnabled: customComponent?.enabled ?? false,
    context: defaultContext,
    liquidTemplate: liquid.parseAndRenderSync(templateDefaultValue, defaultContext),
  });

  const {isLoading, mutate: upsert} = useCohortMutation({
    mutationFn: async (data: CustomComponentInputADto) => upsertCustomComponent(merchant.id, data),
    notifySuccessMessage: t('updateSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(customComponentsKeys.getByMerchantId(merchant.id));
    },
  });

  return (
    <div className="space-y-6">
      <h3 className="-mb-2 text-base font-semibold">
        {t('digitalAssetComponentEditor', {componentName: t(`components.${type}`)})}
        <a
          href={`https://docs.getcohort.com/concepts/experience-space/customizing#${docAnchorsMapping[type]}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          <Button className="ml-2 inline-block text-xs" variant="link" size="icon">
            <ArrowSquareOut className="mr-1 inline-block" size={12} />
            {t('viewDoc')}
          </Button>
        </a>
      </h3>
      <div className="flex items-center gap-2">
        <Switch
          id="enable"
          checked={state.customisationEnabled}
          onCheckedChange={check => {
            dispatch({
              type: 'SET_CUSTOMISATION_ENABLED',
              payload: check,
            });

            if (!check) {
              jsonEditorRef.current?.destroy();
              jsonEditorRef.current = undefined;
            }
          }}
        />
        <label htmlFor="enable">
          {t('enableCustomisation', {componentName: t(`components.${type}`)})}
        </label>
      </div>
      {state.customisationEnabled && (
        <Fragment>
          <p className="!-mb-4 text-sm text-gray-500">
            {t('hintJsonEditor', {componentName: t(`components.${type}`)})}
          </p>
          <JsonEditor
            value={state.context}
            ref={jsonEditorRef}
            validator={json => {
              const res = contextSchema.safeParse(json);

              if (!res.success) {
                return res.error.errors.map(error => ({
                  path: error.path as Array<string>,
                  message: error.message,
                  severity: ValidationSeverity.error,
                }));
              }
              const rendered = liquid.parseAndRenderSync(rawTemplate.current, res.data);

              dispatch({
                type: 'UPDATE_LIQUID_TEMPLATE',
                payload: {
                  liquidTemplate: rendered,
                  context: res.data,
                },
              });
              return [];
            }}
          />
          <div className="grid gap-4 [grid-template-columns:2fr_1fr]">
            <LiquidEditor
              height={600}
              defaultValue={templateDefaultValue}
              liquidConfig={{
                schema: contextSchema,
                context: state.context,
              }}
              onValidate={markers =>
                dispatch({type: 'UPDATE_CAN_SAVE', payload: markers.length === 0})
              }
              onChange={({template, parsedTemplate}) => {
                rawTemplate.current = template;
                dispatch({
                  type: 'UPDATE_LIQUID_TEMPLATE',
                  payload: {
                    liquidTemplate: DOMPurify.sanitize(parsedTemplate, {
                      FORCE_BODY: true,
                      ADD_TAGS: ['style'],
                      ADD_ATTR: ['class', 'id', 'style'],
                    }),
                  },
                });
              }}
              onError={error => {
                if (error instanceof Error) {
                  dispatch({
                    type: 'SET_LIQUID_ERROR',
                    payload: error.message,
                  });
                }
              }}
              suggestionsSchema={suggestionsSchema}
            />
            <div className="flex flex-col gap-2">
              {state.error && (
                <div className="rounded-md bg-red-500 p-2 text-center text-white">
                  {state.error}
                </div>
              )}
              <ComponentPreview template={state.liquidTemplate} type={type} />
            </div>
          </div>
        </Fragment>
      )}
      <div className="flex justify-end space-x-2">
        <Button
          type="submit"
          disabled={!state.canSave}
          loading={isLoading}
          onClick={() => {
            const template = rawTemplate.current;
            const component: CustomComponentInputADto = {
              template: template,
              enabled: state.customisationEnabled,
              type,
            };

            return upsert(component);
          }}
        >
          {t('save')}
        </Button>
      </div>
    </div>
  );
}
