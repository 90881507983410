import type {InstagramCommentMediaVerificationAttemptData} from '@cohort/shared/apps/instagram/triggers/commentMedia';
import {Quotes} from '@phosphor-icons/react';
import {Fragment} from 'react';

const InstagramCommentMediaTriggerIntegrationStepCompletionContextComponent: React.FC<{
  data: InstagramCommentMediaVerificationAttemptData;
}> = ({data}) => {
  const {comment} = data;

  if (!comment) {
    return <Fragment />;
  }

  const {text} = comment;
  return (
    <div className="flex">
      <div className="mr-2 w-4">
        <Quotes className="h-4 w-4 text-slate-400" />
      </div>
      <p className="italic">{text}</p>
    </div>
  );
};

export default InstagramCommentMediaTriggerIntegrationStepCompletionContextComponent;
