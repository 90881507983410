import type {PaginationFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import {useBasePageFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import type {EventId} from '@cohort/shared/apps';
import {EventIdSchema} from '@cohort/shared/apps';

export const eventIdFilter = EventIdSchema.Values;

export type UserActivityFilters = {
  search: string;
  eventIds: EventId[];
  setSearch: (search: string) => void;
  setUserEventIds: (eventIds: EventId[]) => void;
  reset: () => void;
};

export const useUserActivityFilters = (): PaginationFilters & UserActivityFilters =>
  useBasePageFilters<UserActivityFilters>(/^(\/test)?\/users\/.*\/activity$/u, set => ({
    search: '',
    eventIds: [],
    setSearch: search => set({search, page: 1}),
    setUserEventIds: eventIds => set({eventIds, page: 1}),
    reset: () => set({page: 1, search: ''}),
  }));
