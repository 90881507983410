import type {PerkAnalyticsADto} from '@cohort/admin-schemas/perk';
import Button from '@cohort/merchants/components/buttons/Button';
import CardListDisplay from '@cohort/merchants/components/CardListDisplay';
import EmptyState from '@cohort/merchants/components/EmptyState';
import ErrorState from '@cohort/merchants/components/ErrorState';
import LoadingState from '@cohort/merchants/components/LoadingState';
import {usePerksDisplay} from '@cohort/merchants/hooks/stores/perksDisplay';
import PerkListItem from '@cohort/merchants/pages/perks/overview/PerkListItem';
import PerkOverviewCard from '@cohort/merchants/pages/perks/overview/PerkOverviewCard';
import {Plus} from '@phosphor-icons/react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {match, P} from 'ts-pattern';

interface PerksListProps {
  perks?: PerkAnalyticsADto[];
  isLoading: boolean;
  isError: boolean;
  onHandleCreatePerkClick: () => void;
}

const PerksList: React.FC<PerksListProps> = ({
  perks,
  isLoading,
  isError,
  onHandleCreatePerkClick,
}) => {
  const display = usePerksDisplay(store => store.display);
  const {t} = useTranslation('pages', {keyPrefix: 'perks.overview.perksList'});

  const perksGuard = (perks: PerkAnalyticsADto[] | undefined): perks is PerkAnalyticsADto[] =>
    perks !== undefined && perks.length > 0;

  const content = match({perks, isLoading, isError})
    .with({isLoading: true}, () => <LoadingState />)
    .with({isError: true}, () => <ErrorState />)
    .with(
      {
        perks: P.select(P.when(perksGuard)),
      },
      perks => (
        <CardListDisplay display={display} testId="perks-list">
          {perks.map(perk =>
            display === 'card' ? (
              <PerkOverviewCard key={perk.id} perk={perk} />
            ) : (
              <PerkListItem key={perk.id} perk={perk} />
            )
          )}
        </CardListDisplay>
      )
    )
    .otherwise(() => (
      <EmptyState
        title={t('labelEmpty')}
        description={t('placeholderEmpty')}
        cta={
          <Button onClick={() => onHandleCreatePerkClick()} data-testid="create-campaign-empty">
            <Plus className="-ml-1 mr-2 h-5 w-5" />
            {t('createPerk')}
          </Button>
        }
      />
    ));

  return content;
};

export default PerksList;
