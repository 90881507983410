import type {GetCampaignsParamsADto} from '@cohort/admin-schemas/campaign';
import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import CardListDisplayToggler from '@cohort/merchants/components/buttons/CardListDisplayToggler';
import Filters from '@cohort/merchants/components/filters/Filters';
import SingleChoiceFilter from '@cohort/merchants/components/filters/SingleChoiceFilter';
import {useCampaigns} from '@cohort/merchants/hooks/api/Campaigns';
import {
  sortFilter,
  statusFilter,
  useCampaignsListFilters,
  visibilityFilter,
} from '@cohort/merchants/hooks/filters/campaignsListFilters';
import {useCampaignsDisplay} from '@cohort/merchants/hooks/stores/campaignsDisplay';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import {
  trackCampaignCreateButtonClicked,
  trackCampaignsDisplayChanged,
  trackCampaignsOverviewPageViewed,
  trackCampaignsSortingChanged,
  trackCampaignsStatusFilterChanged,
} from '@cohort/merchants/lib/Tracking';
import CampaignsList from '@cohort/merchants/pages/campaigns/overview/CampaignsList';
import CreateCampaignModal from '@cohort/merchants/pages/campaigns/overview/CreateCampaignModal';
import {Plus} from '@phosphor-icons/react';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {shallow} from 'zustand/shallow';

const PAGE_SIZE = 20;

const CampaignsOverviewPage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {t} = useTranslation('pages', {keyPrefix: 'campaigns.overview.page'});
  const {display, setDisplay} = useCampaignsDisplay(
    store => ({
      display: store.display,
      setDisplay: store.setDisplay,
    }),
    shallow
  );
  const filters = useCampaignsListFilters();

  const [createCampaignModalOpen, setCreateCampaignModalOpen] = useState(false);

  // i18nOwl-ignore [status.draft, status.ended, status.published]
  const statusFilterOptions = statusFilter.map(status => ({
    value: status,
    label: t(`status.${status}`),
    active: filters.status ? filters.status.includes(status) : false,
  }));

  // i18nOwl-ignore [sortBy.label, sortBy.internalName, sortBy.-numOrdered, sortBy.-totalSupply, sortBy.-updatedAt]
  const sortingOptions = sortFilter.map(sort => ({
    value: sort,
    label: t(`sortBy.${sort}`),
    active: filters.sort === sort,
  }));

  // i18nOwl-ignore [visibility.all, visibility.archived, visibility.visible]
  const visibilityFilterOptions = visibilityFilter.map(visibility => ({
    value: visibility,
    label: t(`visibility.${visibility}`),
    active: filters.visibility === visibility,
  }));

  const {data, isLoading, isError} = useCampaigns(merchantId, {
    status: filters.status,
    page: filters.page,
    pageSize: PAGE_SIZE,
    orderBy: filters.sort,
    visibility: filters.visibility,
  });
  const [pagination, campaigns] = data ?? [];

  useEffect(() => {
    trackCampaignsOverviewPageViewed();
  }, []);

  function handleCreateCampaignClick(): void {
    setCreateCampaignModalOpen(true);
    trackCampaignCreateButtonClicked();
  }

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
    topRightElements: (
      <Button
        key="createCampaign"
        onClick={handleCreateCampaignClick}
        data-testid="create-campaign-empty"
      >
        <Plus className="-ml-1 mr-2 h-5 w-5" />
        {t('buttonAddCampaign')}
      </Button>
    ),
  };

  const paginationConfig = {
    pagination,
    onPageChange: filters.setPage,
  };

  return (
    <PageLayout title={t('title')}>
      <AdminPage header={headerConfig} pagination={paginationConfig}>
        <div className="mb-7 flex w-full flex-col justify-between space-x-2 sm:flex-row sm:items-center">
          <Filters
            filters={[
              {
                label: t('filterByStatus'),
                options: statusFilterOptions,
                type: 'multiple',
                onChange: statusOptions => {
                  const activeFilterOptions = statusOptions.filter(o => o.active);
                  trackCampaignsStatusFilterChanged(activeFilterOptions);
                  filters.setStatus(
                    activeFilterOptions.map(o => o.value) as GetCampaignsParamsADto['status']
                  );
                },
              },
              {
                label: t('filterByVisibility'),
                options: [...visibilityFilterOptions],
                type: 'single',
                defaultValue: 'visible',
                onChange: visibilityOption => {
                  filters.setVisibility(
                    visibilityOption.value as GetCampaignsParamsADto['visibility']
                  );
                },
              },
            ]}
          />
          <div className="flex flex-row items-center space-x-4">
            <SingleChoiceFilter
              options={sortingOptions}
              onChange={option => {
                trackCampaignsSortingChanged(option.value);
                filters.setSort(option.value as GetCampaignsParamsADto['orderBy']);
              }}
              prefix={t('sortBy.label')}
            />
            <CardListDisplayToggler
              display={display}
              setDisplay={display => {
                trackCampaignsDisplayChanged(display);
                setDisplay(display);
              }}
            />
          </div>
        </div>
        <CampaignsList
          campaigns={campaigns}
          isError={isError}
          isLoading={isLoading}
          onHandleCreateCampaignClick={handleCreateCampaignClick}
        />
        {createCampaignModalOpen && (
          <CreateCampaignModal onClose={() => setCreateCampaignModalOpen(false)} />
        )}
      </AdminPage>
    </PageLayout>
  );
};

export default CampaignsOverviewPage;
