import type {CohortWithBadgeADto} from '@cohort/admin-schemas/cohort';
import {useCohort} from '@cohort/merchants/hooks/api/Cohorts';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {createContext, Fragment} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

type CurrentCohortProviderProps = {
  children: React.ReactNode;
};

export const CurrentCohortContext = createContext<CohortWithBadgeADto | null>(null);

export const CurrentCohortProvider: React.FC<CurrentCohortProviderProps> = ({children}) => {
  const merchant = useCurrentMerchant();
  const {cohortId} = useParams();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const {data: cohort, isFetched} = useCohort(merchant.id, cohortId!);

  if (cohortId === undefined) {
    navigate('/not-found', {replace: true});
  }

  if (isFetched && !cohort) {
    navigate('/not-found', {replace: true});
  }

  // Maybe one day we could create Skeletons for page loading
  if (cohort === undefined) {
    return <Fragment />;
  }

  return <CurrentCohortContext.Provider value={cohort}>{children}</CurrentCohortContext.Provider>;
};
