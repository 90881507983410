// i18nOwl-ignore [app-twitter.description]
import type {App, TriggerIntegration} from '@cohort/merchants/apps';
import {makeOauthEditConnectionComponent} from '@cohort/merchants/apps/appUtils';
import {createConnectAccountTriggerIntegration} from '@cohort/merchants/apps/common/triggers/ConnectAccountTriggerIntegration';
import translationEn from '@cohort/merchants/apps/twitter/locales/en.json';
import translationFr from '@cohort/merchants/apps/twitter/locales/fr.json';
import {TwitterFollowTriggerIntegration} from '@cohort/merchants/apps/twitter/triggerIntegrations/follow/TriggerIntegration';
import type {TwitterAppStruct} from '@cohort/shared/apps/twitter';
import {TwitterAppSpec} from '@cohort/shared/apps/twitter';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

const TwitterApp: App<TwitterAppStruct> = {
  spec: TwitterAppSpec,
  ConnectionEditComponent: makeOauthEditConnectionComponent('twitter'),
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="twitter" height={40} width={40} />,
  notificationIntegrations: [],
  perkIntegrations: [],
  triggerIntegrations: [
    createConnectAccountTriggerIntegration(TwitterAppSpec) as TriggerIntegration,
    TwitterFollowTriggerIntegration as TriggerIntegration,
  ],
  userEvents: [],
};

export default TwitterApp;
