/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getMerchant, getMerchantApiSettings} from '@cohort/merchants/lib/api/Merchants';

export const merchantsKeys = {
  merchants: ['merchants'] as const,
  getById: (merchantId: string) => [...merchantsKeys.merchants, merchantId] as const,
  settings: (merchantId: string) => [...merchantsKeys.getById(merchantId), 'settings'] as const,
  customStyle: (merchantId: string, customStyleSheetUrl: string) =>
    [...merchantsKeys.getById(merchantId), 'customStyle', customStyleSheetUrl] as const,
};

export const useMerchant = (merchantId: string, options?: QueryOptions) =>
  useCohortQuery({
    queryKey: merchantsKeys.getById(merchantId),
    queryFn: async () => getMerchant(merchantId),
    refetchInterval: options?.autoRefresh === true ? 5000 : false,
  });

export const useMerchantApiSettings = (merchantId: string) =>
  useCohortQuery({
    queryKey: merchantsKeys.settings(merchantId),
    queryFn: async () => getMerchantApiSettings(merchantId),
  });

export const useMerchantCustomStyle = (merchantId: string, customStyleSheetUrl: string) =>
  useCohortQuery({
    queryKey: merchantsKeys.customStyle(merchantId, customStyleSheetUrl),
    queryFn: async () => {
      const res = await fetch(customStyleSheetUrl);

      return res.text();
    },
  });
