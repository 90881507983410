import {useUrlParams} from '@cohort/merchants/hooks/useUrlParams';
import {useMemo} from 'react';

export default function usePageSearchParam(): number {
  const {searchParams} = useUrlParams();
  const page = useMemo(() => {
    const searchPage = parseInt(searchParams.get('page') ?? '1', 10);

    return isNaN(searchPage) ? 1 : searchPage;
  }, [searchParams]);

  return page;
}
