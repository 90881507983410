import {useCurrentApp} from '@cohort/merchants/hooks/contexts/currentApp';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import AppPageBreadcrumb from '@cohort/merchants/pages/apps/app/AppPageBreadcrumb';
import AppPageMenu from '@cohort/merchants/pages/apps/app/AppPageMenu';
import {CurrentAppProvider} from '@cohort/merchants/pages/apps/app/CurrentAppContext';
import {Outlet} from 'react-router-dom';

const AppPageSkeleton: React.FC = () => {
  const {appSpec} = useCurrentApp();

  return (
    <PageLayout title={appSpec.name} breadcrumb={<AppPageBreadcrumb />} menu={<AppPageMenu />}>
      <Outlet />
    </PageLayout>
  );
};

const AppPageLayout = (): JSX.Element => (
  <CurrentAppProvider>
    <AppPageSkeleton />
  </CurrentAppProvider>
);

export default AppPageLayout;
