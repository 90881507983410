import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import type {CohortUserCreatedEventStruct} from '@cohort/shared/apps/cohort/userEvents/userCreated';
import {CohortUserCreatedEventSpec} from '@cohort/shared/apps/cohort/userEvents/userCreated';
import {Trans} from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => (
  <Trans i18nKey="userEvents.user.created.detail" ns="app-cohort" />
);

export const CohortUserCreatedUserEvent: UserEvent<CohortUserCreatedEventStruct> = {
  spec: CohortUserCreatedEventSpec,
  detailComponent: DetailComponent,
};
