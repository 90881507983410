import {Badge} from '@cohort/merchants/components/Badge';
import {useTranslation} from 'react-i18next';

interface StatusBadgeProps {
  status: string;
}

export const MembershipStatusBadge = (props: StatusBadgeProps): JSX.Element => {
  const {status} = props;
  const {t} = useTranslation('components', {keyPrefix: 'membershipStatusBadge'});

  let badgeData;

  switch (status) {
    case 'active':
      badgeData = {
        text: t('statusActive'),
        backgroundColor: 'bg-green-100',
        textColor: 'text-green-800',
        dotColor: 'text-green-400',
      };
      break;
    case 'pending':
      badgeData = {
        text: t('statusPending'),
        backgroundColor: 'bg-yellow-100',
        textColor: 'text-yellow-800',
        dotColor: 'text-yellow-400',
      };
      break;
    case 'expired':
      badgeData = {
        text: t('statusExpired'),
        backgroundColor: 'bg-primary/10',
        textColor: 'text-primary',
        dotColor: 'text-primary',
      };
      break;
    case 'deactivated':
      badgeData = {
        text: t('statusDesactivated'),
        backgroundColor: 'bg-red-100',
        textColor: 'text-red-800',
        dotColor: 'text-red-400',
      };
      break;
    default:
      throw new Error('Unsupported status');
  }

  return (
    <Badge
      text={badgeData.text}
      backgroundColor={badgeData.backgroundColor}
      textColor={badgeData.textColor}
      dotColor={badgeData.dotColor}
    />
  );
};

MembershipStatusBadge.displayName = 'MembershipStatusBadge';
