import {UserAttributeASchema} from '@cohort/admin-schemas/userAttributes';
import {DateSchema, EmailSchema} from '@cohort/shared/schema/common';
import {CohortTypeSchema} from '@cohort/shared/schema/common/cohort';
import {PaginationParamsSchema} from '@cohort/shared/schema/common/pagination';
import {ResourceListDataTypeSchema} from '@cohort/shared/schema/common/userProperty';
import {z} from 'zod';

export const UserAnalyticsASchema = z.object({
  id: z.string().uuid(),
  email: EmailSchema,
  engagementScore: z.number().nullable(),
  membershipPassHandle: z.string(),
  membershipPassMemberId: z.number(),
  membershipPassBlockExplorerUrl: z.string().url().nullable(),
  joinedAt: DateSchema,
  lastActiveAt: DateSchema,
  journeysCount: z.number(),
  journeysCompletedCount: z.number(),
  ownershipsCount: z.number(),
  perksCount: z.number(),
  perkUsagesCount: z.number(),
  userAttributes: z.array(UserAttributeASchema).optional(),
});
export type UserAnalyticsADto = z.infer<typeof UserAnalyticsASchema>;

export const GetUserAnalyticsOrderByParamASchema = z.enum([
  '-lastActiveAt',
  'lastActiveAt',
  '-joinedAt',
  'joinedAt',
  '-journeysCompletedCount',
  'journeysCompletedCount',
  '-perkUsagesCount',
  'perkUsagesCount',
  '-ownershipsCount',
  'ownershipsCount',
  '-engagementScore',
  'engagementScore',
]);
export type GetUserAnalyticsOrderByParamADto = z.infer<typeof GetUserAnalyticsOrderByParamASchema>;

export const GetUserAnalyticsParamsASchema = PaginationParamsSchema.extend({
  orderBy: GetUserAnalyticsOrderByParamASchema,
  search: z.string().min(1).optional(),
});
export type GetUserAnalyticsParamsADto = z.infer<typeof GetUserAnalyticsParamsASchema>;

export const UpdateUserDataASchema = z.object({
  membershipPass: z
    .object({
      subscriptionDate: DateSchema.optional(),
    })
    .optional(),
});
export type UpdateUserDataADto = z.infer<typeof UpdateUserDataASchema>;

export const ImportUserASchema = z.object({
  email: EmailSchema,
  userAttributes: z
    .array(
      z.object({
        userPropertyId: z.string().uuid(),
        value: z
          .union([
            z.string(),
            z.number(),
            z.boolean(),
            DateSchema,
            z.array(z.string()),
            ResourceListDataTypeSchema,
          ])
          .nullable(),
      })
    )
    .optional(),
});
export const ImportUsersASchema = z.object({
  users: z.array(ImportUserASchema),
  cohortId: z.string().uuid().optional(),
});
export type ImportUsersADto = z.infer<typeof ImportUsersASchema>;

export const GetUserCohortsParamsASchema = PaginationParamsSchema;
export type GetUserCohortsParamsADto = z.infer<typeof GetUserCohortsParamsASchema>;

export const UserCohortASchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  type: CohortTypeSchema,
  memberSince: DateSchema,
  createdAt: DateSchema,
});
export type UserCohortADto = z.infer<typeof UserCohortASchema>;

export const GetUserBadgesParamsASchema = PaginationParamsSchema;
export type GetUserBadgesParamsADto = z.infer<typeof GetUserBadgesParamsASchema>;
