/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getContentCategories} from '@cohort/merchants/lib/api/ContentCategories';

export const contentCategoriesKeys = {
  contentCategories: ['content-categories'] as const,
  list: (merchantId: string) =>
    [...contentCategoriesKeys.contentCategories, merchantId, 'list'] as const,
};

export const useContentCategories = (merchantId: string, options?: QueryOptions) =>
  useCohortQuery({
    queryKey: contentCategoriesKeys.list(merchantId),
    queryFn: async () => getContentCategories(merchantId),
    ...options,
  });
