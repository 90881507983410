import {create} from 'zustand';
import {persist} from 'zustand/middleware';

type PerksDisplay = 'card' | 'list';

interface PerksDisplayStore {
  display: PerksDisplay;
  setDisplay: (display: PerksDisplay) => void;
}

export const usePerksDisplay = create<PerksDisplayStore>()(
  persist(
    set => ({
      display: 'list',
      setDisplay: display => set({display}),
    }),
    {
      name: 'perks-display',
    }
  )
);
