import {DateSchema, EmailSchema} from '@cohort/shared/schema/common';
import {CurrencySchema} from '@cohort/shared/schema/common/currency';
import {OrderTypeSchema} from '@cohort/shared/schema/common/orders';
import {z} from 'zod';

export const OrderUserASchema = z.object({
  id: z.string().uuid(),
  email: EmailSchema,
});
export type OrderUserADto = z.infer<typeof OrderUserASchema>;

export const OrderASchema = z.object({
  id: z.string().uuid(),
  amount: z.number().int().min(0),
  campaignId: z.string().uuid(),
  createdAt: DateSchema,
  currency: CurrencySchema.nullable(),
  type: OrderTypeSchema,
  user: OrderUserASchema,
});
export type OrderADto = z.infer<typeof OrderASchema>;
