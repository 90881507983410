import type {UpdateCohortADto} from '@cohort/admin-schemas/cohort';
import BreadCrumb from '@cohort/merchants/components/BreadCrumb';
import CohortBadgesVisuals from '@cohort/merchants/components/cohorts/CohortBadgesVisuals';
import {CohortTypeBadge} from '@cohort/merchants/components/cohorts/CohortTypeBadge';
import {cohortsKeys} from '@cohort/merchants/hooks/api/Cohorts';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentCohort} from '@cohort/merchants/hooks/contexts/currentCohort';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {patchCohort} from '@cohort/merchants/lib/api/Cohorts';
import DeleteCohort from '@cohort/merchants/pages/users/cohort/DeleteCohort';
import {useQueryClient} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

const PatchCohortFormSchema = z.object({
  name: z.string().min(3, {message: 'errorTooShort3'}),
});

const CohortPageBreadcrumb = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const queryClient = useQueryClient();
  const cohort = useCurrentCohort();

  const {t} = useTranslation('pages', {
    keyPrefix: 'users.cohort.cohortPageBreadcrumb',
  });

  const {mutate: updateCohort} = useCohortMutation({
    mutationFn: async (cohortData: UpdateCohortADto) =>
      patchCohort(merchantId, cohort.id, cohortData),
    notifyErrorMessage: t('notificationErrorUpdate'),
    notifySuccessMessage: t('notificationUpdateSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(cohortsKeys.getById(merchantId, cohort.id));
      queryClient.invalidateQueries(cohortsKeys.list(merchantId));
    },
  });

  return (
    <BreadCrumb
      title={cohort.name}
      handleTitleChange={(name: string) => updateCohort({name})}
      editableTextPrefix={<CohortBadgesVisuals badges={cohort.badges} imgSize={32} />}
      validator={(name: string) => PatchCohortFormSchema.safeParse({name}).success}
      badges={[<CohortTypeBadge key="type-badge" type={cohort.type} size="small" />]}
    >
      <div className="ml-4 flex items-center gap-4">
        <DeleteCohort cohort={cohort} />
      </div>
    </BreadCrumb>
  );
};

export default CohortPageBreadcrumb;
