import Button from '@cohort/merchants/components/buttons/Button';
import type {PreConnectionConfigComponentProps} from '@cohort/merchants/components/connections/OAuthEditConnectionComponent';
import RadioCards from '@cohort/merchants/components/form/RadioCards';
import {SalesforceEnvironmentSchema} from '@cohort/shared/apps/salesforce/connector';
import {zodResolver} from '@hookform/resolvers/zod';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

const SalesforceConnectionSchema = z.object({
  environment: SalesforceEnvironmentSchema,
});
type SalesforceConnectionType = z.infer<typeof SalesforceConnectionSchema>;

const SalesforceEnvironmentPicker: React.FC<PreConnectionConfigComponentProps> = ({
  onConfigFinished,
}) => {
  const {t} = useTranslation('app-salesforce', {
    keyPrefix: 'salesforceEnvironmentPicker',
  });

  const {register, handleSubmit, control} = useForm<SalesforceConnectionType>({
    resolver: zodResolver(SalesforceConnectionSchema),
    defaultValues: {
      environment: 'production',
    },
  });

  return (
    <form
      onSubmit={e => {
        e.stopPropagation();
        handleSubmit(onConfigFinished)(e);
      }}
      className="w-full space-y-6"
      data-testid="start-oauth2-form"
      id="start-oauth2-form"
    >
      <RadioCards
        name="environment"
        alignment="items-start"
        direction="row"
        label={t('labelEnvironment')}
        register={register}
        control={control}
        options={[
          {
            label: t('labelProduction'),
            value: 'production',
          },
          {
            label: t('labelSandbox'),
            value: 'sandbox',
          },
        ]}
      />
      <Button data-testid="start-oauth2-submit-btn" type="submit" form="start-oauth2-form">
        {t('buttonSubmit')}
      </Button>
    </form>
  );
};

export default SalesforceEnvironmentPicker;
