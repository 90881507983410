type RankBadgeProps = {
  rank: number;
};

const RankBadge: React.FC<RankBadgeProps> = ({rank}) => (
  <div className="flex h-[30px] w-[30px] rounded-md bg-slate-100">
    <div className="m-auto text-xs font-semibold text-slate-500">{rank}</div>
  </div>
);

export default RankBadge;
