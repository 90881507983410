import AsidePageMenu from '@cohort/merchants/components/AsidePageMenu';
import {getDigitalAssetsRoute, getPerksRoute} from '@cohort/merchants/lib/Pages';
import {Gift, ImagesSquare} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

const RewardsMenu = (): JSX.Element => {
  const {t} = useTranslation('pages', {keyPrefix: 'rewards.rewardsMenu'});

  return (
    <AsidePageMenu
      entries={[
        {
          name: t('labelPerks'),
          path: getPerksRoute().path,
          icon: <Gift size={20} />,
          testId: 'perks-link',
        },
        {
          name: t('labelDigitalAssets'),
          path: getDigitalAssetsRoute().path,
          icon: <ImagesSquare size={20} />,
          testId: 'digital-assets-link',
        },
      ]}
    />
  );
};

export default RewardsMenu;
