import {PerkIntegrationIdSchema} from '@cohort/shared/apps';
import {
  asArray,
  DateSchema,
  LocalizedRichTextSchema,
  LocalizedStringSchema,
  MetadataSchema,
  PositiveIntegerSchema,
} from '@cohort/shared/schema/common';
import {PdfMimeTypeSchema, VideoMimeTypeSchema} from '@cohort/shared/schema/common/assets';
import {PaginationParamsSchema} from '@cohort/shared/schema/common/pagination';
import {
  PerkStatusSchema,
  PerkTypeSchema,
  PrivateContentPerkContentTypeSchema,
} from '@cohort/shared/schema/common/perks';
import {z} from 'zod';

export const PrivateContentAllowedMimeTypesASchema = z.enum([
  ...VideoMimeTypeSchema.options,
  ...PdfMimeTypeSchema.options,
]);
export type PrivateContentAllowedMimeTypesADto = z.infer<
  typeof PrivateContentAllowedMimeTypesASchema
>;

const PerkDisplayNameASchema = LocalizedStringSchema.describe('The name of the perk');
const PerkInternalNameASchema = z.string().min(3).describe('The internal name of the perk');
const PerkDescriptionASchema = LocalizedRichTextSchema.describe('The description of the perk');
export const PerkMaxUsagesPerTokenASchema = z
  .number()
  .min(0)
  .describe('The maximum number of times a token can be used');
const PerkCampaignIdsASchema = z
  .array(z.string().uuid())
  .describe('The campaigns the perk is available in');

export const RaffleASchema = z.object({
  numWinners: z.number().min(0),
});
export type RaffleADto = z.infer<typeof RaffleASchema>;

export const PerkIntegrationASchema = z.object({
  perkIntegrationId: PerkIntegrationIdSchema,
  connectionId: z.string().uuid().nullable(),
  config: z.unknown(),
});
export type PerkIntegrationADto = z.infer<typeof PerkIntegrationASchema>;

export const PerkASchema = z.object({
  id: z.string().uuid(),
  createdAt: DateSchema,
  updatedAt: DateSchema,
  displayName: PerkDisplayNameASchema,
  internalName: PerkInternalNameASchema,
  status: PerkStatusSchema,
  description: PerkDescriptionASchema,
  bannerFileKey: z.string().nullable(),
  videoFileKey: z.string().nullable(),
  thumbnailFileKey: z.string().nullable(),
  raffle: RaffleASchema.nullable(),
  privateContent: LocalizedRichTextSchema,
  privateContentFileKey: z.string().nullable(),
  privateContentThumbnailFileKey: z.string().nullable(),
  privateContentFileType: PrivateContentPerkContentTypeSchema.nullable(),
  type: PerkTypeSchema,
  integration: PerkIntegrationASchema.nullable(),
  maxUsagesPerToken: PerkMaxUsagesPerTokenASchema.nullable(),
  maxAccessesPerUser: PositiveIntegerSchema.nullable(),
  metadata: MetadataSchema,
});
export type PerkADto = z.infer<typeof PerkASchema>;

export const PerkAnalyticsASchema = PerkASchema.extend({
  perkAccessesCount: z.number().int().min(0),
  perkAccessesWithUsageCount: z.number().int().min(0),
  campaignsCount: z.number().int().min(0),
});
export type PerkAnalyticsADto = z.infer<typeof PerkAnalyticsASchema>;

export const CreatePerkRequestASchema = z.object({
  displayName: PerkDisplayNameASchema.optional(),
  internalName: PerkInternalNameASchema,
  description: PerkDescriptionASchema.optional(),
  bannerFileKey: z.string().nullable().optional(),
  videoFileKey: z.string().nullable().optional(),
  thumbnailFileKey: z.string().nullable().optional(),
  raffle: RaffleASchema.nullable().optional(),
  privateContent: LocalizedRichTextSchema.optional(),
  privateContentFileKey: z.string().nullable().optional(),
  privateContentThumbnailFileKey: z.string().nullable().optional(),
  privateContentFileType: PrivateContentPerkContentTypeSchema.nullable().optional(),
  type: PerkTypeSchema,
  integration: PerkIntegrationASchema.nullable().optional(),
  maxUsagesPerToken: PerkMaxUsagesPerTokenASchema.nullable().optional(),
  maxAccessesPerUser: PositiveIntegerSchema.nullable().optional(),
  metadata: MetadataSchema.optional(),
});
export type CreatePerkRequestADto = z.infer<typeof CreatePerkRequestASchema>;

export const PatchPerkRequestASchema = z
  .object({
    displayName: PerkDisplayNameASchema,
    internalName: PerkInternalNameASchema,
    description: PerkDescriptionASchema,
    bannerFileKey: z.string().nullable(),
    videoFileKey: z.string().nullable(),
    thumbnailFileKey: z.string().nullable(),
    raffle: RaffleASchema.nullable(),
    integration: PerkIntegrationASchema.nullable(),
    privateContent: LocalizedRichTextSchema,
    privateContentFileKey: z.string().nullable(),
    privateContentThumbnailFileKey: z.string().nullable(),
    privateContentFileType: PrivateContentPerkContentTypeSchema.nullable(),
    maxUsagesPerToken: PerkMaxUsagesPerTokenASchema.nullable(),
    maxAccessesPerUser: PositiveIntegerSchema.nullable(),
    metadata: MetadataSchema,
    campaignIds: PerkCampaignIdsASchema,
  })
  .partial();

export type PatchPerkRequestADto = z.infer<typeof PatchPerkRequestASchema>;

export const PerksOrderByASchema = z.enum([
  'internalName',
  '-internalName',
  'createdAt',
  '-createdAt',
  'updatedAt',
  '-updatedAt',
  'type',
  '-type',
]);

export type PerksOrderByADto = z.infer<typeof PerksOrderByASchema>;

const PerkUsageStatusSchema = z.enum(['used', 'unused']);

export const GetPerksParamsASchema = PaginationParamsSchema.extend({
  perkId: z.preprocess(asArray, z.array(z.string().uuid())).optional(),
  orderBy: PerksOrderByASchema.default('-updatedAt'),
  status: z.preprocess(asArray, z.array(PerkStatusSchema)).optional(),
  integration: z.preprocess(asArray, z.array(PerkIntegrationIdSchema)).optional(),
  type: z.preprocess(asArray, z.array(PerkTypeSchema)).optional(),
  usageStatus: PerkUsageStatusSchema.optional(),
});
export type GetPerksParamsADto = z.infer<typeof GetPerksParamsASchema>;
