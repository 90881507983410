import type {OrganizationADto, SsoProviderADto} from '@cohort/admin-schemas/organization';
import Button from '@cohort/merchants/components/buttons/Button';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {loginWithSsoProvider, ORG_PICKER_TENANT_ID} from '@cohort/merchants/lib/Firebase';
import {trackSigninError, trackSigninSuccess} from '@cohort/merchants/lib/Tracking';
import EmailPasswordForm from '@cohort/merchants/pages/sign-in/EmailPasswordForm';
import MagicLinkForm from '@cohort/merchants/pages/sign-in/MagicLinkForm';
import OrganizationLogo from '@cohort/merchants/pages/sign-in/OrganizationLogo';
import {notifyError} from '@cohort/merchants/stores/ErrorModalStore';
import {SSO_GOOGLE_DEFAULT_PROVIDER} from '@cohort/shared/constants';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

type SignInFormProps = {
  organization: OrganizationADto | null;
  onUserLoggedIn: () => void;
};

const SignInForm: React.FC<SignInFormProps> = ({organization, onUserLoggedIn}) => {
  const [emailSent, setEmailSent] = useState(false);
  const {t} = useTranslation('pages', {keyPrefix: 'sign-in.signInForm'});

  const emailEnabled = organization === null || organization.adminEmailSigninEnabled;

  const ssoProvider: SsoProviderADto | null = organization
    ? organization.adminSsoProvider
    : SSO_GOOGLE_DEFAULT_PROVIDER;

  const {mutate: handleSsoSignIn} = useCohortMutation({
    mutationFn: async ({tenantId, ssoProvider}: {ssoProvider: SsoProviderADto; tenantId: string}) =>
      loginWithSsoProvider(tenantId, ssoProvider.type, ssoProvider.providerId),
    onSuccess: async () => {
      trackSigninSuccess({signinMethod: 'saml'});
      onUserLoggedIn();
    },
    onError: err => {
      // For some reason err instanceof FirebaseError is not working here
      if (
        typeof err === 'object' &&
        err !== null &&
        'code' in err &&
        typeof err.code === 'string' &&
        [
          'auth/popup-closed-by-user',
          'auth/cancelled-popup-request',
          'auth/internal-error',
        ].includes(err.code)
      ) {
        return;
      }
      trackSigninError({signinMethod: 'saml'});
      notifyError(err);
    },
  });

  return (
    <div className="flex flex-col space-y-6">
      {organization !== null && <OrganizationLogo organization={organization} />}

      {!emailSent && (
        <h1 className="text-2xl font-semibold text-slate-900">
          {organization !== null
            ? t('title', {
                organizationName: organization.name,
              })
            : t('titleNoOrg')}
        </h1>
      )}

      <div className="flex flex-col space-y-4">
        {organization === null && (
          <MagicLinkForm emailSent={emailSent} setEmailSent={setEmailSent} />
        )}

        {organization !== null && organization.adminEmailSigninEnabled && (
          <EmailPasswordForm
            onUserLoggedIn={onUserLoggedIn}
            authTenantId={organization.adminAuthTenantId}
          />
        )}

        {!emailSent && emailEnabled && ssoProvider && (
          <div className="relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="bg-white px-2 text-slate-500">{t('dividerOr')}</span>
            </div>
          </div>
        )}

        {!emailSent && ssoProvider && (
          <div>
            <div>
              <Button
                variant="secondary"
                className="h-11 w-full"
                onClick={() => {
                  const tenantId = organization
                    ? organization.adminAuthTenantId
                    : ORG_PICKER_TENANT_ID;
                  handleSsoSignIn({ssoProvider, tenantId});
                }}
              >
                {ssoProvider.logoFileKey && (
                  <img
                    className="mr-3 h-6 w-6"
                    src={getImageUrl(import.meta.env.COHORT_ENV, ssoProvider.logoFileKey, {
                      h: Sizes.XS,
                      w: Sizes.XS,
                      quality: '100',
                    })}
                  />
                )}
                {t('signInWith', {provider: ssoProvider.name})}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignInForm;
