type LabelValueTableProps = {
  data: {label: string; value: string | undefined}[];
};
export const LabelValueTable: React.FC<LabelValueTableProps> = ({data}) => {
  return (
    <div className="rounded-md border border-border bg-slate-50 ">
      {data.map(item => (
        <div className="mx-4 flex flex-row justify-between border-b border-border py-4 last:border-none">
          <p className="text-muted-foreground">{item.label}</p>
          <p className="font-medium">{item.value}</p>
        </div>
      ))}
    </div>
  );
};
