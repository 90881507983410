import {z} from 'zod';

const VideoMimeTypes = ['video/x-m4v', 'video/mp4', 'video/mpeg', 'video/quicktime'] as const;
export const VideoMimeTypeSchema = z.enum(VideoMimeTypes);
export type VideoMimeType = z.infer<typeof VideoMimeTypeSchema>;

const ImageMimeTypes = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/webp'] as const;
export const ImageMimeTypeSchema = z.enum(ImageMimeTypes);
export type ImageMimeType = z.infer<typeof ImageMimeTypeSchema>;

const PdfMimeTypes = ['application/pdf'] as const;
export const PdfMimeTypeSchema = z.enum(PdfMimeTypes);
export type PdfMimeType = z.infer<typeof PdfMimeTypeSchema>;

export const MimeTypeSchema = z.enum([...ImageMimeTypes, ...VideoMimeTypes, ...PdfMimeTypes]);
export type MimeType = z.infer<typeof MimeTypeSchema>;

export const AssetKindSchema = z.enum([
  'badgeVisual',
  'banner',
  'digitalAssetVisual',
  'journeyVisual',
  'storeVisual',
  'logo',
  'membershipPassBanner',
  'perkBanner',
  'perkVideo',
  'privateContent',
  'termsAndConditions',
]);
export type AssetKind = z.infer<typeof AssetKindSchema>;

export const AllowedAssetMimeTypes: Record<AssetKind, z.ZodEnum<[string, ...string[]]>> = {
  badgeVisual: z.enum(ImageMimeTypes),
  banner: z.enum(ImageMimeTypes),
  digitalAssetVisual: z.enum([...ImageMimeTypes, ...VideoMimeTypes]),
  journeyVisual: z.enum([...ImageMimeTypes, ...VideoMimeTypes]),
  storeVisual: z.enum([...ImageMimeTypes, ...VideoMimeTypes]),
  logo: z.enum(ImageMimeTypes),
  membershipPassBanner: z.enum(ImageMimeTypes),
  perkBanner: z.enum(ImageMimeTypes),
  perkVideo: z.enum(VideoMimeTypes),
  privateContent: z.enum([...VideoMimeTypes, ...PdfMimeTypes]),
  termsAndConditions: z.enum(PdfMimeTypes),
} as const;
