import {Check, X} from '@phosphor-icons/react';
import React, {useCallback} from 'react';

const StatusDot: React.FC<{statusIcon?: 'success' | 'error'}> = ({statusIcon}) => {
  if (statusIcon === 'success') {
    return (
      <Check className="absolute left-[-4.5px] top-1.5 z-10 h-5 w-5 rounded-full bg-green-100 p-1 text-green-600" />
    );
  }
  if (statusIcon === 'error') {
    return (
      <X className="absolute left-[-4.5px] top-1.5 z-10 h-5 w-5 rounded-full bg-red-100 p-1 text-red-600" />
    );
  }
  return <div className="absolute left-[-0.5px] top-1.5 z-10 h-3 w-3 rounded-full bg-slate-200" />;
};

type TimelineItemProps = {
  children: React.ReactNode;
  showVerticalLine?: boolean;
  statusIcon?: 'success' | 'error';
};

export const TimelineItem: React.FC<TimelineItemProps> = ({
  children,
  showVerticalLine,
  statusIcon,
}) => {
  const [height, setHeight] = React.useState(0);

  const ref = useCallback(
    (node: HTMLDivElement | null) => {
      if (node === null) {
        return;
      }
      setHeight(node.clientHeight);
    },
    [setHeight]
  );

  return (
    <div className="relative pb-4 last:pb-0" ref={ref}>
      {/* step dot */}
      <StatusDot statusIcon={statusIcon} />
      {/* step vertical line */}
      {showVerticalLine && (
        <div className="absolute left-[5px] top-1.5 w-[1px] bg-slate-200" style={{height}} />
      )}
      <div className="ml-6 flex w-full items-center">{children}</div>
    </div>
  );
};

type TimelineProps = {
  timelineItems: TimelineItemProps[];
};

export const Timeline: React.FC<TimelineProps> = ({timelineItems}) => (
  <div className="flex flex-col">
    {timelineItems.map((item, index) => {
      const isLastItem = index === timelineItems.length - 1;
      // eslint-disable-next-line react/no-array-index-key
      return <TimelineItem key={index} showVerticalLine={!isLastItem} {...item} />;
    })}
  </div>
);

export default Timeline;
