import {CustomComponentTypeSchema} from '@cohort/shared/schema/common/customComponents';
import {z} from 'zod';

export const CustomComponentASchema = z.object({
  id: z.string().uuid(),
  type: CustomComponentTypeSchema,
  enabled: z.boolean(),
  template: z.string().nullable(),
});

export const CustomComponentInputASchema = CustomComponentASchema.omit({id: true});

export type CustomComponentADto = z.infer<typeof CustomComponentASchema>;
export type CustomComponentInputADto = z.infer<typeof CustomComponentInputASchema>;

export const CustomComponentListASchema = z.array(CustomComponentASchema);
export type CustomComponentListADto = z.infer<typeof CustomComponentListASchema>;
