import {resourceToUserEvents} from '@cohort/merchants/apps';
import AdminPage from '@cohort/merchants/components/AdminPage';
import NotificationsSettingsList from '@cohort/merchants/components/notifications/NotificationsSettingsList';
import {useCurrentPerk} from '@cohort/merchants/hooks/contexts/currentPerk';
import {useTranslation} from 'react-i18next';

const PerkNotificationsPage: React.FC = () => {
  const {t} = useTranslation('pages', {keyPrefix: 'perks.perk.notifications.page'});
  const perk = useCurrentPerk();

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
  };

  const perkUserEvents = resourceToUserEvents['perk'];
  const perkUserEventsIds = perkUserEvents.map(userEvent => userEvent.spec.id);

  return (
    <AdminPage header={headerConfig}>
      <p className="font-semibold">{t('whenAUserHas')}</p>
      <div className="ml-4">
        <NotificationsSettingsList
          userEventIds={perkUserEventsIds}
          resource={{
            type: 'perk',
            id: perk.id,
          }}
        />
      </div>
    </AdminPage>
  );
};

export default PerkNotificationsPage;
