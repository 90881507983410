import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {notify} from '@cohort/merchants/hooks/toast';
import {requestChangePassword} from '@cohort/merchants/lib/api/Auth';
import {logout} from '@cohort/merchants/lib/Firebase';
import {languageToLocale, timeJsLocale} from '@cohort/merchants/lib/Utils';
import {Popover, PopoverContent, PopoverTrigger} from '@cohort/shared-frontend/components/Popover';
import {Tabs, TabsList, TabsTrigger} from '@cohort/shared-frontend/components/Tabs';
import {useTurnstile} from '@cohort/shared-frontend/hooks/useTurnstile';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Key, SignOut, User} from '@phosphor-icons/react';
import dayjs from 'dayjs';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {shallow} from 'zustand/shallow';

const NavbarUserPopUp: React.FC = () => {
  const {t, i18n} = useTranslation('components', {keyPrefix: 'navBar.navbarUserPopUp'});
  const currentLanguage = i18n.language;
  const {token, resetToken} = useTurnstile();

  const {organizationMember} = useUserSessionStore(
    store => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      organizationMember: store.organizationMember!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      organization: store.organization!,
    }),
    shallow
  );

  const {mutate: handleResetPassword} = useCohortMutation({
    mutationFn: async () => {
      if (!token) {
        return;
      }
      const res = await requestChangePassword(organizationMember.email, token);
      return res;
    },
    onSuccess: async () => {
      resetToken?.();
      notify('success', t('titleToast'), {
        description: t('textToast'),
      });
    },
  });

  async function handleSignOutClick(): Promise<void> {
    await logout();
  }

  const changeLanguage = (language: string): void => {
    const locale = languageToLocale(language);
    dayjs.locale(timeJsLocale(locale));
    i18n.changeLanguage(language);
  };

  const options = [
    {
      value: 'en',
      label: (
        <div className="flex flex-row space-x-2">
          <span>🇬🇧</span>
          <span>EN</span>
        </div>
      ),
    },
    {
      value: 'fr',
      label: (
        <div className="flex flex-row space-x-2">
          <span>🇫🇷</span>
          <span>FR</span>
        </div>
      ),
    },
  ];

  return (
    <Popover>
      <PopoverTrigger>
        <User size={24} className="text-slate-500 hover:text-slate-700" />
      </PopoverTrigger>
      <PopoverContent className="w-60 text-sm *:w-full">
        <div className="border-b border-border p-4">
          <p className="text-slate-500">{t('labelSignedInAs')}</p>
          <p className="truncate text-sm font-medium text-slate-700">{organizationMember.email}</p>
        </div>
        <div className="border-b border-border py-2 text-sm  *:flex *:w-full *:items-center *:px-4 *:py-2 *:text-slate-700">
          <button
            onClick={() => handleResetPassword()}
            className="cursor-pointer text-left hover:bg-slate-50"
          >
            <Key className="mr-3 h-5 w-5 text-slate-400" aria-hidden="true" />
            <p className="text-slate-700">{t('labelChangePassword')}</p>
          </button>
          <button onClick={handleSignOutClick} className="cursor-pointer hover:bg-slate-50">
            <SignOut className="mr-3 h-5 w-5 font-normal text-slate-400" aria-hidden="true" />
            <p className="text-slate-700"> {t('labelSignOut')}</p>
          </button>
        </div>
        <div className="flex flex-col space-y-2 p-4">
          <label className="font-medium text-slate-700">{t('labelLanguage')}</label>
          <Tabs defaultValue={currentLanguage} onValueChange={val => changeLanguage(val)}>
            <TabsList className="grid w-full grid-cols-2">
              {options.map(option => (
                <TabsTrigger key={option.value} value={option.value}>
                  <div
                    className={cn(
                      currentLanguage === option.value ? 'text-slate-900' : 'text-slate-600'
                    )}
                    key={option.value}
                  >
                    {option.label}
                  </div>
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default NavbarUserPopUp;
