import {createDataStore} from '@cohort/merchants/lib/DataStore';
import {defaultErrorMessage} from '@cohort/shared/models';

export interface ModalState {
  shown: boolean;
  children?: JSX.Element | string;
}

const modalStateStore = createDataStore<ModalState>({shown: false});

export const setModalState = modalStateStore.setData;
export const useModalState = modalStateStore.useData;

export const notifyError = (error: unknown, message?: string): void => {
  if (message !== undefined) {
    showModal(message);
    return;
  }
  if (error instanceof Error) {
    showModal(error.message);
  } else {
    showModal(defaultErrorMessage);
  }
};

export const showModal = (children: JSX.Element | string): void => {
  const newState: ModalState = {children, shown: true};
  setModalState({...newState, shown: true});
};

export const hideModal = (children?: JSX.Element | string): void => {
  setModalState({shown: false, children});
};
