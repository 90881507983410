import SvgIcon from '@cohort/merchants/components/Svg';
import SignInForm from '@cohort/merchants/pages/sign-in/SignInForm';
import React from 'react';

type FirstSignInProps = {
  onUserLoggedIn: () => void;
};
const FirstSignIn: React.FC<FirstSignInProps> = ({onUserLoggedIn}) => (
  <div className="flex min-h-screen">
    <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div className="mx-auto flex w-full max-w-sm flex-col lg:w-96">
        <SignInForm organization={null} onUserLoggedIn={onUserLoggedIn} />
      </div>
    </div>
    <div className="relative hidden w-0 flex-1 lg:block">
      <div className="flex h-screen flex-row items-center justify-center">
        <SvgIcon name="SignInBg" className="absolute -z-10 min-h-full min-w-full" />
        <SvgIcon name="CohortLogo" height={50} />
      </div>
    </div>
  </div>
);
export default FirstSignIn;
