import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@cohort/shared-frontend/components/Accordion';
import {useTranslation} from 'react-i18next';

type AdvancedOptionsProps = {
  accordionItemValue: string;
  accordionDefaultValue?: string;
  children: React.ReactNode;
};

const AdvancedOptions: React.FC<AdvancedOptionsProps> = ({
  accordionDefaultValue,
  accordionItemValue,
  children,
}) => {
  const {t} = useTranslation('components', {keyPrefix: 'form.advancedOptions'});

  return (
    <Accordion
      type="single"
      collapsible
      className="w-full overflow-auto rounded-xl border"
      defaultValue={accordionDefaultValue}
    >
      <AccordionItem value={accordionItemValue} className="border-0 bg-gray-50">
        <AccordionTrigger className="px-5 text-base font-semibold">
          {t('labelAdvancedOptions')}
        </AccordionTrigger>
        <AccordionContent className="space-y-4 overflow-visible bg-white px-5 py-4">
          {children}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default AdvancedOptions;
