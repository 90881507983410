import AdminPage from '@cohort/merchants/components/AdminPage';
import CopyToClipboard from '@cohort/merchants/components/buttons/CopyToClipboard';
import SectionSeparator from '@cohort/merchants/components/SectionSeparator';
import {useCustomTerms} from '@cohort/merchants/hooks/api/CustomTerms';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {trackBrandSettingsPageViewed} from '@cohort/merchants/lib/Tracking';
import BrandSettingsForm from '@cohort/merchants/pages/settings/brand/BrandSettingsForm';
import CustomTermsForm from '@cohort/merchants/pages/settings/brand/CustomTermsForm';
import XpsAccessForm from '@cohort/merchants/pages/settings/brand/XpsAccessForm';
import {Fragment, useEffect} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

const BrandSettingsPage = (): JSX.Element => {
  const {t} = useTranslation('pages', {keyPrefix: 'settings.brand.page'});

  const merchant = useCurrentMerchant();
  const {data: customTerms} = useCustomTerms(merchant.id);

  useEffect(() => trackBrandSettingsPageViewed(), []);

  const headerConfig = {
    title: (
      <Link target="_blank" to={merchant.spaceUrl}>
        {t('title')}
      </Link>
    ),
    subtitle: t('subtitle'),
  };

  return (
    <AdminPage header={headerConfig}>
      <div className="flex items-center gap-2">
        <p>
          <Trans
            i18nKey="settings.brand.page.xpsUrl"
            ns="pages"
            values={{
              xpsUrl: merchant.spaceUrl,
            }}
            components={{
              xpsLink: (
                <a href={merchant.spaceUrl} className="font-medium underline" target="_blank" />
              ),
            }}
          />
        </p>
        <CopyToClipboard target={merchant.spaceUrl} />
      </div>
      <SectionSeparator />
      <BrandSettingsForm />
      <SectionSeparator />
      <XpsAccessForm />
      {customTerms !== undefined && (
        <Fragment>
          <SectionSeparator />
          <CustomTermsForm customTerms={customTerms} />
        </Fragment>
      )}
    </AdminPage>
  );
};

export default BrandSettingsPage;
