import Button from '@cohort/merchants/components/buttons/Button';
import type {PreConnectionConfigComponentProps} from '@cohort/merchants/components/connections/OAuthEditConnectionComponent';
import Input from '@cohort/merchants/components/form/input/Input';
import {ShopifyShopIdSchema} from '@cohort/shared/apps/shopify/merchantConnector';
import {zodResolver} from '@hookform/resolvers/zod';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

const SHOPIFY_SHOP_DOMAIN = '.myshopify.com';

const ShopifyConnectionSchema = z.object({
  shopId: z.preprocess(arg => `${arg}${SHOPIFY_SHOP_DOMAIN}`, ShopifyShopIdSchema),
});
type ShopifyConnectionType = z.infer<typeof ShopifyConnectionSchema>;

const ShopIdInputAddon: React.FC = () => (
  <div className="pointer-events-none absolute inset-y-0 right-0 my-px mr-px flex items-center rounded-br-md rounded-tr-md border-l bg-slate-50 px-3 text-sm text-slate-500">
    {SHOPIFY_SHOP_DOMAIN}
  </div>
);

const ShopifyShopPicker: React.FC<PreConnectionConfigComponentProps> = ({onConfigFinished}) => {
  const {t} = useTranslation('app-shopify', {
    keyPrefix: 'editConnection',
  });
  const {register, handleSubmit, control} = useForm<ShopifyConnectionType>({
    resolver: zodResolver(ShopifyConnectionSchema),
  });

  return (
    <form
      onSubmit={e => {
        e.stopPropagation();
        handleSubmit(onConfigFinished)(e);
      }}
      className="space-y-6 p-6"
      data-testid="start-oauth2-form"
      id="start-oauth2-form"
    >
      <Input
        insetPrefix={{
          component: <ShopIdInputAddon />,
          size: 5,
        }}
        type="text"
        name="shopId"
        label={t('labelShopId')}
        register={register}
        control={control}
        description={t('descriptionShopId')}
      />
      <Button data-testid="start-oauth2-submit-btn" type="submit" form="start-oauth2-form">
        {t('buttonSubmit')}
      </Button>
    </form>
  );
};

export default ShopifyShopPicker;
