import {
  DateSchema,
  LocalizedRichTextSchema,
  LocalizedStringSchema,
} from '@cohort/shared/schema/common';
import {
  BadgeInternalNameSchema,
  BadgeStatusSchema,
  BadgeVisibilitySchema,
} from '@cohort/shared/schema/common/badges';
import {PaginationParamsSchema} from '@cohort/shared/schema/common/pagination';
import {z} from 'zod';

export const BadgeASchema = z.object({
  id: z.string().uuid(),
  merchantId: z.string().uuid(),
  createdAt: DateSchema,
  updatedAt: DateSchema,
  cohortId: z.string().uuid().nullable(),
  name: LocalizedStringSchema,
  internalName: BadgeInternalNameSchema,
  description: LocalizedRichTextSchema,
  rank: z.number(),
  status: BadgeStatusSchema,
  imageFileKey: z.string().nullable(),
  visibility: BadgeVisibilitySchema,
  lockedDescription: LocalizedRichTextSchema,
});
export type BadgeADto = z.infer<typeof BadgeASchema>;

export const CreateBadgeASchema = z.object({
  internalName: z.string(),
});
export type CreateBadgeADto = z.infer<typeof CreateBadgeASchema>;

export const PatchBadgeASchema = z
  .object({
    internalName: z.string(),
    name: LocalizedStringSchema.or(z.record(z.never())),
    description: LocalizedRichTextSchema.or(z.record(z.never())),
    status: BadgeStatusSchema,
    imageFileKey: z.string().nullable(),
    cohortId: z.string().uuid().nullable(),
    visibility: BadgeVisibilitySchema,
    lockedDescription: LocalizedRichTextSchema.or(z.record(z.never())),
  })
  .partial();
export type PatchBadgeADto = z.infer<typeof PatchBadgeASchema>;

export const ReorderBadgesASchema = z.array(z.string().uuid());
export type ReorderBadgesADto = z.infer<typeof ReorderBadgesASchema>;

export const PatchBadgesAResponseSchema = z.array(BadgeASchema);

export const GetBadgesParamsASchema = PaginationParamsSchema;
export type GetBadgesParamsADto = z.infer<typeof GetBadgesParamsASchema>;
