import type {PerkCardProps} from '@cohort/components-xps/components/cards/PerkCard';
import PerkCard from '@cohort/components-xps/components/cards/PerkCard';
import {useCustomComponents} from '@cohort/merchants/hooks/api/CustomComponents';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCurrentPerk} from '@cohort/merchants/hooks/contexts/currentPerk';
import usePreviewStyle from '@cohort/merchants/hooks/previewStyle';
import {usePerkUtils} from '@cohort/merchants/hooks/usePerkUtils';
import {getCustomComponent, getUserContext} from '@cohort/merchants/lib/CustomComponents';
import {
  trackPerkPreviewModalClosed,
  trackPerkPreviewModalOpened,
} from '@cohort/merchants/lib/Tracking';
import PerkPreviewModal from '@cohort/merchants/pages/perks/perk/edit/PerkPreviewModal';
import type {PerkFormValues} from '@cohort/merchants/pages/perks/perk/edit/utils';
import {DEFAULT_ACCENT_COLOR, DEFAULT_BACKGROUND_COLOR} from '@cohort/shared/schema/common';
import {getVideoUrl, Sizes} from '@cohort/shared/utils/media';
import {makePerkContext} from '@cohort/shared/utils/templating';
import {isFile} from '@cohort/shared-frontend/utils/isFile';
import {ArrowsOut} from '@phosphor-icons/react';
import {Fragment, useState} from 'react';
import type {Control} from 'react-hook-form';
import {useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type PerkPreviewAsideProps = {
  control: Control<PerkFormValues>;
};

const PerkPreviewAside: React.FC<PerkPreviewAsideProps> = ({control}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'perks.perk.edit.perkPreviewAside'});
  const perk = useCurrentPerk();
  const merchant = useCurrentMerchant();
  const {getPerkTypeData} = usePerkUtils();
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [displayName, bannerFileKey, videoFileKey, selectedLanguage] = useWatch({
    control,
    name: ['displayName', 'bannerFileKey', 'videoFileKey', 'selectedLanguage'],
  });
  const {data: components} = useCustomComponents(merchant.id);
  const perkCustomComponent = getCustomComponent(components, 'perk', true);
  const userContext = getUserContext(merchant);

  usePreviewStyle(
    merchant.backgroundColorCode ?? DEFAULT_BACKGROUND_COLOR,
    merchant.accentColorCode ?? DEFAULT_ACCENT_COLOR
  );

  const computeVideoFileKey = (): File | string | null => {
    if (videoFileKey && isFile(videoFileKey)) {
      return videoFileKey;
    }

    if (perk.videoFileKey === null) {
      return null;
    }

    return getVideoUrl(import.meta.env.COHORT_ENV, perk.videoFileKey, {
      h: Sizes.M,
      w: Sizes.M,
    });
  };

  // Order of priorities here to compute the thumbnailFileKey:
  // - Custom banner
  // - Custom video thumbnail
  // - Existing PerkBanner
  // We do not show the private content thumbnailFileKey as we want the same aspect than on the store page.
  const computeThumbnailFileKey = (): File | string | null => {
    if (bannerFileKey) {
      return bannerFileKey;
    } else if (videoFileKey && isFile(videoFileKey)) {
      return videoFileKey;
    } else if (perk.bannerFileKey !== null) {
      return perk.bannerFileKey;
    } else if (perk.thumbnailFileKey !== null) {
      return perk.thumbnailFileKey;
    } else {
      return null;
    }
  };

  const perkCard: PerkCardProps['perk'] = {
    bannerFileKey: bannerFileKey ?? null,
    displayName: displayName[selectedLanguage] ?? getPerkTypeData(perk.type).perkTitlePlaceholder,
    thumbnailFileKey: computeThumbnailFileKey(),
    videoFileKey: computeVideoFileKey(),
  };

  const getFileKey = (fileKey: string | null | File): string | null => {
    if (fileKey && isFile(fileKey)) {
      return URL.createObjectURL(fileKey);
    }
    return fileKey;
  };
  const customComponent = perkCustomComponent?.template
    ? {
        template: perkCustomComponent.template,
        context: {
          perk: makePerkContext({
            displayName:
              displayName[selectedLanguage] ?? getPerkTypeData(perk.type).perkTitlePlaceholder,
            bannerFileKey: bannerFileKey ?? null,
            thumbnailFileKey: getFileKey(computeThumbnailFileKey()),
            videoFileKey: getFileKey(computeVideoFileKey()),
            id: perk.id,
            description: perk.description[selectedLanguage] ?? '',
            type: perk.type,
            integration: perk.integration,
          }),
          user: userContext,
          lang: selectedLanguage,
          isMobile: false,
        },
      }
    : undefined;

  return (
    <Fragment>
      <div className="hidden w-[var(--preview-width)] gap-4 [grid-template-rows:min-content_1fr] lg:grid">
        <button
          data-testid="open-perk-preview"
          className="flex cursor-pointer items-center justify-center text-slate-500"
          onClick={() => {
            setPreviewModalOpen(true);
            trackPerkPreviewModalOpened(perk);
          }}
        >
          <ArrowsOut className="mr-1 h-4 w-4" />
          <span className="text-sm font-medium">{t('labelPreviewOpen')}</span>
        </button>
        <div id="preview" className="flex h-full items-center justify-center rounded-xl p-4">
          <div className="w-[--xps-perk-card-width] max-w-[var(--preview-width)]">
            <PerkCard
              perk={perkCard}
              availableInstances={1}
              canBeUsed
              customComponent={customComponent}
            />
          </div>
        </div>
        {previewModalOpen && (
          <PerkPreviewModal
            perk={perkCard}
            availableInstances={1}
            canBeUsed
            handleOnClose={() => {
              setPreviewModalOpen(false);
              trackPerkPreviewModalClosed(perk);
            }}
            customComponent={customComponent}
          />
        )}
      </div>
    </Fragment>
  );
};

export default PerkPreviewAside;
