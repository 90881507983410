// i18nOwl-ignore [app-shopify.description]
import type {App} from '@cohort/merchants/apps';
import {makeOauthEditConnectionComponent} from '@cohort/merchants/apps/appUtils';
import translationEn from '@cohort/merchants/apps/shopify/locales/en.json';
import translationFr from '@cohort/merchants/apps/shopify/locales/fr.json';
import {ShopifyDiscountPerkIntegration} from '@cohort/merchants/apps/shopify/perkIntegrations/discount/PerkIntegration';
import ShopifyShopPicker from '@cohort/merchants/apps/shopify/ShopifyShopPicker';
import type {ShopifyAppStruct} from '@cohort/shared/apps/shopify';
import {ShopifyAppSpec} from '@cohort/shared/apps/shopify';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

const ShopifyApp: App<ShopifyAppStruct> = {
  spec: ShopifyAppSpec,
  ConnectionEditComponent: makeOauthEditConnectionComponent('shopify', {
    preConnectionConfigComponent: ShopifyShopPicker,
  }),
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="shopify" height={40} width={40} />,
  notificationIntegrations: [],
  perkIntegrations: [ShopifyDiscountPerkIntegration],
  triggerIntegrations: [],
  userEvents: [],
};

export default ShopifyApp;
