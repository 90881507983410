import Button from '@cohort/merchants/components/buttons/Button';
import Input from '@cohort/merchants/components/form/input/Input';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {notify} from '@cohort/merchants/hooks/toast';
import {sendSigninOtp} from '@cohort/merchants/lib/api/Auth';
import {
  trackError,
  trackSigninError,
  trackSigninFormRedirect,
  trackSigninSendLinkSuccess,
} from '@cohort/merchants/lib/Tracking';
import {waitlistFormRedirect} from '@cohort/merchants/lib/Utils';
import {EmailSchema} from '@cohort/shared/schema/common';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import {useTurnstile} from '@cohort/shared-frontend/hooks/useTurnstile';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {zodResolver} from '@hookform/resolvers/zod';
import {CaretLeft} from '@phosphor-icons/react';
import {Fragment, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {z} from 'zod';

const RESEND_CODE_DELAY_SEC = 15;

const MagicLinkFormSchema = z.object({email: EmailSchema});
type MagicLinkForm = z.infer<typeof MagicLinkFormSchema>;

type MagicLinkFormProps = {
  emailSent: boolean;
  setEmailSent: (emailSent: boolean) => void;
};

const MagicLinkForm: React.FC<MagicLinkFormProps> = ({emailSent, setEmailSent}) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const {t} = useTranslation('pages', {keyPrefix: 'sign-in.magicLinkForm'});
  const {token, resetToken} = useTurnstile();

  const {register, control, handleSubmit, watch, formState} = useForm<MagicLinkForm>({
    defaultValues: {
      email: '',
    },
    mode: 'onBlur',
    resolver: zodResolver(MagicLinkFormSchema),
  });

  const {mutate: handleEmailLinkSignIn, isLoading} = useCohortMutation({
    mutationFn: async ({email}: {email: string}) => {
      if (!token) {
        return;
      }
      const res = await sendSigninOtp(email, token);
      return res;
    },
    onSuccess: async () => {
      if (emailSent) {
        notify('success', t('resendSuccess'));
      }
      resetToken?.();
      setTimeLeft(RESEND_CODE_DELAY_SEC);
      setEmailSent(true);
      trackSigninSendLinkSuccess();
    },
    onError: err => {
      if (isCohortError(err, 'organization-member.not-found')) {
        trackSigninFormRedirect({signinMethod: 'emailLink'});
        waitlistFormRedirect();
      } else {
        trackSigninError({signinMethod: 'emailLink'});
        trackError(err);
      }
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const email = watch('email');

  return (
    <form
      className="flex flex-col space-y-4"
      onSubmit={handleSubmit(data => handleEmailLinkSignIn(data))}
    >
      {emailSent && (
        <Fragment>
          <Button
            variant={'link'}
            className={cn('-ml-1 justify-start p-0')}
            onClick={() => setEmailSent(false)}
          >
            <CaretLeft className="h-5 w-5" />
            {t('buttonBack')}
          </Button>
          <h1 className="text-2xl font-semibold">{t('titleEmailSent')}</h1>
          <p className="text-md text-leading-5 text-sm text-slate-500">
            <Trans
              i18nKey="sign-in.magicLinkForm.subtitleEmailSent"
              ns="pages"
              components={{
                bold: <span className="font-semibold" />,
              }}
              values={{email}}
            />
          </p>
        </Fragment>
      )}
      {!emailSent && (
        <Input
          label={t('labelEmail')}
          placeholder="vitalik@getcohort.com"
          type="text"
          name="email"
          register={register}
          control={control}
        />
      )}
      <Button
        type="submit"
        className="h-11 w-full"
        loading={isLoading}
        disabled={!formState.isValid || timeLeft > 0}
        variant={emailSent ? 'secondary' : 'primary'}
      >
        {timeLeft > 0
          ? t('buttonCooldown', {numSeconds: timeLeft})
          : emailSent
            ? t('buttonResend')
            : t('buttonContinue')}
      </Button>
    </form>
  );
};
export default MagicLinkForm;
