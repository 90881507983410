import type {BadgeADto, PatchBadgeADto} from '@cohort/admin-schemas/badge';
import {
  isDefaultLanguageTranslationConsistent,
  isDefaultTranslationDefined,
  removeUndefinedLanguages,
} from '@cohort/merchants/lib/form/localization';
import {fileValidatorOptional} from '@cohort/merchants/lib/form/validators';
import {
  LanguageSchema,
  LocalizedRichTextSchema,
  LocalizedStringSchema,
} from '@cohort/shared/schema/common';
import {BadgeVisibilitySchema} from '@cohort/shared/schema/common/badges';
import type {UseMutateAsyncFunction} from '@tanstack/react-query';
import {isDefined} from 'remeda';
import {z} from 'zod';

//
// Badge Appearance Step Schemas
//
const BaseBadgeAppearanceStepSchema = z.object({
  name: LocalizedStringSchema,
  description: LocalizedRichTextSchema,
  imageFileKey: z.any().refine(fileValidatorOptional),
});
export const BadgeAppearanceStepSchema = BaseBadgeAppearanceStepSchema.extend({
  defaultLanguage: LanguageSchema.optional(),
  selectedLanguage: LanguageSchema.optional(),
  definedLanguages: z.array(LanguageSchema).optional(),
});
type BadgeAppearanceStep = z.infer<typeof BadgeAppearanceStepSchema>;

// Refinement used for the Save for later btn.
export const SaveBadgeAppearanceForLaterRefinement = (
  request: Partial<BadgeAppearanceStep>,
  ctx: z.RefinementCtx
): {message: string; path: string[]} | boolean => {
  const {name, description, definedLanguages, defaultLanguage} = request;

  if (!defaultLanguage || !definedLanguages) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'errorRequired',
      path: ['badge'],
    });
    return false;
  }

  // If the data in default language is not defined but it is defined in another language, we need to throw an error.
  if (definedLanguages.length < 2) {
    return true;
  } else {
    if (!isDefaultLanguageTranslationConsistent(name ?? {}, defaultLanguage)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorTooShort3',
        path: ['name'],
      });
      return false;
    }

    if (!isDefaultLanguageTranslationConsistent(description ?? {}, defaultLanguage)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorTooShort3',
        path: ['description'],
      });
      return false;
    }
  }

  return true;
};

// Refinement used for the Continue btn.
const RefinedBadgeAppearanceStepSchema = BadgeAppearanceStepSchema.superRefine(
  ({name, description, defaultLanguage, imageFileKey}, ctx) => {
    if (defaultLanguage === undefined || !isDefaultTranslationDefined(name, defaultLanguage)) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorTooShort3',
        path: ['name'],
      });
    }

    if (!isDefaultTranslationDefined(description, defaultLanguage)) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorTooShort3',
        path: ['description'],
      });
    }

    if (!isDefined(imageFileKey)) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorRequired',
        path: ['imageFileKey'],
      });
    }
  }
);

export const TransformedBadgeAppearanceStepSchema = RefinedBadgeAppearanceStepSchema.transform(
  data => {
    data.description = removeUndefinedLanguages(data.description, data.definedLanguages);
    data.name = removeUndefinedLanguages(data.name, data.definedLanguages);
    return data;
  }
);

//
// Assigned Cohort Step Schemas
//
export const BadgeCohortStepSchema = z.object({
  cohortId: z.string().nullable(),
});
export type BadgeCohortStepValues = z.infer<typeof BadgeCohortStepSchema>;

export const SaveBadgeCohortForLaterRefinement = (
  request: Partial<BadgeCohortStepValues>,
  ctx: z.RefinementCtx
): {message: string; path: string[]} | boolean => {
  if (request.cohortId === undefined) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'errorRequired',
      path: ['cohortId'],
    });
    return false;
  }
  return true;
};

//
// Visibility Step Schemas
//
export const BadgeVisibilityStepSchema = z.object({
  descriptionType: z.enum(['badge', 'custom']),
  visibility: BadgeVisibilitySchema.optional(),
  description: LocalizedRichTextSchema,
  lockedDescription: LocalizedRichTextSchema,
  defaultLanguage: LanguageSchema.optional(),
  selectedLanguage: LanguageSchema.optional(),
  definedLanguages: z.array(LanguageSchema).optional(),
});
type BadgeVisibilityStep = z.infer<typeof BadgeVisibilityStepSchema>;

export const SaveBadgeVisibilityForLaterRefinement = (
  request: Partial<BadgeVisibilityStep>,
  ctx: z.RefinementCtx
): {message: string; path: string[]} | boolean => {
  if (!request.visibility) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'errorRequired',
      path: ['visibility'],
    });
    return false;
  }

  return true;
};

const RefinedBadgeVisibilityStepSchema = BadgeVisibilityStepSchema.superRefine(
  ({visibility, lockedDescription, defaultLanguage, descriptionType}, ctx) => {
    if (defaultLanguage === undefined) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorRequired',
        path: ['defaultLanguage'],
      });
    }

    if (visibility === 'cohort') {
      if (isDefaultTranslationDefined(lockedDescription, defaultLanguage)) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'errorValue',
          path: ['lockedDescription'],
        });
      }
    } else {
      if (
        descriptionType === 'badge' &&
        isDefaultTranslationDefined(lockedDescription, defaultLanguage)
      ) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'errorValue',
          path: ['lockedDescription'],
        });
      }

      if (
        descriptionType === 'custom' &&
        !isDefaultTranslationDefined(lockedDescription, defaultLanguage)
      ) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'errorValue',
          path: ['lockedDescription'],
        });
      }
    }
  }
);

export const TransformedBadgeVisibilityStepSchema = RefinedBadgeVisibilityStepSchema.transform(
  data => {
    data.lockedDescription = removeUndefinedLanguages(
      data.lockedDescription,
      data.definedLanguages
    );
    return data;
  }
);

// Badge Form Steps
export const BadgeFormSteps = ['appearance', 'cohort', 'visibility'] as const;
export type BadgeFormStep = (typeof BadgeFormSteps)[number];

type BadgeFormStepsValidators = Record<
  BadgeFormStep,
  z.ZodObject<z.ZodRawShape> | z.ZodEffects<z.ZodObject<z.ZodRawShape>>
>;

export const badgeFormStepsValidators: BadgeFormStepsValidators = {
  appearance: RefinedBadgeAppearanceStepSchema,
  cohort: z.object({
    cohortId: z.string(),
  }),
  visibility: RefinedBadgeVisibilityStepSchema,
};

export type BadgeFormStepProps = {
  isProcessing: boolean;
  setStepDirty?: (dirty: boolean) => void;
  setStepSaveBtn?: (btn: JSX.Element) => void;
  onStepValidated: () => void;
  updateBadgeMutation: UseMutateAsyncFunction<BadgeADto | null, unknown, PatchBadgeADto, unknown>;
};
