import {cn} from '@cohort/shared-frontend/utils/classNames';

type BadgeProps = {
  text: string | JSX.Element;
  textColor: string;
  dotColor?: string;
  backgroundColor: string;
  borderColor?: string;
  size?: 'large' | 'small';
  className?: string;
};

export const Badge = ({
  text,
  textColor,
  dotColor,
  borderColor,
  backgroundColor,
  size = 'large',
  className,
}: BadgeProps): JSX.Element => (
  <span
    className={cn(
      'inline-flex items-center whitespace-nowrap rounded-md font-medium',
      backgroundColor,
      textColor,
      borderColor,
      borderColor && 'border',
      size === 'large' ? 'px-2 py-0.5 text-sm' : 'px-2 py-0.5 text-xs',
      className
    )}
    data-testid={'badge' + text}
  >
    {dotColor !== undefined && (
      <svg
        className={cn('-ml-1 mr-1.5 h-2 w-2 ', dotColor, size === 'large' ? '-ml-0.5' : '-ml-1')}
        fill="currentColor"
        viewBox="0 0 8 8"
      >
        <circle cx={4} cy={4} r={3} />
      </svg>
    )}
    {text}
  </span>
);
