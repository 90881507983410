import type {CampaignADto} from '@cohort/admin-schemas/campaign';
import {Fragment} from 'react';
import {Trans} from 'react-i18next';

const StoreOptionsSectionContent: React.FC<{campaign: CampaignADto}> = ({campaign}) => {
  if (campaign.totalSupply !== null) {
    return (
      <Fragment>
        <p className="font-medium">
          <Trans
            i18nKey="campaigns.campaign.edit.review.storeOptionsSectionContent.totalSupply"
            ns="pages"
            values={{quantity: campaign.totalSupply}}
            components={{
              quantity: <span className="font-normal text-gray-500" />,
            }}
          />
        </p>
      </Fragment>
    );
  }
  return <Fragment />;
};

export default StoreOptionsSectionContent;
