import {PencilSimple} from '@phosphor-icons/react';

type ReviewSectionProps = {
  title: string;
  content?: JSX.Element;
  children?: React.ReactNode;
  onEditStep: () => void;
};

const ReviewSection: React.FC<ReviewSectionProps> = ({title, content, children, onEditStep}) => (
  <section className="rounded-lg bg-gray-50 p-6">
    <div className="grid items-center [grid-template-columns:200px_1fr_min-content]">
      <h3 className="text-lg font-semibold leading-6">{title}</h3>
      <div>{content}</div>
      <button onClick={onEditStep}>
        <PencilSimple className="h-5 w-5 text-primary" />
      </button>
    </div>
    {children}
  </section>
);

export default ReviewSection;
