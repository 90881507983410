import {Dialog, DialogContent} from '@cohort/merchants/components/modals/Dialog';
import {hideModal, useModalState} from '@cohort/merchants/stores/ErrorModalStore';
import {Warning} from '@phosphor-icons/react';
import type {FC} from 'react';
import {useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

export const ErrorModal: FC = () => {
  const {shown, children} = useModalState();
  const onClose = useCallback(() => hideModal(children), [children]);
  const {t} = useTranslation('components', {keyPrefix: 'errorModal'});

  useEffect(() => {
    const handleKeyDown = (evt: KeyboardEvent): void => {
      if (evt.code === 'Escape' && shown) {
        hideModal();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [shown]);

  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.pointerEvents = shown ? 'none' : 'inherit';
    }
  }, [shown]);

  return (
    <Dialog open={shown} onOpenChange={onClose}>
      <DialogContent displayCloseButton={false}>
        <div className="p-6">
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <Warning className="h-6 w-6 text-red-600" aria-hidden="true" />
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3 className="text-base font-semibold leading-6">{t('title')}</h3>
              <div className="mt-2">
                <p className="text-sm text-slate-500">{children}</p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              data-testid="error-modal-close-button"
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50 sm:mt-0 sm:w-auto"
              onClick={onClose}
            >
              {t('buttonClose')}
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
ErrorModal.displayName = 'ErrorModal';
