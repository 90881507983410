import {Card, CardTitleSecondary} from '@cohort/components-xps/components/cards/Card';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import type {JourneyStepWDto} from '@cohort/wallet-schemas/journeySteps';

type StepsListProps = {
  children: React.ReactElement<StepCardProps> | React.ReactElement<StepCardProps>[];
};

type StepCardProps = {
  step: JourneyStepWDto;
  isCampaignEnded: boolean;
  isMobile: boolean;
  children?: React.ReactNode;
  detailsComponent?: React.ReactNode;
};

const StepCard: React.FC<StepCardProps> = ({
  step,
  children,
  detailsComponent,
  isMobile,
  isCampaignEnded,
}) => {
  const canBeCompleted = !step.isCompleted && !step.availability.locked && !isCampaignEnded;

  return (
    <Card>
      <div className="gap-2 md:flex md:items-center">
        <div
          className={cn('flex items-start pb-1 md:px-2 md:pb-0', !canBeCompleted && 'opacity-50')}
        >
          {step.icon}
        </div>
        <div className={cn('flex-grow', !canBeCompleted && 'opacity-50')}>
          <CardTitleSecondary>{step.title}</CardTitleSecondary>
          <div
            dangerouslySetInnerHTML={{__html: step.description}}
            className="prose !max-w-full break-all text-sm"
          />
          {!isMobile && !!detailsComponent && detailsComponent}
        </div>
        {children && <div>{children}</div>}
        {isMobile && !!detailsComponent && detailsComponent}
      </div>
    </Card>
  );
};

const StepsList: React.FC<StepsListProps> = ({children}) => (
  <ul className="flex list-none flex-col gap-4">{children}</ul>
);

export const JourneySteps = {
  StepsList,
  StepCard,
};
