import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import type {Control, Path, UseFormRegister} from 'react-hook-form';
import {useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type NftSettingsInputForm = {
  nftEnabled: boolean;
  nftNonTransferable?: boolean;
};

type DigitalAssetCollectionNftInputProps<T extends NftSettingsInputForm> = {
  digitalAssetNftEnabled?: boolean;
  control: Control<T>;
  register: UseFormRegister<T>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function DigitalAssetCollectionNftInput<T extends NftSettingsInputForm>({
  digitalAssetNftEnabled,
  control,
  register,
}: DigitalAssetCollectionNftInputProps<T>): JSX.Element {
  const {t} = useTranslation('pages', {
    keyPrefix: 'digitalAssetCollections.digitalAssetCollection.digitalAssetCollectionNftInput',
  });
  const nftEnabled = useWatch({control, name: 'nftEnabled' as Path<T>});

  return (
    <div className="flex flex-col gap-6">
      <SwitchInput
        name={'nftEnabled' as Path<T>}
        label={t('labelNftEnabled')}
        labelPosition="right"
        control={control}
        register={register}
        disabled={digitalAssetNftEnabled}
      />
      {nftEnabled && (
        <SwitchInput
          name={'nftNonTransferable' as Path<T>}
          label={t('labelNftNonTransferable')}
          labelPosition="right"
          control={control}
          register={register}
          disabled={digitalAssetNftEnabled}
        />
      )}
    </div>
  );
}
