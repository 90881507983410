import MultipleChoicesFilter from '@cohort/merchants/components/filters/MultipleChoicesFilter';
import SingleChoiceFilter from '@cohort/merchants/components/filters/SingleChoiceFilter';
import {X} from '@phosphor-icons/react';
import React, {Fragment, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

export interface FilterOption {
  value: string;
  label: string;
  active: boolean;
}

type FiltersType = {
  label: string;
  options: FilterOption[];
  type: 'single' | 'multiple';
  icon?: JSX.Element;
  className?: string;
  short?: boolean;
};

type FiltersWithSingle = FiltersType & {
  type: 'single';
  defaultValue: string;
  onChange?: (option: FilterOption) => void;
};

type FiltersWithMultiple = FiltersType & {
  type: 'multiple';
  onChange?: (options: FilterOption[]) => void;
};

interface FiltersProps {
  filters: (FiltersWithSingle | FiltersWithMultiple)[];
}

const Filters: React.FC<FiltersProps> = ({filters}) => {
  const {t} = useTranslation('components', {keyPrefix: 'filters.filters'});

  const handleClearFilters = useCallback(() => {
    for (const filter of filters) {
      filter.options = filter.options.map(option => {
        return {...option, active: false};
      });
      if (filter.type === 'single') {
        filter.onChange?.(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.options.find(option => option.value === filter.defaultValue) ?? filter.options[0]!
        );
      } else {
        filter.onChange?.(filter.options);
      }
    }
  }, [filters]);

  const someFilterActive = useMemo(() => {
    return filters.some(filter => {
      return filter.options.some(option => option.active);
    });
  }, [filters]);

  return (
    <div className="z-20 flex flex-row flex-wrap items-center gap-x-4 gap-y-2">
      {filters.map(filter => {
        if (filter.options.length === 0) {
          return <Fragment key={filter.label} />;
        }
        if (filter.type === 'single') {
          return (
            <SingleChoiceFilter
              key={filter.label}
              prefix={filter.label}
              options={filter.options}
              onChange={(option: FilterOption) => filter.onChange?.(option)}
              border
              className={filter.className}
            />
          );
        }
        return (
          <MultipleChoicesFilter
            key={filter.label}
            label={filter.label}
            options={filter.options}
            onChange={(options: FilterOption[]) => filter.onChange?.(options)}
            icon={filter.icon}
            className={filter.className}
            short={filter.short}
          />
        );
      })}
      {someFilterActive && (
        <Fragment>
          <button
            className="flex cursor-pointer flex-row items-center font-medium text-slate-400 hover:text-slate-500"
            onClick={handleClearFilters}
          >
            <X className="mr-1 h-4 w-4" aria-hidden="true" />
            <h3 className="text-sm">{t('buttonClearFilters')}</h3>
          </button>
        </Fragment>
      )}
    </div>
  );
};

export default Filters;
