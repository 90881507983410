import {JourneyStepASchema} from '@cohort/admin-schemas/journeySteps';
import {TriggerIntegrationIdSchema} from '@cohort/shared/apps';
import {asArray, DateSchema, EmailSchema} from '@cohort/shared/schema/common';
import {JourneyParticipationStatusSchema} from '@cohort/shared/schema/common/journeyParticipations';
import {PaginationParamsSchema} from '@cohort/shared/schema/common/pagination';
import {z} from 'zod';

const StepCompletionAttemptASchema = z.object({
  stepId: z.string().uuid(),
  createdAt: DateSchema,
  success: z.boolean(),
  data: z.unknown().nullable(),
  triggerIntegrationId: TriggerIntegrationIdSchema.nullable(),
  manual: z.boolean(),
  id: z.string().uuid(),
});
export type StepCompletionAttemptADto = z.infer<typeof StepCompletionAttemptASchema>;

export const JourneyParticipationASchema = z.object({
  id: z.string().uuid(),
  createdAt: DateSchema,
  completedAt: DateSchema.nullable(),
  status: JourneyParticipationStatusSchema,
  userEmail: EmailSchema,
  campaign: z.object({
    internalName: z.string(),
    imageFileKey: z.string().nullable(),
    id: z.string().uuid(),
  }),
  journeySteps: z.array(JourneyStepASchema),
  stepCompletionAttempts: z.array(StepCompletionAttemptASchema),
});
export type JourneyParticipationADto = z.infer<typeof JourneyParticipationASchema>;

export const MarkStepCompletedASchema = z.object({
  journeyStepId: z.string().uuid(),
});
export type MarkStepCompletedADto = z.infer<typeof MarkStepCompletedASchema>;

export const JourneyParticipationsOrderBySchema = z.enum([
  'createdAt',
  '-createdAt',
  'completedAt',
  '-completedAt',
]);

export type JourneyParticipationsOrderBy = z.infer<typeof JourneyParticipationsOrderBySchema>;

export const JourneyParticipationsParamsASchema = PaginationParamsSchema.merge(
  z.object({
    campaignId: z.string().uuid().optional(),
    statuses: z.preprocess(asArray, z.array(JourneyParticipationStatusSchema)).optional(),
    searchEmail: z.string().optional(),
    userId: z.string().uuid().optional(),
    orderBy: JourneyParticipationsOrderBySchema.default('-createdAt'),
  })
);
export type JourneyParticipationsParamsADto = z.infer<typeof JourneyParticipationsParamsASchema>;

export const GetParticipationAnalyticsParamsASchema = z.object({
  campaignId: z.string().uuid(),
});

export type GetParticipationAnalyticsParamsADto = z.infer<
  typeof GetParticipationAnalyticsParamsASchema
>;

export const StepCompletionASchema = z.object({
  journeyStepId: z.string().uuid(),
  countCompleted: z.number().int().min(0),
});
export type StepCompletionADto = z.infer<typeof StepCompletionASchema>;

export const ParticipationAnalyticsASchema = z.object({
  countTotal: z.number().int().min(0),
  countCompleted: z.number().int().min(0),
  countCompletedBySteps: z.array(StepCompletionASchema),
});

export type ParticipationAnalyticsADto = z.infer<typeof ParticipationAnalyticsASchema>;
