import BreadCrumb from '@cohort/merchants/components/BreadCrumb';
import {useCurrentApp} from '@cohort/merchants/hooks/contexts/currentApp';
import React from 'react';

const AppPageBreadcrumb: React.FC = () => {
  const {app, appSpec} = useCurrentApp();

  return (
    <BreadCrumb
      title={appSpec.name}
      image={React.cloneElement(app.logo, {height: 24, width: 24})}
    ></BreadCrumb>
  );
};

export default AppPageBreadcrumb;
