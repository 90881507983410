import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import CountryFlag from '@cohort/merchants/components/CountryFlag';
import {SelectPicker} from '@cohort/merchants/components/form/select/SelectPicker';
import SectionSeparator from '@cohort/merchants/components/SectionSeparator';
import {notify} from '@cohort/merchants/hooks/toast';
import {RequestError} from '@cohort/merchants/lib/Api';
import {
  createStripeConnectAccount,
  getStripeConnectOnboardingLink,
} from '@cohort/merchants/lib/api/Stripe';
import {StripeCountries} from '@cohort/shared/schema/common/stripe';
import {Warning} from '@phosphor-icons/react';
import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

export type CustomSelectOption = {
  value: string;
  label: string;
  icon: JSX.Element;
};

const formatOptionLabel = ({label, icon}: CustomSelectOption): JSX.Element => (
  <div className="flex items-center space-x-2">
    {icon}
    <div>{label}</div>
  </div>
);

const PaymentSettingsNewAccount = (): JSX.Element => {
  const [refreshNeeded, setRefreshNeeded] = useState(false);
  const [loading, setLoading] = useState(false);

  const [country, setCountry] = useState<CustomSelectOption | null>(null);

  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.payments.paymentSettingsNewAccount',
  });

  const handleSubmit = useCallback(async () => {
    if (country === null) {
      return;
    }
    if (refreshNeeded) {
      notify('error', t('errorConflict'));
      return;
    }
    setLoading(true);
    try {
      await createStripeConnectAccount({country: country.value});
      const {url} = await getStripeConnectOnboardingLink();
      window.location.href = url;
    } catch (err: unknown) {
      if (err instanceof RequestError) {
        switch (err.status) {
          case 409:
            notify('error', t('errorConflict'), {duration: 7000});
            setRefreshNeeded(true);
            break;
          case 417:
            notify('error', t('errorCountryUnavailable'), {duration: 7000});
            break;
        }
      }
      setLoading(false);
    }
  }, [country, refreshNeeded, t]);

  const countryOptions = useMemo(
    () =>
      Object.entries(StripeCountries).map(([value, label]) => ({
        value,
        label,
        icon: <CountryFlag countryCode={value} size={20} />,
      })),
    []
  );

  const headerConfig = {
    title: t('header'),
    subtitle: t('subheaderNotActive'),
  };

  return (
    <AdminPage header={headerConfig}>
      <SectionSeparator />
      <p className="mt-2 font-semibold leading-5">{t('titleNewAccount')}</p>
      <p className="mt-2 font-normal leading-5 text-slate-500">{t('subtitleNewAccount')}</p>
      <p className="mt-10 whitespace-nowrap text-sm font-medium text-slate-700">
        {t('labelSelectCountry')}
      </p>
      <div className="mt-1 flex h-10 flex-row gap-x-3">
        <SelectPicker
          value={country}
          options={countryOptions}
          onChange={selected => setCountry(selected)}
          isDisabled={loading}
          formatOptionLabel={formatOptionLabel}
          placeholder={t('placeholderSelectCountry')}
          name="country"
        />
      </div>
      <p className="mt-2 text-sm text-slate-500">
        <Warning className="mr-1 inline h-4 w-4 text-slate-400" />
        {t('labelSelectCountryWarning')}
      </p>
      <Button
        className="mt-8 self-end"
        loading={loading}
        onClick={handleSubmit}
        disabled={country === null}
      >
        {t('buttonConfirm')}
      </Button>
    </AdminPage>
  );
};

export default PaymentSettingsNewAccount;
