import {resourceToUserEvents} from '@cohort/merchants/apps';
import AdminPage from '@cohort/merchants/components/AdminPage';
import NotificationsSettingsList from '@cohort/merchants/components/notifications/NotificationsSettingsList';
import {useCurrentDigitalAssetCollection} from '@cohort/merchants/hooks/contexts/currentDigitalAssetCollection';
import {useTranslation} from 'react-i18next';

const DigitalAssetCollectionNotificationsPage: React.FC = () => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'digitalAssetCollections.digitalAssetCollection.notifications.page',
  });
  const digitalAssetCollection = useCurrentDigitalAssetCollection();

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
  };

  const digitalAssetUserEvents = resourceToUserEvents['digitalAssetCollection'];
  const digitalAssetUserEventsIds = digitalAssetUserEvents.map(userEvent => userEvent.spec.id);

  return (
    <AdminPage header={headerConfig}>
      <p className="font-semibold">{t('whenAUserHas')}</p>
      <div className="ml-4">
        <NotificationsSettingsList
          userEventIds={digitalAssetUserEventsIds}
          resource={{
            type: 'digitalAssetCollection',
            id: digitalAssetCollection.id,
          }}
        />
      </div>
    </AdminPage>
  );
};

export default DigitalAssetCollectionNotificationsPage;
