import AsidePageMenu from '@cohort/merchants/components/AsidePageMenu';
import {useCurrentApp} from '@cohort/merchants/hooks/contexts/currentApp';
import {getAppConnectionsRoute, getAppSyncsRoute} from '@cohort/merchants/lib/Pages';
import {ArrowsClockwise, Link} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

const AppPageMenu: React.FC = () => {
  const {appId, app, appSpec} = useCurrentApp();
  const {t} = useTranslation('pages', {keyPrefix: 'apps.app.appPageMenu'});

  return (
    <AsidePageMenu
      entries={[
        {
          name: t('connections'),
          path: getAppConnectionsRoute(appId).path,
          icon: <Link size={20} />,
        },
        ...(app.sync !== undefined &&
        // @Devs - We don't support content sync edit yet so we hide the syncs menu item
        appSpec.syncSpec.userSyncsConfigSchema !== null
          ? [
              {
                name: t('syncs'),
                path: getAppSyncsRoute(appId).path,
                icon: <ArrowsClockwise size={20} />,
              },
            ]
          : []),
      ]}
    />
  );
};

export default AppPageMenu;
