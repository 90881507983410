import type {DigitalAssetOrderByADto} from '@cohort/admin-schemas/digitalAsset';
import type {PaginationFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import {useBasePageFilters} from '@cohort/merchants/hooks/filters/basePageFilters';

export const statusFilter = ['used', 'unused'] as const;
export const sortFilter = ['createdAt', '-createdAt'] as const;

export type DigitalAssetsFilters = {
  sort: DigitalAssetOrderByADto;
  setSort: (sort: DigitalAssetOrderByADto) => void;
  reset: () => void;
};

export const useDigitalAssetsFilters = (
  path: RegExp,
  defaultOrderBy: DigitalAssetOrderByADto
): PaginationFilters & DigitalAssetsFilters =>
  useBasePageFilters<DigitalAssetsFilters>(path, set => ({
    sort: defaultOrderBy,
    setSort: sort => set({sort}),
    reset: () => set({sort: defaultOrderBy}),
  }));
