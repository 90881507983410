import Button from '@cohort/merchants/components/buttons/Button';
import DraggableList from '@cohort/merchants/components/form/DraggableList';
import DraggableListItem from '@cohort/merchants/components/form/DraggableListItem';
import FileInput from '@cohort/merchants/components/form/FileInput';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {CampaignSettingsStepValues} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import CampaignSettingsFaqModal from '@cohort/merchants/pages/campaigns/campaign/edit/settings/FaqModal';
import type {FaqItem} from '@cohort/shared/schema/common/campaign';
import {ChatCenteredText, Plus} from '@phosphor-icons/react';
import {Fragment, useState} from 'react';
import type {Control, UseFormRegister} from 'react-hook-form';
import {useFieldArray, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type FaqSectionProps = {
  control: Control<CampaignSettingsStepValues>;
  register: UseFormRegister<CampaignSettingsStepValues>;
};

type FormModalState = {
  open: boolean;
  faq?: FaqItem;
  faqIdx?: number;
};

const FaqItemCard: React.FC<{
  faq: FaqItem;
  handleEdit: () => void;
  handleRemove: () => void;
}> = ({faq, handleEdit, handleRemove}) => (
  <DraggableListItem onEdit={handleEdit} onRemove={handleRemove}>
    <div className="flex items-center gap-2 pl-4">
      <span className="flex items-center font-medium">
        <ChatCenteredText className="mr-4 h-6 text-yellow-300" />
        {faq.question}
      </span>
    </div>
  </DraggableListItem>
);

const FaqSection: React.FC<FaqSectionProps> = ({control, register}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.settings.faqSection',
  });
  const campaign = useCurrentCampaign();

  const selectedLanguage = useWatch({
    control,
    name: 'selectedLanguage',
  });
  const {fields, append, remove, update, replace} = useFieldArray({
    name: `faqs.${selectedLanguage}`,
    control,
  });
  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  const [formModal, setFormModal] = useState<FormModalState>({
    open: false,
    faq: undefined,
    faqIdx: undefined,
  });

  function closeModal(): void {
    setFormModal({open: false, faq: undefined});
  }

  return (
    <Fragment>
      <div className="mb-2 flex w-full items-center justify-between">
        <label className="block text-sm font-medium text-slate-700">{t('titleFaqs')}</label>
        <Button onClick={() => setFormModal({open: true, faq: undefined})} data-testid="add-faq">
          <Plus className="-ml-1 mr-2 h-5 w-5" />
          {t('buttonNewFaq')}
        </Button>
      </div>
      {fields.length === 0 && (
        <div className="rounded-md border-2 border-dashed py-2 text-center text-sm font-medium text-slate-500">
          {t('placeholderNoFaqs')}
        </div>
      )}
      <DraggableList
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        handleOnReorder={ids => replace(ids.map(id => fields.find(field => field.id === id)!))}
        items={fields.map((faq, index) => {
          return {
            item: (
              <FaqItemCard
                faq={faq}
                handleEdit={() => {
                  setFormModal({
                    open: true,
                    faq,
                    faqIdx: index,
                  });
                }}
                handleRemove={() => remove(index)}
              />
            ),
            id: faq.id,
          };
        })}
      />
      {formModal.open && (
        <CampaignSettingsFaqModal
          title={formModal.faq ? t('updateFaqTitle') : t('addFaqTitle')}
          faq={formModal.faq}
          onClose={closeModal}
          onSave={faq => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            formModal.faq ? update(formModal.faqIdx!, faq) : append(faq);
            closeModal();
          }}
        />
      )}
      {isDefaultLanguageSelected && (
        <FileInput
          name="tosFileKey"
          label={campaign.type === 'journey' ? t('labelResources') : t('labelTosFileKey')}
          register={register}
          control={control}
          accept="application/pdf"
          assetKind="termsAndConditions"
          acceptHint={t('instructionTosFileKey')}
        />
      )}
    </Fragment>
  );
};

export default FaqSection;
