// i18nOwl-ignore [app-talon-one.description]
import type {App} from '@cohort/merchants/apps';
import TalonOneEditConnectionComponent from '@cohort/merchants/apps/talon-one/EditConnectionComponent';
import translationEn from '@cohort/merchants/apps/talon-one/locales/en.json';
import translationFr from '@cohort/merchants/apps/talon-one/locales/fr.json';
import {TalonOneCouponPerkIntegration} from '@cohort/merchants/apps/talon-one/perkIntegrations/coupon/PerkIntegration';
import type {TalonOneAppStruct} from '@cohort/shared/apps/talon-one';
import {TalonOneAppSpec} from '@cohort/shared/apps/talon-one';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

const TalonOneApp: App<TalonOneAppStruct> = {
  spec: TalonOneAppSpec,
  ConnectionEditComponent: TalonOneEditConnectionComponent,
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="talon-one" height={40} width={40} />,
  notificationIntegrations: [],
  perkIntegrations: [TalonOneCouponPerkIntegration],
  triggerIntegrations: [],
  userEvents: [],
};

export default TalonOneApp;
