import type {UserCohortADto} from '@cohort/admin-schemas/userAnalytics';
import Button from '@cohort/merchants/components/buttons/Button';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import {cohortsKeys} from '@cohort/merchants/hooks/api/Cohorts';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {usersKeys} from '@cohort/merchants/hooks/api/Users';
import {useCurrentUser} from '@cohort/merchants/hooks/contexts/currentUser';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {deleteCohortUser} from '@cohort/merchants/lib/api/Cohorts';
import {Trash} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

type RemoveUserFromCohortProps = {
  cohort: UserCohortADto;
};

const RemoveUserFromCohort: React.FC<RemoveUserFromCohortProps> = ({cohort}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const user = useCurrentUser();
  const queryClient = useQueryClient();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {t} = useTranslation('pages', {
    keyPrefix: 'users.user.cohorts.removeUserFromCohort',
  });

  const {mutate: handleDelete} = useCohortMutation({
    mutationFn: async () => deleteCohortUser(merchantId, cohort.id, user.id),
    notifySuccessMessage: t('notificationDeleteSuccess'),
    onSuccess: async () => {
      queryClient.invalidateQueries(cohortsKeys.getById(merchantId, cohort.id));
      queryClient.invalidateQueries(usersKeys.getById(merchantId, user.id));
      setShowDeleteModal(false);
    },
  });

  if (cohort.type !== 'static') {
    return null;
  }

  return (
    <div>
      <Button
        variant="secondary"
        size="icon"
        onClick={e => {
          e.stopPropagation();
          setShowDeleteModal(true);
        }}
      >
        <Trash size={20} className="text-red-400" />
      </Button>
      {showDeleteModal && (
        <DeletionModal
          title={t('deleteModalTitle')}
          subtitle={t('deleteModalSubtitle')}
          onClose={() => setShowDeleteModal(false)}
          show={true}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default RemoveUserFromCohort;
