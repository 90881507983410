import {resourceToUserEvents} from '@cohort/merchants/apps';
import AdminPage from '@cohort/merchants/components/AdminPage';
import NotificationsSettingsList from '@cohort/merchants/components/notifications/NotificationsSettingsList';
import {useCurrentBadge} from '@cohort/merchants/hooks/contexts/currentBadge';
import {useTranslation} from 'react-i18next';

const BadgeNotificationsPage: React.FC = () => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'users.badges.badge.notifications.page',
  });
  const badge = useCurrentBadge();

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
  };

  const badgeUserEvents = resourceToUserEvents['badge'];
  const badgeUserEventsIds = badgeUserEvents.map(userEvent => userEvent.spec.id);

  return (
    <AdminPage header={headerConfig}>
      <p className="font-semibold">{t('whenAUserHas')}</p>
      <div className="ml-4">
        <NotificationsSettingsList
          userEventIds={badgeUserEventsIds}
          resource={{
            type: 'badge',
            id: badge.id,
          }}
        />
      </div>
    </AdminPage>
  );
};

export default BadgeNotificationsPage;
