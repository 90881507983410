import type {PerkCardProps} from '@cohort/components-xps/components/cards/PerkCard';
import PerkCard from '@cohort/components-xps/components/cards/PerkCard';
import Button from '@cohort/merchants/components/buttons/Button';
import {X} from '@phosphor-icons/react';
import React from 'react';
import {useTranslation} from 'react-i18next';

type PerkPreviewModalProps = {
  handleOnClose: () => void;
} & PerkCardProps;

const PerkPreviewModal: React.FC<PerkPreviewModalProps> = ({handleOnClose, ...props}) => {
  const {t} = useTranslation('components', {keyPrefix: 'previews.perkPreviewPanel'});

  return (
    <div className="fixed left-0 top-0 z-50 flex h-full w-full flex-col items-center bg-slate-50 p-8">
      <div className="flex w-full flex-row items-center justify-between">
        <Button variant="secondary" data-testid="close-perk-preview" onClick={handleOnClose}>
          <X size={20} className="-ml-1 mr-2 h-5 w-5" />
          {t('labelPreviewClose')}
        </Button>
      </div>
      <div className="flex flex-grow flex-col justify-center">
        <div
          id="preview"
          className="flex min-w-[var(--preview-width)] items-center justify-center rounded-xl p-4"
        >
          <div className="w-[--xps-perk-card-width]">
            <PerkCard {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerkPreviewModal;
