import {usePerkIntegrations} from '@cohort/merchants/apps/usePerkIntegrations';
import {usePerkUtils} from '@cohort/merchants/hooks/usePerkUtils';
import {useUrlParams} from '@cohort/merchants/hooks/useUrlParams';
import {trackPerkCreateAppFilterChanged} from '@cohort/merchants/lib/Tracking';
import {PerkIntegrationIdSchema} from '@cohort/shared/apps';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface AsideMenuFilterItemProps {
  isActive: boolean;
  handleClick: () => void;
  title: string;
  iconColor: string;
  backgroundColor: string;
  number: number;
}

interface AsideMenuProps {
  filter: string | null;
}

const AsideMenuFilterItem: React.FC<AsideMenuFilterItemProps> = ({
  isActive,
  handleClick,
  title,
  backgroundColor,
  iconColor,
  number,
}) => {
  return (
    <div
      key={title}
      className={cn(
        '-ml-2 flex cursor-pointer items-center justify-between rounded-md p-2',
        isActive && 'bg-white'
      )}
      onClick={handleClick}
    >
      <span className="text-sm">{title}</span>
      <span className={cn('w-8 rounded-md px-2 text-center text-sm', iconColor, backgroundColor)}>
        {number}
      </span>
    </div>
  );
};

const AsideMenu: React.FC<AsideMenuProps> = ({filter}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'perks.new.selectPerkType'});
  const {perkTypesData} = usePerkUtils();
  const {patchSearchParams, deleteSearchParam} = useUrlParams();
  const {getPerkIntegration, getPerkIntegrationTitle} = usePerkIntegrations();

  return (
    <div>
      <h1 className="mb-4 text-sm font-medium uppercase text-slate-400">
        {t('labelApplications')}
      </h1>
      <div className="flex flex-col">
        <AsideMenuFilterItem
          key={'all'}
          title={t('labelAll')}
          iconColor={'text-slate-500'}
          backgroundColor={'bg-slate-100'}
          isActive={filter === null || filter === 'all'}
          handleClick={() => {
            trackPerkCreateAppFilterChanged();
            deleteSearchParam('integrationId');
          }}
          number={perkTypesData.length}
        />
        {PerkIntegrationIdSchema.options.map(integrationId => {
          const integration = getPerkIntegration(integrationId);

          return (
            <AsideMenuFilterItem
              key={integrationId}
              title={getPerkIntegrationTitle(integration)}
              iconColor={integration.iconColor}
              backgroundColor={integration.backgroundColor}
              isActive={filter === integrationId}
              handleClick={() => {
                trackPerkCreateAppFilterChanged(integrationId);
                patchSearchParams({integrationId});
              }}
              number={
                perkTypesData.filter(
                  perkTypeData =>
                    perkTypeData.perkIntegrations.includes(integrationId) &&
                    perkTypeData.type !== 'custom'
                ).length
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default AsideMenu;
