import Button from '@cohort/merchants/components/buttons/Button';
import RadioCards from '@cohort/merchants/components/form/RadioCards';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetTitle,
} from '@cohort/merchants/components/modals/Sheet';
import type {StepTriggerFormType} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/journey/utils';
import type {InstagramMedia} from '@cohort/shared/apps/instagram/common';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {zodResolver} from '@hookform/resolvers/zod';
import {CheckCircle} from '@phosphor-icons/react';
import dayjs from 'dayjs';
import type {UseFormReturn} from 'react-hook-form';
import {useController, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

const SelectMediaFormSchema = z.object({
  mediaId: z.string().nullable(),
});
type SelectMediaFormValues = z.infer<typeof SelectMediaFormSchema>;

type SelectMediaSheetProps = {
  medias: InstagramMedia[];
  formReturn: UseFormReturn<StepTriggerFormType>;
  onClose: () => void;
};
const SelectMediaSheet: React.FC<SelectMediaSheetProps> = ({medias, formReturn, onClose}) => {
  const {setValue, control} = formReturn;
  const {field: triggerMediaIdField} = useController({
    name: 'triggerIntegrationConfig.mediaId',
    control,
  });
  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'triggerIntegrations.comment-media.configComponent.selectMediaSheet',
  });

  const {register: selectMediaRegister, control: selectMediaControl} =
    useForm<SelectMediaFormValues>({
      resolver: zodResolver(SelectMediaFormSchema),
      defaultValues: {
        mediaId: triggerMediaIdField.value ?? null,
      },
    });
  const {field: mediaIdField} = useController({control: selectMediaControl, name: 'mediaId'});

  const onSubmit = (): void => {
    if (mediaIdField.value !== null) {
      setValue('triggerIntegrationConfig.mediaId', mediaIdField.value);
    }
    onClose();
  };

  const mediasByMonth = medias.reduce((groups, media) => {
    const monthYear = dayjs(media.createdAt).format('MMMM YYYY');
    if (!groups.has(monthYear)) {
      groups.set(monthYear, []);
    }
    groups.get(monthYear)?.push(media);
    return groups;
  }, new Map<string, InstagramMedia[]>());

  return (
    <Sheet open onOpenChange={onClose}>
      <SheetContent className="max-h-100vh !grid h-[100vh] gap-0 bg-white [grid-template-rows:min-content_1fr_min-content]">
        <SheetTitle className="p-6">{t('title')}</SheetTitle>
        <div className="m-6 mt-0 flex flex-col gap-6 overflow-y-auto rounded-2xl border border-slate-200 bg-slate-50 p-6">
          {Array.from(mediasByMonth).map(([monthYear, medias]) => (
            <div key={monthYear}>
              <p className="mb-4 text-sm capitalize text-slate-700">{monthYear}</p>
              <RadioCards
                name="mediaId"
                direction="row"
                register={selectMediaRegister}
                control={selectMediaControl}
                className="grid grid-cols-4 gap-4"
                options={medias.map(media => ({
                  label: (
                    <div className="aspect-square overflow-hidden rounded-lg shadow-sm">
                      <img
                        src={media.thumbnailUrl}
                        className={cn(media.id === mediaIdField.value && 'opacity-50')}
                      />
                    </div>
                  ),
                  value: media.id,
                  tooltipContent: t('postedOn', {date: dayjs(media.createdAt).format('ll')}),
                }))}
                labelClassName="p-0"
                withCheckIcon={true}
                checkIconComponent={
                  <div className="absolute bottom-3 right-3 rounded-full bg-white p-[1px]">
                    <CheckCircle weight="fill" className="h-5 w-5 text-primary-darker" />
                  </div>
                }
              />
            </div>
          ))}
        </div>
        <SheetFooter className="flex w-full !flex-row !justify-between border-t border-border px-6 py-4">
          <Button variant="secondary" onClick={onClose}>
            {t('buttonCancel')}
          </Button>
          <Button onClick={onSubmit}>{t('buttonConfirm')}</Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default SelectMediaSheet;
