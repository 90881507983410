import type {PerkIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import CohortFormConfig from '@cohort/merchants/components/form/cohortForm/CohortFormConfig';
import {useCurrentPerk} from '@cohort/merchants/hooks/contexts/currentPerk';

const CohortFormPerkIntegrationConfigComponent: React.FC<PerkIntegrationConfigComponentProps> = ({
  formReturn: {register, control},
}) => {
  const perk = useCurrentPerk();

  return (
    <CohortFormConfig
      register={register}
      control={control}
      fieldPaths={{
        questions: 'integration.config.questions',
        description: 'integration.config.description',
      }}
      existingConfig={perk.integration?.config}
    />
  );
};

export default CohortFormPerkIntegrationConfigComponent;
