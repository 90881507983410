type CountryFlagProps = {
  countryCode: string;
  size: number;
  loading?: 'eager' | 'lazy';
  classes?: string;
};

const CountryFlag = (props: CountryFlagProps): JSX.Element => {
  const {countryCode, size = 18, loading} = props;
  return (
    <span className="inline-grid">
      <svg
        className="absolute self-center justify-self-center"
        height={String(size + 2)}
        width={String(size + 2)}
        viewBox={'0 0 100 100'}
      >
        <circle cx="50" cy="50" r="50" stroke="#F0F0F0" fill="#F0F0F0" />
      </svg>
      <img
        className="self-center justify-self-center"
        style={{width: size, height: size, zIndex: 1}}
        src={`https://res.cloudinary.com/cohort-xyz/image/upload/cohort-common/flags/${countryCode.toLowerCase()}.svg`}
        alt={countryCode}
        loading={loading}
      />
    </span>
  );
};
CountryFlag.displayName = 'CountryFlag';

export default CountryFlag;
