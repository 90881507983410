import type {OrganizationADto} from '@cohort/admin-schemas/organization';
import {CardWithRadientBackground} from '@cohort/merchants/components/CardWithRadientBackground';
import SignInForm from '@cohort/merchants/pages/sign-in/SignInForm';
import React from 'react';

type OrganizationSignInProps = {
  organization: OrganizationADto;
  onUserLoggedIn: () => void;
};
const OrganizationSignIn: React.FC<OrganizationSignInProps> = ({organization, onUserLoggedIn}) => (
  <CardWithRadientBackground className="!w-[500px] space-y-0 p-6">
    <SignInForm organization={organization} onUserLoggedIn={onUserLoggedIn} />
  </CardWithRadientBackground>
);
export default OrganizationSignIn;
