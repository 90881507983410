import type {CohortWithBadgeADto} from '@cohort/admin-schemas/cohort';
import CohortBadgesVisuals from '@cohort/merchants/components/cohorts/CohortBadgesVisuals';
import {CohortTypeBadge} from '@cohort/merchants/components/cohorts/CohortTypeBadge';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {getCohortMembersRoute} from '@cohort/merchants/lib/Pages';
import {formatDate} from '@cohort/shared/utils/format';
import {createColumnHelper} from '@tanstack/react-table';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

type CohortsTableProps = {
  cohorts: Array<CohortWithBadgeADto>;
  isLoading: boolean;
};

const CohortsTable: React.FC<CohortsTableProps> = ({cohorts, isLoading}) => {
  const navigate = useNavigate();
  const {t} = useTranslation('pages', {keyPrefix: 'users.cohorts.cohortsTable'});
  const columnHelper = createColumnHelper<CohortWithBadgeADto>();
  const columns = [
    columnHelper.display({
      header: t('tableHeaderName'),
      id: 'name',
      cell: data => {
        const cohort = data.row.original;
        return (
          <div className="flex items-center gap-2">
            <div className="font-medium text-foreground">{cohort.name}</div>
            {cohort.badges.length > 0 && <CohortBadgesVisuals badges={cohort.badges} />}
          </div>
        );
      },
      enableSorting: false,
    }),
    columnHelper.accessor('type', {
      header: t('tableHeaderType'),
      cell: data => <CohortTypeBadge type={data.getValue()} />,
      enableSorting: false,
    }),
    columnHelper.accessor('usersCount', {
      header: t('tableHeaderMembers'),
      enableSorting: false,
    }),
    columnHelper.accessor(row => formatDate(row.createdAt), {
      id: 'createdAt',
      header: t('tableHeaderCreated'),
      enableSorting: false,
    }),
  ];
  const table = useCohortTable({
    columns,
    data: cohorts,
  });

  return (
    <DataTable
      table={table}
      columnsLength={columns.length}
      isLoading={isLoading}
      emptyStatePlaceholder={t('emptyPlaceholder')}
      onRowClick={cohort => navigate(getCohortMembersRoute(cohort.id).path)}
    />
  );
};

export default CohortsTable;
