import {useTranslation} from 'react-i18next';

const EmptyRewardsSectionContent: React.FC = () => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.review.emptyRewardsSectionContent',
  });

  return <p className="text-gray-500">{t('noRewards')}</p>;
};

export default EmptyRewardsSectionContent;
