import Input from '@cohort/merchants/components/form/input/Input';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import type {CampaignSettingsStepValues} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Info} from '@phosphor-icons/react';
import type {Control, UseFormRegister} from 'react-hook-form';
import {useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type OptionsSectionProps = {
  control: Control<CampaignSettingsStepValues>;
  register: UseFormRegister<CampaignSettingsStepValues>;
};

const OptionsSection: React.FC<OptionsSectionProps> = ({control, register}) => {
  const campaign = useCurrentCampaign();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.settings.optionsSection',
  });

  const withMaxSupply = useWatch({
    control,
    name: 'withMaxSupply',
  });

  const disableInput = campaign.type === 'airdrop';

  return (
    <div
      className={cn(
        'grid max-w-[400px] gap-4 [grid-template-columns:max-content_1fr]',
        disableInput && 'opacity-70 hover:cursor-not-allowed'
      )}
    >
      <Input
        disabled={disableInput}
        type="checkbox"
        name="withMaxSupply"
        label={t('labelWithMaxSupply')}
        labelPosition="right"
        control={control}
        register={register}
      />
      {withMaxSupply && (
        <Input
          disabled={disableInput}
          type="number"
          name="totalSupply"
          label={
            campaign.type === 'journey' ? t('journeyLabelTotalSupply') : t('storeLabelTotalSupply')
          }
          labelPosition="right"
          control={control}
          register={register}
          min={Math.max(campaign.numOrdered, 1)}
          className="w-20"
        />
      )}
      {disableInput && (
        <div className="flex items-center gap-1 text-xs text-primary">
          <Info size={20} /> {t('comingSoon')}
        </div>
      )}
    </div>
    // @Devs Todo: Add expiration data input here later.
  );
};

export default OptionsSection;
