import {create} from 'zustand';

export interface CampaignPageStore {
  campaignPreview?: React.ReactNode;
  footer?: React.ReactNode;
  menu?: React.ReactNode;
  setFooter: (footer: React.ReactNode) => void;
  setCampaignPreview: (campaignPreview: React.ReactNode) => void;
  setMenu: (menu: React.ReactNode) => void;
}

export const useCampaignPageStore = create<CampaignPageStore>(set => ({
  campaignPreview: undefined,
  footer: undefined,
  menu: undefined,
  setFooter: footer => set({footer}),
  setCampaignPreview: campaignPreview => set({campaignPreview}),
  setMenu: menu => set({menu}),
}));
