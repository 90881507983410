/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getOrganizationMembers} from '@cohort/merchants/lib/api/OrganizationMembers';

export const organizationMembersKeys = {
  members: ['organizationMembers'] as const,
  list: () => [...organizationMembersKeys.members, 'list'] as const,
};

export const useOrganizationMembers = (enabled?: boolean) =>
  useCohortQuery({
    queryKey: organizationMembersKeys.list(),
    queryFn: async () => getOrganizationMembers(),
    enabled,
  });
