import type {App, NotificationIntegration} from '@cohort/merchants/apps';
import {
  getAppsWithNotificationIntegrations,
  getNotificationIntegrations,
} from '@cohort/merchants/apps';
import {useApps} from '@cohort/merchants/apps/useApps';
import type {AppId} from '@cohort/shared/apps';
import type {NotificationIntegrationStruct} from '@cohort/shared/apps/app';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {camelCase} from 'lodash';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

export type Output<T extends NotificationIntegrationStruct> = {
  getNotificationIntegration: (notificationIntegrationId: T['Id']) => NotificationIntegration<T>;
  getNotificationIntegrationTitle: (integration: NotificationIntegration<T>) => string;
  getAppFromNotificationIntegration: (notificationIntegrationId: T['Id']) => App;
  notificationIntegrationDisabled: (integration: NotificationIntegration<T>) => boolean;
};

export const useNotificationIntegrations = <
  T extends NotificationIntegrationStruct,
>(): Output<T> => {
  const {t} = useTranslation();
  const {appDisabled} = useApps();
  const flags = useFlags();

  const getNotificationIntegration = useCallback(
    (notificationIntegrationId: T['Id']): NotificationIntegration<T> => {
      const notificationIntegrations = getNotificationIntegrations();
      const notificationIntegration = notificationIntegrations.find(
        integration => integration.spec.id === notificationIntegrationId
      );

      if (!notificationIntegration) {
        throw new Error(
          `No NotificationIntegration found for notificationIntegrationId: ${notificationIntegrationId}`
        );
      }
      return notificationIntegration as NotificationIntegration<T>;
    },
    []
  );

  const getAppFromNotificationIntegration = useCallback(
    (notificationIntegrationId: T['Id']): App => {
      const appsListWithNotificationIntegrations = getAppsWithNotificationIntegrations();
      const app = appsListWithNotificationIntegrations.find(app =>
        app.notificationIntegrations.find(
          integration => integration.spec.id === notificationIntegrationId
        )
      );

      if (!app) {
        throw new Error(
          `Never happens: No app found for notification ${notificationIntegrationId}`
        );
      }
      return app;
    },
    []
  );

  const getNotificationIntegrationTitle = (integration: NotificationIntegration<T>): string => {
    const [appId, integrationId] = integration.spec.id.split('.');

    return t(`notificationIntegrations.${integrationId}.title`, {
      ns: `app-${appId}`,
    });
  };

  const notificationIntegrationDisabled = (integration: NotificationIntegration<T>): boolean => {
    const [appId, notificationIntegrationId] = integration.spec.id.split('.') as [AppId, string];
    if (appDisabled(appId)) {
      return true;
    }
    const flag = flags[camelCase(`apps.${appId}.notifications.${notificationIntegrationId}`)];
    return flag === false;
  };

  return {
    getNotificationIntegration,
    getNotificationIntegrationTitle,
    getAppFromNotificationIntegration,
    notificationIntegrationDisabled,
  };
};
