import Button from '@cohort/merchants/components/buttons/Button';
import Form from '@cohort/merchants/components/form/Form';
import Input from '@cohort/merchants/components/form/input/Input';
import {Dialog, DialogContent, DialogTitle} from '@cohort/merchants/components/modals/Dialog';
import {badgesKeys} from '@cohort/merchants/hooks/api/Badges';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {createBadge} from '@cohort/merchants/lib/api/Badges';
import {getBadgeEditRoute} from '@cohort/merchants/lib/Pages';
import {BadgeInternalNameSchema} from '@cohort/shared/schema/common/badges';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {z} from 'zod';

interface CreateBadgeModalProps {
  onClose: () => void;
}

const BadgeInternalNameFormSchema = z.object({
  internalName: BadgeInternalNameSchema,
});

type BadgeInternalNameFormValues = z.infer<typeof BadgeInternalNameFormSchema>;

const CreateBadgeModal: React.FC<CreateBadgeModalProps> = ({onClose}) => {
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {t} = useTranslation('pages', {keyPrefix: 'users.badges.overview.createBadgeModal'});

  const {isLoading, mutate: createBadgeMutation} = useCohortMutation({
    mutationFn: async (data: BadgeInternalNameFormValues) => createBadge(merchant.id, data),
    onSuccess: async newBadge => {
      await queryClient.invalidateQueries(badgesKeys.list(merchant.id));
      navigate(getBadgeEditRoute(newBadge.id).path);
    },
  });

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent displayCloseButton={false}>
        <Form
          className="p-6"
          testId="create-badge-modal"
          formSchema={BadgeInternalNameFormSchema}
          defaultValues={{
            internalName: t('placeholderInternalName'),
          }}
          isSubmitting={isLoading}
          onSubmit={data => createBadgeMutation(data)}
          formActions={[
            <Button className="h-10 w-full" type="submit" disabled={false}>
              {t('buttonCreate')}
            </Button>,
          ]}
        >
          {({register, control}) => (
            <Fragment>
              <div className="flex flex-col justify-center pb-4 text-center">
                <DialogTitle className="text-lg">{t('title')}</DialogTitle>
              </div>
              <div className="mb-6 mt-4">
                <Input
                  type="text"
                  name="internalName"
                  register={register}
                  control={control}
                  description={t('contextInternalName')}
                />
              </div>
            </Fragment>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateBadgeModal;
