import {create} from 'zustand';

export interface SyncPageStore {
  footer?: React.ReactNode;
  setFooter: (footer: React.ReactNode) => void;
}

export const useSyncPageStore = create<SyncPageStore>(set => ({
  footer: undefined,
  setFooter: footer => set({footer}),
}));
