import type {CampaignADto, PatchCampaignADto} from '@cohort/admin-schemas/campaign';
import CompletionBreadcrumb from '@cohort/merchants/components/form/CompletionBreadcrumb';
import SubmitFooter from '@cohort/merchants/components/form/SubmitFooter';
import {campaignsKeys} from '@cohort/merchants/hooks/api/Campaigns';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import useStepForm from '@cohort/merchants/hooks/form/stepForm';
import {useCampaignFormState} from '@cohort/merchants/hooks/stores/campaignFormState';
import {useCampaignPageStore} from '@cohort/merchants/hooks/stores/campaignPage';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {patchCampaign, publishCampaign} from '@cohort/merchants/lib/api/Campaigns';
import {uploadAsset} from '@cohort/merchants/lib/form/utils';
import {getCampaignsRoute} from '@cohort/merchants/lib/Pages';
import CampaignAudienceStep from '@cohort/merchants/pages/campaigns/campaign/edit/CampaignAudienceStep';
import CampaignReviewStep from '@cohort/merchants/pages/campaigns/campaign/edit/CampaignReviewStep';
import CampaignRewardStep from '@cohort/merchants/pages/campaigns/campaign/edit/CampaignRewardStep';
import CampaignSettingsAirdropStep from '@cohort/merchants/pages/campaigns/campaign/edit/CampaignSettingsAirdropStep';
import CampaignSettingsJourneyStep from '@cohort/merchants/pages/campaigns/campaign/edit/CampaignSettingsJourneyStep';
import CampaignSettingsStoreStep from '@cohort/merchants/pages/campaigns/campaign/edit/CampaignSettingsStoreStep';
import CampaignTypeStep from '@cohort/merchants/pages/campaigns/campaign/edit/CampaignTypeStep';
import type {CampaignFormStep} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import {
  CampaignFormSteps,
  campaignFormStepsValidators,
} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import {notifyError} from '@cohort/merchants/stores/ErrorModalStore';
import type {AssetKind} from '@cohort/shared/schema/common/assets';
import {useQueryClient} from '@tanstack/react-query';
import {useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {match} from 'ts-pattern';
import {shallow} from 'zustand/shallow';

const CampaignEditPage = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const campaign = useCurrentCampaign();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {rewardStepSaved, setRewardStepSaved} = useCampaignFormState(campaign.id)();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.page',
  });

  const isStepCompleted = (step: CampaignFormStep, campaign: CampaignADto): boolean => {
    /* 
      validation of rewards step is always true for journeys which can have none,
      so we also need to check rewardStepSaved to see if the user saved the reward step at least once
      if the settings step is valid, we can also consider the reward step as completed
    */
    if (step === 'reward') {
      const rewardStepIsValid =
        rewardStepSaved || campaignFormStepsValidators['settings'].safeParse(campaign).success;
      return rewardStepIsValid;
    }
    return campaignFormStepsValidators[step].safeParse(campaign).success;
  };

  const {setFooter, setMenu} = useCampaignPageStore(
    state => ({
      setFooter: state.setFooter,
      setMenu: state.setMenu,
    }),
    shallow
  );

  const [state, dispatch] = useStepForm<CampaignFormStep>('type', () => {
    const steps = [];
    let currentStep: CampaignFormStep | undefined = undefined;

    for (const step of CampaignFormSteps) {
      const isCompleted = isStepCompleted(step, campaign);
      const isCurrent = !currentStep && !isCompleted;

      if (isCurrent) {
        currentStep = step;
      }

      // i18nOwl-ignore [steps.audience, steps.review, steps.reward, steps.settings, steps.type]
      steps.push({
        id: step,
        label: t(`steps.${step}`),
        visited: isCompleted || isCurrent,
        isCurrent,
        isCompleted,
        isDirty: false,
        countsForCompletion: step !== 'review' ? true : false,
        onClick: () => dispatch({type: 'JUMP_TO', payload: step}),
      });
    }
    return {
      steps,
      step: currentStep || 'type',
    };
  });

  const onStepValidated = useCallback(
    async (step: CampaignFormStep) => {
      await queryClient.invalidateQueries(campaignsKeys.getById(merchantId, campaign.id));

      dispatch({type: 'GO_TO', payload: step});
    },
    [merchantId, queryClient, campaign.id, dispatch]
  );

  const {isLoading: isUploading, mutateAsync: uploadFile} = useCohortMutation({
    mutationFn: async ({file, assetKind}: {file: File; assetKind: AssetKind}) =>
      uploadAsset(file, assetKind, merchantId),
    onError: err => notifyError(err, t('errorFileUploadFailed')),
  });

  const {isLoading: isUpdating, mutateAsync: updateCampaign} = useCohortMutation({
    mutationFn: async (data: PatchCampaignADto) => patchCampaign(merchantId, campaign.id, data),
    notifyErrorMessage: t('notificationErrorUpdate'),
    onSuccess: async () => {
      queryClient.invalidateQueries(campaignsKeys.getById(merchantId, campaign.id));
      queryClient.invalidateQueries(campaignsKeys.list(merchantId));
    },
  });

  const {isLoading: isPublishing, mutate: publishCampaignMutation} = useCohortMutation({
    mutationFn: async () => publishCampaign(merchantId, campaign.id),
    notifySuccessMessage: t('notificationPublicationSuccess'),
    notifyErrorMessage: t('notificationErrorPublish'),
    onSuccess: async () => {
      queryClient.invalidateQueries(campaignsKeys.getById(merchantId, campaign.id));
      queryClient.invalidateQueries(campaignsKeys.list(merchantId));
      navigate(getCampaignsRoute().path);
    },
  });

  useEffect(() => {
    setMenu(<CompletionBreadcrumb steps={state.steps} />);
    return () => setMenu(null);
  }, [setMenu, state.steps]);

  useEffect(() => {
    const currentStep = state.step;
    const previousStep = CampaignFormSteps[CampaignFormSteps.indexOf(currentStep) - 1];

    setFooter(
      <SubmitFooter
        formName={`campaign-${state.step}-step`}
        loading={isUpdating || isPublishing || isUploading}
        submitLabel={state.step === 'review' ? t('publishBtn') : t('continueBtn')}
        additionalAction={state.stepSaveBtn}
        withBackButton={
          previousStep && {
            label: t('backBtn'),
            onClick: () => dispatch({type: 'JUMP_TO', payload: previousStep}),
          }
        }
      />
    );
    return () => setFooter(null);
  }, [
    setFooter,
    t,
    isUpdating,
    state.step,
    state.stepSaveBtn,
    isPublishing,
    isUploading,
    dispatch,
  ]);

  const setStepDirty = useCallback(
    (dirty: boolean) => dispatch({type: 'SET_STEP_DIRTY', payload: dirty}),
    [dispatch]
  );
  const setStepSaveBtn = useCallback(
    (btn: JSX.Element) => dispatch({type: 'SET_STEP_SAVE_BTN', payload: btn}),
    [dispatch]
  );

  return match(state.step)
    .with('type', () => (
      <CampaignTypeStep
        isProcessing={isUpdating || isUploading}
        setStepDirty={setStepDirty}
        setStepSaveBtn={setStepSaveBtn}
        onStepValidated={() => onStepValidated('audience')}
        updateCampaign={updateCampaign}
      />
    ))
    .with('audience', () => (
      <CampaignAudienceStep
        isProcessing={isUpdating || isUploading}
        setStepDirty={setStepDirty}
        setStepSaveBtn={setStepSaveBtn}
        onStepValidated={() => onStepValidated('reward')}
        updateCampaign={updateCampaign}
      />
    ))
    .with('reward', () => (
      <CampaignRewardStep
        isProcessing={isUpdating || isUploading}
        setStepDirty={setStepDirty}
        setStepSaveBtn={setStepSaveBtn}
        onStepValidated={() => {
          setRewardStepSaved(true);
          onStepValidated('settings');
        }}
        updateCampaign={updateCampaign}
      />
    ))
    .with('settings', () => {
      if (campaign.type === 'journey') {
        return (
          <CampaignSettingsJourneyStep
            isProcessing={isUpdating || isUploading}
            uploadMutation={uploadFile}
            setStepDirty={setStepDirty}
            setStepSaveBtn={setStepSaveBtn}
            onStepValidated={() => onStepValidated('review')}
            updateCampaign={updateCampaign}
          />
        );
      } else if (campaign.type === 'airdrop') {
        return (
          <CampaignSettingsAirdropStep
            isProcessing={isUpdating || isUploading}
            uploadMutation={uploadFile}
            setStepDirty={setStepDirty}
            setStepSaveBtn={setStepSaveBtn}
            onStepValidated={() => onStepValidated('review')}
            updateCampaign={updateCampaign}
          />
        );
      }
      return (
        <CampaignSettingsStoreStep
          isProcessing={isUpdating || isUploading}
          uploadMutation={uploadFile}
          setStepDirty={setStepDirty}
          setStepSaveBtn={setStepSaveBtn}
          onStepValidated={() => onStepValidated('review')}
          updateCampaign={updateCampaign}
        />
      );
    })
    .with('review', () => (
      <CampaignReviewStep
        onEditStep={step => dispatch({type: 'JUMP_TO', payload: step})}
        setStepSaveBtn={setStepSaveBtn}
        publishCampaign={publishCampaignMutation}
      />
    ))
    .exhaustive();
};

export default CampaignEditPage;
