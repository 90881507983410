import liquid from '@cohort/shared/lib/liquid';
import type {DefaultContextDto} from '@cohort/shared/schema/templating/common';
import parse from 'html-react-parser';
import {useMemo} from 'react';

type CustomComponentCardProps<T extends DefaultContextDto> = {
  context: T;
  template: string;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function CustomComponentCard<T extends DefaultContextDto>({
  context,
  template,
}: CustomComponentCardProps<T>): ReturnType<typeof parse> {
  const liquidTemplate = useMemo(
    () => liquid.parseAndRenderSync(template, context),
    [context, template]
  );

  return parse(liquidTemplate);
}
