import type {UserPropertyADto} from '@cohort/admin-schemas/userProperty';
import {useApps} from '@cohort/merchants/apps/useApps';
import DataTypeIcon from '@cohort/merchants/components/DataTypeIcon';
import Loader from '@cohort/merchants/components/Loader';
import {useUserPropertiesWithInfiniteQuery} from '@cohort/merchants/hooks/api/UserProperties';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import useHiddenUserProperties from '@cohort/merchants/hooks/useHiddenUserProperties';
import AppLogo from '@cohort/merchants/pages/users/overview/appLogo';
import type {PaginationDto} from '@cohort/shared/schema/common/pagination';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {UserList, X} from '@phosphor-icons/react';
import type {InfiniteData} from '@tanstack/react-query';
import {Fragment, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useInView} from 'react-intersection-observer';
import {match, P} from 'ts-pattern';

type UserPropertyListElementProps = {
  userProperty: UserPropertyADto;
  hideUserProperty: (userProperty: UserPropertyADto) => void;
  isHidden: boolean;
};

const UserPropertyListElement: React.FC<UserPropertyListElementProps> = ({
  userProperty,
  hideUserProperty,
  isHidden,
}) => {
  const apps = useApps();
  // i18nOwl-ignore [userPropertyDataType.boolean, userPropertyDataType.date, userPropertyDataType.number, userPropertyDataType.string, userPropertyDataType.resource_list, userPropertyDataType.string_list]
  const {t} = useTranslation('pages', {
    keyPrefix: 'users.overview.userPropertiesDrawer',
  });

  const app = apps.getApp(userProperty.appId);

  return (
    <li
      className={cn(
        'flex justify-between rounded-lg border border-slate-200 p-4 shadow-sm',
        isHidden && 'hidden'
      )}
    >
      <div className="flex space-x-4">
        <AppLogo
          app={app}
          fallbackLogo={<UserList size={20} className="text-slate-400" />}
          tooltipContent={app?.spec.name ?? t('customProperty')}
        />
        <div className="text-sm font-medium">{userProperty.name}</div>
      </div>
      <div className="flex items-center space-x-4">
        <div className="flex items-center gap-x-1.5">
          <DataTypeIcon dataType={userProperty.dataType} />
          <div className="text-sm font-normal text-slate-700">
            {t(`userPropertyDataType.${userProperty.dataType}`)}
          </div>
        </div>
        <button
          type="button"
          onClick={() => {
            hideUserProperty(userProperty);
          }}
        >
          <X size={16} className="text-slate-400" />
        </button>
      </div>
    </li>
  );
};

type VisibleUserPropertiesListProps = {
  userPropertiesToHide: UserPropertyADto[];
  setUserPropertiesToHide: (userProperties: UserPropertyADto[]) => void;
};

const PAGE_SIZE = 100;

const VisibleUserPropertiesList: React.FC<VisibleUserPropertiesListProps> = ({
  userPropertiesToHide,
  setUserPropertiesToHide,
}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'users.overview.userPropertiesDrawer'});

  const merchant = useCurrentMerchant();
  const {ref, inView} = useInView();
  const excludedReferenceIds = useHiddenUserProperties();

  const {
    data: visibleUserPropertiesData = {pages: []},
    fetchNextPage,
    hasNextPage,
    status,
    isFetching,
  } = useUserPropertiesWithInfiniteQuery(merchant.id, PAGE_SIZE, {
    visible: true,
    excludedReferenceIds,
  });
  const userPropertiesTotal = visibleUserPropertiesData.pages.flatMap(page => page.data).length;

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  const emptyGuard = (
    data: InfiniteData<{data: UserPropertyADto[]; meta: PaginationDto}> | undefined
  ): data is InfiniteData<{data: UserPropertyADto[]; meta: PaginationDto}> =>
    userPropertiesTotal - userPropertiesToHide.length === 0 ||
    (data !== undefined && (data.pages[0]?.data ?? []).length === 0);

  const hideUserProperty = (userProperty: UserPropertyADto): void => {
    setUserPropertiesToHide([...userPropertiesToHide, userProperty]);
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  };

  return match({
    visibleUserPropertiesData,
    status,
    isFetching,
  })
    .with(
      {
        visibleUserPropertiesData: P.select(P.when(emptyGuard)),
        isFetching: false,
      },
      () => (
        <div className="flex h-full flex-col items-center justify-center rounded-lg bg-slate-50 text-sm font-medium">
          <UserList size={32} className="text-slate-400" />
          {t('userPropertiesList.labelEmpty')}
        </div>
      )
    )
    .otherwise(() => {
      // "min-h-0 flex-1 overflow-y-auto" allows a vertical scroll with dynamic height.
      return (
        <div className="no-scrollbar min-h-0 flex-1 overflow-y-auto">
          <ul className="space-y-2">
            {visibleUserPropertiesData.pages.map((userProperties, pageIdx) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={pageIdx}>
                {userProperties.data.map(userProperty => (
                  <UserPropertyListElement
                    key={userProperty.id}
                    userProperty={userProperty}
                    hideUserProperty={hideUserProperty}
                    isHidden={userPropertiesToHide.includes(userProperty)}
                  />
                ))}
              </Fragment>
            ))}
            <li ref={ref}></li>
          </ul>
          {isFetching && (
            <div className="flex justify-center">
              <Loader size={30} color="gray" />
            </div>
          )}
        </div>
      );
    });
};

export default VisibleUserPropertiesList;
