import type {JourneyParticipationsOrderBy} from '@cohort/admin-schemas/journeyParticipation';
import type {PaginationFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import {useBasePageFilters} from '@cohort/merchants/hooks/filters/basePageFilters';
import type {JourneyParticipationStatus} from '@cohort/shared/schema/common/journeyParticipations';

export const statusFilter = ['in-progress', 'completed'] as const;

export type UserJourneysFilters = {
  status: Array<JourneyParticipationStatus>;
  setStatus: (status: Array<JourneyParticipationStatus>) => void;
  reset: () => void;
  orderBy: JourneyParticipationsOrderBy;
  setOrderBy: (orderBy: JourneyParticipationsOrderBy) => void;
};

export const useUserJourneysFilters = (): PaginationFilters & UserJourneysFilters =>
  useBasePageFilters<UserJourneysFilters>(/^(\/test)?\/users\/.*\/journeys$/u, set => ({
    status: [],
    setStatus: status => set({status}),
    orderBy: '-createdAt',
    setOrderBy: orderBy => set({orderBy}),
    reset: () => set({page: 1, status: []}),
  }));
