import type {PerkCardProps} from '@cohort/components-xps/components/cards/PerkCard';
import PerkCard from '@cohort/components-xps/components/cards/PerkCard';
import type {PerkIntegrationId} from '@cohort/shared/apps';
import type {PerkType} from '@cohort/shared/schema/common/perks';
import type {DefaultContextDto} from '@cohort/shared/schema/templating/common';
import {makePerkContext} from '@cohort/shared/utils/templating';

export type PerkReward = {
  id: string;
  videoFileKey: string | null;
  thumbnailFileKey: string | null;
  bannerFileKey: string | null;
  displayName: string;
  maxAccessesPerUser: number | null;
  description: string | null;
  type: PerkType;
  integration: {
    perkIntegrationId: PerkIntegrationId;
  } | null;
};

type PerkRewardProps = {
  perk: PerkReward;
  customComponent?: {
    template: string;
    context: DefaultContextDto;
  };
} & Omit<PerkCardProps, 'perk' | 'customComponent'>;

const PerkReward: React.FC<PerkRewardProps> = ({perk, customComponent, ...props}) => {
  let customComponentConfig = undefined;

  if (customComponent) {
    customComponentConfig = {
      template: customComponent.template,
      context: {
        perk: makePerkContext(perk),
        ...customComponent.context,
      },
    };
  }
  return (
    <PerkCard
      perk={{
        bannerFileKey: perk.bannerFileKey,
        displayName: perk.displayName,
        thumbnailFileKey: perk.thumbnailFileKey,
        videoFileKey: perk.videoFileKey,
      }}
      customComponent={customComponentConfig}
      {...props}
    />
  );
};

export default PerkReward;
