import {Progress} from '@cohort/merchants/components/Progress';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

export type CompletionStep<T extends string> = {
  id: T;
  label: string;
  isCurrent: boolean;
  isCompleted: boolean;
  visited: boolean;
  isDirty: boolean;
  isDisabled?: boolean;
  countsForCompletion: boolean;
  onClick: () => void;
};

type CompletionBreadcrumbProps<T extends string> = {
  steps: Array<CompletionStep<T>>;
  hideCompletionProgress?: boolean;
};

type BreadcrumbItemProps<T extends string> = {
  step: CompletionStep<T>;
  hasDirtyStep: boolean;
  isDisabled?: boolean;
};

type BreadcrumbItemDividerProps = {
  isCompleted: boolean;
};

type BreadcrumbItemIconProps = {
  isCurrent: boolean;
  visited: boolean;
  isCompleted: boolean;
};

const BreadcrumbItemIcon: React.FC<BreadcrumbItemIconProps> = ({
  isCurrent,
  isCompleted,
  visited,
}) => (
  <div
    className={cn(
      'flex h-5 w-5 items-center justify-center rounded-full border',
      (isCurrent || isCompleted || visited) && '!border-slate-400 bg-slate-400'
    )}
  >
    {isCompleted ? (
      <div className="mb-[3px] inline-block h-[10px] w-[6px] rotate-45 border-b-2 border-r-2 border-white"></div>
    ) : (
      <div
        className={cn(
          'h-[6px] w-[6px] rounded-full bg-gray-300',
          (isCurrent || visited) && '!bg-white'
        )}
      ></div>
    )}
  </div>
);

// eslint-disable-next-line @typescript-eslint/naming-convention
function BreadcrumbItem<T extends string>({
  step,
  hasDirtyStep,
}: BreadcrumbItemProps<T>): JSX.Element {
  const {t} = useTranslation('components', {
    keyPrefix: 'form.v2.completionBreadcrumb',
  });
  const isReachable =
    (step.isCurrent || step.isCompleted || step.visited) &&
    (step.isDisabled === undefined || !step.isDisabled);

  return (
    <li
      className={cn(
        'flex items-center gap-3',
        isReachable ? 'cursor-pointer' : 'cursor-not-allowed'
      )}
      onClick={() => {
        if (isReachable) {
          const confirm = hasDirtyStep ? window.confirm(t('promptLostChangesWarning')) : true;

          if (confirm) {
            step.onClick();
          }
        }
      }}
    >
      <BreadcrumbItemIcon
        isCurrent={step.isCurrent}
        isCompleted={step.isCompleted}
        visited={step.visited}
      />
      <div
        className={cn(
          'text-sm',
          step.isCurrent && 'font-semibold ',
          !isReachable && 'text-slate-400'
        )}
      >
        {step.label}
      </div>
    </li>
  );
}

const BreadcrumbItemDivider: React.FC<BreadcrumbItemDividerProps> = ({isCompleted}) => (
  <li
    className={cn('my-1 ml-2 h-[10px] w-[2px] bg-slate-400', !isCompleted && '!bg-gray-200')}
  ></li>
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function CompletionBreadcrumb<T extends string>({
  steps,
  hideCompletionProgress = false,
}: CompletionBreadcrumbProps<T>): JSX.Element {
  const {t} = useTranslation('components', {
    keyPrefix: 'form.v2.completionBreadcrumb',
  });
  const shares = steps.filter(step => step.countsForCompletion).length;
  const completedShares = steps.filter(step => step.isCompleted && step.countsForCompletion).length;
  const sharePercentage = Math.round(100 / shares);
  const progress = Math.round(sharePercentage * completedShares);
  const dirtyStep = steps.find(step => step.isDirty);

  return (
    <div>
      <ul className="flex flex-col">
        {steps.map((step, idx) => (
          <Fragment key={step.id}>
            <BreadcrumbItem step={step} hasDirtyStep={dirtyStep ? true : false} />
            {idx < steps.length - 1 && <BreadcrumbItemDivider isCompleted={step.isCompleted} />}
          </Fragment>
        ))}
      </ul>
      {!hideCompletionProgress && (
        <div className="mt-6 rounded-xl border bg-white px-4 py-2 pb-3 text-sm text-gray-500">
          <div className="flex items-center gap-1">
            <span className="text-base font-medium text-primary">{progress}%</span> {t('complete')}
          </div>
          <div className="relative mt-2">
            <Progress value={progress} />
          </div>
        </div>
      )}
    </div>
  );
}
