import type {UserPropertyADto} from '@cohort/admin-schemas/userProperty';
import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {UserPropertyDataTypeBadge} from '@cohort/merchants/components/UserPropertyDataTypeBadge';
import {usePaginatedUserProperties} from '@cohort/merchants/hooks/api/UserProperties';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useUserPropertiesSettingsFilters} from '@cohort/merchants/hooks/filters/userPropertiesSettingsFilters';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {getUserPropertySettingsRoute} from '@cohort/merchants/lib/Pages';
import CreateUserPropertyModal from '@cohort/merchants/pages/settings/user-properties/CreateUserPropertyModal';
import DeleteUserPropertyModal from '@cohort/merchants/pages/settings/user-properties/DeleteUserPropertyModal';
import {formatDate} from '@cohort/shared/utils/format';
import {Trash, UserList} from '@phosphor-icons/react';
import {createColumnHelper} from '@tanstack/react-table';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const PAGE_SIZE = 20;

interface UserPropertiesTableProps {
  userProperties: UserPropertyADto[];
  isLoading: boolean;
  orderBy: string;
  setOrderBy: (orderBy: string) => void;
}

const UserPropertiesTable = ({
  userProperties,
  isLoading,
  orderBy,
  setOrderBy,
}: UserPropertiesTableProps): JSX.Element => {
  const {t} = useTranslation('pages', {keyPrefix: 'settings.userProperties.page'});
  const navigate = useNavigate();
  const [userPropertyToDelete, setUserPropertyToDelete] = useState<UserPropertyADto | null>(null);
  const columnHelper = createColumnHelper<UserPropertyADto>();
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: t('tableHeaderName'),
      cell: data => <span className="font-medium text-foreground">{data.getValue()}</span>,
    }),
    columnHelper.accessor('dataType', {
      id: 'dataType',
      header: t('tableHeaderType'),
      cell: data => <UserPropertyDataTypeBadge userProperty={data.row.original} />,
    }),
    columnHelper.accessor(row => formatDate(row.createdAt), {
      id: 'createdAt',
      header: t('tableHeaderCreatedAt'),
    }),
    columnHelper.display({
      id: 'actions',
      cell: data =>
        data.row.original.appId === null ? (
          <div className="text-right">
            <Button
              variant="secondary"
              size="icon"
              onClick={e => {
                e.stopPropagation();
                setUserPropertyToDelete(data.row.original);
              }}
            >
              <Trash size={20} className="text-red-400" />
            </Button>
          </div>
        ) : null,
    }),
  ];
  const table = useCohortTable(
    {
      columns,
      data: userProperties,
    },
    {
      sortBy: orderBy,
      onSortUpdate: setOrderBy,
    }
  );

  return (
    <Fragment>
      <DataTable
        table={table}
        columnsLength={columns.length}
        emptyStatePlaceholder={t('emptyPlaceholder')}
        isLoading={isLoading}
        onRowClick={row => navigate(getUserPropertySettingsRoute(row.id).path)}
      />
      {userPropertyToDelete !== null && (
        <DeleteUserPropertyModal
          onClose={() => setUserPropertyToDelete(null)}
          userProperty={userPropertyToDelete}
        />
      )}
    </Fragment>
  );
};

const UserPropertiesSettingsPage = (): JSX.Element => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {keyPrefix: 'settings.userProperties.page'});

  const filters = useUserPropertiesSettingsFilters();

  const [addModalOpened, setAddModalOpened] = useState(false);

  const {data, isLoading: isUserPropertiesLoading} = usePaginatedUserProperties(merchant.id, {
    pageSize: PAGE_SIZE,
    page: filters.page,
    orderBy: filters.orderBy,
    hasAppId: false,
  });
  const [pagination, userProperties] = data ?? [];

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
    topRightElements: (
      <Button
        key="add"
        onClick={() => {
          setAddModalOpened(true);
        }}
      >
        <UserList size={20} className="mr-2" />
        {t('createPropertyButton')}
      </Button>
    ),
  };

  const paginationConfig = {
    pagination,
    onPageChange: filters.setPage,
  };

  return (
    <AdminPage header={headerConfig} pagination={paginationConfig}>
      <UserPropertiesTable
        userProperties={userProperties ?? []}
        isLoading={isUserPropertiesLoading}
        orderBy={filters.orderBy}
        setOrderBy={filters.setOrderBy as (value: string) => void}
      />
      {addModalOpened && <CreateUserPropertyModal onClose={() => setAddModalOpened(false)} />}
    </AdminPage>
  );
};

export default UserPropertiesSettingsPage;
