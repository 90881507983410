import type {DigitalAssetOrderByADto} from '@cohort/admin-schemas/digitalAsset';
import AdminPage from '@cohort/merchants/components/AdminPage';
import KPICard from '@cohort/merchants/components/KPICard';
import {useDigitalAssets} from '@cohort/merchants/hooks/api/DigitalAssets';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCurrentUser} from '@cohort/merchants/hooks/contexts/currentUser';
import {useDigitalAssetsFilters} from '@cohort/merchants/hooks/filters/digitalAssetsFilter';
import UserDigitalAssetsTable from '@cohort/merchants/pages/users/user/digital-assets/UserDigitalAssetsTable';
import {Gift} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

const PAGE_SIZE = 20;

const UserDigitalAssetsPage = (): JSX.Element => {
  const user = useCurrentUser();
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {keyPrefix: 'users.user.digitalAssets.page'});

  const filters = useDigitalAssetsFilters(
    /^(\/test)?\/users\/.*\/digital-assets$/u,
    '-obtentionDate'
  );

  const {data, isLoading} = useDigitalAssets(merchant.id, {
    userId: [user.id],
    page: filters.page,
    pageSize: PAGE_SIZE,
    orderBy: filters.sort,
  });

  const [pagination, digitalAssets] = data ?? [];

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
    topRightElements: pagination && (
      <KPICard
        icon={<Gift className="mr-1.5 h-4 w-4 text-slate-500" />}
        key="digital-assets"
        text={t('labelDigitalAsset')}
        value={pagination.total}
      />
    ),
  };

  const paginationConfig = {
    pagination,
    onPageChange: filters.setPage,
  };

  return (
    <AdminPage header={headerConfig} pagination={paginationConfig} className="space-y-10">
      <UserDigitalAssetsTable
        sortBy={filters.sort}
        onSort={sort => filters.setSort(sort as DigitalAssetOrderByADto)}
        isLoading={isLoading}
        digitalAssets={digitalAssets ?? []}
      />
    </AdminPage>
  );
};
export default UserDigitalAssetsPage;
