// eslint-disable-next-line import/no-unassigned-import
import 'react-image-crop/dist/ReactCrop.css';

import Button from '@cohort/merchants/components/buttons/Button';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetTitle,
} from '@cohort/merchants/components/modals/Sheet';
import type {ImageToCrop} from '@cohort/merchants/lib/ImageCrop';
import {
  centerAspectCrop,
  drawCanvasPreview,
  scaleMinDimensions,
} from '@cohort/merchants/lib/ImageCrop';
import type {AssetKind} from '@cohort/shared/schema/common/assets';
import type {SizedAssetKind} from '@cohort/shared/utils/fileUploads';
import {ASSETS_MIN_DIMENSIONS, isLandscapeVisual} from '@cohort/shared/utils/fileUploads';
import {isRoundedVisual} from '@cohort/shared/utils/media';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import type {Crop, PixelCrop} from 'react-image-crop';
import ReactCrop from 'react-image-crop';

interface Props {
  open: boolean;
  onClose: (uploading?: boolean) => void;
  onSubmit: (croppedImg: File) => void;
  imageToCrop: ImageToCrop;
  assetKind: AssetKind;
  setIsCropLoading: (isLoading: boolean) => void;
}

export const SlideOverCropImage: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  imageToCrop,
  assetKind,
  setIsCropLoading,
}) => {
  const {t} = useTranslation('components', {keyPrefix: 'form.slideOverCropImage'});
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [crop, setCrop] = useState<Crop>();
  const [minCropDimensions, setMinCropDimensions] = useState({
    minWidth: 20,
    minHeight: 20,
  });

  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    setCrop(undefined); // Makes crop preview update between images.
  }, [imageToCrop.src]);

  useEffect(() => {
    if (imgRef.current && Object.keys(ASSETS_MIN_DIMENSIONS).includes(assetKind)) {
      const scaledDimensions = scaleMinDimensions(imgRef.current, assetKind as SizedAssetKind);
      setMinCropDimensions({
        minWidth: scaledDimensions.minWidth,
        minHeight: scaledDimensions.minHeight,
      });
    }
  }, [assetKind]);

  useEffect(() => {
    if (completedCrop !== undefined && imgRef.current && previewCanvasRef.current) {
      drawCanvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
    }
  }, [imageToCrop.src, completedCrop]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>): void => {
      const {width, height} = e.currentTarget;
      setCrop(centerAspectCrop(width, height, assetKind));
    },
    [setCrop, assetKind]
  );

  const handleSave = useCallback((): void => {
    onClose(true);
    setIsCropLoading(true);
    previewCanvasRef.current?.toBlob(
      blob => {
        if (blob) {
          const croppedImg = new File([blob], imageToCrop.name, {
            type: 'image/webp',
          });
          onSubmit(croppedImg);
        }
        setIsCropLoading(false);
      },
      'image/webp',
      0.9
    );
  }, [imageToCrop.name, onClose, onSubmit, setIsCropLoading]);

  const handleCropComplete = (crop: PixelCrop): void => {
    if (crop.height > 0 && crop.width > 0) {
      setCompletedCrop(crop);
    }
  };

  const submitButtonText = useMemo((): string => {
    switch (assetKind) {
      case 'digitalAssetVisual':
        return t('buttonSaveVisual');
      case 'logo':
        return t('buttonSaveLogo');
      case 'banner':
        return t('buttonSaveBanner');
      case 'perkBanner':
        return t('buttonSaveBanner');
      default:
        return t('buttonSave');
    }
  }, [assetKind, t]);

  return (
    <Sheet open={open} onOpenChange={onClose}>
      <SheetContent className="flex h-full !max-w-[32rem] flex-col gap-0 bg-white py-6 shadow-xl">
        <div className="px-4 pb-4 sm:px-6">
          <SheetTitle className="text-lg font-medium">{t('labelCrop')}</SheetTitle>
        </div>
        <div className="h-full overflow-auto pb-6">
          <div className="mt-2 px-4 sm:px-6">
            <div className="mb-8 flex justify-center">
              <div className="flex justify-center">
                <ReactCrop
                  crop={crop}
                  onChange={percentCrop => setCrop(percentCrop)}
                  onComplete={c => handleCropComplete(c)}
                  aspect={isLandscapeVisual(assetKind) ? 16 / 9 : 1}
                  className="w-full shadow-md"
                  circularCrop={isRoundedVisual(assetKind)}
                  {...minCropDimensions}
                >
                  <img
                    ref={imgRef}
                    className="w-full"
                    src={imageToCrop.src}
                    onLoad={handleImageLoad}
                  />
                </ReactCrop>
              </div>
            </div>
            <div className="flex justify-center">
              <canvas
                ref={previewCanvasRef}
                className={cn(
                  'w-full border border-slate-400 object-contain',
                  isRoundedVisual(assetKind) && 'aspect-square rounded-full'
                )}
              />
            </div>
          </div>
        </div>
        <SheetFooter className="flex justify-end border-t px-4 pt-4">
          <React.Fragment>
            <Button onClick={handleClose} variant="secondary" data-testid="crop-cancel">
              {t('buttonCancel')}
            </Button>
            <Button onClick={handleSave} className="ml-3" data-testid="crop-save">
              {submitButtonText}
            </Button>
          </React.Fragment>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};
