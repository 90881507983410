import type {PatchDigitalAssetCollectionRequestADto} from '@cohort/admin-schemas/digitalAssetCollection';
import AdminPage from '@cohort/merchants/components/AdminPage';
import AdvancedOptions from '@cohort/merchants/components/form/AdvancedOptions';
import DynamicKeyValueInput from '@cohort/merchants/components/form/DynamicKeyValueInput';
import FileInput from '@cohort/merchants/components/form/FileInput';
import LocalizedInput from '@cohort/merchants/components/form/input/LocalizedInput';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import SubmitFooter from '@cohort/merchants/components/form/SubmitFooter';
import LocalizedTextEditorInput from '@cohort/merchants/components/form/textEditor/LocalizedTextEditorInput';
import HighlightText from '@cohort/merchants/components/HighlightText';
import Separator from '@cohort/merchants/components/Separator';
import {digitalAssetCollectionsKeys} from '@cohort/merchants/hooks/api/DigitalAssetCollections';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentDigitalAssetCollection} from '@cohort/merchants/hooks/contexts/currentDigitalAssetCollection';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useDigitalAssetCollectionPageStore} from '@cohort/merchants/hooks/stores/digitalAssetCollectionPage';
import {usePrompt} from '@cohort/merchants/hooks/usePrompt';
import {patchDigitalAssetCollection} from '@cohort/merchants/lib/api/DigitalAssetCollections';
import {
  changeSelectedLanguageIfNeeded,
  getDefinedLanguages,
  removeUndefinedLanguages,
} from '@cohort/merchants/lib/form/localization';
import {uploadAsset} from '@cohort/merchants/lib/form/utils';
import {fileValidatorRequired} from '@cohort/merchants/lib/form/validators';
import {trackDigitalAssetCollectionDetailsPageViewed} from '@cohort/merchants/lib/Tracking';
import DigitalAssetCollectionNftInput from '@cohort/merchants/pages/digital-asset-collections/digital-asset-collection/DigitalAssetCollectionNftSettingsInput';
import DigitalAssetCollectionOwnershipsInput from '@cohort/merchants/pages/digital-asset-collections/digital-asset-collection/DigitalAssetCollectionOwnershipsSettings';
import {notifyError} from '@cohort/merchants/stores/ErrorModalStore';
import {
  LanguageSchema,
  LocalizedRichTextSchema,
  LocalizedStringSchema,
  MetadataSchema,
} from '@cohort/shared/schema/common';
import type {AssetKind} from '@cohort/shared/schema/common/assets';
import {AllowedAssetMimeTypes} from '@cohort/shared/schema/common/assets';
import {isVideoFile} from '@cohort/shared/utils/mimeTypes';
import {isFile} from '@cohort/shared-frontend/utils/isFile';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment, useEffect} from 'react';
import {useController, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

const DigitalAssetCollectionFormSchema = z
  .object({
    title: LocalizedStringSchema,
    description: LocalizedRichTextSchema,
    media: z.any().refine(fileValidatorRequired, 'errorRequired'),
    defaultLanguage: LanguageSchema,
    selectedLanguage: LanguageSchema,
    definedLanguages: z.array(LanguageSchema),
    withMaxOwnershipsPerUser: z.boolean(),
    maxOwnershipsPerUser: z.number().nullable(),
    nftEnabled: z.boolean(),
    nftNonTransferable: z.boolean(),
    metadata: MetadataSchema,
  })
  .superRefine(({description, defaultLanguage}, ctx) => {
    if (description[defaultLanguage] === undefined) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorRequired',
        path: [`description.${defaultLanguage}`],
      });
    }
  })
  .superRefine(({title, defaultLanguage}, ctx) => {
    if (title[defaultLanguage] === undefined) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorRequired',
        path: [`title.${defaultLanguage}`],
      });
    }
  })
  .transform(data => {
    data.title = removeUndefinedLanguages(data.title, data.definedLanguages);
    data.description = removeUndefinedLanguages(data.description, data.definedLanguages);
    return data;
  });

type DigitalAssetCollectionFormValues = z.infer<typeof DigitalAssetCollectionFormSchema>;

const DigitalAssetCollectionSettingsPage = (): JSX.Element => {
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();
  const digitalAssetCollection = useCurrentDigitalAssetCollection();
  const {t} = useTranslation('pages', {
    keyPrefix: 'digitalAssetCollections.digitalAssetCollection.settings.page',
  });

  const setFooter = useDigitalAssetCollectionPageStore(store => store.setFooter);

  const {register, control, handleSubmit, reset, formState} =
    useForm<DigitalAssetCollectionFormValues>({
      defaultValues: {
        title: digitalAssetCollection.title,
        description: digitalAssetCollection.description,
        media: digitalAssetCollection.imageFileKey,
        defaultLanguage: merchant.defaultLanguage,
        selectedLanguage: merchant.defaultLanguage,
        definedLanguages: getDefinedLanguages(merchant.defaultLanguage, [
          digitalAssetCollection.title,
          digitalAssetCollection.description,
        ]),
        withMaxOwnershipsPerUser: digitalAssetCollection.maxOwnershipsPerUser !== null,
        maxOwnershipsPerUser: digitalAssetCollection.maxOwnershipsPerUser,
        nftEnabled: digitalAssetCollection.nftEnabled,
        nftNonTransferable: digitalAssetCollection.nftNonTransferable,
        metadata: digitalAssetCollection.metadata,
      },
      resolver: zodResolver(DigitalAssetCollectionFormSchema),
    });

  const {field: selectedLanguageField} = useController({
    control,
    name: 'selectedLanguage',
  });
  const selectedLanguage = selectedLanguageField.value;
  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  usePrompt(t('promptLostChanges'), formState.isDirty);

  const {isLoading: isUpdating, mutate: updateDigitalAssetCollection} = useCohortMutation({
    mutationFn: async (data: PatchDigitalAssetCollectionRequestADto) =>
      patchDigitalAssetCollection(merchant.id, digitalAssetCollection.id, data),
    notifyErrorMessage: t('notificationErrorUpdate'),
    notifySuccessMessage: t('notificationSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(digitalAssetCollectionsKeys.list(merchant.id));
      queryClient.invalidateQueries(
        digitalAssetCollectionsKeys.getById(merchant.id, digitalAssetCollection.id)
      );
      reset({}, {keepValues: true});
    },
  });

  const {isLoading: isUploading, mutateAsync: uploadFile} = useCohortMutation({
    mutationFn: async ({file, assetKind}: {file: File; assetKind: AssetKind}) =>
      uploadAsset(file, assetKind, merchant.id),
    onError: err => notifyError(err, t('errorFileUploadFailed')),
  });

  useEffect(() => {
    trackDigitalAssetCollectionDetailsPageViewed(digitalAssetCollection);
  }, [digitalAssetCollection]);

  useEffect(() => {
    if (formState.isDirty) {
      setFooter(
        <SubmitFooter
          formName="digital-asset-collection-form"
          submitLabel={t('buttonSaveAndPublish')}
          loading={isUpdating || isUploading}
        />
      );
    } else {
      setFooter(undefined);
    }
    return () => setFooter(undefined);
  }, [formState.isDirty, setFooter, t, isUpdating, isUploading]);

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
  };

  return (
    <form
      id="digital-asset-collection-form"
      onSubmit={handleSubmit(
        async data => {
          const nftEnabled =
            data.nftEnabled === true && digitalAssetCollection.nftEnabled === false
              ? true
              : undefined;

          const patchData: PatchDigitalAssetCollectionRequestADto = {
            title: data.title,
            description: data.description,
            nftEnabled,
            nftNonTransferable: nftEnabled !== undefined ? data.nftNonTransferable : undefined,
            metadata: data.metadata,
            // Ensure preserving maxOwnershipsPerUser value if manually modified in Forest Admin
            maxOwnershipsPerUser: data.withMaxOwnershipsPerUser ? data.maxOwnershipsPerUser : null,
          };
          // upload image or video if changed
          if (isFile(data.media)) {
            const asset = await uploadFile({
              file: data.media as unknown as File,
              assetKind: 'digitalAssetVisual',
            });
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (asset === null) {
              return notifyError(t('errorFileUploadFailed'));
            }
            if (isVideoFile(asset.mimeType)) {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              patchData.imageFileKey = asset.previewFileKey!;
              patchData.animationFileKey = asset.fileKey;
            } else {
              patchData.imageFileKey = asset.fileKey;
              patchData.animationFileKey = null;
            }
          }
          updateDigitalAssetCollection(patchData);
        },
        errors =>
          changeSelectedLanguageIfNeeded(
            errors,
            merchant.defaultLanguage,
            selectedLanguageField.onChange
          )
      )}
    >
      <AdminPage header={headerConfig}>
        <LanguageSelectorInput
          control={control}
          definedLanguagesPath="definedLanguages"
          selectedLanguagePath="selectedLanguage"
        />
        <LocalizedInput
          type="text"
          name="title"
          label={t('labelTitle')}
          placeholder={t('placeholderTitle')}
          register={register}
          control={control}
          selectedLanguage={selectedLanguage}
          optional={!isDefaultLanguageSelected}
        />
        <LocalizedTextEditorInput
          name="description"
          label={t('labelDescription')}
          placeholder={t('placeholderDescription')}
          register={register}
          control={control}
          selectedLanguage={selectedLanguage}
          optional={!isDefaultLanguageSelected}
        />
        {isDefaultLanguageSelected && (
          <Fragment>
            <FileInput
              label={t('labelVisual')}
              name="media"
              register={register}
              control={control}
              acceptHint={t('instructionVisual')}
              accept={AllowedAssetMimeTypes.digitalAssetVisual.options.join(',')}
              assetKind="digitalAssetVisual"
              description={t('helperVisual')}
              withResize
            />
            <Separator />
            <DigitalAssetCollectionNftInput
              digitalAssetNftEnabled={digitalAssetCollection.nftEnabled}
              control={control}
              register={register}
            />
            <Separator />
            <DigitalAssetCollectionOwnershipsInput control={control} register={register} />
          </Fragment>
        )}

        <div className="overflow-visible">
          <AdvancedOptions
            accordionItemValue="digital-asset-collection-settings-advanced-options"
            accordionDefaultValue={
              digitalAssetCollection.metadata.length > 0
                ? 'digital-asset-collection-settings-advanced-options'
                : undefined
            }
          >
            <DynamicKeyValueInput
              name="metadata"
              control={control}
              register={register}
              labels={{
                title: t('metadataLabel'),
                addBtn: t('addMetadataBtn'),
                key: t('metadataKey'),
                value: t('metadataValue'),
              }}
            />
            <HighlightText
              text={
                <div>
                  {t('metadataExplainations')}
                  <br />
                  <a
                    href="https://docs.getcohort.com/developers/merchants-api"
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('metadataDocs')}
                  </a>
                </div>
              }
              type="info"
            />
          </AdvancedOptions>
        </div>
      </AdminPage>
    </form>
  );
};

export default DigitalAssetCollectionSettingsPage;
