import {create} from 'zustand';
import {persist} from 'zustand/middleware';

interface CampaignFormStateStore {
  setRewardStepSaved: (rewardStepSaved: boolean) => void;
  rewardStepSaved: boolean | null;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCampaignFormState = (campaignId: string) =>
  create<CampaignFormStateStore>()(
    persist(
      set => ({
        rewardStepSaved: false,
        setRewardStepSaved: rewardStepSaved => set({rewardStepSaved}),
      }),
      {
        name: `campaign_form_state_campaignId_${campaignId}`,
      }
    )
  );
