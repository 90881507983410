import {Badge} from '@cohort/merchants/components/Badge';
import Tooltip from '@cohort/shared-frontend/components/Tooltip';
import {useTranslation} from 'react-i18next';

type RewardLimitBadgeProps = {
  limitPerUser: number;
};

const RewardLimitBadge: React.FC<RewardLimitBadgeProps> = ({limitPerUser}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.reward.rewardLimitBadge',
  });

  return (
    <Tooltip content={t('tooltipContent')} side="top">
      <Badge
        size="small"
        text={t('rewardLimitText', {limitPerUser})}
        backgroundColor="bg-orange-100"
        textColor="text-orange-700"
      />
    </Tooltip>
  );
};

export default RewardLimitBadge;
