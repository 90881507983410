import type {DigitalAssetCollectionAnalyticsADto} from '@cohort/admin-schemas/digitalAssetCollection';
import type {
  CreateDigitalAssetCollectionADto,
  DuplicateDigitalAssetCollectionADto,
  GetDigitalAssetCollectionsParamsADto,
  PatchDigitalAssetCollectionRequestADto,
} from '@cohort/admin-schemas/digitalAssetCollection';
import {DigitalAssetCollectionAnalyticsASchema} from '@cohort/admin-schemas/digitalAssetCollection';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import type {PaginationDto} from '@cohort/shared/schema/common/pagination';
import {paginatedParser} from '@cohort/shared/utils/parser';

export async function getDigitalAssetCollections(
  merchantId: string,
  params: GetDigitalAssetCollectionsParamsADto
): Promise<[PaginationDto, DigitalAssetCollectionAnalyticsADto[]]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/digital-asset-collections`, {
    expect: HttpCodes.SUCCESS,
    parser: paginatedParser(DigitalAssetCollectionAnalyticsASchema),
    params,
  });
}

export async function getDigitalAssetCollection(
  merchantId: string,
  digitalAssetId: string
): Promise<DigitalAssetCollectionAnalyticsADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/digital-asset-collections/${digitalAssetId}`, {
    expect: HttpCodes.SUCCESS,
    parser: DigitalAssetCollectionAnalyticsASchema.parse,
  });
}

export async function createDigitalAssetCollection(
  merchantId: string,
  data: CreateDigitalAssetCollectionADto
): Promise<DigitalAssetCollectionAnalyticsADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/digital-asset-collections`, {
    expect: HttpCodes.CREATED,
    parser: DigitalAssetCollectionAnalyticsASchema.parse,
    body: data,
  });
}

export async function deleteDigitalAssetCollection(
  merchantId: string,
  digitalAssetCollectionId: string
): Promise<{}> {
  return apiCall(
    'DELETE',
    `/v1/merchants/${merchantId}/digital-asset-collections/${digitalAssetCollectionId}`,
    {
      expect: HttpCodes.SUCCESS,
      parser: () => ({}),
    }
  );
}

export async function duplicateDigitalAssetCollection(
  merchantId: string,
  digitalAssetCollectionId: string,
  data: DuplicateDigitalAssetCollectionADto
): Promise<DigitalAssetCollectionAnalyticsADto> {
  return apiCall(
    'POST',
    `/v1/merchants/${merchantId}/digital-asset-collections/${digitalAssetCollectionId}/duplicate`,
    {
      expect: HttpCodes.CREATED,
      parser: DigitalAssetCollectionAnalyticsASchema.parse,
      body: data,
    }
  );
}

export async function patchDigitalAssetCollection(
  merchantId: string,
  digitalAssetCollectionId: string,
  data: PatchDigitalAssetCollectionRequestADto
): Promise<DigitalAssetCollectionAnalyticsADto> {
  return apiCall(
    'PATCH',
    `/v1/merchants/${merchantId}/digital-asset-collections/${digitalAssetCollectionId}`,
    {
      expect: HttpCodes.SUCCESS,
      parser: DigitalAssetCollectionAnalyticsASchema.parse,
      body: data,
    }
  );
}
