import type {UserAttributeADto} from '@cohort/admin-schemas/userAttributes';
import type {UserPropertyADto} from '@cohort/admin-schemas/userProperty';
import {useApps} from '@cohort/merchants/apps/useApps';
import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import Filters from '@cohort/merchants/components/filters/Filters';
import HighlightText from '@cohort/merchants/components/HighlightText';
import Loader from '@cohort/merchants/components/Loader';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetTitle,
} from '@cohort/merchants/components/modals/Sheet';
import SectionSeparator from '@cohort/merchants/components/SectionSeparator';
import {useUserAttributes} from '@cohort/merchants/hooks/api/UserAttributes';
import {useUserPropertiesWithInfiniteQuery} from '@cohort/merchants/hooks/api/UserProperties';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCurrentUser} from '@cohort/merchants/hooks/contexts/currentUser';
import useHiddenUserProperties from '@cohort/merchants/hooks/useHiddenUserProperties';
import {getUsersRoute} from '@cohort/merchants/lib/Pages';
import {getUserAttributeValue} from '@cohort/merchants/lib/Utils';
import LinkSection from '@cohort/merchants/pages/settings/integrations/LinkSection';
import AppLogo from '@cohort/merchants/pages/users/overview/appLogo';
import UserAttributeCell from '@cohort/merchants/pages/users/overview/UserAttributeCell';
import UserInformationSection from '@cohort/merchants/pages/users/user/overview/UserInformationSection';
import UserPropertiesSection from '@cohort/merchants/pages/users/user/overview/UserPropertiesSection';
import type {AppId} from '@cohort/shared/apps';
import {AppIdSchema} from '@cohort/shared/apps';
import type {PaginationDto} from '@cohort/shared/schema/common/pagination';
import {Desktop, UserList} from '@phosphor-icons/react';
import {ListMagnifyingGlass} from '@phosphor-icons/react/dist/ssr';
import type {InfiniteData} from '@tanstack/react-query';
import {Fragment, useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useInView} from 'react-intersection-observer';
import {useNavigate} from 'react-router-dom';
import {sortBy} from 'remeda';
import {match, P} from 'ts-pattern';

type UserPropertyDetailElementProps = {
  userProperty: UserPropertyADto;
  userAttribute?: UserAttributeADto;
};

const UserPropertyDetailElement: React.FC<UserPropertyDetailElementProps> = ({
  userProperty,
  userAttribute,
}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'users.user.overview.page'});
  const {getApp} = useApps();
  const app = getApp(userProperty.appId);

  const value =
    userAttribute === undefined ? (
      '-'
    ) : (
      <UserAttributeCell
        dataType={userProperty.dataType}
        value={getUserAttributeValue(userProperty, userAttribute)}
        className="text-sm font-normal text-slate-700"
        tagsListDisplayMode="list"
      />
    );

  return (
    <li className="flex justify-between border-t py-4 first:border-t-0">
      <div className="flex items-center space-x-1.5">
        <AppLogo
          app={app}
          tooltipContent={app?.spec.name ?? t('userPropertyDetailElement.customProperty')}
          fallbackLogo={<UserList size={20} className="text-slate-400" />}
        />
        <div className="text-sm font-normal text-slate-500">{userProperty.name}</div>
      </div>
      <div className="flex items-center space-x-4">{value}</div>
    </li>
  );
};

type UserPropertiesDetailDrawerProps = {
  onClose: () => void;
};

const PAGE_SIZE = 100;
const APP_IDS_TO_EXCLUDE = ['cohort'] as Array<AppId>;

const UserPropertiesDetailDrawer: React.FC<UserPropertiesDetailDrawerProps> = ({onClose}) => {
  const [activeAppIdFilters, setActiveAppIdFilters] = useState<Array<string>>([]);
  const merchant = useCurrentMerchant();
  const user = useCurrentUser();
  const {t} = useTranslation('pages', {keyPrefix: 'users.user.overview.page'});
  const navigate = useNavigate();
  const {ref, inView} = useInView();
  const apps = useApps();
  const excludedReferenceIds = useHiddenUserProperties();

  const {
    data: userProperties,
    fetchNextPage,
    hasNextPage,
    status,
    isFetching,
  } = useUserPropertiesWithInfiniteQuery(merchant.id, PAGE_SIZE, {
    excludedReferenceIds,
  });
  const userPropertiesData = userProperties?.pages.flatMap(page => page.data) ?? [];

  const {data: userAttributes} = useUserAttributes(
    merchant.id,
    {
      userId: user.id,
      userPropertyIds: userPropertiesData.map(property => property.id),
    },
    {
      enabled: userPropertiesData.length > 0,
    }
  );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  const emptyGuard = (
    data: InfiniteData<{data: UserPropertyADto[]; meta: PaginationDto}> | undefined
  ): data is InfiniteData<{data: UserPropertyADto[]; meta: PaginationDto}> => {
    return data !== undefined && (data.pages[0]?.data ?? []).length === 0;
  };

  const appIdsFilters = AppIdSchema.options.filter(option => !APP_IDS_TO_EXCLUDE.includes(option));
  const appFilterOptions = [null, ...appIdsFilters].map(appId => {
    const app = apps.getApp(appId);

    return {
      value: appId === null ? 'custom' : appId,
      label: appId === null ? t('drawer.customProperty') : app?.spec.name ?? appId,
      active:
        activeAppIdFilters.length > 0 ? activeAppIdFilters.includes(appId ?? 'custom') : false,
    };
  });

  const content = match({
    userProperties,
    status,
    isFetching,
  })
    .with(
      {
        userProperties: P.select(P.when(emptyGuard)),
        isFetching: false,
      },
      () => (
        <div className="flex h-full flex-col items-center justify-center rounded-lg bg-slate-50 text-sm font-medium">
          <UserList size={32} className="text-slate-400" />
          {t('drawer.labelEmpty')}
        </div>
      )
    )
    .otherwise(() => {
      // "min-h-0 flex-1 overflow-y-auto" allows a vertical scroll with dynamic height.
      return (
        <div className="no-scrollbar min-h-0 flex-1 overflow-y-auto">
          <ul className="rounded-lg border border-slate-200 bg-slate-50 px-4">
            {userProperties?.pages.map((userProperties, pageIdx) => {
              const uPropertiesToDisplay =
                activeAppIdFilters.length > 0
                  ? userProperties.data.filter(uProp =>
                      activeAppIdFilters.includes(uProp.appId ?? 'custom')
                    )
                  : userProperties.data;

              const sortedUserProperties = sortBy(uPropertiesToDisplay, [
                uProperty => uProperty.appId ?? 'custom',
                'asc',
              ]);

              return (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={pageIdx}>
                  {sortedUserProperties.map(userProperty => (
                    <UserPropertyDetailElement
                      key={userProperty.id}
                      userProperty={userProperty}
                      userAttribute={userAttributes?.find(
                        uAttr => uAttr.userPropertyId === userProperty.id
                      )}
                    />
                  ))}
                </Fragment>
              );
            })}
            <li ref={ref}></li>
          </ul>
          {isFetching && (
            <div className="flex justify-center">
              <Loader size={30} color="gray" />
            </div>
          )}
        </div>
      );
    });

  return (
    <Sheet open onOpenChange={onClose}>
      <SheetContent className="flex h-full flex-col gap-6 p-6">
        <div className="space-y-6">
          <SheetTitle className="text-xl font-semibold">{t('drawer.title')}</SheetTitle>
          <Filters
            filters={[
              {
                label: t('drawer.filterByApp'),
                options: appFilterOptions,
                type: 'multiple',
                icon: <Desktop className="mr-1.5 inline-block h-4 w-4" />,
                onChange: typeOptions => {
                  const activeFilterOptions = typeOptions.filter(o => o.active);
                  setActiveAppIdFilters(
                    activeFilterOptions.map(o => o.value) as Array<AppId | 'custom'>
                  );
                },
              },
            ]}
          />
        </div>

        {content}

        <div className="space-y-6">
          <SheetFooter className="gap-6 sm:flex-col">
            <HighlightText
              type="info"
              text={
                <Trans
                  i18nKey="users.user.overview.page.drawer.helper"
                  ns="pages"
                  components={{
                    pageLink: (
                      <a
                        className="font-medium text-blue-600 underline"
                        href={getUsersRoute().path}
                        onClick={e => {
                          e.preventDefault();
                          navigate(getUsersRoute().path);
                        }}
                      />
                    ),
                  }}
                />
              }
            />
            <div className="flex justify-end">
              <Button form="user-properties-visibility-form" onClick={() => onClose()}>
                {t('drawer.buttonClose')}
              </Button>
            </div>
          </SheetFooter>
        </div>
      </SheetContent>
    </Sheet>
  );
};

const UserOverviewPage: React.FC = (): JSX.Element => {
  const [showUserPropertiesDetailDrawer, setShowUserPropertiesDetailDrawer] = useState(false);
  const {t} = useTranslation('pages', {keyPrefix: 'users.user.overview.page'});

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
  };

  return (
    <AdminPage header={headerConfig} className="space-y-10">
      <SectionSeparator />
      <LinkSection
        title={t('sectionInformationTitle')}
        description={t('sectionInformationSubtitle')}
      />
      <UserInformationSection />
      <SectionSeparator />
      <div className="flex justify-between">
        <LinkSection
          title={t('sectionPropertiesTitle')}
          description={t('sectionPropertiesSubtitle')}
        />
        <Button variant="secondary" onClick={() => setShowUserPropertiesDetailDrawer(true)}>
          <ListMagnifyingGlass size={20} className="mr-2 text-slate-600" /> {t('showAllProperties')}
        </Button>
      </div>
      <UserPropertiesSection />
      {showUserPropertiesDetailDrawer && (
        <UserPropertiesDetailDrawer onClose={() => setShowUserPropertiesDetailDrawer(false)} />
      )}
    </AdminPage>
  );
};

export default UserOverviewPage;
