import type {
  CreateNotificationConfigurationADto,
  DeleteNotificationConfigurationsParamsADto,
  NotificationConfigurationADto,
  PatchNotificationConfigurationADto,
} from '@cohort/admin-schemas/notification';
import {NotificationConfigurationASchema} from '@cohort/admin-schemas/notification';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import {z} from 'zod';

export const getNotificationConfigurations = async (
  merchantId: string
): Promise<NotificationConfigurationADto[]> => {
  return apiCall('GET', `/v1/merchants/${merchantId}/notification-configurations`, {
    expect: HttpCodes.SUCCESS,
    parser: z.array(NotificationConfigurationASchema).parse,
  });
};

export const createNotificationConfiguration = async (
  merchantId: string,
  data: CreateNotificationConfigurationADto
): Promise<NotificationConfigurationADto> => {
  return apiCall('POST', `/v1/merchants/${merchantId}/notification-configurations`, {
    expect: HttpCodes.CREATED,
    parser: NotificationConfigurationASchema.parse,
    body: {...data},
  });
};

export const patchNotificationConfiguration = async (
  merchantId: string,
  notificationConfigurationId: string,
  data: PatchNotificationConfigurationADto
): Promise<NotificationConfigurationADto> => {
  return apiCall(
    'PATCH',
    `/v1/merchants/${merchantId}/notification-configurations/${notificationConfigurationId}`,
    {
      expect: HttpCodes.SUCCESS,
      parser: NotificationConfigurationASchema.parse,
      body: {...data},
    }
  );
};

export const deleteNotificationConfiguration = async (
  merchantId: string,
  notificationConfigurationId: string
): Promise<{}> => {
  return apiCall(
    'DELETE',
    `/v1/merchants/${merchantId}/notification-configurations/${notificationConfigurationId}`,
    {
      expect: HttpCodes.SUCCESS,
      parser: () => ({}),
    }
  );
};

export const deleteNotificationConfigurations = async (
  merchantId: string,
  params: DeleteNotificationConfigurationsParamsADto
): Promise<{}> => {
  return apiCall('DELETE', `/v1/merchants/${merchantId}/notification-configurations`, {
    expect: HttpCodes.SUCCESS,
    parser: () => ({}),
    params,
  });
};
