import type {UserCohortADto} from '@cohort/admin-schemas/userAnalytics';
import {CohortTypeBadge} from '@cohort/merchants/components/cohorts/CohortTypeBadge';
import {DataTable} from '@cohort/merchants/components/tables/DataTable';
import {useCohortTable} from '@cohort/merchants/hooks/table/table';
import {getCohortMembersRoute} from '@cohort/merchants/lib/Pages';
import RemoveUserFromCohort from '@cohort/merchants/pages/users/user/cohorts/RemoveUserFromCohort';
import {formatDate} from '@cohort/shared/utils/format';
import {createColumnHelper} from '@tanstack/react-table';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

type UserCohortsTableProps = {
  isLoading: boolean;
  cohorts: UserCohortADto[];
};

const UserCohortsTable: React.FC<UserCohortsTableProps> = ({isLoading, cohorts}) => {
  const navigate = useNavigate();
  const {t} = useTranslation('pages', {
    keyPrefix: 'users.user.cohorts.userCohortsTable',
  });
  const columnHelper = createColumnHelper<UserCohortADto>();
  const columns = [
    columnHelper.accessor('name', {
      header: t('tableHeaderName'),
      cell: data => <span className="font-medium text-foreground">{data.getValue()}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor('type', {
      header: t('tableHeaderType'),
      cell: data => <CohortTypeBadge type={data.getValue()} />,
      enableSorting: false,
    }),
    columnHelper.accessor(row => formatDate(row.memberSince), {
      id: 'memberSince',
      header: t('tableHeaderMemberSince'),
      enableSorting: false,
    }),
    columnHelper.accessor(row => formatDate(row.createdAt), {
      id: 'createdAt',
      header: t('tableHeaderCreated'),
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => null,
      cell: data =>
        data.row.original.type === 'static' ? (
          <div className="text-right">
            <RemoveUserFromCohort cohort={data.row.original} />
          </div>
        ) : null,
    }),
  ];
  const table = useCohortTable({
    columns,
    data: cohorts,
  });

  return (
    <DataTable
      table={table}
      columnsLength={columns.length}
      isLoading={isLoading}
      emptyStatePlaceholder={t('emptyPlaceholder')}
      onRowClick={cohort => navigate(getCohortMembersRoute(cohort.id).path)}
    />
  );
};
export default UserCohortsTable;
