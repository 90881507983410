import Button from '@cohort/merchants/components/buttons/Button';
import Input from '@cohort/merchants/components/form/input/Input';
import PasswordInput from '@cohort/merchants/components/form/input/PasswordInput';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {notify} from '@cohort/merchants/hooks/toast';
import {loginWithEmailAndPassword} from '@cohort/merchants/lib/Firebase';
import {getForgotPasswordRoute} from '@cohort/merchants/lib/Pages';
import {trackError, trackSigninError, trackSigninSuccess} from '@cohort/merchants/lib/Tracking';
import {EmailSchema} from '@cohort/shared/schema/common';
import {zodResolver} from '@hookform/resolvers/zod';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {match} from 'ts-pattern';
import {z} from 'zod';

const ExpectedErrorSchema = z.object({
  code: z.enum([
    'auth/invalid-login-credentials',
    'auth/too-many-requests',
    'auth/user-not-found',
    'auth/wrong-password',
  ]),
});

type EmailPasswordFormProps = {
  onUserLoggedIn: () => void;
  authTenantId: string;
};

const FormInputSchema = z.object({
  email: EmailSchema,
  password: z.string().min(6),
});
type FormInput = z.infer<typeof FormInputSchema>;

type Location = {
  state?: {
    email?: string;
    passwordReset?: boolean;
  };
};

const EmailPasswordForm: React.FC<EmailPasswordFormProps> = ({onUserLoggedIn, authTenantId}) => {
  const location = useLocation() as Location;

  const {t} = useTranslation('pages', {keyPrefix: 'sign-in.emailPasswordForm'});
  const navigate = useNavigate();

  const {register, control, handleSubmit, formState, watch} = useForm<FormInput>({
    defaultValues: {
      email: location.state?.email ?? '',
      password: '',
    },
    mode: 'onBlur',
    resolver: zodResolver(FormInputSchema),
  });

  const email = watch('email');

  const {mutate: handleEmailPasswordSignin, isLoading} = useCohortMutation({
    mutationFn: async ({email, password}: FormInput) => {
      await loginWithEmailAndPassword(email, password, authTenantId);
    },
    onSuccess: async () => {
      trackSigninSuccess({signinMethod: 'emailPassword'});
      onUserLoggedIn();
    },
    onError: err => {
      let message;
      const parsed = ExpectedErrorSchema.safeParse(err);
      if (parsed.success) {
        message = match(parsed.data.code)
          .with('auth/too-many-requests', () => t('errorTooManyRequests'))
          .otherwise(() => t('errorInvalidEmailOrPassword'));
      } else {
        message = t('errorUnexpected');
        trackSigninError({signinMethod: 'emailPassword'});
        trackError(err);
      }
      notify('error', message);
    },
  });

  return (
    <form
      className="flex flex-col space-y-4"
      onSubmit={handleSubmit(data => handleEmailPasswordSignin(data))}
    >
      {location.state?.passwordReset && (
        <HighlightText type="success" text={t('highlightPasswordReset')} />
      )}
      <Input
        label={t('labelEmail')}
        placeholder="vitalik@getcohort.com"
        type="text"
        name="email"
        register={register}
        control={control}
        disabled={isLoading}
        hideError
      />
      <div className="flex flex-col space-y-2">
        <PasswordInput
          register={register}
          control={control}
          name="password"
          disabled={isLoading}
          hideError
        />
        <div className="flex flex-row justify-end">
          <Button
            variant="link"
            className="inline h-full p-0 text-end text-sm"
            onClick={() => navigate(getForgotPasswordRoute().path, {state: {email}})}
          >
            {t('buttonForgotPassword')}
          </Button>
        </div>
      </div>

      <Button
        type="submit"
        className="h-11 w-full"
        loading={isLoading}
        disabled={!formState.isValid}
      >
        {t('buttonContinue')}
      </Button>
    </form>
  );
};
export default EmailPasswordForm;
