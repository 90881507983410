// i18nOwl-ignore [app-tiktok.description]
import type {App, TriggerIntegration} from '@cohort/merchants/apps';
import {createConnectAccountTriggerIntegration} from '@cohort/merchants/apps/common/triggers/ConnectAccountTriggerIntegration';
import translationEn from '@cohort/merchants/apps/tiktok/locales/en.json';
import translationFr from '@cohort/merchants/apps/tiktok/locales/fr.json';
import type {TikTokAppStruct} from '@cohort/shared/apps/tiktok';
import {TikTokAppSpec} from '@cohort/shared/apps/tiktok';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

const TikTokApp: App<TikTokAppStruct> = {
  spec: TikTokAppSpec,
  ConnectionEditComponent: null,
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="tiktok" height={40} width={40} />,
  notificationIntegrations: [],
  perkIntegrations: [],
  triggerIntegrations: [
    createConnectAccountTriggerIntegration(TikTokAppSpec) as TriggerIntegration,
  ],
  userEvents: [],
};

export default TikTokApp;
