import type {CustomTermsADto} from '@cohort/admin-schemas/customTerms';
import {CustomTermsASchema} from '@cohort/admin-schemas/customTerms';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';

export async function postCustomTerms(
  merchantId: string,
  data: CustomTermsADto
): Promise<CustomTermsADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/custom-terms`, {
    expect: HttpCodes.CREATED,
    body: data,
    parser: CustomTermsASchema.parse,
  });
}

export async function getCustomTerms(merchantId: string): Promise<CustomTermsADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/custom-terms`, {
    expect: HttpCodes.SUCCESS,
    parser: CustomTermsASchema.parse,
  });
}
