import FileInput from '@cohort/merchants/components/form/FileInput';
import LocalizedInput from '@cohort/merchants/components/form/input/LocalizedInput';
import LocalizedTextEditorInput from '@cohort/merchants/components/form/textEditor/LocalizedTextEditorInput';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {CampaignSettingsStepValues} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import {AllowedAssetMimeTypes} from '@cohort/shared/schema/common/assets';
import {Fragment} from 'react';
import type {Control, UseFormRegister} from 'react-hook-form';
import {useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type JourneyAppearanceSectionProps = {
  control: Control<CampaignSettingsStepValues>;
  register: UseFormRegister<CampaignSettingsStepValues>;
};

const JourneyAppearanceSection: React.FC<JourneyAppearanceSectionProps> = ({control, register}) => {
  const merchant = useCurrentMerchant();

  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.settings.journey.journeyAppearanceSection',
  });

  const selectedLanguage = useWatch({
    control,
    name: 'selectedLanguage',
  });

  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  if (selectedLanguage === undefined) {
    return <Fragment />;
  }
  return (
    <Fragment>
      <div className="flex gap-4">
        <LocalizedInput
          type="text"
          name="journey.title"
          label={t('labelTitle')}
          placeholder={t('placeholderTitle', {merchantName: merchant.name})}
          register={register}
          control={control}
          selectedLanguage={selectedLanguage}
          optional={!isDefaultLanguageSelected}
        />
        <LocalizedInput
          type="text"
          name="journey.optInCta"
          label={t('labeloptInCta')}
          placeholder={t('placeholderOptInCta')}
          register={register}
          control={control}
          selectedLanguage={selectedLanguage}
          optional={!isDefaultLanguageSelected}
        />
      </div>
      <LocalizedTextEditorInput
        label={t('labelDescription')}
        name="journey.description"
        register={register}
        control={control}
        selectedLanguage={selectedLanguage}
        placeholder={t('placeholderDescription')}
        optional={!isDefaultLanguageSelected}
      />

      {isDefaultLanguageSelected && (
        <FileInput
          label={t('labelVisual')}
          name="journey.imageFileKey"
          register={register}
          control={control}
          acceptHint={t('instructionVisual')}
          accept={AllowedAssetMimeTypes.journeyVisual.options.join(',')}
          assetKind="journeyVisual"
          description={t('helperVisual')}
          withResize
        />
      )}
    </Fragment>
  );
};

export default JourneyAppearanceSection;
