import BaseReferenceComponent from '@cohort/merchants/components/references/BaseReferenceComponent';
import {getUserRoute} from '@cohort/merchants/lib/Pages';

type UserReferenceComponentProps = {
  user: {
    id: string;
    email: string;
  };
};

const UserReferenceComponent: React.FC<UserReferenceComponentProps> = ({user}) => (
  <BaseReferenceComponent title={user.email} redirectPath={getUserRoute(user.id).path} />
);
export default UserReferenceComponent;
