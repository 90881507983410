import {
  LoadingCardOverlay,
  OwnedCardOverlay,
} from '@cohort/components-xps/components/cards/CardOverlays';
import CustomComponentCard from '@cohort/components-xps/components/cards/CustomComponentCard';
import {DigitalAssetVisual} from '@cohort/components-xps/components/DigitalAssetVisual';
import i18nComponentsXpsInstance from '@cohort/components-xps/lib/i18n';
import type {DigitalAssetContextTDto} from '@cohort/shared/schema/templating/digitalAsset';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import React from 'react';
import {getI18n, useTranslation} from 'react-i18next';

export type DigitalAssetCardProps = {
  digitalAsset: {
    animationFileKey: string | null;
    imageFileKey: string | null;
    collection: {
      title: string;
    };
    nft?: {
      blockExplorerUrl: string | null;
    };
    tokenId: number | null;
  };
  onClick?: () => void;
  customComponent?: {
    context: DigitalAssetContextTDto;
    template: string;
  };
  canBeObtained?: boolean;
  transferring?: boolean;
};

export const DigitalAssetCard: React.FC<DigitalAssetCardProps> = ({
  digitalAsset,
  onClick,
  customComponent,
  canBeObtained = true,
  transferring = false,
}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'digitalAssetVisual',
    lng: getI18n().language,
    i18n: i18nComponentsXpsInstance,
  });

  return (
    <div
      onClick={onClick}
      className={cn(
        'relative h-full',
        transferring || !onClick ? 'pointer-events-none' : 'cursor-pointer'
      )}
    >
      {customComponent ? (
        <CustomComponentCard
          context={customComponent.context}
          template={customComponent.template}
        />
      ) : (
        <DigitalAssetVisual
          tokenId={digitalAsset.tokenId}
          title={digitalAsset.collection.title}
          blockExplorerUrl={digitalAsset.nft?.blockExplorerUrl}
          digitalAssetImageFileKey={digitalAsset.imageFileKey}
          animationFileKey={digitalAsset.animationFileKey}
        />
      )}
      {transferring && <LoadingCardOverlay />}
      {!canBeObtained && <OwnedCardOverlay text={t('alreadyOwned')} />}
    </div>
  );
};
