import Button from '@cohort/merchants/components/buttons/Button';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import type {
  CriteronType,
  DynamicCohortRuleType,
} from '@cohort/merchants/pages/users//cohort/rule/utils';
import {getDefaultCriteriaProps} from '@cohort/merchants/pages/users//cohort/rule/utils';
import CohortMembershipCriteria from '@cohort/merchants/pages/users/cohort/rule/criteria/CohortMembershipCriteria';
import UserEventCriteria from '@cohort/merchants/pages/users/cohort/rule/criteria/UserEventCriteria';
import UserPropertyCriteria from '@cohort/merchants/pages/users/cohort/rule/criteria/UserPropertyCriteria';
import {PlusCircle, Trash} from '@phosphor-icons/react';
import React, {Fragment} from 'react';
import type {Control, UseFormRegister} from 'react-hook-form';
import {useController, useFieldArray, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type DynamicCohortGroupFormProps = {
  control: Control<DynamicCohortRuleType>;
  register: UseFormRegister<DynamicCohortRuleType>;
};

type DynamicCohortCriteriaFormProps = {
  control: Control<DynamicCohortRuleType>;
  register: UseFormRegister<DynamicCohortRuleType>;
  groupIndex: number;
  removeGroup: () => void;
};

type AddButtonProps = {
  text: string;
  onClick: () => void;
};

type SectionSeparatorProps = {
  text: string;
};

type CriteriaFormProps = {
  control: Control<DynamicCohortRuleType>;
  register: UseFormRegister<DynamicCohortRuleType>;
  groupIndex: number;
  criteriaIndex: number;
};

const AddButton: React.FC<AddButtonProps> = ({text, onClick}) => (
  <Button variant="ghost" onClick={onClick}>
    <PlusCircle size={20} className="mr-2" />
    {text}
  </Button>
);

const SectionSeparator: React.FC<SectionSeparatorProps> = ({text}) => (
  <li className="inline-block rounded-md border border-primary bg-primary/5 px-2 py-1 font-semibold uppercase text-primary">
    {text}
  </li>
);

const CriteriaForm: React.FC<CriteriaFormProps> = ({
  control,
  register,
  groupIndex,
  criteriaIndex,
}) => {
  const criteria = useWatch({
    control,
    name: `groups.${groupIndex}.criteria.${criteriaIndex}`,
  });

  if (criteria.type === null) {
    return null;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getCriteriaFormComponent = () => {
    switch (criteria.type) {
      case 'user-property':
        return UserPropertyCriteria;
      case 'user-event':
        return UserEventCriteria;
      case 'cohort-membership':
        return CohortMembershipCriteria;
    }
  };
  const CriteriaFormComponent = getCriteriaFormComponent();

  return (
    <CriteriaFormComponent
      control={control}
      register={register}
      groupIndex={groupIndex}
      criteriaIndex={criteriaIndex}
    />
  );
};

const DynamicCohortCriteriaTypeSelect: React.FC<CriteriaFormProps> = ({
  control,
  register,
  groupIndex,
  criteriaIndex,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'users.cohort.rule.dynamicCohortGroupForm',
  });
  const {field: criteria} = useController({
    name: `groups.${groupIndex}.criteria.${criteriaIndex}`,
    control,
  });

  return (
    <SelectInput
      name={`groups.${groupIndex}.criteria.${criteriaIndex}.type`}
      placeholder={t('criteriaPlaceholder')}
      register={register}
      control={control}
      options={[
        {value: 'user-property', label: t('userPropertyCriteria')},
        {value: 'user-event', label: t('userEventCriteria')},
        {value: 'cohort-membership', label: t('cohortMembershipCriteria')},
      ]}
      onChange={input => {
        if (input) {
          // reset the criteria when the type is changed
          criteria.onChange({
            id: null,
            type: input.value,
            ...getDefaultCriteriaProps(input.value as CriteronType['type']),
          });
        }
      }}
    />
  );
};

const DynamicCohortCriteriaForm: React.FC<DynamicCohortCriteriaFormProps> = ({
  control,
  register,
  groupIndex,
  removeGroup,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'users.cohort.rule.dynamicCohortGroupForm',
  });
  const {fields, append, remove} = useFieldArray({
    name: `groups.${groupIndex}.criteria`,
    control,
    keyName: '_id',
  });

  return (
    <ul className="space-y-4">
      {fields.map((criteria, criteriaIndex) => (
        <Fragment key={criteria._id}>
          {criteriaIndex > 0 && <SectionSeparator text={t('andSeparator')} />}
          <li className="flex flex-col gap-4 rounded-md border bg-slate-50 p-4">
            <div className="flex items-center justify-between">
              <div className="w-[350px]">
                <DynamicCohortCriteriaTypeSelect
                  control={control}
                  register={register}
                  groupIndex={groupIndex}
                  criteriaIndex={criteriaIndex}
                />
              </div>

              <Button
                variant="secondary"
                size="xs"
                onClick={() => {
                  remove(criteriaIndex);
                  if (fields.length === 1) {
                    removeGroup();
                  }
                }}
              >
                <Trash size={16} className="mr-2" />
                {t('removeCriteriaBtn')}
              </Button>
            </div>
            <CriteriaForm
              control={control}
              register={register}
              groupIndex={groupIndex}
              criteriaIndex={criteriaIndex}
            />
          </li>
        </Fragment>
      ))}
      <AddButton text={t('addCriteriaBtn')} onClick={() => append({id: null, type: null})} />
    </ul>
  );
};

const DynamicCohortGroupForm: React.FC<DynamicCohortGroupFormProps> = ({control, register}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'users.cohort.rule.dynamicCohortGroupForm',
  });
  const {fields, append, remove} = useFieldArray({
    name: 'groups',
    control,
    keyName: '_id',
  });

  return (
    <ul className="space-y-4">
      {fields.map((group, index) => (
        <Fragment key={group._id}>
          {index > 0 && <SectionSeparator text={t('orSeparator')} />}
          <li className="rounded-md border p-4">
            <DynamicCohortCriteriaForm
              control={control}
              register={register}
              groupIndex={index}
              removeGroup={() => remove(index)}
            />
          </li>
        </Fragment>
      ))}
      <AddButton
        text={t('addGroupBtn')}
        onClick={() =>
          append({
            id: null,
            criteria: [
              {
                id: null,
                type: null,
              },
            ],
          })
        }
      />
    </ul>
  );
};

export default DynamicCohortGroupForm;
