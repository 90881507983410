import type {
  CustomComponentADto,
  CustomComponentListADto,
} from '@cohort/admin-schemas/customComponent';
import type {MerchantADto} from '@cohort/admin-schemas/merchant';
import type {CustomComponentType} from '@cohort/shared/schema/common/customComponents';
import type {UserTDto} from '@cohort/shared/schema/templating/common';
import {UserTSchema} from '@cohort/shared/schema/templating/common';
import {makeUserContext} from '@cohort/shared/utils/templating';
import {generateMockObjectFromSchema} from '@cohort/shared/utils/zod';

export function getCustomComponent(
  customComponents: CustomComponentListADto | undefined,
  type: CustomComponentType,
  enabled?: boolean
): CustomComponentADto | undefined {
  if (!customComponents) {
    return;
  }
  const enabledFilter = (customComponent: CustomComponentADto): boolean => {
    if (enabled === undefined) {
      return true;
    }
    return customComponent.enabled === enabled;
  };

  return customComponents.find(
    customComponent => customComponent.type === type && enabledFilter(customComponent)
  );
}

export function getUserContext(merchant: MerchantADto): ReturnType<typeof makeUserContext> {
  const user = generateMockObjectFromSchema(UserTSchema) as UserTDto;

  return makeUserContext(merchant, {
    ...user,
    membershipPass: user.membership,
    wallet: {
      address: user.walletAddress,
    },
  });
}
