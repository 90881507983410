import type {UserEvent} from '@cohort/merchants/apps';
import {getUserEvents} from '@cohort/merchants/apps';
import type {UserEventStruct} from '@cohort/shared/apps/userEvent';
import {useTranslation} from 'react-i18next';

export type Output<UE extends UserEventStruct> = {
  getUserEvent: (userEventId: UE['Id']) => UserEvent<UE>;
  getUserEventTitle: (userEventId: UE['Id']) => string;
};

export const useUserEventApps = <UE extends UserEventStruct>(): Output<UE> => {
  const {t} = useTranslation();

  const getUserEvent = (userEventId: UE['Id']): UserEvent<UE> => {
    const userEvent = getUserEvents().find(integration => integration.spec.id === userEventId);
    if (!userEvent) {
      throw new Error(`No UserEvent found for userEventId: ${userEventId}`);
    }
    return userEvent as unknown as UserEvent<UE>;
  };

  const getUserEventTitle = (userEventId: UE['Id']): string => {
    const userEvent = getUserEvent(userEventId);
    const [appId, eventId, action] = userEvent.spec.id.split('.');
    return t(`userEvents.${eventId}.${action}.title`, {
      ns: `app-${appId}`,
    });
  };

  return {
    getUserEvent,
    getUserEventTitle,
  };
};
