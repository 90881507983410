import type {UpdateStripeConnectAccountPayoutScheduleDataADto} from '@cohort/admin-schemas/stripe';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

export type IntervalOption = {
  value: UpdateStripeConnectAccountPayoutScheduleDataADto['interval'];
  label: string;
};

export type DayOfWeekOption = {
  value: Exclude<UpdateStripeConnectAccountPayoutScheduleDataADto['dayOfWeek'], null>;
  label: string;
};

function useIntervalOptions(): IntervalOption[] {
  const {t} = useTranslation('dates');

  return useMemo(
    () => [
      {value: 'daily', label: t('intervals.daily')},
      {value: 'weekly', label: t('intervals.weekly')},
      {value: 'monthly', label: t('intervals.monthly')},
    ],
    [t]
  );
}

function useDaysOfWeekOptions(): DayOfWeekOption[] {
  const {t} = useTranslation('dates');

  return useMemo(
    () => [
      {value: 'monday', label: t('daysOfWeek.monday')},
      {value: 'tuesday', label: t('daysOfWeek.tuesday')},
      {value: 'wednesday', label: t('daysOfWeek.wednesday')},
      {value: 'thursday', label: t('daysOfWeek.thursday')},
      {value: 'friday', label: t('daysOfWeek.friday')},
      {value: 'saturday', label: t('daysOfWeek.saturday')},
      {value: 'sunday', label: t('daysOfWeek.sunday')},
    ],
    [t]
  );
}

export {useIntervalOptions, useDaysOfWeekOptions};
