import type {
  CohortADto,
  CohortUserADto,
  CohortUsersExportParamsADto,
  CohortWithBadgeADto,
  CreateCohortADto,
  GetCohortsParamsADto,
  GetCohortUsersParamsADto,
  UpdateCohortADto,
} from '@cohort/admin-schemas/cohort';
import {
  CohortASchema,
  CohortUserASchema,
  CohortWithBadgeASchema,
} from '@cohort/admin-schemas/cohort';
import type {RuleADto, RuleInputADto} from '@cohort/admin-schemas/rule';
import {RuleASchema} from '@cohort/admin-schemas/rule';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import type {ExportDto} from '@cohort/shared/schema/common/export';
import {ExportSchema} from '@cohort/shared/schema/common/export';
import type {PaginationDto} from '@cohort/shared/schema/common/pagination';
import {paginatedParser} from '@cohort/shared/utils/parser';

export async function getCohorts(
  merchantId: string,
  params: GetCohortsParamsADto
): Promise<[PaginationDto, CohortWithBadgeADto[]]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/cohorts`, {
    expect: HttpCodes.SUCCESS,
    parser: paginatedParser(CohortWithBadgeASchema),
    params,
  });
}

export async function getCohortById(
  merchantId: string,
  cohortId: string
): Promise<CohortWithBadgeADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/cohorts/${cohortId}`, {
    expect: HttpCodes.SUCCESS,
    parser: CohortWithBadgeASchema.parse,
  });
}

export async function createCohort(
  merchantId: string,
  data: CreateCohortADto
): Promise<CohortADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/cohorts`, {
    expect: HttpCodes.CREATED,
    parser: CohortASchema.parse,
    body: data,
  });
}

export async function patchCohort(
  merchantId: string,
  cohortId: string,
  data: UpdateCohortADto
): Promise<CohortADto> {
  return apiCall('PATCH', `/v1/merchants/${merchantId}/cohorts/${cohortId}`, {
    expect: HttpCodes.SUCCESS,
    parser: CohortASchema.parse,
    body: data,
  });
}

export async function deleteCohort(merchantId: string, cohortId: string): Promise<{}> {
  return apiCall('DELETE', `/v1/merchants/${merchantId}/cohorts/${cohortId}`, {
    expect: HttpCodes.SUCCESS,
    parser: () => ({}),
  });
}

export async function getCohortUsers(
  merchantId: string,
  cohortId: string,
  params: GetCohortUsersParamsADto
): Promise<[PaginationDto, CohortUserADto[]]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/cohorts/${cohortId}/users`, {
    expect: HttpCodes.SUCCESS,
    parser: paginatedParser(CohortUserASchema),
    params,
  });
}

export async function deleteCohortUser(
  merchantId: string,
  cohortId: string,
  userId: string
): Promise<{}> {
  return apiCall('DELETE', `/v1/merchants/${merchantId}/cohorts/${cohortId}/users/${userId}`, {
    expect: HttpCodes.SUCCESS,
    parser: () => ({}),
  });
}

export async function exportCohortUsers(
  merchantId: string,
  cohortId: string,
  params: CohortUsersExportParamsADto
): Promise<ExportDto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/cohorts/${cohortId}/users/export`, {
    expect: HttpCodes.CREATED,
    parser: ExportSchema.parse,
    params,
  });
}

export async function getCohortRule(merchantId: string, cohortId: string): Promise<RuleADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/cohorts/${cohortId}/rule`, {
    expect: HttpCodes.SUCCESS,
    parser: RuleASchema.parse,
  });
}

export async function upsertCohortRule(
  merchantId: string,
  cohortId: string,
  data: RuleInputADto
): Promise<RuleADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/cohorts/${cohortId}/rule`, {
    expect: HttpCodes.CREATED,
    parser: RuleASchema.parse,
    body: data,
  });
}
