import type {
  Language,
  LocalizedRichText,
  LocalizedString,
  WebappLanguage,
} from '@cohort/shared/schema/common';
import {LanguageSchema} from '@cohort/shared/schema/common';
import type {LocalizedFaqs} from '@cohort/shared/schema/common/campaign';
import type {CohortFormOption, CohortFormQuestion} from '@cohort/shared/schema/common/cohortForm';
import type {FieldErrors} from 'react-hook-form';
import {isEmpty, pick} from 'remeda';
import {ZodError} from 'zod';

export function getDefinedLanguages(
  defaultLanguage: Language,
  localizedContentList?: Array<LocalizedString | LocalizedFaqs>
): Array<Language> {
  const definedLanguages = new Array<Language>(defaultLanguage);
  if (localizedContentList === undefined) {
    return definedLanguages;
  }
  for (const localizedContent of localizedContentList) {
    for (const language in localizedContent) {
      if (
        !definedLanguages.includes(language as Language) &&
        localizedContent[language as Language] !== null
      ) {
        definedLanguages.push(language as Language);
      }
    }
  }
  return definedLanguages;
}

export function removeUndefinedLanguages<T extends LocalizedString | LocalizedFaqs | undefined>(
  localizedContent: T,
  definedLanguages?: Array<Language>
): T {
  if (localizedContent === undefined || definedLanguages === undefined) {
    return localizedContent;
  }

  return pick(localizedContent, definedLanguages) as T;
}

export function removeEmptyLanguages(localizedContent: LocalizedFaqs): LocalizedFaqs {
  const data = {...localizedContent};
  for (const language of Object.keys(LanguageSchema.Enum)) {
    if (data[language as Language]?.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete data[language as Language];
    }
  }
  return data;
}

function findLanguageInErrors(
  fieldErrors: FieldErrors,
  highlightedLanguage: Language
): Language | null {
  let languageWithErrors: Language | null = null;
  for (const fieldError in fieldErrors) {
    // for localized fields, errors are in 'en' or 'fr' fields
    const errorKeys = Object.keys({...fieldErrors[fieldError]});
    for (const errorKey of errorKeys) {
      const language = LanguageSchema.safeParse(errorKey);
      if (language.success) {
        if (language.data === highlightedLanguage) {
          return language.data;
        } else {
          languageWithErrors = language.data;
        }
      }
    }
  }
  return languageWithErrors;
}

const findLanguageInZodErrors = (errors: ZodError, highlightedLanguage: Language): Language => {
  const languagesWithError = new Set<Language>();

  try {
    for (const error of errors.issues) {
      const language = LanguageSchema.parse(error.path[error.path.length - 1]);
      languagesWithError.add(language);
    }

    if (languagesWithError.has(highlightedLanguage)) {
      return highlightedLanguage;
    } else {
      const [lang] = languagesWithError;
      return lang as Language; // We can safely cast it to Language as we parse it with LanguageSchema.
    }
  } catch {
    return highlightedLanguage;
  }
};

export function changeSelectedLanguageIfNeeded<T extends Language | WebappLanguage | null>(
  errors: FieldErrors | ZodError,
  merchantDefaultLanguage: NonNullable<T>,
  setSelectedLanguage: (language: NonNullable<T>) => void
): void {
  let languageWithErrors: T | null = null;
  if (errors instanceof ZodError) {
    languageWithErrors = findLanguageInZodErrors(errors, merchantDefaultLanguage) as T;
  } else {
    languageWithErrors = findLanguageInErrors(errors, merchantDefaultLanguage) as T;
  }
  // if there is no 'fr' or 'en' fields in errors, navigate to default language where the non-localized fields are
  if (languageWithErrors !== null) {
    setSelectedLanguage(languageWithErrors);
  } else {
    setSelectedLanguage(merchantDefaultLanguage);
  }
}

export const isDefaultTranslationDefined = (
  localizedString: LocalizedString | LocalizedRichText,
  defaultLanguage: Language
): boolean =>
  !isEmpty(localizedString) &&
  localizedString[defaultLanguage] !== undefined &&
  localizedString[defaultLanguage] !== null;

// If the translation in default language is not defined but it is defined in another language, we throw an error.
export const isDefaultLanguageTranslationConsistent = (
  localizedString: LocalizedString,
  defaultLanguage: Language
): boolean => {
  return isEmpty(localizedString) || isDefaultTranslationDefined(localizedString, defaultLanguage);
};

export const sortLanguages = (
  languages: Array<Language>,
  getLanguageTranslation: (language: Language) => string,
  defaultLanguage?: Language
): Array<Language> => {
  return [
    ...languages.sort((a: Language, b: Language) => {
      if (a === defaultLanguage) {
        return -1;
      }
      if (b === defaultLanguage) {
        return 1;
      }
      return getLanguageTranslation(a).localeCompare(getLanguageTranslation(b));
    }),
  ];
};

export const removeLanguagesFromQuestions = (
  questions: Array<CohortFormQuestion>,
  definedLanguages: Array<Language>
): Array<CohortFormQuestion> => {
  return questions.map(question => {
    const options: Array<CohortFormOption> | null =
      question.options?.map(option => {
        return {
          ...option,
          label: removeUndefinedLanguages(option.label, definedLanguages),
        };
      }) ?? null;
    const name = removeUndefinedLanguages(question.name, definedLanguages);
    return {
      ...question,
      name,
      options,
    };
  });
};
