import {
  isDefaultTranslationDefined,
  removeUndefinedLanguages,
} from '@cohort/merchants/lib/form/localization';
import {TriggerIntegrationIdSchema} from '@cohort/shared/apps';
import {
  LanguageSchema,
  LocalizedRichTextSchema,
  LocalizedStringSchema,
} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const StepTriggerSchema = z.object({
  id: z.string().uuid().nullable(),
  connectionId: z.string().uuid().nullable(),
  withMaxVerificationAttempts: z.boolean().optional(),
  maxVerificationAttempts: z.number().nullable(),
  triggerIntegrationId: TriggerIntegrationIdSchema,
  triggerIntegrationConfig: z.any(),
  title: LocalizedStringSchema.optional(),
});
export type StepTriggerType = z.infer<typeof StepTriggerSchema>;

export const StepTriggerFormSchema = StepTriggerSchema.extend({
  selectedLanguage: LanguageSchema,
  definedLanguages: z.array(LanguageSchema),
});
export type StepTriggerFormType = z.infer<typeof StepTriggerFormSchema>;

export const StepTriggerConnectionRequiredSchema = z.object({
  connectionId: z.string().uuid(),
});

export const CreateEditStepModalSchema = z
  .object({
    id: z.string().uuid().nullable(),
    title: LocalizedStringSchema,
    description: LocalizedRichTextSchema,
    icon: z.string().emoji(),
    defaultLanguage: LanguageSchema,
    selectedLanguage: LanguageSchema,
    definedLanguages: z.array(LanguageSchema),
    triggers: z.preprocess(arg => {
      // Filter all triggers that are default empty triggers
      const preprocessedTriggers = arg as Array<Partial<StepTriggerType>>;

      return preprocessedTriggers.filter(trigger => trigger.triggerIntegrationId);
    }, z.array(StepTriggerSchema)),
    orchestration: z.object({
      withStartDate: z.boolean(),
      startDate: z.date({coerce: true}).optional(),
      withPreRequiredSteps: z.boolean(),
      preRequiredSteps: z.array(z.string().uuid()).min(1, 'errorRequired').optional(),
    }),
  })
  .superRefine(({title, description, defaultLanguage}, ctx) => {
    if (!isDefaultTranslationDefined(title, defaultLanguage)) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorRequired',
        path: ['title'],
      });
    }
    if (!isDefaultTranslationDefined(description, defaultLanguage)) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorRequired',
        path: ['description'],
      });
    }
  })
  .transform(data => {
    data.title = removeUndefinedLanguages(data.title, data.definedLanguages);
    data.description = removeUndefinedLanguages(data.description, data.definedLanguages);
    return data;
  });

export type CreateEditStepModalType = z.infer<typeof CreateEditStepModalSchema>;

export const defaultEmptyTrigger = {
  id: null,
  triggerIntegrationId: undefined,
  triggerIntegrationConfig: undefined,
  connectionId: null,
  withMaxVerificationAttempts: false,
  maxVerificationAttempts: null,
  // we force the type here for default step creation
} as unknown as StepTriggerType;
