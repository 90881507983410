import {create} from 'zustand';
import {persist} from 'zustand/middleware';

type DigitalAssetCollectionsDisplay = 'card' | 'list';

interface DigitalAssetCollectionsDisplayStore {
  display: DigitalAssetCollectionsDisplay;
  setDisplay: (display: DigitalAssetCollectionsDisplay) => void;
}

export const useDigitalAssetCollectionsDisplay = create<DigitalAssetCollectionsDisplayStore>()(
  persist(
    set => ({
      display: 'card',
      setDisplay: display => set({display}),
    }),
    {
      name: 'digital-asset-collections-display',
    }
  )
);
