type KPICardProps = {
  icon: React.ReactNode;
  text: React.ReactNode;
  value: React.ReactNode;
};

const KPICard: React.FC<KPICardProps> = ({icon, text, value}) => (
  <div className="flex items-center justify-between space-x-4 rounded-xl border px-4 py-3">
    <div className="flex items-center gap-2">
      {icon}
      <span className="text-sm font-medium text-slate-500">{text}</span>
    </div>
    <div className="text-sm font-semibold text-slate-700">{value}</div>
  </div>
);

export default KPICard;
