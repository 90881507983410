import Button from '@cohort/merchants/components/buttons/Button';
import React from 'react';

type LinkSectionButton = {
  color?: 'primary' | 'secondary';
  label: string;
  url?: string;
  icon?: React.ReactNode;
  handleOnClick?: () => void;
  isSubmit?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  testId?: string;
};

type LinkSectionProps = {
  title: string;
  description: string;
  buttons?: LinkSectionButton[];
};

const LinkSection: React.FC<LinkSectionProps> = ({title, description, buttons}) => {
  const sectionBtn = (button: LinkSectionButton): JSX.Element => (
    <Button
      key={button.label}
      variant={button.color ?? 'secondary'}
      type={button.isSubmit ? 'submit' : 'button'}
      data-testid={button.testId}
      onClick={button.handleOnClick}
      disabled={button.isDisabled}
      loading={button.isLoading}
    >
      {button.icon}
      {button.label}
    </Button>
  );

  return (
    <section className="grid items-center gap-4 [grid-template-columns:1fr_min-content]">
      <div>
        <h3 className="mb-2 text-lg font-semibold">{title}</h3>
        <p className="text-slate-400">{description}</p>
      </div>
      <div className="flex gap-2">
        {buttons?.map(button => {
          return button.url !== undefined ? (
            <a key={button.label} href={button.url} target="_blank" rel="noreferrer noopener">
              {sectionBtn(button)}
            </a>
          ) : (
            sectionBtn(button)
          );
        })}
      </div>
    </section>
  );
};

export default LinkSection;
