import type {RuleInputADto} from '@cohort/admin-schemas/rule';
import AdminPage from '@cohort/merchants/components/AdminPage';
import SubmitFooter from '@cohort/merchants/components/form/SubmitFooter';
import Loader from '@cohort/merchants/components/Loader';
import {cohortsKeys, useCohortRule} from '@cohort/merchants/hooks/api/Cohorts';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentCohort} from '@cohort/merchants/hooks/contexts/currentCohort';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCohortPageStore} from '@cohort/merchants/hooks/stores/cohortPage';
import {upsertCohortRule} from '@cohort/merchants/lib/api/Cohorts';
import {handleFormErrors} from '@cohort/merchants/lib/form/utils';
import DynamicCohortGroupForm from '@cohort/merchants/pages/users/cohort/rule/DynamicCohortGroupForm';
import type {DynamicCohortRuleType} from '@cohort/merchants/pages/users/cohort/rule/utils';
import {
  DynamicCohortRuleSchema,
  DynamicCohortRuleSchemaRequired,
} from '@cohort/merchants/pages/users/cohort/rule/utils';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

type DynamicCohortRuleFormProps = {
  rule?: RuleInputADto;
};

// Splitting the form into a separate component to wait for the rule to be fetched to be used as default value
const DynamicCohortRuleForm: React.FC<DynamicCohortRuleFormProps> = ({rule}) => {
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();
  const cohort = useCurrentCohort();
  const {t} = useTranslation('pages', {
    keyPrefix: 'users.cohort.rule.page',
  });
  const setFooter = useCohortPageStore(store => store.setFooter);

  const {isLoading, mutate: upsertRule} = useCohortMutation({
    mutationFn: async (data: RuleInputADto) => upsertCohortRule(merchant.id, cohort.id, data),
    notifySuccessMessage: t('ruleUpsertSuccess'),
    onSuccess: () => queryClient.invalidateQueries(cohortsKeys.getById(merchant.id, cohort.id)),
  });

  const {control, register, formState, handleSubmit, clearErrors, setError} =
    useForm<DynamicCohortRuleType>({
      resolver: zodResolver(DynamicCohortRuleSchema),
      defaultValues: DynamicCohortRuleSchema.parse({
        id: rule?.id ?? null,
        groups: rule?.groups ?? [],
      }),
    });

  useEffect(() => {
    setFooter(
      <SubmitFooter
        formName="dynamic-cohort-rule-form"
        submitLabel="Save conditions"
        loading={isLoading}
      />
    );
    return () => setFooter(null);
  }, [formState.isDirty, setFooter, t, isLoading]);

  return (
    <form
      id="dynamic-cohort-rule-form"
      onSubmit={handleSubmit(data => {
        try {
          // Zod has a deepPartial method but it doesn't provide a deepRequired method
          const parsedData = DynamicCohortRuleSchemaRequired.parse(data);

          upsertRule(parsedData);
        } catch (error) {
          if (error instanceof z.ZodError) {
            for (const issue of error.issues) {
              // Handle the error if no conditions are selected
              if (issue.code === z.ZodIssueCode.invalid_union_discriminator) {
                issue.message = 'errorRequired';
              }
            }
            return handleFormErrors(error, clearErrors, setError);
          }
          throw error;
        }
      })}
    >
      <DynamicCohortGroupForm control={control} register={register} />
    </form>
  );
};

const DynamicCohortRulePage: React.FC = () => {
  const merchant = useCurrentMerchant();
  const cohort = useCurrentCohort();
  const {t} = useTranslation('pages', {
    keyPrefix: 'users.cohort.rule.page',
  });
  const enabled = !!cohort.ruleId;

  const {data: rule, isFetched} = useCohortRule(merchant.id, cohort.id, {
    enabled,
  });

  return (
    <AdminPage
      header={{
        title: t('title'),
        subtitle: t('subtitle'),
      }}
    >
      {!isFetched && enabled ? (
        <Loader size={30} color="gray" />
      ) : (
        <DynamicCohortRuleForm rule={rule} />
      )}
    </AdminPage>
  );
};

export default DynamicCohortRulePage;
