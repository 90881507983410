import type {
  UpdateUserPropertyDataADto,
  UserPropertyADto,
} from '@cohort/admin-schemas/userProperty';
import AppBadge from '@cohort/merchants/apps/AppBadge';
import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import Form from '@cohort/merchants/components/form/Form';
import Input from '@cohort/merchants/components/form/input/Input';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {userPropertiesKeys, useUserProperty} from '@cohort/merchants/hooks/api/UserProperties';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {
  getUserPropertyReferenceIdAvailability,
  updateUserProperty,
} from '@cohort/merchants/lib/api/UserProperties';
import {notifyError} from '@cohort/merchants/stores/ErrorModalStore';
import {UserPropertyDataTypeSchema} from '@cohort/shared/schema/common/userProperty';
import {formatDate} from '@cohort/shared/utils/format';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {z} from 'zod';

type UserPropertyFormProps = {
  userProperty: UserPropertyADto;
};

const UserPropertyForm: React.FC<UserPropertyFormProps> = ({userProperty}): JSX.Element => {
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();
  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.userProperties.userPropertyDetailPage',
  });

  const {mutate: updateUserPropertyMutation, isLoading} = useCohortMutation({
    mutationFn: async (data: UpdateUserPropertyDataADto) =>
      updateUserProperty(merchant.id, userProperty.id, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries(userPropertiesKeys.userProperties);
    },
    notifySuccessMessage: t('notificationSuccess'),
    onError: err => {
      notifyError(err, t('notificationError'));
    },
  });

  // only the name and referenceId is updatable, other fields are here for display purposes
  const UpdateUserPropertyFormSchema = z.object({
    name: z.string().min(1),
    referenceId: z.string().refine(
      async value => {
        if (value === userProperty.referenceId) {
          // no changes
          return true;
        }
        const availability = await getUserPropertyReferenceIdAvailability(merchant.id, {
          appId: userProperty.appId,
          referenceId: value,
        });
        return availability.available;
      },
      {message: 'errorNotAvailable'}
    ),
    dataType: UserPropertyDataTypeSchema,
    createdAt: z.string(),
    updatedAt: z.string(),
  });

  const isSyncedProperty = userProperty.appId !== null;

  return (
    <Form
      className="flex flex-col space-y-6"
      formSchema={UpdateUserPropertyFormSchema}
      defaultValues={{
        name: userProperty.name,
        referenceId: userProperty.referenceId,
        dataType: userProperty.dataType,
        createdAt: formatDate(userProperty.createdAt),
        updatedAt: formatDate(userProperty.updatedAt),
      }}
      isSubmitting={isLoading}
      onSubmit={data =>
        updateUserPropertyMutation({name: data.name, referenceId: data.referenceId})
      }
      formActions={[
        <Button variant="primary" type="submit">
          {t('buttonSave')}
        </Button>,
      ]}
    >
      {({register, control}) => (
        <Fragment>
          <Input
            type="text"
            name="name"
            register={register}
            control={control}
            label={t('labelName')}
            disabled={isSyncedProperty}
          />
          <Input
            type="text"
            name="referenceId"
            register={register}
            control={control}
            label={t('labelReferenceId')}
            disabled={isSyncedProperty}
          />
          <Input
            type="text"
            name="dataType"
            register={register}
            control={control}
            label={t('labelDataType')}
            disabled
          />
          <Input
            type="text"
            name="createdAt"
            register={register}
            control={control}
            label={t('labelCreatedAt')}
            disabled
          />
          <Input
            type="text"
            name="updatedAt"
            register={register}
            control={control}
            label={t('labelUpdatedAt')}
            disabled
          />
          <div className="flex flex-col space-y-2 text-sm">
            <label className="font-medium text-slate-700">{t('labelUserSync')}</label>
            {userProperty.appId !== null ? <AppBadge appId={userProperty.appId} /> : <p>-</p>}
          </div>
        </Fragment>
      )}
    </Form>
  );
};

const UserPropertyDetailPage = (): JSX.Element => {
  const merchant = useCurrentMerchant();
  const {userPropertyId} = useParams();
  const {t} = useTranslation('pages', {
    keyPrefix: 'settings.userProperties.userPropertyDetailPage',
  });
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const {data: userProperty} = useUserProperty(merchant.id, userPropertyId!, {
    enabled: userPropertyId !== undefined,
  });

  if (userProperty === undefined) {
    return <Fragment />;
  }
  const headerConfig = {
    title: userProperty.name,
    subtitle: t('subtitle'),
  };

  const refreshKey = userProperty.updatedAt.toString();
  return (
    <AdminPage header={headerConfig} key={refreshKey}>
      <UserPropertyForm userProperty={userProperty} />
    </AdminPage>
  );
};

export default UserPropertyDetailPage;
