import type {OrganizationADto} from '@cohort/admin-schemas/organization';
import Button from '@cohort/merchants/components/buttons/Button';
import Input from '@cohort/merchants/components/form/input/Input';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {requestChangePassword} from '@cohort/merchants/lib/api/Auth';
import {getSignInRoute} from '@cohort/merchants/lib/Pages';
import OrganizationLogo from '@cohort/merchants/pages/sign-in/OrganizationLogo';
import {EmailSchema} from '@cohort/shared/schema/common';
import {useTurnstile} from '@cohort/shared-frontend/hooks/useTurnstile';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {zodResolver} from '@hookform/resolvers/zod';
import {CaretLeft} from '@phosphor-icons/react';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {z} from 'zod';

type ForgotPasswordFormProps = {
  organization: OrganizationADto;
};

const FormSchema = z.object({
  email: EmailSchema,
});
type Form = z.infer<typeof FormSchema>;

type Location = {
  state?: {
    email?: string;
  };
};

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({organization}) => {
  const [emailSent, setEmailSent] = useState(false);
  const {t} = useTranslation('pages', {keyPrefix: 'forgot-password.forgotPasswordForm'});
  const navigate = useNavigate();
  const {token, resetToken} = useTurnstile();
  const location = useLocation() as Location;

  const {mutate: handleResetPassword, isLoading} = useCohortMutation({
    mutationFn: async ({email}: {email: string}) => {
      if (!token) {
        return;
      }
      const res = await requestChangePassword(email, token);
      return res;
    },
    onSuccess: async () => {
      setEmailSent(true);
    },
    onSettled: () => {
      resetToken?.();
    },
  });

  const {register, control, handleSubmit, formState, watch} = useForm<Form>({
    defaultValues: {
      email: location.state?.email ?? '',
    },
    mode: 'onBlur',
    resolver: zodResolver(FormSchema),
  });

  const email = watch('email');

  if (emailSent) {
    return (
      <div className="flex flex-col space-y-6">
        <OrganizationLogo organization={organization} />
        <div className="flex flex-col space-y-2">
          <h1 className="text-2xl font-semibold text-slate-900">{t('sentTitle')}</h1>
          <p className="text-sm text-slate-500">{t('sentInstructions')}</p>
        </div>
        <Button
          type="button"
          onClick={() => navigate(getSignInRoute().path, {state: {email}})}
          className="h-11 w-full"
        >
          {t('buttonBack')}
        </Button>
      </div>
    );
  }

  return (
    <form
      className="flex flex-col space-y-6"
      onSubmit={handleSubmit(data => handleResetPassword(data))}
    >
      <Button
        variant={'link'}
        className={cn('-ml-1 justify-start p-0')}
        onClick={() => navigate(getSignInRoute().path, {state: {email}})}
      >
        <CaretLeft className="h-5 w-5" />
        {t('buttonBack')}
      </Button>
      <OrganizationLogo organization={organization} />
      <div className="flex flex-col space-y-2">
        <h1 className="text-2xl font-semibold text-slate-900">{t('sendTitle')}</h1>
        <p className="text-sm text-slate-500">{t('sendInstructions')}</p>
      </div>
      <Input
        label={t('emailAddress')}
        placeholder="vitalik@getcohort.com"
        type="text"
        name="email"
        register={register}
        control={control}
        hideError
      />
      <Button
        type="submit"
        className="h-11 w-full"
        loading={isLoading}
        disabled={!formState.isValid}
      >
        {t('buttonContinue')}
      </Button>
    </form>
  );
};
export default ForgotPasswordForm;
