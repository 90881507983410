import type {ExecuteActionResponseADto} from '@cohort/admin-schemas/apps';
import type {UpdateSyncDataADto} from '@cohort/admin-schemas/sync';
import type {SyncConfigComponentProps} from '@cohort/merchants/apps';
import UserPropertyColumnsTable from '@cohort/merchants/apps/salesforce/sync/UserPropertyColumnsTable';
import type {SalesforceUserPropertyColumnWithSampleData} from '@cohort/merchants/apps/salesforce/sync/utils';
import {
  checkIfTestQueryError,
  getUserPropertyColumnsFromRows,
} from '@cohort/merchants/apps/salesforce/sync/utils';
import Button from '@cohort/merchants/components/buttons/Button';
import ErrorState from '@cohort/merchants/components/ErrorState';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {InformationTooltip} from '@cohort/merchants/components/InformationTooltip';
import SectionSeparator from '@cohort/merchants/components/SectionSeparator';
import {useConnection} from '@cohort/merchants/hooks/api/Connections';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {notify} from '@cohort/merchants/hooks/toast';
import {executeAction} from '@cohort/merchants/lib/api/Apps';
import type {TestQueryActionStruct} from '@cohort/shared/apps/salesforce/actions/testQuery';
import {SalesforceSyncConfigSchema} from '@cohort/shared/apps/salesforce/sync';
import {defaultNetworkErrorMessage} from '@cohort/shared/models';
import Editor from '@monaco-editor/react';
import {Lightning} from '@phosphor-icons/react';
import {isEqual} from 'lodash';
import type {editor} from 'monaco-editor';
import {Fragment, useRef, useState} from 'react';
import {useController, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const SalesforceSyncImportUsersConfigComponent: React.FC<SyncConfigComponentProps> = ({
  sync,
  setIsSavingDisabled,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [testQueryUserPropertyColumns, setTestQueryUserPropertyColumns] = useState<
    SalesforceUserPropertyColumnWithSampleData[]
  >([]);
  const [isRunQueryRequired, setIsRunQueryRequired] = useState(false);
  const {control, setValue} = useFormContext<UpdateSyncDataADto>();
  const editorRef = useRef<editor.IStandaloneCodeEditor>();
  const {field: configField} = useController({name: 'userSyncConfig', control});

  const {t} = useTranslation('app-salesforce', {
    keyPrefix: 'sync.importUsersConfigComponent',
  });

  const {data: connection, isFetched} = useConnection(merchantId, sync.connectionId);
  const {mutate: testQuery, isLoading: isTestQueryLoading} = useCohortMutation({
    mutationFn: async (data: {
      query: string;
      merchantConnectionId: string;
    }): Promise<ExecuteActionResponseADto<TestQueryActionStruct>> =>
      executeAction<TestQueryActionStruct>(merchantId, {
        appId: 'salesforce',
        actionId: 'test-query',
        merchantConnectionId: data.merchantConnectionId,
        input: {query: data.query},
      }),
    onSuccess: (data, {query}) => {
      // i18nOwl-ignore [errors.invalidQuery, errors.noRowsReturned]
      // i18nOwl-ignore [errors.noEmailOrInvalidEmail, errors.noUpdatedAtOrInvalidDate]
      const errorMessage = checkIfTestQueryError(data, t);
      if (errorMessage !== null) {
        setError(errorMessage);
        setTestQueryUserPropertyColumns([]);
        setIsSavingDisabled(true);

        return;
      }

      const userPropertyColumns = getUserPropertyColumnsFromRows(data.output.rows);
      const config = {
        query,
        userPropertyColumns: userPropertyColumns.map(({sampleData, ...rest}) => rest),
        emailColumn: 'Email',
      };
      setError(null);
      setTestQueryUserPropertyColumns(userPropertyColumns);
      setValue('userSyncConfig', config);
      setIsSavingDisabled(false);
      setIsRunQueryRequired(false);
    },
    onError: error => {
      notify(
        'error',
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        error instanceof Error && error.message !== undefined
          ? error.message
          : defaultNetworkErrorMessage
      );

      setError(null);
      setTestQueryUserPropertyColumns([]);
      setIsSavingDisabled(true);
    },
  });

  if (isFetched && connection === undefined) {
    return <ErrorState />;
  }

  const getEditorValue = (): string | undefined => {
    return editorRef.current?.getValue();
  };

  const handleEditorDidMount = (editor: editor.IStandaloneCodeEditor): void => {
    setIsEditorReady(true);
    editorRef.current = editor;
  };

  const formQueryValue = configField.value ?? undefined;
  const parsedFormQueryValue =
    formQueryValue !== undefined
      ? SalesforceSyncConfigSchema.parse(formQueryValue)
      : formQueryValue;

  const userSyncConfig =
    sync.userSyncConfig !== null
      ? SalesforceSyncConfigSchema.parse(sync.userSyncConfig)
      : undefined;
  const userPropertyToDeleteColumns =
    userSyncConfig?.userPropertyColumns.filter(
      column =>
        !testQueryUserPropertyColumns.some(
          testQueryColumn => testQueryColumn.referenceId === column.referenceId
        )
    ) ?? [];

  return (
    <Fragment>
      <SectionSeparator className="pb-2" />
      <h2 className="text-lg font-semibold leading-6">{t('titleSqlQuery')}</h2>
      <div className="flex flex-col space-y-4">
        <HighlightText
          type="info"
          text={
            <div>
              <p>{t('instructions.mandatoryFields')}</p>
              <ul className="list-disc pl-6">
                <li>{t('instructions.emailField')}</li>
                <li>{t('instructions.updatedAtField')}</li>
              </ul>
            </div>
          }
        />
        {error && <HighlightText type="error" text={error} />}
        <Editor
          theme="vs-dark"
          defaultLanguage="sql"
          defaultValue={parsedFormQueryValue?.query ?? 'SELECT'}
          height={150}
          onMount={handleEditorDidMount}
          onChange={value => {
            setIsRunQueryRequired(value !== parsedFormQueryValue?.query);
          }}
          options={{
            minimap: {enabled: false},
            padding: {top: 10, bottom: 10},
            scrollbar: {vertical: 'hidden'},
          }}
        />
        <div className="flew-row flex gap-2">
          <Button
            variant="secondary"
            onClick={() => {
              const value = getEditorValue();
              testQuery({query: value ?? '', merchantConnectionId: sync.connectionId});
            }}
            loading={isTestQueryLoading}
            disabled={!isEditorReady || !isRunQueryRequired}
          >
            <Lightning size={20} className="mr-2" />
            {t('runQueryButton')}
          </Button>
          <InformationTooltip content={t('tooltipRunQuery')} side="right" />
        </div>
      </div>
      <SectionSeparator />
      {userSyncConfig === undefined || !isEqual(userSyncConfig, configField.value) ? (
        <Fragment>
          <h2 className="text-lg font-semibold leading-6">{t('titlePropertiesToSync')}</h2>
          <UserPropertyColumnsTable
            userPropertyColumns={testQueryUserPropertyColumns}
            isQueryConfigured={
              error === null && parsedFormQueryValue?.userPropertyColumns.length === 0
            }
          />
          {error === null && userPropertyToDeleteColumns.length > 0 && (
            <Fragment>
              <h2 className="text-lg font-semibold leading-6">{t('titlePropertiesToDelete')}</h2>
              <UserPropertyColumnsTable
                userPropertyColumns={userPropertyToDeleteColumns}
                hideSampleData
              />
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <h2 className="text-lg font-semibold leading-6">{t('titleSyncedProperties')}</h2>
          <UserPropertyColumnsTable
            userPropertyColumns={userSyncConfig.userPropertyColumns}
            hideSampleData
            isQueryConfigured
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default SalesforceSyncImportUsersConfigComponent;
