import AdminPage from '@cohort/merchants/components/AdminPage';
import Button from '@cohort/merchants/components/buttons/Button';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import ContentsOverviewMenu from '@cohort/merchants/pages/contents/overview/ContentsOverviewMenu';
import {Plus} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

const ContentsOverviewPage: React.FC = () => {
  const {t} = useTranslation('pages', {keyPrefix: 'contents.overview.page'});

  const headerConfig = {
    title: t('title'),
    subtitle: t('subtitle'),
    topRightElements: (
      <Button key="createContent" onClick={() => {}} data-testid="create-content">
        <Plus className="-ml-1 mr-2 h-5 w-5" />
        {t('buttonCreateContent')}
      </Button>
    ),
  };

  const paginationConfig = {};

  return (
    <PageLayout title={t('title')} menu={<ContentsOverviewMenu />}>
      <AdminPage header={headerConfig} pagination={paginationConfig}>
        <div>Work in progress (Will be done in Next PR).</div>
      </AdminPage>
    </PageLayout>
  );
};

export default ContentsOverviewPage;
