import {EventIdSchema, NotificationIntegrationIdSchema} from '@cohort/shared/apps';
import {asArray, DateSchema} from '@cohort/shared/schema/common';
import {NotificationConfigurationResourceTypeSchema} from '@cohort/shared/schema/common/notificationConfiguration';
import {z} from 'zod';

export const NotificationASchema = z.object({
  status: z.enum(['success', 'error']),
  createdAt: DateSchema,
  message: z.string().nullable(),
});
export type NotificationADto = z.infer<typeof NotificationASchema>;

export const NotificationConfigurationASchema = z.object({
  id: z.string().uuid(),
  merchantId: z.string().uuid(),
  userEventId: EventIdSchema,
  notificationIntegrationId: NotificationIntegrationIdSchema,
  notificationIntegrationConfig: z.unknown(),
  connectionId: z.string().uuid().nullable(),
  createdAt: DateSchema,
  resourceType: NotificationConfigurationResourceTypeSchema.nullable(),
  resourceId: z.string().uuid().nullable(),
  lastExecution: NotificationASchema.nullable(),
});
export type NotificationConfigurationADto = z.infer<typeof NotificationConfigurationASchema>;

export const CreateNotificationConfigurationASchema = z.object({
  userEventId: EventIdSchema,
  connectionId: z.string().uuid().nullable(),
  notificationIntegrationId: NotificationIntegrationIdSchema,
  notificationIntegrationConfig: z.unknown(),
  resourceType: NotificationConfigurationResourceTypeSchema.nullable(),
  resourceId: z.string().uuid().nullable(),
});
export type CreateNotificationConfigurationADto = z.infer<
  typeof CreateNotificationConfigurationASchema
>;

export const PatchNotificationConfigurationASchema = z
  .object({
    connectionId: z.string().uuid().nullable(),
    notificationIntegrationId: NotificationIntegrationIdSchema,
    notificationIntegrationConfig: z.unknown(),
  })
  .partial();
export type PatchNotificationConfigurationADto = z.infer<
  typeof PatchNotificationConfigurationASchema
>;

export const DeleteNotificationConfigurationsParamsASchema = z.object({
  notificationConfigurationIds: z.preprocess(asArray, z.array(z.string().uuid())),
});
export type DeleteNotificationConfigurationsParamsADto = z.infer<
  typeof DeleteNotificationConfigurationsParamsASchema
>;
