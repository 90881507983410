import type {RowData, SortingState, Table, TableOptions} from '@tanstack/react-table';
import {getCoreRowModel, useReactTable} from '@tanstack/react-table';

function toSortingState<T extends string>(apiSort: T): SortingState {
  return [
    {
      id: apiSort.replace('-', ''),
      desc: apiSort.startsWith('-'),
    },
  ];
}

function fromSortingState<T extends string>(sortingState: SortingState): T {
  const sort = sortingState[0];

  if (!sort) {
    return '' as T;
  }
  return `${sort.desc ? '-' : ''}${sort.id}` as T;
}

export function useCohortTable<TData extends RowData, U extends string>(
  options: Omit<TableOptions<TData>, 'getCoreRowModel'>,
  sortConfig?: {
    sortBy: U;
    onSortUpdate: (sortBy: U) => void;
  }
): Table<TData> {
  return useReactTable({
    // By default, the ability to remove sorting while cycling through the sorting states for a column is enabled
    enableSortingRemoval: false,
    // Tell the table that we handle the sorting ourselves
    manualSorting: true,
    ...options,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: sortConfig
      ? newSortStateOrUpdater => {
          const newApiSortState =
            typeof newSortStateOrUpdater === 'function'
              ? fromSortingState(newSortStateOrUpdater(toSortingState(sortConfig.sortBy)))
              : fromSortingState(newSortStateOrUpdater);

          sortConfig.onSortUpdate(newApiSortState as U);
        }
      : undefined,
    state: {
      ...options.state,
      ...(sortConfig && {
        sorting: toSortingState(sortConfig.sortBy),
      }),
    },
  });
}
