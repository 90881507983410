import Button from '@cohort/merchants/components/buttons/Button';
import DraggableList from '@cohort/merchants/components/form/DraggableList';
import DraggableListItem from '@cohort/merchants/components/form/DraggableListItem';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetTitle,
} from '@cohort/merchants/components/modals/Sheet';
import RankBadge from '@cohort/merchants/components/RankBadge';
import {X} from '@phosphor-icons/react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

type BaseItemType = {
  internalName: string;
  id: string;
  imageFileKey?: string | null;
};

type ListItemProps<T extends BaseItemType> = {
  item: T;
  index: number;
  itemVisualComponent?: React.FC<{imageFileKey?: string | null; size: number}>;
  name: React.ReactNode;
  status?: React.ReactNode;
};

const ListItem = <T extends BaseItemType>({
  item,
  index,
  itemVisualComponent,
  name,
  status,
}: ListItemProps<T>): JSX.Element => {
  const ItemVisualComponent = itemVisualComponent;

  return (
    <div className="flex items-center space-x-4">
      <RankBadge rank={index + 1} />
      <DraggableListItem className="w-full bg-slate-50" grow>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex space-x-3">
            {ItemVisualComponent !== undefined && (
              <ItemVisualComponent imageFileKey={item.imageFileKey} size={8} />
            )}
            <div className="flex flex-row items-center gap-3 text-sm font-medium">{name}</div>
          </div>
          {status}
        </div>
      </DraggableListItem>
    </div>
  );
};

type ReorderItemsDrawerProps<T extends BaseItemType> = {
  title: string;
  subtitle: string;
  items: Array<T>;
  onClose: () => void;
  onSave: (data: Array<string>) => void;
  isLoading: boolean;
  nameDisplayer: (item: T) => React.ReactNode;
  statusDisplayer?: (item: T) => React.ReactNode;
  itemVisualComponent?: React.FC;
};

const ReorderItemsDrawer = <T extends BaseItemType>({
  title,
  subtitle,
  items,
  onClose,
  onSave,
  nameDisplayer,
  statusDisplayer,
  isLoading,
  itemVisualComponent,
}: ReorderItemsDrawerProps<T>): JSX.Element => {
  const [itemsList, setItemsList] = useState(items);
  const {t} = useTranslation('components', {keyPrefix: 'reorderItemsDrawer'});

  return (
    <Sheet open onOpenChange={onClose}>
      <SheetContent className="flex h-full flex-col gap-0 p-6">
        <div className="mb-6 space-y-2">
          <SheetTitle className="text-xl font-semibold">{title}</SheetTitle>
          <p className="text-sm font-normal text-slate-500">{subtitle}</p>
        </div>
        <div className="flex h-full flex-col justify-between">
          <div className="grow">
            <DraggableList
              handleOnReorder={itemIds => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                setItemsList(itemIds.map(id => itemsList.find(item => item.id === id)!));
              }}
              items={itemsList.map((item, index) => ({
                id: item.id,
                item: (
                  <ListItem
                    key={item.id}
                    item={item}
                    index={index}
                    name={nameDisplayer(item)}
                    status={statusDisplayer?.(item)}
                    itemVisualComponent={itemVisualComponent}
                  />
                ),
              }))}
            />
          </div>

          <SheetFooter>
            <div className="flex w-full items-center justify-between">
              <Button
                variant="secondary"
                onClick={onClose}
                data-testid="cancel"
                disabled={isLoading}
              >
                <X className="-ml-1 mr-2 h-5 w-5" />
                {t('buttonCancel')}
              </Button>
              <Button
                form="campaign-form-faq"
                onClick={() => onSave(itemsList.map(item => item.id))}
                disabled={isLoading}
              >
                {t('buttonEdit')}
              </Button>
            </div>
          </SheetFooter>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default ReorderItemsDrawer;
