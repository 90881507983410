import type {
  GetMerchantApiSettingsResponseADto,
  MerchantADto,
  PatchMerchantADto,
} from '@cohort/admin-schemas/merchant';
import {
  GetMerchantApiSettingsResponseASchema,
  MerchantASchema,
} from '@cohort/admin-schemas/merchant';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';

export async function patchMerchant(
  merchantId: string,
  data: PatchMerchantADto
): Promise<MerchantADto> {
  return apiCall('PATCH', `/v1/merchants/${merchantId}`, {
    expect: HttpCodes.SUCCESS,
    parser: MerchantASchema.parse,
    body: data,
  });
}

export async function getMerchant(merchantId: string): Promise<MerchantADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}`, {
    expect: HttpCodes.SUCCESS,
    parser: MerchantASchema.parse,
  });
}

export async function getMerchantApiSettings(
  merchantId: string
): Promise<GetMerchantApiSettingsResponseADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/api-settings`, {
    expect: HttpCodes.SUCCESS,
    parser: GetMerchantApiSettingsResponseASchema.parse,
  });
}
