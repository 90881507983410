import {useCurrentOrganization} from '@cohort/merchants/hooks/api/Organizations';
import {useOrganizationSlug} from '@cohort/merchants/hooks/useOrganizationSlug';
import {verifySigninOtp} from '@cohort/merchants/lib/api/Auth';
import {loginWithCustomToken} from '@cohort/merchants/lib/Firebase';
import {getHomeRoute, getOrgPickerRoute} from '@cohort/merchants/lib/Pages';
import {trackSigninPageViewed} from '@cohort/merchants/lib/Tracking';
import {getSigninSubdomainUrl} from '@cohort/merchants/lib/Utils';
import FirstSignIn from '@cohort/merchants/pages/sign-in/FirstSignIn';
import OrganizationSignIn from '@cohort/merchants/pages/sign-in/OrganizationSignIn';
import {notifyError} from '@cohort/merchants/stores/ErrorModalStore';
import React, {Fragment, useCallback, useEffect, useRef} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

const SignInPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const organizationSlug = useOrganizationSlug();
  const initialized = useRef(false);
  const {data: organization, error: organizationFetchError} = useCurrentOrganization({
    enabled: Boolean(organizationSlug),
  });

  useEffect(() => trackSigninPageViewed(), []);

  const onUserLoggedIn = useCallback(() => {
    if (organization) {
      navigate(getHomeRoute().path, {replace: true});
    } else {
      navigate(getOrgPickerRoute().path, {replace: true});
    }
  }, [navigate, organization]);

  const processOtpSignIn = useCallback(
    async (email: string, code: string) => {
      try {
        const token = await verifySigninOtp(email, code);

        await loginWithCustomToken(token);
        onUserLoggedIn();
      } catch (err) {
        notifyError(err);
      }
    },
    [onUserLoggedIn]
  );

  useEffect(() => {
    if (!initialized.current) {
      const email = searchParams.get('email');
      const code = searchParams.get('code');
      if (email && code) {
        processOtpSignIn(email, code);
      }
    }
    return () => {
      initialized.current = true;
    };
  }, [processOtpSignIn, searchParams]);

  useEffect(() => {
    if (organizationFetchError) {
      window.location.assign(getSigninSubdomainUrl());
    }
  }, [organizationFetchError]);

  if (organizationSlug && !organization) {
    // The organization is loading
    return <Fragment />;
  }

  if (organization) {
    return <OrganizationSignIn organization={organization} onUserLoggedIn={onUserLoggedIn} />;
  }

  return <FirstSignIn onUserLoggedIn={onUserLoggedIn} />;
};

export default SignInPage;
