import type {UserPropertyADto} from '@cohort/admin-schemas/userProperty';
import DataTypeIcon from '@cohort/merchants/components/DataTypeIcon';
import NetworkedSelect from '@cohort/merchants/components/form/select/NetworkedSelect';
import {usePaginatedUserProperties} from '@cohort/merchants/hooks/api/UserProperties';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import type {UserPropertyDataType} from '@cohort/shared/schema/common/userProperty';
import {useState} from 'react';

const USER_PROPERTIES_PAGE_SIZE = 50;

type SelectUserPropertyProps = {
  onChange: (value: UserPropertyADto | undefined) => void;
  index: number;
  value: UserPropertyADto | undefined;
  mappedProperties: Array<UserPropertyADto | undefined>;
  isDisabled: boolean;
  dataTypesFilter: Array<UserPropertyDataType>;
  className?: string;
};
const SelectUserProperty: React.FC<SelectUserPropertyProps> = ({
  onChange,
  index,
  value,
  mappedProperties,
  isDisabled,
  dataTypesFilter,
  className,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const [search, setSearch] = useState<string | undefined>(undefined);

  const {data: userPropertiesData, isLoading} = usePaginatedUserProperties(merchantId, {
    pageSize: USER_PROPERTIES_PAGE_SIZE,
    page: 1,
    hasAppId: false,
    ...(search !== undefined && search.trim().length > 0 && {search}),
    orderBy: 'name',
    dataTypes: dataTypesFilter,
  });
  const [, userProperties] = userPropertiesData ?? [];

  return (
    <NetworkedSelect
      name={`userProperties.${index}`}
      options={
        userProperties?.map(userProperty => ({
          value: userProperty,
          label: (
            <div className="flex flex-row items-center gap-2">
              <DataTypeIcon dataType={userProperty.dataType} size={16} />
              {userProperty.name}
            </div>
          ),
          isDisabled:
            mappedProperties.find(attribute => attribute?.id === userProperty.id) !== undefined,
        })) ?? []
      }
      onInputChange={setSearch}
      onChange={newValue => onChange(newValue ? newValue.value : undefined)}
      value={
        value
          ? {
              value: value,
              label: (
                <div className="flex flex-row items-center gap-2">
                  <DataTypeIcon dataType={value.dataType} size={16} />
                  {value.name}
                </div>
              ),
            }
          : undefined
      }
      isClearable
      isLoading={isLoading}
      isDisabled={isDisabled}
      className={className}
    />
  );
};

export default SelectUserProperty;
