import {Badge} from '@cohort/merchants/components/Badge';
import type {CampaignStatus} from '@cohort/shared/schema/common/campaign';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

interface CampaignStatusBadgeProps {
  status: CampaignStatus;
  size?: 'large' | 'small';
}

type BadgeData = {
  text: string;
  backgroundColor: string;
  textColor: string;
  dotColor: string;
  borderColor?: string;
};

export const CampaignStatusBadge: React.FC<CampaignStatusBadgeProps> = ({status, size}) => {
  const {t} = useTranslation('components', {keyPrefix: 'campaigns.campaignStatusBadge'});
  const badgeData: BadgeData = match(status)
    .with('draft', () => ({
      text: t('statusDraft'),
      backgroundColor: 'bg-yellow-100',
      textColor: 'text-yellow-800',
      dotColor: 'text-yellow-400',
    }))
    .with('published', () => ({
      text: t('statusPublished'),
      backgroundColor: 'bg-green-100',
      textColor: 'text-green-800',
      dotColor: 'text-green-400',
    }))
    .with('ended', () => ({
      text: t('statusEnded'),
      backgroundColor: 'bg-gray-100',
      textColor: 'text-gray-800',
      dotColor: 'text-gray-400',
    }))
    .exhaustive();

  return (
    <Badge
      text={badgeData.text}
      backgroundColor={badgeData.backgroundColor}
      textColor={badgeData.textColor}
      dotColor={badgeData.dotColor}
      borderColor={badgeData.borderColor}
      size={size ?? 'large'}
    />
  );
};
