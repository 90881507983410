import type {PerkIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {assertConnectionId} from '@cohort/merchants/apps';
import Input from '@cohort/merchants/components/form/input/Input';
import HighlightText from '@cohort/merchants/components/HighlightText';
import Loader from '@cohort/merchants/components/Loader';
import {useConnectionProfile} from '@cohort/merchants/hooks/api/Connections';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {InstagramUserConnectorStruct} from '@cohort/shared/apps/instagram/userConnector';
import {Fragment, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

const InstagramCloseFriendsListPerkIntegrationConfigComponent: React.FC<
  PerkIntegrationConfigComponentProps
> = ({formReturn: {setValue, register, control}, connectionId}) => {
  assertConnectionId(connectionId);

  const merchant = useCurrentMerchant();
  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'perkIntegrations.close-friends-list.configComponent',
  });

  const {data: profile, isLoading} = useConnectionProfile<InstagramUserConnectorStruct>(
    merchant.id,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    connectionId!,
    {
      enabled: !!connectionId,
    }
  );

  // Set the value of the username to the input field
  useEffect(() => {
    setValue('integration.config.username', `${profile?.properties.username}`);
  }, [profile, setValue]);

  if (isLoading) {
    return <Loader size={30} color="gray" />;
  }
  if (!profile) {
    return <Fragment />;
  }
  return (
    <div className="mt-3 space-y-2">
      <HighlightText type="info" text={t('instructions')} />
      <Input
        type="text"
        name="integration.config.username"
        label={t('labelInstagramUsername')}
        disabled={true}
        register={register}
        control={control}
      />
    </div>
  );
};

export default InstagramCloseFriendsListPerkIntegrationConfigComponent;
