import ColorInput from '@cohort/merchants/components/form/ColorInput';
import FileInput from '@cohort/merchants/components/form/FileInput';
import Input from '@cohort/merchants/components/form/input/Input';
import LocalizedInput from '@cohort/merchants/components/form/input/LocalizedInput';
import RadioCards from '@cohort/merchants/components/form/RadioCards';
import LocalizedTextEditorInput from '@cohort/merchants/components/form/textEditor/LocalizedTextEditorInput';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {CampaignSettingsStepValues} from '@cohort/merchants/pages/campaigns/campaign/edit/formSchemas/common';
import {DEFAULT_ACCENT_COLOR, DEFAULT_BACKGROUND_COLOR} from '@cohort/shared/schema/common';
import {AllowedAssetMimeTypes} from '@cohort/shared/schema/common/assets';
import {Fragment, useEffect} from 'react';
import type {Control, UseFormRegister, UseFormSetValue} from 'react-hook-form';
import {useWatch} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';

type StoreAppearanceSectionProps = {
  control: Control<CampaignSettingsStepValues>;
  register: UseFormRegister<CampaignSettingsStepValues>;
  setValue: UseFormSetValue<CampaignSettingsStepValues>;
};

const StoreAppearanceSection: React.FC<StoreAppearanceSectionProps> = ({
  control,
  register,
  setValue,
}) => {
  const campaign = useCurrentCampaign();
  const merchant = useCurrentMerchant();

  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.edit.settings.store.storeAppearanceSection',
  });

  const storeThemeColorType = useWatch({
    control,
    name: 'store.storeThemeColorType',
  });
  const selectedLanguage = useWatch({
    control,
    name: 'selectedLanguage',
  });

  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  useEffect(() => {
    if (
      (campaign.store?.backgroundColorCode === merchant.backgroundColorCode &&
        campaign.store.accentColorCode === merchant.accentColorCode) ||
      (campaign.store?.backgroundColorCode === null && campaign.store.accentColorCode === null)
    ) {
      setValue('store.storeThemeColorType', 'experience-space');
    } else {
      setValue('store.storeThemeColorType', 'custom');
    }
    // we only want to execute this once on initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (selectedLanguage === undefined) {
    return <Fragment></Fragment>;
  }
  return (
    <Fragment>
      <div className="flex gap-4">
        <LocalizedInput
          type="text"
          name="store.title"
          label={t('labelTitle')}
          placeholder={t('placeholderTitle', {merchantName: merchant.name})}
          register={register}
          control={control}
          selectedLanguage={selectedLanguage}
          optional={!isDefaultLanguageSelected}
        />
        <Input
          type="text"
          name="store.slug"
          label={t('labelSlug')}
          register={register}
          control={control}
        />
      </div>
      <LocalizedTextEditorInput
        label={t('labelDescription')}
        name="store.description"
        register={register}
        control={control}
        selectedLanguage={selectedLanguage}
        placeholder={t('placeholderDescription')}
        optional={!isDefaultLanguageSelected}
      />
      {isDefaultLanguageSelected && (
        <FileInput
          label={t('labelVisual')}
          name="store.imageFileKey"
          register={register}
          control={control}
          acceptHint={t('instructionVisual')}
          accept={AllowedAssetMimeTypes.storeVisual.options.join(',')}
          assetKind="storeVisual"
          description={t('helperVisual')}
          withResize
        />
      )}
      {isDefaultLanguageSelected && (
        <RadioCards
          name="store.storeThemeColorType"
          direction="row"
          label={t('labelStoreThemeColorType')}
          register={register}
          control={control}
          onChange={e => {
            if (e.target.value === 'experience-space') {
              setValue(
                'store.backgroundColorCode',
                merchant.backgroundColorCode || DEFAULT_BACKGROUND_COLOR
              );
              setValue('store.accentColorCode', merchant.accentColorCode || DEFAULT_ACCENT_COLOR);
            }

            if (e.target.value === 'custom') {
              setValue(
                'store.backgroundColorCode',
                campaign.store?.backgroundColorCode ||
                  merchant.backgroundColorCode ||
                  DEFAULT_BACKGROUND_COLOR
              );
              setValue(
                'store.accentColorCode',
                campaign.store?.accentColorCode || merchant.accentColorCode || DEFAULT_ACCENT_COLOR
              );
            }
          }}
          options={[
            {
              label: (
                <Trans
                  i18nKey="campaigns.campaign.edit.settings.store.storeAppearanceSection.labelExperienceSpace"
                  ns="pages"
                  components={{
                    bold: <span className="font-medium" />,
                    lighter: <span className="text-gray-500" />,
                  }}
                />
              ),
              value: 'experience-space',
            },
            {
              label: (
                <Trans
                  i18nKey="campaigns.campaign.edit.settings.store.storeAppearanceSection.labelCustom"
                  ns="pages"
                  components={{
                    bold: <span className="font-medium" />,
                    lighter: <span className="text-gray-500" />,
                  }}
                />
              ),
              value: 'custom',
            },
          ]}
        />
      )}
      {isDefaultLanguageSelected && (
        <div className="flex flex-row gap-4">
          <ColorInput
            name="store.backgroundColorCode"
            label={t('labelBackgroundColorCode')}
            register={register}
            control={control}
            disabled={
              storeThemeColorType && ['experience-space', 'image'].includes(storeThemeColorType)
            }
          />
          <ColorInput
            name="store.accentColorCode"
            label={t('labelAccentColorCode')}
            register={register}
            control={control}
            disabled={storeThemeColorType === 'experience-space'}
          />
        </div>
      )}
    </Fragment>
  );
};

export default StoreAppearanceSection;
