import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Play} from '@phosphor-icons/react';
import React from 'react';

export type MediaCoverProps = {
  imgUrl: string | null;
  displayName: string;
  bannerFileKey: string | File | null;
  videoFileKey: string | File | null;
  handlePlayVideoClick: () => void;
  onImageLoad?: (imageDominantColor: string) => void;
  enforce16x9AspectRatio?: boolean;
  layout?: 'row' | 'column';
};

export const MediaCover: React.FC<MediaCoverProps> = ({
  imgUrl,
  videoFileKey,
  bannerFileKey,
  displayName,
  handlePlayVideoClick,
  enforce16x9AspectRatio,
  layout = 'row',
}: MediaCoverProps): React.ReactElement => (
  <div
    className="h-full w-full overflow-clip"
    onClick={() => videoFileKey !== null && handlePlayVideoClick()}
  >
    {imgUrl !== null ? (
      <div className="z-10 h-full">
        <div className="relative h-full">
          {bannerFileKey === null && videoFileKey instanceof File ? ( // If a banner has not been defined by the merchant, use the video as the banner.
            <video
              data-testid="perk-video-preview"
              className={cn(
                'w-full group-hover:opacity-90',
                enforce16x9AspectRatio && 'aspect-video object-cover',
                layout === 'row' ? 'h-full' : 'h-auto'
              )}
              src={URL.createObjectURL(videoFileKey)}
            />
          ) : (
            <img
              title={displayName}
              src={imgUrl}
              className={cn(
                'w-full group-hover:opacity-90',
                enforce16x9AspectRatio && 'aspect-video object-cover',
                layout === 'row' ? 'h-full' : 'h-auto'
              )}
              crossOrigin="anonymous"
              alt={displayName}
            />
          )}
          {videoFileKey !== null && (
            <div
              data-testid="play-video-button"
              className="pointer-events-none absolute inset-0 flex items-center justify-center text-white transition-all duration-200 ease-in-out group-hover:scale-125"
            >
              <div className="flex rounded-full p-4 backdrop-blur backdrop-filter">
                <Play className="h-8 w-8" aria-hidden="true" />
              </div>
            </div>
          )}
        </div>
      </div>
    ) : null}
  </div>
);
