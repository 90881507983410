import type {
  UpdateUserPropertiesDataADto,
  UserPropertyADto,
} from '@cohort/admin-schemas/userProperty';
import Button from '@cohort/merchants/components/buttons/Button';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetTitle,
} from '@cohort/merchants/components/modals/Sheet';
import SectionSeparator from '@cohort/merchants/components/SectionSeparator';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {userPropertiesKeys} from '@cohort/merchants/hooks/api/UserProperties';
import {usersKeys} from '@cohort/merchants/hooks/api/Users';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {updateUserProperties} from '@cohort/merchants/lib/api/UserProperties';
import HiddenUserPropertiesSelector from '@cohort/merchants/pages/users/overview/user-properties-visibility-drawer/HiddenUserPropertiesSelector';
import VisibleUserPropertiesList from '@cohort/merchants/pages/users/overview/user-properties-visibility-drawer/VisibleUserPropertiesList';
import {X} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

type UserPropertiesVisibilityDrawerProps = {
  onClose: () => void;
};

const UserPropertiesVisibilityDrawer: React.FC<UserPropertiesVisibilityDrawerProps> = ({
  onClose,
}) => {
  const [userPropertiesToHide, setUserPropertiesToHide] = useState<UserPropertyADto[]>([]);
  const [userPropertiesToShow, setUserPropertiesToShow] = useState<UserPropertyADto[]>([]);
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {keyPrefix: 'users.overview.userPropertiesDrawer'});
  const queryClient = useQueryClient();

  const formatUserPropertiesData = (): UpdateUserPropertiesDataADto => {
    const formattedPropertiesToHide = userPropertiesToHide.map(property => ({
      id: property.id,
      visible: false,
    }));
    const formattedPropertiesToShow = userPropertiesToShow.map(property => ({
      id: property.id,
      visible: true,
    }));
    return [...formattedPropertiesToHide, ...formattedPropertiesToShow];
  };

  const {mutate: updateUserPropertiesMutation, isLoading} = useCohortMutation({
    mutationFn: async () => updateUserProperties(merchant.id, formatUserPropertiesData()),
    onSuccess: async () => {
      await queryClient.invalidateQueries(userPropertiesKeys.userProperties);
      await queryClient.invalidateQueries(usersKeys.users);
      onClose();
    },
    notifySuccessMessage: t('notificationSuccess'),
  });

  return (
    <Sheet open onOpenChange={onClose}>
      <SheetContent className="flex h-full flex-col gap-6 p-6">
        <div className="space-y-2">
          <SheetTitle className="text-xl font-semibold">{t('title')}</SheetTitle>
          <p className="text-sm font-normal text-slate-500">{t('subtitle')}</p>
        </div>

        <VisibleUserPropertiesList
          userPropertiesToHide={userPropertiesToHide}
          setUserPropertiesToHide={setUserPropertiesToHide}
        />

        <div className="space-y-6">
          <div className="space-y-4">
            <SectionSeparator size="small" className="mt-0" />

            <HiddenUserPropertiesSelector
              userPropertiesToShow={userPropertiesToShow}
              setUserPropertiesToShow={setUserPropertiesToShow}
            />

            <HighlightText type="info" text={t('infoText')} />
          </div>

          <SheetFooter>
            <div className="flex w-full items-center justify-between">
              <Button variant="secondary" onClick={onClose} data-testid="cancel">
                <X className="-ml-1 mr-2 h-5 w-5" />
                {t('buttonCancel')}
              </Button>
              <Button
                form="user-properties-visibility-form"
                onClick={() => updateUserPropertiesMutation()}
                loading={isLoading}
              >
                {t('buttonSave')}
              </Button>
            </div>
          </SheetFooter>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default UserPropertiesVisibilityDrawer;
