import type {OrderADto} from '@cohort/admin-schemas/orders';
import {OrderASchema} from '@cohort/admin-schemas/orders';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import type {ExportDto, ExportFormatDto} from '@cohort/shared/schema/common/export';
import {ExportSchema} from '@cohort/shared/schema/common/export';
import type {PaginationDto, PaginationParamsDto} from '@cohort/shared/schema/common/pagination';
import {paginatedParser} from '@cohort/shared/utils/parser';

export async function getOrders(
  merchantId: string,
  campaignId: string,
  paginationParams: PaginationParamsDto
): Promise<[PaginationDto, OrderADto[]]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/orders`, {
    expect: HttpCodes.SUCCESS,
    parser: paginatedParser(OrderASchema),
    params: {
      campaignId,
      ...paginationParams,
    },
  });
}

export async function exportOrders(
  merchantId: string,
  campaignId: string,
  format: ExportFormatDto
): Promise<ExportDto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/orders/export`, {
    expect: HttpCodes.CREATED,
    parser: ExportSchema.parse,
    params: {
      campaignId,
      format,
    },
  });
}
